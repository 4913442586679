import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_WITH_PAGINATION_REQUEST,
  GET_CATEGORIES_WITH_PAGINATION_SUCCESS,
  GET_CATEGORIES_WITH_PAGINATION_FAILURE,
} from "./categoryCreationTypes";

const initialState = {
  loading: false,
  categories: [],
  error: "",
};

const getCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_CATEGORIES_FAILURE:
      return {
        loading: false,
        categories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateWithPagination = {
  loading: false,
  categories: [],
  error: "",
};

const getCategoriesWithPaginationReducer = (
  state = initialStateWithPagination,
  action
) => {
  switch (action.type) {
    case GET_CATEGORIES_WITH_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORIES_WITH_PAGINATION_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_CATEGORIES_WITH_PAGINATION_FAILURE:
      return {
        loading: false,
        categories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export { getCategoriesReducer, getCategoriesWithPaginationReducer };
