import axios from "axios";
import Config from "../../config/index";
import {
  GET_ALLOCATED_ACTIVITY_REQUEST,
  GET_ALLOCATED_ACTIVITY_SUCCESS,
  GET_ALLOCATED_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  GET_CREATEDACTIVITY_FAILURE,
  GET_CREATEDACTIVITY_REQUEST,
  GET_CREATEDACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
} from "./createdActivityTypes";

let baseUrl = Config.baseUrl;

const getAllocatedActivityRequest = () => {
  return {
    type: GET_ALLOCATED_ACTIVITY_REQUEST,
  };
};

const getAllocatedActivitySuccess = (payload) => {
  return {
    type: GET_ALLOCATED_ACTIVITY_SUCCESS,
    payload: payload,
  };
};

const getAllocatedActivityFailure = (error) => {
  return {
    type: GET_ALLOCATED_ACTIVITY_FAILURE,
    payload: error,
  };
};

const getAllocatedActivity = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllocatedActivityRequest());
    let url = `${baseUrl}/document_mvt_map?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllocatedActivitySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAllocatedActivityFailure(err.message));
      });
  };
};

const getCreatedActivityRequest = () => {
  return {
    type: GET_CREATEDACTIVITY_REQUEST,
  };
};

const getCreatedActivitySuccess = (payload) => {
  return {
    type: GET_CREATEDACTIVITY_SUCCESS,
    payload: payload,
  };
};

const getCreatedActivityFailure = (error) => {
  return {
    type: GET_CREATEDACTIVITY_FAILURE,
    payload: error,
  };
};

const getCreatedActivity = (documentType, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCreatedActivityRequest());
    let url = `${baseUrl}/mvt?document_type=${documentType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCreatedActivitySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getCreatedActivityFailure(err.message));
      });
  };
};

const createActivityRequest = () => {
  return {
    type: CREATE_ACTIVITY_REQUEST,
  };
};

const createActivitySuccess = (payload) => {
  return {
    type: CREATE_ACTIVITY_SUCCESS,
    payload: payload,
  };
};

const createActivityFailure = (error) => {
  return {
    type: CREATE_ACTIVITY_FAILURE,
    payload: error,
  };
};

const createActivity = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createActivityRequest());
    let url = `${baseUrl}/document_mvt_map`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createActivitySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createActivityFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteActivityRequest = () => {
  return {
    type: DELETE_ACTIVITY_REQUEST,
  };
};

const deleteActivitySuccess = (payload) => {
  return {
    type: DELETE_ACTIVITY_SUCCESS,
    payload: payload,
  };
};

const deleteActivityFaliure = (error) => {
  return {
    type: DELETE_ACTIVITY_FAILURE,
    payload: error,
  };
};

const deleteActivity = (mvtId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteActivityRequest());
    let url = `${baseUrl}/mvt?id=${mvtId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteActivitySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteActivityFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const updateActivityRequest = () => {
  return {
    type: UPDATE_ACTIVITY_REQUEST,
  };
};

const updateActivitySuccess = (payload) => {
  return {
    type: UPDATE_ACTIVITY_SUCCESS,
    payload: payload,
  };
};

const updateActivityFaliure = (error) => {
  return {
    type: UPDATE_ACTIVITY_FAILURE,
    payload: error,
  };
};

const updateActivity = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateActivityRequest());
    let url = `${baseUrl}/mvt`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateActivitySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateActivityFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  getAllocatedActivity,
  getCreatedActivity,
  createActivity,
  deleteActivity,
  updateActivity,
};
