export const GET_DIRECT_DEMAND_REQUEST = "GET_DIRECT_DEMAND_REQUEST";
export const GET_DIRECT_DEMAND_SUCCESS = "GET_DIRECT_DEMAND_SUCCESS";
export const GET_DIRECT_DEMAND_FAILURE = "GET_DIRECT_DEMAND_FAILURE";

export const CREATE_DIRECT_DEMAND_REQUEST = "CREATE_DIRECT_DEMAND_REQUEST";
export const CREATE_DIRECT_DEMAND_SUCCESS = "CREATE_DIRECT_DEMAND_SUCCESS";
export const CREATE_DIRECT_DEMAND_FAILURE = "CREATE_DIRECT_DEMAND_FAILURE";

export const UPLOAD_EXCEL_FOR_DEMAND_REQUEST =
  "UPLOAD_EXCEL_FOR_DEMAND_REQUEST";
export const UPLOAD_EXCEL_FOR_DEMAND_SUCCESS =
  "UPLOAD_EXCEL_FOR_DEMAND_SUCCESS";
export const UPLOAD_EXCEL_FOR_DEMAND_FAILURE =
  "UPLOAD_EXCEL_FOR_DEMAND_FAILURE";

export const GET_BINS_FOR_PRINT_REQUEST = "GET_BINS_FOR_PRINT_REQUEST";
export const GET_BINS_FOR_PRINT_SUCCESS = "GET_BINS_FOR_PRINT_SUCCESS";
export const GET_BINS_FOR_PRINT_FAILURE = "GET_BINS_FOR_PRINT_FAILURE";

export const GET_PARTNO_REQUEST = "GET_PARTNO_REQUEST";
export const GET_PARTNO_SUCCESS = "GET_PARTNO_SUCCESS";
export const GET_PARTNO_FAILURE = "GET_PARTNO_FAILURE";

export const GET_PLANT_REQUEST = "GET_PLANT_REQUEST";
export const GET_PLANT_SUCCESS = "GET_PLANT_SUCCESS";
export const GET_PLANT_FAILURE = "GET_PLANT_FAILURE";

export const POST_CREATEASN_REQUEST = "POST_CREATEASN_REQUEST";
export const POST_CREATEASN_SUCCESS = "POST_CREATEASN_SUCCESS";
export const POST_CREATEASN_FAILURE = "POST_CREATEASN_FAILURE";

export const POST_CALCULATE_PACKAGE_DETAILS_REQUEST =
  "POST_CALCULATE_PACKAGE_DETAILS_REQUEST";
export const POST_CALCULATE_PACKAGE_DETAILS_SUCCESS =
  "POST_CALCULATE_PACKAGE_DETAILS_SUCCESS";
export const POST_CALCULATE_PACKAGE_DETAILS_FAILURE =
  "POST_CALCULATE_PACKAGE_DETAILS_FAILURE";

export const GET_CALCULATE_PACKAGE_WEIGHT_REQUEST =
  "GET_CALCULATE_PACKAGE_WEIGHT_REQUEST";
export const GET_CALCULATE_PACKAGE_WEIGHT_SUCCESS =
  "GET_CALCULATE_PACKAGE_WEIGHT_SUCCESS";
export const GET_CALCULATE_PACKAGE_WEIGHT_FAILURE =
  "GET_CALCULATE_PACKAGE_WEIGHT_FAILURE";

export const GET_PACKAGE_TYPE_REQUEST = "GET_PACKAGE_TYPE_REQUEST";
export const GET_PACKAGE_TYPE_SUCCESS = "GET_PACKAGE_TYPE_SUCCESS";
export const GET_PACKAGE_TYPE_FAILURE = "GET_PACKAGE_TYPE_FAILURE";

export const GET_DELIVERY_PLANT_REQUEST = "GET_DELIVERY_PLANT_REQUEST";
export const GET_DELIVERY_PLANT_SUCCESS = "GET_DELIVERY_PLANT_SUCCESS";
export const GET_DELIVERY_PLANT_FAILURE = "GET_DELIVERY_PLANT_FAILURE";

export const GET_UNLOADING_POINT_REQUEST = "GET_UNLOADING_POINT_REQUEST";
export const GET_UNLOADING_POINT_SUCCESS = "GET_UNLOADING_POINT_SUCCESS";
export const GET_UNLOADING_POINT_FAILURE = "GET_UNLOADING_POINT_FAILURE";

export const UPDATE_ACTUAL_QTY_REQUEST = "UPDATE_ACTUAL_QTY_REQUEST";
export const UPDATE_ACTUAL_QTY_SUCCESS = "UPDATE_ACTUAL_QTY_SUCCESS";
export const UPDATE_ACTUAL_QTY_FAILURE = "UPDATE_ACTUAL_QTY_FAILURE";

export const GET_PARTNO_FOR_PLANNER_REQUEST = "GET_PARTNO_FOR_PLANNER_REQUEST";
export const GET_PARTNO_FOR_PLANNER_SUCCESS = "GET_PARTNO_FOR_PLANNER_SUCCESS";
export const GET_PARTNO_FOR_PLANNER_FAILURE = "GET_PARTNO_FOR_PLANNER_FAILURE";

export const GET_VENDORS_REQUEST = "GET_VENDORS_REQUEST";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS_FAILURE = "GET_VENDORS_FAILURE";

export const GET_ITEMS_FOR_ASN_REQUEST = "GET_ITEMS_FOR_ASN_REQUEST";
export const GET_ITEMS_FOR_ASN_SUCCESS = "GET_ITEMS_FOR_ASN_SUCCESS";
export const GET_ITEMS_FOR_ASN_FAILURE = "GET_ITEMS_FOR_ASN_FAILURE";

export const GET_PURCHASE_ORDER_FOR_DEMAND_REQUEST =
  "GET_PURCHASE_ORDER_FOR_DEMAND_REQUEST";
export const GET_PURCHASE_ORDER_FOR_DEMAND_SUCCESS =
  "GET_PURCHASE_ORDER_FOR_DEMAND_SUCCESS";
export const GET_PURCHASE_ORDER_FOR_DEMAND_FAILURE =
  "GET_PURCHASE_ORDER_FOR_DEMAND_FAILURE";

export const GET_ITEM_CODE_BY_PO_REQUEST = "GET_ITEM_CODE_BY_PO_REQUEST";
export const GET_ITEM_CODE_BY_PO_SUCCESS = "GET_ITEM_CODE_BY_PO_SUCCESS";
export const GET_ITEM_CODE_BY_PO_FAILURE = "GET_ITEM_CODE_BY_PO_FAILURE";
