export const GET_CATEGORYITEMMAPPING_REQUEST =
  "GET_CATEGORYITEMMAPPING_REQUEST";
export const GET_CATEGORYITEMMAPPING_SUCCESS =
  "GET_CATEGORYITEMMAPPING_SUCCESS";
export const GET_CATEGORYITEMMAPPING_FAILURE =
  "GET_CATEGORYITEMMAPPING_FAILURE";

export const GET_WAREHOUSES_BY_MOVEMENT_REQUEST =
  "GET_WAREHOUSES_BY_MOVEMENT_REQUEST";
export const GET_WAREHOUSES_BY_MOVEMENT_SUCCESS =
  "GET_WAREHOUSES_BY_MOVEMENT_SUCCESS";
export const GET_WAREHOUSES_BY_MOVEMENT_FAILURE =
  "GET_WAREHOUSES_BY_MOVEMENT_FAILURE";

export const CREATE_CATEGORYITEMMAPPING_REQUEST =
  "CREATE_CATEGORYITEMMAPPING_REQUEST";
export const CREATE_CATEGORYITEMMAPPING_SUCCESS =
  "CREATE_CATEGORYITEMMAPPING_SUCCESS";
export const CREATE_CATEGORYITEMMAPPING_FAILURE =
  "CREATE_CATEGORYITEMMAPPING_FAILURE";

export const UPDATE_CATEGORYITEMMAPPING_REQUEST =
  "UPDATE_CATEGORYITEMMAPPING_REQUEST";
export const UPDATE_CATEGORYITEMMAPPING_SUCCESS =
  "UPDATE_CATEGORYITEMMAPPING_SUCCESS";
export const UPDATE_CATEGORYITEMMAPPING_FAILURE =
  "UPDATE_CATEGORYITEMMAPPING_FAILURE";

export const GET_CATEGORYITEMMAPPING_WITH_PAGINATION_REQUEST =
  "GET_CATEGORYITEMMAPPING_WITH_PAGINATION_REQUEST";
export const GET_CATEGORYITEMMAPPING_WITH_PAGINATION_SUCCESS =
  "GET_CATEGORYITEMMAPPING_WITH_PAGINATION_SUCCESS";
export const GET_CATEGORYITEMMAPPING_WITH_PAGINATION_FAILURE =
  "GET_CATEGORYITEMMAPPING_WITH_PAGINATION_FAILURE";
