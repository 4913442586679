// Dashboard components
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

const EditButton = ({ onClick, disabled, tooltip, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <MDButton
        onClick={onClick}
        disabled={disabled}
        variant="gradient"
        iconOnly
        type="button"
        {...props}
        color={props?.style?.backgroundColor ? null : "warning"}
        style={
          disabled
            ? { background: "grey", color: "white",marginLeft:"2px"}
            : props?.style
        }
      >
        <Icon>edit_sharp</Icon>
      </MDButton>
    </Tooltip>
  );
};

export default EditButton;
