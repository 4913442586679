import {
  GET_UNPLANNED_ISSUE_FAILURE,
  GET_UNPLANNED_ISSUE_REQUEST,
  GET_UNPLANNED_ISSUE_SUCCESS,
  GET_ITEMSBYLOCATION_REQUEST,
  GET_ITEMSBYLOCATION_SUCCESS,
  GET_ITEMSBYLOCATION_FAILURE,
  GET_SUIDS_BY_LOCATION_AND_ITEM_REQUEST,
  GET_SUIDS_BY_LOCATION_AND_ITEM_SUCCESS,
  GET_SUIDS_BY_LOCATION_AND_ITEM_FAILURE,
  GET_SUIDS_BY_DOCUMENT_NO_REQUEST,
  GET_SUIDS_BY_DOCUMENT_NO_SUCCESS,
  GET_SUIDS_BY_DOCUMENT_NO_FAILURE,
  GET_ACC_USAGE_REQUEST,
  GET_ACC_USAGE_SUCCESS,
  GET_ACC_USAGE_FAILURE,
  GET_CC_USAGE_SUCCESS,
  GET_CC_USAGE_REQUEST,
  GET_CC_USAGE_FAILURE,
} from "./unplannedIssueTypes";

const initialState = {
  loading: false,
  unplannedIssues: [],
  error: "",
};

const getUnplannedIssueReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNPLANNED_ISSUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_UNPLANNED_ISSUE_SUCCESS:
      return {
        loading: false,
        unplannedIssues: action.payload,
        error: "",
      };
    case GET_UNPLANNED_ISSUE_FAILURE:
      return {
        loading: false,
        unplannedIssues: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForAcc = {
  loading: false,
  accType: [],
  error: "",
};

const getAccTypeReducer = (state = initialStateForAcc, action) => {
  switch (action.type) {
    case GET_ACC_USAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACC_USAGE_SUCCESS:
      return {
        loading: false,
        accType: action.payload,
        error: "",
      };
    case GET_ACC_USAGE_FAILURE:
      return {
        loading: false,
        accType: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForCc = {
  loading: false,
  ccType: [],
  error: "",
};

const getCcTypeReducer = (state = initialStateForCc, action) => {
  switch (action.type) {
    case GET_CC_USAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CC_USAGE_SUCCESS:
      return {
        loading: false,
        ccType: action.payload,
        error: "",
      };
    case GET_CC_USAGE_FAILURE:
      return {
        loading: false,
        ccType: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForItemsByLocation = {
  loading: false,
  itemsByLocation: [],
  error: "",
};

const getItemsByLocationReducer = (
  state = initialStateForItemsByLocation,
  action
) => {
  switch (action.type) {
    case GET_ITEMSBYLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMSBYLOCATION_SUCCESS:
      return {
        loading: false,
        itemsByLocation: action.payload,
        error: "",
      };
    case GET_ITEMSBYLOCATION_FAILURE:
      return {
        loading: false,
        itemsByLocation: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSuidsBylocationAndItem = {
  loading: false,
  suidsByLocationAndItem: [],
  error: "",
};

const getSuidsByLocationAndItemReducer = (
  state = initialStateForSuidsBylocationAndItem,
  action
) => {
  switch (action.type) {
    case GET_SUIDS_BY_LOCATION_AND_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUIDS_BY_LOCATION_AND_ITEM_SUCCESS:
      return {
        loading: false,
        suidsByLocationAndItem: action.payload,
        error: "",
      };
    case GET_SUIDS_BY_LOCATION_AND_ITEM_FAILURE:
      return {
        loading: false,
        suidsByLocationAndItem: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSuidsByDocumentNo = {
  loading: false,
  suidsByDocumentNo: [],
  error: "",
};

const getSuidsByDocumentNoReducer = (
  state = initialStateForSuidsByDocumentNo,
  action
) => {
  switch (action.type) {
    case GET_SUIDS_BY_DOCUMENT_NO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUIDS_BY_DOCUMENT_NO_SUCCESS:
      return {
        loading: false,
        suidsByDocumentNo: action.payload,
        error: "",
      };
    case GET_SUIDS_BY_DOCUMENT_NO_FAILURE:
      return {
        loading: false,
        suidsByDocumentNo: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getUnplannedIssueReducer,
  getItemsByLocationReducer,
  getSuidsByLocationAndItemReducer,
  getSuidsByDocumentNoReducer,
  getAccTypeReducer,
  getCcTypeReducer,
};
