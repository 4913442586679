import axios from "axios";
import Config from "../../../config/index";
import {
  GET_FAILING_MINIMUM_TOP_10_FAILURE,
  GET_FAILING_MINIMUM_TOP_10_REQUEST,
  GET_FAILING_MINIMUM_TOP_10_SUCCESS,
  GET_REJECTED_TOP_10_REQUEST,
  GET_REJECTED_TOP_10_SUCCESS,
  GET_REJECTED_TOP_10_FAILURE,
  GET_RTV_TOP_10_REQUEST,
  GET_RTV_TOP_10_SUCCESS,
  GET_RTV_TOP_10_FAILURE,
  GET_TO_TOP_10_REQUEST,
  GET_TO_TOP_10_SUCCESS,
  GET_TO_TOP_10_FAILURE,
} from "./top10Types";

let baseUrl = Config.baseUrl;

const getFailingMinimumTop10Request = () => {
  return {
    type: GET_FAILING_MINIMUM_TOP_10_REQUEST,
  };
};

const getFailingMinimumTop10Success = (payload) => {
  return {
    type: GET_FAILING_MINIMUM_TOP_10_SUCCESS,
    payload: payload,
  };
};

const getFailingMinimumTop10Failure = (error) => {
  return {
    type: GET_FAILING_MINIMUM_TOP_10_FAILURE,
    payload: error,
  };
};

const getFailingMinimumTop10 = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getFailingMinimumTop10Request());
    let url = `${baseUrl}/reports/top_ten_reports/failing_minimum`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getFailingMinimumTop10Success(response.data));
      })
      .catch((err) => {
        dispatch(getFailingMinimumTop10Failure(err.message));
      });
  };
};

const getRejectedTop10Request = () => {
  return {
    type: GET_REJECTED_TOP_10_REQUEST,
  };
};

const getRejectedTop10Success = (payload) => {
  return {
    type: GET_REJECTED_TOP_10_SUCCESS,
    payload: payload,
  };
};

const getRejectedTop10Failure = (error) => {
  return {
    type: GET_REJECTED_TOP_10_FAILURE,
    payload: error,
  };
};

const getRejectedTop10 = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRejectedTop10Request());
    let url = `${baseUrl}/reports/top_ten_reports/rejected`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRejectedTop10Success(response.data));
      })
      .catch((err) => {
        dispatch(getRejectedTop10Failure(err.message));
      });
  };
};

const getRTVTop10Request = () => {
  return {
    type: GET_RTV_TOP_10_REQUEST,
  };
};

const getRTVTop10Success = (payload) => {
  return {
    type: GET_RTV_TOP_10_SUCCESS,
    payload: payload,
  };
};

const getRTVTop10Failure = (error) => {
  return {
    type: GET_RTV_TOP_10_FAILURE,
    payload: error,
  };
};

const getRTVTop10 = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRTVTop10Request());
    let url = `${baseUrl}/reports/top_ten_reports/rtv`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRTVTop10Success(response.data));
      })
      .catch((err) => {
        dispatch(getRTVTop10Failure(err.message));
      });
  };
};

const getTOTop10Request = () => {
  return {
    type: GET_TO_TOP_10_REQUEST,
  };
};

const getTOTop10Success = (payload) => {
  return {
    type: GET_TO_TOP_10_SUCCESS,
    payload: payload,
  };
};

const getTOTop10Failure = (error) => {
  return {
    type: GET_TO_TOP_10_FAILURE,
    payload: error,
  };
};

const getTOTop10 = (withinWH, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getTOTop10Request());
    let url = `${baseUrl}/reports/top_ten_reports/to?withinWH=${withinWH}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getTOTop10Success(response.data));
      })
      .catch((err) => {
        dispatch(getTOTop10Failure(err.message));
      });
  };
};

export { getFailingMinimumTop10, getRejectedTop10, getRTVTop10, getTOTop10 };
