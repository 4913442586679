
  import axios from "axios";
  import Config from "../../config/index";
import { GET_CONSUMPTION_PENDING_FAILURE, GET_CONSUMPTION_PENDING_REQUEST, GET_CONSUMPTION_PENDING_SUCCESS } from "./consumptionPendingTypes";
  
  let baseUrl = Config.baseUrl;
  
  const getConsumptionPendingRequest = () => {
    return {
      type: GET_CONSUMPTION_PENDING_REQUEST,
    };
  };
  
  const getConsumptionPendingSuccess = (payload) => {
    return {
      type: GET_CONSUMPTION_PENDING_SUCCESS,
      payload: payload,
    };
  };
  
  const getConsumptionPendingFailure = (error) => {
    return {
      type: GET_CONSUMPTION_PENDING_FAILURE,
      payload: error,
    };
  };
  
  const getConsumptionPending = (jwtToken) => {
    let headers = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    return (dispatch) => {
      dispatch(getConsumptionPendingRequest());
      let url = `${baseUrl}/consumption_pending`;
      axios
        .get(url, headers)
        .then((response) => {
          dispatch(getConsumptionPendingSuccess(response.data.data));
        })
        .catch((err) => {
          dispatch(getConsumptionPendingFailure(err.message));
        });
    };
  };
  
  export { getConsumptionPending };
  