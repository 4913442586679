/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

// Functions from store
import { getSubModulesByRoleId, getCompletedCycleCount } from "../../../store";

//  some date function
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";
import { Backdrop } from "@mui/material";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const CompletedCycleCount = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getCompletedCycleCount,
  completedCycleCountReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let cycleCountModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ae5d7837-c16d-4005-ac6d-00cf1f48cb31"
  );

  let viewAccess = cycleCountModule
    ? cycleCountModule.actionId.includes("b43e6fd2-816f-41a8-ad32-757530a52053")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getCompletedCycleCount(pageNo, rowsPerPage, searchObject);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Cycle Count Code", accessor: "cycleCountCode" },
      { Header: "Cycle Count Type", accessor: "cycleCountType" },
      {
        Header: "Start Date",
        accessor: "startDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      { Header: "Category", accessor: "category" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Location", accessor: "location" },
      { Header: "WMS Qty", accessor: "availableQty" },
      { Header: "Reported Qty", accessor: "actualQty" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempCompletedCycleCount = [];
    let data = completedCycleCountReducer.completedCycleCount.data
      ? completedCycleCountReducer.completedCycleCount.data
      : [];
    setPaginationObj(
      completedCycleCountReducer?.completedCycleCount?.pagination
    );
    setLoading(completedCycleCountReducer.loading);

    data.map((count) => {
      let itemDescription = count.itemDescription;
      let len = desLength;
      const obj = {
        cycleCountCode: count.cycleCountCode,
        cycleCountType: count.cycleCountType,
        category: count.categoryName,
        
        itemCode: count.itemCode ? `${count.itemCode}` : "",
        itemDescription: `${itemDescription ? itemDescription.substring(0, len) : ""}`,
        location: count.locationCode,
        startDate: count.startDate,
        endDate: count.endDate,
        availableQty: count.availableQty,
        actualQty: count.actualQty,
      };
      tempCompletedCycleCount.push(obj);
    });
    viewAccess && setState({ ...state, rows: tempCompletedCycleCount });
  }, [completedCycleCountReducer]);

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    completedCycleCountReducer: state.getCompletedCycleCountReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getCompletedCycleCount: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getCompletedCycleCount(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedCycleCount);
