/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getSubModulesByRoleId,
  getSuids,
  getWarehouseOccupancy,
  getWarehouses,
} from "../../../store";

import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const WarehouseOccupancy = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  warehouses,
  getWarehouses,
  getLevel1ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getWarehouseOccupancyReducer,
  getLevel4ByLevelReducer,
  getLevel4ByLevel,
  getWarehouseOccupancy,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getWarehouses(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let warehouseOccupancyModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "3a27839d-1042-4fa3-85e1-6a89cd7840c3"
  );

  let viewWarehouseOccupancy = warehouseOccupancyModule
    ? warehouseOccupancyModule.actionId.includes(
        "5f6af518-e5b4-4609-aede-49f3efa4abe9"
      )
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "Uom", accessor: "uom" },
      { Header: "SKU", accessor: "sku" },
      { Header: "SUID", accessor: "suid" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Zone", accessor: "zone" },
      { Header: "Section", accessor: "section" },
      { Header: "Partition", accessor: "partition" },
      { Header: "Location", accessor: "location" },
      { Header: "Created Date", accessor: "createdDate" }, 
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getWarehouseOccupancyReducer.warehouseOccupancy.data
      ? getWarehouseOccupancyReducer.warehouseOccupancy.data
      : [];
    setLoading(getWarehouseOccupancyReducer.loading);
    data?.map((finishedGoods) => {
      const formatDate = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        itemCode: finishedGoods.itemCode,
        qty: `${finishedGoods.qty}`,
        uom: `${finishedGoods.uom}`,
        suid: finishedGoods.suid,
        sku: finishedGoods.sku,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        lotSerialNo: `${
          finishedGoods.lotNumber ? finishedGoods.lotNumber : "NA"
        } / ${finishedGoods.serialNumber ? finishedGoods.serialNumber : "NA"}`,
        warehouse: finishedGoods.warehouseCode,
        zone: finishedGoods.zoneCode,
        section: finishedGoods.sectionCode,
        partition: finishedGoods.partitionCode,
        location: finishedGoods.locationCode,
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewWarehouseOccupancy && setState({ ...state, rows: tempFinishedGoods });
  }, [getWarehouseOccupancyReducer]);

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    { label: "qty", key: "qty" },
    {
      label: "suid",
      key: "suid",
    },
    { label: "LOT/Serial No", key: "lotSerialNo" },
    { label: "warehouse", key: "warehouse" },
    { label: "zone", key: "zone" },
    { label: "section", key: "section" },
    { label: "partition", key: "partition" },
    { label: "location", key: "location" },
  ];

  const csvLink = {
    filename: "Warehouse Occupancy Report.csv",
    headers: headers,
    data: rows,
  };

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
        key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);
  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];
    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [selectedOptions, setSelectedOptions] = useState({
    warehouse: null,
    zone: null,
    section: null,
    rack: null,
    location: null,
  });

  const handleChange = (level, newValue) => {
    setSelectedOptions((prevState) => {
      const newState = { ...prevState, [level]: newValue };
      if (level === "warehouse") {
        newState.zone = null;
        newState.section = null;
        newState.rack = null;
        newState.location = null;
      } else if (level === "zone") {
        newState.section = null;
        newState.rack = null;
        newState.location = null;
      } else if (level === "section") {
        newState.rack = null;
        newState.location = null;
      } else if (level === "rack") {
        newState.location = null;
      }
      return newState;
    });
  };

  const fetchLevelData = useCallback(() => {
    const { warehouse, zone, section, rack } = selectedOptions;
    if (warehouse) getLevel1ByLevel(warehouse.value, 0, jwtToken);
    if (warehouse && zone)
      getLevel2ByLevel(warehouse.value, zone.value, jwtToken);
    if (warehouse && section)
      getLevel3ByLevel(warehouse.value, section.value, jwtToken);
    if (warehouse && rack)
      getLevel4ByLevel(warehouse.value, rack.value, jwtToken);
  }, [
    selectedOptions,
    getLevel1ByLevel,
    getLevel2ByLevel,
    getLevel3ByLevel,
    getLevel4ByLevel,
    jwtToken,
  ]);

  useEffect(() => {
    fetchLevelData();
  }, [fetchLevelData]);

  const concatenatedLocation = [
    selectedOptions.warehouse?.label,
    selectedOptions.zone?.label,
    selectedOptions.section?.label,
    selectedOptions.rack?.label,
    selectedOptions.location?.label,
  ]
    .filter(Boolean)
    .join("-");

  useEffect(() => {
    getWarehouseOccupancy(concatenatedLocation, jwtToken);
  }, [concatenatedLocation, getWarehouseOccupancy, jwtToken]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-4}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <MDBox>
                      <MDBox
                        display="flex"
                        mt={2}
                        flexDirection={{ xs: "column", lg: "row" }}
                      >
                        {[
                          {
                            label: "Warehouse",
                            value: "warehouse",
                            options: warehouseOptions,
                          },
                          {
                            label: "Zone",
                            value: "zone",
                            options: level1Options,
                            dependsOn: "warehouse",
                          },
                          {
                            label: "Section",
                            value: "section",
                            options: level2Options,
                            dependsOn: "zone",
                          },
                          {
                            label: "Partition",
                            value: "rack",
                            options: level3Options,
                            dependsOn: "section",
                          },
                          {
                            label: "Location",
                            value: "location",
                            options: level4Options,
                            dependsOn: "rack",
                          },
                        ].map(({ label, value, options, dependsOn }) => (
                          <MDBox
                            key={value}
                            width={{ xs: "100%", lg: "350px" }}
                            style={{ marginRight: "10px" }}
                          >
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              {label}
                            </MDTypography>
                            <Select
                              isClearable
                              isDisabled={
                                dependsOn ? !selectedOptions[dependsOn] : false
                              }
                              placeholder="Choose One ..."
                              className="select-css-for-filter"
                              maxMenuHeight={130}
                              autoComplete="off"
                              value={selectedOptions[value]}
                              options={options}
                              onChange={(newValue) =>
                                handleChange(value, newValue)
                              }
                            />
                          </MDBox>
                        ))}
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <MDBox
                      color="text"
                      px={0}
                      display="flex"
                      flexDirection="column"
                    >
                      <MDBox
                        display="flex"
                        marginLeft={-1}
                        marginTop={{ xs: "20px", lg: "42px" }}
                        marginBottom="10px"
                      >
                        <CSVLink {...csvLink}>
                          <Tooltip title="Download All Data">
                            <MDButton
                              type="button"
                              variant="outlined"
                              color="success"
                              iconOnly
                            >
                              <Icon>download</Icon>
                            </MDButton>
                          </Tooltip>
                        </CSVLink>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    hideColumns={["", "asnCode", "vendorCodeName"]}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,

    getSuidsReducer: state.getSuidsReducer,
    warehouses: state.warehouses,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    getWarehouseOccupancyReducer: state.getWarehouseOccupancyReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),

    getSuids: (itemId, jwtToken) => dispatch(getSuids(itemId, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getWarehouseOccupancy: (concatenatedLocation, jwtToken) =>
      dispatch(getWarehouseOccupancy(concatenatedLocation, jwtToken)),
    getLevel1ByLevel: (dWarehouseSelected, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(dWarehouseSelected, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(WarehouseOccupancy);
