/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDCard from "components/MDCard";

// @mui material components
import Card from "@mui/material/Card";
import { ToastContainer, toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";

// import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mui/material/Icon";

// React-Select component and styling
import Select from "react-select";

//  config file
import Config from "../../../config/index";

// Functions from store
import {
  getWarehouses,
  getCategoryLocationMapping,
  getCategoryItemMapping,
  getZonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
  getSubModulesByRoleId,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getAcn,
  createCategoryLocationMapping,
  uploadCsvForItemMapping,
  getItemsByCategory,
  getRelationLocation,
  getWarehousesByMovement,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import { addMinutes, format } from "date-fns";
import { Backdrop } from "@mui/material";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 750 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
  overflow: "scroll",
};

const styleForDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 550 },
  boxShadow: 24,
  p: 2,
  pt: 3,
};
const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};

const styleForCategoryModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 480, md: 700, lg: 800, xl: 800 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const CategoryLocationMapping = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getWarehouses,
  warehouses,
  getCategoryLocationMapping,
  getCategoryLocationMappingReducer,
  getCategoryItemMapping,
  getCategoryItemMappingReducer,
  getZonesByWarehouse,
  getWarehousesByMovement,
  zonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevel,
  getLevel4ByLevelReducer,
  getAcn,
  getAcnReducer,
  createCategoryLocationMapping,
  uploadCsvForItemMapping,
  getRelationLocationReducer,
  getItemsByCategory,
  getRelationLocation,
  getWarehouseByMovementReducer,
  getCategoryItemsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let categoryLocationMapping = modulesData.find(
    (moduleId) => moduleId.moduleId == "4df7bd62-072b-43a6-b68a-a2ce2949e84b"
  );

  let allocateAccess = categoryLocationMapping
    ? categoryLocationMapping.actionId.includes(
        "3cb0af79-44f5-4f7f-b4b7-976f131731f0"
      )
    : null;

  let viewAccess = categoryLocationMapping
    ? categoryLocationMapping.actionId.includes(
        "3482f756-06b6-43f2-8802-2c9def048fcb"
      )
    : null;
  let viewItems = categoryLocationMapping
    ? categoryLocationMapping.actionId.includes(
        "bd27e877-76e0-483a-abd7-e23f43a25b6b"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [zoneIdSelected, setZoneIdSelected] = useState("");
  const [sectionIdSelected, setSectionIdSelected] = useState("");
  const [rackIdSelected, setRackIdSelected] = useState("");
  const [locationIdSelected, setLocationIdSelected] = useState("");

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
    setWarehouseIdSelected("");
  };

  const handleChangeWarehouse = (selectedData) => {
    setWarehouseIdSelected(selectedData);
    setZoneIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const handleZoneIDChange = (selectedRack) => {
    setZoneIdSelected(selectedRack);
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const handleSectionIdChange = (selectedRack) => {
    setSectionIdSelected(selectedRack);
    setRackIdSelected("");
    setLocationIdSelected("");
  };
  const handleRackIdChange = (selectedRack) => {
    setRackIdSelected(selectedRack);
    setLocationIdSelected("");
  };

  const handleLocationIdChange = (selectedLocation) => {
    setLocationIdSelected(selectedLocation);
  };

  useEffect(() => {
    getCategoryLocationMapping(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);
  // useEffect(() => {
  //   getWarehousesByMovement(activityIdSelected?.value,jwtToken);
  // }, [activityIdSelected]);

  useEffect(() => {
    getCategoryItemMapping(jwtToken);
  }, []);

  const [selectedZoneName, setSelectedZoneName] = useState("");

  const handleZoneNameChange = (selectedZoneName) => {
    setSelectedZoneName(selectedZoneName);
    setRackIdSelected("");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "Category Code", accessor: "categoryCode" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Zone", accessor: "zone" },
      { Header: "Section", accessor: "section" },
      { Header: "Rack", accessor: "rack" },
      { Header: "Location", accessor: "location" },
      { Header: "ACN CODE", accessor: "acnCode" },

      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [categoryItemTable, setCategoryItemTable] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns: categoryColumns, rows: categoryRows } = categoryItemTable;

  const [categoryItems, setCategoryItems] = useState("");

  useEffect(() => {
    let tempCategoryItemsObj = [];

    let data = categoryItems ? categoryItems : [];

    data.map((data) => {
      const formatDate = new Date(data.cd);

      const categoryItemsObj = {
        itemCode: data.itemCode,
        itemDescription:
          data.itemDescription === (undefined || "undefined" || null)
            ? "-"
            : data.itemDescription,

        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
      };

      tempCategoryItemsObj.push(categoryItemsObj);
    });

    setCategoryItemTable({ ...categoryItemTable, rows: tempCategoryItemsObj });
  }, [categoryItems]);
  const [openCategoryLineItemsModal, setOpenCategoryLineItemsModal] =
    useState(false);
  const data = getRelationLocationReducer.locations?.data || [];

  const handleOpenCategoryLineItemsModal = () => {
    setOpenCategoryLineItemsModal(true);
  };

  const handleCloseCategoryLineItemsModal = () => {
    setOpenCategoryLineItemsModal(false);
  };

  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    let tempActivityOptions = [];
    let data = getAcnReducer.acn.data ? getAcnReducer.acn.data : [];
    data.map((activity) => {
      tempActivityOptions.push({
        label: activity.acnName,
        value: activity.acnId,
      });
    });
    setActivityOptions(tempActivityOptions);
  }, [getAcnReducer]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [warehouseOptionsByMovement, setWarehouseOptionsByMovement] = useState(
    []
  );
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = getWarehouseByMovementReducer.warehouses.data
      ? getWarehouseByMovementReducer.warehouses.data
      : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
      });
    });
    setWarehouseOptionsByMovement(tempWarehouseOptions);
  }, [getWarehouseByMovementReducer]);

  const [categoryCodeOptions, setCategoryCodeOptions] = useState([]);

  useEffect(() => {
    let tempCategoryCodeOptions = [];
    let data = getCategoryItemMappingReducer.categoryItemMapping.data
      ? getCategoryItemMappingReducer.categoryItemMapping.data
      : [];
    data.map((item) => {
      tempCategoryCodeOptions.push({
        label: item.categoryCode,
        value: item.categoryId,
      });
    });
    let arr = tempCategoryCodeOptions.filter(
      (category) => category.value !== 1
    );

    setCategoryCodeOptions(arr);
  }, [getCategoryItemMappingReducer]);

  const [zoneOptions, setZoneOptions] = useState([]);

  useEffect(() => {
    let tempZonesByWarehouse = [];
    let data = zonesByWarehouse.zones.data ? zonesByWarehouse.zones.data : [];
    data.map((zone) => {
      tempZonesByWarehouse.push({
        label: zone.zoneName,
        value: zone.id,
      });
    });
    setZoneOptions(tempZonesByWarehouse);
  }, [zonesByWarehouse]);

  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  const [openBinsOptions, setOpenBinsOptions] = useState([]);

  const [allocateAddCategoryModal, setAllocateAddCategoryModal] =
    useState(false);

  const handleCloseAllocateAddCategoryModal = () => {
    setAllocateAddCategoryModal(false);
  };

  const initialValuesForAdding = {
    categoryCode: "",
  };

  const handleOpenAllocateAddModal = () => {
    setAllocateAddCategoryModal(true);
    formikForAdding.setValues(initialValuesForAdding);
    formikForAdding.setTouched({});
    formikForAdding.setErrors({});
  };
  const formikForAdding = useFormik({
    initialValues: initialValuesForAdding,
    // onSubmit: submitForUser,
  });

  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setDeleteCategoryModal(true);
    formikForDelete.setValues(initialValuesForDelete);
    formikForDelete.setTouched({});
    formikForDelete.setErrors({});
  };
  const initialValuesForDelete = {
    categoryCode: "",
  };
  const formikForDelete = useFormik({
    initialValues: initialValuesForDelete,
    // onSubmit: submitForUser,
  });
  const handleCloseDeleteModal = () => {
    setDeleteCategoryModal(false);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempCategoryLocationMapping = [];
    let data = getCategoryLocationMappingReducer.category.data
      ? getCategoryLocationMappingReducer.category.data
      : [];
    setPaginationObj(getCategoryLocationMappingReducer.category?.pagination);
    setLoading(getCategoryLocationMappingReducer.loading);
    data.map((item) => {
      const formatDate = new Date(item.cd);
      let des = item?.itemName;
      let len = desLength;
      const itemObject = {
        privateKey: item.categoryCode,
        categoryCode: item.categoryCode,
        warehouse: item.warehouseCode,
        zone: item.zone,
        section: item.section,
        rack: item.rack,
        location: item.location,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        itemName: des ? des.substring(0, len) : "",
        acnCode: item.acnCode,
        action: (
          <>
            {viewItems && (
              <Tooltip title="View Items">
                <MDButton
                  // disabled={plans.isPublished === 1}
                  variant="gradient"
                  color="info"
                  iconOnly
                  type="button"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    handleOpenCategoryLineItemsModal(item);
                    setCategoryItems(item.items);
                  }}
                >
                  <Icon>visibility_icon</Icon>
                </MDButton>
              </Tooltip>
            )}
          </>
        ),
      };
      tempCategoryLocationMapping.push(itemObject);
    });

    viewAccess && setState({ ...state, rows: tempCategoryLocationMapping });
  }, [getCategoryLocationMappingReducer]);

  const [isSuccessAllocateItem, setIsSuccessAllocateItem] = useState(false);
  const [isErrorAllocateItem, setIsErrorAllocateItem] = useState(false);

  useEffect(() => {
    if (isSuccessAllocateItem) {
      setTimeout(() => {
        setIsSuccessAllocateItem(false);
      }, 3000);
    }
  }, [isSuccessAllocateItem]);

  useEffect(() => {
    if (isErrorAllocateItem) {
      setTimeout(() => {
        setIsErrorAllocateItem(false);
      }, 3000);
    }
  }, [isErrorAllocateItem]);

  const [allocateCategoryModal, setAllocateCategoryModal] = useState(false);
  const handleAllocateCategoryModal = () => {
    setAllocateCategoryModal(!allocateCategoryModal);
    formik.setErrors({});
    setZoneIdSelected("");
    setWarehouseIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };
  const handleCloseAllocateCategoryModal = () => {
    setAllocateCategoryModal(!allocateCategoryModal);
    formik.setErrors({});
    setWarehouseIdSelected("");
    setLocationIdSelected("");
    setActivityIdSelected("");
    setSelectedCategoryCode("");
  };

  const [ActivityMappingData, setActivityMappingData] = useState({});

  const [selectedCategoryCode, setSelectedCategoryCode] = useState("");

  const handleCategoryCodeChange = (selectedCategoryCode) => {
    setSelectedCategoryCode(selectedCategoryCode);
  };

  const [selectedBin, setSelectedBin] = useState("");

  const handleBinChange = (selectedBin) => {
    setSelectedBin(selectedBin);
  };
  const [selectedBinForAdd, setSelectedBinForAdd] = useState("");

  const handleBinChangeForAdd = (selectedBinForAdd) => {
    setSelectedBinForAdd(selectedBinForAdd);
  };

  const [selectedOpenBin, setSelectedOpenBin] = useState(
    ActivityMappingData?.locationName
  );

  useEffect(() => {
    if (ActivityMappingData) {
      setSelectedOpenBin({
        label: ActivityMappingData?.locationName,
        value: ActivityMappingData?.locationName,
      });
    }
  }, [ActivityMappingData]);

  const handleOpenBinChange = (selectedOpenBin) => {
    setSelectedOpenBin(selectedOpenBin);
  };

  const initialValues = {
    categoryCode: "",
    acn: "",
    warehouseCode: "",
    level1: "",
    level2: "",
    level3: "",
    location: "",
  };

  const validationSchema = Yup.object({
    categoryCode: Yup.object().required("Select Category Code"),
    acn: Yup.object().required("Select movement type"),
    warehouseCode: Yup.object().required("Select a warehouse"),
    level1: Yup.object().required("Select a level"),
    // level2: Yup.object().required("Select a level"),
    // level3: Yup.object().required("Select a level"),
    // location: Yup.object().required("Select a location"),
  });

  const [errorMsg, setErrorMsg] = useState("");

  const [allocateButtonName, setAllocateButtonName] = useState("Allocate");

  const onSubmit = async (values, { resetForm }) => {
    setAllocateButtonName("Please wait...");
    const payload = {
      warehouseId: warehouseIdSelected?.value,
      categoryId: selectedCategoryCode.value,
      zoneId: zoneIdSelected.value,
      sectionId: sectionIdSelected.value,
      rackId: rackIdSelected.value,
      locationId: locationIdSelected.value,
      acnId: activityIdSelected.value,
    };

    let res = await createCategoryLocationMapping(payload, jwtToken);
    if (res.status) {
      setIsSuccessAllocateItem(true);
      getOpenBinsByWarehouseAndZone(warehouseIdSelected?.value, zoneIdSelected);
      getCategoryLocationMapping(pageNo, rowsPerPage, searchObject, jwtToken);
      setWarehouseIdSelected("");
      setLocationIdSelected("");
      setActivityIdSelected("");
      setSectionIdSelected("");
      setZoneIdSelected("");
      setRackIdSelected("");
      setSelectedCategoryCode("");
      setTimeout(() => {
        setAllocateButtonName("Allocate");
      }, 2250);
    }
    if (!res.status) {
      setErrorMsg(res?.data?.data?.msg);
      setIsErrorAllocateItem(true);
      setAllocateButtonName("Allocate");
      handleCloseAllocateCategoryModal();
    }
    setSelectedCategoryCode("");
    setSelectedZoneName("");
    setSelectedBin("");
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [isSuccessAddActivityMapping, setIsSuccessAddActivityMapping] =
    useState(false);
  const [isErrorAddActivityMapping, setIsErrorAddActivityMapping] =
    useState(false);

  useEffect(() => {
    if (isSuccessAddActivityMapping) {
      setTimeout(() => {
        setIsSuccessAddActivityMapping(false);
      }, 3000);
    }
  }, [isSuccessAddActivityMapping]);

  useEffect(() => {
    if (isErrorAddActivityMapping) {
      setTimeout(() => {
        setIsErrorAddActivityMapping(false);
      }, 3000);
    }
  }, [isErrorAddActivityMapping]);

  const [addActivityMappingModal, setAddActivityMappingModal] = useState(false);
  const handleAddActivityMappingModal = () => {
    setAddActivityMappingModal(!addActivityMappingModal);
    formikForAdd.setErrors({});
  };
  const handleCloseAddActivityMappingModal = () => {
    setAddActivityMappingModal(!addActivityMappingModal);
    formikForAdd.setErrors({});
  };

  const initialValueForAddActivityMapping = {
    bin: "",
  };

  const validationSchemaForAddActivityMapping = Yup.object({
    bin: Yup.string().required("Select the BIN"),
  });

  const onSubmitForAddActivityMapping = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseIdSelected?.value,
      itemId: ActivityMappingData.itemId,
      zoneId: ActivityMappingData.zoneId,
      locationId: selectedBinForAdd.value,
    };

    setSelectedBinForAdd("");
    resetForm();
  };

  const formikForAdd = useFormik({
    initialValues: initialValueForAddActivityMapping,
    validationSchema: validationSchemaForAddActivityMapping,
    onSubmit: onSubmitForAddActivityMapping,
  });

  const [isSuccessEditActivityMapping, setIsSuccessEditActivityMapping] =
    useState(false);
  const [isErrorEditActivityMapping, setIsErrorEditActivityMapping] =
    useState(false);

  useEffect(() => {
    if (isSuccessEditActivityMapping) {
      setTimeout(() => {
        setIsSuccessEditActivityMapping(false);
      }, 3000);
    }
  }, [isSuccessEditActivityMapping]);

  useEffect(() => {
    if (isErrorEditActivityMapping) {
      setTimeout(() => {
        setIsErrorEditActivityMapping(false);
      }, 3000);
    }
  }, [isErrorEditActivityMapping]);

  const [editActivityMappingModal, setEditActivityMappingModal] =
    useState(false);
  const handleEditActivityMappingModal = () => {
    setEditActivityMappingModal(!editActivityMappingModal);
    formikForEdit.setErrors({});
  };

  const handleCloseEditActivityMappingModal = () => {
    setEditActivityMappingModal(!editActivityMappingModal);
    formikForEdit.setErrors({});
  };
  const initialValueForEditActivityMapping = {
    bin: "",
  };

  const validationSchemaForEditActivityMapping = Yup.object({
    bin: Yup.string().required("Select the BIN"),
  });

  const onSubmitForEditActivityMapping = async (values, reset) => {
    const payload = {
      itemId: ActivityMappingData.itemId,
      warehouseId: warehouseIdSelected?.value,
      zoneId: ActivityMappingData.zoneId,
      locationId: selectedOpenBin?.value,
    };

    // let res = await editBinAllocation(payload, jwtToken);

    // if (res.status) {
    //   setIsSuccessEditActivityMapping(true);
    //   getCategoryLocationMapping(warehouseIdSelected?.value, jwtToken);
    // }
    // if (!res.status) {
    //   setIsErrorEditActivityMapping(true);
    // }
    // setSelectedOpenBin("");
  };
  const formikForEdit = useFormik({
    initialValues: initialValueForEditActivityMapping,
    validationSchema: validationSchemaForEditActivityMapping,
    onSubmit: onSubmitForEditActivityMapping,
    enableReinitialize: true,
  });

  const [isErrorDeleteActivityMapping, setIsErrorDeleteActivityMapping] =
    useState(false);
  useEffect(() => {
    if (isErrorDeleteActivityMapping) {
      setTimeout(() => {
        setIsErrorDeleteActivityMapping(false);
      }, 3000);
    }
  }, [isErrorDeleteActivityMapping]);

  const [openDeleteActivityMappingModal, setOpenDeleteActivityMappingModal] =
    useState(false);

  const handleOpenDeleteActivityMappingModal = (data = {}) => {
    setActivityMappingData(data);
    setOpenDeleteActivityMappingModal(true);
  };

  const handleCloseDeleteActivityMappingModal = () => {
    setOpenDeleteActivityMappingModal(false);
  };

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const onClickDeleteActivityMapping = async () => {
    // let res = await removeBinAllocation(
    //   warehouseIdSelected?.value,
    //   ActivityMappingData.itemId,
    //   ActivityMappingData.zoneId,
    //   ActivityMappingData.locationId,
    //   jwtToken
    // );
    // if (res.status) {
    //   getCategoryLocationMapping(warehouseIdSelected?.value, jwtToken);
    //   setOpenDeleteActivityMappingModal(false);
    // }
    // if (!res.status) {
    //   setDeleteErrorMsg(res.data.response.data.msg);
    //   setIsErrorDeleteActivityMapping(true);
    // }
  };

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };
  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("item_mapping", uploadCsvData);

    // let res = await uploadCsvForItemMapping(data);

    // if (res.status) {
    //   getCategoryLocationMapping(jwtToken);
    //   setUploadCsvFilter(false);
    //   DataUploadSuccessNotification();
    // }

    // if (!res.status) {
    //   DataUploadFailureNotification(res?.data?.data?.msg);
    // }
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <div>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              <MDBox
                color="text"
                px={{ xs: 0.5, sm: 0.5, md: 2, lg: 2, xl: 2 }}
                style={{ marginLeft: "-15px" }}
              >
                {allocateAccess ? (
                  <CreateButton
                    tooltip="Allocate Location"
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleAllocateCategoryModal();
                      getAcn(jwtToken);
                      getWarehouses(jwtToken);
                      getRelationLocation(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                ) : (
                  <CreateButton
                    tooltip="Allocate Location"
                    disabled
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => handleAllocateCategoryModal()}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}
                {/* <Tooltip title="Upload CSV">
                    <MDButton
                      style={{ marginLeft: "1rem" }}
                      // disabled={!uploadItem}
                      type="button"
                      variant="outlined"
                      color="info"
                      iconOnly
                      onClick={uploadCsvFilterHandler}
                    >
                      <Icon>upload</Icon>
                    </MDButton>
                  </Tooltip> */}
              </MDBox>
            </MDBox>

            {uploadCsvFilter && (
              <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                <div>
                  <input
                    className="choose_file"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  />
                  <MDButton
                    disabled={
                      !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                    }
                    variant="contained"
                    color="info"
                    style={{
                      marginLeft: { xs: "1.5rem", lg: "1" },
                      marginTop: "0rem",
                    }}
                    type="button"
                    onClick={uploadCsvHandler}
                  >
                    UPLOAD
                  </MDButton>
                </div>
                <div style={{ marginBottom: "-20px" }}>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    <strong>*</strong> Please upload
                    <strong>".csv"</strong> file only
                  </span>
                </div>
              </div>
            )}
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>

            <Modal
              open={allocateAddCategoryModal}
              onClose={handleCloseAllocateAddCategoryModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    ADD
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAllocateAddCategoryModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForAdding.handleSubmit}
                  >
                    <MDBox mb={1}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        {" "}
                        Category Code
                      </MDTypography>

                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="categoryCode"
                        onBlur={() => {
                          formikForAdding.handleBlur({
                            target: { name: "categoryCode" },
                          });
                        }}
                        // value={selectedUser}
                        // options={userOptions}
                        // onChange={(selectedOption) => {
                        //   handleUserChange(selectedOption);
                        //   formikForAdding.setFieldValue("user", selectedOption);
                        // }}
                      />
                      {formikForAdding.touched.categoryCode &&
                        formikForAdding.errors.categoryCode && (
                          <TextError
                            msg={formikForAdding.errors.categoryCode}
                          />
                        )}
                    </MDBox>
                    {/* 
                <MDBox>
                  <Collapse in={isErrorForAllocate}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorForAllocate(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg}
                    </Alert>
                  </Collapse>
                  <Collapse in={isSuccessForAllocate}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorForAllocate(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Picklist released Successfully
                    </Alert>
                  </Collapse>
                </MDBox> */}

                    <MDBox mt={3}>
                      <MDButton
                        color="success"
                        fullWidth
                        type="submit"
                        disabled={!formikForAdding.isValid}
                      >
                        Add
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openCategoryLineItemsModal}
              onClose={handleCloseCategoryLineItemsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCategoryModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Items
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCategoryLineItemsModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />

                <MDBox>
                  <DataTable
                    table={{ columns: categoryColumns, rows: categoryRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    // errorMsg={errorMsgForLineItemFromApi}
                  />
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={deleteCategoryModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Delete
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseDeleteModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForDelete.handleSubmit}
                  >
                    <MDBox mb={1}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        {" "}
                        Category Code
                      </MDTypography>

                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="user"
                        onBlur={() => {
                          formikForDelete.handleBlur({
                            target: { name: "user" },
                          });
                        }}
                        // value={selectedUser}
                        // options={userOptions}
                        // onChange={(selectedOption) => {
                        //   handleUserChange(selectedOption);
                        //   formikForDelete.setFieldValue("user", selectedOption);
                        // }}
                      />
                      {formikForDelete.touched.user &&
                        formikForDelete.errors.user && (
                          <TextError msg={formikForDelete.errors.user} />
                        )}
                    </MDBox>

                    <MDBox mt={3}>
                      <MDButton
                        color="error"
                        fullWidth
                        type="submit"
                        disabled={!formikForDelete.isValid}
                      >
                        Delete
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={allocateCategoryModal}
              onClose={handleCloseAllocateCategoryModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Allocate
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAllocateCategoryModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Category Code
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="categoryCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "categoryCode" },
                              });
                            }}
                            value={selectedCategoryCode}
                            options={categoryCodeOptions}
                            onChange={(selectedOption) => {
                              handleCategoryCodeChange(selectedOption);
                              formik.setFieldValue(
                                "categoryCode",
                                selectedOption
                              );
                            }}
                          />

                          {formik.touched.categoryCode &&
                            formik.errors.categoryCode && (
                              <TextError msg={formik.errors.categoryCode} />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1} ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Movement{" "}
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="acn"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "acn" },
                              });
                            }}
                            value={activityIdSelected}
                            options={activityOptions}
                            // onChange={(selectedOption) => {
                            //   handleActivityIdChange(selectedOption);
                            //   formik.setFieldValue("acn", selectedOption);
                            // }}
                            onChange={(selectedOption) => {
                              handleActivityIdChange(selectedOption);
                              formik.setFieldValue("acn", selectedOption);
                              getWarehousesByMovement(
                                selectedOption?.value,

                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.acn && formik.errors.acn && (
                            <TextError msg={formik.errors.acn} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Warehouse Code{" "}
                          </MDTypography>

                          <Select
                            isDisabled={activityIdSelected ? false : true}
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="warehouseCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "warehouseCode" },
                              });
                            }}
                            value={warehouseIdSelected}
                            options={warehouseOptionsByMovement}
                            onChange={(selectedOption) => {
                              handleChangeWarehouse(selectedOption);
                              getZonesByWarehouse(selectedOption?.value);
                              formik.setFieldValue(
                                "warehouseCode",
                                selectedOption
                              );
                              getLevel1ByLevel(
                                selectedOption?.value,
                                0,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.warehouseCode &&
                            formik.errors.warehouseCode && (
                              <TextError msg={formik.errors.warehouseCode} />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Level 1 :-
                            {data.length > 0
                              ? data[0].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={warehouseIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="level1"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "level1" },
                              });
                            }}
                            value={zoneIdSelected}
                            options={level1Options}
                            onChange={(selectedOption) => {
                              handleZoneIDChange(selectedOption);
                              formik.setFieldValue("level1", selectedOption);
                              getLevel2ByLevel(
                                warehouseIdSelected?.value,
                                selectedOption?.value,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.level1 && formik.errors.level1 && (
                            <TextError msg={formik.errors.level1} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Level 2 :-
                            {data.length > 0
                              ? data[1].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={zoneIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="level2"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "level2" },
                              });
                            }}
                            value={sectionIdSelected}
                            options={level2Options}
                            onChange={(selectedOption) => {
                              handleSectionIdChange(selectedOption);
                              formik.setFieldValue("level2", selectedOption);
                              getLevel3ByLevel(
                                warehouseIdSelected?.value,
                                selectedOption?.value,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.level2 && formik.errors.level2 && (
                            <TextError msg={formik.errors.level2} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            Level 3 :-
                            {data.length > 0
                              ? data[2].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={sectionIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose One..."
                            name="level3"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "level3" },
                              });
                            }}
                            value={rackIdSelected}
                            options={level3Options}
                            onChange={(selectedOption) => {
                              handleRackIdChange(selectedOption);
                              formik.setFieldValue("level3", selectedOption);
                              getLevel4ByLevel(
                                warehouseIdSelected?.value,
                                selectedOption?.value,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.level3 && formik.errors.level3 && (
                            <TextError msg={formik.errors.level3} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            {data.length > 0
                              ? data[3].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={rackIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="location"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "location" },
                              });
                            }}
                            value={locationIdSelected}
                            options={level4Options}
                            onChange={(selectedOption) => {
                              handleLocationIdChange(selectedOption);
                              formik.setFieldValue("location", selectedOption);
                            }}
                          />
                          {formik.touched.location &&
                            formik.errors.location && (
                              <TextError msg={formik.errors.location} />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12}>
                        <Collapse in={isErrorAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Location Allocated successfully
                          </Alert>
                        </Collapse>
                      </Grid>

                      <Grid item xs={12}>
                        <MDBox mt={1}>
                          <MDButton
                            color="info"
                            fullWidth
                            type="submit"
                            disabled={
                              !formik.isValid ||
                              selectedCategoryCode == "" ||
                              activityIdSelected == "" ||
                              warehouseIdSelected == "" ||
                              zoneIdSelected == "" ||
                              allocateButtonName === "Please wait..."
                            }
                          >
                            {allocateButtonName}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      {/* <Footer /> */}
    </div>
    // </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    warehouses: state.warehouses,
    getCategoryLocationMappingReducer: state.getCategoryLocationMappingReducer,
    getCategoryItemMappingReducer: state.getCategoryItemMappingReducer,
    zonesByWarehouse: state.zonesByWarehouse,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    getAcnReducer: state.getAcnReducer,
    openBinsByWarehouse: state.openBinsByWarehouse,
    getCategoryItemsReducer: state.getCategoryItemsReducer,
    getRelationLocationReducer: state.getRelationLocationReducer,
    getWarehouseByMovementReducer: state.getWarehouseByMovementReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getCategoryLocationMapping: (pageNo, rowsPerPage, searchjwtToken) =>
      dispatch(getCategoryLocationMapping(pageNo, rowsPerPage, searchjwtToken)),
    getCategoryItemMapping: (jwtToken) =>
      dispatch(getCategoryItemMapping(jwtToken)),
    getZonesByWarehouse: (id, jwtToken) =>
      dispatch(getZonesByWarehouse(id, jwtToken)),
    getWarehousesByMovement: (activityIdSelected, jwtToken) =>
      dispatch(getWarehousesByMovement(activityIdSelected, jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getAcn: (jwtToken) => dispatch(getAcn(jwtToken)),
    getOpenBinsByWarehouseAndZone: (id, zoneId, jwtToken) =>
      dispatch(getOpenBinsByWarehouseAndZone(id, zoneId, jwtToken)),
    createCategoryLocationMapping: (payload, jwtToken) =>
      dispatch(createCategoryLocationMapping(payload, jwtToken)),
    uploadCsvForItemMapping: (payload, jwtToken) =>
      dispatch(uploadCsvForItemMapping(payload, jwtToken)),
    getItemsByCategory: (categoryId, jwtToken) =>
      dispatch(getItemsByCategory(categoryId, jwtToken)),

    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryLocationMapping);
