/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Formik, Form, Field, FieldArray, useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Clear";
import * as Yup from "yup";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { ToastContainer, toast } from "react-toastify";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { CSVLink } from "react-csv";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  // getAssemblyMaster,
  createStationAndItemMapping,
  getStationsByAssemblyType,
  getItemMaster,
  getItemsByStation,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getSubModulesByRoleId,
  getAssemblyMaster,
  deleteStations,
  deleteStationsForView,
  uploadForStation,
  updateStationView,
} from "../../store";
// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";
import TextError from "utils/TextError";
import CreateButton from "components/standardized-components/CreateButton";
import ViewButton from "components/standardized-components/ViewButton";
import { Backdrop, CircularProgress } from "@mui/material";
import DeleteButton from "components/standardized-components/DeleteButton";
import { addMinutes, format } from "date-fns";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import EditButton from "components/standardized-components/EditButton";
import MDInput from "components/MDInput";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const styleForView = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForCreateBom = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1450 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const StationAndItemMapping = ({
  getStationsByAssemblyType,
  getStationsByAssemblyTypeReducer,
  uploadForStation,
  getAssemblyMaster,
  getAssemblyMasterReducer,
  createStationAndItemMapping,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getLevel4ByLevel,
  itemMaster,
  getLevel3ByLevelReducer,
  getItemsByStationReducer,
  getLevel4ByLevelReducer,
  getLevel3ByLevel,
  getItemMaster,
  getItemsByStation,
  deleteStationsForView,
  updateStationView,
  deleteStations,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [zoneIdSelected, setZoneIdSelected] = useState("");
  const [sectionIdSelected, setSectionIdSelected] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getAssemblyMaster(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let stationModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "a902b908-892e-42bd-b525-8b743d12cfe7"
  );

  let viewStations = stationModule
    ? stationModule.actionId.includes("19ed34d1-8ead-4b97-848d-95a393deab4d")
    : null;
  let viewMappedItems = stationModule
    ? stationModule.actionId.includes("f37fa51c-3157-4a1f-b209-7a13fec9f95e")
    : null;
  let createAccess = stationModule
    ? stationModule.actionId.includes("706095c0-9968-4467-aed2-c6843218321e")
    : null;
  let deleteAccess = stationModule
    ? stationModule.actionId.includes("2db1d59b-0e13-4c1d-852c-71747826269e")
    : null;
  let uploadAccess = stationModule
    ? stationModule.actionId.includes("bacfe959-3bea-4355-804d-88e4cebc6815")
    : null;
  let downloadAccess = stationModule
    ? stationModule.actionId.includes("9ca6f3dd-9d72-4505-8d3b-367c5e467bdb")
    : null;
  let deleteAccessForMapping = stationModule
    ? stationModule.actionId.includes("345add1e-69f0-49aa-85b4-08e068c4a7e6")
    : null;



  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  const [state, setState] = useState({
    columns: [
      { Header: "Assembly", accessor: "assembly" },
      { Header: "Assembly Type", accessor: "assemblyType" },
      { Header: "Assembly Work Area", accessor: "zone" },
      { Header: "Station", accessor: "station" },
      { Header: "Station Work Area", accessor: "section" },
      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        uom: item.uom,
        categoryId: item.categoryId,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [isSuccessForCreateBOM, setIsSuccessForCreateBOM] = useState(false);
  const [isErrorForCreateBOM, setIsErrorForCreateBOM] = useState(false);

  useEffect(() => {
    if (isSuccessForCreateBOM) {
      setTimeout(() => {
        setIsSuccessForCreateBOM(false);
      }, 3000);
    }
  }, [isSuccessForCreateBOM]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];
    data.map((level) => {
      tempLevelOptions.push({
        label: `${level.locationName} / ${level.parentName}`,
        value: level.locationId,
        parentId: level.parentId,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);
  const [singleDataForDelete, setSingleDataForDelete] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (data) => {
    setOpenDeleteModal(true);
    setSingleDataForDelete(data);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const [singleDataForDeleteForStation, setSingleDataForDeleteForStation] =
    useState({});
  const [openDeleteModalForStation, setOpenDeleteModalForStation] =
    useState(false);

  const handleOpenDeleteModalForStation = (data) => {
    setOpenDeleteModalForStation(true);
    setSingleDataForDeleteForStation(data);
  };

  const handleCloseDeleteModalForStation = () => {
    setOpenDeleteModalForStation(false);
  };

  useEffect(() => {
    if (isErrorForCreateBOM) {
      setTimeout(() => {
        setIsErrorForCreateBOM(false);
      }, 3000);
    }
  }, [isErrorForCreateBOM]);

  const [assemblyTypeSelected, setAssemblyTypeSelected] = useState(2);
  const handleAssemblyTypeChange = (selectedAssemblyName) => {
    setAssemblyTypeSelected(selectedAssemblyName);
  };
  const [assemblyTypesOptions, setAssemblyTypesOptions] = useState([]);

  useEffect(() => {
    let tempAssemblyTypesOptions = [];
    let data = getAssemblyMasterReducer.assembly.data
      ? getAssemblyMasterReducer.assembly.data
      : [];
    data.map((assemblyType) => {
      tempAssemblyTypesOptions.push({
        label: assemblyType.assembly,
        value: assemblyType.assemblyId,
      });
    });
    setAssemblyTypesOptions(tempAssemblyTypesOptions);
  }, [getAssemblyMasterReducer]);

  useEffect(() => {
    setAssemblyTypeSelected({
      label: assemblyTypesOptions[0]?.label,
      value: assemblyTypesOptions[0]?.value,
    });
  }, [assemblyTypesOptions]);

  useEffect(() => {
    getStationsByAssemblyType(
      assemblyTypeSelected?.value,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );
  }, [assemblyTypeSelected, pageNo, rowsPerPage, searchObject]);

  const [stationId, setStationId] = useState("");
  const [stationName, setStationName] = useState("");
  const [assemblyName, setAssemblyName] = useState("");

  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [errorMsgForDelete, setErrorMsgForDelete] = useState(false);
  const [isSuccessForDelete, setIsSuccessForDelete] = useState(false);

  useEffect(() => {
    if (isErrorDelete) {
      setTimeout(() => {
        setIsErrorDelete(false);
      }, 3000);
    }
  }, [isErrorDelete]);
  useEffect(() => {
    if (isSuccessForDelete) {
      setTimeout(() => {
        setIsSuccessForDelete(false);
      }, 2000);
    }
  }, [isSuccessForDelete]);

  const [deleteButtonName, setDeleteButtonName] = useState("Delete");
  const deleteTripNo = async () => {
    setDeleteButtonName("Please wait...");
    let stationId = singleDataForDelete.stationId;
    let assemblyId = singleDataForDelete.assemblyId;
    let res = await deleteStations(stationId, assemblyId);
    if (res.status) {
      await getStationsByAssemblyType(
        assemblyTypeSelected?.value,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setTimeout(() => {
        handleCloseDeleteModal();
        setDeleteButtonName("Delete");
      }, 3000);
      setIsSuccessForDelete(true);
    }
    if (!res.status) {
      setIsErrorDelete(true);
      setErrorMsgForDelete(res.data.data.msg);
      setDeleteButtonName("Delete");
    }
  };

  const [isErrorDeleteForStation, setIsErrorDeleteForStation] = useState(false);
  const [errorMsgForDeleteForStation, setErrorMsgForDeleteForStation] =
    useState(false);
  const [isSuccessForDeleteForStation, setIsSuccessForDeleteForStation] =
    useState(false);

  useEffect(() => {
    if (isErrorDeleteForStation) {
      setTimeout(() => {
        setIsErrorDeleteForStation(false);
      }, 3000);
    }
  }, [isErrorDeleteForStation]);
  useEffect(() => {
    if (isSuccessForDeleteForStation) {
      setTimeout(() => {
        setIsSuccessForDeleteForStation(false);
      }, 2000);
    }
  }, [isSuccessForDeleteForStation]);

  const [deleteStationButtonName, setStationDeleteButtonName] =
    useState("Delete");

  const deleteStationNo = async () => {
    setStationDeleteButtonName("Please wait...");
    let itemMinMaxAndStationMapId =
      singleDataForDeleteForStation.itemMinMaxAndStationMapId;
    let res = await deleteStationsForView(itemMinMaxAndStationMapId);
    if (res.status) {
      await getItemsByStation(
        stationId,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setTimeout(() => {
        handleCloseDeleteModalForStation();
        setStationDeleteButtonName("Delete");
      }, 3000);
      setIsSuccessForDeleteForStation(true);
    }
    if (!res.status) {
      setIsErrorDeleteForStation(true);
      setErrorMsgForDeleteForStation(res.data.data.msg);
      setStationDeleteButtonName("Delete");
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempStations = [];
    let data = getStationsByAssemblyTypeReducer.stationsByAssemblyType.data
      ? getStationsByAssemblyTypeReducer.stationsByAssemblyType.data
      : [];

    setPaginationObj(
      getStationsByAssemblyTypeReducer?.stationsByAssemblyType?.pagination
    );

    setLoading(getStationsByAssemblyTypeReducer.loading);
    data?.map((stations) => {
      const formatDate = new Date(stations.cd);
      let des = stations?.description;
      let len = desLength;
      const time = new Date(stations.cd);
      const stationsObject = {
        assembly: stations.assembly,
        station: stations.station,
        assemblyType: stations.assemblyType,
        zone: stations.zone,
        warehouseCode: stations.warehouseCode,
        itemCode: stations.itemCode,
        rack: stations.rackCode,
        location: stations.locationCode,
        thresholdQty: stations.thresholdQty,
        requestQty: stations.requestQty,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        section: stations.section,
        action: (
          <>
            {createAccess && (
              <CreateButton
                tooltip="Create mapping"
                color="info"
                iconOnly
                type="button"
                onClick={() => {
                  handleCreateBomModal(stations);
                  setStationName(stations.station);
                  setAssemblyName(stations.assembly);
                  setStationId(stations.stationId);
                  setWarehouseIdSelected(stations.warehouseId);
                  setSectionIdSelected(stations.sectionId);
                  setZoneIdSelected(stations.zoneId);
                  getItemMaster(jwtToken);
                }}
                style={{ marginRight: "5px" }}
              >
                <Icon>add</Icon>
              </CreateButton>
            )}
            {deleteAccessForMapping &&
            <DeleteButton
              tooltip="Delete"
              variant="gradient"
              color="error"
              iconOnly
              type="button"
              style={{ marginRight: "5px" }}
              onClick={() => handleOpenDeleteModal(stations)}
            >
              <Icon>delete</Icon>
            </DeleteButton>}

            {stations.isMapped === 1 ? (
              <ViewButton
                tooltip="View"
                // disabled={!viewVins}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => {
                  setStationId(stations.stationId);
                  setStationName(stations.station);
                  setWarehouseIdSelected(stations.warehouseId);
                  setAssemblyName(stations.assembly);
                  handleOpenViewItemModal(stations);
                  getItemsByStation(
                    stations.stationId,
                    pageNo,
                    rowsPerPage,
                    searchObject,
                    jwtToken
                  );
                }}
                style={{ marginRight: "5px" }}
              >
                <Icon>visibility</Icon>
              </ViewButton>
            ) : (
              <ViewButton
                tooltip="View"
                disabled
                variant="gradient"
                color="white"
                iconOnly
                type="button"
                onClick={() => {
                  setStationId(stations.stationId);
                  setStationName(stations.station);
                  setWarehouseIdSelected(stations.warehouseId);
                  handleOpenViewItemModal(stations);
                }}
                style={{
                  marginRight: "5px",
                  background: "grey",
                  color: "white",
                  opacity: 0.5,
                }}
              >
                <Icon>visibility</Icon>
              </ViewButton>
            )}
          </>
        ),
      };
      tempStations.push(stationsObject);
    });
    viewStations && setState({ ...state, rows: tempStations });
  }, [getStationsByAssemblyTypeReducer]);

  const [viewModal, setOpenViewModal] = useState(false);

  const handleOpenViewItemModal = (stations) => {
    setTimeout(() => {
      setOpenViewModal(true);
    }, 200);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };
  const [vinTable, setViewTable] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Rack", accessor: "rackName" },
      { Header: "Location Code", accessor: "locationCode" },
      { Header: "Request QTY", accessor: "reqQty" },
      { Header: "Threshold Qty", accessor: "thresholdQty" },

      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const [loading2, setLoading2] = useState(true);
  const { columns: itemColumns, rows: itemRows } = vinTable;

  useEffect(() => {
    let tempVin = [];
    let data = getItemsByStationReducer.itemsByStation.data
      ? getItemsByStationReducer.itemsByStation.data
      : [];

    setPaginationObj(getItemsByStationReducer?.itemsByStation?.pagination);
    setLoading2(getItemsByStationReducer.loading);
    data.map((vin) => {
      let des = vin?.itemDescription;
      let len = desLength;
      const formatDate = new Date(vin.cd);
      const vinInfo = {
        locationCode: vin.locationCode,
        itemCode: vin.itemCode
          ? `${vin.itemCode} / ${des ? des.substring(0, len) : ""}`
          : "",

        reqQty: vin.requestQty,
        thresholdQty: vin.thresholdQty,
        rackName: vin.rackName,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          // <DeleteButton
          //   tooltip="Delete"
          //   variant="gradient"
          //   color="error"
          //   iconOnly
          //   type="button"
          //   style={{ marginRight: "5px" }}
          //   onClick={() => handleOpenDeleteModalForStation(vin)}
          // >
          //   <Icon>delete</Icon>
          // </DeleteButton>
          <div>
            {deleteAccess && (
              <DeleteButton
                tooltip="Delete Category"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteModalForStation(vin)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
            {/* {editAccess && (
              <EditButton
                tooltip="Edit"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateCategoryModal(vin)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )} */}
          </div>
        ),
      };
      tempVin.push(vinInfo);
    });
    viewMappedItems && setViewTable({ ...vinTable, rows: tempVin });
  }, [getItemsByStationReducer]);

  const [createStationAndItemMappingModal, setCreateBomModal] = useState(false);
  const handleCreateBomModal = (stations) => {
    setCreateBomModal(true);
  };
  const handleCloseCreateBomModal = () => {
    setCreateBomModal(false);
  };
  const initialValuesForBom = {
    itemsForStation: [
      {
        itemId: "",
        requestQty: "",
        thresholdQty: "",
      },
    ],
  };

  const validationSchemaForBom = Yup.object({
    itemsForStation: Yup.array().of(
      Yup.object({
        requestQty: Yup.number()
          .min(0, "Quantity cannot be negative")
          .required("Quantity is required"),
      })
    ),
  });

  const [buttonName, setButtonName] = useState("Submit");

  const [openUpdateCategoryModal, setOpenUpdateCategoryModal] = useState(false);
  const [singleCategoryDataForUpdate, setCategoryDataForUpdate] = useState({});

  const handleOpenUpdateCategoryModal = (data = {}) => {
    setCategoryDataForUpdate(data);
    setOpenUpdateCategoryModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };

  const handleCloseUpdateCategoryModal = () => {
    setOpenUpdateCategoryModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");

  const initialValueForUpdate = {
    requestQty: singleCategoryDataForUpdate.requestQty,
    thresholdQty: singleCategoryDataForUpdate.thresholdQty,
  };

  const [editButtonName, setEditButtonName] = useState("Submit");

  const onSubmitForUpdate = async (values) => {
    setEditButtonName("Please wait...");
    let payload = {
      requestQty: values.requestQty,
      thresholdQty: values.thresholdQty,
      userId: userId,
      id: singleCategoryDataForUpdate.itemMinMaxAndStationMapId,
    };
    let res = await updateStationView(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getItemsByStation(stationId, jwtToken);
      setTimeout(() => {
        setEditButtonName("Submit");
        handleCloseUpdateCategoryModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
      setEditButtonName("Submit");
    }
  };
  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const onSubmitForCreateBom = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      items: values.itemsForStation,
      userId: userId,
      stationId: stationId,
      warehouseId: warehouseIdSelected,
      zoneId: zoneIdSelected,
      sectionId: sectionIdSelected,
    };

    let res = await createStationAndItemMapping(payload, jwtToken);
    if (res.status) {
      getStationsByAssemblyType(
        assemblyTypeSelected?.value,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      getItemsByStation(stationId, pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessForCreateBOM(true);
      resetForm();
      setTimeout(() => {
        handleCloseCreateBomModal();
        setButtonName("Submit");
      }, 3000);
    }
    if (!res.status) {
      setErrorMsgForCreate(res.data.data.msg);
      setIsErrorForCreateBOM(true);
      setButtonName("Submit");
    }
  };

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("file", uploadCsvData);

    let res = await uploadForStation(data, jwtToken);

    if (res.status) {
      getStationsByAssemblyType(
        assemblyTypeSelected?.value,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification();
    }
  };
  const headers = [
    {
      label: "warehouseCode",
      key: "warehouseCode",
    },
    {
      label: "zone",
      key: "zone",
    },
    { label: "section", key: "section" },
    { label: "station", key: "station" },
    { label: "itemCode", key: "itemCode" },
    {
      label: "thresholdQty",
      key: "thresholdQty",
    },
    {
      label: "requestQty",
      key: "requestQty",
    },
    { label: "rack", key: "rack" },
    { label: "location", key: "location" },
  ];
  const csvLink = {
    filename: "Station And Item Mapping Template.csv",
    headers: headers,
    data: [],
  };
  const csvLink2 = {
    filename: "stationAndItemMapping .csv",
    headers: headers,
    data: rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox color="text" px={2} mt={4}>
                {uploadAccess && (
                <UploadButton
                  tooltip="Upload CSV"
                  //   disabled={!uploadItem}
                  style={{ marginRight: "1rem" }}
                  type="button"
                  variant="outlined"
                  color="info"
                  iconOnly
                  onClick={uploadCsvFilterHandler}
                >
                  <Icon>upload</Icon>
                </UploadButton>
                 )}

                {downloadAccess && (
                <CSVLink {...csvLink}>
                  <DownloadButton
                    tooltip="Download Template"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="error"
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </DownloadButton>
                </CSVLink>
                )}

                {downloadAccess && (
                <CSVLink {...csvLink2}>
                  <DownloadDataButton
                    tooltip="Download Data"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="success"
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </DownloadDataButton>
                </CSVLink>
                 )}
              </MDBox>
              {uploadCsvFilter && (
                <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                  <div>
                    <input
                      className="choose_file"
                      style={{
                        marginTop: "1.5rem",
                        marginLeft: "1.5rem",
                        // marginBottom: "-9rem",
                      }}
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setUploadCsvData(e.target.files[0]);
                      }}
                    />
                    <MDButton
                      disabled={
                        !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                      }
                      variant="contained"
                      color="info"
                      style={{ marginLeft: "0", marginTop: "0rem" }}
                      type="button"
                      onClick={uploadCsvHandler}
                    >
                      UPLOAD
                    </MDButton>
                  </div>
                  <div style={{ marginBottom: "-20px" }}>
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "30px",
                        fontStyle: "italic",
                      }}
                    >
                      <strong>*</strong> Please upload
                      <strong>".csv"</strong> file only
                    </span>
                  </div>
                </div>
              )}
              <Grid item xs={12} lg={8} ml={3}>
                <MDBox>
                  <MDBox
                    display="flex"
                    mt={2}
                    flexDirection={{ xs: "column", lg: "row" }}
                  >
                    <MDBox
                      width={{ xs: "100%", lg: "250px" }}
                      style={{ marginRight: "10px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Assembly
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        autoComplete="off"
                        value={assemblyTypeSelected}
                        options={assemblyTypesOptions}
                        onChange={handleAssemblyTypeChange}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>

              <Modal
                open={viewModal}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForView}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Station:- {stationName}
                    </MDTypography>
                    <MDTypography
                      variant="h4"
                      fontWeight="medium"
                      flexGrow={1}
                      ml={-50}
                    >
                      Assembly:- {assemblyName}
                    </MDTypography>

                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                              marginBottom: "5px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />
                  <MDBox>
                    <DataTable
                      table={{ columns: itemColumns, rows: itemRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                      serverSidePagination={true}
                      paginationObj={paginationObj}
                      pageNoValue={pageNo}
                      rowsPerPageValue={rowsPerPage}
                      pageNoProp={importPageNo}
                      rowsPerPageProp={importRowsPerPage}
                      searchProp={importSearch}
                    />
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={createStationAndItemMappingModal}
                onClose={handleCloseCreateBomModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreateBom}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Mapping
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateBomModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox>
                      <MDBox>
                        <Grid container>
                          <Formik
                            initialValues={initialValuesForBom}
                            validationSchema={validationSchemaForBom}
                            onSubmit={onSubmitForCreateBom}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  <div>
                                    <MDBox></MDBox>
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Station:- {stationName}
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForStation">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForStation } = values;

                                          return (
                                            <div>
                                              {itemsForStation.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={1}
                                                        mb={2}
                                                      >
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={3}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Item Code/Des
                                                            </MDTypography>
                                                            <Select
                                                              isClearable
                                                              className="select-css-for-itemcode"
                                                              maxMenuHeight={80}
                                                              placeholder="Choose one..."
                                                              name={`itemsForStation[${index}].itemId`}
                                                              onBlur={() => {
                                                                formik.handleBlur(
                                                                  {
                                                                    target: {
                                                                      name: `itemsForStation[${index}].itemId`,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              options={
                                                                itemCodeOptions
                                                              }
                                                              value={
                                                                itemCodeOptions.find(
                                                                  (option) =>
                                                                    option.value ===
                                                                    formik
                                                                      .values
                                                                      .itemsForStation[
                                                                      index
                                                                    ].itemId
                                                                ) || null
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForStation[${index}].itemId`,
                                                                  selectedOption
                                                                    ? selectedOption.value
                                                                    : ""
                                                                );

                                                                getLevel3ByLevel(
                                                                  warehouseIdSelected,
                                                                  sectionIdSelected,
                                                                  jwtToken
                                                                );

                                                                const selectedItem =
                                                                  itemCodeOptions.find(
                                                                    (item) =>
                                                                      item.value ===
                                                                      selectedOption?.value
                                                                  );

                                                                const category =
                                                                  selectedItem
                                                                    ? selectedItem.categoryId
                                                                    : "";
                                                                formik.setFieldValue(
                                                                  `itemsForStation.${index}.categoryId`,
                                                                  category
                                                                );
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ].itemId && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForStation[
                                                                      index
                                                                    ].itemId
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={1.8}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            ml={2.5}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Threshold Qty
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="number"
                                                              name={`itemsForStation[${index}].thresholdQty`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                formik.values
                                                                  .itemsForStation[
                                                                  index
                                                                ].thresholdQty
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                  min: 0,
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForStation &&
                                                              formik.touched
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.touched
                                                                .itemsForStation[
                                                                index
                                                              ].thresholdQty &&
                                                              formik.errors
                                                                .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ]
                                                                .thresholdQty && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForStation[
                                                                      index
                                                                    ]
                                                                      .thresholdQty
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={1.5}
                                                        >
                                                          <MDBox mb={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Request Qty
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="number"
                                                              name={`itemsForStation[${index}].requestQty`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                formik.values
                                                                  .itemsForStation[
                                                                  index
                                                                ].requestQty
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                  min: 0,
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForStation &&
                                                              formik.touched
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.touched
                                                                .itemsForStation[
                                                                index
                                                              ].requestQty &&
                                                              formik.errors
                                                                .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ].requestQty && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForStation[
                                                                      index
                                                                    ].requestQty
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            mr={3}
                                                            style={{
                                                              width: "15rem",
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Rack (Optional)
                                                            </MDTypography>
                                                            <Select
                                                              isMulti
                                                              isClearable
                                                              className="select-css"
                                                              maxMenuHeight={80}
                                                              placeholder="Choose one or more..."
                                                              name={`itemsForStation[${index}].rackId`}
                                                              onBlur={() => {
                                                                formik.handleBlur(
                                                                  {
                                                                    target: {
                                                                      name: `itemsForStation[${index}].rackId`,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              options={
                                                                level3Options
                                                              }
                                                              value={
                                                                formik.values
                                                                  .itemsForStation[
                                                                  index
                                                                ].rackId
                                                              }
                                                              onChange={(
                                                                selectedOptions
                                                              ) => {
                                                                const rackIds =
                                                                  selectedOptions
                                                                    ? selectedOptions.map(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value
                                                                      )
                                                                    : [];

                                                                getLevel4ByLevel(
                                                                  warehouseIdSelected,
                                                                  JSON.stringify(
                                                                    rackIds
                                                                  ),
                                                                  jwtToken
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForStation.${index}.rackId`,
                                                                  selectedOptions
                                                                );
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForStation[
                                                                  index
                                                                ].itemId
                                                              }
                                                            />
                                                            {formik.touched
                                                              .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ].rackId && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForStation[
                                                                      index
                                                                    ].rackId
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            ml={2}
                                                            style={{
                                                              width: "15rem",
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Location
                                                              (Optional)
                                                            </MDTypography>
                                                            <Select
                                                              isClearable
                                                              isMulti
                                                              className="select-css"
                                                              maxMenuHeight={80}
                                                              placeholder="Choose one..."
                                                              name={`itemsForStation[${index}].locationId`}
                                                              onBlur={() => {
                                                                formik.handleBlur(
                                                                  {
                                                                    target: {
                                                                      name: `itemsForStation[${index}].locationId`,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              options={
                                                                level4Options
                                                              }
                                                              value={
                                                                formik.values
                                                                  .itemsForStation[
                                                                  index
                                                                ].locationId
                                                              }
                                                              onChange={(
                                                                selectedOptions
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForStation.${index}.locationId`,
                                                                  selectedOptions
                                                                    ? selectedOptions
                                                                    : []
                                                                );
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForStation[
                                                                  index
                                                                ].rackId
                                                              }
                                                            />

                                                            {formik.touched
                                                              .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForStation[
                                                                index
                                                              ].locationId && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForStation[
                                                                      index
                                                                    ].locationId
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            marginTop: "2.5rem",
                                                            marginLeft: "2rem",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                          </div>
                                                          {index ===
                                                            itemsForStation.length -
                                                              1 && (
                                                            <div>
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      requestQty:
                                                                        "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    width:
                                                                      "30px",
                                                                    marginLeft:
                                                                      "0rem",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>

                                    <div style={{ background: "white" }}>
                                      <MDButton
                                        style={{
                                          position: "sticky",
                                          bottom: "0",
                                        }}
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        disabled={
                                          !formik.isValid ||
                                          formik.values.itemsForStation.some(
                                            (item) =>
                                              item.itemId === "" ||
                                              item.requestQty === "" ||
                                              item.thresholdQty === ""
                                          ) ||
                                          buttonName === "Please wait..."
                                        }
                                      >
                                        {buttonName}
                                      </MDButton>
                                    </div>
                                    <MDBox>
                                      <Collapse in={isErrorForCreateBOM}>
                                        <Alert
                                          severity="error"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsErrorForCreateBOM(false);
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          {errorMsgForCreate}
                                        </Alert>
                                      </Collapse>
                                      <Collapse in={isSuccessForCreateBOM}>
                                        <Alert
                                          severity="success"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsSuccessForCreateBOM(false);
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          Created Successfully
                                        </Alert>
                                      </Collapse>
                                    </MDBox>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteTripNo}
                      disabled={deleteButtonName === "Please wait..."}
                    >
                      {deleteButtonName}
                    </MDButton>
                    <MDBox mt={2}>
                      <Collapse in={isErrorDelete}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForDelete}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openDeleteModalForStation}
                onClose={handleCloseDeleteModalForStation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteModalForStation}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteStationNo}
                      disabled={deleteStationButtonName === "Please wait..."}
                    >
                      {deleteStationButtonName}
                    </MDButton>
                    <MDBox mt={2}>
                      <Collapse in={isErrorDeleteForStation}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteForStation(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDeleteForStation}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForDeleteForStation}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForDeleteForStation(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateCategoryModal}
                onClose={handleCloseUpdateCategoryModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Edit
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateCategoryModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Request Qty</MDTypography>
                        <MDInput
                          type="text"
                          name="requestQty"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.requestQty}
                          error={
                            formikForUpdate.touched.requestQty &&
                            formikForUpdate.errors.requestQty &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.requestQty &&
                          formikForUpdate.errors.requestQty && (
                            <TextError
                              msg={formikForUpdate.errors.requestQty}
                            />
                          )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Threshhold Qty</MDTypography>
                        <MDInput
                          type="text"
                          name="thresholdQty"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.thresholdQty}
                          error={
                            formikForUpdate.touched.thresholdQty &&
                            formikForUpdate.errors.thresholdQty &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.thresholdQty &&
                          formikForUpdate.errors.thresholdQty && (
                            <TextError
                              msg={formikForUpdate.errors.thresholdQty}
                            />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully!
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={
                            !formikForUpdate.isValid ||
                            editButtonName === "Please wait..."
                          }
                        >
                          {editButtonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getStationsByAssemblyTypeReducer: state.getStationsByAssemblyTypeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getAssemblyMasterReducer: state.getAssemblyMasterReducer,
    itemMaster: state.itemMaster,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getItemsByStationReducer: state.getItemsByStationReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAssemblyMaster: (jwtToken) => dispatch(getAssemblyMaster(jwtToken)),
    getStationsByAssemblyType: (
      assemblyType,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getStationsByAssemblyType(
          assemblyType,
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    deleteStations: (stationId, assemblyId, jwtToken) =>
      dispatch(deleteStations(stationId, assemblyId, jwtToken)),
    deleteStationsForView: (stationId, jwtToken) =>
      dispatch(deleteStationsForView(stationId, jwtToken)),
    createStationAndItemMapping: (payload, jwtToken) =>
      dispatch(createStationAndItemMapping(payload, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getItemsByStation: (station, pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getItemsByStation(station, pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    uploadForStation: (payload, jwtToken) =>
      dispatch(uploadForStation(payload, jwtToken)),
    updateStationView: (payload, jwtToken) =>
      dispatch(updateStationView(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationAndItemMapping);
