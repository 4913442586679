/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import Icon from "@mui/material/Icon";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Functions from store
import { getRejectedVendors } from "../../store/rejected-vendors/rejectedVendorsActions";

// Router components
import { useNavigate } from "react-router-dom";

// Cookies
import Cookies from "universal-cookie";
import { getSubModulesByRoleId } from "store";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const RejectedVendors = ({
  getRejectedVendors,
  rejectedVendorsReducer,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let rejectedVendorsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "4b1cf524-6f2a-4ebc-a7e6-6f2be06c136a"
  );
  let viewForm = rejectedVendorsModule
    ? rejectedVendorsModule.actionId.includes(
        "2d9a40bb-403d-44a1-aac4-2d8a28737acb"
      )
    : null;
  let viewAccessForRejected = rejectedVendorsModule
    ? rejectedVendorsModule.actionId.includes(
        "bb1b01ab-6fcb-4927-bbd4-1b68bbe1f182"
      )
    : null;
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  const navigate = useNavigate();
  const [state, setState] = useState({
    columns: [
      { Header: "vendor Name", accessor: "vendorName" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Email", accessor: "email" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "Action", accessor: "action", cannotSearch: true },
    ],
    rows: [],
  });
  const { columns, rows } = state;
  const viewFunction = (data) => {
    navigate(`/rejected-vendors/${data.vendorUserId}`);
  };

  useEffect(() => {
    viewAccessForRejected &&
      getRejectedVendors(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [viewAccessForRejected, pageNo, rowsPerPage, searchObject]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempUsers = [];
    let rejectedVendorData = rejectedVendorsReducer.rejectedVendors.data
      ? rejectedVendorsReducer.rejectedVendors.data
      : [];

    setPaginationObj(rejectedVendorsReducer?.rejectedVendors?.pagination);
    setLoading(rejectedVendorsReducer.loading);
    rejectedVendorData.map((user) => {
      const formatDate = new Date(user.cd);
      const singleUser = {
        privateKey: user.email,
        vendorName: user.vendorName,
        contactPerson: user.contactPerson,
        email: user.email,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          <Tooltip title="View Form">
            {viewForm &&
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => viewFunction(user)}
            >
              <Icon>visibility_icon</Icon>
            </MDButton>}
          </Tooltip>
        ),
      };
      tempUsers.push(singleUser);
    });
    setState({ ...state, rows: tempUsers });
  }, [rejectedVendorsReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    rejectedVendorsReducer: state.rejectedVendorsReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getRejectedVendors: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getRejectedVendors(pageNo, rowsPerPage, searchObject, jwtToken)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(RejectedVendors);
