import {
  GET_CATEGORYITEMMAPPING_REQUEST,
  GET_CATEGORYITEMMAPPING_SUCCESS,
  GET_CATEGORYITEMMAPPING_FAILURE,
  GET_WAREHOUSES_BY_MOVEMENT_REQUEST,
  GET_WAREHOUSES_BY_MOVEMENT_SUCCESS,
  GET_WAREHOUSES_BY_MOVEMENT_FAILURE,
  GET_CATEGORYITEMMAPPING_WITH_PAGINATION_REQUEST,
  GET_CATEGORYITEMMAPPING_WITH_PAGINATION_SUCCESS,
  GET_CATEGORYITEMMAPPING_WITH_PAGINATION_FAILURE,
} from "./categoryItemMappingTypes";

const initialState = {
  loading: false,
  categoryItemMapping: [],
  error: "",
};

const getCategoryItemMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORYITEMMAPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORYITEMMAPPING_SUCCESS:
      return {
        loading: false,
        categoryItemMapping: action.payload,
        error: "",
      };
    case GET_CATEGORYITEMMAPPING_FAILURE:
      return {
        loading: false,
        categoryItemMapping: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateWithPagination = {
  loading: false,
  categoryItemMapping: [],
  error: "",
};

const getCategoryItemMappingWithPaginationReducer = (
  state = initialStateWithPagination,
  action
) => {
  switch (action.type) {
    case GET_CATEGORYITEMMAPPING_WITH_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORYITEMMAPPING_WITH_PAGINATION_SUCCESS:
      return {
        loading: false,
        categoryItemMapping: action.payload,
        error: "",
      };
    case GET_CATEGORYITEMMAPPING_WITH_PAGINATION_FAILURE:
      return {
        loading: false,
        categoryItemMapping: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForWarehouse = {
  loading: false,
  warehouses: [],
  error: "",
};

const getWarehouseByMovementReducer = (
  state = initialStateForWarehouse,
  action
) => {
  switch (action.type) {
    case GET_WAREHOUSES_BY_MOVEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WAREHOUSES_BY_MOVEMENT_SUCCESS:
      return {
        loading: false,
        warehouses: action.payload,
        error: "",
      };
    case GET_WAREHOUSES_BY_MOVEMENT_FAILURE:
      return {
        loading: false,
        warehouses: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getCategoryItemMappingReducer,
  getWarehouseByMovementReducer,
  getCategoryItemMappingWithPaginationReducer,
};
