/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
// Functions from store
import {
  getSubModulesByRoleId,
  getCompletedTransactions,
} from "../../../store";

// React-Select component and styling

import "../../../index.css";

import Config from "../../../config/index";
// Cookies
import Cookies from "universal-cookie";
import { Backdrop, CircularProgress } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const CompletedTransaction = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getCompletedTransactionsReducer,
  getCompletedTransactions,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let completedTransactionModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "47a8970c-0653-4742-a44a-dca7182ce915"
  );

  let viewAccess = completedTransactionModule
    ? completedTransactionModule.actionId.includes(
        "5e8ceb89-13ca-4023-acb0-7bbf18dbc83a"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getCompletedTransactions(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Transaction code", accessor: "transactionCode" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Request Qty", accessor: "reqQty" },
      { Header: "Picklist Qty", accessor: "picklistQty" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempAsset = [];
    let data = getCompletedTransactionsReducer.transactions.data
      ? getCompletedTransactionsReducer.transactions.data
      : [];
    setPaginationObj(getCompletedTransactionsReducer?.transactions?.pagination);
    setLoading(getCompletedTransactionsReducer.loading);
    data.map((purchase) => {
      const formatDate = new Date(purchase.cd);
      let len = desLength;
      let des = purchase?.itemDescription;
      const purchaseObject = {
        privateKey: purchase.transactionId,
        transactionCode: purchase.transactionCode,
        itemCode: purchase.itemCode ? `${purchase.itemCode}` : "",
        itemDescription: `${des ? des.substring(0, len) : ""}`,
        reqQty: `${purchase.requestQty}`,
        picklistQty: `${purchase.picklistQty} `,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        // isEditable: purchase.isEditable,
      };
      viewAccess && tempAsset.push(purchaseObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [getCompletedTransactionsReducer]);

  return (
    <div>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox mt={-3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getCompletedTransactionsReducer: state.getCompletedTransactionsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getCompletedTransactions: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getCompletedTransactions(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedTransaction);
