import {
  PUT_CREATEVENDORLINK_REQUEST,
  PUT_CREATEVENDORLINK_SUCCESS,
  PUT_CREATEVENDORLINK_FAILURE,
  PUT_RESENDVENDORLINK_REQUEST,
  PUT_RESENDVENDORLINK_SUCCESS,
  PUT_RESENDVENDORLINK_FAILURE,
  PUT_VENDORDETAILS_REQUEST,
  PUT_VENDORDETAILS_SUCCESS,
  PUT_VENDORDETAILS_FAILURE,
  GET_VENDOR_REQUEST,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAILURE,
  GET_VENDORSNOTAPPROVED_REQUEST,
  GET_VENDORSNOTAPPROVED_SUCCESS,
  GET_VENDORSNOTAPPROVED_FAILURE,
  PUT_ACCEPTHANDLER_REQUEST,
  PUT_ACCEPTHANDLER_SUCCESS,
  PUT_ACCEPTHANDLER_FAILURE,
  PUT_REJECTHANDLER_REQUEST,
  PUT_REJECTHANDLER_SUCCESS,
  PUT_REJECTHANDLER_FAILURE,
  PUT_UPDATEEMAIL_REQUEST,
  PUT_UPDATEEMAIL_SUCCESS,
  PUT_UPDATEEMAIL_FAILURE,
  GET_PREVIEW_SUCCESS,
  SET_VENDORID_REQUEST,
  SET_VENDORID_SUCCESS,
  SET_VENDORID_FAILURE,
  REJECTED_VENDORDETAILS_REQUEST,
  REJECTED_VENDORDETAILS_SUCCESS,
  REJECTED_VENDORDETAILS_FAILURE,
} from "./vendorDetailsTypes";

import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const createVendorRegistrationLinkRequest = () => {
  return {
    type: PUT_CREATEVENDORLINK_REQUEST,
  };
};

const createVendorRegistrationLinkSuccess = (payload) => {
  return {
    type: PUT_CREATEVENDORLINK_SUCCESS,
    payload: payload,
  };
};

const createVendorRegistrationLinkFaliure = (error) => {
  return {
    type: PUT_CREATEVENDORLINK_FAILURE,
    payload: error,
  };
};

const createVendorRegistrationLink = (payload) => {
  return (dispatch) => {
    dispatch(createVendorRegistrationLinkRequest());
    let url = `${baseUrl}/vendor/create_vendor`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(createVendorRegistrationLinkSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(createVendorRegistrationLinkFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const resendVendorRegistrationLinkRequest = () => {
  return {
    type: PUT_RESENDVENDORLINK_REQUEST,
  };
};

const resendVendorRegistrationLinkSuccess = (payload) => {
  return {
    type: PUT_RESENDVENDORLINK_SUCCESS,
    payload: payload,
  };
};

const resendVendorRegistrationLinkFaliure = (error) => {
  return {
    type: PUT_RESENDVENDORLINK_FAILURE,
    payload: error,
  };
};

const resendVendorRegistrationLink = (payload) => {
  return (dispatch) => {
    dispatch(resendVendorRegistrationLinkRequest());
    let url = `${baseUrl}/vendor/resend_registration_mail`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(resendVendorRegistrationLinkSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(resendVendorRegistrationLinkFaliure(err.response));
        return { status: false, data:err.response };
      });
  };
};

const createVendorDetailsRequest = () => {
  return {
    type: PUT_VENDORDETAILS_REQUEST,
  };
};

const createVendorDetailsSuccess = (payload) => {
  return {
    type: PUT_VENDORDETAILS_SUCCESS,
    payload: payload,
  };
};

const createVendorDetailsFaliure = (error) => {
  return {
    type: PUT_VENDORDETAILS_FAILURE,
    payload: error,
  };
};

const createVendorDetails = (payload, token) => {
  return (dispatch) => {
    dispatch(createVendorDetailsRequest());
    let url = `${baseUrl}/vendor/vendor_details_form`;
    return axios
      .post(url, payload, {
        headers: { authorization: token },
      })
      .then((response) => {
        dispatch(createVendorDetailsSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(createVendorDetailsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getVendorByVendorIdRequest = () => {
  return {
    type: GET_VENDOR_REQUEST,
  };
};

const getVendorByVendorIdSuccess = (payload) => {
  return {
    type: GET_VENDOR_SUCCESS,
    payload: payload,
  };
};

const getVendorByVendorIdFaliure = (error) => {
  return {
    type: GET_VENDOR_FAILURE,
    payload: error,
  };
};

const getVendorByVendorId = (vendorId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getVendorByVendorIdRequest());
    let url = `${baseUrl}/vendor/get_by_vendorId/${vendorId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getVendorByVendorIdSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getVendorByVendorIdFaliure(err.message));
      });
  };
};

const getVendorsNotApprovedRequest = () => {
  return {
    type: GET_VENDORSNOTAPPROVED_REQUEST,
  };
};

const getVendorsNotApprovedSuccess = (payload) => {
  return {
    type: GET_VENDORSNOTAPPROVED_SUCCESS,
    payload: payload,
  };
};

const getVendorsNotApprovedFaliure = (error) => {
  return {
    type: GET_VENDORSNOTAPPROVED_FAILURE,
    payload: error,
  };
};

const getVendorsNotApproved = () => {
  return (dispatch) => {
    dispatch(getVendorsNotApprovedRequest());
    let url = `${baseUrl}/vendor/vendors_not_approved`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getVendorsNotApprovedSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getVendorsNotApprovedFaliure(err.message));
      });
  };
};

const acceptVendorHandlerRequest = () => {
  return {
    type: PUT_ACCEPTHANDLER_REQUEST,
  };
};

const acceptVendorHandlerSuccess = (payload) => {
  return {
    type: PUT_ACCEPTHANDLER_SUCCESS,
    payload: payload,
  };
};

const acceptVendorHandlerFaliure = (error) => {
  return {
    type: PUT_ACCEPTHANDLER_FAILURE,
    payload: error,
  };
};

const acceptVendorHandler = (payload) => {
  return (dispatch) => {
    dispatch(acceptVendorHandlerRequest());
    let url = `${baseUrl}/vendor/approve_vendor`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(acceptVendorHandlerSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(acceptVendorHandlerFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const rejectVendorHandlerRequest = () => {
  return {
    type: PUT_REJECTHANDLER_REQUEST,
  };
};

const rejectVendorHandlerSuccess = (payload) => {
  return {
    type: PUT_REJECTHANDLER_SUCCESS,
    payload: payload,
  };
};

const rejectVendorHandlerFaliure = (error) => {
  return {
    type: PUT_REJECTHANDLER_FAILURE,
    payload: error,
  };
};

const rejectVendorHandler = (payload) => {
  return (dispatch) => {
    dispatch(rejectVendorHandlerRequest());
    let url = `${baseUrl}/vendor/reject_vendor`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(rejectVendorHandlerSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(rejectVendorHandlerFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateEmailRequest = () => {
  return {
    type: PUT_UPDATEEMAIL_REQUEST,
  };
};

const updateEmailSuccess = (payload) => {
  return {
    type: PUT_UPDATEEMAIL_SUCCESS,
    payload: payload,
  };
};

const updateEmailFaliure = (error) => {
  return {
    type: PUT_UPDATEEMAIL_FAILURE,
    payload: error,
  };
};

const updateEmail = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateEmailRequest());
    let url = `${baseUrl}/vendor/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateEmailSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateEmailFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getPreviewSuccess = (payload) => {
  return {
    type: GET_PREVIEW_SUCCESS,
    payload: payload,
  };
};

const getPreview = (payload) => {
  return (dispatch) => {
    dispatch(getPreviewSuccess(payload));
  };
};

const setVendorIdRequest = () => {
  return {
    type: SET_VENDORID_REQUEST,
  };
};

const setVendorIdSuccess = (payload) => {
  return {
    type: SET_VENDORID_SUCCESS,
    payload: payload,
  };
};

const setVendorIdFaliure = (error) => {
  return {
    type: SET_VENDORID_FAILURE,
    payload: error,
  };
};

const setVendorIdHandler = (payload) => {
  return (dispatch) => {
    dispatch(setVendorIdSuccess(payload));
  };
};

const rejectedVendorDetailsRequest = () => {
  return {
    type: REJECTED_VENDORDETAILS_REQUEST,
  };
};

const rejectedVendorDetailsSuccess = (payload) => {
  return {
    type: REJECTED_VENDORDETAILS_SUCCESS,
    payload: payload,
  };
};

const rejectedVendorDetailsFaliure = (error) => {
  return {
    type: REJECTED_VENDORDETAILS_FAILURE,
    payload: error,
  };
};

const rejectedVendorDetails = (payload, token) => {
  return (dispatch) => {
    dispatch(rejectedVendorDetailsRequest());
    let url = `${baseUrl}/vendor/update_vendor_details_form`;
    return axios
      .post(url, payload, {
        headers: { authorization: token },
      })
      .then((response) => {
        dispatch(rejectedVendorDetailsSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(rejectedVendorDetailsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  createVendorRegistrationLink,
  resendVendorRegistrationLink,
  createVendorDetails,
  getVendorByVendorId,
  getVendorsNotApproved,
  acceptVendorHandler,
  rejectVendorHandler,
  updateEmail,
  getPreview,
  setVendorIdHandler,
  rejectedVendorDetails,
};
