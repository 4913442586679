/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getPurchaseOrder,
  createPurchaseOrder,
  editPurchaseOrder,
  deletePurchaseOrder,
  uploadForPurchaseOrder,
  publishPurchaseOrder,
  getItemMaster,
  getVendorCodes,
  publishPo,
  createAsn,
  getPackageType,
  calculatePacketDetails,
  getDeliveryPlantForPO,
  getUnloadingPointForPO,
  calculatePacketWeight,
  getBinsForPrint,
} from "../../store";

// React-Select component and styling

import "../../index.css";

// config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import EditButton from "components/standardized-components/EditButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import { Backdrop, CircularProgress } from "@mui/material";
import ReleaseButton from "components/standardized-components/ReleaseButton";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForCreatePo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,

  width: { xs: 350, lg: 1130 },
  height: "fit-content",
  maxHeight: "90%",
  overflow: "scroll",
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForCreateASN = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "80%", lg: "90%", xl: "90%" },
  height: "fit-content",
  maxHight: "60%",
  overflow: "auto",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const PurchaseOrder = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  createPurchaseOrder,
  editPurchaseOrder,
  deletePurchaseOrder,
  publishPurchaseOrder,
  uploadForPurchaseOrder,
  getPurchaseOrder,
  getVendorCodes,
  getPurchaseOrderReducer,
  itemMaster,
  getItemMaster,
  getVendorCodeReducer,
  publishPo,

  getPackageType,
  packageTypeReducer,
  createAsn,

  calculatePacketDetails,
  packageDetailsReducer,
  getDeliveryPlantForPO,
  getUnloadingPointForPO,
  deliveryPlantForPOReducer,
  unloadingPointForPOReducer,
  calculatePacketWeight,
  packetWeightReducer,
  getBinsForPrint,
  binsForPrintReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let purchaseOrderModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "aa4860c3-2619-4478-8005-c53efa538d66"
  );
  let createAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "dbee5e2e-a68d-4cbf-99bd-817f09cc20dc"
      )
    : null;
  let viewAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "16d13a65-675a-493c-b856-3e5b0fd96fc0"
      )
    : null;
  let uploadAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "7aa71f3a-219c-4295-b561-219cf378e953"
      )
    : null;

  let downloadAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "cb45caa1-e2c4-421a-848e-e1bd82d2bcba"
      )
    : null;
  let editAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "e83c5627-9334-4702-bc8c-26092e94e6ec"
      )
    : null;
  let deleteAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "0b0369bb-e87b-4dc0-9276-5fe43a16f1a4"
      )
    : null;
  let createAsnAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "455fcfb4-1453-49c2-9a99-a636ef80f983"
      )
    : null;

  let publishAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "42b2a60c-1720-49b9-894c-397bdf91b5c5"
      )
    : null;
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  useEffect(() => {
    setTimeout(() => {
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);
    }, 1000);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "PO No.", accessor: "purchaseOrderNumber" },
      { Header: "PO LineNo.", accessor: "purchaseOrderLineNumber" },
      // { Header: "Part Code/Description", accessor: "partDesc" },
      { Header: "Vendor Code", accessor: "vendorCode" },
      { Header: "Item code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Po Date", accessor: "date" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Unloading Point", accessor: "unloadingPoint" },
      { Header: "Delivery Plant", accessor: "deliveryPlant" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [asnQtyChange, setAsnQtyChange] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorMsgForDelete, setErrorMsgForDelete] = useState("");
  const [isErrorForCreateOrder, setIsErrorForCreateOrder] = useState(false);
  const [isSuccessForCreateOrder, setIsSuccessForCreateOrder] = useState(false);

  useEffect(() => {
    if (isErrorForCreateOrder) {
      setTimeout(() => {
        setIsErrorForCreateOrder(false);
      }, 3000);
    }
  }, [isErrorForCreateOrder]);

  useEffect(() => {
    if (isSuccessForCreateOrder) {
      setTimeout(() => {
        setIsSuccessForCreateOrder(false);
      }, 3000);
    }
  }, [isSuccessForCreateOrder]);
  const [isErrorRelease, setIsErrorRelease] = useState(false);
  const [isSuccessRelease, setIsSuccessRelease] = useState(false);

  useEffect(() => {
    if (isErrorRelease) {
      setTimeout(() => {
        setIsErrorRelease(false);
      }, 3000);
    }
  }, [isErrorRelease]);

  useEffect(() => {
    if (isSuccessRelease) {
      setTimeout(() => {
        setIsSuccessRelease(false);
      }, 3000);
    }
  }, [isSuccessRelease]);

  const [singleDataForRelease, setSingleDataForRelease] = useState({});

  const [openReleaseModal, setOpenReleaseModal] = useState(false);

  const handleOpenReleaseModal = (asset = {}) => {
    setSingleDataForRelease(asset);
    setOpenReleaseModal(true);
  };
  const handleCloseReleaseModal = () => {
    setOpenReleaseModal(false);
  };

  const [asnCode, setAsnCode] = useState("");
  const [packageType, setPackageType] = useState("");

  useEffect(() => {
    getBinsForPrint(asnCode, jwtToken);
  }, [asnCode]);

  const [partCode, setPartCode] = useState("");

  const [purchaseOrderNumberOptions, setPurchaseOrderNumberOptions] = useState(
    []
  );

  const [demandId, setDemandId] = useState("");

  useEffect(() => {
    getPackageType(partCode, jwtToken);
  }, [partCode]);

  useEffect(() => {
    calculatePacketWeight(partCode, asnQtyChange, jwtToken);
  }, [partCode, asnQtyChange]);

  useEffect(() => {
    getDeliveryPlantForPO(demandId, jwtToken);
  }, [demandId]);

  useEffect(() => {
    getUnloadingPointForPO(demandId, jwtToken);
  }, [demandId]);

  const [singleDeliveryPlantData, setSingleDeliveryPlantData] = useState("");
  const [singleUnloadingPointData, setSingleUnloadingPointData] = useState("");

  const [singleAsnPackageTypeData, setSingleAsnPackageTypeData] = useState("");

  const [singleAsnPackageWeightData, setSingleAsnPackageWeightData] =
    useState("");

  const [singleAsnPackageDetailsData, setSingleAsnPackageDetailsData] =
    useState({});

  useEffect(() => {
    let data = packageTypeReducer.packageTypeData.data
      ? packageTypeReducer.packageTypeData.data
      : [];

    setSingleAsnPackageTypeData(data?.packaging);
  }, [packageTypeReducer]);

  useEffect(() => {
    let data = packetWeightReducer.packetWeightData.data
      ? packetWeightReducer.packetWeightData.data
      : [];

    setSingleAsnPackageWeightData(data?.packWeight);
  }, [packetWeightReducer]);

  useEffect(() => {
    let data = deliveryPlantForPOReducer.deliveryPlantData.data
      ? deliveryPlantForPOReducer.deliveryPlantData.data
      : [];

    setSingleDeliveryPlantData(data?.deliveryPlant);
  }, [deliveryPlantForPOReducer]);

  useEffect(() => {
    let data = unloadingPointForPOReducer.unloadingPointData.data
      ? unloadingPointForPOReducer.unloadingPointData.data
      : [];

    setSingleUnloadingPointData(data.unloadingPoint);
  }, [unloadingPointForPOReducer]);

  useEffect(() => {
    let data = packageDetailsReducer.packageDetailsData.data
      ? packageDetailsReducer.packageDetailsData.data
      : [];
    setSingleAsnPackageDetailsData(data);
  }, [packageDetailsReducer]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempAsset = [];
    let data = getPurchaseOrderReducer.purchaseOrders.data
      ? getPurchaseOrderReducer.purchaseOrders.data
      : [];
    setPaginationObj(getPurchaseOrderReducer.purchaseOrders?.pagination);
    setLoading(getPurchaseOrderReducer.loading);
    data.map((purchase) => {
      const formatDate = new Date(purchase.date);

      const purchaseObject = {
        privateKey: purchase.purchaseOrderId,
        purchaseOrderNumber: purchase.purchaseOrderNumber,
        purchaseOrderLineNumber: purchase.purchaseOrderLineNumber,
        vendorCode: purchase.vendorCode,
        purchaseOrderId: purchase.purchaseOrderId,
        itemCode: purchase.itemCode,
        itemDescription: purchase.itemDescription,
        isReleased: purchase.isReleased,
        date: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: purchase.qty,
        unloadingPoint:
          purchase.unloadingPoint == (undefined || null || "undefined")
            ? "NA"
            : purchase.unloadingPoint,
        deliveryPlant:
          purchase.deliveryPlant == (undefined || null || "undefined")
            ? "NA"
            : purchase.deliveryPlant,
        status: purchase.isReleased === 0 ? "Not Released" : "Released",
        // isEditable: purchase.isEditable,
        action: (
          <div>
            {createAsnAccess ? (
              <Tooltip title="ASN Creation">
                <MDButton
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={() => {
                    setPartCode(purchase.itemCode);
                    setDemandId(purchase?.purchaseOrderId);

                    handleOpenCreateASNModal(purchase);
                  }}
                  style={{
                    marginRight: "5px",
                    padding: "2%",
                    marginBottom: "2px",
                  }}
                >
                  <Icon style={{ marginRight: "2px" }}>add</Icon>
                  ASN
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="ASN Creation">
                <MDButton
                  disabled
                  variant="gradient"
                  color="info"
                  type="button"
                  // onClick={() => {
                  //   setPartCode(singleData.itemCode);
                  //   setDemandId(singleData.demandId);
                  //   setPartCode(singleData.itemCode);
                  //   handleOpenCreateASNModal(singleData);
                  // }}
                  style={{
                    marginRight: "5px",
                    marginBottom: "2px",
                    padding: "2%",
                    backgroundColor: "grey",
                    color: "white",
                  }}
                >
                  <Icon style={{ marginRight: "2px" }}>add</Icon>
                  ASN
                </MDButton>
              </Tooltip>
            )}
            <Tooltip title="Publish">
              {publishAccess && (
                <ReleaseButton
                  disabled={purchase.isPublished === 1}
                  type="button"
                  variant="gradient"
                  color="success"
                  iconOnly
                  onClick={() => {
                    handleOpenPublishModal(purchase);
                  }}
                  style={{ marginLeft: "0.5px" }}
                >
                  {" "}
                  <Icon>forward</Icon>
                </ReleaseButton>
              )}
            </Tooltip>
            {editAccess && (
              <EditButton
                tooltip="Edit"
                disabled={purchase.isReleased === 1}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdatePurchaseOrderModal(purchase)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
            {deleteAccess && (
              <DeleteButton
                tooltip="Delete"
                disabled={purchase.isReleased === 1}
                variant="gradient"
                color="error"
                style={{ marginLeft: "5px" }}
                iconOnly
                type="button"
                onClick={() => handleOpenDeleteModal(purchase)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </div>
        ),
      };
      tempAsset.push(purchaseObject);
    });

    viewAccess && setState({ ...state, rows: tempAsset });
  }, [getPurchaseOrderReducer]);

  const [fileForTestCertificate, setFileForTestCertificate] = useState({});

  const handleChangeForTestCertificate = (event) => {
    setFileForTestCertificate(event.target.files[0]);
  };

  const [fileForInvoiceFile, setFileForInvoiceFile] = useState({});

  const handleChangeForInvoiceFile = (event) => {
    setFileForInvoiceFile(event.target.files[0]);
  };

  const [openCreateASNModal, setOpenCreateASNModal] = useState(false);

  const [singleASNData, setSingleASNData] = useState({});
  console.log(singleASNData);
  const handleOpenCreateASNModal = (ActualQty = {}) => {
    setSingleASNData(ActualQty);

    setOpenCreateASNModal(true);
    setIsErrorCreateASN(false);
    setIsSuccessCreateASN(false);
  };
  const handleCloseCreateASNModal = () => {
    setOpenCreateASNModal(false);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
    setAsnQtyChange("");
    window.location.reload();
  };
  const AsnCreationNotification = () =>
    toast.success("Asn Created Successfully", {
      position: "top-center",
      autoClose: 2000,
    });
  const [createAsnError, setCreateAsnError] = useState("");

  const [isErrorCreateASN, setIsErrorCreateASN] = useState(false);
  const [isSuccessCreateASN, setIsSuccessCreateASN] = useState(false);

  useEffect(() => {
    if (isErrorCreateASN) {
      setTimeout(() => {
        setIsErrorCreateASN(false);
      }, 3000);
    }
  }, [isErrorCreateASN]);

  useEffect(() => {
    if (isSuccessCreateASN) {
      setTimeout(() => {
        setIsSuccessCreateASN(false);
      }, 3000);
    }
  }, [isSuccessCreateASN]);

  const initialValues = {
    demandId: singleASNData ? singleASNData.id : "",
    partNoDes: `${singleASNData ? singleASNData.itemCode : ""}/${
      singleASNData ? singleASNData.itemDescription : ""
    }`,
    poNoLineNo: `${singleASNData ? singleASNData.purchaseOrderNumber : ""}/${
      singleASNData ? singleASNData.purchaseOrderLineNumber : ""
    }`,
    scheduleQuantity: singleASNData ? singleASNData.qty : "",
    asnQty: asnQtyChange ? asnQtyChange : "",
    balanceQty: singleASNData ? singleASNData.balanceQty : "",
    unloadingPoint: singleUnloadingPointData ? singleUnloadingPointData : "",
    deliveryPlant: singleDeliveryPlantData ? singleDeliveryPlantData : "",
    packageType: singleAsnPackageTypeData ? singleAsnPackageTypeData : "",
    packetNo: "",

    packetWeight: "",
    invoiceNo: singleASNData ? singleASNData.invoiceNo : "",
    invoiceDate: singleASNData ? singleASNData.invoiceDate : "",
    // invoiceFile: "",
    planDate: "",
  };

  const validationSchema = Yup.object({
    // partNoDes: Yup.string().required("Enter Part No/Description"),
    // poNoLineNo: Yup.string().required("Enter Po No/Line Number"),
    // scheduleQuantity: Yup.string().required("Enter Schedule Quantity"),
    asnQty: Yup.number()
      .min(1)
      .max(singleASNData.balanceQty)
      .required("Enter a valid ASN quantity"),
    // invoiceNo: Yup.string().required("Enter Invoice No"),
    // invoiceDate: Yup.string().required("Enter Invoice Date"),
    // // deliveryPlant: Yup.string()
    // //   .required("Enter a experience")
    // //   .min(1, "Invalid data")
    // //   .max(2, "Invalid data")
    // //   .test("Is positive", "must be positive", (val) => val >= 0),
    // deliveryPlant: Yup.string().required("Select Delivery Plant"),
    // typeOfPacket: Yup.string().required("Select Type of Packet"),
    // noOfPacket: Yup.string().required("Enter No of Packet"),
    // unitPackQuantity: Yup.string().required("Enter Unit Pack Quantity"),
    // unitPacketWeight: Yup.string().required("Enter Unit Packet Weight"),
  });

  // const handleChange = (event) => {
  //   setjobDescFile(event.target.files[0]);
  // };

  const [buttonNameForCreateAsn, setButtonNameForCreateAsn] =
    useState("Create ASN");

  const onSubmit = async (values, { resetForm }) => {
    setButtonNameForCreateAsn("Please wait...");
    let payload = {
      poNoLineNo: values.poNoLineNo,
      testCertificate: fileForTestCertificate,
      invoice: fileForInvoiceFile,
      asnDetails: JSON.stringify([
        {
          invoiceDate: invoiceDate,
          planDate: singleASNData ? singleASNData.date : "",
          itemCodeDes: values.partNoDes,
          invoiceNo: invoiceNo,
          asnQty: values.asnQty,
          packageType: singleAsnPackageTypeData,
          packetNo: singleAsnPackageDetailsData.packetQty,
          packetWeight: singleAsnPackageWeightData,
          unloadingPoint: singleUnloadingPointData,
          deliveryPlant: singleDeliveryPlantData,
          demandId: singleASNData ? singleASNData.demandId : "",
        },
      ]),
      vendorId: userId,
    };

    let response = await createAsn(payload, jwtToken);

    if (response.status === true) {
      setAsnCode(response.data.data.asnCode);
      setPackageType(response.data.data.packageType);

      setIsSuccessCreateASN(true);
      AsnCreationNotification();
      setTimeout(() => {
        setButtonNameForCreateAsn("Create ASN");
      }, 2250);
      // handleCloseCreateASNModal();
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);

      resetForm();
    }
    if (response.status === false) {
      setIsErrorCreateASN(true);
      setButtonNameForCreateAsn("Create ASN");
      setCreateAsnError(response?.data?.data?.msg);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const [invoiceDate, setInvoiceDate] = useState("");

  const [invoiceNo, setInvoiceNo] = useState("");

  const [openPrintASNModal, setOpenPrintASNModal] = useState(false);

  const handleClosePrintASNModal = () => {
    setOpenPrintASNModal(false);
  };

  const handleOpenPrintASNModal = () => {
    setOpenPrintASNModal(true);
  };

  let typeOptions = [
    {
      label: "Bin",
      value: "bin",
    },
    {
      label: "Pallet",
      value: "pallet",
    },
  ];

  const [openCreatePoModal, setOpenCreatePoModal] = useState(false);

  const handleOpenCreatePoModal = () => {
    setOpenCreatePoModal(true);
    formikForCreatePo.setValues(initialValueForCreatePo);
    formikForCreatePo.setTouched({});
    formikForCreatePo.setErrors({});
    setSelectedItemCode("");
    setSelectedVendorCode("");

    getVendorCodes(jwtToken);
    getItemMaster(jwtToken);
  };

  const handleCloseCreateProductionModal = () => {
    setOpenCreatePoModal(false);
  };

  const initialValueForCreatePo = {
    // partCodeDes: "",
    poDate: "",
    vendorCode: "",
    itemCode: "",
    poNo: "",
    poLineNo: "",
    reqQty: "",
  };
  const today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  let currentDate = yyyy + "-" + mm + "-" + dd;
  today.setHours(0, 0, 0, 0); // Set to start of the day
  const todayStr = today.toISOString().split("T")[0];

  const validationSchemaForPo = Yup.object({
    // partCodeDes: Yup.string().required("select partCode"),
    poDate: Yup.date().min(today, "Date cannot be in the past!"),
    poNo: Yup.string().required("Enter PO No."),
    vendorCode: Yup.object().required("Select Vendor Code"),
    itemCode: Yup.object().required("Select Item Code"),
    poLineNo: Yup.string().required("Enter PO Line No."),
    reqQty: Yup.string().required("Enter a qty"),
    unloadingPoint: Yup.string().required("Enter unloading point"),
    deliveryPlant: Yup.string().required("Enter delivery plant"),
  });

  const onSubmitForCreatePo = async (values, { resetForm }) => {
    let payload = {
      purchaseOrderNumber: values.poNo.trim(),
      purchaseOrderLineNumber: values.poLineNo.trim(),
      itemCode: selectedItemCode?.itemCode,
      itemId: selectedItemCode?.value,
      date: values.poDate,
      qty: values.reqQty,
      unloadingPoint: values.unloadingPoint.trim(),
      deliveryPlant: values.deliveryPlant.trim(),
      userId: userId,
      vendorCode: selectedVendorCode?.value,
    };

    let res = await createPurchaseOrder(payload, jwtToken);

    if (res.status) {
      setIsSuccessForCreateOrder(true);
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);
      setSelectedItemCode("");
      setSelectedVendorCode("");
      setTimeout(() => {
        handleCloseCreateProductionModal();
      }, 2000);

      resetForm();
    }
    if (!res.status) {
      setIsErrorForCreateOrder(true);
      setErrorMsgForCreate(res?.data?.data?.msg);
    }
  };

  const formikForCreatePo = useFormik({
    initialValues: initialValueForCreatePo,
    validationSchema: validationSchemaForPo,
    onSubmit: onSubmitForCreatePo,
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    singlePurchaseOrderDataForDelete,
    setSinglePurchaseOrderDataForDelete,
  ] = useState({});

  const handleOpenDeleteModal = (asset = {}) => {
    setSinglePurchaseOrderDataForDelete(asset);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);

  useEffect(() => {
    if (isErrorDelete) {
      setTimeout(() => {
        setIsErrorDelete(false);
      }, 3000);
    }
  }, [isErrorDelete]);

  useEffect(() => {
    if (isSuccessDelete) {
      setTimeout(() => {
        setIsSuccessDelete(false);
      }, 3000);
    }
  }, [isSuccessDelete]);

  const deletePurchaseOrderData = async () => {
    let purchaseOrderId = Number(
      singlePurchaseOrderDataForDelete.purchaseOrderId
    );

    let res = await deletePurchaseOrder(purchaseOrderId, userId, jwtToken);

    if (res.status) {
      setIsSuccessDelete(true);
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);

      // setTimeout(() => {
      //   handleCloseDeleteModal();
      // }, 2250);
    }
    if (!res.status) {
      setIsErrorDelete(true);
      setErrorMsgForDelete(res?.data?.data?.msg);
    }
  };
  const [isErrorMsgForRelease, setIsErrorMsgForRelease] = useState(false);
  const [isSuccessMsgForRelease, setIsSuccessMsgForRelease] = useState(false);

  const [openUpdatePurchaseOrderModal, setOpenUpdatePurchaseOrderModal] =
    useState(false);
  const [singlePurchaseOrderDataForUpdate, setPurchaseOrderDataForUpdate] =
    useState({});
  const handleOpenUpdatePurchaseOrderModal = (Asset = {}) => {
    setPurchaseOrderDataForUpdate(Asset);
    setOpenUpdatePurchaseOrderModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateProductionModal = () => {
    setOpenUpdatePurchaseOrderModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    reqQty: singlePurchaseOrderDataForUpdate.qty,
    eDate: singlePurchaseOrderDataForUpdate.date?.slice(0, 10),
  };

  //   const validationSchemaForUpdate = Yup.object({
  //     assetName: Yup.string().required("Enter the asset name!"),
  //     assetCode: Yup.string().required("Enter the asset code!"),
  //   });

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        itemCode: item.itemCode,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [selectedVendorCode, setSelectedVendorCode] = useState("");

  const handleVendorCodeChange = (selectedItemCode) => {
    setSelectedVendorCode(selectedItemCode);
  };

  const [vendorCodeOptions, setVendorCodeOptions] = useState([]);

  useEffect(() => {
    let tempVendorCodeOptions = [];
    let data = getVendorCodeReducer.vendors.data
      ? getVendorCodeReducer.vendors.data
      : [];

    data.map((item) => {
      tempVendorCodeOptions.push({
        label: item.vendorName,
        value: item.vendorCode,
      });
    });
    setVendorCodeOptions(tempVendorCodeOptions);
  }, [getVendorCodeReducer]);

  const onSubmitForUpdate = async (values) => {
    let payload = {
      purchaseOrderId: singlePurchaseOrderDataForUpdate.purchaseOrderId,
      qty: values.reqQty,
      date: values.eDate,
      userId: userId,
    };

    let res = await editPurchaseOrder(payload, jwtToken);

    if (res.status) {
      setIsSuccessForUpdate(true);
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleCloseUpdateProductionModal();
      }, 2000);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res?.data?.data?.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("purchase", uploadCsvData);

    let res = await uploadForPurchaseOrder(data, jwtToken);

    if (res.status) {
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  const headers = [
    {
      label: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
    },
    {
      label: "purchaseOrderLineNumber",
      key: "purchaseOrderLineNumber",
    },
    { label: "vendorCode", key: "vendorCode" },
    { label: "itemCode", key: "itemCode" },
    { label: "date", key: "date" },
    { label: "qty", key: "qty" },
    { label: "unloadingPoint", key: "unloadingPoint" },
    { label: "deliveryPlant", key: "deliveryPlant" },
  ];

  const csvLink = {
    filename: "Purchase Order.csv",
    headers: headers,
    data: rows,
  };

  const csvLink2 = {
    filename: "Purchase Order Template.csv",
    headers: headers,
    data: [],
  };
  const [openCreatePublishModal, setOpenCreatePublishModal] = useState(false);

  const handleOpenCreatePublishModal = (data = {}) => {
    setOpenCreatePublishModal(true);
  };

  const createPublishHandler = (event, data) => {
    handleOpenCreatePublishModal(data);
  };

  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState(false);

  const handleOpenCreateReleaseModal = (data = {}) => {
    setOpenCreateReleaseModal(true);
  };

  const handleCloseCreateReleaseModal = () => {
    setOpenCreateReleaseModal(false);
  };

  const createReleaseHandler = (event, data) => {
    handleOpenCreateReleaseModal(data);
  };

  const [uncheck, setUncheck] = useState(false);

  const [selectedReleaseData, setSelectedReleaseData] = useState([]);

  const ReleaseForData = (data) => {
    setSelectedReleaseData(data);
  };
  const onClickCreateReleaseList = async () => {
    let tempArray = [];
    selectedReleaseData.map((data) => {
      const tempPayload = {
        purchaseOrderId: data.purchaseOrderId,
      };

      tempArray.push(tempPayload);
    });

    let payload = {
      userId: userId,
      purchaseOrderIds: tempArray,
    };

    let res = await publishPurchaseOrder(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessRelease(true);
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseCreateReleaseModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorRelease(true);
      setIsErrorMsgForRelease(res?.data?.data?.msg);
    }
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let value = selectedReleaseData.some((data) => data.isReleased === 1);
    setDisableButton(value);
  }, [selectedReleaseData]);

  const [isErrorPublish, setIsErrorPublish] = useState(false);
  const [isSuccessPublish, setIsSuccessPublish] = useState(false);

  useEffect(() => {
    if (isErrorPublish) {
      setTimeout(() => {
        setIsErrorPublish(false);
      }, 3000);
    }
  }, [isErrorPublish]);

  useEffect(() => {
    if (isSuccessPublish) {
      setTimeout(() => {
        setIsSuccessPublish(false);
      }, 3000);
    }
  }, [isSuccessPublish]);

  const [isErrorMsgForPublish, setIsErrorMsgForPublish] = useState(false);

  const [singlePublishData, setSinglePublishData] = useState({});

  const [openPublishModal, setOpenPublishModal] = useState(false);

  const handleOpenPublishModal = (asset = {}) => {
    setSinglePublishData(asset);
    setOpenPublishModal(true);
  };
  const handleClosePublishModal = () => {
    setOpenPublishModal(false);
  };

  const publishData = async () => {
    let payload = [
      {
        purchaseOrderId: singlePublishData.purchaseOrderId,
      },
    ];

    let response = await publishPo(payload, jwtToken);

    if (response.status) {
      setIsSuccessPublish(true);
      getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleClosePublishModal();
      }, 2250);
    }
    if (!response.status) {
      setIsErrorPublish(true);
      setIsErrorMsgForPublish(response?.data?.data?.msg);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {/* {createAssetAccess && ( */}
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={3}
                  // mb={3}
                >
                  <MDBox color="text" px={2} ml={2} mb={1}>
                    {createAccess && (
                      <CreateButton
                        tooltip="Create Purchase Order"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreatePoModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    )}

                    <CSVLink {...csvLink2}>
                      <DownloadButton
                        tooltip="Download Template"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadButton>
                    </CSVLink>

                    {uploadAccess && (
                      <UploadButton
                        tooltip="Upload Purchase Order"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="info"
                        iconOnly
                        onClick={uploadCsvFilterHandler}
                      >
                        <Icon>upload</Icon>
                      </UploadButton>
                    )}

                    {downloadAccess && (
                      <CSVLink {...csvLink}>
                        <DownloadDataButton
                          tooltip="Download Purchase Order List"
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="success"
                          iconOnly
                        >
                          <Icon>download</Icon>
                        </DownloadDataButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDBox>
                {uploadCsvFilter && (
                  <div>
                    <div>
                      <input
                        className="choose_file"
                        style={{
                          // marginTop: "1.5rem",
                          marginLeft: "1.5rem",
                          // marginBottom: "-9rem",
                        }}
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          setUploadCsvData(e.target.files[0]);
                        }}
                      />

                      <MDButton
                        disabled={
                          !uploadCsvData.name ||
                          uploadCsvData.type !== "text/csv"
                        }
                        variant="contained"
                        color="info"
                        style={{
                          marginLeft: { xs: "1.5rem", lg: "1" },
                          marginTop: "0rem",
                        }}
                        type="button"
                        onClick={uploadCsvHandler}
                      >
                        UPLOAD
                      </MDButton>
                    </div>
                    <div>
                      <span
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontStyle: "italic",
                        }}
                      >
                        <strong>*</strong> Please upload
                        <strong>".csv"</strong> file only
                      </span>
                    </div>
                  </div>
                )}

                <MDBox mt={0}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                    searchProp={importSearch}
                  />
                </MDBox>
                <Modal
                  open={openCreatePoModal}
                  onClose={handleCloseCreateProductionModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForCreatePo}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Purchase Order
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateProductionModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreatePo.handleSubmit}
                      >
                        <MDBox>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Purchase Order Date
                                </MDTypography>
                                <MDInput
                                  type="date"
                                  name="poDate"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForCreatePo.handleBlur}
                                  onChange={formikForCreatePo.handleChange}
                                  value={formikForCreatePo.values.poDate}
                                  error={
                                    formikForCreatePo.touched.poDate &&
                                    formikForCreatePo.errors.poDate &&
                                    true
                                  }
                                />
                                {formikForCreatePo.touched.poDate &&
                                  formikForCreatePo.errors.poDate && (
                                    <TextError
                                      msg={formikForCreatePo.errors.poDate}
                                    />
                                  )}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Item Code/Des
                                </MDTypography>

                                <Select
                                  isClearable
                                  className="select-css-for-itemcode"
                                  maxMenuHeight={130}
                                  placeholder="Choose one..."
                                  name="itemCode"
                                  onBlur={() => {
                                    formikForCreatePo.handleBlur({
                                      target: { name: "itemCode" },
                                    });
                                  }}
                                  value={selectedItemCode}
                                  options={itemCodeOptions}
                                  onChange={(selectedOption) => {
                                    handleItemCodeChange(selectedOption);
                                    formikForCreatePo.setFieldValue(
                                      "itemCode",
                                      selectedOption
                                    );
                                  }}
                                />

                                {formikForCreatePo.touched.itemCode &&
                                  formikForCreatePo.errors.itemCode && (
                                    <TextError
                                      msg={formikForCreatePo.errors.itemCode}
                                    />
                                  )}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Vendor Code
                                </MDTypography>

                                <Select
                                  isClearable
                                  className="select-css"
                                  maxMenuHeight={130}
                                  placeholder="Choose one..."
                                  name="vendorCode"
                                  onBlur={() => {
                                    formikForCreatePo.handleBlur({
                                      target: { name: "vendorCode" },
                                    });
                                  }}
                                  value={selectedVendorCode}
                                  options={vendorCodeOptions}
                                  onChange={(selectedOption) => {
                                    handleVendorCodeChange(selectedOption);
                                    formikForCreatePo.setFieldValue(
                                      "vendorCode",
                                      selectedOption
                                    );
                                  }}
                                />

                                {formikForCreatePo.touched.vendorCode &&
                                  formikForCreatePo.errors.vendorCode && (
                                    <TextError
                                      msg={formikForCreatePo.errors.vendorCode}
                                    />
                                  )}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  PO No
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="poNo"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForCreatePo.handleBlur}
                                  onChange={formikForCreatePo.handleChange}
                                  value={formikForCreatePo.values.poNo}
                                  error={
                                    formikForCreatePo.touched.poNo &&
                                    formikForCreatePo.errors.poNo &&
                                    true
                                  }
                                />
                                {formikForCreatePo.touched.poNo &&
                                  formikForCreatePo.errors.poNo && (
                                    <TextError
                                      msg={formikForCreatePo.errors.poNo}
                                    />
                                  )}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  PO Line No
                                </MDTypography>
                                <MDInput
                                  type="number"
                                  name="poLineNo"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForCreatePo.handleBlur}
                                  onChange={formikForCreatePo.handleChange}
                                  value={formikForCreatePo.values.poLineNo}
                                  error={
                                    formikForCreatePo.touched.poLineNo &&
                                    formikForCreatePo.errors.poLineNo &&
                                    true
                                  }
                                />
                                {formikForCreatePo.touched.poLineNo &&
                                  formikForCreatePo.errors.poLineNo && (
                                    <TextError
                                      msg={formikForCreatePo.errors.poLineNo}
                                    />
                                  )}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Unloading Point
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="unloadingPoint"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForCreatePo.handleBlur}
                                  onChange={formikForCreatePo.handleChange}
                                  value={
                                    formikForCreatePo.values.unloadingPoint
                                  }
                                  error={
                                    formikForCreatePo.touched.unloadingPoint &&
                                    formikForCreatePo.errors.unloadingPoint &&
                                    true
                                  }
                                  inputProps={{
                                    maxLength: 45,
                                  }}
                                />
                                {formikForCreatePo.touched.unloadingPoint &&
                                  formikForCreatePo.errors.unloadingPoint && (
                                    <TextError
                                      msg={
                                        formikForCreatePo.errors.unloadingPoint
                                      }
                                    />
                                  )}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Delivery Plant
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="deliveryPlant"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForCreatePo.handleBlur}
                                  onChange={formikForCreatePo.handleChange}
                                  value={formikForCreatePo.values.deliveryPlant}
                                  error={
                                    formikForCreatePo.touched.deliveryPlant &&
                                    formikForCreatePo.errors.deliveryPlant &&
                                    true
                                  }
                                  inputProps={{
                                    maxLength: 45,
                                  }}
                                />
                                {formikForCreatePo.touched.deliveryPlant &&
                                  formikForCreatePo.errors.deliveryPlant && (
                                    <TextError
                                      msg={
                                        formikForCreatePo.errors.deliveryPlant
                                      }
                                    />
                                  )}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Required Qty
                                </MDTypography>

                                <MDInput
                                  type="number"
                                  name="reqQty"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForCreatePo.handleBlur}
                                  onChange={formikForCreatePo.handleChange}
                                  value={formikForCreatePo.values.reqQty}
                                  error={
                                    formikForCreatePo.touched.reqQty &&
                                    formikForCreatePo.errors.reqQty &&
                                    true
                                  }
                                />
                                {formikForCreatePo.touched.reqQty &&
                                  formikForCreatePo.errors.reqQty && (
                                    <TextError
                                      msg={formikForCreatePo.errors.reqQty}
                                    />
                                  )}
                              </MDBox>
                            </Grid>
                            <MDBox mt={2}>
                              <Collapse in={isSuccessForCreateOrder}>
                                <Alert
                                  severity="success"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsSuccessForCreateOrder(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  Purchase Order created successfully!
                                </Alert>
                              </Collapse>
                              <Collapse in={isErrorForCreateOrder}>
                                <Alert
                                  severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsErrorForCreateOrder(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {errorMsgForCreate}
                                </Alert>
                              </Collapse>
                            </MDBox>

                            <MDButton
                              type="submit"
                              color="info"
                              fullWidth
                              disabled={!formikForCreatePo.isValid}
                            >
                              Create
                            </MDButton>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openUpdatePurchaseOrderModal}
                  onClose={handleCloseUpdateProductionModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Update
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseUpdateProductionModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForUpdate.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6"> Qty</MDTypography>
                          <MDInput
                            type="number"
                            name="reqQty"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.reqQty}
                            error={
                              formikForUpdate.touched.reqQty &&
                              formikForUpdate.errors.reqQty &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.reqQty &&
                            formikForUpdate.errors.reqQty && (
                              <TextError msg={formikForUpdate.errors.reqQty} />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6"> Date</MDTypography>
                          <MDInput
                            type="date"
                            name="eDate"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.eDate}
                            error={
                              formikForUpdate.touched.eDate &&
                              formikForUpdate.errors.eDate &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.eDate &&
                            formikForUpdate.errors.eDate && (
                              <TextError msg={formikForUpdate.errors.eDate} />
                            )}
                        </MDBox>
                        <MDBox>
                          <Collapse in={isErrorForUpdate}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForUpdate}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForUpdate}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Updated successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formikForUpdate.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openDeleteModal}
                  onClose={handleCloseDeleteModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to delete?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseDeleteModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deletePurchaseOrderData}
                        >
                          Delete
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDelete}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDelete}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openCreateReleaseModal}
                  onClose={handleCloseCreateReleaseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForRelease}>
                    <MDTypography>
                      Are you sure you want to Release?
                    </MDTypography>
                    <MDBox mt={2}>
                      <MDButton
                        style={{ width: "10rem", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseCreateReleaseModal}
                      >
                        Cancel
                      </MDButton>
                      <MDButton
                        style={{ width: "10rem", marginLeft: "0.5rem" }}
                        color="info"
                        onClick={onClickCreateReleaseList}
                      >
                        Release
                      </MDButton>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessRelease}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessRelease(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Released successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorRelease}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorRelease(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgForRelease}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openPublishModal}
                  onClose={handleClosePublishModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to Publish?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "1.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleClosePublishModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="success"
                          onClick={publishData}
                        >
                          Publish
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessPublish}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessPublish(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Purchase Order Published successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorPublish}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorPublish(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgForPublish}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openCreateASNModal}
                  onClose={handleCloseCreateASNModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForCreateASN}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create ASN
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseCreateASNModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              padding: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr />

                    <MDBox pb={3} px={1} overflow="auto" height="500px">
                      <MDBox pt={1} px={1}>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={formik.handleSubmit}
                        >
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={4}>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Part No/Description
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="partNoDes"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.partNoDes}
                                  error={
                                    formik.touched.partNoDes &&
                                    formik.errors.partNoDes &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,
                                    maxLength: 45,
                                  }}
                                />
                                {formik.touched.partNoDes &&
                                  formik.errors.partNoDes && (
                                    <TextError
                                      style={{ marginBottom: "-10px" }}
                                      msg={formik.errors.partNoDes}
                                    />
                                  )}
                              </MDBox>

                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Po No/Line Number{" "}
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="poNoLineNo"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.poNoLineNo}
                                  error={
                                    formik.touched.poNoLineNo &&
                                    formik.errors.poNoLineNo &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "14px",
                                    },
                                    readOnly: true,

                                    maxLength: 45,
                                  }}
                                />
                                {formik.touched.poNoLineNo &&
                                  formik.errors.poNoLineNo && (
                                    <TextError msg={formik.errors.poNoLineNo} />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Type of Packet{" "}
                                </MDTypography>

                                <MDInput
                                  type="text"
                                  name="packageType"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={singleAsnPackageTypeData}
                                  error={
                                    formik.touched.packageType &&
                                    formik.errors.packageType &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,

                                    maxLength: 45,
                                  }}
                                />
                                {formik.touched.packageType &&
                                  formik.errors.packageType && (
                                    <TextError
                                      msg={formik.errors.packageType}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Unloading Point{" "}
                                </MDTypography>

                                <MDInput
                                  type="text"
                                  name="unloadingPoint"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={singleUnloadingPointData}
                                  error={
                                    formik.touched.unloadingPoint &&
                                    formik.errors.unloadingPoint &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,

                                    maxLength: 45,
                                  }}
                                />

                                {formik.touched.unloadingPoint &&
                                  formik.errors.unloadingPoint && (
                                    <TextError
                                      msg={formik.errors.unloadingPoint}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Delivery Plant{" "}
                                </MDTypography>

                                <MDInput
                                  type="text"
                                  name="deliveryPlant"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={singleDeliveryPlantData}
                                  error={
                                    formik.touched.deliveryPlant &&
                                    formik.errors.deliveryPlant &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "14px",
                                      marginTop: "1px",
                                    },
                                    readOnly: true,

                                    maxLength: 45,
                                  }}
                                />

                                {formik.touched.deliveryPlant &&
                                  formik.errors.deliveryPlant && (
                                    <TextError
                                      msg={formik.errors.deliveryPlant}
                                    />
                                  )}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Schedule Quantity{" "}
                                </MDTypography>
                                <MDInput
                                  type="number"
                                  name="scheduleQuantity"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.scheduleQuantity}
                                  error={
                                    formik.touched.scheduleQuantity &&
                                    formik.errors.scheduleQuantity &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,

                                    maxLength: 45,
                                  }}
                                />
                                {formik.touched.scheduleQuantity &&
                                  formik.errors.scheduleQuantity && (
                                    <TextError
                                      msg={formik.errors.scheduleQuantity}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Balance Quantity
                                </MDTypography>
                                <MDInput
                                  type="number"
                                  name="balanceQty"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    singleASNData.balanceQty - asnQtyChange
                                  }
                                  error={
                                    formik.touched.balanceQty &&
                                    formik.errors.balanceQty &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,
                                    inputProps: {
                                      max: singleASNData.balanceQty,
                                      min: 1,
                                    },
                                  }}
                                />
                                {formik.touched.balanceQty &&
                                  formik.errors.balanceQty && (
                                    <TextError msg={formik.errors.balanceQty} />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  ASN Quantity{" "}
                                  <Tooltip title="Required" placement="right">
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: 5,
                                        marginBottom: 15,
                                      }}
                                    >
                                      *
                                    </span>
                                  </Tooltip>
                                </MDTypography>
                                <MDInput
                                  type="number"
                                  name="asnQty"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setAsnQtyChange(e.target.value);
                                    calculatePacketDetails(
                                      e.target.value,
                                      partCode,
                                      userId,
                                      jwtToken
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.asnQty}
                                  error={
                                    formik.touched.asnQty &&
                                    formik.errors.asnQty &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                  }}
                                />
                                {formik.touched.asnQty &&
                                  formik.errors.asnQty && (
                                    <MDBox mb={-3}>
                                      <TextError msg={formik.errors.asnQty} />
                                    </MDBox>
                                  )}
                              </MDBox>

                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  No of Packet/Bin{" "}
                                </MDTypography>
                                <MDInput
                                  type="number"
                                  name="packetNo"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={singleAsnPackageDetailsData.packetQty}
                                  error={
                                    formik.touched.packetNo &&
                                    formik.errors.packetNo &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,
                                  }}
                                />
                                {formik.touched.packetNo &&
                                  formik.errors.packetNo && (
                                    <TextError msg={formik.errors.packetNo} />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Unit Pack Quantity{" "}
                                </MDTypography>
                                <MDInput
                                  type="number"
                                  name="unitPackQuantity"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    singleAsnPackageDetailsData.unitPackQty
                                  }
                                  error={
                                    formik.touched.unitPackQuantity &&
                                    formik.errors.unitPackQuantity &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "14px",
                                      marginTop: "2px",
                                    },
                                    readOnly: true,
                                  }}
                                />
                                {formik.touched.unitPackQuantity &&
                                  formik.errors.unitPackQuantity && (
                                    <TextError
                                      msg={formik.errors.unitPackQuantity}
                                    />
                                  )}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Unit Packet Weight (kg)
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="packetWeight"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={singleAsnPackageWeightData}
                                  error={
                                    formik.touched.packetWeight &&
                                    formik.errors.packetWeight &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "13px",
                                    },
                                    readOnly: true,

                                    maxLength: 45,
                                  }}
                                />
                                {formik.touched.packetWeight &&
                                  formik.errors.packetWeight && (
                                    <TextError
                                      msg={formik.errors.packetWeight}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Invoice No{" "}
                                  <Tooltip title="Required" placement="right">
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: 5,
                                        marginBottom: 15,
                                      }}
                                    >
                                      *
                                    </span>
                                  </Tooltip>
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="invoiceNo"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setInvoiceNo(e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={invoiceNo}
                                  error={
                                    formik.touched.invoiceNo &&
                                    formik.errors.invoiceNo &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "12px",
                                    },

                                    maxLength: 45,
                                  }}
                                />
                                {formik.touched.invoiceNo &&
                                  formik.errors.invoiceNo && (
                                    <MDBox mb={-3}>
                                      <TextError
                                        msg={formik.errors.invoiceNo}
                                      />
                                    </MDBox>
                                  )}
                              </MDBox>

                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    // marginBottom: "2px",
                                  }}
                                >
                                  Invoice Date{" "}
                                  <Tooltip title="Required" placement="right">
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: 5,
                                        // marginBottom: 5,
                                      }}
                                    >
                                      *
                                    </span>
                                  </Tooltip>
                                </MDTypography>

                                <MDInput
                                  type="date"
                                  name="invoiceDate"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setInvoiceDate(e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={invoiceDate}
                                  error={
                                    formik.touched.invoiceDate &&
                                    formik.errors.invoiceDate &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "12.5px",
                                    },

                                    max: currentDate,
                                  }}
                                />

                                {formik.touched.invoiceDate &&
                                  formik.errors.invoiceDate && (
                                    <MDBox mb={-3}>
                                      <TextError
                                        msg={formik.errors.invoiceDate}
                                      />
                                    </MDBox>
                                  )}
                              </MDBox>

                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Invoice (PDF Only)
                                  <Tooltip title="Required" placement="right">
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: 5,
                                        // marginBottom: 15,
                                      }}
                                    >
                                      *
                                    </span>
                                  </Tooltip>
                                </MDTypography>
                                <input
                                  name="invoiceFile"
                                  style={{
                                    borders: "none",
                                    marginTop: "0px",
                                    width: "100%",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  autoComplete="off"
                                  accept=".pdf"
                                  id="chooseFile"
                                  onChange={(event) =>
                                    handleChangeForInvoiceFile(event)
                                  }
                                />
                              </MDBox>

                              <MDBox mb={3} mt={2}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  flexGrow={1}
                                  style={{ fontWeight: "500", fontSize: 14 }}
                                >
                                  Test Certificate (PDF Only)
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      // marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span>
                                </MDTypography>
                                <input
                                  name="testCertificate"
                                  style={{
                                    borders: "none",
                                    marginTop: "0px",
                                    width: "100%",
                                    // height:"15px"
                                  }}
                                  className="choose_file"
                                  type="file"
                                  autoComplete="off"
                                  accept=".pdf"
                                  id="chooseFile"
                                  onChange={(event) =>
                                    handleChangeForTestCertificate(event)
                                  }
                                />
                              </MDBox>
                            </Grid>
                          </Grid>

                          <MDBox>
                            <Collapse in={isErrorCreateASN}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateASN(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {createAsnError}
                              </Alert>
                            </Collapse>
                            <Collapse in={isSuccessCreateASN}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateASN(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                ASN created successfully
                              </Alert>
                            </Collapse>
                          </MDBox>
                          <MDBox mt={2} display="flex">
                            <MDBox mr={2}>
                              <MDButton
                                color="info"
                                type="submit"
                                marginRight="10px"
                                disabled={
                                  !formik.isValid ||
                                  !fileForInvoiceFile.name ||
                                  !fileForTestCertificate.name ||
                                  fileForTestCertificate.type !==
                                    "application/pdf" ||
                                  fileForInvoiceFile.type !==
                                    "application/pdf" ||
                                  invoiceDate === "" ||
                                  invoiceNo === "" ||
                                  buttonNameForCreateAsn === "Please wait..."
                                }
                              >
                                {buttonNameForCreateAsn}
                              </MDButton>
                            </MDBox>
                            <MDBox>
                              <MDButton
                                color="success"
                                type="submit"
                                marginLeft="20px"
                                onClick={() => {
                                  // setPrintAsnDetailsInvoiceNo(
                                  //   formik.values.invoiceDate
                                  // );
                                  handleOpenPrintASNModal();
                                }}
                                disabled={
                                  !(
                                    /Can/i.test(packageType) ||
                                    /Carton/i.test(packageType) ||
                                    /Corrugated/i.test(packageType)
                                  )
                                }
                              >
                                Print PKT QTY
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getPurchaseOrderReducer: state.getPurchaseOrderReducer,
    profileData: state.profile,
    itemMaster: state.itemMaster,
    getVendorCodeReducer: state.getVendorCodeReducer,
    packageTypeReducer: state.packageTypeReducer,
    packageDetailsReducer: state.packageDetailsReducer,
    deliveryPlantForPOReducer: state.deliveryPlantForPOReducer,
    unloadingPointForPOReducer: state.unloadingPointForPOReducer,
    packetWeightReducer: state.packetWeightReducer,
    binsForPrintReducer: state.binsForPrintReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPurchaseOrder: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken)),
    getVendorCodes: (jwtToken) => dispatch(getVendorCodes(jwtToken)),
    deletePurchaseOrder: (purchaseOrderId, userId, jwtToken) =>
      dispatch(deletePurchaseOrder(purchaseOrderId, userId, jwtToken)),
    publishPurchaseOrder: (payload, jwtToken) =>
      dispatch(publishPurchaseOrder(payload, jwtToken)),
    createPurchaseOrder: (payload, jwtToken) =>
      dispatch(createPurchaseOrder(payload, jwtToken)),
    editPurchaseOrder: (payload, jwtToken) =>
      dispatch(editPurchaseOrder(payload, jwtToken)),
    uploadForPurchaseOrder: (payload, jwtToken) =>
      dispatch(uploadForPurchaseOrder(payload, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    publishPo: (payload, jwtToken) => dispatch(publishPo(payload, jwtToken)),

    getBinsForPrint: (asnCode, jwtToken) =>
      dispatch(getBinsForPrint(asnCode, jwtToken)),

    getPackageType: (partCode, jwtToken) =>
      dispatch(getPackageType(partCode, jwtToken)),
    calculatePacketWeight: (partCode, asnQtyChange, jwtToken) =>
      dispatch(calculatePacketWeight(partCode, asnQtyChange, jwtToken)),
    getUnloadingPointForPO: (id, jwtToken) =>
      dispatch(getUnloadingPointForPO(id, jwtToken)),
    getDeliveryPlantForPO: (id, jwtToken) =>
      dispatch(getDeliveryPlantForPO(id, jwtToken)),
    createAsn: (payload, jwtToken) => dispatch(createAsn(payload, jwtToken)),
    calculatePacketDetails: (asnQty, partCode, userId, jwtToken) =>
      dispatch(calculatePacketDetails(asnQty, partCode, userId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);
