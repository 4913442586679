/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getStatus,
  getSubModulesByRoleId,
  resendVendorRegistrationLink,
  updateEmail,
} from "../../store";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Cookies
import Cookies from "universal-cookie";
import ViewButton from "components/standardized-components/ViewButton";
import EditButton from "components/standardized-components/EditButton";
import StandardButton from "components/standardized-components/StandardButton";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 275,
  height: 235,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const Status = ({
  getStatus,
  statusReducer,
  resendVendorRegistrationLink,
  resendVendorRegistrationLinkReducer,
  getSubModulesByRoleIdReducer,
  updateEmail,
  updateEmailReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let statusModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "df1b3bed-2f1b-4361-b0b6-3dfa98ad5eb6"
  );
  let resendFormLink = statusModule
    ? statusModule.actionId.includes("c15899ec-f4ae-488e-964c-83f7cf680202")
    : null;
  let viewVendors = statusModule
    ? statusModule.actionId.includes("51bf3422-b376-434f-a8de-98dd5fd90384")
    : null;
  let editEmail = statusModule
    ? statusModule.actionId.includes("27aed331-d718-466e-a842-5d4f32287bc5")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const navigate = useNavigate();
  useEffect(() => {
    viewVendors && getStatus(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [viewVendors, pageNo, rowsPerPage, searchObject]);

  const viewFunction = (data) => {
    navigate(`/status/${data.vendorUserId}`);
  };

  const [state, setState] = useState({
    columns: [
      { Header: "vendor Name", accessor: "vendorName" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Email", accessor: "email" },
      { Header: "Form Filled", accessor: "isFilled" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "Action", accessor: "action", cannotSearch: true },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [openResendLinkModal, setOpenResendLinkModal] = useState(false);
  const [singleDataForResendLinkModal, setSingleDataForResendLinkModal] =
    useState({});
  const handleOpenResendLinkModal = (Data = {}) => {
    setSingleDataForResendLinkModal(Data);
    setOpenResendLinkModal(true);
  };
  const handleCloseResendLinkModal = () => {
    setOpenResendLinkModal(false);
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const [updateErrorMsg, setUpdateErrorMsg] = useState("");

  const [openEditEmailModal, setOpenEditEmailModal] = useState(false);

  const [singleEmailForUpdate, setSingleEmailForUpdate] = useState({});

  const handleOpenEditEmailModal = (Data = {}) => {
    setSingleEmailForUpdate(Data);
    setOpenEditEmailModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };

  const handleCloseEditEmailModal = () => {
    setOpenEditEmailModal(false);
  };

  const initialValues = {
    vendorId: singleEmailForUpdate.vendorId
      ? singleEmailForUpdate.vendorId
      : "",
    email: singleEmailForUpdate.email ? singleEmailForUpdate.email : null,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-mail!"),
  });

  const [emailButtonName, setEmailButtonName] = useState("Submit");

  const onSubmitForUpdateEmail = async (values) => {
    setEmailButtonName("Please wait...");
    let payload = {
      email: values.email.trim(),
      vendorId: `${singleEmailForUpdate?.vendorUserId}`,
    };
    let response = await updateEmail(payload, jwtToken);

    if (response.status) {
      handleCloseEditEmailModal();
      getStatus(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessForUpdate(true);
      setSingleEmailForUpdate({});
      setTimeout(() => {
        setEmailButtonName("Submit");
      }, 2250);
    }
    if (!response.status) {
      setUpdateErrorMsg(response?.data?.data?.msg);
      setIsErrorForUpdate(true);
      setEmailButtonName("Submit");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitForUpdateEmail,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const resendLinkNotification = () =>
    toast.success("Vendor Registration Link Send Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const [isErrorResendLink, setIsErrorResendLink] = useState(false);

  useEffect(() => {
    if (isErrorResendLink) {
      setTimeout(() => {
        setIsErrorResendLink(false);
      }, 3000);
    }
  }, [isErrorResendLink]);

  const [resendButtonName, setResendButtonName] = useState("Resend");
  const [resendMsgButtonName, setResendMsgButtonName] = useState("");

  const handleResendLink = async () => {
    setResendButtonName("Please wait...");
    let payload = {
      email: singleDataForResendLinkModal.email,
    };
    const res = await resendVendorRegistrationLink(payload);
    if (res.status) {
      handleCloseResendLinkModal();
      setTimeout(() => {
        getStatus(pageNo, rowsPerPage, searchObject, jwtToken);
        resendLinkNotification();
      }, 0);
      setTimeout(() => {
        setResendButtonName("Resend");
      }, 2250);
    }
    if (!res.status) {
      setResendButtonName("Resend");
      setIsErrorResendLink(true);
      setResendMsgButtonName(res.data.data.error);
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempStatus = [];
    let usersData = statusReducer.statusData.data
      ? statusReducer.statusData.data
      : [];

    setPaginationObj(statusReducer?.statusData?.pagination);
    setLoading(statusReducer.loading);
    usersData.map((user) => {
      const formatDate = new Date(user.cd);
      const singleUser = {
        privateKey: user.email,
        vendorName: user.vendorName,
        contactPerson: user.contactPerson,
        email: user.email,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        isFilled: (
          <div>{usersData && user.isFilled == true ? "Yes" : "No"}</div>
        ),
        action: (
          <div style={{ display: "flex" }}>
            {usersData && user.isFilled == true ? (
              <>
                <ViewButton
                  tooltip="View"
                  variant="gradient"
                  color="info"
                  iconOnly
                  type="button"
                  onClick={() => viewFunction(user)}
                  style={{ marginLeft: "0px", marginRight: "5px" }}
                >
                  <Icon>visibility_icon</Icon>
                </ViewButton>
              </>
            ) : (
              <>
                {resendFormLink && (
                  <StandardButton
                    tooltip="Resend Link"
                    variant="gradient"
                    color="primary"
                    iconOnly
                    icon="send"
                    type="button"
                    onClick={() => handleOpenResendLinkModal(user)}
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    <Icon>send</Icon>
                  </StandardButton>
                )}
              </>
            )}

            <>
              {editEmail && (
                <EditButton
                  tooltip="Edit Email"
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  onClick={() => handleOpenEditEmailModal(user)}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  <Icon>edit</Icon>
                </EditButton>
              )}
            </>
          </div>
        ),
      };
      tempStatus.push(singleUser);
    });
    setState({ ...state, rows: tempStatus });
  }, [statusReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {!loading ? ( */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <Modal
                open={openResendLinkModal}
                onClose={handleCloseResendLinkModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Card sx={style}>
                  <MDTypography mb={2}>
                    Are you sure you want to Resend Vendor Registration Link ?
                  </MDTypography>
                  <MDCard mt={2}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="secondary"
                          variant="contained"
                          onClick={handleCloseResendLinkModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="warning"
                          onClick={handleResendLink}
                          disabled={resendButtonName === "Please wait..."}
                        >
                          {resendButtonName}
                        </MDButton>
                      </div>
                    </div>
                  </MDCard>
                  <MDCard mt={2}>
                    <Collapse in={isErrorResendLink}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorResendLink(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {resendMsgButtonName}
                      </Alert>
                    </Collapse>
                  </MDCard>
                </Card>
              </Modal>
              <Modal
                open={openEditEmailModal}
                onClose={handleCloseEditEmailModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Enter Email
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseEditEmailModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={1}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          name="email"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          error={
                            formik.touched.email && formik.errors.email && true
                          }
                        />
                        {formik.touched.email && formik.errors.email && (
                          <TextError msg={formik.errors.email} />
                        )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {updateErrorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Email updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={2}>
                        <MDButton
                          variant="contained"
                          color="success"
                          type="submit"
                          fullWidth
                          disabled={
                            !formik.isValid ||
                            emailButtonName === "Please wait..."
                          }
                        >
                          {emailButtonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      {/* {!loading ? <Footer /> : null} */}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    statusReducer: state.statusReducer,
    updateEmailReducer: state.updateEmailReducer,
    resendVendorRegistrationLinkReducer:
      state.resendVendorRegistrationLinkReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getStatus: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getStatus(pageNo, rowsPerPage, searchObject, jwtToken)),
    updateEmail: (payload, jwtToken) =>
      dispatch(updateEmail(payload, jwtToken)),
    resendVendorRegistrationLink: (payload) =>
      dispatch(resendVendorRegistrationLink(payload)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Status);
