import {
  CREATE_CATEGORY_MAPPING_FAILURE,
  CREATE_CATEGORY_MAPPING_REQUEST,
  CREATE_CATEGORY_MAPPING_SUCCESS,
  GET_ACN_FOR_CATEGORY_FAILURE,
  GET_ACN_FOR_CATEGORY_REQUEST,
  GET_ACN_FOR_CATEGORY_SUCCESS,
  GET_CATEGORY_MAPPING_FAILURE,
  GET_CATEGORY_MAPPING_REQUEST,
  GET_CATEGORY_MAPPING_SUCCESS,
  GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_CATEGORYITEMS_REQUEST,
  GET_CATEGORYITEMS_SUCCESS,
  GET_CATEGORYITEMS_FAILURE,
} from "./categoryLocationMappingTypes";

import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getCategoryLocationMappingRequest = () => {
  return {
    type: GET_CATEGORY_MAPPING_REQUEST,
  };
};

const getCategoryLocationMappingSuccess = (payload) => {
  return {
    type: GET_CATEGORY_MAPPING_SUCCESS,
    payload: payload,
  };
};

const getCategoryLocationMappingFailure = (error) => {
  return {
    type: GET_CATEGORY_MAPPING_FAILURE,
    payload: error,
  };
};

const getCategoryLocationMapping = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCategoryLocationMappingRequest());
    let url = `${baseUrl}/mvtmap_category?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCategoryLocationMappingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getCategoryLocationMappingFailure(err.message));
      });
  };
};

const createCategoryLocationMappingRequest = () => {
  return {
    type: CREATE_CATEGORY_MAPPING_REQUEST,
  };
};

const createCategoryLocationMappingSuccess = (payload) => {
  return {
    type: CREATE_CATEGORY_MAPPING_SUCCESS,
    payload: payload,
  };
};

const createCategoryLocationMappingFailure = (error) => {
  return {
    type: CREATE_CATEGORY_MAPPING_FAILURE,
    payload: error,
  };
};

const createCategoryLocationMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createCategoryLocationMappingRequest());
    let url = `${baseUrl}/mvtmap_category`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createCategoryLocationMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createCategoryLocationMappingFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getLevel1ByLevelForCategoryRequest = () => {
  return {
    type: GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_REQUEST,
  };
};

const getLevel1ByLevelForCategorySuccess = (payload) => {
  return {
    type: GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const getLevel1ByLevelForCategoryFailure = (error) => {
  return {
    type: GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_FAILURE,
    payload: error,
  };
};

const getLevel1ByLevelForCategory = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel1ByLevelForCategoryRequest());
    let url = `${baseUrl}/location/level?level=1&warehouse_id=${warehouseId}&parent_id=0`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel1ByLevelForCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel1ByLevelForCategoryFailure(err.message));
      });
  };
};

const getLevel2ByLevelForCategoryRequest = () => {
  return {
    type: GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_REQUEST,
  };
};

const getLevel2ByLevelForCategorySuccess = (payload) => {
  return {
    type: GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const getLevel2ByLevelForCategoryFailure = (error) => {
  return {
    type: GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_FAILURE,
    payload: error,
  };
};

const getLevel2ByLevelForCategory = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel2ByLevelForCategoryRequest());
    let url = `${baseUrl}/location/level?level=2&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel2ByLevelForCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel2ByLevelForCategoryFailure(err.message));
      });
  };
};

const getLevel3ByLevelForCategoryRequest = () => {
  return {
    type: GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_REQUEST,
  };
};

const getLevel3ByLevelForCategorySuccess = (payload) => {
  return {
    type: GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const getLevel3ByLevelForCategoryFailure = (error) => {
  return {
    type: GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_FAILURE,
    payload: error,
  };
};

const getLevel3ByLevelForCategory = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel3ByLevelForCategoryRequest());
    let url = `${baseUrl}/location/level?level=3&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel3ByLevelForCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel3ByLevelForCategoryFailure(err.message));
      });
  };
};

const getLevel4ByLevelForCategoryRequest = () => {
  return {
    type: GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_REQUEST,
  };
};

const getLevel4ByLevelForCategorySuccess = (payload) => {
  return {
    type: GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const getLevel4ByLevelForCategoryFailure = (error) => {
  return {
    type: GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_FAILURE,
    payload: error,
  };
};

const getLevel4ByLevelForCategory = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel4ByLevelForCategoryRequest());
    let url = `${baseUrl}/location/level?level=4&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel4ByLevelForCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel4ByLevelForCategoryFailure(err.message));
      });
  };
};

const getAcnForCategoryRequest = () => {
  return {
    type: GET_ACN_FOR_CATEGORY_REQUEST,
  };
};

const getAcnForCategorySuccess = (payload) => {
  return {
    type: GET_ACN_FOR_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const getAcnForCategoryFailure = (error) => {
  return {
    type: GET_ACN_FOR_CATEGORY_FAILURE,
    payload: error,
  };
};

const getAcnForCategory = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAcnForCategoryRequest());
    let url = `${baseUrl}/acn`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAcnForCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAcnForCategoryFailure(err.message));
      });
  };
};

//   const uploadCsvForItemMappingRequest = () => {
//     return {
//       type: UPLOADCSV_ITEM_REQUEST,
//     };
//   };

//   const uploadCsvForItemMappingSuccess = (payload) => {
//     return {
//       type: UPLOADCSV_ITEM_SUCCESS,
//       payload: payload,
//     };
//   };

//   const uploadCsvForItemMappingFailure = (error) => {
//     return {
//       type: UPLOADCSV_ITEM_FAILURE,
//       payload: error,
//     };
//   };

//   const uploadCsvForItemMapping = (payload, jwtToken) => {
//     let headers = {
//       headers: {
//         Authorization: `Bearer ${jwtToken}`,
//       },
//     };
//     return (dispatch) => {
//       dispatch(uploadCsvForItemMappingRequest());
//       let url = `${baseUrl}/item/upload`;
//       return axios
//         .post(url, payload, headers)
//         .then((response) => {
//           dispatch(uploadCsvForItemMappingSuccess(response.data));
//           return { status: true, data: response.data };
//         })
//         .catch((err) => {
//           dispatch(uploadCsvForItemMappingFailure(err.message));
//           return { status: false, data: err.message };
//         });
//     };
//   };

const getItemsByCategoryRequest = () => {
  return {
    type: GET_CATEGORYITEMS_REQUEST,
  };
};

const getItemsByCategorySuccess = (payload) => {
  return {
    type: GET_CATEGORYITEMS_SUCCESS,
    payload: payload,
  };
};

const getItemsByCategoryFailure = (error) => {
  return {
    type: GET_CATEGORYITEMS_FAILURE,
    payload: error,
  };
};

const getItemsByCategory = (categoryId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsByCategoryRequest());
    let url = `${baseUrl}/`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsByCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsByCategoryFailure(err.message));
      });
  };
};

export {
  getCategoryLocationMapping,
  createCategoryLocationMapping,
  getLevel1ByLevelForCategory,
  getLevel2ByLevelForCategory,
  getLevel3ByLevelForCategory,
  getLevel4ByLevelForCategory,
  getAcnForCategory,
  getItemsByCategory,
  // uploadCsvForItemMapping,
};
