import {
  GET_REJECTEDVENDORS_REQUEST,
  GET_REJECTEDVENDORS_SUCCESS,
  GET_REJECTEDVENDORS_FAILURE,
} from "./rejectedVendorsTypes";

import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getRejectedVendorsRequest = () => {
  return {
    type: GET_REJECTEDVENDORS_REQUEST,
  };
};

const getRejectedVendorsSuccess = (payload) => {
  return {
    type: GET_REJECTEDVENDORS_SUCCESS,
    payload: payload,
  };
};

const getRejectedVendorsFailure = (error) => {
  return {
    type: GET_REJECTEDVENDORS_FAILURE,
    payload: error,
  };
};

const getRejectedVendors = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRejectedVendorsRequest());
    let url = `${baseUrl}/vendor/get_rejected?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRejectedVendorsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRejectedVendorsFailure(err.message));
      });
  };
};

export { getRejectedVendors };
