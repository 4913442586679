/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// Dashboard example components

import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Some required packages for generate and print qr
import QRCode from "react-qr-code";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { useReactToPrint } from "react-to-print";

// Utils
import TextError from "utils/TextError";

import { CSVLink } from "react-csv";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MDSnackbar from "components/MDSnackbar";

// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Functions from store
import {
  createAsn,
  getAsnDetails,
  getDirectDemand,
  getPartNoForPlanner,
  getPartNo,
  getPlant,
  getPackageType,
  calculatePacketDetails,
  getDeliveryPlant,
  getUnloadingPoint,
  calculatePacketWeight,
  getBinsForPrint,
  uploadForDirectDemand,
  getUnConfirmedMonthlyDemand,
  getVendorsListForPlanner,
  getSubModulesByRoleId,
  // updateActualQty,
  createDirectDemand,
  getItemMaster,
  getVendorCodes,
  getItemsDetailsForASN,
  getPurchaseOrdersForDemand,
  getItemCodeByPO,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import UploadButton from "components/standardized-components/UploadButton";
import CreateButton from "components/standardized-components/CreateButton";

const cookies = new Cookies();

let desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "fit-content",
  maxHeight: "90%",
  overflow: "auto",
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForCreateASN = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1420 },
  height: "fit-content",
  maxWidth: "90%",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const styleForDocument = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 340, sm: 340, md: 600, lg: 800, xl: 800 },
  // height: { xs: "70%", sm: "70%", md: "50%", lg: "90%", xl: "90%" },
  overflow: "auto",
  maxHeight: "90%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styles = StyleSheet.create({
  page: {
    width: "100%",
    height: "100%",
    display: "flex",
    // flexDirection: "row",
    marginBottom: "50px",
    marginTop: "50px",
    // backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
  },
});

const DailyDemand = ({
  getDirectDemand,
  getDirectDemandReducer,
  createDirectDemand,
  itemMaster,
  getItemMaster,
  getPartNoForPlanner,
  partNoForPlannerReducer,
  getPartNo,
  partNoReducer,
  getPlant,
  plantReducer,
  getPackageType,
  packageTypeReducer,
  createAsn,
  getVendorCodeReducer,
  getVendorCodes,
  calculatePacketDetails,
  packageDetailsReducer,
  getDeliveryPlant,
  getUnloadingPoint,
  deliveryPlantReducer,
  unloadingPointReducer,
  calculatePacketWeight,
  packetWeightReducer,
  getBinsForPrint,
  binsForPrintReducer,
  uploadForDirectDemand,

  getVendorsListForPlanner,
  vendorsListForPlannerReducer,
  updateActualQty,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getItemsDetailsForASN,
  getItemsDetailsForASNReducer,
  getPurchaseOrdersForDemand,
  getPurchaseOrdersForDemandReducer,
  getItemCodeByPO,
  getItemCodeByPOReducer,
}) => {
  let user = cookies.get("loginDetailsForWMS");
  let roleName = user.data.roleName;
  let userId = user.data.userId;
  let roleId = user.data.roleId;
  let jwtToken = user?.jwt;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let demandModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "f4e71fdd-d9ea-4bb8-85d6-3fc95cf3c069"
  );
  let createDemand = demandModule
    ? demandModule.actionId.includes("29ee448f-eb4b-4586-ac85-b96c4ea2adcc")
    : null;
  let uploadDemand = demandModule
    ? demandModule.actionId.includes("a9a49314-0e77-46ce-86f9-2a4b770db43c")
    : null;
  let downloadDemand = demandModule
    ? demandModule.actionId.includes("78462342-554d-4bea-9611-c7434dc57e33")
    : null;
  let viewDemand = demandModule
    ? demandModule.actionId.includes("3292c45d-7e94-4ea9-ac23-cfe8116e1813")
    : null;
  let createAsnAccess = demandModule
    ? demandModule.actionId.includes("075d3474-10d8-4827-bec0-9c953e97c0a0")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [uploadLoader, setUploadLoader] = useState(false);
  const [purchaseOrderNumberSelected, setPurchaseOrderNumberSelected] =
    useState("");
  const [partNoSelected, setPartNoSelected] = useState("");
  const [plantSelected, setPlantSelected] = useState("");
  const [vendorsListForPlannerSelected, setVendorsListForPlannerSelected] =
    useState("");

  const [asnQtyChange, setAsnQtyChange] = useState("");

  const [invoiceDate, setInvoiceDate] = useState("");

  const [invoiceNo, setInvoiceNo] = useState("");

  const [expiryDate, setExpiryDate] = useState("");

  const [batchNumber, setBatchNumber] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const isoStartDate = new Date(
      (startDate ? startDate : new Date()).getTime() -
        (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    setEndDate(isoStartDate);
  }, [startDate]);

  let today = new Date();

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  let currentDate = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    let timeout = setTimeout(() => {
      getDirectDemand(
        userId,
        purchaseOrderNumberSelected ? purchaseOrderNumberSelected.value : "",
        partNoSelected ? partNoSelected.value : "",
        plantSelected ? plantSelected.value : "",
        vendorsListForPlannerSelected
          ? vendorsListForPlannerSelected.value
          : "",
        roleName,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
    }, 200);

    return () => clearTimeout(timeout);
  }, [
    purchaseOrderNumberSelected,
    partNoSelected,
    plantSelected,
    userId,
    vendorsListForPlannerSelected,
    pageNo,
    rowsPerPage,
    searchObject,
  ]);

  useEffect(() => {
    getPurchaseOrdersForDemand(jwtToken);
  }, []);

  useEffect(() => {
    roleName !== "vendor" && getPartNoForPlanner(jwtToken);
  }, []);

  useEffect(() => {
    // roleName == "vendor" &&
    getPartNo(
      vendorsListForPlannerSelected
        ? vendorsListForPlannerSelected.vendorId
        : userId,
      jwtToken
    );
  }, [userId, vendorsListForPlannerSelected]);

  useEffect(() => {
    // roleName == "vendor" &&
    getItemCodeByPO(purchaseOrderNumberSelected?.value, jwtToken);
  }, [purchaseOrderNumberSelected]);

  useEffect(() => {
    getPlant(jwtToken);
  }, []);

  const handleChangePartNoForPlanner = (selectedOption) => {
    setPartNoSelected(selectedOption);
  };

  const [partNoForPlannerOptions, setPartNoForPlannerOptions] = useState([]);

  useEffect(() => {
    let tempPartNoForPlannerOptions = [];
    let data = partNoForPlannerReducer.partNoForPlannerData.data
      ? partNoForPlannerReducer.partNoForPlannerData.data
      : [];
    data.map((partNo) => {
      tempPartNoForPlannerOptions.push({
        label: partNo.itemCode,
        value: partNo.itemCode,
      });
    });
    setPartNoForPlannerOptions(tempPartNoForPlannerOptions);
  }, [partNoForPlannerReducer]);

  const [partNoByPOOptions, setPartNoByPOOptions] = useState([]);

  useEffect(() => {
    let tempPartNoByPOOptions = [];
    let data = getItemCodeByPOReducer.itemCodeByPO.data
      ? getItemCodeByPOReducer.itemCodeByPO.data
      : [];

    data.map((partNo) => {
      tempPartNoByPOOptions.push({
        label: partNo.itemCode,
        value: partNo.itemCode,
      });
    });
    setPartNoByPOOptions(tempPartNoByPOOptions);
  }, [getItemCodeByPOReducer]);

  useEffect(() => {
    getVendorsListForPlanner(jwtToken);
  }, []);

  const [asnCode, setAsnCode] = useState("");
  const [packageType, setPackageType] = useState("");

  useEffect(() => {
    getBinsForPrint(asnCode, jwtToken);
  }, [asnCode]);

  const [partCode, setPartCode] = useState("");

  const handleChangePurchaseOrderNumber = (selectedOption) => {
    setPurchaseOrderNumberSelected(selectedOption);
  };

  const [purchaseOrderNumberOptions, setPurchaseOrderNumberOptions] = useState(
    []
  );

  useEffect(() => {
    let tempPurchaseOrderNumberOptions = [];
    let data = getPurchaseOrdersForDemandReducer.purchaseOrdersForDemand.data
      ? getPurchaseOrdersForDemandReducer.purchaseOrdersForDemand.data
      : [];
    data.map((purchaseOrderNumber) => {
      tempPurchaseOrderNumberOptions.push({
        label: purchaseOrderNumber.purchaseOrderNumber,
        value: purchaseOrderNumber.purchaseOrderNumber,
      });
    });
    setPurchaseOrderNumberOptions(tempPurchaseOrderNumberOptions);
  }, [getPurchaseOrdersForDemandReducer]);

  useEffect(() => {
    if (roleName == "vendor") {
      setPurchaseOrderNumberSelected({
        label: purchaseOrderNumberOptions[0]?.label,
        value: purchaseOrderNumberOptions[0]?.value,
      });
    }
  }, [purchaseOrderNumberOptions]);

  const [demandId, setDemandId] = useState("");

  useEffect(() => {
    getPackageType(partCode, jwtToken);
  }, [partCode]);

  useEffect(() => {
    calculatePacketWeight(partCode, asnQtyChange, jwtToken);
  }, [partCode, asnQtyChange]);

  useEffect(() => {
    getDeliveryPlant(demandId, jwtToken);
  }, [demandId]);

  useEffect(() => {
    getUnloadingPoint(demandId, jwtToken);
  }, [demandId]);

  const [singleDeliveryPlantData, setSingleDeliveryPlantData] = useState("");
  const [singleUnloadingPointData, setSingleUnloadingPointData] = useState("");

  const [singleAsnPackageTypeData, setSingleAsnPackageTypeData] = useState("");

  const [singleAsnPackageWeightData, setSingleAsnPackageWeightData] =
    useState("");

  const [singleAsnPackageDetailsData, setSingleAsnPackageDetailsData] =
    useState({});

  useEffect(() => {
    let data = packageTypeReducer.packageTypeData.data
      ? packageTypeReducer.packageTypeData.data
      : [];

    setSingleAsnPackageTypeData(data.packaging);
  }, [packageTypeReducer]);

  // useEffect(() => {
  //   let data = packetWeightReducer.packetWeightData.data
  //     ? packetWeightReducer.packetWeightData.data
  //     : [];

  //   setSingleAsnPackageWeightData(data.packWeight);
  // }, [packetWeightReducer]);

  useEffect(() => {
    let data = deliveryPlantReducer.deliveryPlantData.data
      ? deliveryPlantReducer.deliveryPlantData.data
      : [];

    setSingleDeliveryPlantData(data.deliveryPlant);
  }, [deliveryPlantReducer]);

  useEffect(() => {
    let data = unloadingPointReducer.unloadingPointData.data
      ? unloadingPointReducer.unloadingPointData.data
      : [];

    setSingleUnloadingPointData(data.unloadingPoint);
  }, [unloadingPointReducer]);

  useEffect(() => {
    let data = packageDetailsReducer.packageDetailsData.data
      ? packageDetailsReducer.packageDetailsData.data
      : [];
    setSingleAsnPackageDetailsData(data);
  }, [packageDetailsReducer]);

  const handleChangePartNo = (selectedOption) => {
    setPartNoSelected(selectedOption);
  };

  const [partNoOptions, setPartNoOptions] = useState([]);

  useEffect(() => {
    let tempPartNoOptions = [];
    let data = partNoReducer.partNoData.data
      ? partNoReducer.partNoData.data
      : [];
    data.map((partNo) => {
      tempPartNoOptions.push({
        label: partNo.itemCode,
        value: partNo.itemCode,
      });
    });
    setPartNoOptions(tempPartNoOptions);
  }, [partNoReducer]);

  // useEffect(() => {
  //   if (roleName === "vendor") {
  //     setPartNoSelected({
  //       label: partNoByPOOptions[0]?.label,
  //       value: partNoByPOOptions[0]?.value,
  //     });
  //   }
  // }, [partNoByPOOptions]);

  const handleChangePlant = (selectedOption) => {
    setPlantSelected(selectedOption);
  };

  const [plantOptions, setPlantOptions] = useState([]);

  useEffect(() => {
    let tempPlantOptions = [];
    let data = plantReducer.plantData.data ? plantReducer.plantData.data : [];
    data.map((plant) => {
      tempPlantOptions.push({
        label: plant.plantCode,
        value: plant.plantCode,
      });
    });
    setPlantOptions(tempPlantOptions);
  }, [plantReducer]);

  useEffect(() => {
    if (plantOptions) {
      setPlantSelected({
        label: plantOptions[0]?.label,
        value: plantOptions[0]?.value,
      });
    }
  }, [plantOptions]);

  const handleChangeVendorsListForPlanner = (selectedOption) => {
    setVendorsListForPlannerSelected(selectedOption);
    setPartNoSelected("");
  };

  const [vendorsListForPlannerOptions, setVendorsListForPlannerOptions] =
    useState([]);

  useEffect(() => {
    let tempVendorsListForPlannerOptions = [];
    let data = vendorsListForPlannerReducer.vendorsListForPlannerData.data
      ? vendorsListForPlannerReducer.vendorsListForPlannerData.data
      : [];
    data.map((vendorsListForPlanner) => {
      tempVendorsListForPlannerOptions.push({
        label: vendorsListForPlanner.vendorName,
        value: vendorsListForPlanner.vendorCode,
        vendorId: vendorsListForPlanner.vendorUserId,
      });
    });
    setVendorsListForPlannerOptions(tempVendorsListForPlannerOptions);
  }, [vendorsListForPlannerReducer]);

  const packageTypeOptions = [
    { label: "Bin", value: "Bin" },
    // { label: "Can", value: "Can" },
    { label: "Carton", value: "Carton" },
    { label: "Container", value: "Container" },
  ];

  const [packageTypeSelected, setPackageTypeSelected] = useState("");
  const handleChangePackageType = (selectedOption) => {
    setPackageTypeSelected(selectedOption);
  };

  const [state, setState] = useState({
    columns: [
      { Header: "SR No", accessor: "id", align: "left", width: "30px" },
      { Header: "Date", accessor: "date", align: "left" },
      { Header: "Part Code", accessor: "itemCode", align: "left" },
      {
        Header: "Part Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Schedule QTY", accessor: "scheduleQty", align: "left" },
      { Header: "Balance QTY", accessor: "balanceQty", align: "left" },
      // { Header: "Po No", accessor: "poNumber", align: "left" },
      // {
      //   Header: "Po Line Item",
      //   accessor: "poLineNumber",
      //   align: "left",
      //   width: "10px",
      // },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   align: "center",
      //   cannotSearch: true,
      //   cannotSort: true,
      // },
    ],
    rows: [],
  });

  const [stateForPlanner, setStateForPlanner] = useState({
    columns: [
      { Header: "SR No", accessor: "id", align: "left", width: "30px" },
      { Header: "Date", accessor: "date", align: "left" },

      { Header: "Part Code", accessor: "itemCode", align: "left" },
      {
        Header: "Part Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Schedule QTY", accessor: "scheduleQty", align: "left" },
      { Header: "Balance QTY", accessor: "balanceQty", align: "left" },
      { Header: "Po No", accessor: "purchaseOrderNumber", align: "left" },
      {
        Header: "Po Line Item",
        accessor: "purchaseOrderLineNumber",
        align: "left",
        width: "10px",
      },
      {
        Header: "unloading Point",
        accessor: "unloadingPoint",
        align: "left",
        width: "10px",
      },
      {
        Header: "delivery Plant",
        accessor: "deliveryPlant",
        align: "left",
        width: "10px",
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   align: "center",
      //   cannotSearch: true,
      //   cannotSort: true,
      // },
    ],
    rows: [],
  });

  const { columns, rows } = roleName == "vendor" ? state : stateForPlanner;

  // const [itemTable, setItemTable] = useState({
  //   columns: [
  //     { Header: "ASN Code", accessor: "asnCode", align: "left" },
  //     { Header: "Bin Code", accessor: "binCode", align: "left" },
  //     { Header: "QTY", accessor: "qty", align: "left" },
  //     // { Header: "Quantity", accessor: "quantity", align: "left" },
  //     // { Header: "NOB", accessor: "nob", align: "left" },
  //     // { Header: "LOT/Serial No", accessor: "lotSerialNo", align: "left" },
  //     // { Header: "Item Category", accessor: "itemCategory", align: "left" },
  //     { Header: "QR Code", accessor: "action", align: "center" },
  //   ],
  //   rows: [],
  // });

  // const { columns: itemColumns, rows: itemRows } = itemTable;

  const [buttonName, setButtonName] = useState("Submit");
  const [printData, setPrintData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempDailyDemand = [];
    let data = getDirectDemandReducer.demand.data
      ? getDirectDemandReducer.demand.data
      : [];
    setLoading(getDirectDemandReducer.loading);

    data.map((singleData) => {
      let des = singleData?.itemDescription;
      let len = desLength;
      const formatDate = new Date(singleData.date);

      const dataObject = {
        date: singleData.date,
        privateKey: singleData.demandId,
        id: singleData.demandId,
        demandId: singleData.demandId,
        itemCodeDes: `${singleData.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        partCode: singleData.itemCode,
        itemCode: singleData.itemCode,
        partDescription: singleData.itemDescription,
        itemDescription: singleData.itemDescription,

        scheduleQty: singleData.scheduleQty,
        balanceQty: singleData.balanceQty,
        purchaseOrderNumber: singleData.purchaseOrderNumber,
        purchaseOrderLineNumber: singleData.purchaseOrderLineNumber,
        deliveryPlant: singleData.deliveryPlant,
        unloadingPoint: singleData.unloadingPoint,
        // date: format(
        //   addMinutes(formatDate, formatDate.getTimezoneOffset()),
        //   "do MMMM yyyy"
        // ),

        action: (
          <div>
            {/* {role == "vendor" ? ( */}
            {singleData.enable && createAsnAccess ? (
              <Tooltip title="ASN Creation">
                <MDButton
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={() => {
                    setPartCode(singleData.itemCode);
                    setDemandId(singleData?.demandId);

                    handleOpenCreateASNModal(singleData);
                  }}
                  style={{ marginRight: "5px", padding: "2%" }}
                >
                  <Icon style={{ marginRight: "2px" }}>add</Icon>
                  ASN
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="ASN Creation">
                <MDButton
                  disabled
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={() => {
                    setPartCode(singleData.itemCode);
                    setDemandId(singleData.demandId);
                    setPartCode(singleData.itemCode);
                    handleOpenCreateASNModal(singleData);
                  }}
                  style={{ marginRight: "5px", padding: "2%" }}
                >
                  <Icon style={{ marginRight: "2px" }}>add</Icon>
                  ASN
                </MDButton>
              </Tooltip>
            )}
            {/* {updatePlanQtyAccess && (
              <Tooltip title="Update Qty">
                <MDButton
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  onClick={() => handleOpenUpdateActualQtyModal(singleData)}
                  style={{ marginRight: "5px" }}
                >
                  <Icon>edit_sharp</Icon>
                </MDButton>
              </Tooltip>
            )} */}
          </div>
        ),
      };
      tempDailyDemand.push(dataObject);
    });
    viewDemand &&
      (roleName == "vendor"
        ? setState({ ...state, rows: tempDailyDemand })
        : setStateForPlanner({ ...stateForPlanner, rows: tempDailyDemand }));
  }, [getDirectDemandReducer]);

  useEffect(() => {
    let tempBins = [];
    let data = binsForPrintReducer.binsForPrintData.data
      ? binsForPrintReducer.binsForPrintData.data
      : [];

    setPrintData(data);
  }, [binsForPrintReducer]);

  // const [showFilters, setShowFilters] = useState(false);

  // const showFiltersHandler = () => {
  //   setShowFilters(!showFilters);
  // };

  // const changeGrn = (selectedData) => {
  //   setGrnIdSelected(selectedData);
  // };

  // const changeWarehouse = (selectedData) => {
  //   setWarehouseIdSelected(selectedData);
  // };

  const AsnCreationNotification = () =>
    toast.success("Asn Created Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const [isErrorCreatePickupList, setIsErrorCreatePickupList] = useState(false);

  useEffect(() => {
    if (isErrorCreatePickupList) {
      setTimeout(() => {
        setIsErrorCreatePickupList(false);
      }, 3000);
    }
  }, [isErrorCreatePickupList]);

  const [openCreatePickupListModal, setOpenCreatePickupListModal] =
    useState(false);

  const [pickupListData, setPickupListData] = useState({});

  const handleOpenCreatePickupListModal = (data = {}) => {
    setPickupListData(data);
    setOpenCreatePickupListModal(true);
  };

  const handleCloseCreatePickupListModal = () => {
    setOpenCreatePickupListModal(false);
  };

  const createPickupListHandler = (event, data) => {
    handleOpenCreatePickupListModal(data);
  };

  const [selectedPickupListData, setSelectedPickupListData] = useState([]);
  const [uncheck, setUncheck] = useState(false);

  const PickupListData = (data) => {
    setSelectedPickupListData(data);
  };

  const onClickCreatePickupList = async () => {
    let transactionCode = [];

    selectedPickupListData.map((data) => {
      const object = {
        transactionCode: data.transactionNo,
      };
      transactionCode.push(object);
    });

    // let res = await createPickupList(transactionCode);

    // if (res.status) {
    //   handleCloseCreatePickupListModal();
    //   getCreatePickupList(warehouseIdSelected?.value);
    //   CreatePickupListSuccessNotification();
    // }
    // if (!res.status) {
    //   setIsErrorCreatePickupList(true);
    // }
  };

  const [fileForTestCertificate, setFileForTestCertificate] = useState({});

  const handleChangeForTestCertificate = (event) => {
    setFileForTestCertificate(event.target.files[0]);
  };

  const [fileForInvoiceFile, setFileForInvoiceFile] = useState({});

  const handleChangeForInvoiceFile = (event) => {
    setFileForInvoiceFile(event.target.files[0]);
  };

  //   const packageDetailsHandler = async () => {

  // let payload = {
  //   // asnQty: asnQtyChange,
  //   // partCode: partCode
  //   asnQty: "80",
  //   partCode: "M792.636.000"
  // }
  //     let res = await calculatePacketDetails(payload, jwtToken);

  //     if (res.status) {
  //       setSingleAsnPackageDetailsData(res.data);

  //     }
  //     if (!res.status) {

  //     }
  //   };

  const [openCreateASNModal, setOpenCreateASNModal] = useState(false);

  const [singleASNData, setSingleASNData] = useState({});
  const handleOpenCreateASNModal = (ActualQty = {}) => {
    setSingleASNData(ActualQty);

    setOpenCreateASNModal(true);
    setIsErrorCreateASN(false);
    setIsSuccessCreateASN(false);
  };
  const handleCloseCreateASNModal = () => {
    setOpenCreateASNModal(false);
    // formik.setValues(initialValues);
    // formik.setTouched({});
    // formik.setErrors({});
    setAsnQtyChange("");
    window.location.reload();
  };

  const [createAsnError, setCreateAsnError] = useState("");

  const [isErrorCreateASN, setIsErrorCreateASN] = useState(false);
  const [isSuccessCreateASN, setIsSuccessCreateASN] = useState(false);

  useEffect(() => {
    if (isErrorCreateASN) {
      setTimeout(() => {
        setIsErrorCreateASN(false);
      }, 3000);
    }
  }, [isErrorCreateASN]);

  useEffect(() => {
    if (isSuccessCreateASN) {
      setTimeout(() => {
        setIsSuccessCreateASN(false);
      }, 3000);
    }
  }, [isSuccessCreateASN]);

  let lineItems3 = getItemsDetailsForASNReducer.itemsDetailsForASN.data
    ? getItemsDetailsForASNReducer.itemsDetailsForASN.data
    : [];

  const initialValues = {
    lineItems2: [
      {
        itemCodeDes: "",
      },
    ],
  };

  const validationSchema = Yup.object({
    // itemCodeDes: Yup.string().required("Enter Part No/Description"),
    // poNoLineNo: Yup.string().required("Enter Po No/Line Number"),
    // scheduleQuantity: Yup.string().required("Enter Schedule Quantity"),
    lineItems2: Yup.array().of(
      Yup.object({
        balanceQty: Yup.number(),
        asnQty: Yup.number()
          .min(1, "Quantity cannot be negative")
          .max("balanceQty")
          .required("Quantity is required"),
      })
    ),
    balanceQty: Yup.number(),
    // invoiceNo: Yup.string().required("Enter Invoice No"),
    // invoiceDate: Yup.string().required("Enter Invoice Date"),
    // // deliveryPlant: Yup.string()
    // //   .required("Enter a experience")
    // //   .min(1, "Invalid data")
    // //   .max(2, "Invalid data")
    // //   .test("Is positive", "must be positive", (val) => val >= 0),
    // deliveryPlant: Yup.string().required("Select Delivery Plant"),
    // typeOfPacket: Yup.string().required("Select Type of Packet"),
    // noOfPacket: Yup.string().required("Enter No of Packet"),
    // unitPackQuantity: Yup.string().required("Enter Unit Pack Quantity"),
    // unitPacketWeight: Yup.string().required("Enter Unit Packet Weight"),
  });

  // const handleChange = (event) => {
  //   setjobDescFile(event.target.files[0]);
  // };
  const onSubmitForLineItems = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      poNoLineNo: poNoLineNo,
      testCertificate: fileForTestCertificate,
      invoice: fileForInvoiceFile,
      asnDetails: JSON.stringify(values.lineItems2),
      vendorId: userId,
    };

    let response = await createAsn(payload, jwtToken);

    if (response.status === true) {
      setAsnCode(response.data.data.asnCode);
      setPackageType(response.data.data.packageType);

      setIsSuccessCreateASN(true);
      AsnCreationNotification();
      setTimeout(() => {
        setButtonName("Submit");
        handleCloseCreateASNModal();
      }, 2250);
      // handleCloseCreateASNModal();
      getDirectDemand(
        userId,
        purchaseOrderNumberSelected ? purchaseOrderNumberSelected.value : "",
        partNoSelected ? partNoSelected.value : "",
        plantSelected ? plantSelected.value : "",
        vendorsListForPlannerSelected
          ? vendorsListForPlannerSelected.value
          : "",
        roleName,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      getAsnDetails(userId, jwtToken);
      resetForm();
    }
    if (response.status === false) {
      setIsErrorCreateASN(true);
      setCreateAsnError(response?.data?.data?.msg);
      setButtonName("Submit");
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    let payload = {
      testCertificate: fileForTestCertificate,
      invoice: fileForInvoiceFile,
      invoiceNo: invoiceNo,
      invoiceDate: invoiceDate,
      batchNumber: batchNumber,
      expiryDate: expiryDate,
      planDate: singleASNData ? singleASNData.date : "",
      itemCodeDes: values.itemCodeDes,
      poNoLineNo: values.poNoLineNo,
      asnQty: values?.asnQty,
      packageType: singleAsnPackageTypeData,
      packetNo: singleAsnPackageDetailsData.packetQty,
      packetWeight: singleAsnPackageWeightData,
      unloadingPoint: singleUnloadingPointData,
      deliveryPlant: singleDeliveryPlantData,
      demandId: singleASNData ? singleASNData.demandId : "",
      vendorId: userId,
    };

    let response = await createAsn(payload, jwtToken);

    if (response.status === true) {
      setAsnCode(response.data.data.asnCode);
      setPackageType(response.data.data.packageType);

      setIsSuccessCreateASN(true);
      AsnCreationNotification();
      // handleCloseCreateASNModal();
      getDirectDemand(
        userId,
        purchaseOrderNumberSelected ? purchaseOrderNumberSelected.value : "",
        partNoSelected ? partNoSelected.value : "",
        plantSelected ? plantSelected.value : "",
        vendorsListForPlannerSelected
          ? vendorsListForPlannerSelected.value
          : "",
        roleName,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      getAsnDetails(userId, jwtToken);
      resetForm();
    }
    if (response.status === false) {
      setIsErrorCreateASN(true);
    }
  };

  // const formik = useFormik({
  //   initialValues,
  //   onSubmit,
  //   validationSchema,
  //   enableReinitialize: true,
  // });

  const [printAsnDetailsPartCode, setPrintAsnDetailsPartCode] = useState("");
  const [printAsnDetailsInvoiceNo, setPrintAsnDetailsInvoiceNo] = useState("");
  const [printAsnDetailsTransporterName, setPrintAsnDetailsTransporterName] =
    useState("");
  const [printAsnDetailsVehicleNameplate, setPrintAsnDetailsVehicleNameplate] =
    useState("");
  const [printAsnDetailsTransporterType, setPrintAsnDetailsTransporterType] =
    useState("");
  const [printAsnDetailsLRNumber, setPrintAsnDetailsLRNumber] = useState("");
  const [printAsnDetailsPhoneNumber, setPrintAsnDetailsPhoneNumber] =
    useState("");

  const [openPrintASNModal, setOpenPrintASNModal] = useState(false);

  const handleClosePrintASNModal = () => {
    setOpenPrintASNModal(false);
  };

  const handleOpenPrintASNModal = () => {
    setOpenPrintASNModal(true);
  };

  const [show, setShow] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const ConfirmCnfAlertNotification = () =>
    toast.error("Please Go To Monthly Demand And Confirm Actual Qty", {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    setUploadLoader(true);

    let data = new FormData();

    data.append("file", uploadCsvData);
    data.append("userId", userId);

    let res = await uploadForDirectDemand(data, jwtToken);

    if (res.status) {
      setUploadLoader(false);

      getDirectDemand(
        userId,
        purchaseOrderNumberSelected ? purchaseOrderNumberSelected.value : "",
        partNoSelected ? partNoSelected.value : "",
        plantSelected ? plantSelected.value : "",
        vendorsListForPlannerSelected
          ? vendorsListForPlannerSelected.value
          : "",
        roleName,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      setUploadLoader(false);
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  const [openUpdateActualQtyModal, setOpenUpdateActualQtyModal] =
    useState(false);
  const [
    singleMonthlyDemandDataForUpdate,
    setSingleMonthlyDemandDataForUpdate,
  ] = useState({});
  const handleOpenUpdateActualQtyModal = (ActualQty = {}) => {
    setSingleMonthlyDemandDataForUpdate(ActualQty);
    setOpenUpdateActualQtyModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateActualQtyModal = () => {
    setOpenUpdateActualQtyModal(false);

    formikForUpdate.setValues(initialValueForUpdate);
    formikForUpdate.setTouched({});
    formikForUpdate.setErrors({});
  };

  const [errorMsgForUpdateQty, setErrorMsgForUpdateQty] = useState("");

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    // departmentId: singleDepartmentDataForUpdate.departmentId,
    actualQty: singleMonthlyDemandDataForUpdate.actualQty,
    remark: singleMonthlyDemandDataForUpdate.remark,
  };

  const validationSchemaForUpdate = Yup.object({
    actualQty: Yup.number().required("Enter a valid Qty"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      partCode: singleMonthlyDemandDataForUpdate.itemCode,
      planDate: singleMonthlyDemandDataForUpdate.date,
      planQty: values.actualQty,
    };

    // let res = await updateActualQty(payload, jwtToken);

    // if (res.status) {
    //   setIsSuccessForUpdate(true);
    //   setTimeout(() => {
    //     handleCloseUpdateActualQtyModal();
    //   }, 3000);

    //   getDirectDemand(
    //     userId,
    //     partNoSelected ? partNoSelected.value : "",
    //     plantSelected ? plantSelected.value : "",
    //     vendorsListForPlannerSelected
    //       ? vendorsListForPlannerSelected.value
    //       : "",
    //     roleName,
    //     jwtToken
    //   );
    // }
    // if (!res.status) {
    //   setIsErrorForUpdate(true);
    //   setErrorMsgForUpdateQty(
    //     res.data.data.message ? res.data.data.message : res?.data?.data?.msg
    //   );
    // }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [openCreateDemandModal, setOpenCreateDemandModal] = useState(false);

  const handleOpenCreateDemandModal = () => {
    setOpenCreateDemandModal(true);
    formikForCreateDemand.setValues(initialValueForCreateDemand);
    formikForCreateDemand.setTouched({});
    formikForCreateDemand.setErrors({});
    setSelectedVendorCode("");
    setSelectedItemCode("");
    getItemMaster(jwtToken);
    getVendorCodes(jwtToken);
  };

  const handleCloseCreateDemandModal = () => {
    setOpenCreateDemandModal(false);
  };

  const initialValueForCreateDemand = {
    demandDate: "",
    schqty: "",
    vendorCode: "",
    itemCode: "",
  };

  const validationSchemaForDemand = Yup.object({
    purchaseOrderNumber: Yup.string().required("Enter purchase order number"),
    purchaseOrderLineNumber: Yup.string().required(
      "Enter purchase order line number"
    ),
    unloadingPoint: Yup.string().required("Enter unloading point"),
    deliveryPlant: Yup.string().required("Enter delivery plant"),
    demandDate: Yup.date().required("Select the demand date"),
    vendorCode: Yup.object().required("Select a VendorCode"),
    itemCode: Yup.object().required("Select a ItemCode"),
    schqty: Yup.string().required("Enter a qty"),
  });

  const [selectedVendorCode, setSelectedVendorCode] = useState("");

  const handleVendorCodeChange = (selectedItemCode) => {
    setSelectedVendorCode(selectedItemCode);
  };

  const [vendorCodeOptions, setVendorCodeOptions] = useState([]);

  useEffect(() => {
    let tempVendorCodeOptions = [];
    let data = getVendorCodeReducer.vendors.data
      ? getVendorCodeReducer.vendors.data
      : [];

    data.map((item) => {
      tempVendorCodeOptions.push({
        label: item.vendorName,
        value: item.vendorCode,
      });
    });
    setVendorCodeOptions(tempVendorCodeOptions);
  }, [getVendorCodeReducer]);

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        itemCode: item.itemCode,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [isErrorCreateNewDemand, setIsErrorCreateNewDemand] = useState(false);
  const [isSuccessCreateNewDemand, setIsSuccessCreateNewDemand] =
    useState(false);

  useEffect(() => {
    if (isErrorCreateNewDemand) {
      setTimeout(() => {
        setIsErrorCreateNewDemand(false);
      }, 3000);
    }
  }, [isErrorCreateNewDemand]);

  useEffect(() => {
    if (isSuccessCreateNewDemand) {
      setTimeout(() => {
        setIsSuccessCreateNewDemand(false);
      }, 3000);
    }
  }, [isSuccessCreateNewDemand]);

  const onSubmitForCreateDemand = async (values, { resetForm }) => {
    let payload = {
      itemCode: selectedItemCode?.itemCode,
      itemId: selectedItemCode?.value,
      date: values.demandDate,
      scheduleQty: values.schqty.trim(),
      userId: userId,
      vendorCode: selectedVendorCode?.value,
      unloadingPoint: values.unloadingPoint.trim(),
      deliveryPlant: values.deliveryPlant.trim(),
      purchaseOrderNumber: values.purchaseOrderNumber.trim(),
      purchaseOrderLineNumber: values.purchaseOrderLineNumber.trim(),
    };

    let res = await createDirectDemand(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreateNewDemand(true);
      getDirectDemand(pageNo, rowsPerPage, searchObject, jwtToken);
      setSelectedVendorCode("");
      setSelectedItemCode("");
      setTimeout(() => {
        handleCloseCreateDemandModal();
      }, 2000);

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForCreate(res?.data?.data?.msg);
      setIsErrorCreateNewDemand(true);
    }
  };

  const formikForCreateDemand = useFormik({
    initialValues: initialValueForCreateDemand,
    validationSchema: validationSchemaForDemand,
    onSubmit: onSubmitForCreateDemand,
  });
  const [poNoLineNo, setPoNoLineNo] = useState("");
  const [selectedPublishData, setSelectedPublishData] = useState([]);
  const PublishData = (data) => {
    setPoNoLineNo(
      `${data[0]?.purchaseOrderNumber}/${data[0]?.purchaseOrderLineNumber}`
    );
    data.map((data) => {
      const object = {
        demandId: data.demandId,
      };
      selectedPublishData.push(object);
    });
  };

  const [disableButton, setDisableButton] = useState(false);

  // useEffect(() => {
  //   let value = selectedPublishData.some((data) => data.isPublished === 1);
  //   setDisableButton(value);
  // }, [selectedPublishData]);

  const headers = [
    {
      label: "date",
      key: "date",
    },
    {
      label: "scheduleQty",
      key: "scheduleQty",
    },
    {
      label: "vendorCode",
      key: "vendorCode",
    },
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "unloadingPoint",
      key: "unloadingPoint",
    },
    {
      label: "deliveryPlant",
      key: "deliveryPlant",
    },
    {
      label: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
    },
    {
      label: "purchaseOrderLineNumber",
      key: "purchaseOrderLineNumber",
    },
  ];

  const headers2 = [
    {
      label: "date",
      key: "date",
    },
    {
      label: "scheduleQty",
      key: "scheduleQty",
    },
    {
      label: "vendorCode",
      key: "vendorCode",
    },
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "unloadingPoint",
      key: "unloadingPoint",
    },
    {
      label: "deliveryPlant",
      key: "deliveryPlant",
    },
    {
      label: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
    },
    {
      label: "purchaseOrderLineNumber",
      key: "purchaseOrderLineNumber",
    },
  ];

  const csvLink = {
    filename: "Daily Demand.csv",
    headers: headers,
    data: [],
  };

  const csvLink2 = {
    filename: "Daily Demand.csv",
    headers: headers2,
    data: rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Grid item xs={8}>
                <MDBox
                  color="text"
                  px={2}
                  display="flex"
                  flexDirection="column"
                >
                  <MDBox
                    display="flex"
                    marginLeft={0}
                    marginTop="12px"
                    marginBottom="5px"
                  >
                    {createDemand && (
                      <CreateButton
                        tooltip="Create Demand"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateDemandModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    )}
                    <CSVLink {...csvLink}>
                      <DownloadButton
                        tooltip="Download Template"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadButton>
                    </CSVLink>

                    {uploadDemand && (
                      <UploadButton
                        tooltip="Upload Demand"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="info"
                        iconOnly
                        onClick={uploadCsvFilterHandler}
                      >
                        <Icon>upload</Icon>
                      </UploadButton>
                    )}

                    {downloadDemand && (
                      <CSVLink {...csvLink2}>
                        <DownloadDataButton
                          tooltip="Download Demand List"
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="success"
                          iconOnly
                        >
                          <Icon>download</Icon>
                        </DownloadDataButton>
                      </CSVLink>
                    )}
                  </MDBox>
                  <MDBox mr={2}>
                    {uploadCsvFilter && (
                      <div>
                        <div>
                          <input
                            className="choose_file"
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              setUploadCsvData(e.target.files[0]);
                            }}
                          />
                          <MDButton
                            disabled={
                              !uploadCsvData?.name ||
                              uploadCsvData?.type !== "text/csv"
                            }
                            variant="contained"
                            color="info"
                            style={{
                              marginLeft: "0",
                              marginTop: "0.3rem",
                              padding: 1,
                              height: "10px",
                            }}
                            type="button"
                            onClick={uploadCsvHandler}
                          >
                            Upload
                          </MDButton>
                        </div>
                        <div>
                          <span
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "0px",
                              fontStyle: "italic",
                            }}
                          >
                            <strong>*</strong> Please upload
                            <strong>".csv"</strong> file only
                          </span>
                        </div>
                      </div>
                    )}
                  </MDBox>
                </MDBox>
              </Grid>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-5}
                mt={0}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <MDBox>
                      <MDBox
                        display="flex"
                        mt={2}
                        flexDirection={{ xs: "column", lg: "row" }}
                      >
                        {roleName !== "vendor" && (
                          <MDBox
                            width={{ xs: "250px", lg: "800px" }}
                            style={{ marginRight: "10px" }}
                          >
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Vendors
                            </MDTypography>
                            <Select
                              isClearable
                              placeholder="Vendors"
                              className="select-css-for-filter"
                              value={vendorsListForPlannerSelected}
                              options={vendorsListForPlannerOptions}
                              onChange={handleChangeVendorsListForPlanner}
                              autoComplete="off"
                            />
                          </MDBox>
                        )}
                        {roleName === "vendor" && (
                          <MDBox
                            width={{ xs: "250px", lg: "350px" }}
                            style={{ marginRight: "10px" }}
                          >
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              PO Number
                            </MDTypography>

                            <Select
                              placeholder="PO No"
                              className="select-css-for-filter"
                              value={purchaseOrderNumberSelected}
                              options={purchaseOrderNumberOptions}
                              onChange={handleChangePurchaseOrderNumber}
                              autoComplete="off"
                            />
                          </MDBox>
                        )}
                        <MDBox
                          width={{ xs: "250px", lg: "350px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Part Code
                          </MDTypography>
                          {roleName !== "vendor" ? (
                            <Select
                              placeholder="Part No"
                              className="select-css-for-filter"
                              value={partNoSelected}
                              options={
                                vendorsListForPlannerSelected
                                  ? partNoOptions
                                  : partNoForPlannerOptions
                              }
                              onChange={handleChangePartNo}
                              autoComplete="off"
                            />
                          ) : (
                            <Select
                              isClearable
                              placeholder="Part No"
                              className="select-css-for-filter"
                              value={partNoSelected}
                              options={partNoByPOOptions}
                              onChange={handleChangePartNo}
                              autoComplete="off"
                            />
                          )}
                        </MDBox>
                        <MDBox style={{ width: "250px", marginRight: "10px" }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Plant
                          </MDTypography>
                          <Select
                            isDisabled={true}
                            placeholder="Plant"
                            className="select-css-for-filter"
                            value={plantSelected}
                            options={plantOptions}
                            onChange={handleChangePlant}
                            autoComplete="off"
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox>
                  <MDBox
                    flexDirection={{
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    }}
                    style={{ display: "flex" }}
                    mb={2}
                    ml={0}
                  ></MDBox>
                </MDBox>
              </MDBox>

              <MDBox pt={1}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                  hideColumns={[
                    "",
                    "unloadingPoint",
                    "deliveryPlant",
                    "purchaseOrderLineNumber",
                  ]}
                  selection={roleName === "vendor" ? true : false}
                  selectionButtonClick={PublishData}
                  uncheckProp={uncheck}
                  selectionButtonComponent={
                    // createPickuplistAccess &&
                    <Tooltip title="Create ASN">
                      {/* {publishAccess && ( */}
                      <MDButton
                        disabled={disableButton}
                        color="white"
                        type="button"
                        style={{
                          marginTop: "0.5rem",
                          color: "white",
                          border: "1px solid #0275d8",
                          background: "#0275d8",
                          marginLeft: 5,
                        }}
                        onClick={async () => {
                          let res = await getItemsDetailsForASN(
                            {
                              vendorId: userId,
                              demands: selectedPublishData,
                            },
                            jwtToken
                          );

                          handleOpenCreateASNModal();
                        }}
                      >
                        <Icon style={{ marginRight: "2px" }}>add</Icon>
                        ASN
                      </MDButton>
                      {/* )} */}
                      {disableButton && (
                        <MDTypography
                          color="error"
                          variant="p"
                          style={{
                            // textAlign: "center",
                            marginLeft: "1rem",
                            marginTop: "1rem",
                          }}
                        >
                          Remove the previous data!
                        </MDTypography>
                      )}
                    </Tooltip>
                  }
                />
              </MDBox>
              {/* <Modal
                open={openCreateASNModal}
                onClose={handleCloseCreateASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreateASN}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create ASN
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateASNModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pb={3} px={1} overflow="auto" height="600px">
                    <MDBox pt={1} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={4}>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Part No/Description
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="itemCodeDes"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.itemCodeDes}
                                error={
                                  formik.touched.itemCodeDes &&
                                  formik.errors.itemCodeDes &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.itemCodeDes &&
                                formik.errors.itemCodeDes && (
                                  <TextError
                                    style={{ marginBottom: "-10px" }}
                                    msg={formik.errors.itemCodeDes}
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Po No/Line Number{" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="poNoLineNo"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.poNoLineNo}
                                error={
                                  formik.touched.poNoLineNo &&
                                  formik.errors.poNoLineNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "14px",
                                  },
                                  readOnly: true,

                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.poNoLineNo &&
                                formik.errors.poNoLineNo && (
                                  <TextError msg={formik.errors.poNoLineNo} />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Type of Packet{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="packageType"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleAsnPackageTypeData}
                                error={
                                  formik.touched.packageType &&
                                  formik.errors.packageType &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,

                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.packageType &&
                                formik.errors.packageType && (
                                  <TextError msg={formik.errors.packageType} />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Unloading Point{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="unloadingPoint"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleUnloadingPointData}
                                error={
                                  formik.touched.unloadingPoint &&
                                  formik.errors.unloadingPoint &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,

                                  maxLength: 45,
                                }}
                              />

                              {formik.touched.unloadingPoint &&
                                formik.errors.unloadingPoint && (
                                  <TextError
                                    msg={formik.errors.unloadingPoint}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Delivery Plant{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="deliveryPlant"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleDeliveryPlantData}
                                error={
                                  formik.touched.deliveryPlant &&
                                  formik.errors.deliveryPlant &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "14px",
                                    marginTop: "1px",
                                  },
                                  readOnly: true,

                                  maxLength: 45,
                                }}
                              />

                              {formik.touched.deliveryPlant &&
                                formik.errors.deliveryPlant && (
                                  <TextError
                                    msg={formik.errors.deliveryPlant}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  // marginBottom: "2px",
                                }}
                              >
                                Expiry Date{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      // marginBottom: 5,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>

                              <MDInput
                                type="date"
                                name="expiryDate"
                                fullWidth
                                autoComplete="off"
                                onChange={(e) => {
                                  setExpiryDate(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={expiryDate}
                                error={
                                  formik.touched.expiryDate &&
                                  formik.errors.expiryDate &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "12.5px",
                                  },

                                  min: currentDate,
                                }}
                              />

                              {formik.touched.expiryDate &&
                                formik.errors.expiryDate && (
                                  <MDBox mb={-3}>
                                    <TextError msg={formik.errors.expiryDate} />
                                  </MDBox>
                                )}
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Schedule Quantity{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="scheduleQuantity"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.scheduleQuantity}
                                error={
                                  formik.touched.scheduleQuantity &&
                                  formik.errors.scheduleQuantity &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,

                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.scheduleQuantity &&
                                formik.errors.scheduleQuantity && (
                                  <TextError
                                    msg={formik.errors.scheduleQuantity}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Balance Quantity
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="balanceQty"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleASNData.balanceQty - asnQtyChange}
                                error={
                                  formik.touched.balanceQty &&
                                  formik.errors.balanceQty &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  inputProps: {
                                    max: singleASNData.balanceQty,
                                    min: 1,
                                  },
                                }}
                              />
                              {formik.touched.balanceQty &&
                                formik.errors.balanceQty && (
                                  <TextError msg={formik.errors.balanceQty} />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                ASN Quantity{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="asnQty"
                                fullWidth
                                autoComplete="off"
                                onChange={(e) => {
                                  setAsnQtyChange(e.target.value);
                                  calculatePacketDetails(
                                    e.target.value,
                                    partCode,
                                    userId,
                                    jwtToken
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.asnQty}
                                error={
                                  formik.touched.asnQty &&
                                  formik.errors.asnQty &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                }}
                              />
                              {formik.touched.asnQty &&
                                formik.errors.asnQty && (
                                  <MDBox mb={-3}>
                                    <TextError msg={formik.errors.asnQty} />
                                  </MDBox>
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                No of Packet/Bin{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="packetNo"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleAsnPackageDetailsData.packetQty}
                                error={
                                  formik.touched.packetNo &&
                                  formik.errors.packetNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                }}
                              />
                              {formik.touched.packetNo &&
                                formik.errors.packetNo && (
                                  <TextError msg={formik.errors.packetNo} />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Unit Pack Quantity{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="unitPackQuantity"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleAsnPackageDetailsData.unitPackQty}
                                error={
                                  formik.touched.unitPackQuantity &&
                                  formik.errors.unitPackQuantity &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "14px",
                                    marginTop: "2px",
                                  },
                                  readOnly: true,
                                }}
                              />
                              {formik.touched.unitPackQuantity &&
                                formik.errors.unitPackQuantity && (
                                  <TextError
                                    msg={formik.errors.unitPackQuantity}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Batch Number (Optional){" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="batchNumber"
                                fullWidth
                                autoComplete="off"
                                onChange={(e) => {
                                  setBatchNumber(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={batchNumber}
                                error={
                                  formik.touched.batchNumber &&
                                  formik.errors.batchNumber &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "12px",
                                  },

                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.batchNumber &&
                                formik.errors.batchNumber && (
                                  <MDBox mb={-3}>
                                    <TextError
                                      msg={formik.errors.batchNumber}
                                    />
                                  </MDBox>
                                )}
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Unit Packet Weight (kg)
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="packetWeight"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={singleAsnPackageWeightData}
                                error={
                                  formik.touched.packetWeight &&
                                  formik.errors.packetWeight &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,

                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.packetWeight &&
                                formik.errors.packetWeight && (
                                  <TextError msg={formik.errors.packetWeight} />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice No{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="invoiceNo"
                                fullWidth
                                autoComplete="off"
                                onChange={(e) => {
                                  setInvoiceNo(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={invoiceNo}
                                error={
                                  formik.touched.invoiceNo &&
                                  formik.errors.invoiceNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "12px",
                                  },

                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.invoiceNo &&
                                formik.errors.invoiceNo && (
                                  <MDBox mb={-3}>
                                    <TextError msg={formik.errors.invoiceNo} />
                                  </MDBox>
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  // marginBottom: "2px",
                                }}
                              >
                                Invoice Date{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      // marginBottom: 5,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>

                              <MDInput
                                type="date"
                                name="invoiceDate"
                                fullWidth
                                autoComplete="off"
                                onChange={(e) => {
                                  setInvoiceDate(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={invoiceDate}
                                error={
                                  formik.touched.invoiceDate &&
                                  formik.errors.invoiceDate &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "12.5px",
                                  },

                                  max: currentDate,
                                }}
                              />

                              {formik.touched.invoiceDate &&
                                formik.errors.invoiceDate && (
                                  <MDBox mb={-3}>
                                    <TextError
                                      msg={formik.errors.invoiceDate}
                                    />
                                  </MDBox>
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice (PDF Only)
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      // marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>
                              <input
                                name="invoiceFile"
                                style={{
                                  borders: "none",
                                  marginTop: "0px",
                                  width: "100%",
                                }}
                                className="choose_file"
                                type="file"
                                autoComplete="off"
                                accept=".pdf"
                                id="chooseFile"
                                onChange={(event) =>
                                  handleChangeForInvoiceFile(event)
                                }
                              />
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Test Certificate (PDF Only)
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    // marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <input
                                name="testCertificate"
                                style={{
                                  borders: "none",
                                  marginTop: "0px",
                                  width: "100%",
                                  // height:"15px"
                                }}
                                className="choose_file"
                                type="file"
                                autoComplete="off"
                                accept=".pdf"
                                id="chooseFile"
                                onChange={(event) =>
                                  handleChangeForTestCertificate(event)
                                }
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        <MDBox>
                          <Collapse in={isErrorCreateASN}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorCreateASN(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check the details. Server error!
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessCreateASN}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessCreateASN(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              ASN created successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={2} display="flex">
                          <MDBox mr={2}>
                            <MDButton
                              color="info"
                              type="submit"
                              marginRight="10px"
                              disabled={
                                !formik.isValid ||
                                !fileForInvoiceFile.name ||
                                !fileForTestCertificate.name ||
                                invoiceDate === "" ||
                                invoiceNo === ""
                              }
                            >
                              Create ASN
                            </MDButton>
                          </MDBox>
                          <MDBox>
                            <MDButton
                              color="success"
                              type="submit"
                              marginLeft="20px"
                              onClick={() => {
                                // setPrintAsnDetailsInvoiceNo(
                                //   formik.values.invoiceDate
                                // );
                                handleOpenPrintASNModal();
                              }}
                              disabled={
                                !packageType?.includes(
                                  "Carton" ||
                                    "carton" ||
                                    "Corugated" ||
                                    "corugated"
                                )
                              }
                            >
                              Print PKT QTY
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal> */}
              <Modal
                open={openCreateASNModal}
                onClose={handleOpenCreateASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreateASN}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create ASN
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateASNModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                    // component="form"
                    // role="form"
                    // onSubmit={formik.handleSubmit}
                    >
                      <MDBox>
                        <Grid container>
                          <Formik
                            initialValues={initialValues}
                            // validationSchema={validationSchema}
                            onSubmit={onSubmitForLineItems}
                            enableReinitialize={true}
                          >
                            {(formik) => (
                              <Form>
                                <div>
                                  <MDBox
                                    style={{
                                      position: {
                                        xs: "relative",
                                        lg: "sticky",
                                      },
                                      top: "0",
                                      width: "100%",
                                      zIndex: "999",
                                    }}
                                  >
                                    {/* <MDTypography variant="h5">
                                      Header
                                    </MDTypography>
                                    <hr style={{ marginBottom: "20px" }} /> */}
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        style={{ display: "flex" }}
                                      >
                                        <MDBox mb={1} ml={0}>
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Po No/Line Number{" "}
                                          </MDTypography>

                                          <Field
                                            disabled
                                            className="form-control"
                                            type="text"
                                            name="poNoLineNo"
                                            fullWidth
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={`${lineItems3[0].purchaseOrderNumber}/${lineItems3[0].purchaseOrderLineNumber}`}
                                            error={
                                              formik.touched.poNoLineNo &&
                                              formik.errors.poNoLineNo &&
                                              true
                                            }
                                            inputProps={{
                                              style: {
                                                height: "14px",
                                              },
                                              maxLength: 45,
                                            }}
                                          />

                                          {formik.touched.poNoLineNo &&
                                            formik.errors.poNoLineNo && (
                                              <TextError
                                                msg={formik.errors.poNoLineNo}
                                              />
                                            )}
                                        </MDBox>

                                        <MDBox ml={2} mb={1}>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            flexGrow={1}
                                            style={{
                                              fontWeight: "500",
                                              fontSize: 14,
                                            }}
                                          >
                                            Invoice (PDF Only)
                                            <Tooltip
                                              title="Required"
                                              placement="right"
                                            >
                                              <span
                                                style={{
                                                  color: "red",
                                                  marginLeft: 5,
                                                  // marginBottom: 15,
                                                }}
                                              >
                                                *
                                              </span>
                                            </Tooltip>
                                          </MDTypography>
                                          <input
                                            name="invoiceFile"
                                            style={{
                                              borders: "none",
                                              marginTop: "0px",
                                              width: "100%",
                                            }}
                                            className="choose_file"
                                            type="file"
                                            autoComplete="off"
                                            accept=".pdf"
                                            id="chooseFile"
                                            onChange={(event) =>
                                              handleChangeForInvoiceFile(event)
                                            }
                                          />
                                        </MDBox>

                                        <MDBox ml={2} mb={1}>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            flexGrow={1}
                                            style={{
                                              fontWeight: "500",
                                              fontSize: 14,
                                            }}
                                          >
                                            Test Certificate (PDF Only)
                                            <span
                                              style={{
                                                color: "red",
                                                marginLeft: 5,
                                                // marginBottom: 15,
                                              }}
                                            >
                                              *
                                            </span>
                                          </MDTypography>
                                          <input
                                            name="testCertificate"
                                            style={{
                                              borders: "none",
                                              marginTop: "0px",
                                              width: "100%",
                                              // height:"15px"
                                            }}
                                            className="choose_file"
                                            type="file"
                                            autoComplete="off"
                                            accept=".pdf"
                                            id="chooseFile"
                                            onChange={(event) =>
                                              handleChangeForTestCertificate(
                                                event
                                              )
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  <div>
                                    <MDTypography variant="h5">
                                      Items
                                    </MDTypography>
                                    <hr style={{ marginBottom: "10px" }} />
                                    <FieldArray name="lineItems2">
                                      {(fieldArrayProps) => {
                                        const { push, remove, form } =
                                          fieldArrayProps;
                                        const { values } = form;
                                        const { lineItems2 } = values;

                                        return (
                                          <div>
                                            {lineItems3.map(
                                              (lineItem, index) => {
                                                const isAddButtonDisabled =
                                                  !lineItems2[index]?.asnQty ||
                                                  !lineItems2[index]
                                                    .invoiceDate ||
                                                  !lineItems2[index]
                                                    .expiryDate ||
                                                  !lineItems2[index].invoiceNo
                                                    ? true
                                                    : false;
                                                return (
                                                  <>
                                                    <Accordion>
                                                      <AccordionSummary
                                                        expandIcon={
                                                          <ExpandMoreIcon />
                                                        }
                                                        // aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{ mb: "5px" }}
                                                      >
                                                        {" "}
                                                        <MDTypography
                                                          mt={0}
                                                          variant="h5"
                                                          fontWeight="medium"
                                                          color="inherit"
                                                        >
                                                          {lineItem?.itemCode}
                                                        </MDTypography>
                                                        <MDTypography
                                                          ml={2}
                                                          mt={0.3}
                                                          variant="h6"
                                                          fontWeight="medium"
                                                          color="inherit"
                                                          style={{
                                                            color: "red",
                                                            fontStyle: "italic",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {isAddButtonDisabled ? (
                                                            "Please fill all the required fields!"
                                                          ) : (
                                                            <Icon
                                                              color="success"
                                                              style={{
                                                                marginTop:
                                                                  "3px",
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                            >
                                                              {" "}
                                                              check_circle
                                                            </Icon>
                                                          )}
                                                        </MDTypography>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                        <MDTypography
                                                          variant="h6"
                                                          ml={1}
                                                        >
                                                          Item - {index + 1}
                                                        </MDTypography>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                          key={index}
                                                        >
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Part
                                                                  No/Description
                                                                </MDTypography>

                                                                <Field
                                                                  // disabled
                                                                  type="text"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.itemCodeDes`}
                                                                  fullWidth
                                                                  defaultValue={`${lineItem.itemCode}/${lineItem.itemDescription}`}
                                                                  autoComplete="off"
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    formik.handleChange(
                                                                      e
                                                                    );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.itemCodeDes`,

                                                                      `${lineItem.itemCode}/${lineItem.itemDescription}`
                                                                    );
                                                                  }}
                                                                  value={`${lineItem.itemCode}/${lineItem.itemDescription}`}
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .itemCodeDes &&
                                                                    formik
                                                                      .errors
                                                                      .itemCodeDes &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                    // readOnly: true,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .itemCodeDes &&
                                                                  formik.errors
                                                                    .itemCodeDes && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .itemCodeDes
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Type of Packet{" "}
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="text"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.packageType`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItem?.packaging
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .packageType &&
                                                                    formik
                                                                      .errors
                                                                      .packageType &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .packageType &&
                                                                  formik.errors
                                                                    .packageType && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .packageType
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Unloading
                                                                  Point{" "}
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="text"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.unloadingPoint`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItem.unloadingPoint
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .unloadingPoint &&
                                                                    formik
                                                                      .errors
                                                                      .unloadingPoint &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .unloadingPoint &&
                                                                  formik.errors
                                                                    .unloadingPoint && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .unloadingPoint
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Delivery Plant{" "}
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="text"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.deliveryPlant`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItem.deliveryPlant
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .deliveryPlant &&
                                                                    formik
                                                                      .errors
                                                                      .deliveryPlant &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .deliveryPlant &&
                                                                  formik.errors
                                                                    .deliveryPlant && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .deliveryPlant
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Schedule
                                                                  Quantity{" "}
                                                                </MDTypography>

                                                                <Field
                                                                  // disabled
                                                                  type="number"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.scheduleQuantity`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItem.scheduleQty
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .scheduleQuantity &&
                                                                    formik
                                                                      .errors
                                                                      .scheduleQuantity &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .scheduleQuantity &&
                                                                  formik.errors
                                                                    .scheduleQuantity && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .scheduleQuantity
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Balance
                                                                  Quantity
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="number"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.balanceQty`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItem.balanceQty
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .balanceQty &&
                                                                    formik
                                                                      .errors
                                                                      .balanceQty &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .balanceQty &&
                                                                  formik.errors
                                                                    .balanceQty && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .balanceQty
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  ASN Qty
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      marginLeft: 5,
                                                                      // marginBottom: 15,
                                                                    }}
                                                                  >
                                                                    *
                                                                  </span>
                                                                </MDTypography>

                                                                <Field
                                                                  type="number"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.asnQty`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  min={1}
                                                                  required
                                                                  max={
                                                                    lineItem?.balanceQty
                                                                  }
                                                                  onChange={async (
                                                                    e
                                                                  ) => {
                                                                    formik.handleChange(
                                                                      e
                                                                    );
                                                                    let res =
                                                                      await calculatePacketDetails(
                                                                        e.target
                                                                          .value,
                                                                        lineItem.itemCode,
                                                                        userId,
                                                                        jwtToken
                                                                      );

                                                                    let res2 =
                                                                      await calculatePacketWeight(
                                                                        lineItem.itemCode,
                                                                        e.target
                                                                          .value,

                                                                        jwtToken
                                                                      );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.packetNo`,
                                                                      res?.data
                                                                        ?.data
                                                                        ?.packetQty
                                                                    );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.unitPackQty`,
                                                                      res?.data
                                                                        ?.data
                                                                        ?.unitPackQty
                                                                    );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.packetWeight`,
                                                                      res?.data
                                                                        ?.data
                                                                        ?.unitPackWeight
                                                                    );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.itemCodeDes`,
                                                                      `${lineItem.itemCode}/${lineItem.itemDescription}`
                                                                    );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.packageType`,
                                                                      lineItem.packaging
                                                                    );

                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.unloadingPoint`,
                                                                      lineItem.unloadingPoint
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.deliveryPlant`,
                                                                      lineItem.deliveryPlant
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.scheduleQuantity`,
                                                                      lineItem.scheduleQty
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.balanceQty`,
                                                                      lineItem.balanceQty
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.demandId`,
                                                                      lineItem.demandId
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `lineItems2.${index}.planDate`,
                                                                      lineItem.date
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `poNoLineNo`,
                                                                      `${lineItem.purchaseOrderNumber}/${lineItem.purchaseOrderLineNumber}`
                                                                    );
                                                                  }}
                                                                  value={
                                                                    lineItems3?.asnQty
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .asnQty &&
                                                                    formik
                                                                      .errors
                                                                      .asnQty &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .asnQty &&
                                                                  formik.errors
                                                                    .asnQty && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .asnQty
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>

                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  No of
                                                                  Packet/Bin{" "}
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="number"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.packetNo`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItems2[
                                                                      index
                                                                    ]?.packetNo
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .packetNo &&
                                                                    formik
                                                                      .errors
                                                                      .packetNo &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .packetNo &&
                                                                  formik.errors
                                                                    .packetNo && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .packetNo
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Unit Pack
                                                                  Quantity{" "}
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="number"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.unitPackQty`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  // onChange={
                                                                  //   formik.handleChange
                                                                  // }
                                                                  value={
                                                                    lineItems2[
                                                                      index
                                                                    ]
                                                                      ?.unitPackQty
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .unitPackQty &&
                                                                    formik
                                                                      .errors
                                                                      .unitPackQty &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .unitPackQty &&
                                                                  formik.errors
                                                                    .unitPackQty && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .unitPackQty
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Batch Number
                                                                  (Optional){" "}
                                                                </MDTypography>

                                                                <Field
                                                                  type="text"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.batchNumber`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    formik
                                                                      .values
                                                                      .batchNumber
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .batchNumber &&
                                                                    formik
                                                                      .errors
                                                                      .batchNumber &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .batchNumber &&
                                                                  formik.errors
                                                                    .batchNumber && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .batchNumber
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Unit Packet
                                                                  Weight (kg)
                                                                </MDTypography>

                                                                <Field
                                                                  disabled
                                                                  type="number"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.packetWeight`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    lineItems2[
                                                                      index
                                                                    ]
                                                                      ?.packetWeight
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .packetWeight &&
                                                                    formik
                                                                      .errors
                                                                      .packetWeight &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .packetWeight &&
                                                                  formik.errors
                                                                    .packetWeight && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .packetWeight
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Invoice No
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      marginLeft: 5,
                                                                      // marginBottom: 15,
                                                                    }}
                                                                  >
                                                                    *
                                                                  </span>
                                                                </MDTypography>

                                                                <Field
                                                                  type="text"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.invoiceNo`}
                                                                  fullWidth
                                                                  autoComplete="off"
                                                                  required
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    formik
                                                                      .values
                                                                      .invoiceNo
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .invoiceNo &&
                                                                    formik
                                                                      .errors
                                                                      .invoiceNo &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .invoiceNo &&
                                                                  formik.errors
                                                                    .invoiceNo && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .invoiceNo
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Invoice Date
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      marginLeft: 5,
                                                                      // marginBottom: 15,
                                                                    }}
                                                                  >
                                                                    *
                                                                  </span>
                                                                </MDTypography>

                                                                <Field
                                                                  type="date"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.invoiceDate`}
                                                                  fullWidth
                                                                  required
                                                                  max={
                                                                    currentDate
                                                                  }
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    formik
                                                                      .values
                                                                      .invoiceDate
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .invoiceDate &&
                                                                    formik
                                                                      .errors
                                                                      .invoiceDate &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                    min: currentDate,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .invoiceDate &&
                                                                  formik.errors
                                                                    .invoiceDate && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .invoiceDate
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              lg={3}
                                                            >
                                                              <MDBox
                                                                ml={1}
                                                                mb={1}
                                                              >
                                                                <MDTypography
                                                                  variant="h6"
                                                                  style={{
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                >
                                                                  Expiry Date{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      marginLeft: 5,
                                                                      // marginBottom: 15,
                                                                    }}
                                                                  >
                                                                    *
                                                                  </span>
                                                                </MDTypography>

                                                                <Field
                                                                  type="date"
                                                                  className="form-control"
                                                                  name={`lineItems2.${index}.expiryDate`}
                                                                  fullWidth
                                                                  required
                                                                  min={
                                                                    currentDate
                                                                  }
                                                                  autoComplete="off"
                                                                  onChange={
                                                                    formik.handleChange
                                                                  }
                                                                  value={
                                                                    formik
                                                                      .values
                                                                      .expiryDate
                                                                  }
                                                                  error={
                                                                    formik
                                                                      .touched
                                                                      .expiryDate &&
                                                                    formik
                                                                      .errors
                                                                      .expiryDate &&
                                                                    true
                                                                  }
                                                                  inputProps={{
                                                                    style: {
                                                                      height:
                                                                        "14px",
                                                                    },
                                                                    maxLength: 45,
                                                                  }}
                                                                />

                                                                {formik.touched
                                                                  .expiryDate &&
                                                                  formik.errors
                                                                    .expiryDate && (
                                                                    <TextError
                                                                      msg={
                                                                        formik
                                                                          .errors
                                                                          .expiryDate
                                                                      }
                                                                    />
                                                                  )}
                                                              </MDBox>
                                                            </Grid>
                                                          </Grid>
                                                        </div>
                                                      </AccordionDetails>
                                                    </Accordion>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }}
                                    </FieldArray>
                                  </div>
                                </div>
                                <MDBox>
                                  <Collapse in={isErrorCreateASN}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorCreateASN(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      {createAsnError}
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessCreateASN}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessCreateASN(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      ASN created successfully
                                    </Alert>
                                  </Collapse>
                                </MDBox>
                                <div style={{ background: "white" }}>
                                  <MDButton
                                    style={{
                                      position: "sticky",
                                      bottom: "0",
                                    }}
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    disabled={
                                      !fileForInvoiceFile?.name ||
                                      !fileForTestCertificate?.name ||
                                      formik.values.lineItems2.some(
                                        (item) =>
                                          item?.asnQty === "" ||
                                          item.invoiceDate === "" ||
                                          item.expiryDate === "" ||
                                          item.invoiceNo === ""
                                      ) ||
                                      buttonName === "Please wait..."
                                    }
                                  >
                                    {buttonName}
                                  </MDButton>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateActualQtyModal}
                onClose={handleCloseUpdateActualQtyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update Actual Qty
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateActualQtyModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Actual Qty</MDTypography>
                        <MDInput
                          type="number"
                          name="actualQty"
                          fullWidth
                          autoComplete="off"
                          inputProps={{
                            maxLength: 45,
                          }}
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.actualQty}
                          error={
                            formikForUpdate.touched.actualQty &&
                            formikForUpdate.errors.actualQty &&
                            true
                          }
                        />
                        {formikForUpdate.touched.actualQty &&
                          formikForUpdate.errors.actualQty && (
                            <TextError msg={formikForUpdate.errors.actualQty} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdateQty}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Actual Qty updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formikForUpdate.isValid}
                        >
                          Update
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openCreateDemandModal}
                onClose={handleCloseCreateDemandModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Create Demand
                    </MDTypography>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateDemandModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForCreateDemand.handleSubmit}
                    >
                      <MDBox>
                        <MDBox style={{ width: "300px" }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Item Code/Des
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css-for-itemcode"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="itemCode"
                            onBlur={() => {
                              formikForCreateDemand.handleBlur({
                                target: { name: "itemCode" },
                              });
                            }}
                            value={selectedItemCode}
                            options={itemCodeOptions}
                            onChange={(selectedOption) => {
                              handleItemCodeChange(selectedOption);
                              formikForCreateDemand.setFieldValue(
                                "itemCode",
                                selectedOption
                              );
                            }}
                          />

                          {formikForCreateDemand.touched.itemCode &&
                            formikForCreateDemand.errors.itemCode && (
                              <TextError
                                msg={formikForCreateDemand.errors.itemCode}
                              />
                            )}
                        </MDBox>

                        <MDBox>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Vendor Code
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="vendorCode"
                            onBlur={() => {
                              formikForCreateDemand.handleBlur({
                                target: { name: "vendorCode" },
                              });
                            }}
                            value={selectedVendorCode}
                            options={vendorCodeOptions}
                            onChange={(selectedOption) => {
                              handleVendorCodeChange(selectedOption);
                              formikForCreateDemand.setFieldValue(
                                "vendorCode",
                                selectedOption
                              );
                            }}
                          />

                          {formikForCreateDemand.touched.vendorCode &&
                            formikForCreateDemand.errors.vendorCode && (
                              <TextError
                                msg={formikForCreateDemand.errors.vendorCode}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Demand Date
                          </MDTypography>
                          <MDInput
                            type="date"
                            name="demandDate"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForCreateDemand.handleBlur}
                            onChange={formikForCreateDemand.handleChange}
                            value={formikForCreateDemand.values.demandDate}
                            error={
                              formikForCreateDemand.touched.demandDate &&
                              formikForCreateDemand.errors.demandDate &&
                              true
                            }
                            inputProps={{
                              min: currentDate,
                            }}
                          />
                          {formikForCreateDemand.touched.demandDate &&
                            formikForCreateDemand.errors.demandDate && (
                              <TextError
                                msg={formikForCreateDemand.errors.demandDate}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={1}>
                          <div style={{ display: "flex" }}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              PO Number
                            </MDTypography>
                          </div>
                          <MDInput
                            type="text"
                            name="purchaseOrderNumber"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForCreateDemand.handleBlur}
                            onChange={formikForCreateDemand.handleChange}
                            value={
                              formikForCreateDemand.values.purchaseOrderNumber
                            }
                            error={
                              formikForCreateDemand.touched
                                .purchaseOrderNumber &&
                              formikForCreateDemand.errors
                                .purchaseOrderNumber &&
                              true
                            }
                          />
                          {formikForCreateDemand.touched.purchaseOrderNumber &&
                            formikForCreateDemand.errors
                              .purchaseOrderNumber && (
                              <TextError
                                msg={
                                  formikForCreateDemand.errors
                                    .purchaseOrderNumber
                                }
                              />
                            )}
                        </MDBox>
                        <MDBox mb={1}>
                          <div style={{ display: "flex" }}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              PO Line Number
                            </MDTypography>
                          </div>
                          <MDInput
                            type="text"
                            name="purchaseOrderLineNumber"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForCreateDemand.handleBlur}
                            onChange={formikForCreateDemand.handleChange}
                            value={
                              formikForCreateDemand.values
                                .purchaseOrderLineNumber
                            }
                            error={
                              formikForCreateDemand.touched
                                .purchaseOrderLineNumber &&
                              formikForCreateDemand.errors
                                .purchaseOrderLineNumber &&
                              true
                            }
                          />
                          {formikForCreateDemand.touched
                            .purchaseOrderLineNumber &&
                            formikForCreateDemand.errors
                              .purchaseOrderLineNumber && (
                              <TextError
                                msg={
                                  formikForCreateDemand.errors
                                    .purchaseOrderLineNumber
                                }
                              />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Schedule Qty
                          </MDTypography>
                          <MDInput
                            type="number"
                            name="schqty"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForCreateDemand.handleBlur}
                            onChange={formikForCreateDemand.handleChange}
                            value={formikForCreateDemand.values.schqty}
                            error={
                              formikForCreateDemand.touched.schqty &&
                              formikForCreateDemand.errors.schqty &&
                              true
                            }
                          />
                          {formikForCreateDemand.touched.schqty &&
                            formikForCreateDemand.errors.schqty && (
                              <TextError
                                msg={formikForCreateDemand.errors.schqty}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={1}>
                          <div style={{ display: "flex" }}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Unloading Point
                            </MDTypography>
                          </div>
                          <MDInput
                            type="text"
                            name="unloadingPoint"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForCreateDemand.handleBlur}
                            onChange={formikForCreateDemand.handleChange}
                            value={formikForCreateDemand.values.unloadingPoint}
                            error={
                              formikForCreateDemand.touched.unloadingPoint &&
                              formikForCreateDemand.errors.unloadingPoint &&
                              true
                            }
                          />
                          {formikForCreateDemand.touched.unloadingPoint &&
                            formikForCreateDemand.errors.unloadingPoint && (
                              <TextError
                                msg={
                                  formikForCreateDemand.errors.unloadingPoint
                                }
                              />
                            )}
                        </MDBox>
                        <MDBox mb={1}>
                          <div style={{ display: "flex" }}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Delivery Plant
                            </MDTypography>
                          </div>
                          <MDInput
                            type="text"
                            name="deliveryPlant"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForCreateDemand.handleBlur}
                            onChange={formikForCreateDemand.handleChange}
                            value={formikForCreateDemand.values.deliveryPlant}
                            error={
                              formikForCreateDemand.touched.deliveryPlant &&
                              formikForCreateDemand.errors.deliveryPlant &&
                              true
                            }
                          />
                          {formikForCreateDemand.touched.deliveryPlant &&
                            formikForCreateDemand.errors.deliveryPlant && (
                              <TextError
                                msg={formikForCreateDemand.errors.deliveryPlant}
                              />
                            )}
                        </MDBox>
                        <MDBox mt={2}>
                          <Collapse in={isSuccessCreateNewDemand}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessCreateNewDemand(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Demand created successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorCreateNewDemand}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorCreateNewDemand(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForCreate}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <div className="mt-3">
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                            disabled={!formikForCreateDemand.isValid}
                          >
                            Create Demand
                          </MDButton>
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openPrintASNModal}
                onClose={handleClosePrintASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDocument}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Print
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleClosePrintASNModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDBox pt={1} pb={3} height="100%">
                      <div ref={componentRef}>
                        {printData.map((person) => {
                          return (
                            <Document>
                              <Page size="A5" style={styles.page}>
                                <View
                                  style={{
                                    marginBottom: "20px",
                                    marginRight: "0px",
                                    marginTop: "50px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <table style={{ width: "120%" }}>
                                    <thead
                                      style={{
                                        borderSpacing: "5px",
                                        border: "1px solid grey",
                                      }}
                                    >
                                      <tr style={{ padding: "10px" }}>
                                        <th style={{ padding: "10px" }}>
                                          ASN Code
                                        </th>
                                        <th style={{ padding: "10px" }}>
                                          Bin Code
                                        </th>

                                        <th style={{ padding: "20px" }}>QTY</th>
                                        <th style={{ padding: "20px" }}>
                                          QR Code
                                        </th>
                                      </tr>
                                    </thead>
                                    <hr style={{ width: "453%" }} />
                                    <tbody>
                                      <tr
                                        style={{
                                          padding: "10px",
                                          border: "1px solid grey",
                                        }}
                                      >
                                        <td style={{ padding: "10px" }}>
                                          {person.asnCode}
                                        </td>
                                        <td style={{ padding: "10px" }}>
                                          {person.binCode}
                                        </td>
                                        <td style={{ padding: "20px" }}>
                                          {person.qty}
                                        </td>
                                        <td
                                          style={{
                                            padding: "20px",
                                            marginLeft: "50px",
                                          }}
                                        >
                                          <QRCode
                                            size={200}
                                            style={{
                                              height: "auto",
                                              maxWidth: "100%",
                                              width: "100%",
                                            }}
                                            value={person.binCode}
                                            viewBox={`0 0 256 256`}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </View>
                              </Page>
                            </Document>
                          );
                        })}
                      </div>

                      <MDButton
                        color="success"
                        type="button"
                        onClick={handlePrint}
                        style={{ marginLeft: "10px" }}
                      >
                        Print
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openCreatePickupListModal}
                onClose={handleCloseCreatePickupListModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDTypography>
                    Are you sure you want to create pickup list?
                  </MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseCreatePickupListModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="info"
                      onClick={onClickCreatePickupList}
                    >
                      Create
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isErrorCreatePickupList}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreatePickupList(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Could not Create the Pickup List */}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
        {uploadLoader ||
          (loading && (
            <Backdrop
              sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ))}
        <Footer />

        <ToastContainer />
      </MDBox>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getDirectDemandReducer: state.getDirectDemandReducer,
    itemMaster: state.itemMaster,
    partNoForPlannerReducer: state.partNoForPlannerReducer,
    partNoReducer: state.partNoReducer,
    plantReducer: state.plantReducer,
    vendorsListForPlannerReducer: state.vendorsListForPlannerReducer,
    packageTypeReducer: state.packageTypeReducer,
    packageDetailsReducer: state.packageDetailsReducer,
    deliveryPlantReducer: state.deliveryPlantReducer,
    unloadingPointReducer: state.unloadingPointReducer,
    packetWeightReducer: state.packetWeightReducer,
    binsForPrintReducer: state.binsForPrintReducer,
    getVendorCodeReducer: state.getVendorCodeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getItemsDetailsForASNReducer: state.getItemsDetailsForASNReducer,
    getPurchaseOrdersForDemandReducer: state.getPurchaseOrdersForDemandReducer,
    getItemCodeByPOReducer: state.getItemCodeByPOReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getDirectDemand: (
      userId,
      purchaseOrderNumber,
      partNo,
      plant,
      vendorCode,
      roleName,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getDirectDemand(
          userId,
          purchaseOrderNumber,
          partNo,
          plant,
          vendorCode,
          roleName,
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    uploadForDirectDemand: (payload, jwtToken) =>
      dispatch(uploadForDirectDemand(payload, jwtToken)),
    getBinsForPrint: (asnCode, jwtToken) =>
      dispatch(getBinsForPrint(asnCode, jwtToken)),
    getPartNoForPlanner: (jwtToken) => dispatch(getPartNoForPlanner(jwtToken)),
    getPartNo: (userId, jwtToken) => dispatch(getPartNo(userId, jwtToken)),
    getPlant: (jwtToken) => dispatch(getPlant(jwtToken)),
    getVendorsListForPlanner: (jwtToken) =>
      dispatch(getVendorsListForPlanner(jwtToken)),
    getPackageType: (partCode, jwtToken) =>
      dispatch(getPackageType(partCode, jwtToken)),
    calculatePacketWeight: (partCode, asnQtyChange, jwtToken) =>
      dispatch(calculatePacketWeight(partCode, asnQtyChange, jwtToken)),
    getUnloadingPoint: (id, jwtToken) =>
      dispatch(getUnloadingPoint(id, jwtToken)),
    getDeliveryPlant: (id, jwtToken) =>
      dispatch(getDeliveryPlant(id, jwtToken)),
    createAsn: (payload, jwtToken) => dispatch(createAsn(payload, jwtToken)),
    calculatePacketDetails: (asnQty, partCode, userId, jwtToken) =>
      dispatch(calculatePacketDetails(asnQty, partCode, userId, jwtToken)),

    // updateActualQty: (payload, jwtToken) =>
    //   dispatch(updateActualQty(payload, jwtToken)),
    createDirectDemand: (payload, jwtToken) =>
      dispatch(createDirectDemand(payload, jwtToken)),
    getVendorCodes: (jwtToken) => dispatch(getVendorCodes(jwtToken)),
    getItemsDetailsForASN: (payload, jwtToken) =>
      dispatch(getItemsDetailsForASN(payload, jwtToken)),
    getPurchaseOrdersForDemand: (jwtToken) =>
      dispatch(getPurchaseOrdersForDemand(jwtToken)),
    getItemCodeByPO: (poNo, jwtToken) =>
      dispatch(getItemCodeByPO(poNo, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DailyDemand);
