/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import Icon from "@mui/material/Icon";

// Functions from store
import { getRegisteredVendors } from "../../store/registered-vendors/registeredVendorsActions";
import { getSubModulesByRoleId } from "store";
//cookies
import Cookies from "universal-cookie";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const RegisteredVendors = ({
  getRegisteredVendors,
  registeredVendorsReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let registerVModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d0113c1f-d817-4f47-b0da-b77b49992502"
  );
  let viewForm = registerVModule
    ? registerVModule.actionId.includes("73366694-b7d0-4042-a3f4-ae211c891e8a")
    : null;
  let viewRegisteredVendors = registerVModule
    ? registerVModule.actionId.includes("910a8464-a0f9-4917-aa57-372a654bff92")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  useEffect(() => {
    getRegisteredVendors(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "vendor Name", accessor: "vendorName" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Email", accessor: "email" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "Action", accessor: "action", cannotSearch: true },
    ],
    rows: [],
  });
  const { columns, rows } = state;
  const viewFunction = (data) => {
    navigate(`/registered-vendors/${data.vendorUserId}`);
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempUsers = [];
    let registeredVendor = registeredVendorsReducer.registeredVendors.data
      ? registeredVendorsReducer.registeredVendors.data
      : [];

    setPaginationObj(registeredVendorsReducer?.registeredVendors?.pagination);
    setLoading(registeredVendorsReducer.loading);
    registeredVendor.map((user) => {
      const formatDate = new Date(user.cd);
      const singleUser = {
        privateKey: user.email,
        vendorName: user.vendorName,
        contactPerson: user.contactPerson,
        email: user.email,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          <Tooltip title="View Form">
            {viewForm &&
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => viewFunction(user)}
            >
              <Icon>visibility_icon</Icon>
            </MDButton>}
          </Tooltip>
        ),
      };
      viewRegisteredVendors && tempUsers.push(singleUser);
    });
    setState({ ...state, rows: tempUsers });
  }, [registeredVendorsReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {!loading ? ( */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  color="Dark"
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    registeredVendorsReducer: state.registeredVendorsReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getRegisteredVendors: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getRegisteredVendors(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(RegisteredVendors);
