/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// DatePicker components and  styling

import "react-datepicker/dist/react-datepicker.css";

import { CSVLink } from "react-csv";

// Utils component
import TextError from "utils/TextError";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getVendorMasterMapping,
  getVendorsListForPlanner,
  getPartNoForPlanner,
  uploadCsvForVendorMaterialMappingMaster,
  getListOfBinTypes,
  updateVendorMaterialMapping,
  getSubModulesByRoleId,
  getPartNo,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadButton from "components/standardized-components/DownloadButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";

const cookies = new Cookies();

let desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 500 },
  color: "black",

  boxShadow: 24,
  p: 2,
  pt: 3,
};

const VendorMaterialMappingMaster = ({
  getVendorMasterMapping,
  vendorMasterMappingReducer,
  getVendorsListForPlanner,
  vendorsListForPlannerReducer,
  getPartNoForPlanner,
  partNoForPlannerReducer,
  uploadCsvForVendorMaterialMappingMaster,
  getListOfBinTypes,
  listOfBinTypesReducer,
  updateVendorMaterialMapping,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPartNo,
  partNoReducer,
}) => {
  let user = cookies.get("loginDetailsForWMS");
  let roleName = user.data.roleName;
  let roleId = user.data.roleId;
  let userId = user.data.userId;

  let jwtToken = user?.jwt;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let vendorMaterialMappingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "42976586-bfb4-4b07-bca2-b1d566fdcda5"
  );

  let viewAccess = vendorMaterialMappingModule
    ? vendorMaterialMappingModule.actionId.includes(
        "7ad4dc8b-779a-4580-83cf-2b9b36b19be4"
      )
    : null;

  let updateAccess = vendorMaterialMappingModule
    ? vendorMaterialMappingModule.actionId.includes(
        "f4f72b9e-f959-4f50-940a-c66dfddf0452"
      )
    : null;

  let uploadAccess = vendorMaterialMappingModule
    ? vendorMaterialMappingModule.actionId.includes(
        "3d5e371c-1101-4759-ab07-b515ce441cc6"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [vendorsListForPlannerSelected, setVendorsListForPlannerSelected] =
    useState("");
  const [partNoSelected, setPartNoSelected] = useState("");

  useEffect(() => {
    getVendorMasterMapping(
      partNoSelected ? partNoSelected.value : "",
      vendorsListForPlannerSelected ? vendorsListForPlannerSelected.value : "",
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );
  }, [
    partNoSelected,
    vendorsListForPlannerSelected,
    pageNo,
    rowsPerPage,
    searchObject,
  ]);

  useEffect(() => {
    // roleName == "vendor" &&
    getPartNo(
      vendorsListForPlannerSelected
        ? vendorsListForPlannerSelected?.vendorId
        : userId,
      jwtToken
    );
  }, [userId, vendorsListForPlannerSelected]);
  useEffect(() => {
    roleName !== "vendor" && getPartNoForPlanner(jwtToken);
  }, []);

  useEffect(() => {
    getVendorsListForPlanner(jwtToken);
  }, []);

  useEffect(() => {
    getListOfBinTypes(jwtToken);
  }, []);

  const [partNoOptions, setPartNoOptions] = useState([]);

  useEffect(() => {
    let tempPartNoOptions = [];
    let data = partNoReducer.partNoData.data
      ? partNoReducer.partNoData.data
      : [];
    data.map((partNo) => {
      tempPartNoOptions.push({
        label: partNo.itemCode,
        value: partNo.itemCode,
      });
    });
    setPartNoOptions(tempPartNoOptions);
  }, [partNoReducer]);

  useEffect(() => {
    if (roleName == "vendor") {
      setPartNoSelected({
        label: partNoOptions[0]?.label,
        value: partNoOptions[0]?.value,
      });
    }
  }, [partNoOptions]);

  const handleChangePartNoForPlanner = (selectedOption) => {
    setPartNoSelected(selectedOption);
  };

  const [partNoForPlannerOptions, setPartNoForPlannerOptions] = useState([]);

  useEffect(() => {
    let tempPartNoForPlannerOptions = [];
    let data = partNoForPlannerReducer.partNoForPlannerData.data
      ? partNoForPlannerReducer.partNoForPlannerData.data
      : [];
    data.map((partNo) => {
      tempPartNoForPlannerOptions.push({
        label: partNo.itemCode,
        value: partNo.itemCode,
      });
    });
    setPartNoForPlannerOptions(tempPartNoForPlannerOptions);
  }, [partNoForPlannerReducer]);

  const handleChangePartNo = (selectedOption) => {
    setPartNoSelected(selectedOption);
  };

  const handleChangeVendorsListForPlanner = (selectedOption) => {
    setVendorsListForPlannerSelected(selectedOption);
  };

  const [vendorsListForPlannerOptions, setVendorsListForPlannerOptions] =
    useState([]);

  useEffect(() => {
    let tempVendorsListForPlannerOptions = [];
    let data = vendorsListForPlannerReducer.vendorsListForPlannerData.data
      ? vendorsListForPlannerReducer.vendorsListForPlannerData.data
      : [];
    data.map((vendorsListForPlanner) => {
      tempVendorsListForPlannerOptions.push({
        label: vendorsListForPlanner.vendorName,
        value: vendorsListForPlanner.vendorCode,
        vendorId: vendorsListForPlanner.vendorUserId,
      });
    });
    setVendorsListForPlannerOptions(tempVendorsListForPlannerOptions);
  }, [vendorsListForPlannerReducer]);

  const handleChangeListOfBinTypes = (selectedOption) => {
    setListOfBinTypesSelected(selectedOption);
  };

  const [listOfBinTypesOptions, setListOfBinTypesOptions] = useState([]);

  useEffect(() => {
    let tempListOfBinTypesOptions = [];
    let data = listOfBinTypesReducer.listOfBinTypesData.data
      ? listOfBinTypesReducer.listOfBinTypesData.data
      : [];

    data.map((binType) => {
      tempListOfBinTypesOptions.push({
        label: `${binType.binPackType}/${binType.binPartNo}`,
        value: binType.binPartNo,
        type: binType.binPackType,
      });
    });
    setListOfBinTypesOptions(tempListOfBinTypesOptions);
  }, [listOfBinTypesReducer]);

  const [state, setState] = useState({
    columns: [
      { Header: "Part Code", accessor: "itemCode", align: "left" },
      {
        Header: "Part Description",
        accessor: "itemDescription",
        align: "left",
      },
      {
        Header: "VENDOR CODE",
        accessor: "vendorCode",
        align: "left",
        width: "30px",
      },
      { Header: "VENDOR NAME", accessor: "vendorName", align: "left" },
      { Header: "QUOTA", accessor: "quota", align: "left" },
      { Header: "lcd", accessor: "leadCoverageDay", align: "left" },
      { Header: "snp", accessor: "snp", align: "left" },
      { Header: "Bin Type", accessor: "binType", align: "left" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempMonthlyDemand = [];
    let data = vendorMasterMappingReducer.vendorMasterMappingData.data
      ? vendorMasterMappingReducer.vendorMasterMappingData.data
      : [];
    setPaginationObj(
      vendorMasterMappingReducer?.vendorMasterMappingData?.pagination
    );
    setLoading(vendorMasterMappingReducer.loading);
    data.map((singleMonthlyDemandData) => {
      let des = singleMonthlyDemandData?.itemDescription;
      let len = desLength;

      const dataObject = {
        privateKey: singleMonthlyDemandData.id,
        id: singleMonthlyDemandData.id,

        partNoDes: `${singleMonthlyDemandData.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        partCode: singleMonthlyDemandData.itemCode,
        itemCode: singleMonthlyDemandData.itemCode,
        partDescription: singleMonthlyDemandData.itemDescription,
        itemDescription: singleMonthlyDemandData.itemDescription,
        stock: singleMonthlyDemandData.stock,
        binPartNo: singleMonthlyDemandData.binPartNo,
        binType: singleMonthlyDemandData.binType,
        vendorCode: singleMonthlyDemandData.vendorCode,
        vendorName: singleMonthlyDemandData.vendorName,
        quota: singleMonthlyDemandData.quota,
        leadCoverageDay: singleMonthlyDemandData.leadCoverageDay,
        binTypeCode: singleMonthlyDemandData.binTypeCode,
        snp: singleMonthlyDemandData.snp,
        action: updateAccess && (
          <Tooltip title="Edit">
            <MDButton
              variant="gradient"
              color="success"
              iconOnly
              type="button"
              onClick={() => handleOpenUpdateModal(singleMonthlyDemandData)}
              style={{ marginRight: "5px" }}
            >
              <Icon>edit_sharp</Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempMonthlyDemand.push(dataObject);
    });

    viewAccess && setState({ ...state, rows: tempMonthlyDemand });
  }, [vendorMasterMappingReducer]);

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [singleDataForUpdate, setSingleDataForUpdate] = useState({});

  const [listOfBinTypesSelected, setListOfBinTypesSelected] = useState({});
  const handleOpenUpdateModal = (data = {}) => {
    setListOfBinTypesSelected({
      label: `${data.binType}/${data.binTypeCode}`,
      value: data.binTypeCode,
      type: data.binType,
    });
    setSingleDataForUpdate(data);
    setOpenUpdateModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
    formikForUpdate.setErrors({});
  };

  const [errorMsgForUpdate, setIsErrorMsgForUpdate] = useState("");
  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    binType: singleDataForUpdate ? singleDataForUpdate.binType : "",
    quota: singleDataForUpdate ? singleDataForUpdate.quota : "",
    coverage: singleDataForUpdate ? singleDataForUpdate.leadCoverageDay : "",
    snp: singleDataForUpdate ? singleDataForUpdate.snp : "",
  };

  const validationSchemaForUpdate = Yup.object({
    binType: Yup.string().required("Select a bin type"),
    quota: Yup.string().required("Enter a valid quota"),
    coverage: Yup.string().required("Enter a valid coverage"),
    snp: Yup.string().required("Enter a valid snp"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      quota: values.quota.trim(),
      coverage: values.coverage.trim(),
      snp: values.snp.trim(),
      vendorCode: singleDataForUpdate.vendorCode,
      itemCode: singleDataForUpdate.itemCode,
      binType: listOfBinTypesSelected.type,
      binTypeCode: listOfBinTypesSelected.value,
      userId: userId,
    };

    let res = await updateVendorMaterialMapping(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getVendorMasterMapping(
        partNoSelected ? partNoSelected.value : "",
        vendorsListForPlannerSelected
          ? vendorsListForPlannerSelected.value
          : "",
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setIsErrorMsgForUpdate(res?.data?.data?.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadLoader, setUploadLoader] = useState(false);

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    setUploadLoader(true);

    let data = new FormData();

    data.append("file", uploadCsvData);
    data.append("userId", userId);

    let res = await uploadCsvForVendorMaterialMappingMaster(data, jwtToken);

    if (res.status) {
      setUploadLoader(false);
      getVendorMasterMapping(
        partNoSelected ? partNoSelected.value : "",
        vendorsListForPlannerSelected
          ? vendorsListForPlannerSelected.value
          : "",
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      setUploadLoader(false);
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },

    {
      label: "vendorCode",
      key: "vendorCode",
    },

    {
      label: "stock",
      key: "stock",
    },
    {
      label: "snp",
      key: "snp",
    },

    {
      label: "quota",
      key: "quota",
    },
    {
      label: "binTypeCode",
      key: "binTypeCode",
    },
    {
      label: "leadCoverageDay",
      key: "leadCoverageDay",
    },
  ];

  const headers2 = [
    {
      label: "itemCode",
      key: "itemCode",
    },

    {
      label: "itemDescription",
      key: "itemDescription",
    },
    {
      label: "vendorCode",
      key: "vendorCode",
    },
    {
      label: "vendorName",
      key: "vendorName",
    },

    {
      label: "stock",
      key: "stock",
    },
    {
      label: "snp",
      key: "snp",
    },

    {
      label: "quota",
      key: "quota",
    },
    {
      label: "binTypeCode",
      key: "binTypeCode",
    },
    {
      label: "leadCoverageDay",
      key: "leadCoverageDay",
    },
  ];

  const csvLink = {
    filename: "VendorMasterMapping.csv",
    headers: headers,
    data: [],
  };

  const csvLink2 = {
    filename: "VendorMasterMapping.csv",
    headers: headers2,
    data: rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-4}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <MDBox>
                      <MDBox
                        display="flex"
                        mt={2}
                        flexDirection={{ xs: "column", lg: "row" }}
                      >
                        {roleName !== "vendor" && (
                          <MDBox
                            width={{ xs: "250px", lg: "400px" }}
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Vendors
                            </MDTypography>
                            <Select
                              isClearable
                              placeholder="Vendors"
                              className="select-css-for-filter"
                              value={vendorsListForPlannerSelected}
                              options={vendorsListForPlannerOptions}
                              onChange={handleChangeVendorsListForPlanner}
                              autoComplete="off"
                            />
                          </MDBox>
                        )}
                        <MDBox
                          width={{ xs: "250px", lg: "350px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Part Code
                          </MDTypography>
                          {roleName !== "vendor" ? (
                            <Select
                              placeholder="Part No"
                              className="select-css-for-filter"
                              value={partNoSelected}
                              options={
                                vendorsListForPlannerSelected
                                  ? partNoOptions
                                  : partNoForPlannerOptions
                              }
                              onChange={handleChangePartNo}
                              autoComplete="off"
                            />
                          ) : (
                            <Select
                              placeholder="Part No"
                              className="select-css-for-filter"
                              value={partNoSelected}
                              options={partNoOptions}
                              onChange={handleChangePartNo}
                              autoComplete="off"
                            />
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={8} lg={4}>
                    <MDBox
                      color="text"
                      px={2}
                      display="flex"
                      flexDirection="column"
                    >
                      <MDBox
                        display="flex"
                        marginLeft={0}
                        marginTop="10px"
                        marginBottom="10px"
                      >
                        {uploadAccess && (
                          <CSVLink {...csvLink}>
                            <DownloadButton
                              tooltip="Download Template"
                              style={{ marginRight: "1rem" }}
                              type="button"
                              variant="outlined"
                              color="error"
                              iconOnly
                            >
                              <Icon>download</Icon>
                            </DownloadButton>
                          </CSVLink>
                        )}
                        <CSVLink {...csvLink2}>
                          <DownloadDataButton
                            style={{ marginRight: "1rem" }}
                            type="button"
                            variant="outlined"
                            color="success"
                            iconOnly
                            tooltip="Download All Data"
                          >
                            <Icon>download</Icon>
                          </DownloadDataButton>
                        </CSVLink>
                        {uploadAccess && (
                          <UploadButton
                            type="button"
                            variant="outlined"
                            color="info"
                            iconOnly
                            onClick={uploadCsvFilterHandler}
                            tooltip="Upload CSV"
                          >
                            <Icon>upload</Icon>
                          </UploadButton>
                        )}
                      </MDBox>
                      <MDBox mr={2}>
                        {uploadCsvFilter && (
                          <div>
                            <div>
                              <input
                                className="choose_file"
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                  setUploadCsvData(e.target.files[0]);
                                }}
                              />

                              <MDButton
                                disabled={
                                  !uploadCsvData.name ||
                                  uploadCsvData.type !== "text/csv"
                                }
                                variant="contained"
                                color="info"
                                style={{
                                  marginLeft: "0",
                                  marginTop: "0.3rem",
                                  padding: 1,
                                  height: "10px",
                                }}
                                type="button"
                                onClick={uploadCsvHandler}
                              >
                                Upload
                              </MDButton>
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  marginLeft: "0px",
                                  fontStyle: "italic",
                                }}
                              >
                                <strong>*</strong> Please upload
                                <strong>".csv"</strong> file only
                              </span>
                            </div>
                          </div>
                        )}
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={1}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <Modal
                open={openUpdateModal}
                onClose={handleCloseUpdateModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">
                          Bin Type/Part No.
                        </MDTypography>
                        <Select
                          className="select-css"
                          placeholder="Choose one..."
                          name="binType"
                          options={listOfBinTypesOptions}
                          value={listOfBinTypesSelected}
                          onChange={(selectedOption) => {
                            handleChangeListOfBinTypes(selectedOption);
                            formikForUpdate.handleChange("binType")(
                              selectedOption.value
                            );
                          }}
                        />
                        {formikForUpdate.touched.binType &&
                          formikForUpdate.errors.binType && (
                            <TextError msg={formikForUpdate.errors.binType} />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="h6">Quota</MDTypography>
                        <MDInput
                          type="number"
                          name="quota"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.quota}
                          error={
                            formikForUpdate.touched.quota &&
                            formikForUpdate.errors.quota &&
                            true
                          }
                        />
                        {formikForUpdate.touched.quota &&
                          formikForUpdate.errors.quota && (
                            <TextError msg={formikForUpdate.errors.quota} />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="h6">LCD</MDTypography>
                        <MDInput
                          type="number"
                          name="coverage"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.coverage}
                          error={
                            formikForUpdate.touched.coverage &&
                            formikForUpdate.errors.coverage &&
                            true
                          }
                        />
                        {formikForUpdate.touched.coverage &&
                          formikForUpdate.errors.coverage && (
                            <TextError msg={formikForUpdate.errors.coverage} />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="h6">SNP</MDTypography>
                        <MDInput
                          type="number"
                          name="snp"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.snp}
                          error={
                            formikForUpdate.touched.snp &&
                            formikForUpdate.errors.snp &&
                            true
                          }
                        />
                        {formikForUpdate.touched.snp &&
                          formikForUpdate.errors.snp && (
                            <TextError msg={formikForUpdate.errors.snp} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Data updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={
                            !formikForUpdate.isValid ||
                            listOfBinTypesSelected.value == ""
                          }
                        >
                          Update
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
        {uploadLoader ||
          (loading && (
            <Backdrop
              sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ))}
        <ToastContainer />
      </MDBox>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    vendorMasterMappingReducer: state.vendorMasterMappingReducer,
    partNoForPlannerReducer: state.partNoForPlannerReducer,
    listOfBinTypesReducer: state.listOfBinTypesReducer,
    vendorsListForPlannerReducer: state.vendorsListForPlannerReducer,
    partNoReducer: state.partNoReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getVendorMasterMapping: (
      partCode,
      vendorCode,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getVendorMasterMapping(
          partCode,
          vendorCode,
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    getPartNoForPlanner: (jwtToken) => dispatch(getPartNoForPlanner(jwtToken)),
    getPartNo: (userId, jwtToken) => dispatch(getPartNo(userId, jwtToken)),
    getListOfBinTypes: (jwtToken) => dispatch(getListOfBinTypes(jwtToken)),
    getVendorsListForPlanner: (jwtToken) =>
      dispatch(getVendorsListForPlanner(jwtToken)),
    uploadCsvForVendorMaterialMappingMaster: (payload, jwtToken) =>
      dispatch(uploadCsvForVendorMaterialMappingMaster(payload, jwtToken)),
    updateVendorMaterialMapping: (payload, jwtToken) =>
      dispatch(updateVendorMaterialMapping(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(VendorMaterialMappingMaster);
