/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

//  some date function
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getAllGRN,

  // getLineItemsByGrn,
  getItemsByGrn,
  individualPrint,
  packagePrint,
  uploadCsv,
  getAllGRNDataForDownload,
  uploadCsvForGrn,
  getSubModulesByRoleId,
  getCreatedActivity,
  createPickList,
  getSkuForReprint,
  reprint,
} from "../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import PrintButton from "components/standardized-components/PrintButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import StandardButton from "components/standardized-components/StandardButton";
import { Backdrop } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const reprintModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForReprint = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 350, md: 800, lg: 1000, xl: 1000 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1200, xl: 1200 },
  overflow: "auto",
  height: "80%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForLabelModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 450,
  width: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  // maxWidth: 1000,
  // minWidth: 600,
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",

  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForGrnModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "auto",
  width: 1500,
  maxWidth: 1500,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForMovement = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const GroupedGrn = ({
  getAllGRN,
  allGRN,

  // getLineItemsByGrn,
  getItemsByGrn,
  // lineItemsByGrn,
  getGrnItemsReducer,
  individualPrint,
  packagePrint,
  uploadCsv,
  uploadCsvForGrn,
  getAllGRNDataForDownload,
  getAllGRNDataForDownloadReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getCreatedActivity,
  getCreatedActivityReducer,
  createPickList,
  getSkuForReprint,
  skuForReprint,
  reprint,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let role = loginDetails?.data.role;
  let printerConfig = cookies.get("printerConfigForWarehouse");

  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let groupedModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "a9b2d52b-e930-48e0-bdf3-af76bee63a23"
  );

  let uploadGroupedGrn = groupedModule
    ? groupedModule.actionId.includes("fd9283bf-eee0-4f98-993e-d9b21cc15cb6")
    : null;
  let downloadGroupedGrn = groupedModule
    ? groupedModule.actionId.includes("c86b48be-b483-4f34-9495-361df1a25768")
    : null;

  let printGroupedGrn = groupedModule
    ? groupedModule.actionId.includes("37939576-07ea-4c3e-b011-cee19ef2ba6b")
    : null;

  let reprintGroupedGrn = groupedModule
    ? groupedModule.actionId.includes("88530810-be6d-413b-a8b0-d058f0ffcb6b")
    : null;

  let viewGroupedGrn = groupedModule
    ? groupedModule.actionId.includes("891197f7-6988-4550-9036-22e9875f45cf")
    : null;

  let viewSKUS = groupedModule
    ? groupedModule.actionId.includes("a32c8f80-bd9b-4f3d-93c5-e83d8af16988")
    : null;

  let CreatePicklistAccess = groupedModule
    ? groupedModule.actionId.includes("9e4d7852-7912-4319-b224-7b0326e5f71f")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const navigate = useNavigate();

  let flag = 2;

  useEffect(() => {
    getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [createdActivitySelected, setCreatedActivitySelected] = useState("");

  const handleCreatedActivityChange = (selectedCreatedActivity) => {
    setCreatedActivitySelected(selectedCreatedActivity);
  };

  const [isSuccessPrintLabel, setIsSuccessPrintLabel] = useState(false);

  useEffect(() => {
    if (isSuccessPrintLabel) {
      setTimeout(() => {
        setIsSuccessPrintLabel(false);
      }, 3000);
    }
  }, [isSuccessPrintLabel]);

  const grnPrintedNotification = () =>
    toast.success("GRN Printed Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const initialValue = {
    printingType: "",
    noOfPackages: "",
    qtyInPackage: "",
    packageArray: [],
  };
  const [errorMsg, setErrorMsg] = useState("");

  const [printButtonName, setPrintButtonName] = useState("Yes");
  const onSubmitForPrint = async (values, { resetForm }) => {
    setPrintButtonName("Please wait...");

    if (
      d.serialNumber !== ("NA" || null) ||
      values.printingType === "Individual Printing"
    ) {
      const payload = d;
      d.printerId = printerId;
      let res = await individualPrint(payload, jwtToken);
      if (res.status) {
        setIsSuccessPrintLabel(true);
        setTimeout(() => {
          handleClosePrintLabelModal();
          setPrintButtonName("Yes");
        }, 3000);
        // setConfirmationMsg(false);
        getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
        getItemsByGrn(grnNumber, jwtToken);
        // grnPrintedNotification();
        // handleClosePrintLabelModal();
      }
      if (!res.status) {
        setErrorMsg(res?.data?.data?.msg);
        setIsErrorPrintLabel(true);
        setPrintButtonName("Yes");
      }
    }
    if (
      (d.lotNumber === ("NA" || null) && d.serialNumber === ("NA" || null)) ||
      (d.lotNumber !== ("NA" || null) && d.serialNumber === ("NA" || null)) ||
      values.printingType === "Package Printing"
    ) {
      let arr = values.packageArray;
      d.printerId = printerId;
      const payload = { ...d, totalPackage: arr.filter((n) => n) };
      let res = await packagePrint(payload, jwtToken);
      if (res.status) {
        getItemsByGrn(grnNumber, jwtToken);
        getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
        setIsSuccessPrintLabel(true);
        setTimeout(() => {
          handleClosePrintLabelModal();
          setPrintButtonName("Yes");
        }, 3000);
      }
      if (!res.status) {
        setErrorMsg(res?.data?.data?.msg);
        setIsErrorPrintLabel(true);
        setPrintButtonName("Yes");
      }
    }
  };

  const [state, setState] = useState({
    columns: [
      {
        Header: "GRN No",
        accessor: "grnNumber",
        align: "left",
      },
      {
        Header: "GRN Line Item No",
        accessor: "grnLineNumber",
        align: "left",
      },

      { Header: "Category Code", accessor: "categoryCode", align: "left" },
      { Header: "Quantity", accessor: "qty", align: "left" },

      { Header: "ASN Code", accessor: "asnCode", align: "left" },

      {
        Header: "Vendor Code",
        accessor: "vendorCode",
        align: "left",
      },
      { Header: "Vendor Name", accessor: "vendorName", align: "left" },
      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        cannotSearch: "true",
        cannotSort: "true",
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const [isErrorPrintLabel, setIsErrorPrintLabel] = useState(false);
  useEffect(() => {
    if (isErrorPrintLabel) {
      setTimeout(() => {
        setIsErrorPrintLabel(false);
      }, 3000);
    }
  }, [isErrorPrintLabel]);
  const { columns, rows } = state;

  // const [itemTable, setItemTable] = useState({
  //   columns: [
  //     { Header: "Item Code/Des", accessor: "itemCodeDes", align: "left" },
  //     { Header: "MFG Date", accessor: "mfgDate", align: "left" },
  //     { Header: "Quantity", accessor: "quantity", align: "left" },
  //     { Header: "NOB", accessor: "nob", align: "left" },
  //     { Header: "LOT/Serial No", accessor: "lotSerialNo", align: "left" },
  //     { Header: "Item Category", accessor: "itemCategory", align: "left" },
  //     { Header: "Action", accessor: "action", align: "center" },
  //   ],
  //   rows: [],
  // });

  // const { columns: itemColumns, rows: itemRows } = itemTable;

  const [grnItemTable, setGrnItemTable] = useState({
    columns: [
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
      { Header: "GRN Number", accessor: "grnNumber", align: "left" },
      { Header: "GRN Line Number", accessor: "grnLineNumber", align: "left" },
      { Header: "ASN Code", accessor: "asnCode", align: "left" },
      {
        Header: "Vendor Code",
        accessor: "vendorCode",
        align: "left",
      },
      { Header: "Vendor Name", accessor: "vendorName", align: "left" },

      { Header: "Item Code", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "QTY", accessor: "qty", align: "center" },
      { Header: "UOM", accessor: "uom", align: "left" },
      { Header: "Mfg Date", accessor: "mfgDate", align: "center" },
      { Header: "LOT No", accessor: "lotNumber", align: "left" },
      { Header: "Serial No", accessor: "serialNumber", align: "left" },
    ],
    rows: [],
  });

  const { columns: grnColumns, rows: grnRows } = grnItemTable;

  const [tableHeading, setTableHeading] = useState("");
  const [vendorCodeName, setVendorCodeName] = useState("");
  const [openGrnItemModal, setOpenGrnItemModal] = useState(false);
  const handleOpenGrnItemModal = () => {
    setOpenGrnItemModal(!openGrnItemModal);
    setOpen(false);
  };

  const [lineItems, setLineItems] = useState({});

  const handleOpenLineItemsModal = (lineItems = {}) => {
    setLineItems(lineItems);
  };

  const [grnNumber, setGrnNumber] = useState("");
  const [grnLineNumber, setGrnLineNumber] = useState("");

  const [openGrnLineItemsModal, setOpenGrnLineItemsModal] = useState(false);

  const [grnNumberForReprint, setGrnNumberForReprint] = useState("");

  const handleOpenGrnLineItemsModal = () => {
    setOpenGrnLineItemsModal(true);
    setOpen(false);
  };

  const handleCloseGrnLineItemsModal = () => {
    setOpenGrnLineItemsModal(false);
    setOpen(false);
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempGRN = [];
    let data = allGRN.GRN.data ? allGRN.GRN.data : [];
    setPaginationObj(allGRN?.GRN?.pagination);
    setLoading(allGRN.loading);
    data.map((singleGrn) => {
      const formatDate = new Date(singleGrn.cd);
      const singleUser = {
        privateKey: singleGrn.grnNumber,
        grnNumber: singleGrn.grnNumber,
        categoryCode: singleGrn.categoryCode,
        grnLineNumber: singleGrn.grnLineNumber,
        asnCode: singleGrn.asnCode,
        vendorCode: singleGrn.vendorCode,
        vendorName: singleGrn.vendorName,
        itemCode: singleGrn.itemCode,
        itemDescription: singleGrn.itemDescription,
        lotNumber: singleGrn.lotNumber,
        serialNumber: singleGrn.serialNumber,
        poNo: singleGrn.PONumber,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        invNo: singleGrn.InvoiceNo,
        vendorCodeName: `${singleGrn.vendorCode} / ${singleGrn.vendorName}`,
        itemCodeDes: `${singleGrn.itemCode} / ${singleGrn.itemDescription}`,
        lotSerialNo: `${singleGrn.lotNumber} / ${singleGrn.serialNumber}`,
        uom: singleGrn.uom,
        qty: `${singleGrn.grQty ? singleGrn.grQty : ""} ${
          singleGrn.uom ? singleGrn.uom : ""
        }`,
        status: singleGrn.isPrinted === 1 ? "Printed" : "Not Printed",
        action: (
          <>
            {/* <Tooltip title={singleGrn.isPrinted === 0 ? `Print` : `Re-Print`}>
              <MDButton
                color={singleGrn.isPrinted === 0 ? `info` : `error`}
                variant="gradient"
                // disabled={
                //   singleGrn.isPrinted === 1 && role !== "admin" ? true : false
                // }
                type="button"
                onClick={() => {
                  handleOpendModal(singleGrn);
                  handleOpenPrintLabelModal();
                  if (printerConfig === "1" && singleGrn.isPrinted === 0) {
                    handleOpendModal(singleGrn);
                    handleOpenPrintLabelModal();
                  }
                  if (printerConfig === "1" && singleGrn.isPrinted === 1) {
                    navigate("/reprint");
                  }
                  if (printerConfig === "0") {
                    navigate("/printer_configurator");
                  }
                }}
              >
                {singleGrn.isPrinted === 0 ? `Print` : `Re-Print`}
              </MDButton>
            </Tooltip> */}

            <Tooltip title="View GRN Items">
              <MDButton
                style={{ marginLeft: "0.5rem" }}
                variant="gradient"
                color="info"
                // disabled={!viewGRN}
                iconOnly
                type="button"
                onClick={() => {
                  handleOpenGrnLineItemsModal(singleGrn);
                  setGrnNumber(singleGrn.grnNumber);
                  getItemsByGrn(singleGrn.grnNumber, jwtToken);
                  getSkuForReprint(
                    singleGrn.grnNumber,
                    singleGrn.grnLineNumber,
                    jwtToken
                  );
                }}
              >
                <Icon> visibility_icon </Icon>
              </MDButton>
            </Tooltip>
            <Tooltip title="Create Picklist">
              <MDButton
                disabled={singleGrn.isDisabled === 1}
                // style={{ marginLeft: "10px" }}
                color="warning"
                variant="gradient"
                iconOnly
                type="button"
                onClick={() => {
                  handleCreatePicklistModal(singleGrn);
                  getCreatedActivity(1, jwtToken);
                  getItemsByGrn(singleGrn.grnNumber, jwtToken);
                }}
                style={{
                  // marginLeft: "5rem",
                  marginLeft: singleGrn.isDisabled === 1 ? "0.5rem" : "0.5rem",
                  background: singleGrn.isDisabled === 1 ? "grey" : undefined,
                  color: singleGrn.isDisabled === 1 ? "white" : undefined,
                  opacity: singleGrn.isDisabled === 1 ? 0.5 : undefined,
                }}
              >
                <Icon>rotate_right</Icon>
              </MDButton>
            </Tooltip>
          </>
        ),
      };
      tempGRN.push(singleUser);
    });

    viewGroupedGrn && setState({ ...state, rows: tempGRN });
  }, [allGRN]);

  let dataForDownload = getAllGRNDataForDownloadReducer.GRNDataForDownload.data
    ? getAllGRNDataForDownloadReducer.GRNDataForDownload.data
    : [];

  const [reprintTable, setReprintTable] = useState({
    columns: [
      { Header: "SKU", accessor: "sku" },
      { Header: "Qty", accessor: "qty" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns: reprintColumns, rows: reprintRows } = reprintTable;

  const [d, setd] = useState({});

  const [loading3, setLoading3] = useState(true);

  const [orderId, setOrderId] = useState("");
  const [open, setOpen] = useState(false);

  const [rowActiveColor, setRowActiveColor] = useState("none");
  const [dataForItem, setDataForItem] = useState("");

  useEffect(() => {
    let tempGRN = [];
    let data = getGrnItemsReducer.grnItems.data
      ? getGrnItemsReducer?.grnItems.data
      : [];
    const isSkuDataAvailable =
      skuForReprint.sku.data && skuForReprint.sku.data?.length ? true : false;

    setDataForItem(data);

    setLoading3(getGrnItemsReducer.loading);

    data.map((singleGrn) => {
      let des = singleGrn?.itemDescription;
      let len = desLength;
      const formatDate = new Date(singleGrn.cd);
      const singleUser = {
        privateKey: singleGrn.grnNumber,
        grnNumber: singleGrn.grnNumber,
        grnLineNumber: singleGrn.grnLineNumber,
        asnCode: singleGrn.asnCode,
        itemId: singleGrn.itemId,
        locationId: singleGrn.locationId,
        itemCode: singleGrn.itemCode,
        itemDescription: singleGrn.itemDescription,
        itemCodeDes: `${singleGrn.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        vendorCode: `${singleGrn.vendorCode}`,
        vendorName: `${singleGrn.vendorName}`,
        lotNumber: `${singleGrn.lotNumber}`,
        serialNumber: `${singleGrn.serialNumber}`,
        uom: singleGrn.uom,
        qty: singleGrn.qty,
        mfgDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        expand: singleGrn.isPrinted === 1 && (
          <IconButton
            aria-label="expand row"
            size="small"
            style={{
              marginRight: "-80px",
              color: singleGrn.isExpandDisabled === 0 ? "#000" : "#b0b0b0",
              cursor:
                singleGrn.isExpandDisabled === 0 ? "pointer" : "not-allowed",
              opacity: singleGrn.isExpandDisabled === 0 ? 1 : 0.6,
              backgroundColor:
                singleGrn.isExpandDisabled === 0 ? "" : "#e0e0e0",
              border: `1px solid ${
                singleGrn.isExpandDisabled === 0 ? "" : "#d0d0d0"
              }`,
              borderRadius: "6px",
              transition: "all 0.3s ease",
              height: "30px",
              textAlign: "center",
              boxShadow:
                singleGrn.isExpandDisabled === 0
                  ? "0 4px 6px rgba(0, 0, 0, 0.1)"
                  : "none",
              pointerEvents: singleGrn.isExpandDisabled === 0 ? "auto" : "none",
            }}
            disabled={singleGrn.isExpandDisabled === 1}
            className="expand-collapse-button"
          >
            {orderId === singleGrn.grnLineNumber && open === true ? (
              <MDTypography>
                <KeyboardArrowUpIcon
                  onClick={() => {
                    // setTitle(singleGrn.orderNumber);
                    setOrderId(singleGrn.grnLineNumber);
                    setOpen(false);
                    setRowActiveColor("none");
                  }}
                />
              </MDTypography>
            ) : (
              <MDTypography>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    // setTitle(singleGrn.orderNumber);
                    setOrderId(singleGrn.grnLineNumber);
                    getSkuForReprint(
                      singleGrn.grnNumber,
                      singleGrn.grnLineNumber,
                      jwtToken
                    );
                    setOpen(true);
                    setRowActiveColor("blue");
                  }}
                />
              </MDTypography>
            )}
          </IconButton>
        ),
        nestedTableComponent: (
          <Collapse
            in={orderId === singleGrn.grnLineNumber && open}
            timeout="auto"
            unmountOnExit
          >
            <MDBox className="nested-table-container-grn">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // p={1}
                ml={13}
              >
                {!loading2 ? (
                  <DataTable
                    table={{ columns: reprintColumns, rows: reprintRows }}
                    totalRecords={rows.length}
                    isSorted={true}
                    canSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={false}
                    noEndBorder
                    subTableColor="#e0e0de76"
                    subTableHeaderColor="#FFFFFF"
                    tableHoverColor="#FFFFFF"
                    // tableheadColor="#b4b4b276"
                    // tableEntriesColor="#b4b4b276"
                    tableHeaderHoverColor="green"
                    subTableBorderRadius={0}
                    subTableHoverColor="none"
                    searchBarForSubTable={true}
                    subTableEntries={true}
                    suid2={true}
                    className="nested-table"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginLeft: "25rem",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </MDBox>
          </Collapse>
        ),
        action: (
          <>
            {singleGrn.isPrinted === 0 && (
              <PrintButton
                title={singleGrn.isPrinted === 0 ? `Print` : `Re-Print`}
                color={singleGrn.isPrinted === 0 ? `success` : `error`}
                variant="gradient"
                iconOnly
                // disabled={
                //   singleGrn.isPrinted === 1 && role !== "admin" ? true : false
                // }
                disabled={
                  singleGrn.isPrinted === 0 ? !printGroupedGrn : !viewSKUS
                }
                type="button"
                onClick={() => {
                  // handleOpendModal(singleGrn);
                  // handleOpenPrintLabelModal();
                  // if (printerConfig === "1" && singleGrn.isPrinted === 0) {
                  //   handleOpendModal(singleGrn);
                  //   handleOpenPrintLabelModal();
                  // }
                  // if (printerConfig === "1" && singleGrn.isPrinted === 1) {
                  //   navigate("/reprint");
                  // }
                  // if (printerConfig === "0") {
                  //   navigate("/printer_configurator");
                  // }
                  if (singleGrn.isPrinted === 0) {
                    handleOpendModal(singleGrn);
                    handleOpenPrintLabelModal();
                  }
                  if (singleGrn.isPrinted === 1) {
                    // navigate("/reprint");
                    handleOpenReprintModal();
                    setGrnNumber(singleGrn.grnNumber);
                    setGrnNumberForReprint(singleGrn.grnNumber);
                    setGrnLineNumber(singleGrn.grnLineNumber);
                    getSkuForReprint(
                      singleGrn.grnNumber,
                      singleGrn.grnLineNumber,
                      jwtToken
                    );
                  }
                }}
              >
                <Icon> print </Icon>
                {/* {singleGrn.isPrinted === 0 ? `Print` : `Re-Print`} */}
              </PrintButton>
            )}
            {CreatePicklistAccess && (
              <StandardButton
                tooltip="Create Picklist"
                disabled={singleGrn.isDisabled === 1}
                // style={{ marginLeft: "10px" }}
                color="warning"
                variant="gradient"
                iconOnly
                icon="rotate_right"
                type="button"
                onClick={() => handleCreatePicklistModal(singleGrn)}
                style={{
                  // marginLeft: "5rem",
                  marginLeft: singleGrn.isDisabled === 1 ? "0.5rem" : "0.5rem",
                  background: singleGrn.isDisabled === 1 ? "grey" : undefined,
                  color: singleGrn.isDisabled === 1 ? "white" : undefined,
                  opacity: singleGrn.isDisabled === 1 ? 0.5 : undefined,
                }}
              >
                <Icon>rotate_right</Icon>
              </StandardButton>
            )}
          </>
        ),
      };
      tempGRN.push(singleUser);
    });
    setGrnItemTable({ ...grnItemTable, rows: tempGRN });
  }, [getGrnItemsReducer, open, reprintTable]);

  const handleOpendModal = (item = {}) => {
    setd(item);
  };

  const handleCloseLineItemsModal = () => {
    setOpenGrnItemModal(false);
  };

  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);
  //    const handleClosePrintLabelModal = () => {
  //     setOpenPrintLabelModal(false);
  //   };

  const handleOpenPrintLabelModal = () => {
    setOpenPrintLabelModal(true);
  };
  const handleClosePrintLabelModal = () => {
    // setBalance("");
    setConfirmationMsg(false);
    setOpenPrintLabelModal(false);
    setErrorMsg("");
    setIsErrorPrintLabel(false);
  };

  const [confirmationMsg, setConfirmationMsg] = useState(false);

  const onClickForConfirmation = () => {
    setConfirmationMsg(true);
  };

  const [sumOfArray, setSumOfArray] = useState("");

  const [balance, setBalance] = useState("");

  useEffect(() => {
    let rem = d.qty - sumOfArray;
    if (isNaN(rem)) {
      setBalance("");
    }
    if (!isNaN(rem)) {
      setBalance(rem);
    }
  }, [sumOfArray]);

  let pushPackage;
  const [openBalanceItemModal, setOpenBalanceItemModal] = useState(false);

  const handleCloseBalanceItemModal = () => {
    setOpenBalanceItemModal(false);
  };

  const [balanceItemMsg, setBalanceItemMsg] = useState("");

  useEffect(() => {
    if (balance > 0) {
      setBalanceItemMsg(`${balance} ${d.uom} remaining`);
    }
    if (balance < 0) {
      setBalanceItemMsg(`Remove ${Math.abs(balance)} ${d.uom}`);
    }
    if (balance === 0) {
      setBalanceItemMsg("Qty matched");
    }
  }, [balance]);

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("grn", uploadCsvData);

    let res = await uploadCsvForGrn(data, jwtToken);

    if (res.status) {
      getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  const [isSuccessCreatedActivity, setIsSuccessCreatedActivity] =
    useState(false);
  const [isErrorCreatedActivity, setIsErrorCreatedActivity] = useState(false);
  const [isErrorMsgForCreatedActivity, setIsErrorMsgForCreatedActivity] =
    useState("");

  useEffect(() => {
    if (isErrorCreatedActivity) {
      setTimeout(() => {
        setIsErrorCreatedActivity(false);
      }, 3000);
    }
  }, [isErrorCreatedActivity]);

  useEffect(() => {
    if (isSuccessCreatedActivity) {
      setTimeout(() => {
        setIsSuccessCreatedActivity(false);
      }, 3000);
    }
  }, [isSuccessCreatedActivity]);

  const [createdActivityOptions, setCreatedActivityOptions] = useState([]);
  const [itemCodes, setItemCodes] = useState("");

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setCreatedActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const [createPicklistModal, setCreatePicklistModal] = useState(false);
  const handleCreatePicklistModal = (data = {}) => {
    setCreatePicklistModal(true);
    setd(data);
    setCreatePicklistModal(!createPicklistModal);
    setCreatedActivitySelected("");
  };

  const handleCreateClosePicklistModal = (data = {}) => {
    setCreatePicklistModal(false);
  };

  const initialValuesForAdd = {
    mvtId: "",
    documentTypeId: "",
  };
  const validationSchemaForAdd = Yup.object({
    // mvtId: Yup.string().required("Select activity name"),
  });

  const [picklistButtonName, setPicklistButtonName] = useState("Submit");

  const onSubmitForAdd = async (values, { resetForm }) => {
    setPicklistButtonName("Please wait...");
    let filteredItems = dataForItem.map(({ itemCode, qty, itemId }) => ({
      itemCode,
      qty,
      itemId,
    }));
    let payload = {
      mvtId: createdActivitySelected?.value,
      documentTypeId: 1,
      documentNumber: d.grnNumber,
      items: filteredItems,
    };
    let res = await createPickList(payload, jwtToken);
    if (res.status) {
      setIsSuccessCreatedActivity(true);
      setTimeout(() => {
        setPicklistButtonName("Submit");
        handleCreateClosePicklistModal();
      }, 2250);
      getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
    }
    if (!res.status) {
      setIsErrorCreatedActivity(true);
      setIsErrorMsgForCreatedActivity(res?.data?.data?.msg);
      setPicklistButtonName("Submit");
    }

    resetForm();
  };

  const formikForAdd = useFormik({
    initialValues: initialValuesForAdd,
    onSubmit: onSubmitForAdd,
    validationSchema: validationSchemaForAdd,
    enableReinitialize: true,
  });

  const [isErrorRePrint, setIsErrorRePrint] = useState(false);

  useEffect(() => {
    if (isErrorRePrint) {
      setTimeout(() => {
        setIsErrorRePrint(false);
      }, 3000);
    }
  }, [isErrorRePrint]);

  const [reprintData, setReprintData] = useState({});

  const [openReprintModal, setOpenReprintModal] = useState(false);

  const handleOpenReprintModal = () => {
    setOpenReprintModal(true);
  };
  const handleCloseReprintModal = () => {
    setOpenReprintModal(false);
  };

  const ReprintNotification = () =>
    toast.success("SKU Reprinted Successfully", {
      position: "top-center",
      autoClose: 2000,
    });
  const [openReprintConfirmationModal, setOpenReprintConfirmationModal] =
    useState(false);

  const handleOpenReprintConfirmationModal = (data = {}) => {
    setReprintData(data);
    setOpenReprintConfirmationModal(true);
  };
  const handleCloseReprintConfirmationModal = () => {
    setOpenReprintConfirmationModal(false);
  };

  const [reprintButtonName, setReprintButtonName] = useState("Yes");

  const onClickRePrint = async () => {
    setReprintButtonName("Please wait...");
    let payload = {
      suid: reprintData.suid,
      printerId: printerId,
    };
    let res = await reprint(payload, jwtToken);
    if (res.status) {
      ReprintNotification();
      getSkuForReprint(grnNumber, grnLineNumber, jwtToken);
      setOpenReprintConfirmationModal(false);
      setTimeout(() => {
        setReprintButtonName("Yes");
      }, 2250);
    }
    if (!res.status) {
      setIsErrorRePrint(true);
      setReprintButtonName("Yes");
    }
  };

  useEffect(() => {
    getSkuForReprint(grnNumber, grnLineNumber, jwtToken);
  }, [grnNumber, grnLineNumber]);

  const [loading2, setLoading2] = useState(true);
  useEffect(() => {
    let tempSkus = [];
    let data = skuForReprint.sku.data ? skuForReprint.sku.data : [];

    setLoading2(skuForReprint.loading);
    data.map((sku) => {
      const object = {
        privateKey: sku.grnNumber,

        sku: sku.sku,

        qty: `${sku.qty} ${sku.uom}`,

        action: (
          <Tooltip title="Reprint">
            {reprintGroupedGrn && (
              <MDButton
                // disabled={!reprintGroupedGrn}
                iconOnly
                variant="gradient"
                color="error"
                onClick={() => {
                  // handleOpenReprintModal(sku);
                  if (printerConfig === true) {
                    handleOpenReprintConfirmationModal(sku);
                  }
                  if (printerConfig === false) {
                    navigate("/printer-configurator");
                  }
                }}
              >
                <Icon> print </Icon>
              </MDButton>
            )}
          </Tooltip>
        ),
      };
      tempSkus.push(object);
    });

    setReprintTable({ ...reprintTable, rows: tempSkus });
  }, [skuForReprint]);

  const headers = [
    {
      label: "grnNumber",
      key: "grnNumber",
    },
    {
      label: "grnLineNumber",
      key: "grnLineNumber",
    },
    { label: "asnCode", key: "asnCode" },
    { label: "itemCode", key: "itemCode" },
    {
      label: "itemDescription",
      key: "itemDescription",
    },

    {
      label: "qty",
      key: "qty",
    },
    {
      label: "uom",
      key: "uom",
    },
    {
      label: "vendorCode",
      key: "vendorCode",
    },
    {
      label: "vendorName",
      key: "vendorName",
    },
    {
      label: "lotNumber",
      key: "lotNumber",
    },
    {
      label: "serialNumber",
      key: "serialNumber",
    },
  ];

  const csvLink = {
    filename: "Grn List.csv",
    headers: headers,
    data: dataForDownload,
  };

  const csvLink2 = {
    filename: "Grn Template.csv",
    headers: headers,
    data: [],
  };

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              <MDBox color="text" px={2} ml={-2} mb={1}>
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  {downloadGroupedGrn ? (
                    <CSVLink {...csvLink2}>
                      <DownloadButton
                        tooltip="Download Template"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadButton>
                    </CSVLink>
                  ) : (
                    ""
                  )}
                  {uploadGroupedGrn && (
                    <UploadButton
                      tooltip="Upload CSV"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="info"
                      iconOnly
                      onClick={uploadCsvFilterHandler}
                    >
                      <Icon>upload</Icon>
                    </UploadButton>
                  )}

                  {downloadGroupedGrn ? (
                    <CSVLink {...csvLink}>
                      <Tooltip title="Download Grn List">
                        <MDButton
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="success"
                          iconOnly
                          onClick={() => getAllGRNDataForDownload(jwtToken)}
                        >
                          <Icon>download</Icon>
                        </MDButton>
                      </Tooltip>
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
            {uploadCsvFilter && (
              <div>
                <div>
                  {/* <input
                    className="choose_file"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  /> */}
                  {/* {downloadGroupedGrn ? (
                    <CSVLink {...csvLink}>
                      <DownloadDataButton
                        tooltip="Download Grn List"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="success"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadDataButton>
                    </CSVLink>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            )}
            {uploadCsvFilter && (
              <div>
                <div>
                  <input
                    className="choose_file"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  />

                  <MDButton
                    disabled={
                      !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                    }
                    variant="contained"
                    color="info"
                    style={{
                      marginLeft: { xs: "1.5rem", lg: "1" },
                      marginTop: "0rem",
                    }}
                    type="button"
                    onClick={uploadCsvHandler}
                  >
                    UPLOAD
                  </MDButton>
                </div>
                <div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    <strong>*</strong> Please upload
                    <strong>".csv"</strong> file only
                  </span>
                </div>
              </div>
            )}

            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                hideColumns={["asnCode", "vendorCodeName"]}
                rowActiveColor={rowActiveColor}
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>

            <div>
              <Modal
                open={openGrnItemModal}
                onClose={handleOpenGrnItemModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Items
                    </MDTypography>
                    <MDBox></MDBox>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                              marginBottom: "5px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseLineItemsModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />
                  <MDBox>
                    <MDTypography variant="h5" gutterBottom marginLeft="25px">
                      GRN: {tableHeading}
                    </MDTypography>
                    <MDTypography variant="h5" gutterBottom marginLeft="25px">
                      Vendor: {vendorCodeName}
                    </MDTypography>
                    <DataTable
                      // table={{ columns: itemColumns, rows: itemRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </MDBox>
                </MDCard>
              </Modal>
            </div>

            <Modal
              open={openPrintLabelModal}
              onClose={handleClosePrintLabelModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForLabelModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Print Label
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-40px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleClosePrintLabelModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <Formik
                  initialValues={initialValue}
                  onSubmit={onSubmitForPrint}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div style={{ display: "flex" }}>
                          <MDTypography variant="h6">
                            Grn Number:{" "}
                            <span style={{ fontWeight: 400 }}>
                              {d.grnNumber}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ marginLeft: "2rem" }}
                          >
                            Item Code:{" "}
                            <span style={{ fontWeight: 400 }}>
                              {d.itemCode}
                            </span>
                          </MDTypography>
                        </div>
                        {d.lotNumber !== ("NA" || null) && (
                          <div className="form-group">
                            <MDTypography
                              variant="h5"
                              fontWeight="medium"
                              flexGrow={1}
                              mt={2}
                            >
                              Type of Printing
                            </MDTypography>
                            <div>
                              <Field
                                type="radio"
                                name="printingType"
                                value="Individual Printing"
                                className="radio"
                              />
                              <span>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  style={{
                                    marginLeft: "1.5rem",
                                    fontSize: "15px",
                                    marginTop: "-1.77rem",
                                    fontWeight: 500,
                                  }}
                                >
                                  Individual Printing
                                </MDTypography>
                              </span>
                            </div>
                            <div>
                              <Field
                                type="radio"
                                name="printingType"
                                value="Package Printing"
                                className="radio"
                              />
                              <span>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  style={{
                                    marginLeft: "1.5rem",
                                    fontSize: "15px",
                                    marginTop: "-1.77rem",
                                    fontWeight: 500,
                                  }}
                                >
                                  Package Printing
                                </MDTypography>
                              </span>
                            </div>
                          </div>
                        )}
                        {((d.lotNumber === ("NA" || null) &&
                          d.serialNumber === ("NA" || null)) ||
                          (d.lotNumber !== ("NA" || null) &&
                            d.serialNumber === ("NA" || null) &&
                            formik.values.printingType ===
                              "Package Printing")) && (
                          <div>
                            <div style={{ marginTop: "20px" }}>
                              {/* <h5>Package Printing</h5> */}
                              <div style={{ display: "flex" }}>
                                <MDTypography variant="h5">
                                  Total: {d.qty} {d.uom}
                                </MDTypography>
                                <MDTypography
                                  variant="h5"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  {formik.values.packageArray.length > 0 &&
                                    `Balance: ${balanceItemMsg}`}
                                </MDTypography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                }}
                              >
                                {formik.values.packageArray.length === 0 && (
                                  <div className="form-group">
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                      flexGrow={1}
                                    >
                                      Number of Packages
                                    </MDTypography>

                                    <Field
                                      type="text"
                                      name="noOfPackages"
                                      className="form-control"
                                      autocomplete="off"
                                      style={{
                                        width: "100px",
                                        borderRadius: 10,
                                        marginLeft: 0,
                                        height: 35,
                                        paddingLeft: "15px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              {formik.values.packageArray.length === 0 && (
                                <div>
                                  <MDButton
                                    type="button"
                                    variant="gradient"
                                    color="success"
                                    style={{
                                      marginBottom: "1rem",
                                      marginTop: "12px",
                                    }}
                                    onClick={() => {
                                      // for (
                                      //   let i = 0;
                                      //   i < formik.values.noOfPackages;
                                      //   i++
                                      // ) {
                                      //   pushPackage();
                                      // }

                                      let a = d.qty;
                                      let b = +formik.values.noOfPackages;
                                      // let a = 68;
                                      // let b = 7;

                                      let quo = +Math.floor(a / b);
                                      let rem = +a % b;

                                      formik.setFieldValue(
                                        "packageArray",
                                        rem === 0
                                          ? Array(b).fill(quo)
                                          : [
                                              ...Array(b - 1).fill(quo),
                                              ...Array(1).fill(quo + rem),
                                            ]
                                      );
                                    }}
                                  >
                                    Create Package
                                  </MDButton>
                                </div>
                              )}
                              <div>
                                <FieldArray name="packageArray">
                                  {(fieldArrayProps) => {
                                    const { push, remove, form } =
                                      fieldArrayProps;
                                    const { values } = form;
                                    const { packageArray } = values;
                                    pushPackage = push;

                                    let newPakageArray =
                                      packageArray.filter(Number);

                                    let sum = +newPakageArray.reduce(
                                      (a, b) => a + b,
                                      0
                                    );
                                    setSumOfArray(sum);
                                    return (
                                      <div>
                                        <Grid container>
                                          {packageArray.map((type, index) => (
                                            <Grid item xs={6}>
                                              <div
                                                key={index}
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <label
                                                  htmlFor="packageArray"
                                                  style={{
                                                    marginTop: "0.4rem",
                                                  }}
                                                >
                                                  <MDTypography variant="h6">
                                                    Package {index + 1}
                                                  </MDTypography>
                                                </label>
                                                <Field
                                                  name={`packageArray[${index}]`}
                                                  type="number"
                                                  style={{
                                                    width: "100px",
                                                    borderRadius: 10,
                                                    marginLeft: 0,
                                                    height: 35,
                                                    paddingLeft: "15px",
                                                    marginLeft: "2rem",
                                                    margin: "5px",
                                                  }}
                                                  autocomplete="off"
                                                  value={
                                                    formik.values.packageArray[
                                                      index
                                                    ]
                                                  }
                                                  // onChange={(e) => {
                                                  //   formik.handleChange(e);
                                                  // }}
                                                  // onBlur={() => {
                                                  //   setSumOfArray(
                                                  //     formik.values.packageArray.reduce(
                                                  //       (a, b) => a + b,
                                                  //       0
                                                  //     )
                                                  //   );
                                                  // }}
                                                />

                                                <span>
                                                  {index > 0 && (
                                                    <MDButton
                                                      variant="graidiant"
                                                      color="error"
                                                      type="button"
                                                      onClick={(e) => {
                                                        remove(index);

                                                        // setSumOfArray(
                                                        //   formik.values.packageArray.reduce(
                                                        //     (a, b) => a + b,
                                                        //     0
                                                        //   )
                                                        // );
                                                      }}
                                                      style={{
                                                        // marginLeft: "0.1rem",
                                                        marginTop: "0.4rem",
                                                      }}
                                                    >
                                                      {/* <i
                                                     className="fa fa-times"
                                                     style={{
                                                       color: "red",
                                                     }}
                                                   ></i> */}

                                                      <IconButton
                                                        style={{
                                                          background:
                                                            "whitesmoke",
                                                          color: "black",
                                                          borderRadius: 5,
                                                          // marginLeft: "-42px",
                                                        }}
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                      >
                                                        <CloseIcon fontSize="inherit" />
                                                      </IconButton>
                                                    </MDButton>
                                                  )}
                                                </span>
                                              </div>
                                            </Grid>
                                          ))}
                                          {balance !== 0 &&
                                            formik.values.packageArray.length >
                                              0 && (
                                              <MDButton
                                                type="button"
                                                variant="outlined"
                                                color="info"
                                                style={{
                                                  marginBottom: "1rem",
                                                  marginTop: "10px",
                                                }}
                                                onClick={() => {
                                                  push("");
                                                }}
                                              >
                                                Add Package
                                              </MDButton>
                                            )}
                                        </Grid>
                                      </div>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </div>
                          </div>
                        )}

                        {confirmationMsg && (
                          <MDTypography variant="h5" mt={2}>
                            {(d.serialNumber !== ("NA" || null) ||
                              formik.values.printingType ===
                                "Individual Printing") &&
                              confirmationMsg &&
                              `Do you want to print ${d.qty} individual labels?`}

                            {((d.lotNumber === ("NA" || null) &&
                              d.serialNumber === "NA") ||
                              (d.lotNumber !== ("NA" || null) &&
                                d.serialNumber === ("NA" || null) &&
                                formik.values.printingType ===
                                  "Package Printing")) &&
                              confirmationMsg &&
                              `Do you want to print ${formik.values.packageArray.length} package labels?`}
                          </MDTypography>
                        )}
                        {confirmationMsg && balance !== 0 && balance !== "" && (
                          <h6 style={{ color: "red" }}>{balanceItemMsg}</h6>
                        )}

                        <div class="clearfix">
                          {!confirmationMsg && d !== "" && (
                            <MDButton
                              style={{ marginTop: "10px" }}
                              type="button"
                              onClick={onClickForConfirmation}
                              variant="gradient"
                              color="info"
                              fullWidth
                              // color="info"
                              disabled={
                                (d.lotNumber === ("NA" || null) &&
                                  d.serialNumber === "NA") ||
                                (d.lotNumber !== ("NA" || null) &&
                                  d.serialNumber === ("NA" || null) &&
                                  formik.values.printingType ===
                                    "Package Printing")
                                  ? (balance === 0) === true
                                    ? false
                                    : true
                                  : false
                              }
                            >
                              Print Label
                            </MDButton>
                          )}
                          {confirmationMsg && (
                            <span>
                              <MDButton
                                type="button"
                                variant="outlined"
                                color="error"
                                style={{ marginTop: "10px" }}
                                onClick={() => {
                                  setOpenPrintLabelModal(false);
                                  setConfirmationMsg(false);
                                }}
                              >
                                Cancel
                              </MDButton>
                              <MDButton
                                type="submit"
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "40px",
                                }}
                                variant="gradient"
                                color="success"
                                disabled={printButtonName === "Please wait..."}
                              >
                                {printButtonName}
                              </MDButton>
                            </span>
                          )}
                        </div>
                        <div className="mt-3">
                          <Collapse in={isErrorPrintLabel}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorPrintLabel(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessPrintLabel}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessPrintLabel(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              GRN Printed Successfully
                            </Alert>
                          </Collapse>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </MDCard>
            </Modal>
            <div>
              <Modal
                open={openGrnLineItemsModal}
                onClose={handleCloseGrnLineItemsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForGrnModal}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Items
                    </MDTypography>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                              marginBottom: "5px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseGrnLineItemsModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />

                  <MDBox className="nested-table-container">
                    {!loading3 ? (
                      <DataTable
                        table={{ columns: grnColumns, rows: grnRows }}
                        isSorted={true}
                        tableSearch={true}
                        entriesPerPage={{
                          defaultValue: 5,
                          entries: [5, 10, 15, 20, 25, 30],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        nestedTable={true}
                        nestedTableData={reprintRows}
                        hideColumns={["qty", "uom", "mfgDate", "lotSerialNo"]}

                        // errorMsg={errorMsgForLineItemFromApi}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "100px",
                          marginBottom: "100px",
                        }}
                      >
                        <CircularProgress color="info" />
                      </div>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={createPicklistModal}
                onClose={handleCreateClosePicklistModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForMovement}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Movement Change
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCreateClosePicklistModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForAdd.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          style={{ fontWeight: "500" }}
                        >
                          Grn Number:-{" "}
                          <span style={{ fontWeight: 400 }}>
                            {d?.grnNumber}
                          </span>
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          style={{ fontWeight: "500" }}
                        >
                          Movement
                        </MDTypography>

                        <Select
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="mvtId"
                          // styles={customStyles}
                          onBlur={() => {
                            formikForAdd.handleBlur({
                              target: { name: "mvtId" },
                            });
                          }}
                          value={createdActivitySelected}
                          options={createdActivityOptions}
                          onChange={(selectedOption) => {
                            handleCreatedActivityChange(selectedOption);
                            // formikForAdd.handleChange("mvtId")(
                            //   selectedOption.label
                            // );
                          }}
                        />

                        {formikForAdd.touched.mvtId &&
                          formikForAdd.errors.mvtId && (
                            <TextError msg={formikForAdd.errors.mvtId} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorCreatedActivity}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorCreatedActivity(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {isErrorMsgForCreatedActivity}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessCreatedActivity}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessCreatedActivity(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Submitted successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={3}>
                        <MDButton
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={
                            createdActivitySelected == "" ||
                            picklistButtonName === "Please wait..."
                          }
                        >
                          {picklistButtonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </div>
            <Modal
              open={openReprintModal}
              onClose={handleCloseReprintModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForReprint}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    {grnNumberForReprint}
                  </MDTypography>
                  <MDBox></MDBox>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseReprintModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />
                <MDBox>
                  <DataTable
                    table={{ columns: reprintColumns, rows: reprintRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openReprintConfirmationModal}
              onClose={handleCloseReprintConfirmationModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={deleteStyle}>
                <MDTypography>Are you sure you want to reprint ?</MDTypography>
                <MDBox mt={2} display="flex">
                  <MDButton
                    style={{ width: "100%", marginRight: "0.5rem" }}
                    color="info"
                    variant="outlined"
                    onClick={handleCloseReprintConfirmationModal}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    style={{ width: "100%", marginLeft: "0.5rem" }}
                    color="success"
                    onClick={onClickRePrint}
                    disabled={reprintButtonName === "Please wait..."}
                  >
                    {reprintButtonName}
                  </MDButton>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isErrorRePrint}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorRePrint(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Could not reprint
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    allGRN: state.allGRN,

    getGrnItemsReducer: state.getGrnItemsReducer,
    getAllGRNDataForDownloadReducer: state.getAllGRNDataForDownloadReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    skuForReprint: state.skuForReprint,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAllGRN: (flag, pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken)),

    getItemsByGrn: (grnNumber, jwtToken) =>
      dispatch(getItemsByGrn(grnNumber, jwtToken)),
    getAllGRNDataForDownload: (jwtToken) =>
      dispatch(getAllGRNDataForDownload(jwtToken)),
    individualPrint: (payload, jwtToken) =>
      dispatch(individualPrint(payload, jwtToken)),
    packagePrint: (payload, jwtToken) =>
      dispatch(packagePrint(payload, jwtToken)),
    uploadCsv: (payload, jwtToken) => dispatch(uploadCsv(payload, jwtToken)),
    uploadCsvForGrn: (payload, jwtToken) =>
      dispatch(uploadCsvForGrn(payload, jwtToken)),
    getCreatedActivity: (documentType, jwtToken) =>
      dispatch(getCreatedActivity(documentType, jwtToken)),
    createPickList: (payload, jwtToken) =>
      dispatch(createPickList(payload, jwtToken)),
    getSkuForReprint: (grnNumber, grnLineNumber, jwtToken) =>
      dispatch(getSkuForReprint(grnNumber, grnLineNumber, jwtToken)),
    reprint: (payload, jwtToken) => dispatch(reprint(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(GroupedGrn);
