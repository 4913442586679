import {
  GET_ITEMMASTER_REQUEST,
  GET_ITEMMASTER_SUCCESS,
  GET_ITEMMASTER_FAILURE,
  EDIT_ITEMMASTER_REQUEST,
  EDIT_ITEMMASTER_SUCCESS,
  EDIT_ITEMMASTER_FAILURE,
  GET_BINSBYITEMCODE_REQUEST,
  GET_BINSBYITEMCODE_SUCCESS,
  GET_BINSBYITEMCODE_FAILURE,
  UPLOADCSV_REQUEST,
  UPLOADCSV_SUCCESS,
  UPLOADCSV_FAILURE,
  GET_ITEMS_DATA_FOR_DOWNLOAD_REQUEST,
  GET_ITEMS_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_ITEMS_DATA_FOR_DOWNLOAD_FAILURE,
  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAILURE,
  GET_ITEMTYPE_REQUEST,
  GET_ITEMTYPE_SUCCESS,
  GET_ITEMTYPE_FAILURE,
  GET_ITEMMASTER_WITH_PAGINATION_REQUEST,
  GET_ITEMMASTER_WITH_PAGINATION_SUCCESS,
  GET_ITEMMASTER_WITH_PAGINATION_FAILURE,
} from "./itemMasterTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getItemMasterRequest = () => {
  return {
    type: GET_ITEMMASTER_REQUEST,
  };
};

const getItemMasterSuccess = (payload) => {
  return {
    type: GET_ITEMMASTER_SUCCESS,
    payload: payload,
  };
};

const getItemMasterFailure = (error) => {
  return {
    type: GET_ITEMMASTER_FAILURE,
    payload: error,
  };
};

const getItemMaster = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemMasterRequest());
    let url = `${baseUrl}/item`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemMasterSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemMasterFailure(err.message));
      });
  };
};

const getItemMasterWithPaginationRequest = () => {
  return {
    type: GET_ITEMMASTER_WITH_PAGINATION_REQUEST,
  };
};

const getItemMasterWithPaginationSuccess = (payload) => {
  return {
    type: GET_ITEMMASTER_WITH_PAGINATION_SUCCESS,
    payload: payload,
  };
};

const getItemMasterWithPaginationFailure = (error) => {
  return {
    type: GET_ITEMMASTER_WITH_PAGINATION_FAILURE,
    payload: error,
  };
};

const getItemMasterWithPagination = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemMasterWithPaginationRequest());
    let url = `${baseUrl}/item_with_pagination?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemMasterWithPaginationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemMasterWithPaginationFailure(err.message));
      });
  };
};

const editItemMasterRequest = () => {
  return {
    type: EDIT_ITEMMASTER_REQUEST,
  };
};

const editItemMasterSuccess = (payload) => {
  return {
    type: EDIT_ITEMMASTER_SUCCESS,
    payload: payload,
  };
};

const editItemMasterFailure = (error) => {
  return {
    type: EDIT_ITEMMASTER_FAILURE,
    payload: error,
  };
};

const editItemMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editItemMasterRequest());
    let url = `${baseUrl}/item`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editItemMasterSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editItemMasterFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

const getBinsByItemCodeRequest = () => {
  return {
    type: GET_BINSBYITEMCODE_REQUEST,
  };
};

const getBinsByItemCodeSuccess = (payload) => {
  return {
    type: GET_BINSBYITEMCODE_SUCCESS,
    payload: payload,
  };
};

const getBinsByItemCodeFailure = (error) => {
  return {
    type: GET_BINSBYITEMCODE_FAILURE,
    payload: error,
  };
};

const getBinsByItemCode = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getBinsByItemCodeRequest());
    let url = `${baseUrl}/item/by_id?item_id=${id}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getBinsByItemCodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getBinsByItemCodeFailure(err.message));
      });
  };
};

const uploadCsvRequest = () => {
  return {
    type: UPLOADCSV_REQUEST,
  };
};

const uploadCsvSuccess = (payload) => {
  return {
    type: UPLOADCSV_SUCCESS,
    payload: payload,
  };
};

const uploadCsvFailure = (error) => {
  return {
    type: UPLOADCSV_FAILURE,
    payload: error,
  };
};

const uploadCsv = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvRequest());
    let url = `${baseUrl}/item/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getItemsDataForDownloadRequest = () => {
  return {
    type: GET_ITEMS_DATA_FOR_DOWNLOAD_REQUEST,
  };
};

const getItemsDataForDownloadSuccess = (payload) => {
  return {
    type: GET_ITEMS_DATA_FOR_DOWNLOAD_SUCCESS,
    payload: payload,
  };
};

const getItemsDataForDownloadFailure = (error) => {
  return {
    type: GET_ITEMS_DATA_FOR_DOWNLOAD_FAILURE,
    payload: error,
  };
};

const getItemsDataForDownload = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsDataForDownloadRequest());
    // let url = `${baseUrl}/grn`;
    let url = `${baseUrl}/item_download`;
    // let url = `${baseUrl}/v2/grn?page_number=3&rows_per_page=5&search=`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsDataForDownloadSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsDataForDownloadFailure(err.message));
      });
  };
};

const createItemRequest = () => {
  return {
    type: CREATE_ITEM_REQUEST,
  };
};

const createItemSuccess = (payload) => {
  return {
    type: CREATE_ITEM_SUCCESS,
    payload: payload,
  };
};

const createItemFailure = (error) => {
  return {
    type: CREATE_ITEM_FAILURE,
    payload: error,
  };
};

const createItem = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createItemRequest());
    let url = `${baseUrl}/item`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createItemSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createItemFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

const getItemTypesRequest = () => {
  return {
    type: GET_ITEMTYPE_REQUEST,
  };
};

const getItemTypesSuccess = (payload) => {
  return {
    type: GET_ITEMTYPE_SUCCESS,
    payload: payload,
  };
};

const getItemTypesFailure = (error) => {
  return {
    type: GET_ITEMTYPE_FAILURE,
    payload: error,
  };
};

const getItemTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemTypesRequest());
    let url = `${baseUrl}/itemtype`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemTypesFailure(err.message));
      });
  };
};

export {
  getItemMaster,
  editItemMaster,
  getBinsByItemCode,
  uploadCsv,
  getItemsDataForDownload,
  createItem,
  getItemTypes,
  getItemMasterWithPagination,
};
