export const GET_STATIONS_BY_ASSEMBLY_REQUEST =
  "GET_STATIONS_BY_ASSEMBLY_REQUEST";
export const GET_STATIONS_BY_ASSEMBLY_SUCCESS =
  "GET_STATIONS_BY_ASSEMBLY_SUCCESS";
export const GET_STATIONS_BY_ASSEMBLY_FAILURE =
  "GET_STATIONS_BY_ASSEMBLY_FAILURE";

export const CREATE_STATION_AND_ITEM_MAPPING_REQUEST =
  "CREATE_STATION_AND_ITEM_MAPPING_REQUEST";
export const CREATE_STATION_AND_ITEM_MAPPING_SUCCESS =
  "CREATE_STATION_AND_ITEM_MAPPING_SUCCESS";
export const CREATE_STATION_AND_ITEM_MAPPING_FAILURE =
  "CREATE_STATION_AND_ITEM_MAPPING_FAILURE";

export const GET_ITEMS_BY_STATION_REQUEST = "GET_ITEMS_BY_STATION_REQUEST";
export const GET_ITEMS_BY_STATION_SUCCESS = "GET_ITEMS_BY_STATION_SUCCESS";
export const GET_ITEMS_BY_STATION_FAILURE = "GET_ITEMS_BY_STATION_FAILURE";

export const DELETE_STATIONS_REQUEST = "DELETE_STATIONS_REQUEST";
export const DELETE_STATIONS_SUCCESS = "DELETE_STATIONS_SUCCESS";
export const DELETE_STATIONS_FAILURE = "DELETE_STATIONS_FAILURE";

export const UPDATE_STATIONS_REQUEST = "UPDATE_STATIONS_REQUEST";
export const UPDATE_STATIONS_SUCCESS = "UPDATE_STATIONS_SUCCESS";
export const UPDATE_STATIONS_FAILURE = "UPDATE_STATIONS_FAILURE";

export const DELETE_STATIONSFORVIEW_REQUEST = "DELETE_STATIONSFORVIEW_REQUEST";
export const DELETE_STATIONSFORVIEW_SUCCESS = "DELETE_STATIONSFORVIEW_SUCCESS";
export const DELETE_STATIONSFORVIEW_FAILURE = "DELETE_STATIONSFORVIEW_FAILURE";

export const UPLOAD_EXCEL_FOR_STATION_REQUEST =
  "UPLOAD_EXCEL_FOR_STATION_REQUEST";
export const UPLOAD_EXCEL_FOR_STATION_SUCCESS =
  "UPLOAD_EXCEL_FOR_STATION_SUCCESS";
export const UPLOAD_EXCEL_FOR_STATION_FAILURE =
  "UPLOAD_EXCEL_FOR_STATION_FAILURE";
