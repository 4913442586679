/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

//  some date function

import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CancelIcon from "@mui/icons-material/Clear";
// Functions from store
import {
  getUnplannedIssues,
  getSubModulesByRoleId,
  createUnplannedIssue,
  getDocumentType,
  getCreatedActivity,
  getWarehouses,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getAvailableQtyForTo,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  getRelationLocation,
  getItemsByLocation,
  getSuidsByLocationAndItem,
  getSuidsByDocumentNo,
  createPicklistForUnplannedIssue,
  getAccUsage,
  getCcUsage,
  getUser,
  getItemMaster,
  createMR,
  getMaterialRequest,
} from "../../../store";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import StandardButton from "components/standardized-components/StandardButton";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, lg: 750 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};
const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 380, md: 800, lg: 1400, xl: 1400 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};
const styleForCategoryModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 480, md: 700, lg: 1600, xl: 1600 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForMovement = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const MaterialRequest = ({
  getUnplannedIssues,
  getUnplannedIssueReducer,
  createUnplannedIssue,
  getUser,
  getItemMaster,
  itemMaster,
  getItemsByLocation,
  getItemsByLocationReducer,
  getDocumentType,
  getCreatedActivity,
  getCreatedActivityReducer,
  getDocumentTypeReducer,
  materialRequestReducer,
  getSuidsByLocationAndItem,
  getSuidsByLocationAndItemReducer,
  getSuidsByDocumentNo,
  getSuidsByDocumentNoReducer,
  createMR,
  getWarehouses,
  warehouses,
  createPicklistForUnplannedIssue,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevel,
  getLevel4ByLevelReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAvailableQtyForTo,
  availableQtyState,
  getMaterialRequest,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  getRelationLocation,
  level1ByLevelSrcReducer,
  level2ByLevelSrcReducer,
  level3ByLevelSrcReducer,
  level4ByLevelSrcReducer,
  getRelationLocationReducer,
  getAccTypeReducer,
  getCcTypeReducer,
  getCcUsage,
  getUserReducer,
  getAccUsage,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let userName = loginDetails?.data?.userName || "";

  const defaultUserOption = {
    value: userName,
    label: userName,
  };
  let printerConfig = cookies.get("printerConfigForWarehouse");

  let printerId = loginDetails.data.printerId;

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getUser(jwtToken);
  }, []);
  useEffect(() => {
    getAccUsage(jwtToken);
  }, []);
  useEffect(() => {
    getItemMaster(jwtToken);
  }, []);
  useEffect(() => {
    getMaterialRequest(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let CreateMaterialModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "48fa966e-2fe3-4dde-a18a-6bee5d969b48"
  );

  let createNewUnplannedIssue = CreateMaterialModule
    ? CreateMaterialModule.actionId.includes(
        "da946a45-0e02-4cc2-9554-228cb945a3cb"
      )
    : null;

  let viewUnplannedIssue = CreateMaterialModule
    ? CreateMaterialModule.actionId.includes(
        "eade3eb5-df5b-485b-91bb-bf1171d94476"
      )
    : null;

  let createPickListAccess = CreateMaterialModule
    ? CreateMaterialModule.actionId.includes(
        "77af03c8-3731-467c-b4f8-6f10a98879e7"
      )
    : null;

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [singleLineItem, setSingleLineItem] = useState({});

  const [createdActivitySelected, setCreatedActivitySelected] = useState("");

  const data = getRelationLocationReducer.locations?.data || [];
  const [accTypeSelected, setAccTypeSelected] = useState("");
  useEffect(() => {
    getCcUsage(accTypeSelected?.value, jwtToken);
  }, [accTypeSelected]);

  const [state, setState] = useState({
    columns: [
      { Header: "Transaction Code", accessor: "tranctioncode" },
      { Header: "UserName", accessor: "userName" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      // { Header: "Item Description", accessor: "itemDescription" },
      { Header: "QTY", accessor: "qty" },
      { Header: "uom", accessor: "uom" },
      { Header: "Created Date", accessor: "requesteddate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [categoryItemTable, setCategoryItemTable] = useState({
    columns: [
      { Header: "Suid", accessor: "suid", align: "left" },
      { Header: "Sku", accessor: "sku", align: "left" },
      { Header: "GRN No", accessor: "grnNumber" },
      { Header: "GRN Line No", accessor: "grnLineNumber" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo" },
      { Header: "Qty", accessor: "qty", align: "left" },
      { Header: "Location", accessor: "locationCode" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns: categoryColumns, rows: categoryRows } = categoryItemTable;

  const [categoryItems, setCategoryItems] = useState("");

  useEffect(() => {
    let tempCategoryItemsObj = [];

    let data = getSuidsByDocumentNoReducer.suidsByDocumentNo.data
      ? getSuidsByDocumentNoReducer.suidsByDocumentNo.data
      : [];
    data.map((data) => {
      const formatDate = new Date(data.cd);
      const categoryItemsObj = {
        suid: data.suid,
        sku: data.sku,
        qty: data.qty,
        uom: data.uom,

        locationCode: data.locationCode,
        grnNumber: data.grnNumber,
        grnLineNumber: data.grnLineNumber,
        lotSerialNo: `${data.lotNumber ? data.lotNumber : "NA"} / ${
          data.serialNumber ? data.serialNumber : "NA"
        }`,
        requesteddate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
      };

      tempCategoryItemsObj.push(categoryItemsObj);
    });

    setCategoryItemTable({ ...categoryItemTable, rows: tempCategoryItemsObj });
  }, [getSuidsByDocumentNoReducer]);
  const [openCategoryLineItemsModal, setOpenCategoryLineItemsModal] =
    useState(false);

  const handleOpenCategoryLineItemsModal = () => {
    setOpenCategoryLineItemsModal(true);
  };

  const handleCloseCategoryLineItemsModal = () => {
    setOpenCategoryLineItemsModal(false);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempUnplannedIssues = [];
    let data = materialRequestReducer.materialRequest
      ? materialRequestReducer.materialRequest
      : [];
    setLoading(materialRequestReducer.loading);
    data.map((to) => {
      const formatDate = new Date(to.cd);
      let des = to?.itemDescription;
      let len = desLength;
      const toObject = {
        tranctioncode: to.trCode,
        userName: to.userName,
        warehouse: to.warehouse,

        itemCodeDes: `${to.itemCode} / ${des ? des.substring(0, len) : ""}`,
        qty: to.qty,
        uom: to.uom,
        requesteddate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        suid: (
          <>
            <Tooltip title="View Suids">
              <MDButton
                // disabled={to.suids.length == 0}
                variant="gradient"
                color="info"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  handleOpenCategoryLineItemsModal(to);
                  getSuidsByDocumentNo(to.documentTrId, jwtToken);
                }}
              >
                <Icon>visibility_icon</Icon>
              </MDButton>
            </Tooltip>
            <StandardButton
              tooltip="Create Picklist"
              disabled={
                !createPickListAccess ||
                (to.isDisabled === 1 && to.status === 0) ||
                (to.isDisabled === 0 && to.status === 1)
              }
              // style={{ marginLeft: "10px" }1
              color="warning"
              variant="gradient"
              // disabled={to.isDisabled === 1}
              iconOnly
              icon="rotate_right"
              type="button"
              onClick={() => {
                handleCreatePicklistModal(to);
                getCreatedActivity(12, jwtToken);
              }}
              style={{
                // marginLeft: "5rem",
                marginLeft: to.isDisabled === 1 ? "0.5rem" : "0.5rem",
                background: to.isDisabled === 1 ? "grey" : undefined,
                color: to.isDisabled === 1 ? "white" : undefined,
                opacity: to.isDisabled === 1 ? 0.5 : undefined,
              }}
            >
              <Icon>rotate_right</Icon>
            </StandardButton>
          </>
        ),
      };
      viewUnplannedIssue && tempUnplannedIssues.push(toObject);
    });
    setState({ ...state, rows: tempUnplannedIssues });
  }, [materialRequestReducer]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];

    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];

    data.map((item) => {
      tempItemCodeOptions.push({
        label: `${item.itemCode}/${item.itemDescription}`,
        value: item.itemId,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getUserReducer.user.data ? getUserReducer.user.data : [];

    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.name,
        value: document.userId,
      });
    });
    setUserOptions(tempDocumentTypeOptions);
  }, [getUserReducer]);

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const [selectedLocationCode, setSelectedLocationCode] = useState("");

  const [suidOptions, setSuidOptions] = useState([]);

  useEffect(() => {
    let tempSuidOptions = [];
    let data = getSuidsByLocationAndItemReducer.suidsByLocationAndItem.data
      ? getSuidsByLocationAndItemReducer.suidsByLocationAndItem.data
      : [];

    data.map((suid) => {
      tempSuidOptions.push({
        label: suid.suid,
        value: suid.suid,
        sLocation: suid.locationCode,
        qty: suid.qty,
      });
    });
    setSuidOptions(tempSuidOptions);
  }, [getSuidsByLocationAndItemReducer]);

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentCode,
        documentId: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  const [accTypeOptions, setAccTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getAccTypeReducer.accType.data
      ? getAccTypeReducer.accType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.AC_Usage,
        value: document.AC_Usage,
      });
    });
    setAccTypeOptions(tempDocumentTypeOptions);
  }, [getAccTypeReducer]);

  const [ccTypeSelected, setCcTypeSelected] = useState("");

  const [ccTypeOptions, setCcTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getCcTypeReducer.ccType.data ? getCcTypeReducer.ccType.data : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.CC_Usage,
        value: document.CC_Usage,
        documentId: document.documentId,
      });
    });
    setCcTypeOptions(tempDocumentTypeOptions);
  }, [getCcTypeReducer]);

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
  };

  const [warehouseSelected, setWarehouseSelected] = useState("");

  const handleWarehouseChange = (selectedData) => {
    setWarehouseSelected(selectedData);
  };

  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
    setActivityIdSelected("");
  };
  const handleAccTypeChange = (selectedAccType) => {
    setAccTypeSelected(selectedAccType);
  };
  const handleCcTypeChange = (selectedCcType) => {
    setCcTypeSelected(selectedCcType);
  };

  const initialValues = {
    documentType: "",
    documentNumber: "",
    accType: "",
    ccType: "",

    sourceLocation: "",
    destinationLocation: "",
    sWarehouse: "",
    sZone: "",
    sSection: "",
    sRack: "",
    sLocation: "",
    itemsForUnplannedIssue: [
      {
        itemId: "",
        qty: "",
      },
    ],

    dWarehouse: "",
    dZone: "",
    dSection: "",
    dRack: "",
    dLocation: "",
  };

  const validationSchema = Yup.object({
    documentNumber: Yup.string().required("Enter the document no!"),
  });

  const UnplannedIssueSuccessNotification = () =>
    toast.success("Unplanned issue created successfully!", {
      position: "top-center",
      autoClose: 2000,
    });

  const UnplannedIssueFailureNotification = () =>
    toast.error(errorMsgForCreate, {
      position: "top-center",
      autoClose: 2000,
    });

  const [buttonName, setButtonName] = useState("Submit");

  const [createButtonName, setCreateButtonName] = useState("Submit");

  const onSubmitForCreateUnplannedIssue = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      documentNumber: values.documentNumber,
      ac_usage: accTypeSelected.value,
      cc_usage: ccTypeSelected.value,
      documentCode: documentTypeSelected.value
        ? documentTypeSelected.value
        : "",
      suids: values.itemsForUnplannedIssue,
    };

    let res = await createUnplannedIssue(payload, jwtToken);

    if (res.status) {
      // setIsSuccessCreatedUnplannedIssue(true);
      UnplannedIssueSuccessNotification();
      getUnplannedIssues(jwtToken);
      resetForm();
      setSwarehouseSelected("");
      setSzoneSelected("");
      setSsectionSelected("");
      setSrackSelected("");
      setSlocationSelected("");
      setDwarehouseSelected("");
      setDzoneSelected("");
      setDsectionSelected("");
      setDrackSelected("");
      setDlocationSelected("");
      setDocumentTypeSelected("");
      setActivityIdSelected("");
      setTimeout(() => {
        handleCreateUnplannedIssueModal();
        setButtonName("Submit");
      }, 2500);
    }

    if (!res.status) {
      // setIsErrorCreatedUnplannedIssue(true);
      setErrorMsgForCreate(res?.data?.data?.msg);
      UnplannedIssueFailureNotification();
      setButtonName("Submit");
    }
  };

  const [isSuccessCreatedUnplannedIssue, setIsSuccessCreatedUnplannedIssue] =
    useState(false);
  const [isErrorCreatedUnplannedIssue, setIsErrorCreatedUnplannedIssue] =
    useState(false);

  useEffect(() => {
    if (isErrorCreatedUnplannedIssue) {
      setTimeout(() => {
        setIsErrorCreatedUnplannedIssue(false);
      }, 3000);
    }
  }, [isErrorCreatedUnplannedIssue]);

  useEffect(() => {
    if (isSuccessCreatedUnplannedIssue) {
      setTimeout(() => {
        setIsSuccessCreatedUnplannedIssue(false);
      }, 3000);
    }
  }, [isSuccessCreatedUnplannedIssue]);

  const [createUnplannedIssueModal, setCreateUnplannedIssueModal] =
    useState(false);
  const handleCreateUnplannedIssueModal = () => {
    setItemCodeSelected("");

    setCreateUnplannedIssueModal(!createUnplannedIssueModal);
  };

  const [itemCodeSelected, setItemCodeSelected] = useState("");

  const [sWarehouseSelected, setSwarehouseSelected] = useState("");
  const [sZoneSelected, setSzoneSelected] = useState("");
  const [sSectionSelected, setSsectionSelected] = useState("");
  const [sRackSelected, setSrackSelected] = useState("");
  const [sLocationSelected, setSlocationSelected] = useState("");

  const handleSwarehouseChange = (selectedData) => {
    setSwarehouseSelected(selectedData);
    setSzoneSelected("");
    setSsectionSelected("");
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSzoneChange = (selectedRack) => {
    setSzoneSelected(selectedRack);
    setSsectionSelected("");
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSsectionChange = (selectedRack) => {
    setSsectionSelected(selectedRack);
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSrackChange = (selectedRack) => {
    setSrackSelected(selectedRack);
    setSlocationSelected("");
  };

  const handleSlocationChange = (selectedLocation) => {
    setSlocationSelected(selectedLocation);
  };

  const [dWarehouseSelected, setDwarehouseSelected] = useState("");
  const [dZoneSelected, setDzoneSelected] = useState("");
  const [dSectionSelected, setDsectionSelected] = useState("");
  const [dRackSelected, setDrackSelected] = useState("");
  const [dLocationSelected, setDlocationSelected] = useState("");

  const handleDwarehouseChange = (selectedData) => {
    setDwarehouseSelected(selectedData);
    setDzoneSelected("");
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDzoneChange = (selectedRack) => {
    setDzoneSelected(selectedRack);
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDsectionChange = (selectedRack) => {
    setDsectionSelected(selectedRack);
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDrackChange = (selectedRack) => {
    setDrackSelected(selectedRack);
    setDlocationSelected("");
  };

  const handleDlocationChange = (selectedLocation) => {
    setDlocationSelected(selectedLocation);
  };

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];

    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
        key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  // Source Options
  const [level1OptionsSrc, setLevel1OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level1ByLevelSrcReducer.level1ByLevel.data
      ? level1ByLevelSrcReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1OptionsSrc(tempLevelOptions);
  }, [level1ByLevelSrcReducer]);

  const [level2OptionsSrc, setLevel2OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level2ByLevelSrcReducer.level2ByLevel.data
      ? level2ByLevelSrcReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2OptionsSrc(tempLevelOptions);
  }, [level2ByLevelSrcReducer]);

  const [level3OptionsSrc, setLevel3OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level3ByLevelSrcReducer.level3ByLevel.data
      ? level3ByLevelSrcReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel3OptionsSrc(tempLevelOptions);
  }, [level3ByLevelSrcReducer]);

  const [isSuccessCreatedActivity, setIsSuccessCreatedActivity] =
    useState(false);
  const [isErrorCreatedActivity, setIsErrorCreatedActivity] = useState(false);

  useEffect(() => {
    if (isErrorCreatedActivity) {
      setTimeout(() => {
        setIsErrorCreatedActivity(false);
      }, 3000);
    }
  }, [isErrorCreatedActivity]);

  useEffect(() => {
    if (isSuccessCreatedActivity) {
      setTimeout(() => {
        setIsSuccessCreatedActivity(false);
      }, 3000);
    }
  }, [isSuccessCreatedActivity]);

  const [level4OptionsSrc, setLevel4OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level4ByLevelSrcReducer.level4ByLevel.data
      ? level4ByLevelSrcReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel4OptionsSrc(tempLevelOptions);
  }, [level4ByLevelSrcReducer]);

  // Delivery Options
  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const handleCreatedActivityChange = (selectedCreatedActivity) => {
    setCreatedActivitySelected(selectedCreatedActivity);
  };

  const [level2Options, setLevel2Options] = useState([]);
  const [createdActivityOptions, setCreatedActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setCreatedActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const onSubmitForAdd = async (values, { resetForm }) => {
    setCreateButtonName("Please wait...");
    let payload = {
      warehouse: warehouseSelected?.value,
      AC_Usage: accTypeSelected?.value,
      CC_Usage: ccTypeSelected?.value,
      user: defaultUserOption?.value,
      qty: values.qty,
      itemId: selectedItemCode?.value,
    };
    let res = await createMR(payload, jwtToken);

    if (res.status) {
      setIsSuccessAllocateItem(true);
      setCreateButtonName("Submit");

      getMaterialRequest(jwtToken);
      setTimeout(() => {
        handleCloseAllocateItemModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorAllocateItem(true);
      setCreateErrorMsg(res.message.data.msg);
      setCreateButtonName("Submit");
    }

    resetForm();
  };
  const initialValuesForAdd = {
    mvtId: "",
  };

  const formik = useFormik({
    initialValues: initialValuesForAdd,
    onSubmit: onSubmitForAdd,
    // validationSchema: validationSchemaForAdd,
    enableReinitialize: true,
  });

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  const warehouseMOptions = [
    { label: "NPD", value: "NPD" },
    { label: "R&D", value: "R&D" },
    { label: "PMO", value: "PMO" },
  ];

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [isSuccessAllocateItem, setIsSuccessAllocateItem] = useState(false);
  const [isErrorAllocateItem, setIsErrorAllocateItem] = useState(false);

  const [createErrorMsg, setCreateErrorMsg] = useState("");
  useEffect(() => {
    if (isSuccessAllocateItem) {
      setTimeout(() => {
        setIsSuccessAllocateItem(false);
      }, 3000);
    }
  }, [isSuccessAllocateItem]);

  useEffect(() => {
    if (isErrorAllocateItem) {
      setTimeout(() => {
        setIsErrorAllocateItem(false);
      }, 3000);
    }
  }, [isErrorAllocateItem]);
  const [createPicklistModal, setCreatePicklistModal] = useState(false);

  const handleCreatePicklistModal = () => {
    setCreatePicklistModal(true);
    // formik.setValues(initialValues);
    // formik.setTouched({});
    // formik.setErrors({});
    setActivityIdSelected("");
    // setDocumentNumberSelected("");
    setSelectedItemCode("");
    setDocumentTypeSelected("");
  };

  const handleCloseAllocateItemModal = () => {
    formik.setErrors({});
    setCreatePicklistModal(false);
    setWarehouseSelected("");
    setAccTypeSelected("");
    setCcTypeSelected("");
  };

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              mb={-6}
            >
              {createNewUnplannedIssue && (
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <MDButton
                    tooltip="Create Material Request"
                    style={{ marginRight: "1rem" }}
                    color="info"
                    // variant="contained"
                    type="button"
                    // iconOnly
                    onClick={(event) => {
                      handleCreatePicklistModal();

                      // getDocumentType(jwtToken);
                      // getCreatedActivity(16, jwtToken);
                      getWarehouses(jwtToken);
                    }}
                  >
                    Create MR
                    {/* <Icon>add</Icon> */}
                  </MDButton>
                </MDBox>
              )}
            </MDBox>

            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  hideColumns={["", "asnCode", "vendorCodeName"]}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>

            <Modal
              open={openCategoryLineItemsModal}
              onClose={handleCloseCategoryLineItemsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCategoryModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Suids
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCategoryLineItemsModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />

                <MDBox>
                  <DataTable
                    table={{ columns: categoryColumns, rows: categoryRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={createPicklistModal}
              onClose={handleCloseAllocateItemModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Request
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAllocateItemModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            Username
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="documentId"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "documentId" },
                              });
                            }}
                            value={documentTypeSelected || defaultUserOption} // Set the default user option
                            options={userOptions}
                            onChange={(selectedOption) => {
                              handleDocumentTypeChange(selectedOption);
                              // You can uncomment these lines if needed
                              // getDocumentNumber(selectedOption?.value, jwtToken);
                              // getCreatedActivity(selectedOption?.value, jwtToken);
                              // formik.setFieldValue("documentId", selectedOption);
                            }}
                          />
                          {formik.touched.documentId &&
                            formik.errors.documentId && (
                              <TextError msg={formik.errors.documentId} />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1} ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Warehouse
                          </MDTypography>

                          <Select
                            isClearable
                            // isDisabled={documentTypeSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="documentNumber"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "documentNumber" },
                              });
                            }}
                            value={warehouseSelected}
                            options={warehouseMOptions}
                            onChange={(selectedOption) => {
                              handleWarehouseChange(selectedOption);
                              //   getItemByDocumentNo(
                              //     selectedOption?.value,
                              //     jwtToken
                              //   );
                              //   formik.setFieldValue(
                              //     "documentNumber",
                              //     selectedOption
                              //   );
                            }}
                          />

                          {formik.touched.documentNumber &&
                            formik.errors.documentNumber && (
                              <TextError msg={formik.errors.documentNumber} />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 0 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Item Code/Des
                          </MDTypography>

                          <Select
                            isClearable
                            // isDisabled={documentNumberSelected ? false : true}
                            className="select-css-for-itemcode"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="itemCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "itemCode" },
                              });
                            }}
                            value={selectedItemCode}
                            options={itemCodeOptions}
                            onChange={(selectedOption) => {
                              handleItemCodeChange(selectedOption);
                            }}
                          />

                          {formik.touched.itemCode &&
                            formik.errors.itemCode && (
                              <TextError msg={formik.errors.itemCode} />
                            )}
                        </MDBox>
                      </Grid>
                      <MDBox ml={1} mb={2}>
                        <div style={{ display: "flex" }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Qty
                          </MDTypography>
                        </div>
                        <MDInput
                          type="number"
                          inputProps={{
                            min: 1,
                            height: "10px",
                          }}
                          name="qty"
                          style={{ height: "20px" }}
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.qty}
                          error={
                            formik.touched.qty && formik.errors.qty && true
                          }
                        />
                        {formik.touched.qty && formik.errors.qty && (
                          <TextError msg={formik.errors.qty} />
                        )}
                      </MDBox>

                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 0 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            ACC
                          </MDTypography>

                          <Select
                            isClearable
                            // isDisabled={documentNumberSelected ? false : true}
                            className="select-css-for-itemcode"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="itemCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "itemCode" },
                              });
                            }}
                            value={accTypeSelected}
                            options={accTypeOptions}
                            onChange={(selectedOption) => {
                              handleAccTypeChange(selectedOption);
                            }}
                          />

                          {formik.touched.itemCode &&
                            formik.errors.itemCode && (
                              <TextError msg={formik.errors.itemCode} />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            CC
                          </MDTypography>

                          <Select
                            isClearable
                            // isDisabled={documentNumberSelected ? false : true}
                            className="select-css-for-itemcode"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="itemCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "itemCode" },
                              });
                            }}
                            value={ccTypeSelected}
                            options={ccTypeOptions}
                            onChange={(selectedOption) => {
                              handleCcTypeChange(selectedOption);
                            }}
                          />

                          {formik.touched.itemCode &&
                            formik.errors.itemCode && (
                              <TextError msg={formik.errors.itemCode} />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12}>
                        <MDBox mt={1}>
                          <MDButton
                            color="info"
                            fullWidth
                            type="submit"
                            // disabled={
                            //   !formik.isValid ||
                            //   activityIdSelected == "" ||
                            //   buttonName === "Please wait..."
                            // }
                          >
                            {buttonName}
                          </MDButton>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12}>
                        <Collapse in={isErrorAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {createErrorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Created successfully
                          </Alert>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getUnplannedIssueReducer: state.getUnplannedIssueReducer,
    getItemsByLocationReducer: state.getItemsByLocationReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    getSuidsByLocationAndItemReducer: state.getSuidsByLocationAndItemReducer,
    getSuidsByDocumentNoReducer: state.getSuidsByDocumentNoReducer,
    warehouses: state.warehouses,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    availableQtyState: state.getAvailableQtyForToReducer,
    level1ByLevelSrcReducer: state.getLevel1ByLevelSrcReducer,
    level2ByLevelSrcReducer: state.getLevel2ByLevelSrcReducer,
    level3ByLevelSrcReducer: state.getLevel3ByLevelSrcReducer,
    level4ByLevelSrcReducer: state.getLevel4ByLevelSrcReducer,

    getRelationLocationReducer: state.getRelationLocationReducer,
    getAccTypeReducer: state.getAccTypeReducer,
    getCcTypeReducer: state.getCcTypeReducer,
    getUserReducer: state.getUserReducer,
    itemMaster: state.itemMaster,
    materialRequestReducer: state.materialRequestReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getUnplannedIssues: (jwtToken) => dispatch(getUnplannedIssues(jwtToken)),
    getSuidsByLocationAndItem: (itemId, locationCode, jwtToken) =>
      dispatch(getSuidsByLocationAndItem(itemId, locationCode, jwtToken)),
    getSuidsByDocumentNo: (documentNo, jwtToken) =>
      dispatch(getSuidsByDocumentNo(documentNo, jwtToken)),
    createUnplannedIssue: (payload, jwtToken) =>
      dispatch(createUnplannedIssue(payload, jwtToken)),
    createMR: (payload, jwtToken) => dispatch(createMR(payload, jwtToken)),
    createPicklistForUnplannedIssue: (payload, jwtToken) =>
      dispatch(createPicklistForUnplannedIssue(payload, jwtToken)),

    getItemsByLocation: (jwtToken) => dispatch(getItemsByLocation(jwtToken)),
    getMaterialRequest: (jwtToken) => dispatch(getMaterialRequest(jwtToken)),
    getAccUsage: (jwtToken) => dispatch(getAccUsage(jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getCcUsage: (accTypeSelected, jwtToken) =>
      dispatch(getCcUsage(accTypeSelected, jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getAvailableQtyForTo: (itemId, location, jwtToken) =>
      dispatch(getAvailableQtyForTo(itemId, location, jwtToken)),
    getLevel1ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel2ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel3ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel4ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevelSrc(warehouseId, parentId, jwtToken)),
    getUser: (jwtToken) => dispatch(getUser(jwtToken)),
    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(MaterialRequest);
