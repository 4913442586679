/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

import { getSubModulesByRoleId } from "../../store";

import Cookies from "universal-cookie";

import PendingMaterialRequest from "./pending-material-request";
import ApprovedMaterialRequest from "./approved-material-request";
import MaterialRequest from "./material-request";

const cookies = new Cookies();

const CreateMaterialRequestParent = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let CreateMaterialModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "48fa966e-2fe3-4dde-a18a-6bee5d969b48"
  );

  let PendingMaterialModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ed4020ac-2124-4c64-a776-9236bc4bde19"
  );
  let ApprovedMaterialModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "00686474-46b0-4f03-bc5b-ea5ebd4cdacc"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TabView>
              <TabPanel header="Create Material Requests">
                <MaterialRequest />
              </TabPanel>
              <TabPanel header="Approve/Pending MR Requests">
                <PendingMaterialRequest />
              </TabPanel>
              <TabPanel header="Approved MR Requests">
                <ApprovedMaterialRequest />
              </TabPanel>
            </TabView>
          </Grid>
        </Grid>
      </MDBox> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {CreateMaterialModule &&
            PendingMaterialModule &&
            ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Create Material Requests">
                  <MaterialRequest />
                </TabPanel>
                <TabPanel header="Approve/Pending Material Requests">
                  <PendingMaterialRequest />
                </TabPanel>
                <TabPanel header="Approved Material Requests">
                  <ApprovedMaterialRequest />
                </TabPanel>
              </TabView>
            ) : !CreateMaterialModule &&
              PendingMaterialModule &&
              ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Approve/Pending Material Requests">
                  <PendingMaterialRequest />
                </TabPanel>
                <TabPanel header="Approved Material Requests">
                  <ApprovedMaterialRequest />
                </TabPanel>
              </TabView>
            ) : CreateMaterialModule &&
              !PendingMaterialModule &&
              ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Create Material Requests">
                  <MaterialRequest />
                </TabPanel>
                <TabPanel header="Approved Material Requests">
                  <ApprovedMaterialRequest />
                </TabPanel>
              </TabView>
            ) : CreateMaterialModule &&
              PendingMaterialModule &&
              !ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Create Material Requests">
                  <MaterialRequest />
                </TabPanel>
                <TabPanel header="Approve/Pending Material Requests">
                  <PendingMaterialRequest />
                </TabPanel>
              </TabView>
            ) : CreateMaterialModule &&
              !PendingMaterialModule &&
              !ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Create Material Requests">
                  <MaterialRequest />
                </TabPanel>
              </TabView>
            ) : !CreateMaterialModule &&
              !PendingMaterialModule &&
              ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Approved Material Requests">
                  <ApprovedMaterialRequest />
                </TabPanel>
              </TabView>
            ) : PendingMaterialModule &&
              !CreateMaterialModule &&
              !ApprovedMaterialModule ? (
              <TabView>
                <TabPanel header="Approve/Pending Material Requests">
                  <PendingMaterialRequest />
                </TabPanel>
              </TabView>
            ) : !PendingMaterialModule &&
              !CreateMaterialModule &&
              !ApprovedMaterialModule ? (
              <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                No Material Request Access
              </h4>
            ) : null}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(CreateMaterialRequestParent);
