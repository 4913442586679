/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import {
  loginReducer,
  logoutReducer,
} from "./authentication/login/loginReducer";
import resetPasswordReducer from "./authentication/reset-password/resetPasswordReducer";
import {
  setPasswordReducer,
  setPasswordForNewUserReducer,
  setNewPasswordReducer,
} from "./authentication/set-password/setPasswordReducer";
import {
  forgotPasswordReducer,
  validateOtpForForgotPasswordReducer,
} from "./authentication/forgot-password/forgotPasswordReducer";
import profileReducer from "./profile/profileReducer";

import {
  getInventoryReducer,
  getPickupReducer,
  getWarehouseReducer,
  getYardReducer,
  getFgReducer,
  getPdiReducer,
  getRejectionReducer,
} from "./home/homeReducers";
import {
  getItemMasterReducer,
  getItemMasterWithPaginationReducer,
  editItemMasterReducer,
  getBinsByItemCodeReducer,
  uploadCsvReducer,
  getItemsDataForDownloadReducer,
  getItemTypesReducer,
} from "./item-master/itemMasterReducer";
import {
  getMappedActivityReducer,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevelReducer,
  getAcnReducer,
  getLevel1ByLevelSrcReducer,
  getLevel2ByLevelSrcReducer,
  getLevel3ByLevelSrcReducer,
  getLevel4ByLevelSrcReducer,
} from "./location-mapping/item-location-mapping/itemLocationMappingReducer";

import {
  getWarehousesReducer,
  getWarehouseByIdReducer,
  updateWarehouseReducer,
  createWarehouseReducer,
  deliveryWarehouseReducer,
  getWarehouseByTypeReducer,
} from "./warehouses/warehousesReducer";

import {
  getZonesByCategoryAndWarehouseReducer,
  getOpenCategoryZonesByWarehouseReducer,
  getAllCategoriesReducer,
  addZoneToWarehouseReducer,
  getZonesByWarehouseReducer,
  getOpenBinsByWarehouseReducer,
} from "./zones-and-categories/zoneCategoryReducer";
import {
  getZonesByWarehouseForViewLocationReducer,
  getLocationsByWarehouseReducer,
  addLocationReducer,
  getItemByLocationReducer,
  getLocationsByWarehouseAndZoneReducer,
  deleteLocationReducer,
  getRacksByZoneAndCategoryReducer,
  downloadWarehouseReducer,
  downloadZoneRackLocationReducer,
} from "./view-location/viewLocationReducer";
import {
  getAllGRNReducer,
  getLineItemsByGrnReducer,
  getAllGRNDataForDownloadReducer,
  getGrnItemsReducer,
} from "./grn/grnReducer";
import {
  individualPrintReducer,
  packagePrintReducer,
} from "./label-printing/labelPrintingReducer";
import {
  getSkuForReprintReducer,
  reprintReducer,
} from "./reprint/reprintReducer";
import {
  getSkuByItemCodeAndWarehouseCodeReducer,
  transferOrderIndividualPrintReducer,
  transferOrderPackagePrintReducer,
} from "./movement/create-transfer-order/createTransferOrderReducer";

import { getCreateMaterialReturnOrderReducer } from "./movement/create-material-return-order/createMaterialReturnOrderReducer";
import { getMaterialReturnOrderReducer } from "./movement/material-return-order/materialReturnOrderReducer";

import { printersReducer } from "./device-management/printer-onboarding/printerOnboardingReducer";
import {
  allPrintersReducer,
  printerConfigReducer,
} from "./printer-config/printerConfigReducer";
import devicesReducer from "./device-management/device-onboarding/deviceOnboardingReducer";
import {
  getDepartmentsReducer,
  createDepartmentReducer,
  updateDepartmentReducer,
  deleteDepartmentReducer,
} from "./user-management/department/departmentReducer";
import { getLoginIdsReducer } from "./user-management/login-id/loginIdReducer";
// import { getModulesReducer } from "./user-management/modules/modulesReducer";
import {
  getUsersDetailsReducer,
  createUserDetailsReducer,
  updateUserDetailsReducer,
  deleteUserDetailsReducer,
} from "./user-management/user-details/userDetailsReducer";
import {
  getAllRolesReducer,
  getUserRolesByDepartment,
} from "./user-management/user-roles/userRolesReducer";
import {
  getModulesReducer,
  getSubModulesReducer,
  getModulesForViewReducer,
  getModulesForEditReducer,
  getSubModulesForViewReducer,
  getSubModulesForCreateRoleReducer,
} from "./modules/modulesReducer";

import {
  getModulesByRoleIdReducer,
  getSubModulesByRoleIdReducer,
} from "./user-modules/userModulesReducers";

import { getRelationLocationReducer } from "./relation-location/relationLocationReducer";
import {
  getAllocatedActivityReducer,
  createActivityReducer,
  deleteActivityReducer,
  getCreatedActivityReducer,
  updateActivityReducer,
} from "./created-activity/createdActivityReducer";
import {
  getDocumentTypeReducer,
  getDocumentTypeWithPaginationReducer,
} from "./document-type/documentTypeReducer";

import {
  getDocumentNumberReducer,
  getGroupedPickListTrackingReducer,
  getItemByDocumentNoReducer,
  getItemsByPickListReducer,
  getPickListTrackingReducer,
  getUserReducer,
  releaseUserReducer,
  getAvailableQtyReducer,
} from "./picklist-tracking/picklistTrackingReducer";
import {
  getAssetsReducer,
  updateAssetReducer,
  createNewAssetReducer,
  deleteAssetReducer,
  getAssetTypesReducer,
  getUnmappedBinsReducer,
  getUnmappedPalletsReducer,
  getMappedBinsByRifdReducer,
} from "./asset-master/assetMasterReducer";

import { registeredVendorsReducer } from "./registered-vendors/registeredVendorsReducers";
import { rejectedVendorsReducer } from "./rejected-vendors/rejectedVendorsReducers";
import { statusReducer } from "./status/statusReducers";
import { otpValidateReducer, otpReducer } from "./otp/otpReducers";
import {
  vendorDetailsReducer,
  vendorRegistrationLinkReducer,
  resendVendorRegistrationLinkReducer,
  getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer,
  acceptVendorHandlerReducer,
  rejectVendorHandlerReducer,
  updateEmailReducer,
  getPreviewReducer,
  getVendorIdReducer,
} from "./vendor-details/vendorDetailsReducers";

import {
  getBinsReducer,
  getBintypesReducer,
} from "./bin-pallet-master/bin-master/binMasterReducer";
import {
  getPalletTypesReducer,
  getPalletsReducer,
} from "./bin-pallet-master/pallet-master/palletMasterReducer";
import {
  createDirectDemandReducer,
  getDirectDemandReducer,
  partNoReducer,
  plantReducer,
  packageTypeReducer,
  packageDetailsReducer,
  deliveryPlantReducer,
  unloadingPointReducer,
  packetWeightReducer,
  binsForPrintReducer,
  partNoForPlannerReducer,
  vendorsListForPlannerReducer,
  getItemsDetailsForASNReducer,
  getPurchaseOrdersForDemandReducer,
  getItemCodeByPOReducer,
} from "./direct-demand/directDemandReducer";
import {
  createProductionPlanReducer,
  deleteProductionPlanReducer,
  editRequiredQtyReducer,
  getProductionPlanReducer,
  publishProductionPlanReducer,
} from "./production-plan/productionPlanReducer";
import {
  acceptPoReducer,
  acceptedPoReducer,
  createPurchaseOrderReducer,
  deletePurchaseOrderReducer,
  editPurchaseOrderReducer,
  getReleasedPurchaseOrderReducer,
  getPurchaseOrderReducer,
  getVendorCodeReducer,
  listOfItemsReducer,
  notAcceptedPurchasedOrdersReducer,
  publishPurchaseOrderReducer,
  deliveryPlantForPOReducer,
  unloadingPointForPOReducer,
} from "./purchase-order/purchaseOrderReducer";
import {
  getCategoriesReducer,
  getCategoriesWithPaginationReducer,
} from "./category-creation/categoryCreationReducer";
import {
  getCategoryItemMappingReducer,
  getCategoryItemMappingWithPaginationReducer,
  getWarehouseByMovementReducer,
} from "./category-item-mapping/categoryItemMappingReducer";
import {
  getDocTypeUserMappingReducer,
  getUnmappedDocumentTypeReducer,
  getUnmappedUsersDetailsReducer,
} from "./doctype-user-mapping/doctypeUserMappingReducer";
import {
  getAcnReducerForCategory,
  getCategoryLocationMappingReducer,
  getLevel1ByLevelReducerForCategory,
  getLevel2ByLevelForCategoryReducer,
  getLevel3ByLevelForCategoryReducer,
  getLevel4ByLevelForCategoryReducer,
  getCategoryItemsReducer,
} from "./location-mapping/category-location-mapping/categoryLocationMappingReducer";
import {
  getAllTransferOrdersReducer,
  getSuidsReducer,
  getAvailableQtyForToReducer,
} from "./transfer-order/transferOrderReducer";

import {
  asnDetailsReducer,
  lastAsnDetailsReducer,
  binsReducer,
} from "./asn-details/asnDetailsReducers";

import {
  getFinishedGoodsReducer,
  getGroupedFinishedGoodsReducer,
  getVinByFgCodeReducer,
  getSuidsForFgReducer,
  getFgItemsReducer,
  getFgCodeDocumentReducer,
} from "./fg-transaction/fgTransactionReducer";

import {
  getSalesOrderReducer,
  approveVinReducer,
  getVinStatusByTripNoReducer,
  getAccessoriesByVinReducer,
  getFgCodeReducer,
} from "./dispatch/dispatchReducer";
import {
  rejectionReducer,
  acceptedRejectionsReducer,
  getSkuByDocumentNoforRejectionsReducer,
} from "./rejections/rejectionReducer";
import {
  quarantineReducer,
  acceptedQuarantinesReducer,
  skuBySkuStatusReducer,
  itemCodeByWarehouseReducer,
  locationReducer,
  getSkuStatusByLocationAndItemCodeReducer,
  getSkuByItemCodeReducer,
  getSkuByDocumentNoReducer,
  acnTypeReducer,
} from "./quarantine/quarantineReducer";
import {
  getWorkOrderReducer,
  getItemsForWorkOrderReducer,
} from "./work-order/workOrderReducer";
import { getFgMasterReducer } from "./fg-master/fgMasterReducer";
import {
  getStationsByAssemblyTypeReducer,
  getItemsByStationReducer,
} from "./station-and-item-mapping/stationAndItemReducer";
import {
  getAssemblyByAssemblyTypeReducer,
  getBomReducer,
  getFgCodeByAssemblyTypeReducer,
  getItemForBomReducer,
  getItemsByFgCodeReducer,
} from "./bom/bomReducer";
import { getWmsStockReducer } from "./reports/wms-stock/wmsStockReducer";
import {
  getAgeingParameterReducer,
  getMovementByDocReducer,
  getPicklistAgeingReducer,
} from "./reports/picklist-ageing/picklistAgeingReducer";
import {
  getItemStatusReducer,
  getItemTypeReducer,
} from "./reports/item-status/itemStatusReducer";
import { getWarehouseOccupancyReducer } from "./reports/warehouse-occupancy/warehouseOccupancyReducer";
import { getDocumenTypeTurnReducer } from "./reports/document-type-turn-around-time/document-typeTurnReducer";
import {
  getAgeingReportReducer,
  getLocationsByAgeingReducer,
  getLocationsReducer,
} from "./reports/ageing-report/ageingReportReducer";
import { getAsnGrnDependencyReducer } from "./reports/asn-grn-dependency/asnGrnDependencyReducers";
import { getMinMaxFailureReducer } from "./reports/min-max-failure/minMaxFailureReducers";
import {
  getDailyVendorPerformanceReducer,
  getDateRangeReducer,
  getMonthlyVendorPerformanceReducer,
} from "./reports/vendor-performance/vendorPerformanceReducers";
import {
  getFailingMinimumTop10Reducer,
  getRTVTop10Reducer,
  getRejectedTop10Reducer,
  getTOTop10Reducer,
} from "./reports/top-10/top10Reducers";
import { mappedStationsByAssemblyReducer } from "./assembly-view/assemblyViewReducer";
import {
  getLocationsForAssemblyReducer,
  getWarehousesForAssemblyReducer,
} from "./shop-warehouse/shopWarehouseReducer";
import {
  getAssemblyMasterReducer,
  getAssemblyTypesReducer,
  getUnmappedStationsReducer,
} from "./assembly-master/assemblyMasterReducer";
import {
  getStationMasterReducer,
  getStationsByAssemblyIdForBomReducer,
} from "./station-assembly-master/station-master/stationMasterReducer";
import {
  getCompletedTransactionsReducer,
  getRejectedTransactionsReducer,
  getTransactionsReducer,
} from "./transaction/transactionReducer";
import {
  getActiveCycleCountReducer,
  getCompletedCycleCountReducer,
  getCycleCountTypeReducer,
  getWarehousesByItemReducer,
} from "./cycle-count/cycleCountReducer";

import {
  vendorMasterMappingReducer,
  listOfBinTypesReducer,
} from "./supplier-portal-masters/supplierPortalMastersReducers";

import {
  getUnplannedIssueReducer,
  getItemsByLocationReducer,
  getSuidsByLocationAndItemReducer,
  getSuidsByDocumentNoReducer,
  getAccTypeReducer,
  getCcTypeReducer,
} from "./unplanned-issue/unplannedIssueReducer";

import { getConsumptionPendingReducer } from "./consumption-pending/consumptionPendingReducer";

import { getComponentsReducer } from "./user-management/components-clients/componentsClientsReducer";
import {
  getApproveMaterialRequestReducer,
  getSkuReducer,
  getSuidsForApproveMaterialRequestReducer,
  materialRequestReducer,
} from "./material-request/materialRequestReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  logoutReducer: logoutReducer,
  resetPassword: resetPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
  setNewPasswordReducer: setNewPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  validateOtpForForgotPasswordReducer: validateOtpForForgotPasswordReducer,
  profile: profileReducer,
  printersReducer: printersReducer,
  allPrintersReducer: allPrintersReducer,
  printerConfigReducer: printerConfigReducer,
  devicesReducer: devicesReducer,
  getDepartmentsReducer: getDepartmentsReducer,
  createDepartmentReducer: createDepartmentReducer,
  getStationsByAssemblyIdForBomReducer: getStationsByAssemblyIdForBomReducer,
  updateDepartmentReducer: updateDepartmentReducer,
  deleteDepartmentReducer: deleteDepartmentReducer,

  getAllRolesReducer: getAllRolesReducer,
  getUserRolesByDepartment: getUserRolesByDepartment,
  getModulesReducer: getModulesReducer,
  getSubModulesReducer: getSubModulesReducer,
  getSubModulesForViewReducer: getSubModulesForViewReducer,
  getModulesForViewReducer: getModulesForViewReducer,
  getModulesForEditReducer: getModulesForEditReducer,
  getSubModulesForCreateRoleReducer: getSubModulesForCreateRoleReducer,
  getUsersDetailsReducer: getUsersDetailsReducer,
  createUserDetailsReducer: createUserDetailsReducer,
  updateUserDetailsReducer: updateUserDetailsReducer,
  deleteUserDetailsReducer: deleteUserDetailsReducer,

  getWarehouseByTypeReducer: getWarehouseByTypeReducer,
  partNoReducer: partNoReducer,
  plantReducer: plantReducer,
  asnDetailsReducer: asnDetailsReducer,
  binsReducer: binsReducer,
  lastAsnDetailsReducer: lastAsnDetailsReducer,
  packageTypeReducer: packageTypeReducer,
  packageDetailsReducer: packageDetailsReducer,
  deliveryPlantReducer: deliveryPlantReducer,
  unloadingPointReducer: unloadingPointReducer,
  packetWeightReducer: packetWeightReducer,
  binsForPrintReducer: binsForPrintReducer,
  getLoginIdsReducer: getLoginIdsReducer,

  getInventoryHomePage: getInventoryReducer,
  getPickupListHomePage: getPickupReducer,
  warehouseOccupancyHomePage: getWarehouseReducer,
  getYardOccupancyHomePage: getYardReducer,
  getFinishedGoodsHomePage: getFgReducer,
  getPdiHomePage: getPdiReducer,
  getRejectionHomePage: getRejectionReducer,
  itemMaster: getItemMasterReducer,
  itemMasterWithPagination: getItemMasterWithPaginationReducer,
  getItemTypesReducer: getItemTypesReducer,
  binsByItemCode: getBinsByItemCodeReducer,
  uploadCsvState: uploadCsvReducer,
  getItemsDataForDownloadReducer: getItemsDataForDownloadReducer,
  editItemMaster: editItemMasterReducer,
  locationsByWarehouse: getLocationsByWarehouseReducer,
  locationsByWarehouseAndZone: getLocationsByWarehouseAndZoneReducer,
  deleteLocation: deleteLocationReducer,
  addLocationToWarehouse: addLocationReducer,
  item: getItemByLocationReducer,
  warehouses: getWarehousesReducer,
  warehouseById: getWarehouseByIdReducer,
  updateWarehouse: updateWarehouseReducer,
  deliveryWarehouse: deliveryWarehouseReducer,
  createWarehouseReducer: createWarehouseReducer,
  getMappedActivityReducer: getMappedActivityReducer,
  getUnmappedDocumentTypeReducer: getUnmappedDocumentTypeReducer,
  getUnmappedUsersDetailsReducer: getUnmappedUsersDetailsReducer,
  skuForReprint: getSkuForReprintReducer,
  reprintReducer: reprintReducer,
  skuByItemCodeAndWarehouseCode: getSkuByItemCodeAndWarehouseCodeReducer,
  transferOrders: getAllTransferOrdersReducer,
  getCreateMaterialReturnOrderReducer: getCreateMaterialReturnOrderReducer,
  getMaterialReturnOrderReducer: getMaterialReturnOrderReducer,

  zonesByCategoryAndWarehouse: getZonesByCategoryAndWarehouseReducer,
  openCategoryZones: getOpenCategoryZonesByWarehouseReducer,
  zonesByWarehouse: getZonesByWarehouseReducer,
  getRacksByZoneAndCategoryReducer: getRacksByZoneAndCategoryReducer,
  getLevel1ByLevelReducer: getLevel1ByLevelReducer,
  getLevel2ByLevelReducer: getLevel2ByLevelReducer,
  getLevel3ByLevelReducer: getLevel3ByLevelReducer,
  getLevel4ByLevelReducer: getLevel4ByLevelReducer,
  getAcnReducer: getAcnReducer,
  openBinsByWarehouse: getOpenBinsByWarehouseReducer,
  allCategories: getAllCategoriesReducer,
  addZoneToWarehouse: addZoneToWarehouseReducer,
  allGRN: getAllGRNReducer,
  getAllGRNDataForDownloadReducer: getAllGRNDataForDownloadReducer,
  lineItemsByGrn: getLineItemsByGrnReducer,
  individualLabelPrint: individualPrintReducer,
  packageLabelPrint: packagePrintReducer,
  transferOrderIndividualPrint: transferOrderIndividualPrintReducer,
  transferOrderPackagePrint: transferOrderPackagePrintReducer,

  downloadWarehouseReducer: downloadWarehouseReducer,
  downloadZoneRackLocationReducer: downloadZoneRackLocationReducer,

  getModulesByRoleIdReducer: getModulesByRoleIdReducer,
  getSubModulesByRoleIdReducer: getSubModulesByRoleIdReducer,

  getRelationLocationReducer: getRelationLocationReducer,
  getCreatedActivityReducer: getCreatedActivityReducer,
  createActivityReducer: createActivityReducer,
  deleteActivityReducer: deleteActivityReducer,
  updateActivityReducer: updateActivityReducer,

  getDocumentTypeReducer: getDocumentTypeReducer,
  getDocumentTypeWithPaginationReducer: getDocumentTypeWithPaginationReducer,
  getAllocatedActivityReducer: getAllocatedActivityReducer,

  getGrnItemsReducer: getGrnItemsReducer,
  getPickListTrackingReducer: getPickListTrackingReducer,
  getGroupedPickListTrackingReducer: getGroupedPickListTrackingReducer,
  getItemsByPickListReducer: getItemsByPickListReducer,
  getUserReducer: getUserReducer,
  releaseUserReducer: releaseUserReducer,
  getDocumentNumberReducer: getDocumentNumberReducer,
  getItemByDocumentNoReducer: getItemByDocumentNoReducer,
  getAvailableQtyReducer: getAvailableQtyReducer,
  getAssetsReducer: getAssetsReducer,
  updateAssetReducer: updateAssetReducer,
  createNewAssetReducer: createNewAssetReducer,
  deleteAssetReducer: deleteAssetReducer,
  getAssetTypesReducer: getAssetTypesReducer,

  rejectedVendorsReducer: rejectedVendorsReducer,
  registeredVendorsReducer: registeredVendorsReducer,
  statusReducer: statusReducer,
  updateEmailReducer: updateEmailReducer,
  vendorRegistrationLinkReducer: vendorRegistrationLinkReducer,
  resendVendorRegistrationLinkReducer: resendVendorRegistrationLinkReducer,
  getVendorByVendorIdReducer: getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer: getVendorsNotApprovedReducer,
  acceptVendorHandlerReducer: acceptVendorHandlerReducer,
  rejectVendorHandlerReducer: rejectVendorHandlerReducer,
  getPreviewReducer: getPreviewReducer,
  getVendorIdReducer: getVendorIdReducer,
  otpValidateReducer: otpValidateReducer,
  otpReducer: otpReducer,

  getBinsReducer: getBinsReducer,
  getBintypesReducer: getBintypesReducer,
  getPalletsReducer: getPalletsReducer,
  getPalletTypesReducer: getPalletTypesReducer,
  getUnmappedBinsReducer: getUnmappedBinsReducer,
  getUnmappedPalletsReducer: getUnmappedPalletsReducer,
  getDirectDemandReducer: getDirectDemandReducer,
  createDirectDemandReducer: createDirectDemandReducer,
  getProductionPlanReducer: getProductionPlanReducer,
  createProductionPlanReducer: createProductionPlanReducer,
  editRequiredQtyReducer: editRequiredQtyReducer,
  deleteProductionPlanReducer: deleteProductionPlanReducer,
  getPurchaseOrderReducer: getPurchaseOrderReducer,
  deliveryPlantForPOReducer: deliveryPlantForPOReducer,
  unloadingPointForPOReducer: unloadingPointForPOReducer,
  createPurchaseOrderReducer: createPurchaseOrderReducer,
  editPurchaseOrderReducer: editPurchaseOrderReducer,
  deletePurchaseOrderReducer: deletePurchaseOrderReducer,
  publishProductionPlanReducer: publishProductionPlanReducer,
  publishPurchaseOrderReducer: publishPurchaseOrderReducer,
  acceptedPoReducer: acceptedPoReducer,
  listOfItemsReducer: listOfItemsReducer,
  notAcceptedPurchasedOrdersReducer: notAcceptedPurchasedOrdersReducer,
  getVendorCodeReducer: getVendorCodeReducer,
  acceptPoReducer: acceptPoReducer,

  getReleasedPurchaseOrderReducer: getReleasedPurchaseOrderReducer,
  getCategoriesReducer: getCategoriesReducer,
  getCategoriesWithPaginationReducer: getCategoriesWithPaginationReducer,
  getCategoryItemMappingReducer: getCategoryItemMappingReducer,
  getCategoryItemMappingWithPaginationReducer:
    getCategoryItemMappingWithPaginationReducer,
  getDocTypeUserMappingReducer: getDocTypeUserMappingReducer,
  getCategoryLocationMappingReducer: getCategoryLocationMappingReducer,
  getLevel1ByLevelReducerForCategory: getLevel1ByLevelReducerForCategory,
  getLevel2ByLevelForCategoryReducer: getLevel2ByLevelForCategoryReducer,
  getLevel3ByLevelForCategoryReducer: getLevel3ByLevelForCategoryReducer,
  getLevel4ByLevelForCategoryReducer: getLevel4ByLevelForCategoryReducer,
  getLevel1ByLevelSrcReducer: getLevel1ByLevelSrcReducer,
  getLevel2ByLevelSrcReducer: getLevel2ByLevelSrcReducer,
  getLevel3ByLevelSrcReducer: getLevel3ByLevelSrcReducer,
  getLevel4ByLevelSrcReducer: getLevel4ByLevelSrcReducer,
  getAcnReducerForCategory: getAcnReducerForCategory,
  getCategoryItemsReducer: getCategoryItemsReducer,
  getSuidsReducer: getSuidsReducer,
  getAvailableQtyForToReducer: getAvailableQtyForToReducer,
  getFinishedGoodsReducer: getFinishedGoodsReducer,
  getGroupedFinishedGoodsReducer: getGroupedFinishedGoodsReducer,
  getVinByFgCodeReducer: getVinByFgCodeReducer,
  getSuidsForFgReducer: getSuidsForFgReducer,
  getFgItemsReducer: getFgItemsReducer,
  getFgCodeDocumentReducer: getFgCodeDocumentReducer,
  salesOrder: getSalesOrderReducer,
  approveVin: approveVinReducer,
  vinStatus: getVinStatusByTripNoReducer,
  accessoriesByVin: getAccessoriesByVinReducer,
  rejectionReducer: rejectionReducer,
  acceptedRejectionsReducer: acceptedRejectionsReducer,
  quarantineReducer: quarantineReducer,
  acceptedQuarantinesReducer: acceptedQuarantinesReducer,
  getFgCodeReducer: getFgCodeReducer,
  getWorkOrderReducer: getWorkOrderReducer,
  getItemsForWorkOrderReducer: getItemsForWorkOrderReducer,
  getFgMasterReducer: getFgMasterReducer,
  getStationsByAssemblyTypeReducer: getStationsByAssemblyTypeReducer,
  getItemsByStationReducer: getItemsByStationReducer,
  getBomReducer: getBomReducer,
  getItemsByFgCodeReducer: getItemsByFgCodeReducer,
  getItemForBomReducer: getItemForBomReducer,
  getWmsStockReducer: getWmsStockReducer,
  getPicklistAgeingReducer: getPicklistAgeingReducer,
  getAgeingParameterReducer: getAgeingParameterReducer,
  getItemTypeReducer: getItemTypeReducer,
  getWarehouseOccupancyReducer: getWarehouseOccupancyReducer,
  getDocumenTypeTurnReducer: getDocumenTypeTurnReducer,
  getItemStatusReducer: getItemStatusReducer,
  getAgeingReportReducer: getAgeingReportReducer,
  getLocationsByAgeingReducer: getLocationsByAgeingReducer,
  getLocationsReducer: getLocationsReducer,
  getMovementByDocReducer: getMovementByDocReducer,
  mappedStationsByAssemblyReducer: mappedStationsByAssemblyReducer,
  getLocationsForAssemblyReducer: getLocationsForAssemblyReducer,
  getWarehousesForAssemblyReducer: getWarehousesForAssemblyReducer,
  getAssemblyMasterReducer: getAssemblyMasterReducer,
  getStationMasterReducer: getStationMasterReducer,
  getAssemblyTypesReducer: getAssemblyTypesReducer,
  getTransactionsReducer: getTransactionsReducer,
  getRejectedTransactionsReducer: getRejectedTransactionsReducer,
  getCompletedTransactionsReducer: getCompletedTransactionsReducer,
  getFgCodeByAssemblyTypeReducer: getFgCodeByAssemblyTypeReducer,
  getAssemblyByAssemblyTypeReducer: getAssemblyByAssemblyTypeReducer,
  getWarehouseByMovementReducer: getWarehouseByMovementReducer,
  getMappedBinsByRifdReducer: getMappedBinsByRifdReducer,
  itemCodeByWarehouseReducer: itemCodeByWarehouseReducer,
  locationReducer: locationReducer,
  skuBySkuStatusReducer: skuBySkuStatusReducer,
  getSkuStatusByLocationAndItemCodeReducer:
    getSkuStatusByLocationAndItemCodeReducer,
  getSkuByItemCodeReducer: getSkuByItemCodeReducer,
  getSkuByDocumentNoReducer: getSkuByDocumentNoReducer,
  getSkuByDocumentNoforRejectionsReducer:
    getSkuByDocumentNoforRejectionsReducer,
  acnTypeReducer: acnTypeReducer,
  getActiveCycleCountReducer: getActiveCycleCountReducer,
  getCompletedCycleCountReducer: getCompletedCycleCountReducer,
  getCycleCountTypeReducer: getCycleCountTypeReducer,
  getWarehousesByItemReducer: getWarehousesByItemReducer,
  getUnplannedIssueReducer: getUnplannedIssueReducer,
  getItemsByLocationReducer: getItemsByLocationReducer,
  getSuidsByLocationAndItemReducer: getSuidsByLocationAndItemReducer,
  getSuidsByDocumentNoReducer: getSuidsByDocumentNoReducer,

  getUnmappedStationsReducer: getUnmappedStationsReducer,

  partNoForPlannerReducer: partNoForPlannerReducer,

  vendorsListForPlannerReducer: vendorsListForPlannerReducer,

  vendorMasterMappingReducer: vendorMasterMappingReducer,
  listOfBinTypesReducer: listOfBinTypesReducer,
  getItemsDetailsForASNReducer: getItemsDetailsForASNReducer,
  getPurchaseOrdersForDemandReducer: getPurchaseOrdersForDemandReducer,
  getItemCodeByPOReducer: getItemCodeByPOReducer,
  getAccTypeReducer: getAccTypeReducer,
  getCcTypeReducer: getCcTypeReducer,

  getConsumptionPendingReducer: getConsumptionPendingReducer,

  getComponentsReducer: getComponentsReducer,
  SkuReducer: getSkuReducer,
  materialRequestReducer: materialRequestReducer,
  getApproveMaterialRequestReducer: getApproveMaterialRequestReducer,
  getSuidsForApproveMaterialRequestReducer:
    getSuidsForApproveMaterialRequestReducer,
  getZonesByWarehouseForViewLocationReducer:
    getZonesByWarehouseForViewLocationReducer,
  getAsnGrnDependencyReducer: getAsnGrnDependencyReducer,
  getMinMaxFailureReducer: getMinMaxFailureReducer,
  getDailyVendorPerformanceReducer: getDailyVendorPerformanceReducer,
  getDateRangeReducer: getDateRangeReducer,
  getMonthlyVendorPerformanceReducer: getMonthlyVendorPerformanceReducer,

  getFailingMinimumTop10Reducer: getFailingMinimumTop10Reducer,
  getRTVTop10Reducer: getRTVTop10Reducer,
  getRejectedTop10Reducer: getRejectedTop10Reducer,
  getTOTop10Reducer: getTOTop10Reducer,
});

export default rootReducer;
