import {
  GET_DOCTYPE_USERMAPPING_REQUEST,
  GET_DOCTYPE_USERMAPPING_SUCCESS,
  GET_DOCTYPE_USERMAPPING_FAILURE,
  CREATE_DOCTYPE_USERMAPPING_REQUEST,
  CREATE_DOCTYPE_USERMAPPING_SUCCESS,
  CREATE_DOCTYPE_USERMAPPING_FAILURE,
  UPDATE_DOCTYPE_USERMAPPING_REQUEST,
  UPDATE_DOCTYPE_USERMAPPING_SUCCESS,
  UPDATE_DOCTYPE_USERMAPPING_FAILURE,
  GET_UNMAPPED_DOCUMENT_TYPE_REQUEST,
  GET_UNMAPPED_DOCUMENT_TYPE_SUCCESS,
  GET_UNMAPPED_DOCUMENT_TYPE_FAILURE,
  GET_UNMAPPED_USER_DETAILS_REQUEST,
  GET_UNMAPPED_USER_DETAILS_SUCCESS,
  GET_UNMAPPED_USER_DETAILS_FAILURE,
  DELETE_DOCTYPE_USERMAPPING_REQUEST,
  DELETE_DOCTYPE_USERMAPPING_SUCCESS,
  DELETE_DOCTYPE_USERMAPPING_FAILURE,
} from "./doctypeUserMappingTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getDocTypeUserMappingRequest = () => {
  return {
    type: GET_DOCTYPE_USERMAPPING_REQUEST,
  };
};

const getDocTypeUserMappingSuccess = (payload) => {
  return {
    type: GET_DOCTYPE_USERMAPPING_SUCCESS,
    payload: payload,
  };
};

const getDocTypeUserMappingFailure = (error) => {
  return {
    type: GET_DOCTYPE_USERMAPPING_FAILURE,
    payload: error,
  };
};

const getDocTypeUserMapping = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getDocTypeUserMappingRequest());
    let url = `${baseUrl}/doc_user_mapping/get`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDocTypeUserMappingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDocTypeUserMappingFailure(err.response));
      });
  };
};

const createDocTypeUserMappingRequest = () => {
  return {
    type: CREATE_DOCTYPE_USERMAPPING_REQUEST,
  };
};

const createDocTypeUserMappingSuccess = (payload) => {
  return {
    type: CREATE_DOCTYPE_USERMAPPING_SUCCESS,
    payload: payload,
  };
};

const createDocTypeUserMappingFaliure = (error) => {
  return {
    type: CREATE_DOCTYPE_USERMAPPING_FAILURE,
    payload: error,
  };
};

const createDocTypeUserMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createDocTypeUserMappingRequest());
    let url = `${baseUrl}/doc_user_mapping/assign`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createDocTypeUserMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createDocTypeUserMappingFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateDocTypeUserMappingRequest = () => {
  return {
    type: UPDATE_DOCTYPE_USERMAPPING_REQUEST,
  };
};

const updateDocTypeUserMappingSuccess = (payload) => {
  return {
    type: UPDATE_DOCTYPE_USERMAPPING_SUCCESS,
    payload: payload,
  };
};

const updateDocTypeUserMappingFaliure = (error) => {
  return {
    type: UPDATE_DOCTYPE_USERMAPPING_FAILURE,
    payload: error,
  };
};

const updateDocTypeUserMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateDocTypeUserMappingRequest());
    let url = `${baseUrl}/doc_user_mapping/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateDocTypeUserMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateDocTypeUserMappingFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};
const getUnmappedDocumentTypeRequest = () => {
  return {
    type: GET_UNMAPPED_DOCUMENT_TYPE_REQUEST,
  };
};

const getUnmappedDocumentTypeSuccess = (payload) => {
  return {
    type: GET_UNMAPPED_DOCUMENT_TYPE_SUCCESS,
    payload: payload,
  };
};

const getUnmappedDocumentTypeFailure = (error) => {
  return {
    type: GET_UNMAPPED_DOCUMENT_TYPE_FAILURE,
    payload: error,
  };
};

const getUnmappedDocumentType = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUnmappedDocumentTypeRequest());
    let url = `${baseUrl}/doc_user_mapping/get_not_mapped_doc`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnmappedDocumentTypeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnmappedDocumentTypeFailure(err.message));
      });
  };
};

const getUnmappedUsersDetailsRequest = () => {
  return {
    type: GET_UNMAPPED_USER_DETAILS_REQUEST,
  };
};

const getUnmappedUsersDetailsSuccess = (payload) => {
  return {
    type: GET_UNMAPPED_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};

const getUnmappedUsersDetailsFaliure = (error) => {
  return {
    type: GET_UNMAPPED_USER_DETAILS_FAILURE,
    payload: error,
  };
};

const getUnmappedUsersDetails = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUnmappedUsersDetailsRequest());
    let url = `${baseUrl}/doc_user_mapping/get_not_mapped_user`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnmappedUsersDetailsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnmappedUsersDetailsFaliure(err.message));
      });
  };
};
const deleteDocTypeUserMappingRequest = () => {
  return {
    type: DELETE_DOCTYPE_USERMAPPING_REQUEST,
  };
};

const deleteDocTypeUserMappingSuccess = (payload) => {
  return {
    type: DELETE_DOCTYPE_USERMAPPING_SUCCESS,
    payload: payload,
  };
};

const deleteDocTypeUserMappingFaliure = (error) => {
  return {
    type: DELETE_DOCTYPE_USERMAPPING_FAILURE,
    payload: error,
  };
};

const deleteDocTypeUserMapping = (documentUserMappingId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteDocTypeUserMappingRequest());
    let url = `${baseUrl}/doc_user_mapping/delete?documentUserMappingId=${documentUserMappingId}`;
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteDocTypeUserMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteDocTypeUserMappingFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getDocTypeUserMapping,
  createDocTypeUserMapping,
  updateDocTypeUserMapping,
  getUnmappedDocumentType,
  getUnmappedUsersDetails,
  deleteDocTypeUserMapping,
};
