import { useField, Field, useFormikContext } from "formik";
import TextError from "utils/TextError";
import MDTypography from "components/MDTypography";
import Config from "../../../config/index";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { Card, CardContent, CircularProgress, Icon } from "@mui/material";

const FormFileUpload = ({ label, ...props }) => {
  let baseUrl = Config.baseUrl;
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(props);
  const [filePreview, setFilePreview] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [showPreviousFileDialog, setShowPreviousFileDialog] = useState(false);

  // Sync the file state with Formik on page change
  useEffect(() => {
    if (field?.value && field.value instanceof File) {
      // Only create the preview if the file is valid
      setFilePreview(URL.createObjectURL(field.value));
      setIsPDF(field.value.type === "application/pdf");
    }
  }, [field?.value]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFieldValue(field.name, file); // Update Formik state

      // Check if the file is a PDF and create preview accordingly
      if (file.type === "application/pdf") {
        setFilePreview(URL.createObjectURL(file)); // Generate a URL for the file to preview
        setIsPDF(true);
      } else if (file.type.startsWith("image/")) {
        setFilePreview(URL.createObjectURL(file)); // For images, generate preview URL
        setIsPDF(false);
      } else {
        setFilePreview(null); // Clear preview for unsupported file types
        setIsPDF(false);
      }
    } else {
      setFilePreview(null); // If no file is selected, clear preview
      setIsPDF(false);
    }
  };

  const handlePreviewToggle = () => {
    setShowPreview(!showPreview);
  };

  // const handlePreviousFileDialogToggle = () => {
  //   setShowPreviousFileDialog(!showPreviousFileDialog);
  // };

  // const [showPreviousFileDialog, setShowPreviousFileDialog] = useState(false);

  const handlePreviousFileDialogToggle = () => {
    setShowPreviousFileDialog(!showPreviousFileDialog);
    setIsLoading(true); // Reset loading state when dialog is toggled
  };

  const [isLoading, setIsLoading] = useState(true); // Loading state
  const handleIframeLoad = () => {
    setIsLoading(false); // Set loading to false once the iframe has loaded
  };

  return (
    <>
      <MDTypography variant="h6" color="inherit">
        {label}{" "}
        {props.notRequired ? "" : <span style={{ color: "red" }}>*</span>}
      </MDTypography>
      <Field
        className="choose_file"
        type="file"
        {...field}
        {...props}
        value={undefined}
        onChange={handleFileChange}
      />
      {field?.value?.name && !props.isRejected && (
        <>
          <MDTypography color="info" variant="subtitle2">
            Selected file: {field?.value?.name.slice(0, 14)}
            {field?.value?.name.length > 14 && "..."}
          </MDTypography>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
          >
            <MDButton
              onClick={handlePreviewToggle}
              style={{ marginLeft: "19rem", marginTop: "-7rem" }}
              variant="gradient"
              color="info"
              iconOnly
              size="small"
              type="button"
            >
              <Icon>visibility_icon</Icon>
            </MDButton>
          </div>
        </>
      )}

      {/* Modal (Dialog) for Preview */}
      <Dialog
        open={showPreview}
        onClose={handlePreviewToggle}
        maxWidth="md"
        fullWidth
        sx={{
          backdropFilter: "blur(5px)",
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "#333",
            textAlign: "center",
            paddingBottom: "16px",
            borderBottom: "2px solid #eee",
          }}
        >
          File Preview
        </DialogTitle>

        <DialogContent
          sx={{ padding: 3, display: "flex", justifyContent: "center" }}
        >
          <Card
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.15)",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.02)",
              },
              width: "100%",
              maxWidth: "900px",
            }}
          >
            {isPDF ? (
              <iframe
                src={filePreview}
                title="PDF Preview"
                width="100%"
                height="600px"
                style={{
                  border: "none",
                  borderRadius: "16px",
                  boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.2)",
                  transition: "all 0.3s ease-in-out",
                }}
              />
            ) : (
              <img
                src={filePreview}
                alt="Preview"
                width="100%"
                style={{
                  borderRadius: "16px",
                  boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.2)",
                  transition: "all 0.3s ease-in-out",
                }}
              />
            )}
          </Card>
        </DialogContent>

        <DialogActions sx={{ padding: "16px", justifyContent: "center" }}>
          <MDButton
            onClick={handlePreviewToggle}
            color="primary"
            variant="contained"
            sx={{
              fontSize: "1rem",
              padding: "8px 24px",
              backgroundColor: "#007BFF",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      {field?.value?.name && props.isRejected && (
        <MDTypography mt={1} color="info" variant="subtitle2">
          Selected file: {field?.value?.name}
        </MDTypography>
      )}
      {/* Button to view previous file */}
      {props.isRejected && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <MDButton
              onClick={handlePreviousFileDialogToggle}
              variant="outlined"
              color="info"
              size="small"
            >
              View Previous File
            </MDButton>
          </div>

          <Dialog
            open={showPreviousFileDialog}
            onClose={handlePreviousFileDialogToggle}
            maxWidth="md"
            fullWidth
            sx={{
              backdropFilter: "blur(5px)",
            }}
          >
            <DialogTitle
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#333",
                textAlign: "center",
                paddingBottom: "16px",
                borderBottom: "2px solid #eee",
              }}
            >
              Previous File
            </DialogTitle>

            <DialogContent
              sx={{ padding: 3, display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={{
                  borderRadius: "16px",
                  overflow: "hidden",
                  boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.15)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                  width: "100%",
                  maxWidth: "900px",
                }}
              >
                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                <iframe
                  src={`${baseUrl}/vendor/get_document?email=${props.email}&file_name=${field?.value?.name}`}
                  title="Previous File"
                  width="100%"
                  height="900px"
                  style={{
                    border: "none",
                    borderRadius: "16px",
                    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.2)",
                    transition: "all 0.3s ease-in-out",
                    position: "relative",
                  }}
                  onLoad={handleIframeLoad} // Handle iframe load event
                />
              </Card>
            </DialogContent>

            <DialogActions sx={{ padding: "16px", justifyContent: "center" }}>
              <MDButton
                onClick={handlePreviousFileDialogToggle}
                color="primary"
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  padding: "8px 24px",
                  backgroundColor: "#007BFF",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}
              >
                Close
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      )}

      {meta.touched && meta.error && <TextError msg={meta.error} />}
    </>
  );
};

export default FormFileUpload;
