/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getDocumentTypeWithPagination,
  createDocumentType,
  updateActivity,
  deleteActivity,
  getSubModulesByRoleId,
} from "../../../store";

// Date fns
import { addMinutes, format } from "date-fns";

// Cookies
import Cookies from "universal-cookie";
import { Backdrop, CircularProgress } from "@mui/material";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const DocumentType = ({
  getDocumentTypeWithPagination,
  getDocumentTypeWithPaginationReducer,
  createDocumentType,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let documentTypeModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "607f5b9d-575e-40cf-a59f-c7648a08f2de"
  );

  let viewAccess = documentTypeModule
    ? documentTypeModule.actionId.includes(
        "8368d783-388f-4a5d-90ed-6ebbb4cb6a10"
      )
    : null;
  let createDocument = documentTypeModule
    ? documentTypeModule.actionId.includes(
        "fa523fe6-a329-4825-b936-83c1b99bff4c"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "Document Code", accessor: "documentCode", align: "left" },
      { Header: "Document Name", accessor: "documentName", align: "left" },
      {
        Header: "Created Date",
        accessor: "mfgDate",
        accessor: "mfgDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "do MMMM yyyy"
              )
            : "";
        },
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  useEffect(() => {
    viewAccess &&
      getDocumentTypeWithPagination(
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
  }, [viewAccess, pageNo, rowsPerPage, searchObject]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempDocumentType = [];
    let data = getDocumentTypeWithPaginationReducer.documentType.data
      ? getDocumentTypeWithPaginationReducer.documentType.data
      : [];
    setPaginationObj(
      getDocumentTypeWithPaginationReducer?.documentType?.pagination
    );
    setLoading(getDocumentTypeWithPaginationReducer.loading);
    data.map((document) => {
      // const formatDate = new Date(document.cd);
      const documentTypeObject = {
        documentCode: document.documentCode,
        documentName: document.documentName,
        mfgDate: document.cd,
      };
      tempDocumentType.push(documentTypeObject);
    });
    setState({ ...state, rows: tempDocumentType });
  }, [getDocumentTypeWithPaginationReducer]);

  const [createDocumentModal, setCreateDocumentModal] = useState(false);

  const handleCreateDocumentModal = () => {
    setCreateDocumentModal(true);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
  };
  const handleCreateCloseDocumentModal = () => {
    setCreateDocumentModal(false);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
  };

  const initialValues = {
    documentCode: "",
    documentName: "",
  };

  const validationSchema = Yup.object({
    documentCode: Yup.string().required("Enter Document Code"),
    documentName: Yup.string().required("Enter a Document Name"),
  });
  const [isErrorMsgCreateDocument, setIsErrorMsgCreateDocument] = useState("");

  const [buttonName, setButtonName] = useState("Submit");

  const onSubmit = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      documentCode: values.documentCode.trim(),
      documentName: values.documentName.trim(),
    };

    let res = await createDocumentType(payload);

    if (res.status) {
      setIsSuccessCreateDocument(true);
      getDocumentTypeWithPagination(
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setTimeout(() => {
        setButtonName("Submit");
        handleCreateCloseDocumentModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorCreateDocument(true);
      setIsErrorMsgCreateDocument(res?.data?.data?.msg);
      setButtonName("Submit");
    }

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [isSuccessCreateDocument, setIsSuccessCreateDocument] = useState(false);
  const [isErrorCreateDocument, setIsErrorCreateDocument] = useState(false);

  useEffect(() => {
    if (isErrorCreateDocument) {
      setTimeout(() => {
        setIsErrorCreateDocument(false);
      }, 3000);
    }
  }, [isErrorCreateDocument]);

  useEffect(() => {
    if (isSuccessCreateDocument) {
      setTimeout(() => {
        setIsSuccessCreateDocument(false);
      }, 3000);
    }
  }, [isSuccessCreateDocument]);

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mt={-2}
            >
              <MDBox
                color="text"
                px={{ xs: 0.5, sm: 0.5, md: 2, lg: 2, xl: 2 }}
                style={{ marginLeft: "-15px" }}
              >
                {createDocument && (
                  <CreateButton
                    tooltip="Create Document Type"
                    // disabled={!createDocument}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => handleCreateDocumentModal()}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}
              </MDBox>
            </MDBox>
            <MDBox mt={-3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>
            <Modal
              open={createDocumentModal}
              onClose={handleCreateCloseDocumentModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Document
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCreateCloseDocumentModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6">Document Code</MDTypography>
                      <MDInput
                        type="text"
                        name="documentCode"
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.documentCode}
                        error={
                          formik.touched.documentCode &&
                          formik.errors.documentCode &&
                          true
                        }
                        inputProps={{
                          style: {
                            height: "10px",
                          },
                          maxLength: 45,
                        }}
                      />
                      {formik.touched.documentCode &&
                        formik.errors.documentCode && (
                          <TextError msg={formik.errors.documentCode} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6">Document Name</MDTypography>
                      <MDInput
                        type="text"
                        name="documentName"
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.documentName}
                        error={
                          formik.touched.documentName &&
                          formik.errors.documentName &&
                          true
                        }
                        inputProps={{
                          style: {
                            height: "10px",
                          },
                          maxLength: 45,
                        }}
                      />
                      {formik.touched.documentName &&
                        formik.errors.documentName && (
                          <TextError msg={formik.errors.documentName} />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorCreateDocument}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorCreateDocument(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgCreateDocument}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessCreateDocument}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessCreateDocument(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Document created successfully
                        </Alert>
                      </Collapse>
                    </MDBox>

                    <MDBox mt={3}>
                      <MDButton
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={
                          !formik.isValid || buttonName === "Please wait..."
                        }
                      >
                        {buttonName}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </MDBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getDocumentTypeWithPaginationReducer:
      state.getDocumentTypeWithPaginationReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getDocumentTypeWithPagination: (
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getDocumentTypeWithPagination(
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    createDocumentType: (payload, jwtToken) =>
      dispatch(createDocumentType(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentType);
