import {
  GET_DOCTYPE_USERMAPPING_REQUEST,
  GET_DOCTYPE_USERMAPPING_SUCCESS,
  GET_DOCTYPE_USERMAPPING_FAILURE,
  GET_UNMAPPED_DOCUMENT_TYPE_REQUEST,
  GET_UNMAPPED_DOCUMENT_TYPE_SUCCESS,
  GET_UNMAPPED_DOCUMENT_TYPE_FAILURE,
  GET_UNMAPPED_USER_DETAILS_REQUEST,
  GET_UNMAPPED_USER_DETAILS_SUCCESS,
  GET_UNMAPPED_USER_DETAILS_FAILURE,
} from "./doctypeUserMappingTypes";

const initialState = {
  loading: false,
  docTypeUserMapping: [],
  error: "",
};

const getDocTypeUserMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCTYPE_USERMAPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCTYPE_USERMAPPING_SUCCESS:
      return {
        loading: false,
        docTypeUserMapping: action.payload,
        error: "",
      };
    case GET_DOCTYPE_USERMAPPING_FAILURE:
      return {
        loading: false,
        docTypeUserMapping: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForUnmappedDocumentType = {
  loading: false,
  documentType: [],
  error: "",
};

const getUnmappedDocumentTypeReducer = (
  state = initialStateForUnmappedDocumentType,
  action
) => {
  switch (action.type) {
    case GET_UNMAPPED_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_UNMAPPED_DOCUMENT_TYPE_SUCCESS:
      return {
        loading: false,
        documentType: action.payload,
        error: "",
      };
    case GET_UNMAPPED_DOCUMENT_TYPE_FAILURE:
      return {
        loading: false,
        documentType: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUnmappedUserDetails = {
  loading: false,
  userDetails: {},
  error: "",
};

const getUnmappedUsersDetailsReducer = (
  state = initialStateForUnmappedUserDetails,
  action
) => {
  switch (action.type) {
    case GET_UNMAPPED_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_UNMAPPED_USER_DETAILS_SUCCESS:
      return {
        loading: false,
        userDetails: action.payload,
        error: "",
      };
    case GET_UNMAPPED_USER_DETAILS_FAILURE:
      return {
        loading: false,
        userDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getDocTypeUserMappingReducer,
  getUnmappedDocumentTypeReducer,
  getUnmappedUsersDetailsReducer,
};
