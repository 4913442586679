
export const CREATE_MATERIALREQUEST_REQUEST = "CREATE_MATERIALREQUEST_REQUEST";
export const CREATE_MATERIALREQUEST_SUCCESS = "CREATE_MATERIALREQUEST_SUCCESS";
export const CREATE_MATERIALREQUEST_FAILURE = "CREATE_MATERIALREQUEST_FAILURE";

export const GET_MATERIALREQUEST_REQUEST = "GET_MATERIALREQUEST_REQUEST";
export const GET_MATERIALREQUEST_SUCCESS = "GET_MATERIALREQUEST_SUCCESS";
export const GET_MATERIALREQUEST_FAILURE = "GET_MATERIALREQUEST_FAILURE";


export const CREATE_DISAPPROVAL_REQUEST = "CREATE_DISAPPROVAL_REQUEST";
export const CREATE_DISAPPROVAL_SUCCESS = "CREATE_DISAPPROVAL_SUCCESS";
export const CREATE_DISAPPROVAL_FAILURE = "CREATE_DISAPPROVAL_FAILURE";

export const POST_SKU_REQUEST = "POST_SKU_REQUEST";
export const POST_SKU_SUCCESS = "POST_SKU_SUCCESS";
export const POST_SKU_FAILURE = "POST_SKU_FAILURE";

export const POST_PENDING_REQUEST = "POST_PENDING_REQUEST";
export const POST_PENDING_SUCCESS = "POST_PENDING_SUCCESS";
export const POST_PENDING_FAILURE = "POST_PENDING_FAILURE";

export const GET_MATERIALREQUESTUSERS_REQUEST = "GET_MATERIALREQUESTUSERS_REQUEST";
export const GET_MATERIALREQUESTUSERS_SUCCESS = "GET_MATERIALREQUESTUSERS_SUCCESS";
export const GET_MATERIALREQUESTUSERS_FAILURE = "GET_MATERIALREQUESTUSERS_FAILURE";

export const GET_MATERIALREQUEST_BY_DOC_REQUEST = "GET_MATERIALREQUEST_BY_DOC_REQUEST";
export const GET_MATERIALREQUEST_BY_DOC_SUCCESS = "GET_MATERIALREQUEST_BY_DOC_SUCCESS";
export const GET_MATERIALREQUEST_BY_DOC_FAILURE = "GET_MATERIALREQUEST_BY_DOC_FAILURE";

export const GET_MATERIALREQUEST_CCUSAGE_REQUEST = "GET_MATERIALREQUEST_CCUSAGE_REQUEST";
export const GET_MATERIALREQUEST_CCUSAGE_SUCCESS = "GET_MATERIALREQUEST_CCUSAGE_SUCCESS";
export const GET_MATERIALREQUEST_CCUSAGE_FAILURE = "GET_MATERIALREQUEST_CCUSAGE_FAILURE";

export const GET_MATERIALREQUEST_ACUSAGE_REQUEST = "GET_MATERIALREQUEST_ACUSAGE_REQUEST";
export const GET_MATERIALREQUEST_ACUSAGE_SUCCESS = "GET_MATERIALREQUEST_ACUSAGE_SUCCESS";
export const GET_MATERIALREQUEST_ACUSAGE_FAILURE = "GET_MATERIALREQUEST_ACUSAGE_FAILURE";




export const GET_CREATEMR_REQUEST = "GET_CREATEMR_REQUEST";
export const GET_CREATEMR_SUCCESS = "GET_CREATEMR_SUCCESS";
export const GET_CREATEMR_FAILURE = "GET_CREATEMR_FAILURE";

export const CREATE_MR_REQUEST = "CREATE_MR_REQUEST";
export const CREATE_MR_SUCCESS = "CREATE_MR_SUCCESS";
export const CREATE_MR_FAILURE = "CREATE_MR_FAILURE";

export const CREATE_PICKLIST_FORAPPROVED_REQUEST = "CREATE_PICKLIST_FORAPPROVED_REQUEST";
export const CREATE_PICKLIST_FORAPPROVED_SUCCESS = "CREATE_PICKLIST_FORAPPROVED_SUCCESS";
export const CREATE_PICKLIST_FORAPPROVED_FAILURE = "CREATE_PICKLIST_FORAPPROVED_FAILURE";

export const EDIT_QTY_FOR_CREATE_MR_REQUEST = "EDIT_QTY_FOR_CREATE_MR_REQUEST";
export const EDIT_QTY_FOR_CREATE_MR_SUCCESS = "EDIT_QTY_FOR_CREATE_MR_SUCCESS";
export const EDIT_QTY_FOR_CREATE_MR_FAILURE = "EDIT_QTY_FOR_CREATE_MR_FAILURE";


export const GET_APPROVED_REQUESTS_REQUEST = "GET_APPROVED_REQUESTS_REQUEST";
export const GET_APPROVED_REQUESTS_SUCCESS = "GET_APPROVED_REQUESTS_SUCCESS";
export const GET_APPROVED_REQUESTS_FAILURE = "GET_APPROVED_REQUESTS_FAILURE";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";

export const GET_DOWNLOAD_APPROVED_REQUEST = "GET_DOWNLOAD_APPROVED_REQUEST";
export const GET_DOWNLOAD_APPROVED_SUCCESS = "GET_DOWNLOAD_APPROVED_SUCCESS";
export const GET_DOWNLOAD_APPROVED_FAILURE = "GET_DOWNLOAD_APPROVED_FAILURE";

export const GET_APPROVED_MATERIAL_REQUEST = "GET_APPROVED_MATERIAL_REQUEST";
export const GET_APPROVED_MATERIAL_SUCCESS = "GET_APPROVED_MATERIAL_SUCCESS";
export const GET_APPROVED_MATERIAL_FAILURE = "GET_APPROVED_MATERIAL_FAILURE";


