/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Clear";

// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Functions from store
import {
  getGroupedFinishedGoods,
  getSubModulesByRoleId,
  getVinByFgCode,
  createTransferOrder,
  getItemMaster,
  getDocumentType,
  getCreatedActivity,
  getWarehouses,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  createTransferOrderForQty,
  getAvailableQtyForTo,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  getSuidsForFg,
  getItemsForFg,
} from "../../../store";

//  some date function
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";
import { Backdrop, CircularProgress } from "@mui/material";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 900 },
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const GroupedFinishedGoods = ({
  getGroupedFinishedGoods,
  getGroupedFinishedGoodsReducer,
  getVinByFgCode,
  getVinByFgCodeReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let fgModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "47c917e9-f28a-4b44-abbd-82d3052fd24c"
  );

  let viewFG = fgModule
    ? fgModule.actionId.includes("1e96b691-7a46-4d40-b2a7-b94e927ab907")
    : null;
  let viewVins = fgModule
    ? fgModule.actionId.includes("fa07d7fb-0b7d-47ef-a6f6-e79bbee05156")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "FG Code", accessor: "fgCode" },
      {
        Header: "Date & Time",
        accessor: "dateTime",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      // { Header: "FG Description", accessor: "fgDescription" },
      { Header: "Qty", accessor: "qty" },
      { Header: "UOM", accessor: "uom" },
      { Header: "Status", accessor: "status" },
      { Header: "VIN", accessor: "vin" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [vinTable, setVinTable] = useState({
    columns: [
      { Header: "VIN", accessor: "vin" },
      { Header: "Serial No", accessor: "serialNo" },
      { Header: "Created Date", accessor: "createdDate" },

      { Header: "Status", accessor: "status" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = vinTable;

  useEffect(() => {
    getGroupedFinishedGoods(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getGroupedFinishedGoodsReducer.groupedFinishedGoods.data
      ? getGroupedFinishedGoodsReducer.groupedFinishedGoods.data
      : [];
    setPaginationObj(
      getGroupedFinishedGoodsReducer.groupedFinishedGoods?.pagination
    );
    setLoading(getGroupedFinishedGoodsReducer.loading);
    data?.map((finishedGoods) => {
      let des = finishedGoods?.description;
      let len = desLength;
      const time = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        fgCode: finishedGoods.fgCode,
        dateTime: finishedGoods.cd,
        fgDescription: des ? des.substring(0, len) : "",
        qty: `${finishedGoods.qty}`,
        uom: `${finishedGoods.uom}`,

        status: (
          <button
            className={
              (finishedGoods.overallStatus === 0 && `status-error`) ||
              (finishedGoods.overallStatus === 1 && `status-created`) ||
              (finishedGoods.overallStatus === 2 && `status-info`) ||
              (finishedGoods.overallStatus === 3 && `status-warning`) ||
              (finishedGoods.overallStatus === 4 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(finishedGoods.overallStatus === 0 && `In Progess`) ||
              (finishedGoods.overallStatus === 1 && `Created`) ||
              (finishedGoods.overallStatus === 2 && `Picking`) ||
              (finishedGoods.overallStatus === 3 && `Picked`) ||
              (finishedGoods.overallStatus === 4 && `Delivered`)}
          </button>
        ),
        vin: (
          <Tooltip title="View">
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => {
                setFgCode(finishedGoods.fgCode);
                getVinByFgCode(finishedGoods.fgCode, jwtToken);
                handleOpenVinModal();
              }}
              style={{ marginRight: "5px" }}
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewFG && setState({ ...state, rows: tempFinishedGoods });
  }, [getGroupedFinishedGoodsReducer]);

  const [vinModal, setOpenVinModal] = useState(false);

  const handleOpenVinModal = () => {
    setTimeout(() => {
      setOpenVinModal(true);
    }, 200);
  };
  const handleCloseVinModal = () => {
    setOpenVinModal(false);
  };

  const [fgCode, setFgCode] = useState("");

  const [loading2, setLoading2] = useState(true);
  useEffect(() => {
    let tempVin = [];
    let data = getVinByFgCodeReducer.vin ? getVinByFgCodeReducer.vin : [];
    setLoading2(getVinByFgCodeReducer.loading);
    data.map((vin) => {
      const formatDate = new Date(vin.cd);

      const vinInfo = {
        vin: vin.vin,
        serialNo: vin.sNo,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        status: (
          <button
            className={
              (vin.status === 0 && `status-created`) ||
              (vin.status === 1 && `status-error`) ||
              (vin.status === 3 && `status-success`) ||
              (vin.status === 2 && `status-warning`)
            }
            style={{ width: "5.5rem" }}
          >
            {(vin.status === 0 && `Created`) ||
              (vin.status === 1 && `Not Picked`) ||
              (vin.status === 2 && `Picked`) ||
              (vin.status === 3 && `Delivered`)}
          </button>
        ),
      };
      tempVin.push(vinInfo);
    });
    viewVins && setVinTable({ ...vinTable, rows: tempVin });
  }, [getVinByFgCodeReducer]);

  return (
    <>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              {/* <MDBox color="text" px={2} ml={-2} mb={1}>
                  <Tooltip title="Create Picklist">
                    <MDButton
                      style={{ marginRight: "1rem" }}
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                      onClick={(event) => handleCreateTransferOrderModal()}
                    >
                      <Icon>add</Icon>
                    </MDButton>
                  </Tooltip>
                </MDBox> */}
            </MDBox>
            <MDBox pt={0}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>
            <Modal
              open={vinModal}
              onClose={handleCloseVinModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    {fgCode}
                  </MDTypography>
                  <MDBox></MDBox>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseVinModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />
                <MDBox>
                  {!loading2 ? (
                    <DataTable
                      table={{ columns: itemColumns, rows: itemRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getGroupedFinishedGoodsReducer: state.getGroupedFinishedGoodsReducer,
    getVinByFgCodeReducer: state.getVinByFgCodeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getFgItemsReducer: state.getFgItemsReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    getSuidsForFgReducer: state.getSuidsForFgReducer,
    warehouses: state.warehouses,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    availableQtyState: state.getAvailableQtyForToReducer,
    level1ByLevelSrcReducer: state.getLevel1ByLevelSrcReducer,
    level2ByLevelSrcReducer: state.getLevel2ByLevelSrcReducer,
    level3ByLevelSrcReducer: state.getLevel3ByLevelSrcReducer,
    level4ByLevelSrcReducer: state.getLevel4ByLevelSrcReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getGroupedFinishedGoods: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getGroupedFinishedGoods(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getVinByFgCode: (fgCode, jwtToken) =>
      dispatch(getVinByFgCode(fgCode, jwtToken)),
    getSuidsForFg: (itemId, jwtToken) =>
      dispatch(getSuidsForFg(itemId, jwtToken)),
    createTransferOrder: (payload, jwtToken) =>
      dispatch(createTransferOrder(payload, jwtToken)),
    createTransferOrderForQty: (payload, jwtToken) =>
      dispatch(createTransferOrderForQty(payload, jwtToken)),
    getItemsForFg: (jwtToken) => dispatch(getItemsForFg(jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getAvailableQtyForTo: (itemId, location, jwtToken) =>
      dispatch(getAvailableQtyForTo(itemId, location, jwtToken)),
    getLevel1ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel2ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel3ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel4ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevelSrc(warehouseId, parentId, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupedFinishedGoods);
