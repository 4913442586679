/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
//date -fns
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  acceptPo,
  getReleasedPurchaseOrder,
  publishPo,
} from "../../store";

// React-Select component and styling

import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
import { Backdrop, CircularProgress } from "@mui/material";
import ReleaseButton from "components/standardized-components/ReleaseButton";

const cookies = new Cookies();

const acceptStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const AcceptPo = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  acceptPo,
  getReleasedPurchaseOrder,
  getReleasedPurchaseOrderReducer,
  publishPo,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let acceptModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "a4939e7a-10e3-415b-b07d-326cc89d564d"
  );
  let viewAcceptedPO = acceptModule
    ? acceptModule.actionId.includes("7e7d429e-f4fd-4c87-9227-c96c5a780dbb")
    : null;
  let acceptPO = acceptModule
    ? acceptModule.actionId.includes("5112d77f-312b-4a4d-9964-ba4764f1b39e")
    : null;
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  useEffect(() => {
    getReleasedPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    columns: [
      { Header: "PO No.", accessor: "purchaseOrderNumber" },
      { Header: "PO LineNo.", accessor: "purchaseOrderLineNumber" },
      { Header: "Vendor Code", accessor: "vendorCode" },
      { Header: "Item code", accessor: "itemCode" },
      { Header: "Po Date", accessor: "date" },
      { Header: "Qty", accessor: "qty" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [isErrorAccept, setIsErrorAccept] = useState(false);
  const [isSuccessAccept, setIsSuccessAccept] = useState(false);

  useEffect(() => {
    if (isErrorAccept) {
      setTimeout(() => {
        setIsErrorAccept(false);
      }, 3000);
    }
  }, [isErrorAccept]);

  useEffect(() => {
    if (isSuccessAccept) {
      setTimeout(() => {
        setIsSuccessAccept(false);
      }, 3000);
    }
  }, [isSuccessAccept]);

  const [singleAcceptData, setSingleAcceptData] = useState({});

  const [openAcceptModal, setOpenAcceptModal] = useState(false);

  const handleOpenAcceptModal = (asset = {}) => {
    setSingleAcceptData(asset);
    setOpenAcceptModal(true);
  };
  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };

  const [isErrorPublish, setIsErrorPublish] = useState(false);
  const [isSuccessPublish, setIsSuccessPublish] = useState(false);

  useEffect(() => {
    if (isErrorPublish) {
      setTimeout(() => {
        setIsErrorPublish(false);
      }, 3000);
    }
  }, [isErrorPublish]);

  useEffect(() => {
    if (isSuccessPublish) {
      setTimeout(() => {
        setIsSuccessPublish(false);
      }, 3000);
    }
  }, [isSuccessPublish]);

  const [singlePublishData, setSinglePublishData] = useState({});

  const [openPublishModal, setOpenPublishModal] = useState(false);

  const handleOpenPublishModal = (asset = {}) => {
    setSinglePublishData(asset);
    setOpenPublishModal(true);
  };
  const handleClosePublishModal = () => {
    setOpenPublishModal(false);
  };

  const publishData = async () => {
    let payload = [
      {
        purchaseOrderId: singlePublishData.purchaseOrderId,
      },
    ];

    let response = await publishPo(payload, jwtToken);

    if (response.status) {
      setIsSuccessPublish(true);
      getReleasedPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleClosePublishModal();
      }, 2250);
    }
    if (!response.status) {
      setIsErrorAccept(true);
      setIsErrorMsgForPublish(response?.data?.data?.msg);
    }
  };

  useEffect(() => {
    let tempAsset = [];
    let data = getReleasedPurchaseOrderReducer.acceptedOrders.data
      ? getReleasedPurchaseOrderReducer.acceptedOrders.data
      : [];
    setPaginationObj(
      getReleasedPurchaseOrderReducer.acceptedOrders?.pagination
    );
    setLoading(getReleasedPurchaseOrderReducer.loading);

    data.map((purchase) => {
      const formatDate = new Date(purchase.date);
      const purchaseObject = {
        privateKey: purchase.purchaseOrderId,
        purchaseOrderNumber: purchase.purchaseOrderNumber,
        purchaseOrderLineNumber: purchase.purchaseOrderLineNumber,
        vendorCode: purchase.vendorCode,
        itemCode: purchase.itemCode,
        purchaseOrderId: purchase.purchaseOrderId,
        date: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: purchase.qty,
        // isEditable: purchase.isEditable,
        action: (
          <div>
            {acceptPO && (
              <Tooltip title="Accept">
                <ReleaseButton
                  disabled={purchase.isAccepted === 1}
                  variant="gradient"
                  color="info"
                  iconOnly
                  type="button"
                  onClick={() => handleOpenAcceptModal(purchase)}
                  // style={{ marginRight: "5px" }}
                >
                  <Icon>forward</Icon>
                </ReleaseButton>
              </Tooltip>
            )}
          </div>
        ),
      };
      tempAsset.push(purchaseObject);
    });

    viewAcceptedPO && setState({ ...state, rows: tempAsset });
  }, [getReleasedPurchaseOrderReducer]);

  const [isErrorMsgForAccept, setIsErrorMsgForAccept] = useState(false);
  const [isErrorMsgForPublish, setIsErrorMsgForPublish] = useState(false);

  const [acceptButtonName, setAcceptButtonName] = useState("Accept");

  const acceptData = async () => {
    setAcceptButtonName("Please wait...");
    let payload = {
      purchaseOrderId: singleAcceptData.purchaseOrderId,
      userId: userId,
    };

    let response = await acceptPo(payload, jwtToken);

    if (response.status) {
      setIsSuccessAccept(true);
      getReleasedPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseAcceptModal();
        setAcceptButtonName("Accept");
      }, 2250);
    }
    if (!response.status) {
      setIsErrorAccept(true);
      setIsErrorMsgForAccept(response?.data?.data?.msg);
      setAcceptButtonName("Accept");
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {/* {createAssetAccess && ( */}
                <MDBox mt={0}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                    searchProp={importSearch}
                  />
                </MDBox>

                <Modal
                  open={openAcceptModal}
                  onClose={handleCloseAcceptModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={acceptStyle}>
                    <MDTypography>
                      Are you sure you want to Accept?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "1.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseAcceptModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="success"
                          onClick={acceptData}
                          disabled={acceptButtonName === "Please wait..."}
                        >
                          {acceptButtonName}
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessAccept}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessAccept(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Purchase Order Accepted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorAccept}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAccept(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgForAccept}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openPublishModal}
                  onClose={handleClosePublishModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={acceptStyle}>
                    <MDTypography>
                      Are you sure you want to Publish?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "1.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleClosePublishModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="success"
                          onClick={publishData}
                        >
                          Publish
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessPublish}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessPublish(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Purchase Order Published successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorPublish}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorPublish(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgForPublish}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getReleasedPurchaseOrderReducer: state.getReleasedPurchaseOrderReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getReleasedPurchaseOrder: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getReleasedPurchaseOrder(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    acceptPo: (payload, jwtToken) => dispatch(acceptPo(payload, jwtToken)),
    publishPo: (payload, jwtToken) => dispatch(publishPo(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptPo);
