import {
  GETLOCATION_BYWAREHOUSE_REQUEST,
  GETLOCATION_BYWAREHOUSE_SUCCESS,
  GETLOCATION_BYWAREHOUSE_FAILURE,
  ADDLOCATION_TOWAREHOUSE_REQUEST,
  ADDLOCATION_TOWAREHOUSE_SUCCESS,
  ADDLOCATION_TOWAREHOUSE_FAILURE,
  GETITEM_BYLOCATION_REQUEST,
  GETITEM_BYLOCATION_SUCCESS,
  GETITEM_BYLOCATION_FAILURE,
  GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
  GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  GET_RACKS_BY_ZONE_AND_CATEGORY_REQUEST,
  GET_RACKS_BY_ZONE_AND_CATEGORY_SUCCESS,
  GET_RACKS_BY_ZONE_AND_CATEGORY_FAILURE,
  DOWNLOAD_WAREHOUSE_REQUEST,
  DOWNLOAD_WAREHOUSE_SUCCESS,
  DOWNLOAD_WAREHOUSE_FAILURE,
  DOWNLOAD_ZONE_RACK_LOCATION_REQUEST,
  DOWNLOAD_ZONE_RACK_LOCATION_SUCCESS,
  DOWNLOAD_ZONE_RACK_LOCATION_FAILURE,
  GET_ZONES_BYWAREHOUSE_REQUEST,
  GET_ZONES_BYWAREHOUSE_SUCCESS,
  GET_ZONES_BYWAREHOUSE_FAILURE,
} from "./viewLocationTypes";

const initialStateForZoneByWarehouse = {
  loading: false,
  zones: [],
  error: "",
};

const getZonesByWarehouseForViewLocationReducer = (
  state = initialStateForZoneByWarehouse,
  action
) => {
  switch (action.type) {
    case GET_ZONES_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ZONES_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        zones: action.payload,
        error: "",
      };
    case GET_ZONES_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        zones: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsByWarehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETLOCATION_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETLOCATION_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GETLOCATION_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddLocation = {
  loading: false,
  location: [],
  error: "",
};

const addLocationReducer = (state = initialStateForAddLocation, action) => {
  switch (action.type) {
    case ADDLOCATION_TOWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADDLOCATION_TOWAREHOUSE_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        error: "",
      };
    case ADDLOCATION_TOWAREHOUSE_FAILURE:
      return {
        loading: false,
        location: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForGetItem = {
  loading: false,
  item: [],
  error: "",
};

const getItemByLocationReducer = (state = initialStateForGetItem, action) => {
  switch (action.type) {
    case GETITEM_BYLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETITEM_BYLOCATION_SUCCESS:
      return {
        loading: false,
        item: action.payload,
        error: "",
      };
    case GETITEM_BYLOCATION_FAILURE:
      return {
        loading: false,
        item: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLocations = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsByWarehouseAndZoneReducer = (
  state = initialStateForLocations,
  action
) => {
  switch (action.type) {
    case GETLOCATION_BYWAREHOUSEANDZONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GETLOCATION_BYWAREHOUSEANDZONE_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDeleteLocation = {
  loading: false,
  location: [],
  error: "",
};

const deleteLocationReducer = (
  state = initialStateForDeleteLocation,
  action
) => {
  switch (action.type) {
    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        error: "",
      };
    case DELETE_LOCATION_FAILURE:
      return {
        loading: false,
        location: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRacksByZoneAndCategory = {
  loading: false,
  racksByZoneAndCategory: [],
  error: "",
};

const getRacksByZoneAndCategoryReducer = (
  state = initialStateForRacksByZoneAndCategory,
  action
) => {
  switch (action.type) {
    case GET_RACKS_BY_ZONE_AND_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RACKS_BY_ZONE_AND_CATEGORY_SUCCESS:
      return {
        loading: false,
        racksByZoneAndCategory: action.payload,
        error: "",
      };
    case GET_RACKS_BY_ZONE_AND_CATEGORY_FAILURE:
      return {
        loading: false,
        racksByZoneAndCategory: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForWarehouse = {
  loading: false,
  Warehouse: [],
  error: "",
};

const downloadWarehouseReducer = (state = initialStateForWarehouse, action) => {
  switch (action.type) {
    case DOWNLOAD_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_WAREHOUSE_SUCCESS:
      return {
        loading: false,
        Warehouse: action.payload,
        error: "",
      };
    case DOWNLOAD_WAREHOUSE_FAILURE:
      return {
        loading: false,
        Warehouse: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForZoneRackLocation = {
  loading: false,
  ZoneRackLocation: [],
  error: "",
};

const downloadZoneRackLocationReducer = (
  state = initialStateForZoneRackLocation,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_ZONE_RACK_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_ZONE_RACK_LOCATION_SUCCESS:
      return {
        loading: false,
        ZoneRackLocation: action.payload,
        error: "",
      };
    case DOWNLOAD_ZONE_RACK_LOCATION_FAILURE:
      return {
        loading: false,
        ZoneRackLocation: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getZonesByWarehouseForViewLocationReducer,
  getLocationsByWarehouseReducer,
  addLocationReducer,
  getItemByLocationReducer,
  getLocationsByWarehouseAndZoneReducer,
  deleteLocationReducer,
  getRacksByZoneAndCategoryReducer,
  downloadWarehouseReducer,
  downloadZoneRackLocationReducer,
};
