/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Formik, Form, Field, FieldArray } from "formik";
import CancelIcon from "@mui/icons-material/Clear";
import * as Yup from "yup";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { ToastContainer, toast } from "react-toastify";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  createBom,
  deleteBom,
  deleteFgForBom,
  editBom,
  getAssemblyByAssemblyType,
  getAssemblyMaster,
  getAssemblyTypes,
  getBom,
  getFgCode,
  getFgCodeByAssemblyType,
  getItemMaster,
  getItemsByFgCode,
  getItemsForBom,
  getStationsByAssemblyIdForBom,
  getSubModulesByRoleId,
  uploadForBom,
} from "../../store";
// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";
import TextError from "utils/TextError";
import MDInput from "components/MDInput";
import { CSVLink } from "react-csv";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import EditButton from "components/standardized-components/EditButton";
import ViewButton from "components/standardized-components/ViewButton";
import { Backdrop, CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";
import DeleteButton from "components/standardized-components/DeleteButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const styleForView = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};
const styleForCreateBom = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1120 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};
const BOM = ({
  getBom,
  getBomReducer,
  getAssemblyTypes,
  getAssemblyByAssemblyType,
  editBom,
  createBom,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getItemsForBom,
  getFgCodeByAssemblyType,
  itemMaster,
  getFgCodeReducer,
  getAssemblyMasterReducer,
  getAssemblyTypesReducer,
  getItemsByFgCodeReducer,
  getFgCodeByAssemblyTypeReducer,
  getItemForBomReducer,
  getItemMaster,
  getAssemblyByAssemblyTypeReducer,
  getStationsByAssemblyIdForBomReducer,
  getAssemblyMaster,
  getStationsByAssemblyIdForBom,
  getFgCode,
  uploadForBom,
  getItemsByFgCode,
  deleteBom,
  deleteFgForBom,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getItemMaster(jwtToken);
  }, []);

  const [selectedAssemblyTypeForHeader, setSelectedAssemblyTypeForHeader] =
    useState("");

  useEffect(() => {
    getAssemblyMaster(jwtToken);
  }, []);
  const [assemblyTypesOptionsForHeader, setAssemblyTypesOptionsForHeader] =
    useState([]);

  useEffect(() => {
    getAssemblyByAssemblyType(selectedAssemblyTypeForHeader?.value, jwtToken);
  }, [selectedAssemblyTypeForHeader]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let bomModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "0d0545c5-8479-467d-a0f0-17f60af555a1"
  );

  let createBomAccess = bomModule
    ? bomModule.actionId.includes("74571351-edc8-4c23-a02b-990a8a48138f")
    : null;

  let uploadBom = bomModule
    ? bomModule.actionId.includes("e444a0a7-3b4d-4b9c-95ce-cfadfdd214b9")
    : null;
  let downloadBom = bomModule
    ? bomModule.actionId.includes("d512a882-c64f-4065-b97d-af458bdcd605")
    : null;
  let deleteStationMapping = bomModule
    ? bomModule.actionId.includes("79254ee2-7ecc-4d39-b41a-4fc74d0b6567")
    : null;
  let deleteAccess = bomModule
    ? bomModule.actionId.includes("8f062feb-7bf5-4f05-a857-79344aba44d2")
    : null;
  let addAccess = bomModule
    ? bomModule.actionId.includes("8b8fb00c-ac62-451b-9ef6-b570c46ea43b")
    : null;
  let viewBom = bomModule
    ? bomModule.actionId.includes("eb003d68-6d25-4677-aefe-e9de66175c54")
    : null;
  let viewStationMapping = bomModule
    ? bomModule.actionId.includes("75f440d4-07db-4b82-b72e-59aa522fe040")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "FG Code", accessor: "fgCode" },
      { Header: "Assembly", accessor: "assembly" },
      { Header: "Assembly Type", accessor: "assemblyType" },
      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [singleFgDataForUpdate, setSingleFgDataForUpdate] = useState({});

  const handleOpenUpdateModal = (singleData) => {
    setSingleFgDataForUpdate(singleData);
    setOpenUpdateModal(true);
  };

  const handleCloseModal = () => {
    setOpenUpdateModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [fgModelCode, setFgModelCode] = useState("");

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    reqQty: singleFgDataForUpdate.requiredQty,
  };
  const onSubmitForUpdate = async (values) => {
    let payload = {
      requiredQty: values.reqQty,
      stationId: singleFgDataForUpdate.stationId,
      fgModelId: singleFgDataForUpdate.fgModelId,
      itemId: singleFgDataForUpdate.itemId,
      bomId: singleFgDataForUpdate.bomId,
      userId: userId,
    };
    let res = await editBom(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getBom(pageNo, rowsPerPage, searchObject, jwtToken);
      getItemsByFgCode(fgCode, jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res?.data?.data?.msg);
    }
  };

  const validationSchemaForBom = Yup.object({
    itemsForBom: Yup.array().of(
      Yup.object({
        requiredQty: Yup.number()
          .min(0, "Quantity cannot be negative")
          .required("Quantity is required"),
      })
    ),
  });
  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });
  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        uom: item.uom,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);
  const [itemCodeBomOptions, setItemCodeBomOptions] = useState([]);

  useEffect(() => {
    let tempListOfBinTypesOptions = [];
    let data = getAssemblyTypesReducer.assemblyTypes.data
      ? getAssemblyTypesReducer.assemblyTypes.data
      : [];
    data.map((bin) => {
      tempListOfBinTypesOptions.push({
        label: bin.assemblyType,
        value: bin.assemblyTypeId,
      });
    });
    setAssemblyTypesOptionsForHeader(tempListOfBinTypesOptions);
  }, [getAssemblyTypesReducer]);

  useEffect(() => {
    let tempItemCodeBomOptions = [];
    let data = getItemForBomReducer.items ? getItemForBomReducer.items : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeBomOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
      });
    });
    setItemCodeBomOptions(tempItemCodeBomOptions);
  }, [getItemForBomReducer]);

  const [selectedFgCode, setSelectedFgCode] = useState("");
  const handleFgCodeChange = (selectedActivity) => {
    setSelectedFgCode(selectedActivity);
  };
  const handleAssemblyTypeForHeaderChange = (selectedActivity) => {
    setSelectedAssemblyTypeForHeader(selectedActivity);
  };

  const [itemsForBomLength, setItemsForBomLength] = useState("");

  const [isSuccessForCreateBOM, setIsSuccessForCreateBOM] = useState(false);
  const [isErrorForCreateBOM, setIsErrorForCreateBOM] = useState(false);

  useEffect(() => {
    if (isSuccessForCreateBOM) {
      setTimeout(() => {
        setIsSuccessForCreateBOM(false);
      }, 3000);
    }
  }, [isSuccessForCreateBOM]);

  const [stationsOptions, setStationsOptions] = useState([]);

  const [selectedAssembly, setAssembly] = useState("");
  const [fgCodeForFlag, setFgCodeForFlag] = useState("");
  const [selectedAssemblyType, setAssemblyType] = useState("");
  const [fgModelId, setFgModelId] = useState("");
  const [selectedAssemblyId, setAssemblyId] = useState("");
  const data = getAssemblyMasterReducer.assembly.data || [];

  useEffect(() => {
    getStationsByAssemblyIdForBom(selectedAssembly?.value, jwtToken);
  }, [selectedAssembly]);

  useEffect(() => {
    if (selectedAssemblyId) {
      getStationsByAssemblyIdForBom(selectedAssemblyId, jwtToken);
    }
  }, [selectedAssemblyId, jwtToken]);

  const handleAssemblyChange = (selectedOption) => {
    setAssembly(selectedOption);
  };

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getStationsByAssemblyIdForBomReducer.stations.data
      ? getStationsByAssemblyIdForBomReducer.stations.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.station,
        value: level.stationId,
        stationOrder: level.stationOrder,
      });
    });

    setStationsOptions(tempLevelOptions);
  }, [getStationsByAssemblyIdForBomReducer]);
  useEffect(() => {
    getFgCodeByAssemblyType(selectedAssemblyTypeForHeader?.value, jwtToken);
  }, [selectedAssemblyTypeForHeader]);
  const [fgCodeOptions, setFgCodeOptions] = useState([]);
  useEffect(() => {
    let tempfgCodeOptions = [];
    let data = getFgCodeByAssemblyTypeReducer.fgCodes
      ? getFgCodeByAssemblyTypeReducer.fgCodes
      : [];

    data.map((warehouse) => {
      tempfgCodeOptions.push({
        label: warehouse.fgModelCode,
        value: warehouse.fgModelId,
        uom: warehouse.uom,
      });
    });
    setFgCodeOptions(tempfgCodeOptions);
  }, [getFgCodeByAssemblyTypeReducer]);

  const [assemblyOptions, setAssemblyOptions] = useState([]);
  useEffect(() => {
    let tempfgCodeOptions = [];
    let data = getAssemblyByAssemblyTypeReducer.assembly
      ? getAssemblyByAssemblyTypeReducer.assembly
      : [];
    data.map((warehouse) => {
      tempfgCodeOptions.push({
        label: warehouse.assembly,
        value: warehouse.assemblyId,
      });
    });
    setAssemblyOptions(tempfgCodeOptions);
  }, [getAssemblyByAssemblyTypeReducer]);

  useEffect(() => {
    if (isErrorForCreateBOM) {
      setTimeout(() => {
        setIsErrorForCreateBOM(false);
      }, 3000);
    }
  }, [isErrorForCreateBOM]);

  useEffect(() => {
    getBom(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  useEffect(() => {
    getAssemblyTypes(jwtToken);
  }, []);

  const [fgCode, setFgCode] = useState("");

  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getBomReducer.bom.data ? getBomReducer.bom.data : [];
    setPaginationObj(getBomReducer?.bom?.pagination);

    setLoading(getBomReducer.loading);
    data?.map((finishedGoods) => {
      const formatDate = new Date(finishedGoods.cd);

      let des = finishedGoods?.description;
      let len = desLength;
      const time = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        fgCode: finishedGoods.fgModelCode,
        assembly: finishedGoods.assembly,
        assemblyType: finishedGoods.assemblyType,

        itemDes: finishedGoods.itemCode,
        locationId: finishedGoods.stationCode,
        reqQty: finishedGoods.requiredQty,
        fgDescription: des ? des.substring(0, len) : "",
        qty: `${finishedGoods.qty}`,
        uom: `${finishedGoods.uom}`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          <>
            {viewStationMapping && (
              <ViewButton
                tooltip="View"
                // disabled={!viewVins}
                variant="gradient"
                color="info"
                iconOnly
                type="button"
                onClick={() => {
                  setFgCode(finishedGoods.fgModelId);
                  setFgModelCode(finishedGoods.fgModelCode);

                  handleOpenViewItemModal(finishedGoods);
                }}
                style={{ marginRight: "5px" }}
              >
                <Icon>visibility</Icon>
              </ViewButton>
            )}
            {addAccess && (
              <CreateButton
                tooltip="Add Station"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => {
                  setAssemblyId(finishedGoods.assemblyId);
                  setFgModelId(finishedGoods.fgModelId);
                  setAssembly(finishedGoods.assembly);
                  setAssemblyType(finishedGoods.assemblyType);
                  setFgCodeForFlag(finishedGoods.fgModelCode);
                  handleOpenAddModal(finishedGoods);
                  getStationsByAssemblyIdForBom(
                    finishedGoods.assemblyId,
                    jwtToken
                  );
                }}
                style={{ marginRight: "5px", backgroundColor: "#39AD48" }}
              >
                <Icon>edit_sharp</Icon>
              </CreateButton>
            )}
            {deleteAccess && (
              <DeleteButton
                tooltip="Delete"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenFgDeleteModal(finishedGoods)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewBom && setState({ ...state, rows: tempFinishedGoods });
  }, [getBomReducer]);

  const [isFlagMode, setIsFlagMode] = useState(false);
  const [viewModal, setOpenViewModal] = useState(false);

  const handleOpenViewItemModal = (finishedGoods) => {
    setTimeout(() => {
      setOpenViewModal(true);
    }, 200);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };
  const [vinTable, setViewTable] = useState({
    columns: [
      { Header: "Station", accessor: "station" },
      { Header: "Station Order", accessor: "stationOrder" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Required QTY", accessor: "reqQty" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = vinTable;
  useEffect(() => {
    getItemsByFgCode(fgCode, jwtToken);
  }, [fgCode]);

  useEffect(() => {
    let tempVin = [];
    let data = getItemsByFgCodeReducer.items
      ? getItemsByFgCodeReducer.items
      : [];

    setLoading2(getItemsByFgCodeReducer.loading);
    data.map((vin) => {
      let des = vin?.itemDescription;
      let len = desLength;
      const formatDate = new Date(vin.cd);
      const vinInfo = {
        station: vin.station,
        stationOrder: vin.stationOrder,
        locationId: vin.stationCode,
        // itemCode: vin.itemCode,
        itemCode: vin.itemCode ? `${vin.itemCode}` : "",
        itemDescription: `${des ? des.substring(0, len) : ""}`,
        reqQty: vin.requiredQty,
        uom: vin.uom,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          <>
            {deleteStationMapping && (
              <DeleteButton
                tooltip="Delete"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteModal(vin)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </>
        ),
      };
      tempVin.push(vinInfo);
    });

    setViewTable({ ...vinTable, rows: tempVin });
  }, [getItemsByFgCodeReducer]);

  const [singleDataForDelete, setSingleDataForDelete] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (data) => {
    setOpenDeleteModal(true);
    setSingleDataForDelete(data);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [errorMsgForDelete, setErrorMsgForDelete] = useState(false);
  const [isSuccessForDelete, setIsSuccessForDelete] = useState(false);

  useEffect(() => {
    if (isErrorDelete) {
      setTimeout(() => {
        setIsErrorDelete(false);
      }, 3000);
    }
  }, [isErrorDelete]);
  useEffect(() => {
    if (isSuccessForDelete) {
      setTimeout(() => {
        setIsSuccessForDelete(false);
      }, 2000);
    }
  }, [isSuccessForDelete]);

  const [singleDataForFgDelete, setSingleDataForFgDelete] = useState({});
  const [openFgDeleteModal, setOpenFgDeleteModal] = useState(false);

  const handleOpenFgDeleteModal = (data) => {
    setOpenFgDeleteModal(true);
    setSingleDataForFgDelete(data);
  };

  const handleCloseFgDeleteModal = () => {
    setOpenFgDeleteModal(false);
  };
  const [isErrorForFgDelete, setIsErrorForFgDelete] = useState(false);
  const [errorMsgForDeleteForFg, setErrorMsgForDeleteForFg] = useState(false);
  const [isSuccessForDeleteForFg, setIsSuccessForDeleteForFg] = useState(false);

  useEffect(() => {
    if (isErrorForFgDelete) {
      setTimeout(() => {
        setIsErrorForFgDelete(false);
      }, 3000);
    }
  }, [isErrorForFgDelete]);
  useEffect(() => {
    if (isSuccessForDeleteForFg) {
      setTimeout(() => {
        setIsSuccessForDeleteForFg(false);
      }, 2000);
    }
  }, [isSuccessForDeleteForFg]);

  const [deleteFgButtonName, setDeleteFgButtonName] = useState("Delete");

  const deleteFg = async () => {
    setDeleteFgButtonName("Please wait...");
    let fgId = singleDataForFgDelete.fgModelId;

    let userId = loginDetails.data.userId;

    let res = await deleteFgForBom(fgId, userId);

    if (res.status) {
      await getBom(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleCloseFgDeleteModal();
        setDeleteFgButtonName("Delete");
      }, 3000);
      setIsSuccessForDeleteForFg(true);
    }
    if (!res.status) {
      setIsErrorForFgDelete(true);
      setDeleteFgButtonName("Delete");
      setErrorMsgForDeleteForFg(res?.data?.data?.msg);
    }
  };
  const [deleteButtonName, setDeleteButtonName] = useState("Delete");

  const deleteItemBom = async () => {
    setDeleteButtonName("Please wait...");
    let bomId = singleDataForDelete.bomId;
    let userId = loginDetails.data.userId;
    let res = await deleteBom(bomId, userId);
    if (res.status) {
      await getItemsByFgCode(fgCode, jwtToken);
      setTimeout(() => {
        handleCloseDeleteModal();
        setDeleteButtonName("Delete");
      }, 3000);
      setIsSuccessForDelete(true);
    }
    if (!res.status) {
      setIsErrorDelete(true);
      setErrorMsgForDelete(res?.data?.data?.msg);
      setDeleteButtonName("Delete");
    }
  };

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("bom", uploadCsvData);

    let res = await uploadForBom(data, jwtToken);

    if (res.status) {
      getBom(pageNo, rowsPerPage, searchObject, jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };
  const headers = [
    {
      label: "fgModelCode",
      key: "fgModelCode",
    },
    {
      label: "itemCode",
      key: "itemCode",
    },
    { label: "assembly", key: "assembly" },
    { label: "station", key: "station" },
    { label: "requiredQty", key: "requiredQty" },
  ];
  const csvLink = {
    filename: "BOM Template.csv",
    headers: headers,
    data: [],
  };
  const csvLink2 = {
    filename: "BOM Data.csv",
    headers: headers,
    data: rows,
  };

  const [createBomModal, setCreateBomModal] = useState(false);
  const handleCreateBomModal = () => {
    setIsFlagMode(false);
    setCreateBomModal(true);
  };
  const handleOpenAddModal = () => {
    setIsFlagMode(true);
    setCreateBomModal(true);
  };
  const handleCloseCreateBomModal = () => {
    setCreateBomModal(false);
    setSelectedFgCode("");
    setSelectedAssemblyTypeForHeader("");
    setAssembly("");
  };

  const [flagForAssembly, setFlagForAssembly] = useState(false);

  const initialValuesForBom = {
    fgModelId: "",
    itemsForBom: [
      {
        itemId: "",
        requiredQty: "",
      },
    ],
  };

  const [buttonName, setButtonName] = useState("Submit");

  const onSubmitForCreateBom = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload;

    if (isFlagMode) {
      payload = {
        fgModelId: fgModelId,
        userId: userId,
        items: values.itemsForBom,
      };
    } else {
      payload = {
        fgModelId: selectedFgCode?.value,
        assemblyTypeId: selectedAssemblyTypeForHeader?.value,
        items: values.itemsForBom,
        userId: userId,
      };
    }

    let res = await createBom(payload, jwtToken);
    if (res.status) {
      getBom(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessForCreateBOM(true);
      setAssembly("");
      resetForm();
      setTimeout(() => {
        handleCloseCreateBomModal();
        setButtonName("Submit");
      }, 3000);
    } else {
      setErrorMsgForCreate(res?.data?.data?.msg);
      setIsErrorForCreateBOM(true);
      setButtonName("Submit");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox color="text" px={2} mt={4}>
                {createBomAccess && (
                  <CreateButton
                    tooltip="Create BOM"
                    // disabled={!createAccess}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={() => {
                      handleCreateBomModal();
                      getFgCode(jwtToken);
                    }}
                    style={{ marginRight: "1rem" }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}
                {uploadBom && (
                  <UploadButton
                    tooltip="Upload CSV"
                    //   disabled={!uploadItem}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </UploadButton>
                )}

                {downloadBom && (
                  <CSVLink {...csvLink}>
                    <DownloadButton
                      tooltip="Download Template"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="error"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadButton>
                  </CSVLink>
                )}

                <CSVLink {...csvLink2}>
                  <DownloadDataButton
                    tooltip="Download BOM Data"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="success"
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </DownloadDataButton>
                </CSVLink>
              </MDBox>
              {uploadCsvFilter && (
                <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                  <div>
                    <input
                      className="choose_file"
                      style={{
                        marginTop: "1.5rem",
                        marginLeft: "1.5rem",
                        // marginBottom: "-9rem",
                      }}
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setUploadCsvData(e.target.files[0]);
                      }}
                    />
                    <MDButton
                      disabled={
                        !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                      }
                      variant="contained"
                      color="info"
                      style={{ marginLeft: "0", marginTop: "0rem" }}
                      type="button"
                      onClick={uploadCsvHandler}
                    >
                      UPLOAD
                    </MDButton>
                  </div>
                  <div style={{ marginBottom: "-20px" }}>
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "30px",
                        fontStyle: "italic",
                      }}
                    >
                      <strong>*</strong> Please upload
                      <strong>".csv"</strong> file only
                    </span>
                  </div>
                </div>
              )}
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <Modal
                open={openUpdateModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">QTY</MDTypography>
                        <MDInput
                          type="number"
                          name="reqQty"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.reqQty}
                          error={
                            formikForUpdate.touched.reqQty &&
                            formikForUpdate.errors.reqQty &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.reqQty &&
                          formikForUpdate.errors.reqQty && (
                            <TextError msg={formikForUpdate.errors.reqQty} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          // disabled={!formikForUpdate.isValid}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={viewModal}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForView}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      {fgModelCode}
                    </MDTypography>
                    <MDBox></MDBox>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                              marginBottom: "5px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />
                  <MDBox>
                    {!loading2 ? (
                      <DataTable
                        table={{ columns: itemColumns, rows: itemRows }}
                        isSorted={true}
                        tableSearch={true}
                        entriesPerPage={{
                          defaultValue: 5,
                          entries: [5, 10, 15, 20, 25, 30],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "100px",
                          marginBottom: "100px",
                        }}
                      >
                        <CircularProgress color="info" />
                      </div>
                    )}
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteItemBom}
                      disabled={deleteButtonName === "Please wait..."}
                    >
                      {deleteButtonName}
                    </MDButton>
                    <MDBox mt={2}>
                      <Collapse in={isErrorDelete}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForDelete}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openFgDeleteModal}
                onClose={handleCloseFgDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      color="info"
                      variant="outlined"
                      onClick={handleCloseFgDeleteModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteFg}
                      disabled={deleteFgButtonName === "Please wait..."}
                    >
                      {deleteFgButtonName}
                    </MDButton>
                    <MDBox mt={2}>
                      <Collapse in={isErrorForFgDelete}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForFgDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDeleteForFg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForDeleteForFg}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForDeleteForFg(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={createBomModal}
                onClose={handleCloseCreateBomModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreateBom}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      {isFlagMode ? "Update BOM" : "Create BOM"}
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateBomModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox>
                      <MDBox>
                        <Grid container>
                          <Formik
                            initialValues={initialValuesForBom}
                            validationSchema={validationSchemaForBom}
                            onSubmit={onSubmitForCreateBom}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  <div>
                                    <MDBox>
                                      {isFlagMode && (
                                        <MDBox
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="flex-start"
                                          borderRadius={4}
                                          bgcolor="#f5f5f5"
                                          boxShadow={1}
                                          padding={0.5}
                                        >
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                              marginBottom: 4,
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              FG Code:
                                            </span>{" "}
                                            {fgCodeForFlag}
                                          </MDTypography>
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                              marginBottom: 4,
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Assembly:
                                            </span>{" "}
                                            {selectedAssembly}
                                          </MDTypography>
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Assembly Type:
                                            </span>{" "}
                                            {selectedAssemblyType}
                                          </MDTypography>
                                        </MDBox>
                                      )}

                                      {isFlagMode ? (
                                        ""
                                      ) : (
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={4}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "310px" }}
                                            >
                                              <MDTypography variant="h6">
                                                Assembly Type
                                              </MDTypography>

                                              <Select
                                                isClearable
                                                className="select-css"
                                                maxMenuHeight={130}
                                                placeholder="Choose one..."
                                                name="assemblyType"
                                                onBlur={() => {
                                                  formik.handleBlur({
                                                    target: {
                                                      name: "assemblyType",
                                                    },
                                                  });
                                                }}
                                                value={
                                                  selectedAssemblyTypeForHeader
                                                }
                                                options={
                                                  assemblyTypesOptionsForHeader
                                                }
                                                onChange={(selectedOption) => {
                                                  handleAssemblyTypeForHeaderChange(
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    "assemblyType",
                                                    selectedOption
                                                  );
                                                }}
                                              />

                                              {formik.touched.assemblyType &&
                                                formik.errors.assemblyType && (
                                                  <TextError
                                                    msg={
                                                      formik.errors.assemblyType
                                                    }
                                                  />
                                                )}
                                            </MDBox>
                                          </Grid>

                                          <Grid item xs={12} sm={4}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "310px" }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                style={{ fontWeight: "500" }}
                                              >
                                                Assembly
                                              </MDTypography>
                                              <Select
                                                isClearable
                                                // isDisabled={
                                                //   selectedAssemblyTypeForHeader
                                                //     ? false
                                                //     : true
                                                // }
                                                className="select-css"
                                                maxMenuHeight={130}
                                                placeholder="Choose one..."
                                                name="assembly"
                                                onBlur={() =>
                                                  formik.handleBlur({
                                                    target: {
                                                      name: "assembly",
                                                    },
                                                  })
                                                }
                                                value={
                                                  selectedAssembly ||
                                                  formik.values.assembly
                                                }
                                                options={assemblyOptions}
                                                onChange={(selectedOption) => {
                                                  handleAssemblyChange(
                                                    selectedOption,
                                                    formik.setFieldValue
                                                  );
                                                  formik.setFieldValue(
                                                    "assembly",
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    `itemsForBom[0].stationId`,
                                                    ""
                                                  );
                                                  formik.setFieldValue(
                                                    `itemsForBom[0].stationOrder`,
                                                    ""
                                                  );
                                                  formik.setFieldValue(
                                                    `itemsForBom[0].itemId`,
                                                    ""
                                                  );
                                                  formik.setFieldValue(
                                                    `itemsForBom[0].requiredQty`,
                                                    ""
                                                  );
                                                  setFlagForAssembly();
                                                }}
                                              />
                                              {formik.touched.assembly &&
                                                formik.errors.assembly && (
                                                  <TextError
                                                    msg={formik.errors.assembly}
                                                  />
                                                )}
                                            </MDBox>
                                          </Grid>
                                          <Grid item xs={12} sm={4}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "310px" }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                style={{ fontWeight: "500" }}
                                              >
                                                FG Code/SFG
                                              </MDTypography>
                                              <Select
                                                // isDisabled={
                                                //   selectedAssemblyTypeForHeader
                                                //     ? false
                                                //     : true
                                                // }
                                                isClearable
                                                className="select-css"
                                                maxMenuHeight={130}
                                                placeholder="Choose one..."
                                                name="fgModelId"
                                                onBlur={() => {
                                                  formik.handleBlur({
                                                    target: {
                                                      name: "fgModelId",
                                                    },
                                                  });
                                                }}
                                                value={selectedFgCode}
                                                options={fgCodeOptions}
                                                onChange={(selectedOption) => {
                                                  handleFgCodeChange(
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    "fgCode",
                                                    selectedOption
                                                  );
                                                }}
                                              />
                                              {formik.touched.fgModelId &&
                                                formik.errors.fgModelId && (
                                                  <TextError
                                                    msg={
                                                      formik.errors.fgModelId
                                                    }
                                                  />
                                                )}
                                            </MDBox>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </MDBox>
                                    <MDBox>
                                      <MDTypography variant="h5" mt={2}>
                                        Stations
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForBom">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForBom } = values;
                                          setItemsForBomLength(
                                            itemsForBom.length
                                          );

                                          return (
                                            <div>
                                              {itemsForBom.map(
                                                (item, index) => {
                                                  const isAddButtonDisabled =
                                                    !item.itemId ||
                                                    !item.requiredQty;
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Station
                                                            </MDTypography>
                                                            <Select
                                                              isDisabled={
                                                                !formik.values
                                                                  .assembly &&
                                                                !isFlagMode
                                                              }
                                                              isClearable
                                                              className="select-css"
                                                              maxMenuHeight={
                                                                130
                                                              }
                                                              placeholder="Choose one..."
                                                              name={`itemsForBom[${index}].stationId`}
                                                              onBlur={() => {
                                                                formik.handleBlur(
                                                                  {
                                                                    target: {
                                                                      name: `itemsForBom[${index}].stationId`,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              options={
                                                                stationsOptions
                                                              }
                                                              value={
                                                                itemsForBom[
                                                                  index
                                                                ].stationId
                                                              }
                                                              onChange={async (
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForBom.${index}.stationId`,
                                                                  selectedOption
                                                                    ? selectedOption
                                                                    : ""
                                                                );

                                                                const selectedItem =
                                                                  stationsOptions.find(
                                                                    (item) =>
                                                                      item.value ===
                                                                      selectedOption?.value
                                                                  );
                                                                const stationOrder =
                                                                  selectedItem
                                                                    ? selectedItem.stationOrder
                                                                    : "";

                                                                formik.setFieldValue(
                                                                  `itemsForBom.${index}.stationOrder`,
                                                                  stationOrder
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForBom.itemId`,
                                                                  selectedOption
                                                                    ? selectedOption.value
                                                                    : ""
                                                                );
                                                                if (
                                                                  selectedOption
                                                                ) {
                                                                  const res =
                                                                    await getItemsForBom(
                                                                      selectedOption.value,
                                                                      formik
                                                                        .values
                                                                        .itemsForBom,
                                                                      jwtToken
                                                                    );
                                                                }
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForBom &&
                                                              formik.errors
                                                                .itemsForBom &&
                                                              formik.errors
                                                                .itemsForBom[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForBom[
                                                                index
                                                              ].stationId && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForBom[
                                                                      index
                                                                    ].stationId
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2.5}
                                                        >
                                                          <MDBox mb={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Station Order
                                                            </MDTypography>
                                                            <Field
                                                              disabled
                                                              className="form-control"
                                                              type="text"
                                                              name={`itemsForBom[${index}].stationOrder`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                formik.values
                                                                  .itemsForBom[
                                                                  index
                                                                ]?.stationOrder
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                  readOnly: true,
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2.5}
                                                          mr={8}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Item Code/Des
                                                            </MDTypography>
                                                            <Select
                                                              isClearable
                                                              className="select-css-for-itemcode"
                                                              maxMenuHeight={80}
                                                              placeholder="Choose one..."
                                                              name={`itemsForBom[${index}].itemId`}
                                                              onBlur={() => {
                                                                formik.handleBlur(
                                                                  {
                                                                    target: {
                                                                      name: `itemsForBom[${index}].itemId`,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              options={
                                                                itemCodeBomOptions
                                                              }
                                                              value={
                                                                itemsForBom[
                                                                  index
                                                                ].itemId
                                                              }
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForBom[
                                                                  index
                                                                ].stationId
                                                              } // Disable if stationId is not selected
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForBom.${index}.itemId`,
                                                                  selectedOption
                                                                    ? selectedOption
                                                                    : ""
                                                                );
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForBom &&
                                                              formik.errors
                                                                .itemsForBom &&
                                                              formik.errors
                                                                .itemsForBom[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForBom[
                                                                index
                                                              ].itemId && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForBom[
                                                                      index
                                                                    ].itemId
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2}
                                                          ml={9.5}
                                                        >
                                                          <MDBox mb={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Required Qty
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="number"
                                                              name={`itemsForBom[${index}].requiredQty`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                formik.values
                                                                  .itemsForBom[
                                                                  index
                                                                ].requiredQty
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                  min: 0,
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForBom &&
                                                              formik.touched
                                                                .itemsForBom[
                                                                index
                                                              ] &&
                                                              formik.touched
                                                                .itemsForBom[
                                                                index
                                                              ].requiredQty &&
                                                              formik.errors
                                                                .itemsForBom &&
                                                              formik.errors
                                                                .itemsForBom[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForBom[
                                                                index
                                                              ].requiredQty && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForBom[
                                                                      index
                                                                    ]
                                                                      .requiredQty
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            marginTop: "2.5rem",
                                                            marginLeft: "1rem",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                          </div>
                                                          {index ===
                                                            itemsForBom.length -
                                                              1 && (
                                                            <div>
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() => {
                                                                    if (
                                                                      !isAddButtonDisabled
                                                                    ) {
                                                                      push({
                                                                        itemId:
                                                                          "",
                                                                        requiredQty:
                                                                          "",
                                                                      });
                                                                      // setIsZoneFieldDisabled(
                                                                      //   true
                                                                      // );
                                                                    }
                                                                  }}
                                                                  color="info"
                                                                  style={{
                                                                    width:
                                                                      "30px",
                                                                    marginLeft:
                                                                      "0rem",
                                                                    cursor:
                                                                      isAddButtonDisabled
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                    opacity:
                                                                      isAddButtonDisabled
                                                                        ? 0.5
                                                                        : 1,
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>

                                    <div style={{ background: "white" }}>
                                      <MDButton
                                        style={{
                                          position: "sticky",
                                          bottom: "0",
                                        }}
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        disabled={
                                          !formik.isValid ||
                                          !selectedAssembly ||
                                          formik.values.itemsForBom.some(
                                            (item) => item.itemId === ""
                                          ) ||
                                          buttonName === "Please wait..."
                                        }
                                      >
                                        {buttonName}
                                      </MDButton>
                                    </div>
                                    <MDBox>
                                      <Collapse in={isErrorForCreateBOM}>
                                        <Alert
                                          severity="error"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsErrorForCreateBOM(false);
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          {errorMsgForCreate}
                                        </Alert>
                                      </Collapse>
                                      <Collapse in={isSuccessForCreateBOM}>
                                        <Alert
                                          severity="success"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsSuccessForCreateBOM(false);
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          Created Successfully
                                        </Alert>
                                      </Collapse>
                                    </MDBox>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getBomReducer: state.getBomReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
    getFgCodeReducer: state.getFgCodeReducer,
    getAssemblyMasterReducer: state.getAssemblyMasterReducer,
    getStationsByAssemblyIdForBomReducer:
      state.getStationsByAssemblyIdForBomReducer,
    getItemsByFgCodeReducer: state.getItemsByFgCodeReducer,
    getItemForBomReducer: state.getItemForBomReducer,
    getFgCodeByAssemblyTypeReducer: state.getFgCodeByAssemblyTypeReducer,
    getAssemblyByAssemblyTypeReducer: state.getAssemblyByAssemblyTypeReducer,
    getAssemblyTypesReducer: state.getAssemblyTypesReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getBom: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getBom(pageNo, rowsPerPage, searchObject, jwtToken)),
    getItemsForBom: (stationId, jwtToken) =>
      dispatch(getItemsForBom(stationId, jwtToken)),
    deleteBom: (stationId, userId, jwtToken) =>
      dispatch(deleteBom(stationId, userId, jwtToken)),
    createBom: (payload, jwtToken) => dispatch(createBom(payload, jwtToken)),
    getFgCode: (jwtToken) => dispatch(getFgCode(jwtToken)),
    editBom: (payload, jwtToken) => dispatch(editBom(payload, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    uploadForBom: (payload, jwtToken) =>
      dispatch(uploadForBom(payload, jwtToken)),
    getStationsByAssemblyIdForBom: (assemblyId, jwtToken) =>
      dispatch(getStationsByAssemblyIdForBom(assemblyId, jwtToken)),
    getAssemblyMaster: (jwtToken) => dispatch(getAssemblyMaster(jwtToken)),
    getItemsByFgCode: (fgCode, jwtToken) =>
      dispatch(getItemsByFgCode(fgCode, jwtToken)),
    getFgCodeByAssemblyType: (assemblyType, jwtToken) =>
      dispatch(getFgCodeByAssemblyType(assemblyType, jwtToken)),
    getAssemblyByAssemblyType: (assemblyType, jwtToken) =>
      dispatch(getAssemblyByAssemblyType(assemblyType, jwtToken)),
    deleteFgForBom: (fgId, userId, jwtToken) =>
      dispatch(deleteFgForBom(fgId, userId, jwtToken)),
    getAssemblyTypes: (jwtToken) => dispatch(getAssemblyTypes(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BOM);
