import {
  GET_DAILY_VENDOR_PERFORMANCE_FAILURE,
  GET_DAILY_VENDOR_PERFORMANCE_REQUEST,
  GET_DAILY_VENDOR_PERFORMANCE_SUCCESS,
  GET_DATE_RANGE_REQUEST,
  GET_DATE_RANGE_SUCCESS,
  GET_DATE_RANGE_FAILURE,
  GET_MONTHLY_VENDOR_PERFORMANCE_REQUEST,
  GET_MONTHLY_VENDOR_PERFORMANCE_SUCCESS,
  GET_MONTHLY_VENDOR_PERFORMANCE_FAILURE,
} from "./vendorPerformanceTypes";

const initialStateForDailyVendorPerformance = {
  loading: false,
  dailyVendorPerformance: [],
  error: "",
};

const getDailyVendorPerformanceReducer = (
  state = initialStateForDailyVendorPerformance,
  action
) => {
  switch (action.type) {
    case GET_DAILY_VENDOR_PERFORMANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DAILY_VENDOR_PERFORMANCE_SUCCESS:
      return {
        loading: false,
        dailyVendorPerformance: action.payload,
        error: "",
      };
    case GET_DAILY_VENDOR_PERFORMANCE_FAILURE:
      return {
        loading: false,
        dailyVendorPerformance: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDateRange = {
  loading: false,
  dateRange: [],
  error: "",
};

const getDateRangeReducer = (state = initialStateForDateRange, action) => {
  switch (action.type) {
    case GET_DATE_RANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DATE_RANGE_SUCCESS:
      return {
        loading: false,
        dateRange: action.payload,
        error: "",
      };
    case GET_DATE_RANGE_FAILURE:
      return {
        loading: false,
        dateRange: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForMonthlyVendorPerformance = {
  loading: false,
  monthlyVendorPerformance: [],
  error: "",
};

const getMonthlyVendorPerformanceReducer = (
  state = initialStateForMonthlyVendorPerformance,
  action
) => {
  switch (action.type) {
    case GET_MONTHLY_VENDOR_PERFORMANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MONTHLY_VENDOR_PERFORMANCE_SUCCESS:
      return {
        loading: false,
        monthlyVendorPerformance: action.payload,
        error: "",
      };
    case GET_MONTHLY_VENDOR_PERFORMANCE_FAILURE:
      return {
        loading: false,
        monthlyVendorPerformance: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export {
  getDailyVendorPerformanceReducer,
  getDateRangeReducer,
  getMonthlyVendorPerformanceReducer,
};
