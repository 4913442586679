/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard example components

import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// React-Select component and styling
import Select from "react-select";
// Functions from store
import {
  createPickList,
  getCreatedActivity,
  getDocumentNumber,
  getDocumentType,
  getItemByDocumentNo,
  getPickListTracking,
  getSubModulesByRoleId,
  getUser,
  releaseUser,
  getAvailableQty,
} from "../../../store";

// Config
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import { Opacity } from "@mui/icons-material";
import CreateButton from "components/standardized-components/CreateButton";
import ReleaseButton from "components/standardized-components/ReleaseButton";
import { addMinutes, format } from "date-fns";
import { CSVLink } from "react-csv";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import ViewButton from "components/standardized-components/ViewButton";

const cookies = new Cookies();

const styleForPicklistViewModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  width: { xs: 350, lg: 1300 },
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, lg: 750 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};

const PicklistTracking = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPickListTracking,
  getDocumentType,
  getCreatedActivity,
  releaseUser,
  getUser,
  getDocumentNumber,
  createPickList,
  getItemByDocumentNo,
  picklistTrackingReducer,
  getDocumentTypeReducer,
  getCreatedActivityReducer,
  getDocumentNumberReducer,
  getItemByDocumentNoReducer,
  getUserReducer,
  getAvailableQty,
  getAvailableQtyReducer,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let picklistTrackingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "281193f6-e2a6-42d1-9c4f-69c69038ac90"
  );

  let viewPicklistTracking = picklistTrackingModule
    ? picklistTrackingModule.actionId.includes(
        "e45c6dfc-4f53-44d6-bdfe-bd69be22e196"
      )
    : null;
  let createPicklistAccess = picklistTrackingModule
    ? picklistTrackingModule.actionId.includes(
        "f9b45e5f-cb79-4aca-ab6b-65da94b9c2e4"
      )
    : null;
  let viewSuids = picklistTrackingModule
    ? picklistTrackingModule.actionId.includes(
        "0ec41948-dd73-4cca-8fad-d88ffdd1d2db"
      )
    : null;
  let downloadAccess = picklistTrackingModule
    ? picklistTrackingModule.actionId.includes(
        "48177d1b-5204-48d1-bcd3-34b07bbf4b9d"
      )
    : null;

  let releasePicklistTracking = picklistTrackingModule
    ? picklistTrackingModule.actionId.includes(
        "a0244940-3ddf-4740-84ec-7f65e4880dfe"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});

  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  const [loading, setLoading] = useState(true);

  const [documentNumberSelected, setDocumentNumberSelected] = useState("");
  useEffect(() => {
    getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Pick List Code", accessor: "picklistCode" },
      { Header: "Document No", accessor: "documentNo" },

      { Header: "Item Code", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Item Qty", accessor: "qty" },
      { Header: "Uom", accessor: "uom" },
      { Header: "From Loc", accessor: "source" },
      { Header: "To Loc", accessor: "destination" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Remarks 1",
        accessor: "remarks1",
      },
      {
        Header: "Remarks 2",
        accessor: "remarks2",
      },
      {
        Header: "Remarks 3",
        accessor: "remarks3",
      },
      {
        Header: "Remarks 4",
        accessor: "remarks4",
      },
      { Header: "Created Date", accessor: "createdDate" },

      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  let downloadBom = true;

  const [errorMsg, setErrorMsg] = useState("");

  const [pickListId, setPickListId] = useState("");

  const [allocateUserModal, setAllocateUserModal] = useState(false);
  const [suidData, setSuidData] = useState(false);

  const handleOpenAllocateUserModal = () => {
    setAllocateUserModal(true);
    formikForUser.setValues(initialValuesForUser);
    formikForUser.setTouched({});
    formikForUser.setErrors({});
    setSelectedUser("");
  };
  const handleCloseAllocateUserModal = () => {
    setAllocateUserModal(false);
    setSelectedUser("");
  };
  const [singlePicklist, setSinglePicklistItem] = useState({});

  const [openPicklistViewModal, setOpenPicklistViewModal] = useState(false);

  const handleOpenViewPicklistModal = (picklist) => {
    setSinglePicklistItem(picklist);
    setOpenPicklistViewModal(true);
  };

  const handleCloseViewPicklistModal = () => {
    setOpenPicklistViewModal(false);
  };

  useEffect(() => {
    let tempPicklist = [];

    let data = picklistTrackingReducer.pickList.data
      ? picklistTrackingReducer.pickList.data
      : [];

    setErrorMsgFromApi(picklistTrackingReducer?.error?.data?.msg);
    setPaginationObj(picklistTrackingReducer?.pickList?.pagination);

    setLoading(picklistTrackingReducer.loading);

    data.map((picklist) => {
      const formatDate = new Date(picklist.cd);
      let itemDescription = picklist.itemDescription;
      let len = desLength;

      const picklistObject = {
        privateKey: picklist.pickupListCode,
        documentType: picklist.documentType,
        documentNo: picklist.documentNumber,
        picklistCode: picklist.picklistCode,
        itemId: picklist.itemId,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        itemCodeDes: `${picklist.itemCode} / ${
          itemDescription ? itemDescription.substring(0, len) : ""
        }`,
        itemCode: picklist.itemCode,
        itemDescription: picklist.itemDescription,
        qty: `${picklist.qty}`,
        uom: `${picklist.uom ? picklist.uom : ""}`,

        source: picklist.source,
        destination: picklist.destination,
        status: picklist.status,
        remarks1: picklist.remarks1,
        remarks2: picklist.remarks2,
        remarks3: picklist.remarks3,
        remarks4: picklist.remarks4,
        status: (
          <button
            className={
              (picklist.status === 0 && `status-created`) ||
              (picklist.status === 1 && `status-warning`) ||
              (picklist.status === 2 && `status-info`) ||
              (picklist.status === 3 && `status-info`) ||
              (picklist.status === 4 && `status-info`) ||
              (picklist.status === 5 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(picklist.status === 0 && `Created`) ||
              (picklist.status === 1 && `Released`) ||
              (picklist.status === 2 && `Open`) ||
              (picklist.status === 3 && `Open`) ||
              (picklist.status === 4 && `Open`) ||
              (picklist.status === 5 && `Delivered`)}
          </button>
        ),
        status2:
          (picklist.status === 0 && `Created`) ||
          (picklist.status === 1 && `Released`) ||
          (picklist.status === 2 && `Open`) ||
          (picklist.status === 3 && `Open`) ||
          (picklist.status === 4 && `Open`) ||
          (picklist.status === 5 && `Delivered`),

        action: (
          <span style={{ display: "flex" }}>
            <Tooltip title="View SUID">
              {viewSuids && (
                <ViewButton
                  variant="gradient"
                  color="info"
                  // disabled={!viewGRN}
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewPicklistModal(picklist);
                    setSuidData(picklist?.suids);
                  }}
                  disabled={picklist.viewDisable ? true : false}
                >
                  <Icon> visibility_icon </Icon>
                </ViewButton>
              )}
            </Tooltip>

            {releasePicklistTracking &&
              (picklist.status === 1 || picklist.status === 0 ? (
                <ReleaseButton
                  tooltip="Release"
                  style={{
                    marginLeft: "0.3rem",
                  }}
                  type="button"
                  variant="gradient"
                  color="success"
                  iconOnly
                  onClick={() => {
                    setPickListId(picklist.picklistId);
                    handleOpenAllocateUserModal(picklist);
                    getUser(jwtToken);
                  }}
                >
                  {" "}
                  <Icon>forward</Icon>
                </ReleaseButton>
              ) : (
                <ReleaseButton
                  tooltip="Release"
                  disabled
                  style={{
                    marginLeft: "0.5rem",
                    background: "grey",
                    color: "white",
                    opacity: 0.5,
                  }}
                  type="button"
                  variant="gradient"
                  color="success"
                  iconOnly
                  onClick={() => {
                    setPickListId(picklist.picklistId);
                    handleOpenAllocateUserModal(picklist);
                  }}
                >
                  {" "}
                  <Icon>forward</Icon>
                </ReleaseButton>
              ))}

            <MDTypography
              variant="h6"
              fontWeight="regular"
              style={{ marginTop: "0.45rem", marginLeft: "0.75rem" }}
            >
              {picklist.userName}
            </MDTypography>
          </span>
        ),
      };
      tempPicklist.push(picklistObject);
    });
    viewPicklistTracking && setState({ ...state, rows: tempPicklist });
  }, [picklistTrackingReducer]);

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getItemByDocumentNoReducer.items.data || [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        code: item.itemCode, // Corrected property name here
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [getItemByDocumentNoReducer]);

  const [isSuccessAllocateItem, setIsSuccessAllocateItem] = useState(false);
  const [isErrorAllocateItem, setIsErrorAllocateItem] = useState(false);

  const [createErrorMsg, setCreateErrorMsg] = useState("");
  useEffect(() => {
    if (isSuccessAllocateItem) {
      setTimeout(() => {
        setIsSuccessAllocateItem(false);
      }, 3000);
    }
  }, [isSuccessAllocateItem]);

  useEffect(() => {
    if (isErrorAllocateItem) {
      setTimeout(() => {
        setIsErrorAllocateItem(false);
      }, 3000);
    }
  }, [isErrorAllocateItem]);

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getUserReducer.user.data ? getUserReducer.user.data : [];

    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.name,
        value: document.userId,
      });
    });
    setUserOptions(tempDocumentTypeOptions);
  }, [getUserReducer]);

  const [documentNumberOptions, setDocumentNumber] = useState([]);

  useEffect(() => {
    let tempDocumentNumber = [];
    let data = getDocumentNumberReducer.document.data
      ? getDocumentNumberReducer.document.data
      : [];
    data.map((document) => {
      tempDocumentNumber.push({
        label: document?.documentNumber,
        value: document?.documentNumber,
      });
    });

    setDocumentNumber(tempDocumentNumber);
  }, [getDocumentNumberReducer]);

  const [createPicklistModal, setCreatePicklistModal] = useState(false);

  const handleCreatePicklistModal = () => {
    setCreatePicklistModal(!createPicklistModal);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
    setActivityIdSelected("");
    setDocumentNumberSelected("");
    setSelectedItemCode("");
    setDocumentTypeSelected("");
  };

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
  };

  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    let tempActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempActivityOptions.push({
        label: activity.mvtName,
        value: activity.mvtId,
      });
    });
    setActivityOptions(tempActivityOptions);
  }, [getCreatedActivityReducer]);

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
    setActivityIdSelected("");
    setDocumentNumberSelected("");
    setSelectedItemCode("");
  };

  const handledocumentNumberChange = (selectedDocumentNo) => {
    setDocumentNumberSelected(selectedDocumentNo);
    setSelectedItemCode("");
  };
  const [selectedUser, setSelectedUser] = useState("");

  const handleUserChange = (selectedUser) => {
    setSelectedUser(selectedUser);
  };
  const [availabilityOfQty, setAvailabilityOfQty] = useState("");
  const handleCloseAllocateItemModal = () => {
    setCreatePicklistModal(!createPicklistModal);
    formik.setErrors({});
    setAvailabilityOfQty(0);
  };

  let AvailabilityOfQtyData = getAvailableQtyReducer.availableQty.data
    ? getAvailableQtyReducer.availableQty.data
    : [];

  let AvailableQty =
    AvailabilityOfQtyData.qty >= 1 ? AvailabilityOfQtyData.qty : 0;
  useEffect(() => {
    setAvailabilityOfQty(AvailableQty);
  }, [getAvailableQtyReducer]);
  const initialValues = {
    documentId: "",
    documentNumber: "",
    itemCode: "",
    acn: "",
    qty: "",
  };

  const validationSchema = Yup.object({
    documentId: Yup.object().required("Select document type"),
    documentNumber: Yup.object().required("Select document Number"),
    acn: Yup.object().required("Select Movement name"),
    itemCode: Yup.object().required("Select item Code"),
    qty: Yup.number()
      .positive("Please enter a positive number")
      .min(1, "Invalid qty")
      .max(AvailableQty, "Qty must be less than or equal to available qty")
      .required("Select the qty"),
  });

  const [buttonName, setButtonName] = useState("Submit");

  const onSubmit = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      documentTypeId: documentTypeSelected?.value,
      documentNumber: documentNumberSelected?.value,
      mvtId: activityIdSelected?.value,
      items: [
        {
          itemCode: selectedItemCode?.code,
          itemId: selectedItemCode?.value,
          qty: values.qty,
        },
      ],
    };

    let res = await createPickList(payload);

    if (res.status) {
      getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessAllocateItem(true);
      setButtonName("Submit");
    }
    if (!res.status) {
      setCreateErrorMsg(res?.data?.data?.msg);
      setIsErrorAllocateItem(true);
      setButtonName("Submit");
      handleCloseAllocateItemModal();
    }
    setDocumentTypeSelected("");
    setDocumentNumberSelected("");
    setActivityIdSelected("");
    setSelectedItemCode("");

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [isErrorForAllocate, setIsErrorForAllocate] = useState(false);
  const [isSuccessForAllocate, setIsSuccessForAllocate] = useState(false);

  const initialValuesForUser = {
    user: "",
  };

  const [releaseButtonName, setReleaseButtonName] = useState("Submit");
  const submitForUser = async (values) => {
    setReleaseButtonName("Please wait...");
    let payload = {
      userId: selectedUser.value,
      picklistId: pickListId,
    };

    let res = await releaseUser(payload, jwtToken);
    if (res.status) {
      setIsSuccessForAllocate(true);
      getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setIsSuccessForAllocate(false);
        setReleaseButtonName("Submit");
        handleCloseAllocateUserModal();
      }, 3000);
    }
    if (!res.status) {
      setErrorMsg(res?.data?.data?.msg);
      setIsErrorForAllocate(true);
      setReleaseButtonName("Submit");
    }
  };

  const formikForUser = useFormik({
    initialValues: initialValuesForUser,
    onSubmit: submitForUser,
  });
  const [suidTable, setSuidTable] = useState({
    columns: [
      { Header: "SUID", accessor: "suid", align: "left" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo" },
      { Header: "location Code", accessor: "locationCode", align: "left" },
      { Header: "QTY", accessor: "suqty", align: "left" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "Status", accessor: "status", align: "left" },
    ],
    rows: [],
  });

  const [loading2, setLoading2] = useState(true);

  const { columns: suidColumns, rows: suidRows } = suidTable;
  useEffect(() => {
    let tempGRN = [];
    let data = suidData ? suidData : [];
    setLoading2(suidData.loading);
    data.map((singleSuid) => {
      const formatDate = new Date(singleSuid.cd);
      const singleUser = {
        privateKey: singleSuid.suidId,
        locationId: singleSuid.locationId,
        suid: singleSuid.suid,
        suqty: singleSuid.qty,
        lotSerialNo: `${singleSuid.lotNumber ? singleSuid.lotNumber : "NA"} / ${
          singleSuid.serialNumber ? singleSuid.serialNumber : "NA"
        }`,
        locationCode: singleSuid.locationCode,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        status: (
          <button
            className={
              (singleSuid.status === 2 && `status-created`) ||
              (singleSuid.status === 3 && `status-info`) ||
              (singleSuid.status === 5 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(singleSuid.status === 2 && `Created`) ||
              (singleSuid.status === 3 && `Picked`) ||
              (singleSuid.status === 5 && `Delivered`)}
          </button>
        ),
      };
      tempGRN.push(singleUser);
    });
    setSuidTable({ ...suidTable, rows: tempGRN });
  }, [suidData]);

  const headers = [
    {
      label: "Pick List Code",
      key: "picklistCode",
    },
    {
      label: "Document No",
      key: "documentNo",
    },
    { label: "Item Code / Description", key: "itemCodeDes" },
    { label: "Item Qty", key: "qty" },
    { label: "Uom", key: "uom" },
    { label: "From Loc", key: "source" },
    { label: "To Loc", key: "destination" },
    { label: "Status", key: "status2" },
    { label: "Created Date", key: "createdDate" },
  ];
  const csvLink = {
    filename: "Picklist Template.csv",
    headers: headers,
    data: [],
  };
  const csvLink2 = {
    filename: "Picklist Data.csv",
    headers: headers,
    data: rows,
  };

  return (
    <>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox>
              <MDBox color="text" px={2} ml={-2} mb={1}>
                {createPicklistAccess && (
                  <CreateButton
                    tooltip="Create PickList"
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleCreatePicklistModal();
                      getDocumentType(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}
                {/* {downloadBom && (
                  <CSVLink {...csvLink}>
                    <DownloadButton
                      tooltip="Download Template"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="error"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadButton>
                  </CSVLink>
                )} */}
                {downloadAccess && (
                  <CSVLink {...csvLink2}>
                    <DownloadDataButton
                      tooltip="Download Picklist Data"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="success"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadDataButton>
                  </CSVLink>
                )}
              </MDBox>

              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                  hideColumns={[
                    "source",
                    "destination",
                    "remarks1",
                    "remarks2",
                    "remarks3",
                    "remarks4",
                  ]}
                />
              </MDBox>
            </MDBox>

            <Modal
              open={createPicklistModal}
              onClose={handleCloseAllocateItemModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Picklist
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAllocateItemModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Document Type{" "}
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="documentId"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "documentId" },
                              });
                            }}
                            value={documentTypeSelected}
                            options={documentTypeOptions}
                            onChange={(selectedOption) => {
                              handleDocumentTypeChange(selectedOption);
                              getDocumentNumber(
                                selectedOption?.value,
                                jwtToken
                              );
                              getCreatedActivity(
                                selectedOption?.value,
                                jwtToken
                              );
                              formik.setFieldValue(
                                "documentId",
                                selectedOption
                              );
                            }}
                          />
                          {formik.touched.documentId &&
                            formik.errors.documentId && (
                              <TextError msg={formik.errors.documentId} />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1} ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Document No
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={documentTypeSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="documentNumber"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "documentNumber" },
                              });
                            }}
                            value={documentNumberSelected}
                            options={documentNumberOptions}
                            onChange={(selectedOption) => {
                              handledocumentNumberChange(selectedOption);
                              getItemByDocumentNo(
                                selectedOption?.value,
                                jwtToken
                              );
                              formik.setFieldValue(
                                "documentNumber",
                                selectedOption
                              );
                            }}
                          />

                          {formik.touched.documentNumber &&
                            formik.errors.documentNumber && (
                              <TextError msg={formik.errors.documentNumber} />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Movement{" "}
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="acn"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "acn" },
                              });
                            }}
                            isDisabled={documentTypeSelected ? false : true}
                            value={activityIdSelected}
                            options={activityOptions}
                            onChange={(selectedOption) => {
                              handleActivityIdChange(selectedOption);
                              formik.setFieldValue("acn", selectedOption);
                            }}
                          />
                          {formik.touched.acn && formik.errors.acn && (
                            <TextError msg={formik.errors.acn} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Item Code/Des
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={documentNumberSelected ? false : true}
                            className="select-css-for-itemcode"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="itemCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "itemCode" },
                              });
                            }}
                            value={selectedItemCode}
                            options={itemCodeOptions}
                            onChange={(selectedOption) => {
                              handleItemCodeChange(selectedOption);
                              getAvailableQty(
                                selectedOption?.value,
                                activityIdSelected?.value,
                                jwtToken
                              );
                              formik.setFieldValue("itemCode", selectedOption);
                            }}
                          />

                          {formik.touched.itemCode &&
                            formik.errors.itemCode && (
                              <TextError msg={formik.errors.itemCode} />
                            )}
                        </MDBox>
                      </Grid>
                      <MDBox mb={2}>
                        <div style={{ display: "flex" }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Qty
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ marginLeft: "100px" }}
                          >
                            {" "}
                            Available Qty :-{" "}
                            <span style={{ color: "green" }}>
                              {availabilityOfQty}
                            </span>
                          </MDTypography>
                        </div>
                        <MDInput
                          type="number"
                          inputProps={{
                            min: 1,
                          }}
                          name="qty"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.qty}
                          error={
                            formik.touched.qty && formik.errors.qty && true
                          }
                        />
                        {formik.touched.qty && formik.errors.qty && (
                          <TextError msg={formik.errors.qty} />
                        )}
                      </MDBox>

                      <Grid item xs={12}>
                        <MDBox mt={1}>
                          <MDButton
                            color="info"
                            fullWidth
                            type="submit"
                            disabled={
                              !formik.isValid ||
                              activityIdSelected == "" ||
                              documentNumberSelected == "" ||
                              documentTypeSelected == "" ||
                              selectedItemCode == "" ||
                              formik.values.qty > AvailableQty ||
                              buttonName === "Please wait..."
                            }
                          >
                            {buttonName}
                          </MDButton>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12}>
                        <Collapse in={isErrorAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {createErrorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Picklist created successfully
                          </Alert>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
        <Modal
          open={allocateUserModal}
          onClose={handleCloseAllocateUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={styleForRelease}>
            <MDBox ml={3} mt={-1} display="flex" alignItems="center">
              <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                Release
              </MDTypography>
              <MDBox>
                <Tooltip title="Close">
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseAllocateUserModal}
                    style={{
                      background: "whitesmoke",
                      color: "black",
                      borderRadius: 5,
                      marginLeft: "-55px",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            </MDBox>
            <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

            <MDBox pt={1} pb={3} px={3}>
              <MDBox
                component="form"
                role="form"
                onSubmit={formikForUser.handleSubmit}
              >
                <MDBox mb={1}>
                  <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                    {" "}
                    User (Optional)
                  </MDTypography>

                  <Select
                    isClearable
                    className="select-css"
                    maxMenuHeight={130}
                    placeholder="Choose one..."
                    name="user"
                    onBlur={() => {
                      formikForUser.handleBlur({
                        target: { name: "user" },
                      });
                    }}
                    value={selectedUser}
                    options={userOptions}
                    onChange={(selectedOption) => {
                      handleUserChange(selectedOption);
                      formikForUser.setFieldValue("user", selectedOption);
                    }}
                  />
                  {formikForUser.touched.user && formikForUser.errors.user && (
                    <TextError msg={formikForUser.errors.user} />
                  )}
                </MDBox>

                <MDBox>
                  <Collapse in={isErrorForAllocate}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorForAllocate(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg}
                    </Alert>
                  </Collapse>
                  <Collapse in={isSuccessForAllocate}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorForAllocate(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Picklist released Successfully
                    </Alert>
                  </Collapse>
                </MDBox>

                <MDBox mt={3}>
                  <MDButton
                    color="success"
                    fullWidth
                    type="submit"
                    disabled={
                      !formikForUser.isValid ||
                      releaseButtonName === "Please wait..."
                    }
                  >
                    {releaseButtonName}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDCard>
        </Modal>

        <Modal
          open={openPicklistViewModal}
          onClose={handleCloseViewPicklistModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={styleForPicklistViewModal}>
            <MDBox ml={3} mt={-1} display="flex" alignItems="center">
              <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                Items
              </MDTypography>

              <MDBox>
                <span>
                  <Tooltip title="Close">
                    <IconButton
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                        marginBottom: "5px",
                      }}
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseViewPicklistModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </MDBox>
            </MDBox>
            <hr style={{ marginBottom: "1rem" }} />
            <div style={{ display: "flex" }}>
              <MDTypography
                variant="h5"
                style={{ marginLeft: "2rem" }}
                fontSize={{ xs: "14px", lg: "18px" }}
              >
                Document Number:{" "}
                <span style={{ fontWeight: 400 }}>
                  {singlePicklist.documentNumber}
                </span>
              </MDTypography>
              <MDTypography
                variant="h5"
                style={{ marginLeft: "8rem", marginBottom: "1rem" }}
                fontSize={{ xs: "14px", lg: "18px" }}
              >
                Picklist Code:{" "}
                <span style={{ fontWeight: 400 }}>
                  {singlePicklist.picklistCode}
                </span>
              </MDTypography>
            </div>

            <MDBox>
              {!loading2 ? (
                <DataTable
                  table={{ columns: suidColumns, rows: suidRows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
          </MDCard>
        </Modal>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    picklistTrackingReducer: state.getPickListTrackingReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    getDocumentNumberReducer: state.getDocumentNumberReducer,
    getItemByDocumentNoReducer: state.getItemByDocumentNoReducer,
    getUserReducer: state.getUserReducer,
    allGRN: state.allGRN,
    getAvailableQtyReducer: state.getAvailableQtyReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPickListTracking: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getUser: (jwtToken) => dispatch(getUser(jwtToken)),
    getDocumentNumber: (documentTypeSelected, jwtToken) =>
      dispatch(getDocumentNumber(documentTypeSelected, jwtToken)),
    getItemByDocumentNo: (documentNumberSelected, jwtToken) =>
      dispatch(getItemByDocumentNo(documentNumberSelected, jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    releaseUser: (payload, jwtToken) =>
      dispatch(releaseUser(payload, jwtToken)),
    getAvailableQty: (itemId, mvtId, jwtToken) =>
      dispatch(getAvailableQty(itemId, mvtId, jwtToken)),
    createPickList: (payload, jwtToken) =>
      dispatch(createPickList(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PicklistTracking);
