export const GET_UNPLANNED_ISSUE_REQUEST = "GET_UNPLANNED_ISSUE_REQUEST";
export const GET_UNPLANNED_ISSUE_SUCCESS = "GET_UNPLANNED_ISSUE_SUCCESS";
export const GET_UNPLANNED_ISSUE_FAILURE = "GET_UNPLANNED_ISSUE_FAILURE";


export const GET_ACC_USAGE_REQUEST = "GET_ACC_USAGE_REQUEST";
export const GET_ACC_USAGE_SUCCESS = "GET_ACC_USAGE_SUCCESS";
export const GET_ACC_USAGE_FAILURE = "GET_ACC_USAGE_FAILURE";

export const GET_CC_USAGE_REQUEST = "GET_CC_USAGE_REQUEST";
export const GET_CC_USAGE_SUCCESS = "GET_CC_USAGE_SUCCESS";
export const GET_CC_USAGE_FAILURE = "GET_CC_USAGE_FAILURE";

export const CREATE_UNPLANNED_ISSUE_REQUEST = "CREATE_UNPLANNED_ISSUE_REQUEST";
export const CREATE_UNPLANNED_ISSUE_SUCCESS = "CREATE_UNPLANNED_ISSUE_SUCCESS";
export const CREATE_UNPLANNED_ISSUE_FAILURE = "CREATE_UNPLANNED_ISSUE_FAILURE";

export const UPDATE_RELATION_LOCATION_REQUEST =
  "UPDATE_RELATION_LOCATION_REQUEST";
export const UPDATE_RELATION_LOCATION_SUCCESS =
  "UPDATE_RELATION_LOCATION_SUCCESS";
export const UPDATE_RELATION_LOCATION_FAILURE =
  "UPDATE_RELATION_LOCATION_FAILURE";

export const GET_ITEMSBYLOCATION_REQUEST = "GET_ITEMSBYLOCATION_REQUEST";
export const GET_ITEMSBYLOCATION_SUCCESS = "GET_ITEMSBYLOCATION_SUCCESS";
export const GET_ITEMSBYLOCATION_FAILURE = "GET_ITEMSBYLOCATION_FAILURE";

export const GET_SUIDS_BY_LOCATION_AND_ITEM_REQUEST =
  "GET_SUIDS_BY_LOCATION_AND_ITEM_REQUEST";
export const GET_SUIDS_BY_LOCATION_AND_ITEM_SUCCESS =
  "GET_SUIDS_BY_LOCATION_AND_ITEM_SUCCESS";
export const GET_SUIDS_BY_LOCATION_AND_ITEM_FAILURE =
  "GET_SUIDS_BY_LOCATION_AND_ITEM_FAILURE";

export const GET_SUIDS_BY_DOCUMENT_NO_REQUEST =
  "GET_SUIDS_BY_DOCUMENT_NO_REQUEST";
export const GET_SUIDS_BY_DOCUMENT_NO_SUCCESS =
  "GET_SUIDS_BY_DOCUMENT_NO_SUCCESS";
export const GET_SUIDS_BY_DOCUMENT_NO_FAILURE =
  "GET_SUIDS_BY_DOCUMENT_NO_FAILURE";
