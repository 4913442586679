/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getFailingMinimumTop10,
  getSubModulesByRoleId,
} from "../../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const FailingMinimum = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getFailingMinimumTop10,

  getFailingMinimumTop10Reducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getFailingMinimumTop10(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemStatusReportModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "95815117-55fd-40be-ab8f-a9407d9ae1df"
  );

  let viewItemStatus = itemStatusReportModule
    ? itemStatusReportModule.actionId.includes(
        "e7b51078-d27b-4bf2-9707-efa327848bad"
      )
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "category Code", accessor: "categoryCode" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      { Header: "UOM", accessor: "uom" },
      { Header: "Control Type", accessor: "controlType" },
      { Header: "movement Type", accessor: "movementType" },
      { Header: "count", accessor: "count" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempFailingMinimumTop10 = [];
    let data = getFailingMinimumTop10Reducer.failingMinimumTop10.data
      ? getFailingMinimumTop10Reducer.failingMinimumTop10.data
      : [];

    setLoading(getFailingMinimumTop10Reducer.loading);
    data?.map((FailingMinimumTop10) => {
      let des = FailingMinimumTop10?.itemDescription;
      let len = desLength;
      const formatDate = new Date(FailingMinimumTop10.cd);
      const failingMinimumTop10Object = {
        itemCode: FailingMinimumTop10.itemCode,
        itemCodeDes: `${FailingMinimumTop10.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        controlType:
          (FailingMinimumTop10.controlType === 0 && `No Control`) ||
          (FailingMinimumTop10.controlType === 1 && `Serial Control`) ||
          (FailingMinimumTop10.controlType === 2 && `Lot Control`),
        movementType: FailingMinimumTop10.movementType,
        categoryCode: FailingMinimumTop10.categoryCode,
        count: FailingMinimumTop10.count,
        uom: FailingMinimumTop10.uom,
      };
      tempFailingMinimumTop10.push(failingMinimumTop10Object);
    });
    // viewFG &&
    setState({ ...state, rows: tempFailingMinimumTop10 });
  }, [getFailingMinimumTop10Reducer]);

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "itemDesc",
      key: "itemDesc",
    },
    { label: "categoryCode", key: "categoryCode" },
    { label: "controlType", key: "controlType" },
    { label: "qty", key: "qty" },
  ];

  const csvLink = {
    filename: "Item Status Report.csv",
    headers: headers,
    data: rows,
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-3}
              mt={-4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", lg: "row" }}
                    mt={2}
                  ></MDBox>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
    // </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getFailingMinimumTop10Reducer: state.getFailingMinimumTop10Reducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getFailingMinimumTop10: (jwtToken) =>
      dispatch(getFailingMinimumTop10(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(FailingMinimum);
