import {
  GET_FGMASTER_REQUEST,
  GET_FGMASTER_SUCCESS,
  GET_FGMASTER_FAILURE,
  CREATE_FGMASTER_REQUEST,
  CREATE_FGMASTER_SUCCESS,
  CREATE_FGMASTER_FAILURE,
  EDIT_FGMASTER_REQUEST,
  EDIT_FGMASTER_SUCCESS,
  EDIT_FGMASTER_FAILURE,
  UPLOAD_CSV_FOR_FG_REQUEST,
  UPLOAD_CSV_FOR_FG_SUCCESS,
  UPLOAD_CSV_FOR_FG_FAILURE,
} from "./fgMasterTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getFgMasterRequest = () => {
  return {
    type: GET_FGMASTER_REQUEST,
  };
};

const getFgMasterSuccess = (payload) => {
  return {
    type: GET_FGMASTER_SUCCESS,
    payload: payload,
  };
};

const getFgMasterFailure = (error) => {
  return {
    type: GET_FGMASTER_FAILURE,
    payload: error,
  };
};

const getFgMaster = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getFgMasterRequest());
    let url = `${baseUrl}/model/get_all?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getFgMasterSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getFgMasterFailure(err.message));
      });
  };
};

const editFgMasterRequest = () => {
  return {
    type: EDIT_FGMASTER_REQUEST,
  };
};

const editFgMasterSuccess = (payload) => {
  return {
    type: EDIT_FGMASTER_SUCCESS,
    payload: payload,
  };
};

const editFgMasterFailure = (error) => {
  return {
    type: EDIT_FGMASTER_FAILURE,
    payload: error,
  };
};

const editFgMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editFgMasterRequest());
    let url = `${baseUrl}/model/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editFgMasterSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editFgMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createFgMasterRequest = () => {
  return {
    type: CREATE_FGMASTER_REQUEST,
  };
};

const createFgMasterSuccess = (payload) => {
  return {
    type: CREATE_FGMASTER_SUCCESS,
    payload: payload,
  };
};

const createFgMasterFailure = (error) => {
  return {
    type: CREATE_FGMASTER_FAILURE,
    payload: error,
  };
};

const createFgMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createFgMasterRequest());
    let url = `${baseUrl}/model `;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createFgMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createFgMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadCsvForFgMasterRequest = () => {
  return {
    type: UPLOAD_CSV_FOR_FG_REQUEST,
  };
};

const uploadCsvForFgMasterSuccess = (payload) => {
  return {
    type: UPLOAD_CSV_FOR_FG_SUCCESS,
    payload: payload,
  };
};

const uploadCsvForFgMasterFailure = (error) => {
  return {
    type: UPLOAD_CSV_FOR_FG_FAILURE,
    payload: error,
  };
};

const uploadCsvForFgMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvForFgMasterRequest());
    let url = `${baseUrl}/model/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvForFgMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvForFgMasterFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getFgMaster, editFgMaster, createFgMaster, uploadCsvForFgMaster };
