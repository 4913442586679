/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
// Formik and Yup

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Functions from store
import {
  getConsumptionPending,
  getDocumentType,
  getSubModulesByRoleId,
} from "../../store";

import { CSVLink } from "react-csv";

// config file

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

import Config from "../../config/index";
const cookies = new Cookies();

const ConsumptionPending = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getDocumentType,
  getDocumentTypeReducer,
  getDocumenTypeTurnReducer,
  getConsumptionPending,
  getConsumptionPendingReducer,
  getDocumentTypeTurnAroundTime,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getConsumptionPending(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let documentTypeTurnAroundModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ac101a0a-1f90-47a7-86c4-99b304f2cf24"
  );

  let viewAccess = documentTypeTurnAroundModule
    ? documentTypeTurnAroundModule.actionId.includes(
        "6ab9e198-e4a5-48ae-aac0-975bd8d82ee0"
      )
    : null;

  const navigate = useNavigate();

  const desLength = Config.descriptionLength;
  const [state, setState] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Work Order No", accessor: "workOrderNo" },
      { Header: "shop", accessor: "shop" },
      { Header: "Serial/Lot No", accessor: "lotSerialNo" },
      { Header: "Control Type", accessor: "controlType" },
      { Header: "Station Name", accessor: "stationName" },
      { Header: "FG Model Code", accessor: "fgModelCode" },
      { Header: "Requested Qty", accessor: "requestedQty" },
      { Header: "Vin No", accessor: "vinNo" },
      { Header: "SFG/FG", accessor: "sfgFg" },
      { Header: "Remarks", accessor: "remarks" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getConsumptionPendingReducer.consumptionPending
      ? getConsumptionPendingReducer.consumptionPending
      : [];
    setLoading(getConsumptionPendingReducer.loading);
    data?.map((finishedGoods) => {
      let itemDescription = finishedGoods.itemDescription;
      let len = desLength;

      const formatDate = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        workOrderNo: finishedGoods.woNo,
        shop: finishedGoods.shop,
        requestedQty: finishedGoods.requiredQty,
        vinNo: finishedGoods.vinNo === "null" ? "NA" : finishedGoods.vinNo,
        fgModelCode: finishedGoods.fgModelCode,
        stationName: finishedGoods.station,
        remarks: finishedGoods.remarks,
        sfgFg:
          (finishedGoods.isSFG === 0 && `FG`) ||
          (finishedGoods.isSFG === 1 && `SFG`),
        lotSerialNo: `${
          finishedGoods.serialNumber == "null"
            ? "NA"
            : finishedGoods.serialNumber
        } / ${finishedGoods.lotNumber ? finishedGoods.lotNumber : "NA"}`,
        controlType:
          (finishedGoods.controlType === 0 && `No Control`) ||
          (finishedGoods.controlType === 1 && `Serial Control`) ||
          (finishedGoods.controlType === 2 && `Lot Control`),
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        itemCode: finishedGoods.itemCode,
        itemDescription: finishedGoods.itemDescription,
        itemCodeDes: `${finishedGoods.itemCode} / ${
          itemDescription ? itemDescription.substring(0, len) : ""
        }`,
        turnAroundTime: finishedGoods.turnAroundTime,
        // documentStatus: (
        //   <button
        //     className={
        //       (finishedGoods.documentStatus === 1 && `status-success`) ||
        //       (finishedGoods.documentStatus === 2 && `status-info`) ||
        //       (finishedGoods.documentStatus === 3 && `status-warning`)
        //     }
        //     style={{ width: "5.5rem" }}
        //   >
        //     {(finishedGoods.documentStatus === 1 && `Open`) ||
        //       (finishedGoods.documentStatus === 2 && `In-Progress`) ||
        //       (finishedGoods.documentStatus === 3 && `Closed`)}
        //   </button>
        // ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewAccess && setState({ ...state, rows: tempFinishedGoods });
  }, [getConsumptionPendingReducer]);

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
  };

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  const headers = [
    { label: "itemCode", key: "itemCode" },
    { label: "itemDescription", key: "itemDescription" },
    { label: "workOrderNo", key: "workOrderNo" },
    { label: "shop", key: "shop" },
    { label: "lotSerialNo", key: "lotSerialNo" },
    { label: "controlType", key: "controlType" },
    { label: "stationName", key: "stationName" },
    { label: "fgModelCode", key: "fgModelCode" },
    { label: "requestedQty", key: "requestedQty" },
    { label: "vinNo", key: "vinNo" },
    { label: "SFG/FG", key: "sfgFg" },
    { label: "remarks", key: "remarks" },
    { label: "createdDate", key: "createdDate" },
  ];

  const csvLink = {
    filename: "Consumption Pendind Data.csv",
    headers: headers,
    data: rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-2}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8} lg={8}>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <MDBox style={{ marginTop: "25px", marginLeft: "8px" }}>
                        <CSVLink {...csvLink}>
                          <DownloadDataButton
                            type="button"
                            variant="outlined"
                            color="success"
                            iconOnly
                            tooltip="Download All Data"
                          >
                            <Icon>download</Icon>
                          </DownloadDataButton>
                        </CSVLink>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    // hideColumns={["", "asnCode", "vendorCodeName"]}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getConsumptionPendingReducer: state.getConsumptionPendingReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getConsumptionPending: (jwtToken) =>
      dispatch(getConsumptionPending(jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ConsumptionPending);
