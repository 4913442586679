import {
  GET_COMPONENTS_REQUEST,
  GET_COMPONENTS_SUCCESS,
  GET_COMPONENTS_FAILURE,
} from "./componentsClientsTypes";

import axios from "axios";
import Config from "../../../config/index";
let userManagementUrl = Config.userManagementUrl;

const getComponentsRequest = () => {
  return {
    type: GET_COMPONENTS_REQUEST,
  };
};

const getComponentsSuccess = (payload) => {
  return {
    type: GET_COMPONENTS_SUCCESS,
    payload: payload,
  };
};

const getComponentsFaliure = (error) => {
  return {
    type: GET_COMPONENTS_FAILURE,
    payload: error,
  };
};

const getComponents = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getComponentsRequest());
    let url = `${userManagementUrl}/components/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getComponentsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getComponentsFaliure(err.message));
      });
  };
};

export { getComponents };
