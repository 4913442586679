/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

//  some date function

import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CancelIcon from "@mui/icons-material/Clear";
// Functions from store
import {
  getUnplannedIssues,
  getSubModulesByRoleId,
  createUnplannedIssue,
  getDocumentType,
  getCreatedActivity,
  getWarehouses,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getAvailableQtyForTo,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  getRelationLocation,
  getItemsByLocation,
  getSuidsByLocationAndItem,
  getSuidsByDocumentNo,
  createPicklistForUnplannedIssue,
  getAccUsage,
  getCcUsage,
  getApprovedMaterialRequest,
  releasePicklistForApprovedRequest,
  getSuidsByDocumentNoForApprove,
} from "../../../store";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import StandardButton from "components/standardized-components/StandardButton";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1200, xl: 1200 },
  overflow: "auto",
  height: "80%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};
const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 380, md: 800, lg: 1400, xl: 1400 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};
const styleForCategoryModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 480, md: 700, lg: 1600, xl: 1750 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForMovement = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const ApprovedMaterialRequest = ({
  getUnplannedIssues,
  releasePicklistForApprovedRequest,
  getUnplannedIssueReducer,
  createUnplannedIssue,
  getApproveMaterialRequestReducer,
  getItemsByLocation,
  getItemsByLocationReducer,
  getDocumentType,
  getCreatedActivity,
  getCreatedActivityReducer,
  getDocumentTypeReducer,
  getSuidsByDocumentNoForApprove,
  getSuidsForApproveMaterialRequestReducer,
  getApprovedMaterialRequest,
  getSuidsByLocationAndItem,
  getSuidsByLocationAndItemReducer,
  getSuidsByDocumentNo,
  getSuidsByDocumentNoReducer,
  getWarehouses,
  warehouses,
  createPicklistForUnplannedIssue,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevel,
  getLevel4ByLevelReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAvailableQtyForTo,
  availableQtyState,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  getRelationLocation,
  level1ByLevelSrcReducer,
  level2ByLevelSrcReducer,
  level3ByLevelSrcReducer,
  level4ByLevelSrcReducer,
  getRelationLocationReducer,
  getAccTypeReducer,
  getCcTypeReducer,
  getCcUsage,
  getAccUsage,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let printerConfig = cookies.get("printerConfigForWarehouse");

  let printerId = loginDetails.data.printerId;

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let ApprovedMaterialModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "00686474-46b0-4f03-bc5b-ea5ebd4cdacc"
  );

  let viewSuidIssue = ApprovedMaterialModule
    ? ApprovedMaterialModule.actionId.includes(
        "162ec390-c887-43e1-976e-4e7ab772d2df"
      )
    : null;

  let viewAccess = ApprovedMaterialModule
    ? ApprovedMaterialModule.actionId.includes(
        "453eb0da-2cb1-44de-b3a5-e1c17875403a"
      )
    : null;

  let PicklistAccess = ApprovedMaterialModule
    ? ApprovedMaterialModule.actionId.includes(
        "356689b5-16e5-45ce-9a4b-a24ece4cbd82"
      )
    : null;

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  useEffect(() => {
    getRelationLocation(jwtToken);
  }, []);
  useEffect(() => {
    getApprovedMaterialRequest(jwtToken);
  }, []);

  const [singleLineItem, setSingleLineItem] = useState({});

  const [createdActivitySelected, setCreatedActivitySelected] = useState("");

  const [createPicklistModal, setCreatePicklistModal] = useState(false);
  const handleCreatePicklistModal = (data = {}) => {
    setCreatePicklistModal(true);
    setSingleLineItem(data);
    setCreatedActivitySelected("");
  };

  const handleCloseCreatePicklistModal = (data = {}) => {
    setCreatePicklistModal(false);
  };

  const data = getRelationLocationReducer.locations?.data || [];
  const [accTypeSelected, setAccTypeSelected] = useState("");
  useEffect(() => {
    getUnplannedIssues(jwtToken);
  }, []);
  useEffect(() => {
    getAccUsage(jwtToken);
  }, []);
  useEffect(() => {
    getCcUsage(accTypeSelected?.value, jwtToken);
  }, [accTypeSelected]);

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNo", align: "left" },
      { Header: "Warehouse", accessor: "warehouse", align: "left" },
      { Header: "MR Created User", accessor: "createdUser", align: "left" },
      { Header: "Ramco Approved", accessor: "ramcoApproved", align: "left" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "SUID", accessor: "suid", align: "left" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [categoryItemTable, setCategoryItemTable] = useState({
    columns: [
      { Header: "GRN No", accessor: "grnNumber" },
      { Header: "GRN Line No", accessor: "grnLineNumber" },
      { Header: "Item Code/Des", accessor: "itemCodeDes", align: "left" },
      { Header: "Suid", accessor: "suid", align: "left" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo" },
      { Header: "Location", accessor: "locationCode" },
      { Header: "Issue Qty", accessor: "qty", align: "left" },
      { Header: "Created Date", accessor: "createdDate" },
      { Header: "Delivery Status", accessor: "deliveryStatus" },
    ],
    rows: [],
  });

  const { columns: categoryColumns, rows: categoryRows } = categoryItemTable;

  const [categoryItems, setCategoryItems] = useState("");

  useEffect(() => {
    let tempCategoryItemsObj = [];

    let data = getSuidsForApproveMaterialRequestReducer.approvedRequests
      ? getSuidsForApproveMaterialRequestReducer.approvedRequests
      : [];
    data.map((data) => {
      let des = data?.itemDescription;
      let len = desLength;
      const formatDate = new Date(data.cd);
      const categoryItemsObj = {
        suid: data.suid,
        itemCodeDes: `${data.itemCode} / ${des ? des.substring(0, len) : ""}`,
        qty: data.qty,
        locationCode: data.locationCode,
        grnNumber: data.grnNumber,
        grnLineNumber: data.grnLineNumber,
        // deliveryStatus: data.isPickup===1?"MR Issued":"Not Picked",
        deliveryStatus: (
          <button
            className={
              (data.isPickup === 1 && `status-success`) ||
              (data.isPickup === 0 && `status-error`)
            }
            style={{ width: "5.5rem" }}
          >
            {(data.isPickup === 0 && `Not Picked`) ||
              (data.isPickup === 1 && `MR Issued`)}
          </button>
        ),
        lotSerialNo: `${data.lotNumber ? data.lotNumber : "NA"} / ${
          data.serialNumber ? data.serialNumber : "NA"
        }`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
      };

      tempCategoryItemsObj.push(categoryItemsObj);
    });

    viewSuidIssue &&
      setCategoryItemTable({
        ...categoryItemTable,
        rows: tempCategoryItemsObj,
      });
  }, [getSuidsForApproveMaterialRequestReducer]);
  const [openCategoryLineItemsModal, setOpenCategoryLineItemsModal] =
    useState(false);

  const handleOpenCategoryLineItemsModal = () => {
    setOpenCategoryLineItemsModal(true);
  };

  const handleCloseCategoryLineItemsModal = () => {
    setOpenCategoryLineItemsModal(false);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempUnplannedIssues = [];
    let data = getApproveMaterialRequestReducer.approvedRequests
      ? getApproveMaterialRequestReducer.approvedRequests
      : [];
    setLoading(getApproveMaterialRequestReducer.loading);
    data.map((to) => {
      const formatDate = new Date(to.mrcd);
      // let des = to?.itemDescription;
      // let len = desLength;
      const toObject = {
        documentNo: to.documentNumber,
        warehouse: to.warehouse,
        // itemCodeDes: `${to.itemCode} / ${des ? des.substring(0, len) : ""}`,
        createdUser: to.userName,
        // totalQty: to.totalQty,
        ramcoApproved: to.status === 0 ? "Approved" : "Not Approved",
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        suid: (
          <>
            <Tooltip title="View Suids">
              <MDButton
                // disabled={to.suids.length == 0}
                variant="gradient"
                color="info"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  handleOpenCategoryLineItemsModal(to);
                  getSuidsByDocumentNoForApprove(to.documentNumber, jwtToken);
                }}
              >
                <Icon>visibility_icon</Icon>
              </MDButton>
            </Tooltip>
            {PicklistAccess && (
              <StandardButton
                tooltip="Create Picklist"
                disabled={
                  // !createPickListAccess ||
                  (to.isDisabled === 1 && to.status === 0) ||
                  (to.isDisabled === 0 && to.status === 1)
                }
                // style={{ marginLeft: "10px" }1
                color="warning"
                variant="gradient"
                // disabled={to.isDisabled === 1}
                iconOnly
                icon="rotate_right"
                type="button"
                onClick={() => {
                  handleCreatePicklistModal(to);
                  getCreatedActivity(13, jwtToken);
                }}
                style={{
                  // marginLeft: "5rem",
                  marginLeft: to.isDisabled === 1 ? "0.5rem" : "0.5rem",
                  background: to.isDisabled === 1 ? "grey" : undefined,
                  color: to.isDisabled === 1 ? "white" : undefined,
                  opacity: to.isDisabled === 1 ? 0.5 : undefined,
                }}
              >
                <Icon>rotate_right</Icon>
              </StandardButton>
            )}
          </>
        ),
      };
      viewAccess && tempUnplannedIssues.push(toObject);
    });
    setState({ ...state, rows: tempUnplannedIssues });
  }, [getApproveMaterialRequestReducer]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getItemsByLocationReducer.itemsByLocation.data
      ? getItemsByLocationReducer.itemsByLocation.data
      : [];

    data.map((item) => {
      tempItemCodeOptions.push({
        label: `${item.itemCode}/${item.itemDescription}`,
        value: item.itemId,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [getItemsByLocationReducer]);
  const [selectedItemCode, setSelectedItemCode] = useState("");

  const [selectedLocationCode, setSelectedLocationCode] = useState("");

  const [suidOptions, setSuidOptions] = useState([]);

  useEffect(() => {
    let tempSuidOptions = [];
    let data = getSuidsByLocationAndItemReducer.suidsByLocationAndItem.data
      ? getSuidsByLocationAndItemReducer.suidsByLocationAndItem.data
      : [];

    data.map((suid) => {
      tempSuidOptions.push({
        label: suid.suid,
        value: suid.suid,
        sLocation: suid.locationCode,
        qty: suid.qty,
      });
    });
    setSuidOptions(tempSuidOptions);
  }, [getSuidsByLocationAndItemReducer]);

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentCode,
        documentId: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  const [accTypeOptions, setAccTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getAccTypeReducer.accType.data
      ? getAccTypeReducer.accType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.AC_Usage,
        value: document.AC_Usage,
      });
    });
    setAccTypeOptions(tempDocumentTypeOptions);
  }, [getAccTypeReducer]);

  const [ccTypeSelected, setCcTypeSelected] = useState("");

  const [ccTypeOptions, setCcTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getCcTypeReducer.ccType.data ? getCcTypeReducer.ccType.data : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.CC_Usage,
        value: document.CC_Usage,
        documentId: document.documentId,
      });
    });
    setCcTypeOptions(tempDocumentTypeOptions);
  }, [getCcTypeReducer]);

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
  };

  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
    setActivityIdSelected("");
  };
  const handleAccTypeChange = (selectedAccType) => {
    setAccTypeSelected(selectedAccType);
  };
  const handleCcTypeChange = (selectedCcType) => {
    setCcTypeSelected(selectedCcType);
  };

  const initialValues = {
    documentType: "",
    documentNumber: "",
    accType: "",
    ccType: "",

    sourceLocation: "",
    destinationLocation: "",
    sWarehouse: "",
    sZone: "",
    sSection: "",
    sRack: "",
    sLocation: "",
    itemsForUnplannedIssue: [
      {
        itemId: "",
        qty: "",
      },
    ],

    dWarehouse: "",
    dZone: "",
    dSection: "",
    dRack: "",
    dLocation: "",
  };

  const validationSchema = Yup.object({
    documentNumber: Yup.string().required("Enter the document no!"),
  });

  const UnplannedIssueSuccessNotification = () =>
    toast.success("Unplanned issue created successfully!", {
      position: "top-center",
      autoClose: 2000,
    });

  const UnplannedIssueFailureNotification = () =>
    toast.error(errorMsgForCreate, {
      position: "top-center",
      autoClose: 2000,
    });

  const [buttonName, setButtonName] = useState("Submit");

  const [createButtonName, setCreateButtonName] = useState("Submit");

  const onSubmitForCreateUnplannedIssue = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      documentNumber: values.documentNumber,
      ac_usage: accTypeSelected.value,
      cc_usage: ccTypeSelected.value,
      documentCode: documentTypeSelected.value
        ? documentTypeSelected.value
        : "",
      suids: values.itemsForUnplannedIssue,
    };

    let res = await createUnplannedIssue(payload, jwtToken);

    if (res.status) {
      // setIsSuccessCreatedUnplannedIssue(true);
      UnplannedIssueSuccessNotification();
      getUnplannedIssues(jwtToken);
      resetForm();
      setSwarehouseSelected("");
      setSzoneSelected("");
      setSsectionSelected("");
      setSrackSelected("");
      setSlocationSelected("");
      setDwarehouseSelected("");
      setDzoneSelected("");
      setDsectionSelected("");
      setDrackSelected("");
      setDlocationSelected("");
      setDocumentTypeSelected("");
      setActivityIdSelected("");
      setTimeout(() => {
        handleCreateUnplannedIssueModal();
        setButtonName("Submit");
      }, 2500);
    }

    if (!res.status) {
      // setIsErrorCreatedUnplannedIssue(true);
      setErrorMsgForCreate(res?.data?.data?.msg);
      UnplannedIssueFailureNotification();
      setButtonName("Submit");
    }
  };

  const [isSuccessCreatedUnplannedIssue, setIsSuccessCreatedUnplannedIssue] =
    useState(false);
  const [isErrorCreatedUnplannedIssue, setIsErrorCreatedUnplannedIssue] =
    useState(false);

  useEffect(() => {
    if (isErrorCreatedUnplannedIssue) {
      setTimeout(() => {
        setIsErrorCreatedUnplannedIssue(false);
      }, 3000);
    }
  }, [isErrorCreatedUnplannedIssue]);

  useEffect(() => {
    if (isSuccessCreatedUnplannedIssue) {
      setTimeout(() => {
        setIsSuccessCreatedUnplannedIssue(false);
      }, 3000);
    }
  }, [isSuccessCreatedUnplannedIssue]);

  const [createUnplannedIssueModal, setCreateUnplannedIssueModal] =
    useState(false);
  const handleCreateUnplannedIssueModal = () => {
    setItemCodeSelected("");

    setCreateUnplannedIssueModal(!createUnplannedIssueModal);
  };

  const [itemCodeSelected, setItemCodeSelected] = useState("");

  const [sWarehouseSelected, setSwarehouseSelected] = useState("");
  const [sZoneSelected, setSzoneSelected] = useState("");
  const [sSectionSelected, setSsectionSelected] = useState("");
  const [sRackSelected, setSrackSelected] = useState("");
  const [sLocationSelected, setSlocationSelected] = useState("");

  const handleSwarehouseChange = (selectedData) => {
    setSwarehouseSelected(selectedData);
    setSzoneSelected("");
    setSsectionSelected("");
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSzoneChange = (selectedRack) => {
    setSzoneSelected(selectedRack);
    setSsectionSelected("");
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSsectionChange = (selectedRack) => {
    setSsectionSelected(selectedRack);
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSrackChange = (selectedRack) => {
    setSrackSelected(selectedRack);
    setSlocationSelected("");
  };

  const handleSlocationChange = (selectedLocation) => {
    setSlocationSelected(selectedLocation);
  };

  const [dWarehouseSelected, setDwarehouseSelected] = useState("");
  const [dZoneSelected, setDzoneSelected] = useState("");
  const [dSectionSelected, setDsectionSelected] = useState("");
  const [dRackSelected, setDrackSelected] = useState("");
  const [dLocationSelected, setDlocationSelected] = useState("");

  const handleDwarehouseChange = (selectedData) => {
    setDwarehouseSelected(selectedData);
    setDzoneSelected("");
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDzoneChange = (selectedRack) => {
    setDzoneSelected(selectedRack);
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDsectionChange = (selectedRack) => {
    setDsectionSelected(selectedRack);
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDrackChange = (selectedRack) => {
    setDrackSelected(selectedRack);
    setDlocationSelected("");
  };

  const handleDlocationChange = (selectedLocation) => {
    setDlocationSelected(selectedLocation);
  };

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
        key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  // Source Options
  const [level1OptionsSrc, setLevel1OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level1ByLevelSrcReducer.level1ByLevel.data
      ? level1ByLevelSrcReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1OptionsSrc(tempLevelOptions);
  }, [level1ByLevelSrcReducer]);

  const [level2OptionsSrc, setLevel2OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level2ByLevelSrcReducer.level2ByLevel.data
      ? level2ByLevelSrcReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2OptionsSrc(tempLevelOptions);
  }, [level2ByLevelSrcReducer]);

  const [level3OptionsSrc, setLevel3OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level3ByLevelSrcReducer.level3ByLevel.data
      ? level3ByLevelSrcReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel3OptionsSrc(tempLevelOptions);
  }, [level3ByLevelSrcReducer]);

  const [isSuccessCreatedActivity, setIsSuccessCreatedActivity] =
    useState(false);
  const [isErrorCreatedActivity, setIsErrorCreatedActivity] = useState(false);
  const [isErrorMsgCreatedActivity, setIsErrorMsgCreatedActivity] =
    useState("");

  useEffect(() => {
    if (isErrorCreatedActivity) {
      setTimeout(() => {
        setIsErrorCreatedActivity(false);
      }, 3000);
    }
  }, [isErrorCreatedActivity]);

  useEffect(() => {
    if (isSuccessCreatedActivity) {
      setTimeout(() => {
        setIsSuccessCreatedActivity(false);
      }, 3000);
    }
  }, [isSuccessCreatedActivity]);

  const [level4OptionsSrc, setLevel4OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level4ByLevelSrcReducer.level4ByLevel.data
      ? level4ByLevelSrcReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel4OptionsSrc(tempLevelOptions);
  }, [level4ByLevelSrcReducer]);

  // Delivery Options
  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const handleCreatedActivityChange = (selectedCreatedActivity) => {
    setCreatedActivitySelected(selectedCreatedActivity);
  };

  const [level2Options, setLevel2Options] = useState([]);
  const [createdActivityOptions, setCreatedActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setCreatedActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const onSubmitForAdd = async (values, { resetForm }) => {
    setCreateButtonName("Please wait...");
    let payload = {
      mvtId: createdActivitySelected?.value,
      documentNumber: singleLineItem.documentNumber,
    };
    let res = await releasePicklistForApprovedRequest(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreatedActivity(true);
      setCreateButtonName("Submit");

      getApprovedMaterialRequest(jwtToken);
    }
    if (!res.status) {
      setIsErrorCreatedActivity(true);
      setIsErrorMsgCreatedActivity(res.message.data.msg);
      setCreateButtonName("Submit");
    }

    resetForm();
  };
  const initialValuesForAdd = {
    mvtId: "",
  };

  const formikForAdd = useFormik({
    initialValues: initialValuesForAdd,
    onSubmit: onSubmitForAdd,
    // validationSchema: validationSchemaForAdd,
    enableReinitialize: true,
  });

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            {/* <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                {createNewUnplannedIssue && (
                  <MDBox color="text" px={2} ml={-2} mb={1}>
                    <CreateButton
                      tooltip="Create Unplanned Issue"
                      style={{ marginRight: "1rem" }}
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                      onClick={(event) => {
                        handleCreateUnplannedIssueModal();

                        getDocumentType(jwtToken);
                        getCreatedActivity(16, jwtToken);
                        getWarehouses(jwtToken);
                      }}
                    >
                      <Icon>add</Icon>
                    </CreateButton>
                  </MDBox>
                )}
              </MDBox> */}

            <MDBox pt={1}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  hideColumns={["", "asnCode", "vendorCodeName"]}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>

            <Modal
              open={openCategoryLineItemsModal}
              onClose={handleCloseCategoryLineItemsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCategoryModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Suids
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCategoryLineItemsModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />

                <MDBox>
                  <DataTable
                    table={{ columns: categoryColumns, rows: categoryRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={createPicklistModal}
              onClose={handleCloseCreatePicklistModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForMovement}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreatePicklistModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForAdd.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Document No:-{" "}
                        <span style={{ fontWeight: 400 }}>
                          {singleLineItem?.documentNumber}
                        </span>
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Movement
                      </MDTypography>

                      <Select
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="mvtId"
                        // styles={customStyles}
                        onBlur={() => {
                          formikForAdd.handleBlur({
                            target: { name: "mvtId" },
                          });
                        }}
                        value={createdActivitySelected}
                        options={createdActivityOptions}
                        onChange={(selectedOption) => {
                          handleCreatedActivityChange(selectedOption);
                          formikForAdd.handleChange("mvtId")(
                            selectedOption.label
                          );
                        }}
                      />

                      {formikForAdd.touched.mvtId &&
                        formikForAdd.errors.mvtId && (
                          <TextError msg={formikForAdd.errors.mvtId} />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorCreatedActivity}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorCreatedActivity(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgCreatedActivity}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessCreatedActivity}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessCreatedActivity(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Submitted successfully
                        </Alert>
                      </Collapse>
                    </MDBox>

                    <MDBox mt={3}>
                      <MDButton
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={
                          createdActivitySelected == "" ||
                          createButtonName === "Please wait..."
                        }
                      >
                        {createButtonName}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={createUnplannedIssueModal}
              onClose={handleCreateUnplannedIssueModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Unplanned Issue
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCreateUnplannedIssueModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmitForCreateUnplannedIssue}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div>
                                  <MDBox
                                  // style={{
                                  //   // position: "sticky",
                                  //   top: "0",
                                  //   width: "100%",
                                  //   // zIndex: 999,
                                  // }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} lg={3}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "270px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Document Type
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="documentType"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "documentType",
                                                },
                                              });
                                            }}
                                            value={documentTypeSelected}
                                            options={documentTypeOptions}
                                            onChange={(selectedOption) => {
                                              handleDocumentTypeChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "documentType",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.documentType &&
                                            formik.errors.documentType && (
                                              <TextError
                                                msg={formik.errors.documentType}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={3}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "270px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            ACC Usage
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="accType"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "accType",
                                                },
                                              });
                                            }}
                                            value={accTypeSelected}
                                            options={accTypeOptions}
                                            onChange={(selectedOption) => {
                                              handleAccTypeChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "accType",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.accType &&
                                            formik.errors.accType && (
                                              <TextError
                                                msg={formik.errors.accType}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={3}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "270px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            CC Usage
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="ccType"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "ccType",
                                                },
                                              });
                                            }}
                                            value={ccTypeSelected}
                                            options={ccTypeOptions}
                                            onChange={(selectedOption) => {
                                              handleCcTypeChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "ccType",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.ccType &&
                                            formik.errors.ccType && (
                                              <TextError
                                                msg={formik.errors.ccType}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={3}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "270px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Document No
                                          </MDTypography>
                                          <Field
                                            className="form-control"
                                            type="text"
                                            name="documentNumber"
                                            fullWidth
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.documentNumber}
                                            error={
                                              formik.touched.documentNumber &&
                                              formik.errors.documentNumber &&
                                              true
                                            }
                                            inputProps={{
                                              style: {
                                                height: "14px",
                                              },
                                              maxLength: 45,
                                            }}
                                          />
                                          {formik.errors.documentNumber && (
                                            <TextError
                                              msg={formik.errors.documentNumber}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  <hr style={{ marginBottom: "10px" }} />

                                  <MDBox>
                                    <MDTypography variant="h5">
                                      Source Location
                                    </MDTypography>
                                    <hr style={{ marginBottom: "10px" }} />
                                    <Grid container>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            WareHouse Code
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="sWarehouse"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "sWarehouse",
                                                },
                                              });
                                            }}
                                            value={sWarehouseSelected}
                                            options={warehouseOptions}
                                            onChange={(selectedOption) => {
                                              handleSwarehouseChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                `itemsForUnplannedIssue.itemId`,
                                                ""
                                              );
                                              setSelectedLocationCode(
                                                selectedOption?.label
                                              );
                                              getItemsByLocation(
                                                selectedOption?.label,
                                                jwtToken
                                              );
                                              getLevel1ByLevelSrc(
                                                selectedOption?.value,
                                                0,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "sWarehouse",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "sourceLocation",
                                                `${
                                                  selectedOption?.label
                                                    ? selectedOption.label
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.sWarehouse &&
                                            formik.errors.sWarehouse && (
                                              <TextError
                                                msg={formik.errors.sWarehouse}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 1:-
                                            {data?.length > 0
                                              ? data[0].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              sWarehouseSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="sZone"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "sZone",
                                                },
                                              });
                                            }}
                                            value={sZoneSelected}
                                            options={level1OptionsSrc}
                                            onChange={(selectedOption) => {
                                              handleSzoneChange(selectedOption);
                                              formik.setFieldValue(
                                                `itemsForUnplannedIssue.itemId`,
                                                ""
                                              );
                                              setSelectedLocationCode(
                                                `${sWarehouseSelected?.label}-${selectedOption?.label}`
                                              );

                                              getItemsByLocation(
                                                `${sWarehouseSelected?.label}-${selectedOption?.label}`,
                                                jwtToken
                                              );
                                              getLevel2ByLevelSrc(
                                                sWarehouseSelected?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "sZone",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "sourceLocation",
                                                `${
                                                  formik.values.sWarehouse
                                                    ? formik.values.sWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.sZone &&
                                            formik.errors.sZone && (
                                              <TextError
                                                msg={formik.errors.sZone}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 2:-
                                            {data?.length > 0
                                              ? data[1].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              sZoneSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="sSection"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "sSection",
                                                },
                                              });
                                            }}
                                            value={sSectionSelected}
                                            options={level2OptionsSrc}
                                            onChange={(selectedOption) => {
                                              handleSsectionChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                `itemsForUnplannedIssue.itemId`,
                                                ""
                                              );
                                              setSelectedLocationCode(
                                                `${sWarehouseSelected?.label}-${sZoneSelected?.label}-${selectedOption?.label}`
                                              );
                                              getItemsByLocation(
                                                `${sWarehouseSelected?.label}-${sZoneSelected?.label}-${selectedOption?.label}`,
                                                jwtToken
                                              );
                                              getLevel3ByLevelSrc(
                                                sWarehouseSelected?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "sSection",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "sourceLocation",
                                                `${
                                                  formik.values.sWarehouse
                                                    ? formik.values.sWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  formik.values.sZone
                                                    ? `-${formik.values.sZone.label}`
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.sSection &&
                                            formik.errors.sSection && (
                                              <TextError
                                                msg={formik.errors.sSection}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          // ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 3:-
                                            {data?.length > 0
                                              ? data[2].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              sSectionSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose One..."
                                            name="sRack"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "sRack",
                                                },
                                              });
                                            }}
                                            value={sRackSelected}
                                            options={level3OptionsSrc}
                                            onChange={(selectedOption) => {
                                              handleSrackChange(selectedOption);
                                              formik.setFieldValue(
                                                `itemsForUnplannedIssue.itemId`,
                                                ""
                                              );
                                              setSelectedLocationCode(
                                                `${sWarehouseSelected?.label}-${sZoneSelected?.label}-${sSectionSelected?.label}-${selectedOption?.label}`
                                              );
                                              getItemsByLocation(
                                                `${sWarehouseSelected?.label}-${sZoneSelected?.label}-${sSectionSelected?.label}-${selectedOption?.label}`,
                                                jwtToken
                                              );
                                              getLevel4ByLevelSrc(
                                                sWarehouseSelected?.value,

                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "sRack",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "sourceLocation",
                                                `${
                                                  formik.values.sWarehouse
                                                    ? formik.values.sWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  formik.values.sZone
                                                    ? `-${formik.values.sZone.label}`
                                                    : ""
                                                }${
                                                  formik.values.sSection
                                                    ? `-${formik.values.sSection.label}`
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.sRack &&
                                            formik.errors.sRack && (
                                              <TextError
                                                msg={formik.errors.sRack}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.length > 0
                                              ? data[3].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              sRackSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={90}
                                            placeholder="Choose one..."
                                            name="sLocation"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "sLocation",
                                                },
                                              });
                                            }}
                                            value={sLocationSelected}
                                            options={level4OptionsSrc}
                                            onChange={(selectedOption) => {
                                              handleSlocationChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                `itemsForUnplannedIssue.itemId`,
                                                ""
                                              );
                                              setSelectedLocationCode(
                                                `${sWarehouseSelected?.label}-${sZoneSelected?.label}-${sSectionSelected?.label}-${sRackSelected?.label}-${selectedOption?.label}`
                                              );
                                              getItemsByLocation(
                                                `${sWarehouseSelected?.label}-${sZoneSelected?.label}-${sSectionSelected?.label}-${sRackSelected?.label}-${selectedOption?.label}`,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "sLocation",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "sourceLocation",
                                                `${
                                                  formik.values.sWarehouse
                                                    ? formik.values.sWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  formik.values.sZone
                                                    ? `-${formik.values.sZone.label}`
                                                    : ""
                                                }${
                                                  formik.values.sSection
                                                    ? `-${formik.values.sSection.label}`
                                                    : ""
                                                }${
                                                  formik.values.sRack
                                                    ? `-${formik.values.sRack.label}`
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.sLocation &&
                                            formik.errors.sLocation && (
                                              <TextError
                                                msg={formik.errors.sLocation}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>
                                  <hr style={{ marginBottom: "10px" }} />
                                  <MDBox>
                                    <MDTypography variant="h5">
                                      Items
                                    </MDTypography>
                                    <hr style={{ marginBottom: "10px" }} />

                                    <FieldArray name="itemsForUnplannedIssue">
                                      {(fieldArrayProps) => {
                                        const { push, remove, form } =
                                          fieldArrayProps;
                                        const { values } = form;
                                        const { itemsForUnplannedIssue } =
                                          values;

                                        return (
                                          <div>
                                            {itemsForUnplannedIssue.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                    key={index}
                                                  >
                                                    <Grid container>
                                                      <Grid item xs={12} lg={4}>
                                                        <MDBox ml={1}>
                                                          <MDTypography variant="h6">
                                                            Item Code/Des -{" "}
                                                            {index + 1}
                                                          </MDTypography>
                                                          <Select
                                                            className="select-css-for-itemcode"
                                                            maxMenuHeight={90}
                                                            isClearable
                                                            styles={{
                                                              control: (
                                                                provided
                                                              ) => ({
                                                                ...provided,
                                                                borderRadius: 5,
                                                                height: "12px",
                                                                marginTop:
                                                                  "-2px",
                                                                fontSize:
                                                                  "15px",
                                                              }),
                                                            }}
                                                            options={
                                                              itemCodeOptions
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) => {
                                                              getSuidsByLocationAndItem(
                                                                selectedOption?.value,
                                                                selectedLocationCode,
                                                                jwtToken
                                                              );
                                                              formik.setFieldValue(
                                                                `itemsForUnplannedIssue.${index}.suid`,
                                                                ""
                                                              );
                                                              formik.setFieldValue(
                                                                `itemsForUnplannedIssue.${index}.itemId`,
                                                                selectedOption?.value
                                                              );
                                                            }}
                                                            value={
                                                              itemsForUnplannedIssue.itemId
                                                            }
                                                            placeholder="Choose one..."
                                                          />
                                                        </MDBox>
                                                      </Grid>
                                                      <Grid item xs={12} lg={3}>
                                                        <MDBox ml={1}>
                                                          <MDTypography variant="h6">
                                                            SUID
                                                          </MDTypography>
                                                          <Select
                                                            className="select-css"
                                                            maxMenuHeight={90}
                                                            disabled={
                                                              suidOptions.length ===
                                                              0
                                                            }
                                                            // isClearable
                                                            styles={{
                                                              control: (
                                                                provided
                                                              ) => ({
                                                                ...provided,
                                                                borderRadius: 5,
                                                                height: "12px",
                                                                marginTop:
                                                                  "-2px",
                                                                fontSize:
                                                                  "15px",
                                                              }),
                                                            }}
                                                            options={
                                                              suidOptions
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) => {
                                                              formik.setFieldValue(
                                                                `itemsForUnplannedIssue.${index}.suid`,
                                                                selectedOption?.value
                                                              );

                                                              formik.setFieldValue(
                                                                `itemsForUnplannedIssue.${index}.sourceLocation`,
                                                                selectedOption?.sLocation
                                                              );

                                                              formik.setFieldValue(
                                                                `itemsForUnplannedIssue.${index}.qty`,
                                                                selectedOption?.qty
                                                              );
                                                            }}
                                                            value={
                                                              itemsForUnplannedIssue.suid
                                                            }
                                                            placeholder="Choose one..."
                                                          />
                                                          {/* {formik?.touched[
                                                            `lineitemsForUnplannedIssue.${index}.suid`
                                                          ] &&
                                                            formik?.errors[
                                                              `lineitemsForUnplannedIssue.${index}.suid`
                                                            ] && (
                                                              <TextError
                                                                msg={
                                                                  formik.errors[
                                                                    `lineitemsForUnplannedIssue.${index}.suid`
                                                                  ]
                                                                }
                                                              />
                                                            )} */}
                                                        </MDBox>
                                                      </Grid>
                                                      <Grid item xs={12} lg={3}>
                                                        <MDBox ml={1}>
                                                          <MDTypography variant="h6">
                                                            Source Location
                                                          </MDTypography>
                                                          <Field
                                                            disabled
                                                            className="form-control"
                                                            style={{
                                                              borderRadius: 5,
                                                              marginLeft: 0,
                                                              height: 36,
                                                              paddingLeft:
                                                                "15px",
                                                              width: "280px",
                                                            }}
                                                            type="text"
                                                            name={`itemsForUnplannedIssue.${index}.sourceLocation`}
                                                            value={
                                                              itemsForUnplannedIssue[
                                                                index
                                                              ]?.sourceLocation
                                                            }
                                                            fullWidth
                                                            autoComplete="off"
                                                            onChange={
                                                              formik.handleChange
                                                            }
                                                            // error={
                                                            //   formik.touched
                                                            //     .sLocation &&
                                                            //   formik.errors
                                                            //     .sLocation &&
                                                            //   true
                                                            // }
                                                            inputProps={{
                                                              style: {
                                                                height: "14px",
                                                              },
                                                              maxLength: 45,
                                                              readOnly: true,
                                                            }}
                                                          />

                                                          {formik.touched
                                                            .sLocation &&
                                                            formik.errors
                                                              .sLocation && (
                                                              <TextError
                                                                msg={
                                                                  formik.errors
                                                                    .sLocation
                                                                }
                                                              />
                                                            )}
                                                        </MDBox>
                                                      </Grid>

                                                      <span
                                                        style={{
                                                          display: "flex",
                                                          marginTop: "1.5rem",
                                                          marginLeft: "2rem",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          {index > 0 && (
                                                            <CancelIcon
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              type="button"
                                                              className="secondary"
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              color="error"
                                                            />
                                                          )}
                                                        </div>
                                                        {index ===
                                                          itemsForUnplannedIssue.length -
                                                            1 && (
                                                          <div>
                                                            <Tooltip title="Add Items">
                                                              <AddCircleIcon
                                                                fontSize="large"
                                                                onClick={() =>
                                                                  push({
                                                                    itemId: "",
                                                                    suid: "",
                                                                    sourceLocation:
                                                                      "",
                                                                  })
                                                                }
                                                                color="info"
                                                                style={{
                                                                  width: "30px",
                                                                  marginLeft:
                                                                    "0rem",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          </div>
                                                        )}
                                                      </span>
                                                    </Grid>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }}
                                    </FieldArray>
                                  </MDBox>

                                  <div style={{ background: "white" }}>
                                    <MDButton
                                      style={{
                                        position: "sticky",
                                        bottom: "0",
                                      }}
                                      color="info"
                                      fullWidth
                                      type="submit"
                                      disabled={
                                        !formik.isValid ||
                                        !documentTypeSelected ||
                                        formik.values.itemsForUnplannedIssue.some(
                                          (item) =>
                                            item.itemId === "" ||
                                            item.suid === ""
                                        ) ||
                                        buttonName === "Please wait..."
                                      }
                                    >
                                      {buttonName}
                                    </MDButton>
                                  </div>
                                  <MDBox>
                                    <Collapse in={isErrorCreatedUnplannedIssue}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorCreatedUnplannedIssue(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        {errorMsgForCreate}
                                      </Alert>
                                    </Collapse>
                                    <Collapse
                                      in={isSuccessCreatedUnplannedIssue}
                                    >
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessCreatedUnplannedIssue(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Unplanned Issue Created Successfully
                                      </Alert>
                                    </Collapse>
                                  </MDBox>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
      {/* </DashboardLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getUnplannedIssueReducer: state.getUnplannedIssueReducer,
    getItemsByLocationReducer: state.getItemsByLocationReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    getSuidsByLocationAndItemReducer: state.getSuidsByLocationAndItemReducer,
    getSuidsByDocumentNoReducer: state.getSuidsByDocumentNoReducer,
    warehouses: state.warehouses,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    availableQtyState: state.getAvailableQtyForToReducer,
    level1ByLevelSrcReducer: state.getLevel1ByLevelSrcReducer,
    level2ByLevelSrcReducer: state.getLevel2ByLevelSrcReducer,
    level3ByLevelSrcReducer: state.getLevel3ByLevelSrcReducer,
    level4ByLevelSrcReducer: state.getLevel4ByLevelSrcReducer,

    getRelationLocationReducer: state.getRelationLocationReducer,
    getAccTypeReducer: state.getAccTypeReducer,
    getCcTypeReducer: state.getCcTypeReducer,
    getApproveMaterialRequestReducer: state.getApproveMaterialRequestReducer,
    getSuidsForApproveMaterialRequestReducer:
      state.getSuidsForApproveMaterialRequestReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getUnplannedIssues: (jwtToken) => dispatch(getUnplannedIssues(jwtToken)),
    getSuidsByLocationAndItem: (itemId, locationCode, jwtToken) =>
      dispatch(getSuidsByLocationAndItem(itemId, locationCode, jwtToken)),
    getSuidsByDocumentNo: (documentNo, jwtToken) =>
      dispatch(getSuidsByDocumentNo(documentNo, jwtToken)),
    createUnplannedIssue: (payload, jwtToken) =>
      dispatch(createUnplannedIssue(payload, jwtToken)),
    releasePicklistForApprovedRequest: (payload, jwtToken) =>
      dispatch(releasePicklistForApprovedRequest(payload, jwtToken)),
    createPicklistForUnplannedIssue: (payload, jwtToken) =>
      dispatch(createPicklistForUnplannedIssue(payload, jwtToken)),
    getSuidsByDocumentNoForApprove: (documentId, jwtToken) =>
      dispatch(getSuidsByDocumentNoForApprove(documentId, jwtToken)),

    getItemsByLocation: (jwtToken) => dispatch(getItemsByLocation(jwtToken)),
    getAccUsage: (jwtToken) => dispatch(getAccUsage(jwtToken)),
    getCcUsage: (accTypeSelected, jwtToken) =>
      dispatch(getCcUsage(accTypeSelected, jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getAvailableQtyForTo: (itemId, location, jwtToken) =>
      dispatch(getAvailableQtyForTo(itemId, location, jwtToken)),
    getLevel1ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel2ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel3ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel4ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevelSrc(warehouseId, parentId, jwtToken)),

    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
    getApprovedMaterialRequest: (jwtToken) =>
      dispatch(getApprovedMaterialRequest(jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ApprovedMaterialRequest);
