import axios from "axios";
import Config from "../../../config/index";
import {
  GET_MIN_MAX_FAILURE_FAILURE,
  GET_MIN_MAX_FAILURE_REQUEST,
  GET_MIN_MAX_FAILURE_SUCCESS,
} from "./minMaxFailureTypes";

let baseUrl = Config.baseUrl;

const getMinMaxFailureRequest = () => {
  return {
    type: GET_MIN_MAX_FAILURE_REQUEST,
  };
};

const getMinMaxFailureSuccess = (payload) => {
  return {
    type: GET_MIN_MAX_FAILURE_SUCCESS,
    payload: payload,
  };
};

const getMinMaxFailureFailure = (error) => {
  return {
    type: GET_MIN_MAX_FAILURE_FAILURE,
    payload: error,
  };
};

const getMinMaxFailure = (startDate, endDate, itemCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMinMaxFailureRequest());
    let url = `${baseUrl}/reports/min_max_failure?startDate=${startDate}&endDate=${endDate}&itemCode=${itemCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMinMaxFailureSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getMinMaxFailureFailure(err.message));
      });
  };
};

export { getMinMaxFailure };
