/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Select from "react-select";
import "../../../index.css";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import TextError from "utils/TextError";

import CreateButton from "components/standardized-components/CreateButton";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getSubModulesByRoleId,
  getSuids,
  getWarehouses,
  getWmsStock,
} from "../../../store";

import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const styleForCreateRole = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 340, sm: 340, md: 450, lg: 450, xl: 450 },
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  boxShadow: 24,
  p: 1,
  pt: 2,
};

const ClientDetails = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  warehouses,
  getWarehouses,
  getWmsStock,
  getWmsStockReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getWarehouses(jwtToken);
  }, []);
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [selectedAllStock, setSelectedAllStock] = useState("");

  useEffect(() => {
    getWmsStock(warehouseIdSelected?.value, selectedAllStock?.value, jwtToken);
  }, [warehouseIdSelected, selectedAllStock]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let wmsStockModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "99dfc411-81a6-4609-942c-d1a0aee2cc79"
  );

  let viewWmsStockOccupancy = wmsStockModule
    ? wmsStockModule.actionId.includes("1d97c139-638e-4d1b-a395-b32e731f4287")
    : null;

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "Client", accessor: "client" },
      { Header: "Role", accessor: "role" },
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(false);

  const [openCreateRoleModal, setOpenCreateRoleModal] = useState(false);

  const handleOpenCreateRoleModal = (data) => {
    setOpenCreateRoleModal(true);
  };

  const handleCloseCreateRoleModal = () => {
    setOpenCreateRoleModal(false);
  };

  const [isSuccessCreateRole, setIsSuccessCreateRole] = useState(false);
  const [isErrorCreateRole, setIsErrorCreateRole] = useState(false);
  useEffect(() => {
    if (isSuccessCreateRole) {
      setTimeout(() => {
        setIsSuccessCreateRole(false);
      }, 3000);
    }
  }, [isSuccessCreateRole]);

  useEffect(() => {
    if (isErrorCreateRole) {
      setTimeout(() => {
        setIsErrorCreateRole(false);
      }, 3000);
    }
  }, [isErrorCreateRole]);

  const initialValuesForCreateRole = {
    roleName: "",
    description: "",
    departmentId: "",
  };

  const validationSchemaForCreateRole = Yup.object({
    roleName: Yup.string().required("Enter the role name"),
    description: Yup.string().required("Enter the description"),
  });

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const [submitLoader, setSubmitLoader] = useState(false);

  const onSubmitForCreateRole = async (values, { resetForm }) => {};

  const formikForCreateRole = useFormik({
    initialValues: initialValuesForCreateRole,
    onSubmit: onSubmitForCreateRole,
    validationSchema: validationSchemaForCreateRole,
  });

  return (
    <MDBox mt={-8}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDBox>
              <CreateButton
                tooltip="Create Role"
                color="info"
                variant="contained"
                type="button"
                onClick={() => {
                  handleOpenCreateRoleModal();
                }}
              >
                <Icon>add</Icon>
              </CreateButton>
            </MDBox>
          </MDBox>
          <MDBox mt={-3}>
            {!loading ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                hideColumns={["", "asnCode", "vendorCodeName"]}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  marginBottom: "100px",
                }}
              >
                <CircularProgress color="info" />
              </div>
            )}
          </MDBox>

          <Modal
            open={openCreateRoleModal}
            onClose={handleCloseCreateRoleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={styleForCreateRole}>
              <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                  Create Super Admin
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseCreateRoleModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <hr />

              <MDBox pt={1} pb={3} px={1} overflow="auto">
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForCreateRole.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      style={{ fontWeight: "500" }}
                    >
                      Client
                    </MDTypography>
                    <Select
                      isClearable
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Choose one..."
                      name="departmentId"
                      //   options={departmentsOptions}
                      //   value={departmentSelected}
                      //   onChange={(selectedOption) => {
                      //     handleDepartmentSelected(selectedOption);
                      //     formikForCreateRole.setFieldValue(
                      //       "departmentId",
                      //       selectedOption
                      //     );
                      //   }}
                    />
                    {formikForCreateRole.errors.departmentId && (
                      <TextError
                        msg={formikForCreateRole.errors.departmentId}
                      />
                    )}
                  </MDBox>

                  <MDBox mb={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      style={{ fontWeight: "500" }}
                    >
                      Role
                    </MDTypography>
                    <Select
                      isClearable
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Choose one..."
                      name="departmentId"
                      //   options={departmentsOptions}
                      //   value={departmentSelected}
                      //   onChange={(selectedOption) => {
                      //     handleDepartmentSelected(selectedOption);
                      //     formikForCreateRole.setFieldValue(
                      //       "departmentId",
                      //       selectedOption
                      //     );
                      //   }}
                    />
                    {formikForCreateRole.errors.departmentId && (
                      <TextError
                        msg={formikForCreateRole.errors.departmentId}
                      />
                    )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorCreateRole}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreateRole(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {/* Please check the details. Server error! */}
                        {createErrorMsg}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessCreateRole}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessCreateRole(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Role created successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={2}>
                    {!submitLoader ? (
                      <MDButton
                        variant="contained"
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={!formikForCreateRole.isValid}
                      >
                        Submit
                      </MDButton>
                    ) : (
                      <MDButton
                        variant="contained"
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={!formikForCreateRole.isValid}
                      >
                        <CircularProgress
                          style={{ color: "white", marginRight: "10px" }}
                          size={20}
                        />
                        Submiting...{" "}
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
        </Grid>
      </Grid>
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,

    getSuidsReducer: state.getSuidsReducer,
    warehouses: state.warehouses,
    getWmsStockReducer: state.getWmsStockReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getWmsStock: (warehouseId, status, jwtToken) =>
      dispatch(getWmsStock(warehouseId, status, jwtToken)),

    getSuids: (itemId, jwtToken) => dispatch(getSuids(itemId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ClientDetails);
