/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getQuarantine,
  acceptQuarantine,
  rejectQuarantine,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";
import { ToastContainer } from "react-toastify";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForReject = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const Quarantine = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getQuarantine,
  quarantineReducer,
  acceptQuarantine,
  rejectQuarantine,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let quarantineModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "36d4fab2-0c14-4ae5-bd8a-93cb38a7d8e5"
  );

  let viewQuarantine = quarantineModule
    ? quarantineModule.actionId.includes("5ff563c2-3863-4d54-9063-d9dd24a35282")
    : null;
  let acceptQuarantineAccess = quarantineModule
    ? quarantineModule.actionId.includes("b3de95d6-5874-4383-b101-239c39b4c584")
    : null;
  let rejectQuarantineAccess = quarantineModule
    ? quarantineModule.actionId.includes("e9c1dae4-7ba9-4bac-9a6d-3e3ef6d7a54c")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "SUID", accessor: "sku" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Uom", accessor: "uom" },
      { Header: "LOT No", accessor: "lotNo" },
      { Header: "Serial No", accessor: "SerialNo" },
      { Header: "Location", accessor: "location" },

      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  useEffect(() => {
    getQuarantine(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempQuarantineSku = [];
    let data = quarantineReducer.quarantine.data
      ? quarantineReducer.quarantine.data
      : [];

    setPaginationObj(quarantineReducer?.quarantine?.pagination);
    setLoading(quarantineReducer.loading);
    data.map((quarantine) => {
      const formatDate = new Date(quarantine.cd);
      let des = quarantine?.itemDescription;
      let len = desLength;
      const obj = {
        privateKey: quarantine.sku,
        itemCode: `${quarantine.itemCode}`,
        itemDescription: `${quarantine.itemDescription}`,
        sku: `${quarantine.suid ? quarantine.suid : "NA"}`,
        suid: quarantine.suid,
        itemId: quarantine.itemId,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        location: quarantine.locationCode,
        qty: `${quarantine.qty}`,

        uom: `${quarantine.uom}`,
        // lotSerialNo: `${quarantine.lotNumber ? quarantine.lotNumber : "NA"} / ${
        //   quarantine.serialNumber ? quarantine.serialNumber : "NA"
        // }`,
        lotNo: quarantine.lotNumber ? quarantine.lotNumber : "NA",
        SerialNo: quarantine.serialNumber ? quarantine.serialNumber : "NA",
        action: (
          <>
            {acceptQuarantineAccess && (
              <Tooltip title="Accept">
                <MDButton
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  style={{ marginRight: "5px" }}
                >
                  <Icon>done</Icon>
                </MDButton>
              </Tooltip>
            )}
          </>
        ),
      };
      tempQuarantineSku.push(obj);
    });
    viewQuarantine && setState({ ...state, rows: tempQuarantineSku });
  }, [quarantineReducer]);

  const [openAcceptQuarantineModal, setOpenAcceptQuarantineModal] =
    useState(false);

  const [buttonName, setButtonName] = useState("Submit");
  const [denyButtonName, setDenyButtonName] = useState("Deny");

  const handleOpenAcceptQuarantineModal = (Quarantine = {}) => {
    setOpenAcceptQuarantineModal(true);
    setIsErrorForAccept(false);
    setIsSuccessForAccept(false);
  };
  const handleCloseAcceptQuarantineModal = () => {
    setOpenAcceptQuarantineModal(false);
    formikForAccept.setErrors({});
  };

  const [isErrorForAccept, setIsErrorForAccept] = useState(false);
  const [isErrorMsgForAccept, setIsErrorMsgForAccept] = useState("");
  const [isSuccessForAccept, setIsSuccessForAccept] = useState(false);

  useEffect(() => {
    if (isErrorForAccept) {
      setTimeout(() => {
        setIsErrorForAccept(false);
      }, 3000);
    }
  }, [isErrorForAccept]);

  useEffect(() => {
    if (isSuccessForAccept) {
      setTimeout(() => {
        setIsSuccessForAccept(false);
      }, 3000);
    }
  }, [isSuccessForAccept]);

  const initialValueForAccept = {
    documentNumber: "",
  };

  const validationSchemaForAccept = Yup.object({
    documentNumber: Yup.string().required("Enter document number!"),
  });

  const onSubmitForAccept = async (values) => {
    setButtonName("Please wait...");
    let tempArray = [];
    selectedRejectData.map((data) => {
      const quantity = parseFloat(data.qty);
      const tempPayload = {
        suid: data.suid,
        qty: quantity,
        itemId: data.itemId,
      };
      tempArray.push(tempPayload);
    });

    let payload = {
      documentNumber: values.documentNumber.trim(),
      suids: tempArray,
    };
    let res = await acceptQuarantine(payload, jwtToken);
    if (res.status) {
      setIsSuccessForAccept(true);
      getQuarantine(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleCloseAcceptQuarantineModal();
        setButtonName("Submit");
      }, 2000);
    }
    if (!res.status) {
      setIsErrorForAccept(true);
      setIsErrorMsgForAccept(res?.data?.data?.msg);
      setButtonName("Submit");
    }
  };

  const formikForAccept = useFormik({
    initialValues: initialValueForAccept,
    onSubmit: onSubmitForAccept,
    validationSchema: validationSchemaForAccept,
    enableReinitialize: true,
  });

  const [isErrorReject, setIsErrorReject] = useState(false);
  const [isSuccessReject, setIsSuccessReject] = useState(false);

  useEffect(() => {
    if (isErrorReject) {
      setTimeout(() => {
        setIsErrorReject(false);
      }, 3000);
    }
  }, [isErrorReject]);

  useEffect(() => {
    if (isSuccessReject) {
      setTimeout(() => {
        setIsSuccessReject(false);
      }, 3000);
    }
  }, [isSuccessReject]);

  const [isErrorMsgForReject, setIsErrorMsgForReject] = useState(false);

  const [openCreateRejectModal, setOpenCreateRejectModal] = useState(false);

  const handleOpenCreateRejectModal = (data = {}) => {
    setOpenCreateRejectModal(true);
  };

  const handleCloseCreateRejectModal = () => {
    setOpenCreateRejectModal(false);
  };

  const createRejectHandler = (event, data) => {
    handleOpenCreateRejectModal(data);
  };

  const [uncheck, setUncheck] = useState(false);

  const [selectedRejectData, setSelectedRejectData] = useState([]);
  const RejectForData = (data) => {
    setSelectedRejectData(data);
  };
  const onClickCreateRejectList = async () => {
    setDenyButtonName("Please wait...");
    let tempArray = [];
    selectedRejectData.map((data) => {
      const tempPayload = {
        suid: data.suid,
      };

      tempArray.push(tempPayload);
    });

    let payload = {
      suids: tempArray,
    };

    let res = await rejectQuarantine(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessReject(true);
      getQuarantine(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseCreateRejectModal();
        setDenyButtonName("Deny");
      }, 2250);
    }
    if (!res.status) {
      setDenyButtonName("Deny");
      setIsErrorReject(true);
      setIsErrorMsgForReject(res?.data?.data?.msg);
    }
  };

  return (
    <MDBox pt={0} pb={3} mt={-5}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox mt={-1}>
            <DataTable
              table={{ columns, rows }}
              isSorted={true}
              tableSearch={true}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 10, 15, 20, 25, 30],
              }}
              showTotalEntries={true}
              noEndBorder
              options={{ draggable: false }}
              serverSidePagination={true}
              paginationObj={paginationObj}
              pageNoValue={pageNo}
              rowsPerPageValue={rowsPerPage}
              pageNoProp={importPageNo}
              rowsPerPageProp={importRowsPerPage}
              searchProp={importSearch}
              selection={true}
              uncheckProp={uncheck}
              selectionButtonClick={RejectForData}
              selectionButtonComponent={
                <>
                  <>
                    {rejectQuarantineAccess && (
                      <Tooltip title="Deny">
                        <MDButton
                          color="error"
                          type="button"
                          style={{
                            marginTop: "0rem",

                            marginLeft: "1rem",
                          }}
                          onClick={(evt, data) =>
                            createRejectHandler(evt, data)
                          }
                        >
                          Deny
                        </MDButton>
                      </Tooltip>
                    )}
                    {acceptQuarantineAccess && (
                      <Tooltip title="Confirm">
                        <MDButton
                          color="success"
                          type="button"
                          style={{
                            marginTop: "0rem",

                            marginLeft: "1rem",
                          }}
                          onClick={(evt, data) =>
                            handleOpenAcceptQuarantineModal(evt, data)
                          }
                        >
                          Confirm
                        </MDButton>
                      </Tooltip>
                    )}
                  </>
                </>
              }
            />
          </MDBox>
          <Modal
            open={openAcceptQuarantineModal}
            onClose={handleCloseAcceptQuarantineModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Confirm
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseAcceptQuarantineModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForAccept.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6">Document No</MDTypography>
                    <MDInput
                      type="text"
                      name="documentNumber"
                      fullWidth
                      autoComplete="off"
                      onBlur={formikForAccept.handleBlur}
                      onChange={formikForAccept.handleChange}
                      value={formikForAccept.values.documentNumber}
                      error={
                        formikForAccept.touched.documentNumber &&
                        formikForAccept.errors.documentNumber &&
                        true
                      }
                      inputProps={{
                        maxLength: 45,
                      }}
                    />
                    {formikForAccept.touched.documentNumber &&
                      formikForAccept.errors.documentNumber && (
                        <TextError
                          msg={formikForAccept.errors.documentNumber}
                        />
                      )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorForAccept}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorForAccept(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {isErrorMsgForAccept}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessForAccept}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorForAccept(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Quarantine Confirmed successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="success"
                      fullWidth
                      type="submit"
                      disabled={
                        !formikForAccept.isValid ||
                        buttonName === "Please wait..."
                      }
                    >
                      {buttonName}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
          <Modal
            open={openCreateRejectModal}
            onClose={handleCloseCreateRejectModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={styleForReject}>
              <MDTypography>Are you sure you want to Deny?</MDTypography>
              <MDBox mt={2}>
                <MDButton
                  style={{ width: "10rem", marginRight: "0.5rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleCloseCreateRejectModal}
                >
                  Cancel
                </MDButton>
                <MDButton
                  style={{ width: "10rem", marginLeft: "0.5rem" }}
                  color="error"
                  onClick={onClickCreateRejectList}
                  disabled={denyButtonName === "Please wait..."}
                >
                  {denyButtonName}
                </MDButton>
              </MDBox>
              <MDBox mt={2}>
                <Collapse in={isSuccessReject}>
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsSuccessReject(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Denied successfully!
                  </Alert>
                </Collapse>
                <Collapse in={isErrorReject}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorReject(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {isErrorMsgForReject}
                  </Alert>
                </Collapse>
              </MDBox>
            </MDCard>
          </Modal>
        </Grid>
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    quarantineReducer: state.quarantineReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getQuarantine: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getQuarantine(pageNo, rowsPerPage, searchObject, jwtToken)),
    acceptQuarantine: (jwtToken) => dispatch(acceptQuarantine(jwtToken)),
    rejectQuarantine: (jwtToken) => dispatch(rejectQuarantine(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quarantine);
