export const GET_FAILING_MINIMUM_TOP_10_REQUEST =
  "GET_FAILING_MINIMUM_TOP_10_REQUEST";
export const GET_FAILING_MINIMUM_TOP_10_SUCCESS =
  "GET_FAILING_MINIMUM_TOP_10_SUCCESS";
export const GET_FAILING_MINIMUM_TOP_10_FAILURE =
  "GET_FAILING_MINIMUM_TOP_10_FAILURE";

export const GET_REJECTED_TOP_10_REQUEST = "GET_REJECTED_TOP_10_REQUEST";
export const GET_REJECTED_TOP_10_SUCCESS = "GET_REJECTED_TOP_10_SUCCESS";
export const GET_REJECTED_TOP_10_FAILURE = "GET_REJECTED_TOP_10_FAILURE";

export const GET_RTV_TOP_10_REQUEST = "GET_RTV_TOP_10_REQUEST";
export const GET_RTV_TOP_10_SUCCESS = "GET_RTV_TOP_10_SUCCESS";
export const GET_RTV_TOP_10_FAILURE = "GET_RTV_TOP_10_FAILURE";

export const GET_TO_TOP_10_REQUEST = "GET_TO_TOP_10_REQUEST";
export const GET_TO_TOP_10_SUCCESS = "GET_TO_TOP_10_SUCCESS";
export const GET_TO_TOP_10_FAILURE = "GET_TO_TOP_10_FAILURE";
