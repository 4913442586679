import axios from "axios";
import Config from "../../../config/index";
import {
  GET_ASN_GRN_DEPENDENCY_FAILURE,
  GET_ASN_GRN_DEPENDENCY_REQUEST,
  GET_ASN_GRN_DEPENDENCY_SUCCESS,
} from "./asnGrnDependencyTypes";

let baseUrl = Config.baseUrl;

const getAsnGrnDependencyRequest = () => {
  return {
    type: GET_ASN_GRN_DEPENDENCY_REQUEST,
  };
};

const getAsnGrnDependencySuccess = (payload) => {
  return {
    type: GET_ASN_GRN_DEPENDENCY_SUCCESS,
    payload: payload,
  };
};

const getAsnGrnDependencyFailure = (error) => {
  return {
    type: GET_ASN_GRN_DEPENDENCY_FAILURE,
    payload: error,
  };
};

const getAsnGrnDependency = (
  startDate,
  endDate,
  vendorCode,
  itemCode,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAsnGrnDependencyRequest());
    let url = `${baseUrl}/reports/asn_grn_dependency?vendorCode=${vendorCode}&startDate=${startDate}&endDate=${endDate}&itemCode=${itemCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAsnGrnDependencySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAsnGrnDependencyFailure(err.message));
      });
  };
};

export { getAsnGrnDependency };
