import axios from "axios";
import Config from "../../config/index";
import {
  GET_DOCUMENT_TYPE_REQUEST,
  GET_DOCUMENT_TYPE_SUCCESS,
  GET_DOCUMENT_TYPE_FAILURE,
  CREATE_DOCUMENT_TYPE_REQUEST,
  CREATE_DOCUMENT_TYPE_SUCCESS,
  CREATE_DOCUMENT_TYPE_FAILURE,
  GET_DOCUMENT_TYPE_WITH_PAGINATION_REQUEST,
  GET_DOCUMENT_TYPE_WITH_PAGINATION_SUCCESS,
  GET_DOCUMENT_TYPE_WITH_PAGINATION_FAILURE,
} from "./documentTypeTypes";

let baseUrl = Config.baseUrl;

const getDocumentTypeRequest = () => {
  return {
    type: GET_DOCUMENT_TYPE_REQUEST,
  };
};

const getDocumentTypeSuccess = (payload) => {
  return {
    type: GET_DOCUMENT_TYPE_SUCCESS,
    payload: payload,
  };
};

const getDocumentTypeFailure = (error) => {
  return {
    type: GET_DOCUMENT_TYPE_FAILURE,
    payload: error,
  };
};

const getDocumentType = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDocumentTypeRequest());
    let url = `${baseUrl}/document_without_pagination`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDocumentTypeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDocumentTypeFailure(err.message));
      });
  };
};

const getDocumentTypeWithPaginationRequest = () => {
  return {
    type: GET_DOCUMENT_TYPE_WITH_PAGINATION_REQUEST,
  };
};

const getDocumentTypeWithPaginationSuccess = (payload) => {
  return {
    type: GET_DOCUMENT_TYPE_WITH_PAGINATION_SUCCESS,
    payload: payload,
  };
};

const getDocumentTypeWithPaginationFailure = (error) => {
  return {
    type: GET_DOCUMENT_TYPE_WITH_PAGINATION_FAILURE,
    payload: error,
  };
};

const getDocumentTypeWithPagination = (
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDocumentTypeWithPaginationRequest());
    let url = `${baseUrl}/document?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDocumentTypeWithPaginationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDocumentTypeWithPaginationFailure(err.message));
      });
  };
};

const createDocumentTypeRequest = () => {
  return {
    type: CREATE_DOCUMENT_TYPE_REQUEST,
  };
};

const createDocumentTypeSuccess = (payload) => {
  return {
    type: CREATE_DOCUMENT_TYPE_SUCCESS,
    payload: payload,
  };
};

const createDocumentTypeFailure = (error) => {
  return {
    type: CREATE_DOCUMENT_TYPE_FAILURE,
    payload: error,
  };
};

const createDocumentType = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createDocumentTypeRequest());
    let url = `${baseUrl}/document`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createDocumentTypeSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createDocumentTypeFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getDocumentType, getDocumentTypeWithPagination, createDocumentType };
