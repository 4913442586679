/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
// Formik and Yup

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Functions from store
import {
  getDocumentType,
  getDocumentTypeTurnAroundTime,
  getSubModulesByRoleId,
} from "../../../store";

import { CSVLink } from "react-csv";

// config file

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";
const cookies = new Cookies();

const DocumentType = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getDocumentType,
  getDocumentTypeReducer,
  getDocumenTypeTurnReducer,
  getDocumentTypeTurnAroundTime,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getDocumentType(jwtToken);
  }, []);
  useEffect(() => {
    getDocumentTypeTurnAroundTime(documentTypeSelected?.value, jwtToken);
  }, [documentTypeSelected]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let documentTypeTurnAroundModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ac101a0a-1f90-47a7-86c4-99b304f2cf24"
  );

  let viewAccess = documentTypeTurnAroundModule
    ? documentTypeTurnAroundModule.actionId.includes(
        "6ab9e198-e4a5-48ae-aac0-975bd8d82ee0"
      )
    : null;

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "Document Type", accessor: "documentType" },
      { Header: "Document Number", accessor: "documentNumber" },
      { Header: "Doc type Turn around time", accessor: "turnAroundTime" },
      { Header: "Status", accessor: "documentStatus" },
      { Header: "createdDate", accessor: "createdDate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getDocumenTypeTurnReducer.types
      ? getDocumenTypeTurnReducer.types
      : [];
    setLoading(getDocumenTypeTurnReducer.loading);
    data?.map((finishedGoods) => {
      
      const formatDate = new Date(finishedGoods.documentCd);
      const finishedGoodsObject = {
        picklistCode: finishedGoods.picklistCode,
        documentType: finishedGoods.documentName,
        documentNumber: finishedGoods.documentNumber,
        source: finishedGoods.source,
        destination: finishedGoods.destination,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        turnAroundTime: finishedGoods.turnAroundTime,
        documentStatus: (
          <button
            className={
              (finishedGoods.documentStatus === 1 && `status-success`) ||
              (finishedGoods.documentStatus === 2 && `status-info`) ||
              (finishedGoods.documentStatus === 3 && `status-warning`)
            }
            style={{ width: "5.5rem" }}
          >
            {(finishedGoods.documentStatus === 1 && `Open`) ||
              (finishedGoods.documentStatus === 2 && `In-Progress`) ||
              (finishedGoods.documentStatus === 3 && `Closed`)}
          </button>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewAccess && setState({ ...state, rows: tempFinishedGoods });
  }, [getDocumenTypeTurnReducer]);

  const headers = [
    {
      label: "documentType",
      key: "documentType",
    },
    {
      label: "status",
      key: "status",
    },
    { label: "documentNumber", key: "documentNumber" },
  ];

  const csvLink = {
    filename: "DocumentType.csv",
    headers: headers,
    data: rows,
  };

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
  };

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-2}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8} lg={8}>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <MDBox
                        width={{ xs: "150px", lg: "200px" }}
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Document Type
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                          autoComplete="off"
                          value={documentTypeSelected}
                          options={documentTypeOptions}
                          onChange={handleDocumentTypeChange}
                        />
                      </MDBox>

                      <MDBox style={{ marginTop: "25px", marginLeft: "8px" }}>
                        <CSVLink {...csvLink}>
                          <DownloadDataButton
                            type="button"
                            variant="outlined"
                            color="success"
                            iconOnly
                            tooltip="Download All Data"
                          >
                            <Icon>download</Icon>
                          </DownloadDataButton>
                        </CSVLink>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    hideColumns={["", "asnCode", "vendorCodeName"]}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getDocumenTypeTurnReducer: state.getDocumenTypeTurnReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getDocumentTypeTurnAroundTime: (documentId, jwtToken) =>
      dispatch(getDocumentTypeTurnAroundTime(documentId, jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DocumentType);
