/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as Yup from "yup";
// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  createFgMaster,
  editFgMaster,
  getFgMaster,
  getItemMaster,
  getSubModulesByRoleId,
  uploadCsvForFgMaster,
} from "../../store";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";
import TextError from "utils/TextError";
import MDInput from "components/MDInput";
import EditButton from "components/standardized-components/EditButton";
import UploadButton from "components/standardized-components/UploadButton";
import { Backdrop, CircularProgress } from "@mui/material";
import { CSVLink } from "react-csv";
import DownloadButton from "components/standardized-components/DownloadButton";
import { ToastContainer, toast } from "react-toastify";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const FGMaster = ({
  getFgMaster,
  getFgMasterReducer,
  editFgMaster,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getItemMaster,
  uploadCsvForFgMaster,
  itemMaster,
  createFgMaster,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let fgModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "2079be43-00bc-4957-b3d4-733b83747c28"
  );

  let viewFG = fgModule
    ? fgModule.actionId.includes("dab76e85-5670-4f13-a044-d9ab6fc20d9d")
    : null;
  let createFG = fgModule
    ? fgModule.actionId.includes("66d06d60-9659-4e5d-8b40-4284b826b533")
    : null;
  let editFGAccess = fgModule
    ? fgModule.actionId.includes("4dad687b-f2b6-4110-9360-dfa6fefacaf6")
    : null;
  let uploadFG = fgModule
    ? fgModule.actionId.includes("ebde5da0-5d14-4357-9e23-f974119acab1")
    : null;
  let downloadFG = fgModule
    ? fgModule.actionId.includes("2b654e9b-06ad-4fa5-a267-b2370c58be3b")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "FG Code", accessor: "fgModelCode" },
      { Header: "FG ModelName", accessor: "fgModelName" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Created Date", accessor: "createdDate" },

      // { Header: "FG Description", accessor: "fgDescription" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [itemCodeSelectedForEdit, setItemCodeSelectedForEdit] = useState("");

  const [singleFgDataForUpdate, setSingleFgDataForUpdate] = useState({});

  const handleOpenUpdateModal = (singleData) => {
    setItemCodeSelectedForEdit({
      label: `${singleData.itemCode}/${singleData.itemDescription}`,
      value: singleData.itemId,
    });
    setSingleFgDataForUpdate(singleData);
    setOpenUpdateModal(true);
  };
  const handleChangeItemCodeForEdit = (selectedOption) => {
    setItemCodeSelectedForEdit(selectedOption);
  };

  const handleCloseBinMasterModal = () => {
    setOpenUpdateModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };

  const initialValueForUpdate = {
    fgCode: singleFgDataForUpdate.fgModelCode,
    fgDes: singleFgDataForUpdate.fgModelName,
    itemCode: singleFgDataForUpdate.itemCode,
  };

  const initialValues = {
    fgCode: "",
    fgDes: "",
    itemCode: "",
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorForUpdate, setErrorForUpdate] = useState("");
  const [isSuccessUpdate, setIsSuccessUpdate] = useState("");

  useEffect(() => {
    if (isSuccessUpdate) {
      setTimeout(() => {
        setIsSuccessUpdate(false);
      }, 3000);
    }
  }, [isSuccessUpdate]);
  useEffect(() => {
    if (errorForUpdate) {
      setTimeout(() => {
        setErrorForUpdate(false);
      }, 3000);
    }
  }, [errorForUpdate]);

  const [buttonName, setButtonName] = useState("Submit");
  const onSubmit = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      fgModelCode: values.fgCode.trim(),
      fgModelName: values.fgDes.trim(),
      itemId: values.itemCode?.value,
      userId: userId,
    };
    let res = await createFgMaster(payload, jwtToken);
    if (res.status) {
      getFgMaster(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccess(true);
      setTimeout(() => {
        setButtonName("Submit");
        handleCloseCreateFgModal();
      }, 2250);
      resetForm();
    }
    if (!res.status) {
      setErrorMsg(res?.data?.data?.msg);
      setIsError(true);
      setButtonName("Submit");
    }
  };
  const validationSchema = Yup.object({
    fgCode: Yup.string().required("Enter the FG Code!"),
    fgDes: Yup.string().required("Enter the FG Name!"),
  });
  const formikForCreateFg = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });
  const [editButtonName, setEditButtonName] = useState("Submit");

  const onSubmitForEdit = async (values, { resetForm }) => {
    setEditButtonName("Please wait...");
    const payload = {
      fgModelCode: values.fgCode.trim(),
      fgModelName: values.fgDes.trim(),
      userId: userId,
      itemId: itemCodeSelectedForEdit?.value,
      fgModelId: singleFgDataForUpdate.fgModelId,
    };
    let res = await editFgMaster(payload);
    if (res.status) {
      setIsSuccessUpdate(true);
      getFgMaster(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setEditButtonName("Submit");
        handleCloseBinMasterModal();
      }, 2250);
    }
    if (!res.status) {
      setErrorMsgForUpdate(res?.data?.data?.msg);
      setErrorForUpdate(true);
      setEditButtonName("Submit");
    }
    // resetForm();
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForEdit,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const handleItemCodeChange = (selectedOption) => {
    const itemCode = selectedOption ? selectedOption : null;
    const uom = selectedOption
      ? itemCodeOptions.find((item) => item.value === selectedOption.value)?.uom
      : "";

    formikForCreateFg.setFieldValue("itemCode", itemCode);
    formikForCreateFg.setFieldValue("uom", uom);
  };
  const [openCreateFgModal, setOpenCreateFgModal] = useState(false);

  const handleOpenCreateFgModal = () => {
    setOpenCreateFgModal(true);
    formikForCreateFg.setValues(initialValues);
    formikForCreateFg.setTouched({});
    formikForCreateFg.setErrors({});
    getItemMaster(jwtToken);
  };

  const handleCloseCreateFgModal = () => {
    setOpenCreateFgModal(false);
    formikForCreateFg.setFieldValue("itemCode", 0);
    formikForCreateFg.setFieldValue("uom", "");
  };

  useEffect(() => {
    getFgMaster(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getFgMasterReducer.fgMaster.data
      ? getFgMasterReducer.fgMaster.data
      : [];
    setPaginationObj(getFgMasterReducer?.fgMaster?.pagination);
    setLoading(getFgMasterReducer.loading);
    data?.map((finishedGoods) => {
      let des = finishedGoods?.itemDescription;
      let len = desLength;
      const formatDate = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        fgModelCode: finishedGoods.fgModelCode,
        fgModelName: finishedGoods.fgModelName,

        itemCode: finishedGoods.itemCode ? `${finishedGoods.itemCode}` : "",
        itemDescription: `${des ? des.substring(0, len) : ""}`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          <>
            {editFGAccess && (
              <EditButton
                tooltip="Edit"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateModal(finishedGoods)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
          </>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewFG && setState({ ...state, rows: tempFinishedGoods });
  }, [getFgMasterReducer]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode}`,
        value: item.itemId,
        uom: item.uom,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [itemCodeDesOptions, setItemCodeDesOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        uom: item.uom,
      });
    });
    setItemCodeDesOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("fg", uploadCsvData);

    let res = await uploadCsvForFgMaster(data);

    if (res.status) {
      getFgMaster(pageNo, rowsPerPage, searchObject, jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  const headers = [
    {
      label: "fgModelCode",
      key: "fgModelCode",
    },
    {
      label: "fgModelName",
      key: "fgModelName",
    },
    { label: "itemCode", key: "itemCode" },
  ];
  const csvLink = {
    filename: "FG Master.csv",
    headers: headers,
    data: rows,
  };
  const csvLink2 = {
    filename: "FG Master Template.csv",
    headers: headers,
    data: [],
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox color="text" px={2} mt={4}>
                {createFG && (
                  <CreateButton
                    tooltip="Create FG"
                    // disabled={!createAccess}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={handleOpenCreateFgModal}
                    style={{ marginRight: "1rem" }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}
                {downloadFG && (
                  <CSVLink {...csvLink2}>
                    <DownloadButton
                      tooltip="Download Template"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="error"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadButton>
                  </CSVLink>
                )}
                {uploadFG && (
                  <UploadButton
                    tooltip="Upload CSV"
                    //   disabled={!uploadItem}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </UploadButton>
                )}
                {downloadFG && (
                  <CSVLink {...csvLink}>
                    <DownloadDataButton
                      tooltip="Download FG Master List"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="error"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadDataButton>
                  </CSVLink>
                )}
              </MDBox>
              {uploadCsvFilter && (
                <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                  <div>
                    <input
                      className="choose_file"
                      style={{
                        marginTop: "1.5rem",
                        marginLeft: "1.5rem",
                        // marginBottom: "-9rem",
                      }}
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setUploadCsvData(e.target.files[0]);
                      }}
                    />
                    <MDButton
                      disabled={
                        !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                      }
                      variant="contained"
                      color="info"
                      style={{ marginLeft: "0", marginTop: "0rem" }}
                      type="button"
                      onClick={uploadCsvHandler}
                    >
                      UPLOAD
                    </MDButton>
                  </div>
                  <div style={{ marginBottom: "-20px" }}>
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "30px",
                        fontStyle: "italic",
                      }}
                    >
                      <strong>*</strong> Please upload
                      <strong>".csv"</strong> file only
                    </span>
                  </div>
                </div>
              )}
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <Modal
                open={openCreateFgModal}
                onClose={handleCloseCreateFgModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreate}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create FG Master
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateFgModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3} overflow="auto">
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForCreateFg.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          FG Code
                        </MDTypography>

                        <MDInput
                          type="text"
                          name="fgCode"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForCreateFg.handleBlur}
                          onChange={formikForCreateFg.handleChange}
                          value={formikForCreateFg.values.fgCode}
                          error={
                            formikForCreateFg.touched.fgCode &&
                            formikForCreateFg.errors.fgCode &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                        {formikForCreateFg.touched.fgCode &&
                          formikForCreateFg.errors.fgCode && (
                            <TextError msg={formikForCreateFg.errors.fgCode} />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          FG Name
                        </MDTypography>

                        <MDInput
                          multiline
                          rows={2}
                          type="text"
                          name="fgDes"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForCreateFg.handleBlur}
                          onChange={formikForCreateFg.handleChange}
                          value={formikForCreateFg.values.fgDes}
                          error={
                            formikForCreateFg.touched.fgDes &&
                            formikForCreateFg.errors.fgDes &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                        {formikForCreateFg.touched.fgDes &&
                          formikForCreateFg.errors.fgDes && (
                            <TextError msg={formikForCreateFg.errors.fgDes} />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Item Code/Des
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css-for-itemcode"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="itemCode"
                          onBlur={() => {
                            formikForCreateFg.handleBlur({
                              target: { name: "itemCode" },
                            });
                          }}
                          value={formikForCreateFg.values.itemCode}
                          options={itemCodeOptions}
                          onChange={handleItemCodeChange}
                        />

                        {formikForCreateFg.touched.itemCode &&
                          formikForCreateFg.errors.itemCode && (
                            <TextError
                              msg={formikForCreateFg.errors.itemCode}
                            />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          UOM
                        </MDTypography>
                        <MDInput
                          multiline
                          disabled
                          rows={1}
                          type="text"
                          name="uom"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForCreateFg.handleBlur}
                          onChange={formikForCreateFg.handleChange}
                          value={formikForCreateFg.values.uom}
                          error={
                            formikForCreateFg.touched.uom &&
                            formikForCreateFg.errors.uom &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                      </MDBox>
                      <MDBox>
                        <Collapse in={isError}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsError(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccess}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccess(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            FG model created successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={
                            !formikForCreateFg.isValid ||
                            formikForCreateFg.values.itemCode === "" ||
                            buttonName === "Please wait..."
                          }
                        >
                          {buttonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateModal}
                onClose={handleCloseBinMasterModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseBinMasterModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          FG Code
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="fgCode"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.fgCode}
                          error={
                            formikForUpdate.touched.fgCode &&
                            formikForUpdate.errors.fgCode &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.fgCode &&
                          formikForUpdate.errors.fgCode && (
                            <TextError msg={formikForUpdate.errors.fgCode} />
                          )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          FG Name
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="fgDes"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.fgDes}
                          error={
                            formikForUpdate.touched.fgDes &&
                            formikForUpdate.errors.fgDes &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.fgDes &&
                          formikForUpdate.errors.fgDes && (
                            <TextError msg={formikForUpdate.errors.fgDes} />
                          )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Item Code/Description
                        </MDTypography>
                        <Select
                          isClearable
                          className="select-css-for-itemcode"
                          maxMenuHeight={130}
                          placeholder="Select..."
                          name="itemDes"
                          options={itemCodeDesOptions}
                          value={itemCodeSelectedForEdit}
                          onChange={(selectedOption) => {
                            handleChangeItemCodeForEdit(selectedOption);
                            formikForUpdate.setFieldValue(
                              "itemDes",
                              selectedOption
                            );
                          }}
                        />
                        {formikForUpdate.touched.itemDes &&
                          formikForUpdate.errors.itemDes && (
                            <TextError msg={formikForUpdate.errors.itemDes} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={errorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={editButtonName === "Please wait..."}
                        >
                          {editButtonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getFgMasterReducer: state.getFgMasterReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    createFgMaster: (payload, jwtToken) =>
      dispatch(createFgMaster(payload, jwtToken)),
    getFgMaster: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getFgMaster(pageNo, rowsPerPage, searchObject, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    editFgMaster: (payload) => dispatch(editFgMaster(payload)),
    uploadCsvForFgMaster: (payload, jwtToken) =>
      dispatch(uploadCsvForFgMaster(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FGMaster);
