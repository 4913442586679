import axios from "axios";
import Config from "../../../config/index";
import {
  GET_DAILY_VENDOR_PERFORMANCE_FAILURE,
  GET_DAILY_VENDOR_PERFORMANCE_REQUEST,
  GET_DAILY_VENDOR_PERFORMANCE_SUCCESS,
  GET_DATE_RANGE_REQUEST,
  GET_DATE_RANGE_SUCCESS,
  GET_DATE_RANGE_FAILURE,
  GET_MONTHLY_VENDOR_PERFORMANCE_REQUEST,
  GET_MONTHLY_VENDOR_PERFORMANCE_SUCCESS,
  GET_MONTHLY_VENDOR_PERFORMANCE_FAILURE,
} from "./vendorPerformanceTypes";

let baseUrl = Config.baseUrl;

const getDailyVendorPerformanceRequest = () => {
  return {
    type: GET_DAILY_VENDOR_PERFORMANCE_REQUEST,
  };
};

const getDailyVendorPerformanceSuccess = (payload) => {
  return {
    type: GET_DAILY_VENDOR_PERFORMANCE_SUCCESS,
    payload: payload,
  };
};

const getDailyVendorPerformanceFailure = (error) => {
  return {
    type: GET_DAILY_VENDOR_PERFORMANCE_FAILURE,
    payload: error,
  };
};

const getDailyVendorPerformance = (
  vendorCode,
  itemCode,
  successRate,
  dateRange,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDailyVendorPerformanceRequest());
    let url = `${baseUrl}/reports/daily_vendor_performance_report?vendorCode=${vendorCode}&itemCode=${itemCode}&successRate=${successRate}&dateRange=${dateRange}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDailyVendorPerformanceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDailyVendorPerformanceFailure(err.message));
      });
  };
};

const getDateRangeRequest = () => {
  return {
    type: GET_DATE_RANGE_REQUEST,
  };
};

const getDateRangeSuccess = (payload) => {
  return {
    type: GET_DATE_RANGE_SUCCESS,
    payload: payload,
  };
};

const getDateRangeFailure = (error) => {
  return {
    type: GET_DATE_RANGE_FAILURE,
    payload: error,
  };
};

const getDateRange = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDateRangeRequest());
    let url = `${baseUrl}/reports/get_vendor_performance_date_range`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDateRangeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDateRangeFailure(err.message));
      });
  };
};

const getMonthlyVendorPerformanceRequest = () => {
  return {
    type: GET_MONTHLY_VENDOR_PERFORMANCE_REQUEST,
  };
};

const getMonthlyVendorPerformanceSuccess = (payload) => {
  return {
    type: GET_MONTHLY_VENDOR_PERFORMANCE_SUCCESS,
    payload: payload,
  };
};

const getMonthlyVendorPerformanceFailure = (error) => {
  return {
    type: GET_MONTHLY_VENDOR_PERFORMANCE_FAILURE,
    payload: error,
  };
};

const getMonthlyVendorPerformance = (month, itemCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMonthlyVendorPerformanceRequest());
    let url = `${baseUrl}/reports/monthly_vendor_performance_report?itemCode=${itemCode}&month=${month}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMonthlyVendorPerformanceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getMonthlyVendorPerformanceFailure(err.message));
      });
  };
};

export { getDailyVendorPerformance, getDateRange, getMonthlyVendorPerformance };
