/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Functions from store
import {
  getSubModulesByRoleId,
  getBins,
  deleteBin,
  getBinTypes,
  createBinMaster,
  editBin,
  createBinType,
  uploadBinMaster,
  printBin,
} from "../../../store";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

//  config file
import Config from "../../../config/index";

// Cookies and config
import Cookies from "universal-cookie";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { Backdrop, CircularProgress } from "@mui/material";

import { addMinutes, format } from "date-fns";

import PrintButton from "components/standardized-components/PrintButton";

const cookies = new Cookies();

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const BinMaster = ({
  getBinsReducer,
  getBintypesReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getBins,
  deleteBin,
  createBinMaster,
  getBinTypes,
  editBin,
  createBinType,
  uploadBinMaster,
  printBin,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  const desLength = Config.descriptionLength;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let binMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "f8e511fa-5d70-4590-b58b-443007340e0a"
  );

  let addBinAccess = binMasterModule
    ? binMasterModule.actionId.includes("d9bea4f9-ee24-44aa-87fb-bb15e23dad1c")
    : null;
  let addBinTypeAccess = binMasterModule
    ? binMasterModule.actionId.includes("69dc4758-6b49-400f-9f6b-8d834836ac0d")
    : null;

  let uploadAccess = binMasterModule
    ? binMasterModule.actionId.includes("4ce3bd7c-d5aa-4ffe-8c15-b3365fa3ffeb")
    : null;
  let downloadBinAccess = binMasterModule
    ? binMasterModule.actionId.includes("90a21ae3-e086-422d-82e0-42c1875101a0")
    : null;
  let editBinAccess = binMasterModule
    ? binMasterModule.actionId.includes("13ac3e2c-84a0-4851-a1e4-06c13432ae7d")
    : null;
  let deleteBinAccess = binMasterModule
    ? binMasterModule.actionId.includes("af9efe80-b39c-4313-95e5-583826d87207")
    : null;
  let printBinAccess = binMasterModule
    ? binMasterModule.actionId.includes("81fe8bd9-77d3-4804-8e21-3daa4747daa1")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getBins(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Bin Code", accessor: "binCode" },

      { Header: "Bin Type", accessor: "binType" },
      { Header: "Status", accessor: "status" },
      { Header: "Created Date", accessor: "createdDate" },

      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const [singleDataForDelete, setSingleDataForDelete] = useState({});
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);

  const handleOpenDeleteUserModal = (data) => {
    setOpenDeleteUserModal(true);
    setSingleDataForDelete(data);
  };

  const handleCloseDeleteUserModal = () => {
    setOpenDeleteUserModal(false);
  };

  const [isErrorDeleteUser, setIsErrorDeleteUser] = useState(false);
  const [errorMsgForDelete, setErrorMsgForDelete] = useState(false);

  useEffect(() => {
    if (isErrorDeleteUser) {
      setTimeout(() => {
        setIsErrorDeleteUser(false);
      }, 3000);
    }
  }, [isErrorDeleteUser]);

  const [deleteButtonName, setDeleteButtonName] = useState("Delete");

  const deleteTripNo = async () => {
    setDeleteButtonName("Please wait...");
    let binCode = singleDataForDelete.binCode;
    let res = await deleteBin(binCode);

    if (res.status) {
      await getBins(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleCloseDeleteUserModal();
        setDeleteButtonName("Delete");
      }, 2250);
    }

    if (!res.status) {
      setIsErrorDeleteUser(true);
      setErrorMsgForDelete(res?.data?.data?.msg);
      setDeleteButtonName("Delete");
    }
  };

  const [singleDataForPrint, setSingleDataForPrint] = useState({});
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const handleOpenPrintModal = (data) => {
    setOpenPrintModal(true);
    setSingleDataForPrint(data);
  };

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const [isErrorPrint, setIsErrorPrint] = useState(false);
  const [isSuccessPrint, setIsSuccessPrint] = useState(false);
  const [errorMsgForPrint, setErrorMsgForPrint] = useState(false);

  useEffect(() => {
    if (isErrorPrint) {
      setTimeout(() => {
        setIsErrorPrint(false);
      }, 3000);
    }
  }, [isErrorPrint]);

  useEffect(() => {
    if (isSuccessPrint) {
      setTimeout(() => {
        setIsSuccessPrint(false);
      }, 3000);
    }
  }, [isSuccessPrint]);

  const printBinHandler = async () => {
    let payload = {
      binId: singleDataForPrint.binId,
      printerId: printerId,
    };
    let res = await printBin(payload);

    if (res.status) {
      await getBins(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessPrint(true);
      setTimeout(() => {
        handleClosePrintModal();
      }, 3000);
    }

    if (!res.status) {
      setIsErrorPrint(true);
      setErrorMsgForPrint(res?.data?.data?.msg);
    }
  };

  const [listOfBinTypesSelectedForEdit, setListOfBinTypesSelectedForEdit] =
    useState("");

  const [singleBinDataForUpdate, setSingleBinDataForUpdate] = useState({});

  const initialValueForUpdate = {
    binCode: singleBinDataForUpdate.binCode,

    binType: singleBinDataForUpdate.binType,
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const [errorMsg, setErrorMsg] = useState("");
  const [editButtonName, setEditButtonName] = useState("Submit");

  const onSubmitForUpdate = async (values) => {
    setEditButtonName("Please wait...");
    let payload = {
      binId: singleBinDataForUpdate.binId,
      binCode: values.binCode,
      binTypeId: listOfBinTypesSelectedForEdit.value,

      userId: userId,
    };

    let res = await editBin(payload, jwtToken);

    if (res.status) {
      setIsSuccessForUpdate(true);
      getBins(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setEditButtonName("Submit");
        handleCloseBinMasterModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsg(res?.data?.data?.msg);
      setEditButtonName("Submit");
    }
  };

  const [openUpdateBinMasterModal, setOpenUpdateBinMasterModal] =
    useState(false);

  const handleOpenUpdateBinMasterModal = (singleData) => {
    setListOfBinTypesSelectedForEdit({
      label: singleData.binType,
      value: singleData.binTypeId,
    });
    setSingleBinDataForUpdate(singleData);
    setOpenUpdateBinMasterModal(true);
    getBinTypes(jwtToken);
  };

  const handleCloseBinMasterModal = () => {
    setOpenUpdateBinMasterModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempBinMaster = [];
    let data = getBinsReducer.bins.data ? getBinsReducer.bins.data : [];
    setPaginationObj(getBinsReducer.bins?.pagination);
    setLoading(getBinsReducer.loading);
    data.map((singleData) => {
      const formatDate = new Date(singleData.cd);
      const dataObject = {
        // privateKey: singleData.binCode,
        id: singleData.binId,
        binCode: singleData.binCode,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        binName: singleData.binName,
        binType: singleData.binType,
        weight: singleData.weight,
        binPackType: singleData.binPackType,
        nob: singleData.nob,
        status: singleData.isEmpty === 1 ? "Empty" : "Occupied",
        action: (
          <div>
            {editBinAccess && (
              <EditButton
                tooltip="Edit"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateBinMasterModal(singleData)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}

            {deleteBinAccess && (
              <DeleteButton
                tooltip="Delete"
                disabled={singleData.isEditable === 0 ? true : false}
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteUserModal(singleData)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}

            {printBinAccess && (
              <PrintButton
                tooltip="Print"
                // disabled={singleData.isEditable === 0 ? true : false}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenPrintModal(singleData)}
              >
                <Icon>print</Icon>
              </PrintButton>
            )}
          </div>
        ),
      };
      tempBinMaster.push(dataObject);
    });

    setState({ ...state, rows: tempBinMaster });
  }, [getBinsReducer]);

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [listOfBinTypesOptions, setListOfBinTypesOptions] = useState([]);
  const [listOfBinTypesSelected, setListOfBinTypesSelected] = useState("");

  const handleChangeListOfBinTypes = (selectedOption) => {
    setListOfBinTypesSelected(selectedOption);
  };

  const handleChangeListOfBinTypesForEdit = (selectedOption) => {
    setListOfBinTypesSelectedForEdit(selectedOption);
  };

  // useEffect(() => {
  //   let tempListOfBinTypesOptions = [];
  //   let data = getBintypesReducer.binTypes.data
  //     ? getBintypesReducer.binTypes.data
  //     : [];
  //   data.map((bin) => {
  //     tempListOfBinTypesOptions.push({
  //       label: bin.binType,
  //       value: bin.binTypeId,
  //     });
  //   });
  //   setListOfBinTypesOptions(tempListOfBinTypesOptions);
  // }, [getBintypesReducer]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getBintypesReducer.binTypes.data
      ? getBintypesReducer.binTypes.data
      : [];
    data.map((item) => {
      let des = item?.binTypeCode;
      let len = desLength;
      tempItemCodeOptions.push({
        label: ` ${des ? des.substring(0, len) : ""}/ ${item.binType}  `,
        value: item.binTypeId,
        des1: `${des ? des.substring(0, len) : ""}`,
        des2: `${item.binType}`,
      });
    });
    setListOfBinTypesOptions(tempItemCodeOptions);
  }, [getBintypesReducer]);

  const [isSuccessCreateBin, setIsSuccessCreateBin] = useState(false);
  const [isErrorCreateBin, setIsErrorCreateBin] = useState(false);

  useEffect(() => {
    if (isErrorCreateBin) {
      setTimeout(() => {
        setIsErrorCreateBin(false);
      }, 3000);
    }
  }, [isErrorCreateBin]);

  useEffect(() => {
    if (isSuccessCreateBin) {
      setTimeout(() => {
        setIsSuccessCreateBin(false);
      }, 3000);
    }
  }, [isSuccessCreateBin]);

  const [createBinModal, setCreateBinModal] = useState(false);
  const handleCreateBinModal = () => {
    setCreateBinModal(true);
    getBinTypes(jwtToken);
    setListOfBinTypesSelected("");

    formikForAddBin.setValues(initialValueForCreateBin);
    formikForAddBin.setTouched({});
    formikForAddBin.setErrors({});
  };
  const handleCreateCloseBinModal = () => {
    setCreateBinModal(false);
    getBinTypes(jwtToken);
    setListOfBinTypesSelected("");

    formikForAddBin.setValues(initialValueForCreateBin);
    formikForAddBin.setTouched({});
    formikForAddBin.setErrors({});
  };

  const initialValueForCreateBin = {
    binCode: "",
    nob: "",
    binType: "",
  };

  const validationSchemaForCreateBin = Yup.object({
    binType: Yup.string().required("Select the bin type"),
    nob: Yup.string().required("Enter the nob"),
    // binName: Yup.string().required("Enter the bin Name"),
  });

  const [buttonName, setButtonName] = useState("Create Bin");

  const onSubmitForCreateBin = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let payload = {
      binDescription: values.binCode.trim(),
      nob: values.nob.trim(),
      binTypeId: listOfBinTypesSelected?.value,
      binTypeCode: listOfBinTypesSelected?.des1,
      binPackType: listOfBinTypesSelected?.des2,
      userId: userId,
      // binName: values.binName,
    };
    let res = await createBinMaster(payload, jwtToken);

    if (res.status) {
      getBins(pageNo, rowsPerPage, searchObject, jwtToken);
      setIsSuccessCreateBin(true);
      setTimeout(() => {
        setButtonName("Create Bin");
        handleCreateCloseBinModal();
      }, 2250);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreateBin(true);
      setErrorMsgForCreate(res?.data?.data?.msg);
      setButtonName("Create Bin");
    }
  };

  const formikForAddBin = useFormik({
    initialValues: initialValueForCreateBin,
    onSubmit: onSubmitForCreateBin,
    validationSchema: validationSchemaForCreateBin,
  });

  const [isSuccessCreateBinType, setIsSuccessCreateBinType] = useState(false);
  const [isErrorCreateBinType, setIsErrorCreateBinType] = useState(false);

  useEffect(() => {
    if (isErrorCreateBinType) {
      setTimeout(() => {
        setIsErrorCreateBinType(false);
      }, 3000);
    }
  }, [isErrorCreateBinType]);

  useEffect(() => {
    if (isSuccessCreateBinType) {
      setTimeout(() => {
        setIsSuccessCreateBinType(false);
      }, 3000);
    }
  }, [isSuccessCreateBinType]);

  const [createBinTypeModal, setCreateBinTypeModal] = useState(false);
  const handleCreateBinTypeModal = () => {
    setCreateBinTypeModal(true);

    formikForBinType.setValues(initialValueForCreateBinType);
    formikForBinType.setTouched({});
    formikForBinType.setErrors({});
  };
  const handleCreateCloseBinTypeModal = () => {
    setCreateBinTypeModal(false);

    formikForBinType.setValues(initialValueForCreateBinType);
    formikForBinType.setTouched({});
    formikForBinType.setErrors({});
  };

  const initialValueForCreateBinType = {
    binTypes: "",
    binCode: "",
  };

  const validationSchemaForCreateBinType = Yup.object({
    binTypes: Yup.string().required("Select the bin type"),
    binCode: Yup.string().required("Enter the bin type code"),
  });

  const [errorMsgForCreateBin, setErrorMsgForCreateBin] = useState("");

  const [createBinButtonName, setCreateBinButtonName] =
    useState("Create Bin Type");

  const onSubmitForCreateBinType = async (values, { resetForm }) => {
    setCreateBinButtonName("Please wait...");
    let payload = {
      binType: values.binTypes.trim(),
      binTypeCode: values.binCode.trim(),
      userId: userId,
    };

    let res = await createBinType(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreateBinType(true);
      getBins(pageNo, rowsPerPage, searchObject, jwtToken);
      getBinTypes(jwtToken);
      setTimeout(() => {
        setCreateBinButtonName("Create Bin Type");
        handleCreateCloseBinTypeModal();
      }, 2250);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreateBinType(true);
      setErrorMsgForCreateBin(res?.data?.data?.msg);
      setCreateBinButtonName("Create Bin Type");
    }
  };

  const formikForBinType = useFormik({
    initialValues: initialValueForCreateBinType,
    onSubmit: onSubmitForCreateBinType,
    validationSchema: validationSchemaForCreateBinType,
  });

  const headers = [
    {
      label: "binCode",
      key: "binCode",
    },
    // {
    //   label: "binName",
    //   key: "binName",
    // },
    { label: "binType", key: "binType" },
  ];

  const csvLink = {
    filename: "Bin Template.csv",
    headers: headers,
    data: [],
  };

  const csvLink2 = {
    filename: "Bin Master.csv",
    headers: headers,
    data: rows,
  };

  const DataUploadNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };
  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("bins", uploadCsvData);

    let res = await uploadBinMaster(data, jwtToken);

    if (res.status) {
      setUploadCsvFilter(false);
      getBins(pageNo, rowsPerPage, searchObject, jwtToken);
      DataUploadNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  return (
    <MDBox pt={0} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            mb={-3}
            mt={-3}
          >
            <MDBox mt={2}>
              {addBinAccess && (
                <Tooltip title="Create Bin">
                  <MDButton
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    iconOnly
                    type="button"
                    onClick={handleCreateBinModal}
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              )}

              {addBinTypeAccess && (
                <Tooltip title="Create Bin Type">
                  <MDButton
                    style={{ marginRight: "1rem" }}
                    color="warning"
                    variant="contained"
                    iconOnly
                    type="button"
                    onClick={handleCreateBinTypeModal}
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              )}
              <CSVLink {...csvLink}>
                <Tooltip title="Download Template">
                  <MDButton
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="error"
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
              </CSVLink>

              {uploadAccess && (
                <Tooltip title="Upload Bin Master">
                  <MDButton
                    // disabled={!uploadNewWarehouse}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </MDButton>
                </Tooltip>
              )}

              {downloadBinAccess && (
                <CSVLink {...csvLink2}>
                  <Tooltip title="Download Bin Master List">
                    <MDButton
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="success"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </MDButton>
                  </Tooltip>
                </CSVLink>
              )}
            </MDBox>
          </MDBox>

          {uploadCsvFilter && (
            <div style={{ marginLeft: "1" }}>
              <div>
                <input
                  className="choose_file"
                  style={{
                    marginTop: "1.5rem",
                    marginLeft: "1.5rem",
                    // marginBottom: "-9rem",
                  }}
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    setUploadCsvData(e.target.files[0]);
                  }}
                />
                <MDButton
                  disabled={
                    !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                  }
                  variant="contained"
                  color="info"
                  style={{
                    marginLeft: { xs: "1.5rem", lg: "1" },
                    marginTop: "0rem",
                  }}
                  type="button"
                  onClick={uploadCsvHandler}
                >
                  UPLOAD
                </MDButton>
              </div>
              <div>
                <span
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginLeft: "30px",
                    fontStyle: "italic",
                  }}
                >
                  <strong>*</strong> Please upload
                  <strong>".csv"</strong> file only
                </span>
              </div>
            </div>
          )}

          <MDBox pt={1}>
            {/* {!loading ? ( */}

            <DataTable
              table={{ columns, rows }}
              isSorted={true}
              tableSearch={true}
              options={{ draggable: false }}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 10, 15, 20, 25, 30],
              }}
              showTotalEntries={true}
              noEndBorder
              serverSidePagination={true}
              paginationObj={paginationObj}
              pageNoValue={pageNo}
              rowsPerPageValue={rowsPerPage}
              pageNoProp={importPageNo}
              rowsPerPageProp={importRowsPerPage}
              searchProp={importSearch}
            />
          </MDBox>

          <Modal
            open={openDeleteUserModal}
            onClose={handleCloseDeleteUserModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={deleteStyle}>
              <MDTypography>Are you sure you want to delete?</MDTypography>
              <MDBox mt={2}>
                <MDButton
                  // style={{ width: "48%", marginRight: "0.5rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleCloseDeleteUserModal}
                >
                  Cancel
                </MDButton>
                <MDButton
                  style={{ marginLeft: "0.5rem" }}
                  color="error"
                  onClick={deleteTripNo}
                  disabled={deleteButtonName === "Please wait..."}
                >
                  {deleteButtonName}
                </MDButton>
                <MDBox mt={2}>
                  <Collapse in={isErrorDeleteUser}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDeleteUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsgForDelete}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
          <Modal
            open={openPrintModal}
            onClose={handleClosePrintModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={deleteStyle}>
              <MDTypography>Are you sure you want to print?</MDTypography>
              <MDBox mt={2}>
                <MDButton
                  // style={{ width: "48%", marginRight: "0.5rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleClosePrintModal}
                >
                  Cancel
                </MDButton>
                <MDButton
                  style={{ marginLeft: "0.5rem" }}
                  color="success"
                  onClick={printBinHandler}
                >
                  Print
                </MDButton>
                <MDBox mt={2}>
                  <Collapse in={isSuccessPrint}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessPrint(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Bin printed successfully
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorPrint}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDeleteUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {/* Could not delete Bin  */}
                      {errorMsgForPrint}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>

          <Modal
            open={openUpdateBinMasterModal}
            onClose={handleCloseBinMasterModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Update
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseBinMasterModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForUpdate.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Bin Type
                    </MDTypography>
                    <Select
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Select..."
                      name="binType"
                      options={listOfBinTypesOptions}
                      value={listOfBinTypesSelectedForEdit}
                      onChange={(selectedOption) => {
                        handleChangeListOfBinTypesForEdit(selectedOption);
                        formikForAddBin.handleChange("binType")(
                          selectedOption.label
                        );
                      }}
                    />
                    {formikForAddBin.touched.binType &&
                      formikForAddBin.errors.binType && (
                        <TextError msg={formikForAddBin.errors.binType} />
                      )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorForUpdate}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorForUpdate(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessForUpdate}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorForUpdate(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Bins updated successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="success"
                      fullWidth
                      type="submit"
                      disabled={
                        !formikForUpdate.isValid ||
                        editButtonName === "Please wait..."
                      }
                    >
                      {editButtonName}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>

          <Modal
            open={createBinModal}
            onClose={handleCreateCloseBinModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Create Bin
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCreateCloseBinModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForAddBin.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Bin Type Code / Bin Type
                    </MDTypography>
                    <Select
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Select..."
                      name="binType"
                      options={listOfBinTypesOptions}
                      value={listOfBinTypesSelected}
                      onChange={(selectedOption) => {
                        handleChangeListOfBinTypes(selectedOption);
                        formikForAddBin.handleChange("binType")(
                          selectedOption.label
                        );
                      }}
                    />
                    {formikForAddBin.touched.binType &&
                      formikForAddBin.errors.binType && (
                        <TextError msg={formikForAddBin.errors.binType} />
                      )}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">NOB</MDTypography>
                    <MDInput
                      type="number  "
                      name="nob"
                      fullWidth
                      autoComplete="off"
                      onBlur={formikForAddBin.handleBlur}
                      onChange={formikForAddBin.handleChange}
                      value={formikForAddBin.values.nob}
                      error={
                        formikForAddBin.touched.nob &&
                        formikForAddBin.errors.nob &&
                        true
                      }
                      inputProps={{
                        maxLength: 45,
                      }}
                    />
                    {formikForAddBin.touched.nob &&
                      formikForAddBin.errors.nob && (
                        <TextError msg={formikForAddBin.errors.nob} />
                      )}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">BIN Description</MDTypography>
                    <MDInput
                      type="text"
                      name="binCode"
                      fullWidth
                      autoComplete="off"
                      onBlur={formikForAddBin.handleBlur}
                      onChange={formikForAddBin.handleChange}
                      value={formikForAddBin.values.binCode}
                      error={
                        formikForAddBin.touched.binCode &&
                        formikForAddBin.errors.binCode &&
                        true
                      }
                      inputProps={{
                        maxLength: 45,
                      }}
                    />
                    {formikForAddBin.touched.binCode &&
                      formikForAddBin.errors.binCode && (
                        <TextError msg={formikForAddBin.errors.binCode} />
                      )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorCreateBin}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreateBin(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForCreate}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessCreateBin}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessCreateBin(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Bin created successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={
                        !formikForAddBin.isValid ||
                        buttonName === "Please wait..."
                      }
                    >
                      {buttonName}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>

          <Modal
            open={createBinTypeModal}
            onClose={handleCreateCloseBinTypeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Create Bin Type
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCreateCloseBinTypeModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForBinType.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Bin Type
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="binTypes"
                      fullWidth
                      autoComplete="off"
                      onChange={formikForBinType.handleChange}
                      onBlur={formikForBinType.handleBlur}
                      value={formikForBinType.values.binTypes}
                      error={
                        formikForBinType.touched.binTypes &&
                        formikForBinType.errors.binTypes &&
                        true
                      }
                      inputProps={{
                        maxLength: 45,
                      }}
                    />
                    {formikForBinType.touched.binTypes &&
                      formikForBinType.errors.binTypes && (
                        <TextError msg={formikForBinType.errors.binTypes} />
                      )}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Bin Type Code
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="binCode"
                      fullWidth
                      autoComplete="off"
                      onChange={formikForBinType.handleChange}
                      onBlur={formikForBinType.handleBlur}
                      value={formikForBinType.values.binCode}
                      error={
                        formikForBinType.touched.binCode &&
                        formikForBinType.errors.binCode &&
                        true
                      }
                      inputProps={{
                        maxLength: 45,
                      }}
                    />
                    {formikForBinType.touched.binCode &&
                      formikForBinType.errors.binCode && (
                        <TextError msg={formikForBinType.errors.binCode} />
                      )}
                  </MDBox>
                  <MDBox>
                    <Collapse in={isErrorCreateBinType}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreateBinType(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForCreateBin}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessCreateBinType}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessCreateBinType(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Bin Type created successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="warning"
                      fullWidth
                      type="submit"
                      disabled={
                        !formikForBinType.isValid ||
                        createBinButtonName === "Please wait..."
                      }
                    >
                      {createBinButtonName}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
        </Grid>
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    binMasterReducer: state.binMasterReducer,
    getBinsReducer: state.getBinsReducer,
    getBintypesReducer: state.getBintypesReducer,
    listOfBinTypesReducer: state.listOfBinTypesReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getBins: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getBins(pageNo, rowsPerPage, searchObject, jwtToken)),
    getBinTypes: (jwtToken) => dispatch(getBinTypes(jwtToken)),
    deleteBin: (binCode, jwtToken) => dispatch(deleteBin(binCode, jwtToken)),
    printBin: (payload, jwtToken) => dispatch(printBin(payload, jwtToken)),
    createBinMaster: (payload, jwtToken) =>
      dispatch(createBinMaster(payload, jwtToken)),
    createBinType: (payload, jwtToken) =>
      dispatch(createBinType(payload, jwtToken)),
    editBin: (payload, jwtToken) => dispatch(editBin(payload, jwtToken)),
    uploadBinMaster: (payload, jwtToken) =>
      dispatch(uploadBinMaster(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(BinMaster);
