/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Formik, Form, Field, FieldArray } from "formik";
import CancelIcon from "@mui/icons-material/Clear";
import * as Yup from "yup";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { ToastContainer } from "react-toastify";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";

import MDInput from "components/MDInput";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  createAssemblyMaster,
  createAssemblyStationMaster,
  deleteAssembly,
  deleteStation,
  editAssembly,
  editStation,
  getAssemblyMasterWithPagination,
  getAssemblyTypes,
  getLevel2ByLevel,
  getLocationsForAssembly,
  getStationsByAssembly,
  getSubModulesByRoleId,
  getUnmappedStations,
  getWarehouseForAssembly,
  mapAssembly,
} from "../../store";
// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";
import TextError from "utils/TextError";
import ViewButton from "components/standardized-components/ViewButton";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import CreateButton from "components/standardized-components/CreateButton";
import { Backdrop, CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";
import StandardButton from "components/standardized-components/StandardButton";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const styleForView = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 890,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForCreateAssembly = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 870 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};
const styleForCreateAssemblyForUpdate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 870 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const AssemblyMaster = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  deleteAssembly,
  getLocationsForAssembly,
  getLevel2ByLevelReducer,
  deleteStation,
  createAssemblyStationMaster,
  editAssembly,
  getAssemblyTypesReducer,
  mapAssembly,
  getAssemblyMasterWithPagination,
  getWarehousesForAssemblyReducer,
  editStation,
  getLevel2ByLevel,
  getLocationsForAssemblyReducer,
  getAssemblyMasterReducer,
  getStationMasterReducer,
  getUnmappedStationsReducer,
  createAssemblyMaster,
  getAssemblyTypes,
  getUnmappedStations,
  getWarehouseForAssembly,
  getStationsByAssembly,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [zoneIdSelected, setZoneIdSelected] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getWarehouseForAssembly(jwtToken);
  }, []);
  useEffect(() => {
    getAssemblyTypes(jwtToken);
  }, []);

  useEffect(() => {
    getUnmappedStations(jwtToken);
  }, []);

  useEffect(() => {
    getLocationsForAssembly(warehouseIdSelected?.value, jwtToken);
  }, [warehouseIdSelected]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let fgModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "0acc4eff-2e5d-4a75-ad55-80a2e2f6e773"
  );

  let viewAssembly = fgModule
    ? fgModule.actionId.includes("880e3cd0-881f-4b3e-9393-39282f12b44b")
    : null;
  let createAssemblyAccess = fgModule
    ? fgModule.actionId.includes("7952bbb2-1fa3-480b-adae-f22d8f78dbc3")
    : null;
  let EditAssemblyAccess = fgModule
    ? fgModule.actionId.includes("756394b9-9d64-4c20-98af-0752ba759106")
    : null;
  let deleteAssemblyAccess = fgModule
    ? fgModule.actionId.includes("df0e5884-7b20-4c46-9673-68e67096d3ee")
    : null;
  let addAssemblyAccess = fgModule
    ? fgModule.actionId.includes("21a1e0c6-d3bf-4129-b268-347fbb969375")
    : null;
  let viewStation = fgModule
    ? fgModule.actionId.includes("094c44a3-91ab-400f-9df3-1f65889fb0d3")
    : null;
  let editStationAccess = fgModule
    ? fgModule.actionId.includes("54392a22-3fd7-4f04-b8ce-a6b8d7831b38")
    : null;
  let deleteStationAccess = fgModule
    ? fgModule.actionId.includes("1b60bc71-fa56-4538-8091-8059f2861650")
    : null;
  let mapAccess = fgModule
    ? fgModule.actionId.includes("dee1caae-9609-4340-a8b0-c1307774d02f")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [paginationObj2, setPaginationObj2] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };
  useEffect(() => {
    getAssemblyMasterWithPagination(
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );
  }, [pageNo, rowsPerPage, searchObject]);
  const [state, setState] = useState({
    columns: [
      { Header: "Assembly", accessor: "assembly" },
      { Header: "Assembly Type", accessor: "assemblyType" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Zone", accessor: "zone" },
      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateStationModal, setOpenUpdateStationModal] = useState(false);

  const [singleFgDataForUpdate, setSingleFgDataForUpdate] = useState({});
  const [singleDataForUpdate, setSingleDataForUpdate] = useState({});

  const handleOpenUpdateModal = (singleData) => {
    setAssemblyTypesSelectedForEdit({
      label: singleData.assemblyType,
      value: singleData.assemblyTypeId,
    });
    setSingleFgDataForUpdate(singleData);
    setOpenUpdateModal(true);
  };

  const handleChangeAssemblyTypesForEdit = (selectedOption) => {
    setAssemblyTypesSelectedForEdit(selectedOption);
  };
  const handleOpenUpdateForStationModal = (singleData) => {
    setSingleDataForUpdate(singleData);
    setOpenUpdateStationModal(true);
  };

  const handleCloseModal = () => {
    setOpenUpdateModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };
  const handleCloseStationModal = () => {
    setOpenUpdateStationModal(false);
    formikForStationUpdate.setErrors({});
    formikForStationUpdate.setValues(initialValueForStationUpdate);
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);
  const [isErrorForStationUpdate, setIsErrorForStationUpdate] = useState(false);
  const [isSuccessForStationUpdate, setIsSuccessForStationUpdate] =
    useState(false);
  const [errorMsgForStationUpdate, setErrorMsgForStationUpdate] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [assemblyId, setAssemblyId] = useState("");
  const [assemblyIdForCreate, setAssemblyIdForCreate] = useState("");
  const [assemblyType, setassemblyType] = useState("");

  useEffect(() => {
    getStationsByAssembly(
      assemblyId,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    );
  }, [assemblyId, pageNo, rowsPerPage, searchObject]);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  useEffect(() => {
    if (isErrorForStationUpdate) {
      setTimeout(() => {
        setIsErrorForStationUpdate(false);
      }, 3000);
    }
  }, [isErrorForStationUpdate]);

  useEffect(() => {
    if (isSuccessForStationUpdate) {
      setTimeout(() => {
        setIsSuccessForStationUpdate(false);
      }, 3000);
    }
  }, [isSuccessForStationUpdate]);

  const initialValueForUpdate = {
    assembly: singleFgDataForUpdate.assembly,
  };
  const initialValueForStationUpdate = {
    station: singleDataForUpdate.station,
  };

  const [updateButtonName, setUpdateButtonName] = useState("Submit");
  const [deleteButtonName, setDeleteButtonName] = useState("Delete");

  const onSubmitForUpdate = async (values) => {
    setUpdateButtonName("Please wait...");
    let payload = {
      assemblyId: singleFgDataForUpdate.assemblyId,
      assemblyTypeId: assemblyTypesSelectedForEdit.value,
      assembly: values.assembly.trim(),
      userId: userId,
    };
    let res = await editAssembly(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getAssemblyMasterWithPagination(
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setTimeout(() => {
        handleCloseModal();
        setUpdateButtonName("Submit");
      }, 3000);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res?.data?.data?.msg);
      setUpdateButtonName("Submit");
    }
  };
  const onSubmitForStationUpdate = async (values) => {
    let payload = {
      stationId: singleDataForUpdate.stationId,
      station: values.station.trim(),
      userId: userId,
    };
    let res = await editStation(payload, jwtToken);

    if (res.status) {
      setIsSuccessForStationUpdate(true);
      getAssemblyMasterWithPagination(
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      getStationsByAssembly(
        assemblyId,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setTimeout(() => {
        handleCloseStationModal();
      }, 3000);
    }
    if (!res.status) {
      setIsErrorForStationUpdate(true);
      setErrorMsgForStationUpdate(res?.data?.data?.msg);
    }
  };

  const validationSchemaForStation = Yup.object({
    itemsForStation: Yup.array().of(
      Yup.object({
        stationOrder: Yup.number()
          .min(0, "Station Order cannot be negative")
          .required("Station Order is required"),
        station: Yup.string().required("Station is required"),

        // stationLocationId: Yup.number().required("Select a section"),
      })
    ),
  });
  const validationSchemaForAssembly = Yup.object({
    assembly: Yup.string().required("Enter the Assembly Name!"),
    // assemblyType: Yup.object().nullable().required("Select an AssemblyType!"), // Nullable to handle clearable case
  });

  const [isFlagMode, setIsFlagMode] = useState(false);

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForAssembly,
    enableReinitialize: true,
  });
  const validationSchemaForUpdate = Yup.object({
    station: Yup.string().required("Enter the Station Name!"),
  });
  const formikForStationUpdate = useFormik({
    initialValues: initialValueForStationUpdate,
    validationSchema: validationSchemaForUpdate,
    onSubmit: onSubmitForStationUpdate,
    enableReinitialize: true,
  });

  const [assemblyTypesSelectedForEdit, setAssemblyTypesSelectedForEdit] =
    useState("");

  const [ZoneOptions, setZoneOptions] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLocationsForAssemblyReducer.locations
      ? getLocationsForAssemblyReducer.locations
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setZoneOptions(tempLevelOptions);
  }, [getLocationsForAssemblyReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  const [selectedAssemblyType, setAssemblyType] = useState("");
  const handleAssemblyTypeChange = (selectedActivity) => {
    setAssemblyType(selectedActivity);
  };

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = getWarehousesForAssemblyReducer.warehouses
      ? getWarehousesForAssemblyReducer.warehouses
      : [];

    data.map((warehouse) => {
      if (warehouse.isActive === 1) {
        tempWarehouseOptions.push({
          label: warehouse.warehouseCode,
          value: warehouse.warehouseId,
          key: warehouse.warehouseCode,
        });
      }
    });
    setWarehouseOptions(tempWarehouseOptions);
    if (tempWarehouseOptions.length) {
      setWarehouseIdSelected(tempWarehouseOptions[0]);
    }
  }, [getWarehousesForAssemblyReducer]);

  const [unmappedStationsOptions, setUnmappedStationsOptions] = useState([]);
  useEffect(() => {
    let tempAssetTypeOptions = [];
    let data = getUnmappedStationsReducer.stations.data
      ? getUnmappedStationsReducer.stations.data
      : [];
    data.map((asset) => {
      tempAssetTypeOptions.push({
        label: asset.station,
        value: asset.stationId,
      });
    });

    setUnmappedStationsOptions(tempAssetTypeOptions);
  }, [getUnmappedStationsReducer]);

  const handleCloseCreateAssemblyModal = () => {
    setCreateAssemblyModal(false);
    setIsFlagMode(false);
    setAssemblyType("");
    if (warehouseOptions.length > 0) {
      setWarehouseIdSelected(warehouseOptions[0]);
    }
    setZoneIdSelected("");
    setIsZoneFieldDisabled(false);
  };

  const handleChangeWarehouse = (selectedData) => {
    setWarehouseIdSelected(selectedData);
    setZoneIdSelected("");
  };
  const handleZoneIDChange = (selectedRack) => {
    setZoneIdSelected(selectedRack);
  };

  const [isSuccessForCreateAssembly, setIsSuccessForCreateAssembly] =
    useState(false);
  const [isErrorForCreateAssembly, setIsErrorForCreateAssembly] =
    useState(false);

  useEffect(() => {
    if (isSuccessForCreateAssembly) {
      setTimeout(() => {
        setIsSuccessForCreateAssembly(false);
      }, 3000);
    }
  }, [isSuccessForCreateAssembly]);

  useEffect(() => {
    if (isErrorForCreateAssembly) {
      setTimeout(() => {
        setIsErrorForCreateAssembly(false);
      }, 3000);
    }
  }, [isErrorForCreateAssembly]);

  const [selectedStationId, setSelectedStationId] = useState("");

  const handleStationChange = (selectedMaster) => {
    setSelectedStationId(selectedMaster);
  };
  const [selectedAssemblyForMapping, setSelectedAssemblyForMapping] =
    useState("");

  const handleAssemblyChange = (selectedMaster) => {
    setSelectedAssemblyForMapping(selectedMaster);
  };

  const [loading, setLoading] = useState(true);

  const [selectedAssembly, setAssembly] = useState("");
  const [warehouseCodeForFlag, setWarehouseCodeForFlag] = useState("");
  const [selectedAssemblyTypeForStation, setAssemblyTypeForStation] =
    useState("");
  const [zoneForStation, setZoneForStation] = useState("");

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getAssemblyMasterReducer.assembly.data
      ? getAssemblyMasterReducer.assembly.data
      : [];

    setPaginationObj(getAssemblyMasterReducer?.assembly?.pagination);
    setLoading(getAssemblyMasterReducer.loading);
    data?.map((finishedGoods) => {
      const formatDate = new Date(finishedGoods.cd);
      let des = finishedGoods?.description;
      let len = desLength;
      const time = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        assembly: finishedGoods.assembly,
        assemblyType: finishedGoods.assemblyType,
        zone: finishedGoods.zone,
        warehouse: finishedGoods.warehouseCode,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: (
          <>
            <ViewButton
              tooltip="View"
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => {
                setAssemblyId(finishedGoods.assemblyId);
                setassemblyType(finishedGoods.assembly);

                handleOpenViewItemModal(finishedGoods);
              }}
              style={{ marginRight: "5px", marginBottom: "5px" }}
            >
              <Icon>visibility</Icon>
            </ViewButton>
            {addAssemblyAccess && (
              <CreateButton
                tooltip="Add Station"
                variant="gradient"
                color="success"
                disabled
                iconOnly
                type="button"
                onClick={() => {
                  // setAssemblyId(finishedGoods.assemblyId);
                  // setFgModelId(finishedGoods.fgModelId);
                  setAssembly(finishedGoods.assembly);
                  setZoneForStation(finishedGoods.zone);
                  setAssemblyIdForCreate(finishedGoods.assemblyId);
                  setAssemblyTypeForStation(finishedGoods.assemblyType);
                  setWarehouseCodeForFlag(finishedGoods.warehouseCode);
                  handleOpenAddModal(finishedGoods);
                  getLevel2ByLevel(
                    finishedGoods.warehouseId,
                    finishedGoods.locationId,

                    jwtToken
                  );
                }}
                style={{ marginRight: "5px", backgroundColor: "#39AD48" }}
              >
                <Icon>edit_sharp</Icon>
              </CreateButton>
            )}
            {EditAssemblyAccess && (
              <EditButton
                tooltip="Edit"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateModal(finishedGoods)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
            {deleteAssemblyAccess && (
              <DeleteButton
                tooltip="Delete"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteAssemblyModal(finishedGoods)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewAssembly && setState({ ...state, rows: tempFinishedGoods });
  }, [getAssemblyMasterReducer]);

  const [viewModal, setOpenViewModal] = useState(false);

  const handleOpenAddModal = () => {
    setIsFlagMode(true);
    setCreateAssemblyModal(true);
  };

  const handleOpenViewItemModal = (finishedGoods) => {
    setTimeout(() => {
      setOpenViewModal(true);
    }, 200);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };
  const [viewTable, setViewTable] = useState({
    columns: [
      { Header: "Station", accessor: "station" },
      { Header: "Station Order", accessor: "stationOrder" },
      { Header: "Section ", accessor: "section" },
      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "action ",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];
    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const { columns: itemColumns, rows: itemRows } = viewTable;

  const [loadingForView, setLoadingForView] = useState(true);

  useEffect(() => {
    let tempVin = [];
    let data = getStationMasterReducer.stations.data
      ? getStationMasterReducer.stations.data
      : [];
    setPaginationObj2(getStationMasterReducer.stations?.pagination);
    setLoadingForView(getStationMasterReducer.loading);

    data.map((vin) => {
      const formatDate = new Date(vin.cd);
      const vinInfo = {
        station: vin.station,
        stationOrder: vin.stationOrder,
        section: vin.section,
        uom: vin.uom,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),

        action: (
          <>
            {editStationAccess && (
              <Tooltip title="Edit">
                <MDButton
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  onClick={() => handleOpenUpdateForStationModal(vin)}
                  style={{ marginRight: "5px" }}
                >
                  <Icon>edit_sharp</Icon>
                </MDButton>
              </Tooltip>
            )}
            {deleteStationAccess && (
              <Tooltip title="Delete">
                <MDButton
                  variant="gradient"
                  color="error"
                  iconOnly
                  type="button"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleOpenDeleteStationModal(vin)}
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Tooltip>
            )}
          </>
        ),
      };
      tempVin.push(vinInfo);
    });
    viewStation && setViewTable({ ...viewTable, rows: tempVin });
  }, [getStationMasterReducer]);

  const [assemblyTypesOptions, setAssemblyTypesOptions] = useState([]);
  useEffect(() => {
    let tempListOfBinTypesOptions = [];
    let data = getAssemblyTypesReducer.assemblyTypes.data
      ? getAssemblyTypesReducer.assemblyTypes.data
      : [];
    data.map((bin) => {
      tempListOfBinTypesOptions.push({
        label: bin.assemblyType,
        value: bin.assemblyTypeId,
      });
    });
    setAssemblyTypesOptions(tempListOfBinTypesOptions);
  }, [getAssemblyTypesReducer]);

  const [assemblyOptions, setAssemblyOptions] = useState([]);
  useEffect(() => {
    let tempListOfBinTypesOptions = [];
    let data = getAssemblyMasterReducer.assembly.data
      ? getAssemblyMasterReducer.assembly.data
      : [];
    data.map((bin) => {
      tempListOfBinTypesOptions.push({
        label: bin.assembly,
        value: bin.assemblyId,
      });
    });
    setAssemblyOptions(tempListOfBinTypesOptions);
  }, [getAssemblyMasterReducer]);

  const [createAssemblyModal, setCreateAssemblyModal] = useState(false);
  const handleCreateAssemblyModal = () => {
    setCreateAssemblyModal(true);
  };

  const initialValuesForAssembly = {
    assemblyType: "",
    itemsForStation: [
      {
        station: "",
        stationOrder: "",
        stationLocationId: "",
      },
    ],
  };
  const [isZoneFieldDisabled, setIsZoneFieldDisabled] = useState(false);

  const [buttonName, setButtonName] = useState("Submit");
  const [mapButtonName, setMapButtonName] = useState("Submit");

  const onSubmitForCreateAssembly = async (values, { resetForm }) => {
    // setButtonName("Please wait...");
    let payload;
    if (!isFlagMode) {
      // Trim each station item in itemsForStation, similar to the approach used for itemsForDispatch
      const trimmedItemsForStation = values.itemsForStation.map(item => {
        // Check each property of the item, and trim if it's a string
        const trimmedItem = {};
        for (const [key, value] of Object.entries(item)) {
          trimmedItem[key] = typeof value === 'string' ? value.trim() : value;
        }
        return trimmedItem;
      });

      payload = {
        assembly: values.assembly.trim(),
        assemblyTypeId: selectedAssemblyType?.value,
        assemblyLocationId: zoneIdSelected?.value,
        stations: trimmedItemsForStation, // Use the array with trimmed items
        userId: userId,
      };
      let res = await createAssemblyMaster(payload, jwtToken);
      if (res.status) {
        getAssemblyMasterWithPagination(
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        );
        setIsSuccessForCreateAssembly(true);
        resetForm();
        setTimeout(() => {
          handleCloseCreateAssemblyModal();
          setButtonName("Submit");
        }, 3000);
      }
      if (!res.status) {
        setErrorMsgForCreate(res?.data?.data?.msg);
        setIsErrorForCreateAssembly(true);
        setButtonName("Submit");
      }
    }
    if (isFlagMode) {
      payload = {
        stations: values.itemsForStation,
        assemblyId: assemblyIdForCreate,
        userId: userId,
      };
      let res = await createAssemblyStationMaster(payload, jwtToken);
      if (res.status) {
        getAssemblyMasterWithPagination(
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        );
        setIsSuccessForCreateAssembly(true);
        resetForm();
        setTimeout(() => {
          handleCloseCreateAssemblyModal();
          setButtonName("Submit");
        }, 3000);
      }
      if (!res.status) {
        setErrorMsgForCreate(res?.data?.data?.msg);
        setIsErrorForCreateAssembly(true);
        setButtonName("Submit");
      }
    }
  };

  const [singleDataForDelete, setSingleDataForDelete] = useState({});
  const [openDeleteAssemblyModal, setOpenDeleteAssemblyModal] = useState(false);

  const handleOpenDeleteAssemblyModal = (data) => {
    setOpenDeleteAssemblyModal(true);
    setSingleDataForDelete(data);
  };

  const handleCloseDeleteAssemblyModal = () => {
    setOpenDeleteAssemblyModal(false);
  };

  const [isErrorDeleteAssembly, setIsErrorDeleteAssembly] = useState(false);
  const [errorMsgForDelete, setErrorMsgForDelete] = useState(false);
  const [isSuccessForAssembly, setIsSuccessForAssembly] = useState(false);
  const [isSuccessForStation, setIsSuccessForStation] = useState(false);

  useEffect(() => {
    if (isErrorDeleteAssembly) {
      setTimeout(() => {
        setIsErrorDeleteAssembly(false);
      }, 3000);
    }
  }, [isErrorDeleteAssembly]);
  useEffect(() => {
    if (isSuccessForAssembly) {
      setTimeout(() => {
        setIsSuccessForAssembly(false);
      }, 2000);
    }
  }, [isSuccessForAssembly]);

  useEffect(() => {
    if (isSuccessForStation) {
      setTimeout(() => {
        setIsSuccessForStation(false);
      }, 2000);
    }
  }, [isSuccessForStation]);

  const deleteTripNo = async () => {
    setDeleteButtonName("Please wait...");
    let assemblyId = singleDataForDelete.assemblyId;
    let userId = singleDataForDelete.userId;
    let res = await deleteAssembly(assemblyId, userId);

    if (res.status) {
      getAssemblyMasterWithPagination(
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setTimeout(() => {
        handleCloseDeleteAssemblyModal();
        setDeleteButtonName("Delete");
      }, 3000);
      setIsSuccessForAssembly(true);
    }

    if (!res.status) {
      setIsErrorDeleteAssembly(true);
      setErrorMsgForDelete(res?.data?.data?.msg);
      setDeleteButtonName("Delete");
    }
  };

  const [singleDataForStationDelete, setSingleDataForStationDelete] = useState(
    {}
  );
  const [openDeleteStationModal, setOpenDeleteStationModal] = useState(false);

  const handleOpenDeleteStationModal = (data) => {
    setOpenDeleteStationModal(true);
    setSingleDataForStationDelete(data);
  };

  const handleCloseDeleteStationModal = () => {
    setOpenDeleteStationModal(false);
  };

  const [isErrorDeleteStation, setIsErrorDeleteStation] = useState(false);
  const [errorMsgForStationDelete, setErrorMsgForStationDelete] =
    useState(false);

  useEffect(() => {
    if (isErrorDeleteStation) {
      setTimeout(() => {
        setIsErrorDeleteStation(false);
      }, 3000);
    }
  }, [isErrorDeleteStation]);

  const deleteStationNo = async () => {
    let stationId = singleDataForStationDelete.stationId;
    let userIdForStation = singleDataForStationDelete.userId;
    let res = await deleteStation(stationId, userIdForStation);

    if (res.status) {
      await getStationsByAssembly(
        assemblyId,
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      setIsSuccessForStation(true);
      setTimeout(() => {
        handleCloseDeleteStationModal();
      }, 3000);
    }

    if (!res.status) {
      setIsErrorDeleteStation(true);
      setErrorMsgForStationDelete(res?.data?.data?.msg);
    }
  };

  const [openMapAssetModal, setOpenMapAssetModal] = useState(false);
  const [singleAssetDataForMapAsset, setSingleAssetDataForMapAsset] = useState(
    {}
  );

  const handleOpenMapAssetModal = (Asset = {}) => {
    setOpenMapAssetModal(true);
    // setSingleAssetDataForMapAsset(Asset);
  };

  const handleCloseMapAssetModal = () => {
    setOpenMapAssetModal(false);
    setSelectedAssemblyForMapping("");
    setSelectedStationId("");
  };

  const [isErrorForMapAssembly, setIsErrorForMapAssembly] = useState(false);
  const [isSuccessForMapAssembly, setIsSuccessForMapAssembly] = useState(false);

  useEffect(() => {
    if (isErrorForMapAssembly) {
      setTimeout(() => {
        setIsErrorForMapAssembly(false);
      }, 3000);
    }
  }, [isErrorForMapAssembly]);

  useEffect(() => {
    if (isSuccessForMapAssembly) {
      setTimeout(() => {
        setIsSuccessForMapAssembly(false);
      }, 3000);
    }
  }, [isSuccessForMapAssembly]);

  const [errorMsgForMapAssembly, setErrorMsgForMapAssembly] = useState("");

  const onSubmitForForMapAssembly = async (values, { resetForm }) => {
    setMapButtonName("Please wait...");
    let payload = {
      stationId: selectedStationId?.value,
      userId: userId,
      assemblyId: selectedAssemblyForMapping?.value,
    };

    let res = await mapAssembly(payload, jwtToken);

    if (res.status) {
      setIsSuccessForMapAssembly(true);
      getAssemblyMasterWithPagination(
        pageNo,
        rowsPerPage,
        searchObject,
        jwtToken
      );
      getUnmappedStations(jwtToken);
      setSelectedAssemblyForMapping("");
      setSelectedStationId("");
      setTimeout(() => {
        setMapButtonName("Submit");
        handleCloseMapAssetModal();
      }, 2250);
      resetForm();
    }
    if (!res.status) {
      setErrorMsgForMapAssembly(res?.data?.data?.msg);
      setIsErrorForMapAssembly(true);
      setMapButtonName("Submit");
    }
  };

  const initialValueForMapAsset = {
    stationId: "",
  };

  const formikForMapAsset = useFormik({
    initialValues: initialValueForMapAsset,
    // validationSchema: validationSchemaForMapAsset,
    onSubmit: onSubmitForForMapAssembly,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox color="text" px={2} mt={4}>
                {createAssemblyAccess && (
                  <Tooltip>
                    <CreateButton
                      tooltip="Create Assembly"
                      // disabled={!createAccess}
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                      onClick={handleCreateAssemblyModal}
                      style={{ marginRight: "1rem" }}
                    >
                      <Icon>add</Icon>
                    </CreateButton>
                  </Tooltip>
                )}
                <Tooltip>
                  {mapAccess && (
                    <StandardButton
                      tooltip="Map Assembly"
                      // disabled={assets.isEditable === 0}
                      variant="gradient"
                      color="success"
                      iconOnly
                      icon="map"
                      type="button"
                      onClick={handleOpenMapAssetModal}
                      style={{ marginRight: "1rem" }}
                    >
                      <Icon>map</Icon>
                    </StandardButton>
                  )}
                </Tooltip>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                  searchProp={importSearch}
                />
              </MDBox>
              <Modal
                open={openMapAssetModal}
                onClose={handleCloseMapAssetModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Map
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseMapAssetModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForMapAsset.handleSubmit}
                    >
                      <MDBox mb={1}>
                        <MDTypography
                          variant="h6"
                          style={{ fontWeight: "500" }}
                        >
                          {" "}
                          Stations
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={90}
                          placeholder="Choose one..."
                          name="stationId"
                          value={selectedStationId}
                          options={unmappedStationsOptions}
                          onChange={(selectedOption) => {
                            handleStationChange(selectedOption);

                            formikForMapAsset.setFieldValue(
                              "stationId",
                              selectedOption
                            );
                          }}
                        />
                      </MDBox>
                      <MDBox mb={1}>
                        <MDTypography
                          variant="h6"
                          style={{ fontWeight: "500" }}
                        >
                          {" "}
                          Assembly
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={90}
                          placeholder="Choose one..."
                          name="Assembly"
                          value={selectedAssemblyForMapping}
                          options={assemblyOptions}
                          onChange={(selectedOption) => {
                            handleAssemblyChange(selectedOption);

                            formikForMapAsset.setFieldValue(
                              "Assembly",
                              selectedOption
                            );
                          }}
                        />
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForMapAssembly}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForMapAssembly(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForMapAssembly}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForMapAssembly}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessForMapAssembly(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Assembly mapped successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={3}>
                        <MDButton
                          color="warning"
                          fullWidth
                          type="submit"
                          disabled={
                            !formikForMapAsset.isValid ||
                            mapButtonName === "Please wait..."
                          }
                        >
                          {mapButtonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={viewModal}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForView}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      {assemblyType}
                    </MDTypography>
                    <MDBox></MDBox>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                              marginBottom: "5px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />
                  <MDBox>
                    <DataTable
                      table={{ columns: itemColumns, rows: itemRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      serverSidePagination={true}
                      paginationObj={paginationObj2}
                      pageNoValue={pageNo}
                      rowsPerPageValue={rowsPerPage}
                      pageNoProp={importPageNo}
                      rowsPerPageProp={importRowsPerPage}
                      searchProp={importSearch}
                    />
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={createAssemblyModal}
                onClose={handleCloseCreateAssemblyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard
                  sx={
                    !isFlagMode
                      ? styleForCreateAssembly
                      : styleForCreateAssemblyForUpdate
                  }
                >
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create Assembly
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateAssemblyModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox>
                      <MDBox>
                        <Grid container>
                          <Formik
                            initialValues={initialValuesForAssembly}
                            validationSchema={validationSchemaForStation}
                            onSubmit={onSubmitForCreateAssembly}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  <div>
                                    <MDBox>
                                      {isFlagMode && (
                                        <MDBox
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="flex-start"
                                          borderRadius={4}
                                          bgcolor="#f5f5f5"
                                          boxShadow={1}
                                          padding={0.5}
                                        >
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                              marginRight: 16, // Add margin for spacing
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Assembly:
                                            </span>{" "}
                                            {selectedAssembly}
                                          </MDTypography>
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                              marginRight: 16, // Add margin for spacing
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Assembly Type:
                                            </span>{" "}
                                            {selectedAssemblyTypeForStation}
                                          </MDTypography>
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                              marginRight: 16, // Add margin for spacing
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Warehouse:
                                            </span>{" "}
                                            {warehouseCodeForFlag}
                                          </MDTypography>
                                          <MDTypography
                                            variant="h5"
                                            style={{
                                              fontWeight: "500",
                                              color: "#333",
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Zone:
                                            </span>{" "}
                                            {zoneForStation}
                                          </MDTypography>
                                        </MDBox>
                                      )}

                                      {isFlagMode ? (
                                        ""
                                      ) : (
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={3}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "200px" }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                style={{ fontWeight: "500" }}
                                              >
                                                Assembly
                                              </MDTypography>
                                              <Field
                                                className="form-control"
                                                type="text"
                                                name="assembly"
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                value={formik.values.assembly}
                                                error={
                                                  formik.touched.assembly &&
                                                  formik.errors.assembly &&
                                                  true
                                                }
                                                inputProps={{
                                                  style: {
                                                    height: "14px",
                                                  },
                                                  maxLength: 42,
                                                }}
                                              />
                                              {formik.errors.assembly && (
                                                <TextError
                                                  msg={formik.errors.assembly}
                                                />
                                              )}
                                            </MDBox>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "200px" }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                style={{ fontWeight: "500" }}
                                              >
                                                Assembly Type
                                              </MDTypography>
                                              <Select
                                                isClearable
                                                className="select-css"
                                                maxMenuHeight={130}
                                                placeholder="Choose one..."
                                                name="assemblyType"
                                                onBlur={() => {
                                                  formik.handleBlur({
                                                    target: {
                                                      name: "assemblyType",
                                                    },
                                                  });
                                                }}
                                                value={selectedAssemblyType}
                                                options={assemblyTypesOptions}
                                                onChange={(selectedOption) => {
                                                  handleAssemblyTypeChange(
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    "fgCode",
                                                    selectedOption
                                                  );
                                                }}
                                              />
                                              {formik.touched.assemblyType &&
                                                formik.errors.assemblyType && (
                                                  <TextError
                                                    msg={
                                                      formik.errors.assemblyType
                                                    }
                                                  />
                                                )}
                                            </MDBox>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "200px" }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                style={{ fontWeight: "500" }}
                                              >
                                                Warehouse Code
                                              </MDTypography>
                                              <Select
                                                isDisabled
                                                isClearable
                                                className="select-css"
                                                maxMenuHeight={130}
                                                placeholder="Choose one..."
                                                name="warehouseCode"
                                                onBlur={() => {
                                                  formik.handleBlur({
                                                    target: {
                                                      name: "warehouseCode",
                                                    },
                                                  });
                                                }}
                                                value={warehouseIdSelected}
                                                options={warehouseOptions}
                                                onChange={(selectedOption) => {
                                                  handleChangeWarehouse(
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    "warehouseCode",
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    "itemsForStation",
                                                    [
                                                      {
                                                        stationLocationId: "",
                                                        station: "",
                                                        stationOrder: "",
                                                      },
                                                    ]
                                                  );
                                                }}
                                              />
                                              {formik.touched.warehouseCode &&
                                                formik.errors.warehouseCode && (
                                                  <TextError
                                                    msg={
                                                      formik.errors
                                                        .warehouseCode
                                                    }
                                                  />
                                                )}
                                            </MDBox>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                            <MDBox
                                              mb={1}
                                              style={{ width: "200px" }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                style={{ fontWeight: "500" }}
                                              >
                                                Zone
                                              </MDTypography>
                                              <Select
                                                isClearable
                                                isDisabled={
                                                  formik.values.itemsForStation
                                                    .length > 1
                                                }
                                                className="select-css"
                                                maxMenuHeight={130}
                                                placeholder="Choose one..."
                                                name="level1"
                                                onBlur={() => {
                                                  formik.handleBlur({
                                                    target: { name: "level1" },
                                                  });
                                                }}
                                                value={zoneIdSelected}
                                                options={ZoneOptions}
                                                onChange={(selectedOption) => {
                                                  handleZoneIDChange(
                                                    selectedOption
                                                  );
                                                  getLevel2ByLevel(
                                                    warehouseIdSelected?.value,
                                                    selectedOption?.value,
                                                    jwtToken
                                                  );
                                                  formik.setFieldValue(
                                                    "level1",
                                                    selectedOption
                                                  );

                                                  formik.setFieldValue(
                                                    "itemsForStation",
                                                    [
                                                      {
                                                        stationLocationId: "",
                                                        station: "",
                                                        stationOrder: "",
                                                      },
                                                    ]
                                                  );
                                                }}
                                              />
                                              {formik.touched.level1 &&
                                                formik.errors.level1 && (
                                                  <TextError
                                                    msg={formik.errors.level1}
                                                  />
                                                )}
                                            </MDBox>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </MDBox>
                                    <MDBox>
                                      <MDTypography variant="h5" mt={2}>
                                        Stations
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForStation">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForStation } = values;

                                          const selectedOptions =
                                            itemsForStation.map(
                                              (item) => item.stationLocationId
                                            );

                                          return (
                                            <div>
                                              {itemsForStation.map(
                                                (item, index) => {
                                                  const availableOptions =
                                                    level2Options.filter(
                                                      (option) =>
                                                        !selectedOptions.includes(
                                                          option.value
                                                        ) ||
                                                        option.value ===
                                                          item.stationLocationId
                                                    );
                                                  const isAddButtonDisabled =
                                                    !item.station ||
                                                    !item.stationOrder ||
                                                    !item.stationLocationId;

                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        {isFlagMode ? (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                          >
                                                            <MDBox mb={1}>
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                }}
                                                              >
                                                                Station
                                                              </MDTypography>
                                                              <Field
                                                                className="form-control"
                                                                type="text"
                                                                name={`itemsForStation[${index}].station`}
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={
                                                                  formik.values
                                                                    .itemsForStation[
                                                                    index
                                                                  ].station
                                                                }
                                                                inputProps={{
                                                                  style: {
                                                                    height:
                                                                      "14px",
                                                                    min: 0,
                                                                  },
                                                                  maxLength: 45,
                                                                }}
                                                              />
                                                              {formik.touched
                                                                .itemsForStation &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ].station &&
                                                                formik.errors
                                                                  .itemsForStation &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ].station && (
                                                                  <TextError
                                                                    msg={
                                                                      formik
                                                                        .errors
                                                                        .itemsForStation[
                                                                        index
                                                                      ].station
                                                                    }
                                                                  />
                                                                )}
                                                            </MDBox>
                                                          </Grid>
                                                        ) : (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                          >
                                                            <MDBox mb={1}>
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                }}
                                                              >
                                                                Station
                                                              </MDTypography>
                                                              <Field
                                                                className="form-control"
                                                                type="text"
                                                                name={`itemsForStation[${index}].station`}
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={
                                                                  formik.values
                                                                    .itemsForStation[
                                                                    index
                                                                  ].station
                                                                }
                                                                inputProps={{
                                                                  style: {
                                                                    height:
                                                                      "14px",
                                                                    min: 0,
                                                                  },
                                                                  maxLength: 45,
                                                                }}
                                                              />
                                                              {formik.touched
                                                                .itemsForStation &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ].station &&
                                                                formik.errors
                                                                  .itemsForStation &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ].station && (
                                                                  <TextError
                                                                    msg={
                                                                      formik
                                                                        .errors
                                                                        .itemsForStation[
                                                                        index
                                                                      ].station
                                                                    }
                                                                  />
                                                                )}
                                                            </MDBox>
                                                          </Grid>
                                                        )}

                                                        {isFlagMode ? (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                          >
                                                            <MDBox mb={1}>
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                }}
                                                              >
                                                                Station Order
                                                              </MDTypography>
                                                              <Field
                                                                className="form-control"
                                                                type="number"
                                                                name={`itemsForStation[${index}].stationOrder`}
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={
                                                                  formik.values
                                                                    .itemsForStation[
                                                                    index
                                                                  ].stationOrder
                                                                }
                                                                inputProps={{
                                                                  style: {
                                                                    height:
                                                                      "14px",
                                                                    min: 0,
                                                                  },
                                                                  maxLength: 45,
                                                                }}
                                                              />
                                                              {formik.touched
                                                                .itemsForStation &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ]
                                                                  .stationOrder &&
                                                                formik.errors
                                                                  .itemsForStation &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ]
                                                                  .stationOrder && (
                                                                  <TextError
                                                                    msg={
                                                                      formik
                                                                        .errors
                                                                        .itemsForStation[
                                                                        index
                                                                      ]
                                                                        .stationOrder
                                                                    }
                                                                  />
                                                                )}
                                                            </MDBox>
                                                          </Grid>
                                                        ) : (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                          >
                                                            <MDBox mb={1}>
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                }}
                                                              >
                                                                Station Order
                                                              </MDTypography>
                                                              <Field
                                                                className="form-control"
                                                                type="number"
                                                                name={`itemsForStation[${index}].stationOrder`}
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={
                                                                  formik.values
                                                                    .itemsForStation[
                                                                    index
                                                                  ].stationOrder
                                                                }
                                                                inputProps={{
                                                                  style: {
                                                                    height:
                                                                      "14px",
                                                                    min: 0,
                                                                  },
                                                                  maxLength: 45,
                                                                }}
                                                              />
                                                              {formik.touched
                                                                .itemsForStation &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.touched
                                                                  .itemsForStation[
                                                                  index
                                                                ]
                                                                  .stationOrder &&
                                                                formik.errors
                                                                  .itemsForStation &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ] &&
                                                                formik.errors
                                                                  .itemsForStation[
                                                                  index
                                                                ]
                                                                  .stationOrder && (
                                                                  <TextError
                                                                    msg={
                                                                      formik
                                                                        .errors
                                                                        .itemsForStation[
                                                                        index
                                                                      ]
                                                                        .stationOrder
                                                                    }
                                                                  />
                                                                )}
                                                            </MDBox>
                                                          </Grid>
                                                        )}
                                                        {isFlagMode ? (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={2}
                                                          >
                                                            <MDBox
                                                              mb={1}
                                                              style={{
                                                                width: "12rem",
                                                              }}
                                                            >
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                }}
                                                              >
                                                                Section
                                                              </MDTypography>
                                                              <Select
                                                                isClearable
                                                                // isDisabled={
                                                                //   zoneIdSelected
                                                                //     ? false
                                                                //     : true
                                                                // }
                                                                className="select-css"
                                                                maxMenuHeight={
                                                                  60
                                                                }
                                                                placeholder="Choose one..."
                                                                name={`itemsForStation[${index}].stationLocationId`}
                                                                onBlur={() => {
                                                                  formik.handleBlur(
                                                                    {
                                                                      target: {
                                                                        name: `itemsForStation[${index}].stationLocationId`,
                                                                      },
                                                                    }
                                                                  );
                                                                }}
                                                                options={
                                                                  availableOptions
                                                                }
                                                                value={
                                                                  level2Options.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      item.stationLocationId
                                                                  )
                                                                    ? level2Options.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                          item.stationLocationId
                                                                      )
                                                                    : ""
                                                                }
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  formik.setFieldValue(
                                                                    `itemsForStation.${index}.stationLocationId`,
                                                                    selectedOption
                                                                      ? selectedOption.value
                                                                      : ""
                                                                  );
                                                                }}
                                                              />

                                                              {formik.touched
                                                                .itemsForStation?.[
                                                                index
                                                              ]
                                                                ?.stationLocationId &&
                                                                formik.errors
                                                                  .itemsForStation?.[
                                                                  index
                                                                ]
                                                                  ?.stationLocationId && (
                                                                  <TextError
                                                                    msg={
                                                                      formik
                                                                        .errors
                                                                        .itemsForStation[
                                                                        index
                                                                      ]
                                                                        .stationLocationId
                                                                    }
                                                                  />
                                                                )}
                                                            </MDBox>
                                                          </Grid>
                                                        ) : (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                          >
                                                            <MDBox
                                                              mb={1}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight:
                                                                    "500",
                                                                }}
                                                              >
                                                                Section
                                                              </MDTypography>
                                                              <Select
                                                                isClearable
                                                                isDisabled={
                                                                  zoneIdSelected
                                                                    ? false
                                                                    : true
                                                                }
                                                                className="select-css"
                                                                maxMenuHeight={
                                                                  60
                                                                }
                                                                placeholder="Choose one..."
                                                                name={`itemsForStation[${index}].stationLocationId`}
                                                                onBlur={() => {
                                                                  formik.handleBlur(
                                                                    {
                                                                      target: {
                                                                        name: `itemsForStation[${index}].stationLocationId`,
                                                                      },
                                                                    }
                                                                  );
                                                                }}
                                                                options={
                                                                  availableOptions
                                                                }
                                                                value={
                                                                  level2Options.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      item.stationLocationId
                                                                  )
                                                                    ? level2Options.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                          item.stationLocationId
                                                                      )
                                                                    : ""
                                                                }
                                                                onChange={(
                                                                  selectedOption
                                                                ) => {
                                                                  formik.setFieldValue(
                                                                    `itemsForStation.${index}.stationLocationId`,
                                                                    selectedOption
                                                                      ? selectedOption.value
                                                                      : ""
                                                                  );
                                                                }}
                                                              />

                                                              {formik.touched
                                                                .itemsForStation?.[
                                                                index
                                                              ]
                                                                ?.stationLocationId &&
                                                                formik.errors
                                                                  .itemsForStation?.[
                                                                  index
                                                                ]
                                                                  ?.stationLocationId && (
                                                                  <TextError
                                                                    msg={
                                                                      formik
                                                                        .errors
                                                                        .itemsForStation[
                                                                        index
                                                                      ]
                                                                        .stationLocationId
                                                                    }
                                                                  />
                                                                )}
                                                            </MDBox>
                                                          </Grid>
                                                        )}

                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            marginTop: "2.5rem",
                                                            marginLeft:
                                                              isFlagMode
                                                                ? "5rem"
                                                                : "1rem",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                          </div>
                                                          {index ===
                                                            itemsForStation.length -
                                                              1 && (
                                                            <div>
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() => {
                                                                    if (
                                                                      !isAddButtonDisabled
                                                                    ) {
                                                                      push({
                                                                        stationLocationId:
                                                                          "",
                                                                        station:
                                                                          "",
                                                                        stationOrder:
                                                                          "",
                                                                      });
                                                                      setIsZoneFieldDisabled(
                                                                        true
                                                                      );
                                                                    }
                                                                  }}
                                                                  color="info"
                                                                  style={{
                                                                    width:
                                                                      "30px",
                                                                    marginLeft:
                                                                      "0rem",
                                                                    cursor:
                                                                      isAddButtonDisabled
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                    opacity:
                                                                      isAddButtonDisabled
                                                                        ? 0.5
                                                                        : 1,
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>

                                    <div style={{ background: "white" }}>
                                      <MDButton
                                        style={{
                                          position: "sticky",
                                          bottom: "0",
                                        }}
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        // disabled={
                                        //   !formik.isValid ||
                                        //   formik.values.itemsForStation.some(
                                        //     (item) =>
                                        //       item.stationLocationId === ""
                                        //   )
                                        // }

                                        disabled={
                                          buttonName === "Please wait..."
                                        }
                                      >
                                        {buttonName}
                                      </MDButton>
                                    </div>
                                    <MDBox>
                                      <Collapse in={isErrorForCreateAssembly}>
                                        <Alert
                                          severity="error"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsErrorForCreateAssembly(
                                                  false
                                                );
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          {errorMsgForCreate}
                                        </Alert>
                                      </Collapse>
                                      <Collapse in={isSuccessForCreateAssembly}>
                                        <Alert
                                          severity="success"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsSuccessForCreateAssembly(
                                                  false
                                                );
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          Created Successfully
                                        </Alert>
                                      </Collapse>
                                    </MDBox>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Assembly</MDTypography>
                        <MDInput
                          type="text"
                          name="assembly"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.assembly}
                          error={
                            formikForUpdate.touched.assembly &&
                            formikForUpdate.errors.assembly &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.assembly &&
                          formikForUpdate.errors.assembly && (
                            <TextError msg={formikForUpdate.errors.assembly} />
                          )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Assembly Type
                        </MDTypography>
                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Select..."
                          name="assemblyType"
                          options={assemblyTypesOptions}
                          value={assemblyTypesSelectedForEdit} // Ensure this matches the shape of options
                          onChange={(selectedOption) => {
                            handleChangeAssemblyTypesForEdit(selectedOption);
                            formikForUpdate.setFieldValue(
                              "assemblyType",
                              selectedOption
                            );
                          }}
                        />
                        {formikForUpdate.touched.assemblyType &&
                          formikForUpdate.errors.assemblyType && (
                            <TextError
                              msg={formikForUpdate.errors.assemblyType}
                            />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={updateButtonName === "Please wait..."}
                        >
                          {updateButtonName}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateStationModal}
                onClose={handleCloseStationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseStationModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForStationUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Station</MDTypography>
                        <MDInput
                          type="text"
                          name="station"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForStationUpdate.handleBlur}
                          onChange={formikForStationUpdate.handleChange}
                          value={formikForStationUpdate.values.station}
                          error={
                            formikForStationUpdate.touched.station &&
                            formikForStationUpdate.errors.station &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForStationUpdate.touched.station &&
                          formikForStationUpdate.errors.station && (
                            <TextError
                              msg={formikForStationUpdate.errors.station}
                            />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForStationUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForStationUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForStationUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForStationUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessForStationUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openDeleteAssemblyModal}
                onClose={handleCloseDeleteAssemblyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteAssemblyModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteTripNo}
                      disabled={deleteButtonName === "Please wait..."}
                    >
                      {deleteButtonName}
                    </MDButton>
                    <MDBox mt={2}>
                      <Collapse in={isErrorDeleteAssembly}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteAssembly(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForAssembly}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForAssembly(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openDeleteStationModal}
                onClose={handleCloseDeleteStationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteStationModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteStationNo}
                    >
                      Delete
                    </MDButton>
                    <MDBox mt={2}>
                      <Collapse in={isErrorDeleteStation}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteStation(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForStationDelete}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForStation}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForStation(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loadingForView && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getAssemblyTypesReducer: state.getAssemblyTypesReducer,
    getWarehousesForAssemblyReducer: state.getWarehousesForAssemblyReducer,
    getLocationsForAssemblyReducer: state.getLocationsForAssemblyReducer,
    getAssemblyMasterReducer: state.getAssemblyMasterReducer,
    getStationMasterReducer: state.getStationMasterReducer,
    getUnmappedStationsReducer: state.getUnmappedStationsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAssemblyMasterWithPagination: (
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getAssemblyMasterWithPagination(
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    getUnmappedStations: (jwtToken) => dispatch(getUnmappedStations(jwtToken)),
    getWarehouseForAssembly: (jwtToken) =>
      dispatch(getWarehouseForAssembly(jwtToken)),
    editAssembly: (payload, jwtToken) =>
      dispatch(editAssembly(payload, jwtToken)),
    mapAssembly: (payload, jwtToken) =>
      dispatch(mapAssembly(payload, jwtToken)),
    getLocationsForAssembly: (dWarehouseSelected, jwtToken) =>
      dispatch(getLocationsForAssembly(dWarehouseSelected, jwtToken)),
    getStationsByAssembly: (
      assemblyId,
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getStationsByAssembly(
          assemblyId,
          pageNo,
          rowsPerPage,
          searchObject,
          jwtToken
        )
      ),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    editStation: (payload, jwtToken) =>
      dispatch(editStation(payload, jwtToken)),
    createAssemblyMaster: (payload, jwtToken) =>
      dispatch(createAssemblyMaster(payload, jwtToken)),
    createAssemblyStationMaster: (payload, jwtToken) =>
      dispatch(createAssemblyStationMaster(payload, jwtToken)),
    getAssemblyTypes: (jwtToken) => dispatch(getAssemblyTypes(jwtToken)),
    deleteAssembly: (assemblyId, userId, jwtToken) =>
      dispatch(deleteAssembly(assemblyId, userId, jwtToken)),
    deleteStation: (stationId, userIdForStation, jwtToken) =>
      dispatch(deleteStation(stationId, userIdForStation, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyMaster);
