export const GET_DAILY_VENDOR_PERFORMANCE_REQUEST =
  "GET_DAILY_VENDOR_PERFORMANCE_REQUEST";
export const GET_DAILY_VENDOR_PERFORMANCE_SUCCESS =
  "GET_DAILY_VENDOR_PERFORMANCE_SUCCESS";
export const GET_DAILY_VENDOR_PERFORMANCE_FAILURE =
  "GET_DAILY_VENDOR_PERFORMANCE_FAILURE";

export const GET_DATE_RANGE_REQUEST = "GET_DATE_RANGE_REQUEST";
export const GET_DATE_RANGE_SUCCESS = "GET_DATE_RANGE_SUCCESS";
export const GET_DATE_RANGE_FAILURE = "GET_DATE_RANGE_FAILURE";

export const GET_MONTHLY_VENDOR_PERFORMANCE_REQUEST =
  "GET_MONTHLY_VENDOR_PERFORMANCE_REQUEST";
export const GET_MONTHLY_VENDOR_PERFORMANCE_SUCCESS =
  "GET_MONTHLY_VENDOR_PERFORMANCE_SUCCESS";
export const GET_MONTHLY_VENDOR_PERFORMANCE_FAILURE =
  "GET_MONTHLY_VENDOR_PERFORMANCE_FAILURE";
