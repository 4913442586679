/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

import Select from "react-select";
import TextError from "utils/TextError";
// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

import CancelIcon from "@mui/icons-material/Clear";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getTransactions,
  createTransaction,
  getDocumentType,
  getMaterialRequest,
  getSku,
  createPicklistForPending,
  editQtyForCreateMR,
  createDisapproval,
} from "../../../store";

// React-Select component and styling

import "../../../index.css";

// config file

import Config from "../../../config/index";
// Cookies
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import ReleaseButton from "components/standardized-components/ReleaseButton";
import EditButton from "components/standardized-components/EditButton";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, lg: 750 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};

const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const acceptStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForCreatePicklist = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PendingMaterialRequests = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  createTransaction,
  editQtyForCreateMR,
  getTransactions,
  getSku,
  createDisapproval,
  getTransactionsReducer,
  getDocumentTypeReducer,
  getDocumentType,
  materialRequestReducer,
  getMaterialRequest,
  SkuReducer,
  createPicklistForPending,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;

  let roleName = loginDetails.data.roleName;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getDocumentType(jwtToken);
  }, []);
  useEffect(() => {
    getMaterialRequest(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let PendingMaterialModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ed4020ac-2124-4c64-a776-9236bc4bde19"
  );
  let createAccess = PendingMaterialModule
    ? PendingMaterialModule.actionId.includes(
        "ace0735a-4bb3-4802-9d94-11f7f5f44068"
      )
    : null;
  let viewAccess = PendingMaterialModule
    ? PendingMaterialModule.actionId.includes(
        "0cc035a0-5efd-4744-b007-a3b980a4bc6a"
      )
    : null;
  let EditAccess = PendingMaterialModule
    ? PendingMaterialModule.actionId.includes(
        "9a6602b3-f140-4437-beae-69fb53c2c7f6"
      )
    : null;
  let ReleaseAccess = PendingMaterialModule
    ? PendingMaterialModule.actionId.includes(
        "ec9d2132-cae2-4ce2-b4c4-c6e6a0828f8b"
      )
    : null;

  // useEffect(() => {
  //   getTransactions(jwtToken);
  // }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Transaction Code", accessor: "trCode" },
      // { Header: "UserName", accessor: "username" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      // { Header: "Item Description", accessor: "itemDescription" },
      { Header: "total store quantity", accessor: "totalQty" },
      { Header: "Final Qty", accessor: "finalQty" },
      { Header: "User Requested Qty", accessor: "qty" },
      { Header: "uom", accessor: "uom" },
      { Header: "Created Date", accessor: "requesteddate" },
      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [isErrorMsgForCreate, setIsErrorMsgForCreate] = useState(false);
  const [isErrorForPicklist, setIsErrorPicklist] = useState(false);
  const [isSuccessForPicklist, setIsSuccessPicklist] = useState(false);

  useEffect(() => {
    if (isErrorForPicklist) {
      setTimeout(() => {
        setIsErrorPicklist(false);
      }, 3000);
    }
  }, [isErrorForPicklist]);

  useEffect(() => {
    if (isSuccessForPicklist) {
      setTimeout(() => {
        setIsSuccessPicklist(false);
      }, 3000);
    }
  }, [isSuccessForPicklist]);

  const [finalQtyState, setFinalQtyState] = useState("");
  const [itemCodeState, setItemCodeState] = useState("");
  const [trCodeState, setTrCodeState] = useState("");

  useEffect(() => {
    getSku({
      finalQty: finalQtyState,
      itemId: itemCodeState,
      trCode: trCodeState,
    });
  }, [finalQtyState, itemCodeState, trCodeState]);

  const [loading, setLoading] = useState(true);
  const [singleAcceptData, setSingleAcceptData] = useState({});

  const [openAcceptModal, setOpenAcceptModal] = useState(false);

  const handleOpenAcceptModal = (asset = {}) => {
    setSingleAcceptData(asset);
    setOpenAcceptModal(true);
  };
  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };
  useEffect(() => {
    let tempAsset = [];
    let data = materialRequestReducer.materialRequest
      ? materialRequestReducer.materialRequest
      : [];

    setLoading(getTransactionsReducer.loading);

    data.map((purchase) => {
      if (purchase.documentNumber === null) {
        const formatDate = new Date(purchase.cd);
        let len = desLength;
        let des = purchase?.itemDescription;
        const purchaseObject = {
          privateKey: purchase.id,
          trCode: purchase.trCode,
          userName: purchase.userName,
          warehouse: purchase.warehouse,
          itemCode: purchase.itemCode,

          itemCodeDes: `${purchase.itemCode} / ${
            des ? des.substring(0, len) : ""
          }`,
          qty: purchase.qty,
          totalQty: purchase.suidqty,
          suidqty: purchase.suidqty,

          uom: purchase.uom,
          requesteddate: format(
            addMinutes(formatDate, formatDate.getTimezoneOffset()),
            "do MMMM yyyy"
          ),
          transactionId: purchase.transactionId,
          pckedQty: `${purchase.toBePickedQty}`,
          createdDate: format(
            addMinutes(formatDate, formatDate.getTimezoneOffset()),
            "do MMMM yyyy"
          ),
          itemId: purchase.itemId,
          id: purchase.id,
          finalQty: purchase.finalQty,
          isFIFO: purchase.isFIFO,
          action: (
            <span style={{ display: "flex" }}>
              {EditAccess && (
                <EditButton
                  type="button"
                  disabled={
                    roleName !== "admin"
                      ? true
                      : false || purchase.isFIFO === 0
                      ? true
                      : false
                  }
                  title="Edit"
                  onClick={() => {
                    setFinalQtyState(purchase.finalQty);
                    setItemCodeState(purchase.itemId);
                    setTrCodeState(purchase.trCode);
                    handleOpenEditQtyModal(purchase);
                  }}
                >
                  <i className="fa fa-pencil"></i>
                </EditButton>
              )}
              {ReleaseAccess && (
                <ReleaseButton
                  style={{ marginLeft: "1rem" }}
                  title="Release"
                  className="btn btn-info spacing1"
                  type="button"
                  disabled={purchase.isFIFO === 1 ? true : false}
                  onClick={() => {
                    handleOpenAcceptModal(purchase);
                  }}
                >
                  Release
                </ReleaseButton>
              )}
            </span>
          ),
          // isEditable: purchase.isEditable,
        };
        viewAccess && tempAsset.push(purchaseObject);
      }
    });

    setState({ ...state, rows: tempAsset });
  }, [materialRequestReducer]);

  const [openCreatePicklistModal, setOpenCreatePicklistModal] = useState(false);

  const handleOpenCreatePicklistModal = (data = {}) => {
    setOpenCreatePicklistModal(true);
  };

  const handleCloseCreatePicklistModal = () => {
    setOpenCreatePicklistModal(false);
  };

  const createPicklistHandler = (event, data) => {
    handleOpenCreatePicklistModal(data);
  };

  const [uncheck, setUncheck] = useState(false);

  const [picklistName, setPicklistName] = useState("Create");
  const [selectedCreatePicklistData, setSelectedCreatePicklistData] = useState(
    []
  );

  const createPicklistForData = (data) => {
    setSelectedCreatePicklistData(data);
  };
  // const onClickCreateReleaseList = async () => {
  //   setPicklistName("Please wait...");
  //   let tempArray = [];
  //   selectedCreatePicklistData.map((data) => {
  //     const tempPayload = {
  //       itemId: data.itemId,
  //       balanceQty: data.balanceQty,
  //       qty: data.requestQty,
  //       transactionId: data.transactionId,
  //     };
  //     tempArray.push(tempPayload);
  //   });

  //   let payload = {
  //     mvtId: 9,
  //     items: tempArray,
  //   };
  //   let res = await createTransaction(payload, jwtToken);
  //   if (res.status) {
  //     setUncheck(!uncheck);
  //     setIsSuccessPicklist(true);
  //     getTransactions(jwtToken);

  //     setTimeout(() => {
  //       handleCloseCreatePicklistModal();
  //       setPicklistName("Create");
  //     }, 2250);
  //   }
  //   if (!res.status) {
  //     setIsErrorPicklist(true);
  //     setIsErrorMsgForCreate(res?.data?.data?.msg);
  //     setPicklistName("Create");
  //   }
  // };

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [documentTypeSelected, setDocumentTypeSelected] = useState("");
  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data || [];

    data.forEach((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentCode,
        documentId: document.documentId,
      });
    });

    setDocumentTypeOptions(tempDocumentTypeOptions);

    // Preselect the option with code "MR"
    const preselectedOption = tempDocumentTypeOptions.find(
      (option) => option.value === "MR"
    );

    if (preselectedOption) {
      setDocumentTypeSelected(preselectedOption);
    }
    // else if (tempDocumentTypeOptions.length > 0) {
    //   // If "MR" doesn't exist, preselect the last option
    //   setDocumentTypeSelected(tempDocumentTypeOptions[tempDocumentTypeOptions.length - 1]);
    // }
  }, [getDocumentTypeReducer]);

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
  };
  const initialValueForAccept = {
    documentNumber: "",
  };
  const onSubmitForAccept = async (values) => {
    // setPicklistName("Please wait...");
    let tempArray = [];
    selectedCreatePicklistData.map((data) => {
      const tempPayload = {
        itemCode: data.itemCode,
        isFIFO: data.isFIFO,
        id: data.id,
        finalQty: data.finalQty,
        trCode: data.trCode,
      };
      tempArray.push(tempPayload);
    });

    let payload = {
      documentCode: documentTypeSelected?.value,
      items: tempArray,
    };

    let res = await createPicklistForPending(payload, jwtToken);
    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessPicklist(true);
      getMaterialRequest(jwtToken);

      setTimeout(() => {
        handleCloseCreatePicklistModal();
        setPicklistName("Create");
      }, 2250);
    }
    if (!res.status) {
      setIsErrorPicklist(true);
      setIsErrorMsgForCreate(res?.data?.data?.msg);
      setPicklistName("Create");
    }
  };
  const [isSuccessEditQty, setIsSuccessEditQty] = useState(false);
  const [isErrorEditQty, setIsErrorEditQty] = useState(false);

  useEffect(() => {
    if (isErrorEditQty) {
      setTimeout(() => {
        setIsErrorEditQty(false);
      }, 3000);
    }
  }, [isErrorEditQty]);

  useEffect(() => {
    if (isSuccessEditQty) {
      setTimeout(() => {
        setIsSuccessEditQty(false);
      }, 3000);
    }
  }, [isSuccessEditQty]);

  const [editQtyModal, setEditQtyModal] = useState(false);

  const [singleDataForEdit, setSingleDataForEdit] = useState({});

  const handleOpenEditQtyModal = (data = {}) => {
    setSingleDataForEdit(data);
    setEditQtyModal(true);
  };

  const handleCloseEditQtyModal = () => {
    setEditQtyModal(false);
    setSelectedSku("");
  };

  const initialValueForEditQty = {
    finalQty: singleDataForEdit?.finalQty,
    selectionType: "fifo",
    sku: "",
    skuArr: [],
  };

  const validationSchemaForEditQty = Yup.object({
    finalQty: Yup.number()
      .min(1)
      .max(singleDataForEdit.qty)
      .required("Enter the final qty"),
  });
  const [errorMsgEditQty, setErrorMsgEditQty] = useState("");

  const onSubmitForEditQty = async (values, { resetForm }) => {
    const payload = {
      id: singleDataForEdit.id,
      finalQty: values.finalQty,
      isFifo: values.selectionType === "fifo" ? true : false,
      items: values.skuArr.map(
        ({ itemDescription, locationCode, ...rest }) => rest
      ),
      serial: singleDataForEdit.controlType,
    };
    let res = await editQtyForCreateMR(payload, jwtToken);

    if (res.status) {
      setIsSuccessEditQty(true);
      getMaterialRequest(jwtToken);

      setTimeout(() => {
        handleCloseEditQtyModal();
      }, 2250);
    }

    if (!res.status) {
      setIsErrorEditQty(true);
      setErrorMsgEditQty(res?.data?.data?.msg);
    }
  };

  let pushPackage;

  const [enteredFinalQty, setEnteredFinalQty] = useState(0);
  const [sumOfArray, setSumOfArray] = useState("");
  const [balance, setBalance] = useState("");

  useEffect(() => {
    let rem = enteredFinalQty - sumOfArray;
    if (isNaN(rem)) {
      setBalance("");
    }
    if (!isNaN(rem)) {
      setBalance(rem);
    }
  }, [sumOfArray, enteredFinalQty]);

  const [balanceItemMsg, setBalanceItemMsg] = useState("");

  useEffect(() => {
    if (balance > 0) {
      setBalanceItemMsg(`${balance} more qty has to be added`);
    }
    if (balance < 0) {
      setBalanceItemMsg(`${Math.abs(balance)} qty needs to be removed`);
    }
    if (balance === 0) {
      setBalanceItemMsg("Final Qty matched");
    }
  }, [balance]);

  const [selectedSku, setSelectedSku] = useState("");

  const handleSkuSelected = (selectedOption) => {
    setSelectedSku(selectedOption);
  };

  const [skuOptions, setSkuOptions] = useState([]);
  useEffect(() => {
    let tempSkuOptions = [];

    let data = SkuReducer.sku.data ? SkuReducer.sku.data : [];
    data.map((item) => {
      tempSkuOptions.push({
        label: `${item.Msku ? "MSKU" : "SUID"} : ${
          item.Msku ? item.Msku : item.suid
        } / ${item.suidQty} ${item.uom} / ${item.serialNumber}`,
        value: item,
      });
    });
    setSkuOptions(tempSkuOptions);
  }, [SkuReducer]);

  const [successMsg, setSuccessMsg] = useState("");

  const [singleDataForRelease, setSingleDataForRelease] = useState({});
  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState(false);

  const handleOpenReleaseModal = (data = {}) => {
    setSingleDataForRelease(data);
    setOpenCreateReleaseModal(true);
  };

  const handleCloseCreateReleaseModal = () => {
    setOpenCreateReleaseModal(false);
  };

  const [isSuccessCreateRelease, setIsSuccessCreateRelease] = useState(false);

  const [isErrorCreateRelease, setIsErrorCreateRelease] = useState(false);

  const [isErrorMsgForAccept, setIsErrorMsgForAccept] = useState(false);
  const [isErrorAccept, setIsErrorAccept] = useState(false);
  const [isSuccessAccept, setIsSuccessAccept] = useState(false);

  useEffect(() => {
    if (isErrorAccept) {
      setTimeout(() => {
        setIsErrorAccept(false);
      }, 3000);
    }
  }, [isErrorAccept]);

  useEffect(() => {
    if (isSuccessAccept) {
      setTimeout(() => {
        setIsSuccessAccept(false);
      }, 3000);
    }
  }, [isSuccessAccept]);

  const onClickCreateRelease = async () => {
    const payload = {
      id: singleAcceptData.id,
      trCode: singleAcceptData.trCode,
    };
    let res = await createDisapproval(payload, jwtToken);

    if (res.status) {
      setIsSuccessAccept(true);
      getMaterialRequest(jwtToken);

      setTimeout(() => {
        handleCloseAcceptModal();
        // setAcceptButtonName("Accept")
      }, 2250);
    }
    if (!res.status) {
      setIsErrorAccept(true);
      setIsErrorMsgForAccept(res.message.data.msg);
      // setAcceptButtonName("Accept")
    }
  };

  const formikForAccept = useFormik({
    initialValues: initialValueForAccept,
    onSubmit: onSubmitForAccept,
    // validationSchema: validationSchemaForAccept,
    enableReinitialize: true,
  });

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let value = selectedCreatePicklistData.some((data) => data.suidqty === 0);
    setDisableButton(value);
  }, [selectedCreatePicklistData]);

  return (
    <div>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* {createAssetAccess && ( */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={4}
              // mb={3}
            ></MDBox>

            <MDBox mt={-9}>
              {/* {!loading ? ( */}
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                hideColumns={["", "", "warehouse"]}
                options={{ draggable: false }}
                selection={true}
                uncheckProp={uncheck}
                selectionButtonClick={createPicklistForData}
                selectionButtonComponent={
                  <Tooltip title="Create Approve MR">
                    {createAccess && (
                      <MDButton
                        disabled={disableButton}
                        color="white"
                        type="button"
                        style={{
                          marginTop: "0.5rem",
                          color: "white",
                          border: "1px solid #0275d8",
                          background: "#0275d8",
                          marginLeft: "1rem",
                          marginBottom: "0.8rem",
                        }}
                        onClick={(evt, data) =>
                          createPicklistHandler(evt, data)
                        }
                      >
                        Create Approve MR
                      </MDButton>
                    )}
                    {disableButton && (
                      <MDTypography
                        color="error"
                        variant="p"
                        style={{
                          // textAlign: "center",
                          marginLeft: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        Total Store Quantity is zero!
                      </MDTypography>
                    )}
                  </Tooltip>
                }
              />
              {/* ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )} */}
            </MDBox>
            <Modal
              open={openAcceptModal}
              onClose={handleCloseAcceptModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={acceptStyle}>
                <MDTypography>Are you sure you want to Release?</MDTypography>
                <MDBox mt={2} display="flex">
                  <div>
                    <MDButton
                      style={{ width: "100%", marginRight: "1.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseAcceptModal}
                    >
                      Cancel
                    </MDButton>
                  </div>
                  <div style={{ float: "right" }}>
                    <MDButton
                      style={{ width: "100%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={onClickCreateRelease}
                      // disabled={acceptButtonName==="Please wait..."}
                    >
                      {/* {acceptButtonName} */}Release
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessAccept}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessAccept(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Released successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorAccept}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorAccept(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {isErrorMsgForAccept}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={editQtyModal}
              onClose={handleCloseEditQtyModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Edit Final Qty
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditQtyModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValueForEditQty}
                          validationSchema={validationSchemaForEditQty}
                          onSubmit={onSubmitForEditQty}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div>
                                      <label
                                        htmlFor="selectionType"
                                        style={{ fontSize: "15px" }}
                                      >
                                        Selection Type
                                      </label>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div>
                                      <Field
                                        type="radio"
                                        name="selectionType"
                                        value="fifo"
                                      />
                                      <span
                                        style={{
                                          marginLeft: "0.5rem",
                                          fontSize: "15px",
                                          textAlign: "center",
                                        }}
                                      >
                                        FIFO
                                      </span>
                                    </div>
                                  </Grid>
                                  {singleDataForEdit.controlType === 1 ? (
                                    <Grid item xs={6}>
                                      <div>
                                        <Field
                                          type="radio"
                                          name="selectionType"
                                          value="manual"
                                        />
                                        <span
                                          style={{
                                            marginLeft: "0.5rem",
                                            fontSize: "15px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Manual
                                        </span>
                                      </div>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}

                                  <Grid item xs={12}>
                                    <div className="form-group">
                                      <label htmlFor="finalQty">
                                        Final Qty
                                      </label>
                                      <Field
                                        type="number"
                                        name="finalQty"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                      {formik.touched.finalQty &&
                                        formik.errors.finalQty && (
                                          <TextError
                                            msg={formik.errors.finalQty}
                                          />
                                        )}
                                    </div>
                                  </Grid>
                                  {setEnteredFinalQty(formik.values.finalQty)}

                                  {formik.values.selectionType === "manual" && (
                                    <>
                                      <Grid item xs={10}>
                                        <div>
                                          <label htmlFor="sku">
                                            Select SUID
                                          </label>
                                          <Select
                                            placeholder="Choose one..."
                                            value={selectedSku}
                                            onChange={handleSkuSelected}
                                            options={skuOptions}
                                            clearable
                                            className="suid"
                                          />
                                          {formik.touched.finalQty &&
                                            formik.errors.sku && (
                                              <TextError
                                                msg={formik.errors.sku}
                                              />
                                            )}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div
                                          style={{
                                            marginTop: "27px",
                                            marginLeft: "12px",
                                          }}
                                        >
                                          <MDButton
                                            // type="button"
                                            color="success"
                                            // className="btn btn-block btn-success"
                                            disabled={!selectedSku.value}
                                            style={{ marginTop: "0.2rem" }}
                                            onClick={() => {
                                              pushPackage(selectedSku.value);
                                              setSelectedSku("");
                                            }}
                                          >
                                            ADD
                                          </MDButton>
                                        </div>
                                      </Grid>
                                    </>
                                  )}

                                  {formik.values.selectionType === "manual" &&
                                    formik.values.skuArr.length > 0 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <h4 style={{ marginRight: "2rem" }}>
                                          Selected SUIDS & Qty:
                                        </h4>
                                        <h4>Balance:{balanceItemMsg}</h4>
                                      </div>
                                    )}

                                  {formik.values.skuArr && (
                                    <div>
                                      <FieldArray name="skuArr">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { skuArr } = values;
                                          pushPackage = push;

                                          let qtyArr;

                                          if (
                                            singleDataForEdit.controlType === 0
                                          ) {
                                            qtyArr = skuArr.map(
                                              (value) => value?.qty
                                            );
                                          } else {
                                            qtyArr = skuArr.map(
                                              (value) => value?.suidQty
                                            );
                                          }
                                          let newQtyArr = qtyArr.filter(Number);

                                          let sum = +newQtyArr.reduce(
                                            (a, b) => a + b,
                                            0
                                          );

                                          setSumOfArray(sum);

                                          return (
                                            <Grid container>
                                              {skuArr.map((sku, index) => (
                                                <Grid item xs={12}>
                                                  <div
                                                    key={index}
                                                    style={{
                                                      display: "flex",
                                                      marginTop: "0.2rem",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="skuArr"
                                                      style={{
                                                        marginTop: "0.8rem",
                                                      }}
                                                    >
                                                      <h4>
                                                        SUID:{sku.suid} /{" "}
                                                        {sku.suidQty} {sku.uom}
                                                      </h4>
                                                    </label>

                                                    {singleDataForEdit.controlType ===
                                                    0 ? (
                                                      <Field
                                                        name={`skuArr[${index}].qty`}
                                                        type="number"
                                                        step="any"
                                                        min={0}
                                                        style={{
                                                          width: "100px",
                                                          borderRadius: 10,
                                                          marginLeft: 0,
                                                          marginTop: "10px",
                                                          height: 35,
                                                          paddingLeft: "15px",
                                                          marginLeft: "2rem",
                                                          margin: "5px",
                                                        }}
                                                        autoComplete="off"
                                                        value={
                                                          formik.values.skuArr[
                                                            index
                                                          ].qty
                                                        }
                                                        onChange={(e) => {
                                                          formik.handleChange(
                                                            e
                                                          );
                                                        }}
                                                      />
                                                    ) : null}

                                                    {/* <span>
                                                      <button
                                                        type="button"
                                                        onClick={(e) => {
                                                          remove(index);
                                                        }}
                                                        style={{
                                                          marginLeft: "1rem",
                                                          marginTop: "0.4rem",
                                                        }}
                                                      >
                                                        <i
                                                          className="fa fa-times"
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        ></i>
                                                      </button>
                                                    </span> */}
                                                    <div className="col">
                                                      <CancelIcon
                                                        style={{
                                                          cursor: "pointer",
                                                          marginTop: "0.6rem",
                                                        }}
                                                        type="button"
                                                        className="secondary"
                                                        onClick={(e) =>
                                                          remove(index)
                                                        }
                                                        color="error"
                                                      />
                                                    </div>
                                                  </div>
                                                </Grid>
                                              ))}
                                            </Grid>
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                  )}

                                  <Grid item xs={12}>
                                    <div className="mt-3">
                                      <Collapse in={isErrorEditQty}>
                                        <Alert
                                          severity="error"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsErrorEditQty(false);
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          {errorMsgEditQty}
                                        </Alert>
                                      </Collapse>
                                      <Collapse in={isSuccessEditQty}>
                                        <Alert
                                          severity="success"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsSuccessEditQty(false);
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          Final Qty edited successfully!
                                        </Alert>
                                      </Collapse>
                                    </div>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <div className="mt-3">
                                      {formik.values.selectionType !== "" && (
                                        <MDButton
                                          color="warning"
                                          fullWidth
                                          type="submit"
                                          // className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn"
                                          disabled={
                                            !formik.isValid ||
                                            (formik.values.selectionType ===
                                              "manual" &&
                                              balance !== 0)
                                          }
                                        >
                                          Edit
                                        </MDButton>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={openCreatePicklistModal}
              onClose={handleCloseCreatePicklistModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              {/* <MDCard sx={styleForCreatePicklist}>
                <MDTypography>
                  Are you sure you want to Create Picklist?
                </MDTypography>
                <MDBox mt={2} ml={3}>
                  <MDButton
                    style={{ width: "10rem", marginRight: "0.5rem" }}
                    color="info"
                    variant="outlined"
                    onClick={handleCloseCreatePicklistModal}
                  >
                    Cancel
                  </MDButton>

                  <MDButton
                    style={{ width: "10rem", marginLeft: "0.5rem" }}
                    color="info"
                    onClick={onClickCreateReleaseList}
                    disabled={picklistName === "Please wait..."}
                  >
                    {picklistName}
                  </MDButton>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessForPicklist}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessPicklist(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Created successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorForPicklist}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorPicklist(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {isErrorMsgForCreate}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard> */}
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Create
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreatePicklistModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForAccept.handleSubmit}
                  >
                    <MDBox mb={1} style={{ width: "270px" }}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Document Type
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={90}
                        placeholder="Choose one..."
                        name="documentType"
                        onBlur={() => {
                          formikForAccept.handleBlur({
                            target: {
                              name: "documentType",
                            },
                          });
                        }}
                        value={documentTypeSelected}
                        options={documentTypeOptions}
                        onChange={(selectedOption) => {
                          handleDocumentTypeChange(selectedOption);
                          formikForAccept.setFieldValue(
                            "documentType",
                            selectedOption
                          );
                        }}
                      />
                      {formikForAccept.touched.documentType &&
                        formikForAccept.errors.documentType && (
                          <TextError
                            msg={formikForAccept.errors.documentType}
                          />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForPicklist}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorPicklist(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgForCreate}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForPicklist}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessPicklist(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Created successfully!
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        variant="contained"
                        color="info"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getTransactionsReducer: state.getTransactionsReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    materialRequestReducer: state.materialRequestReducer,
    SkuReducer: state.SkuReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),

    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),

    getSku: (payload, jwtToken) => dispatch(getSku(payload, jwtToken)),
    getTransactions: (jwtToken) => dispatch(getTransactions(jwtToken)),
    getMaterialRequest: (jwtToken) => dispatch(getMaterialRequest(jwtToken)),
    createTransaction: (payload, jwtToken) =>
      dispatch(createTransaction(payload, jwtToken)),
    createPicklistForPending: (payload, jwtToken) =>
      dispatch(createPicklistForPending(payload, jwtToken)),
    editQtyForCreateMR: (payload, jwtToken) =>
      dispatch(editQtyForCreateMR(payload, jwtToken)),
    createDisapproval: (payload, jwtToken) =>
      dispatch(createDisapproval(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingMaterialRequests);
