/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

//  some date function

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CancelIcon from "@mui/icons-material/Clear";
// Functions from store
import {
  getSubModulesByRoleId,
  getDocumentType,
  getCreatedActivity,
  getWarehouses,
  getItemCodeByWarehouse,
  getLocationsForQuarantine,
  getSkuStatusByLocationAndItemCode,
  getSkuByItemCode,
  createRejectionsForAcceptedList,
  getSkuByDocumentNoForRejections,
  releaseRejections,
  // rejectionBySuid,
  // acceptedBySuid,
} from "../../../store";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import { getAcceptedRejections } from "store/rejections/rejectionActions";
import ReleaseButton from "components/standardized-components/ReleaseButton";
import { Backdrop, CircularProgress } from "@mui/material";
import ViewButton from "components/standardized-components/ViewButton";
import { addMinutes, format } from "date-fns";
import StandardButton from "components/standardized-components/StandardButton";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 380, md: 800, lg: 1200, xl: 850 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};
const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};
const styleForStationModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1400 },
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const AcceptedRejections = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAcceptedRejections,
  acceptedRejectionsReducer,
  warehouses,
  itemCodeByWarehouseReducer,
  getSkuStatusByLocationAndItemCodeReducer,
  getSkuByItemCodeReducer,
  getSkuByDocumentNoforRejectionsReducer,
  locationReducer,
  getSkuByDocumentNoForRejections,
  releaseRejections,
  createRejectionsForAcceptedList,
  getDocumentType,
  getCreatedActivity,
  getSkuByItemCode,
  getItemCodeByWarehouse,
  getCreatedActivityReducer,
  getWarehouses,
  getSkuStatusByLocationAndItemCode,
  getLocationsForQuarantine,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let acceptedRejectionsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "08756963-3131-4682-bbc8-67c1d4c5b810"
  );

  let viewAccess = acceptedRejectionsModule
    ? acceptedRejectionsModule.actionId.includes(
        "796751cf-12bd-48a5-af91-2688c055a171"
      )
    : null;
  let createAccess = acceptedRejectionsModule
    ? acceptedRejectionsModule.actionId.includes(
        "3882c174-362b-4050-adb5-8326682dc8fa"
      )
    : null;
  let releaseAccess = acceptedRejectionsModule
    ? acceptedRejectionsModule.actionId.includes(
        "6646d62d-c44a-4b51-9948-62b23ad22926"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [documentNo, setDocumentNo] = useState("");

  useEffect(() => {
    getAcceptedRejections(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);
  useEffect(() => {
    getWarehouses(jwtToken);
  }, []);

  const [loading, setLoading] = useState(true);
  const [documentNumber, setDocumentNumber] = useState("");

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNumber" },
      { Header: "Total Qty", accessor: "totalQty" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },

      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const [stationModal, setOpenStationModal] = useState(false);

  const handleOpenStationModal = () => {
    setOpenStationModal(true);
  };
  const handleCloseStationModal = () => {
    setOpenStationModal(false);
  };

  useEffect(() => {
    let tempAsset = [];
    let data = acceptedRejectionsReducer.acceptedRejections.data
      ? acceptedRejectionsReducer.acceptedRejections.data
      : [];

    setPaginationObj(acceptedRejectionsReducer?.acceptedRejections?.pagination);

    setLoading(acceptedRejectionsReducer.loading);
    data.map((reject) => {
      const formatDate = new Date(reject.cd);
      let des = reject?.itemDescription;
      let len = desLength;
      const rejectObject = {
        privateKey: reject.suid,
        documentNumber: reject.documentNumber,
        totalQty: `${reject.totalQty}`,
        // itemCode: `${reject.itemCode} / ${des ? des.substring(0, len) : ""}`,
        itemCode: reject.itemCode ? `${reject.itemCode}` : "",
        itemDescription: `${des ? des.substring(0, len) : ""}`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: `${reject.qty} ${reject.uom} `,
        lotSerialNo: `${reject.lotNumber ? reject.lotNumber : "NA"} / ${
          reject.serialNumber ? reject.serialNumber : "NA"
        }`,
        suid: reject.suid,

        locationCode: reject.locationCode,
        reason: reject.rejectionReason,
        action: (
          <>
            {releaseAccess && (
              <StandardButton
                disabled={reject.isDisabled === 1}
                tooltip="Create Picklist"
                style={{
                  marginLeft: "0.3rem",
                }}
                type="button"
                variant="gradient"
                color="warning"
                icon="rotate_right"
                iconOnly
                onClick={() => {
                  handleOpenReleaseModal(reject);
                  getCreatedActivity("", jwtToken);
                  setDocumentNumber(reject.documentNumber);
                }}
              >
                {" "}
                <Icon>forward</Icon>
              </StandardButton>
            )}

            <ViewButton
              tooltip="View SUID"
              variant="gradient"
              style={{
                marginLeft: "0.5rem",
              }}
              color="info"
              iconOnly
              type="button"
              onClick={() => {
                setDocumentNo(reject?.documentNumber);
                handleOpenStationModal(reject);
                getSkuByDocumentNoForRejections(reject?.documentTrId, jwtToken);
              }}
            >
              <Icon> visibility_icon </Icon>
            </ViewButton>
          </>
        ),
      };
      tempAsset.push(rejectObject);
    });

    viewAccess && setState({ ...state, rows: tempAsset });
  }, [acceptedRejectionsReducer]);

  const { columns, rows } = state;

  const [stationTable, setStationTable] = useState({
    columns: [
      { Header: "SUID", accessor: "sku" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Uom", accessor: "uom" },
      { Header: "LOT No", accessor: "lotNo" },
      { Header: "Serial No", accessor: "SerialNo" },
      { Header: "Location", accessor: "location" },

      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const [loading2, setLoading2] = useState(true);

  const { columns: stationColumns, rows: stationRows } = stationTable;

  useEffect(() => {
    let tempStations = [];
    let data = getSkuByDocumentNoforRejectionsReducer.sku
      ? getSkuByDocumentNoforRejectionsReducer.sku
      : [];
    setLoading2(getSkuByDocumentNoforRejectionsReducer.loading);
    data.map((station) => {
      const formatDate = new Date(station.cd);
      let des = station?.itemDescription;
      let len = desLength;
      const stationObj = {
        sku: station.suid,
        itemCode: station.itemCode
          ? `${station.itemCode} / ${des ? des.substring(0, len) : ""}`
          : "",
        itemDescription: `${des ? des.substring(0, len) : ""}`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        location: station.locationCode,
        qty: `${station.qty}`,
        uom: `${station.uom}`,
        // lotSerialNo: `${station.lotNumber ? station.lotNumber : "NA"} / ${
        //   station.serialNumber ? station.serialNumber : "NA"
        // }`,
        lotNo: station.lotNumber ? station.lotNumber : "NA",
        SerialNo: station.serialNumber ? station.serialNumber : "NA",
      };
      tempStations.push(stationObj);
    });

    // viewStations &&
    setStationTable({ ...stationTable, rows: tempStations });
  }, [getSkuByDocumentNoforRejectionsReducer]);

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const [selectedSkuStatus, setSelectedSkuStatus] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  useEffect(() => {
    getItemCodeByWarehouse(
      activityIdSelected?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [activityIdSelected, selectedSkuStatus]);
  useEffect(() => {
    getLocationsForQuarantine(
      selectedItemCode?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [selectedItemCode, selectedSkuStatus]);

  useEffect(() => {
    getSkuStatusByLocationAndItemCode(
      selectedLocationId?.value,
      selectedItemCode?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [selectedLocationId, selectedItemCode, selectedSkuStatus]);
  useEffect(() => {
    getSkuByItemCode(
      selectedItemCode?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [selectedItemCode, selectedSkuStatus]);

  const [skuStatusOptions, setSkuStatusOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getSkuStatusByLocationAndItemCodeReducer.skuStatus.data
      ? getSkuStatusByLocationAndItemCodeReducer.skuStatus.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.suid,
        value: activity.suid,
        qty: activity.qty,
      });
    });
    setSkuStatusOptions(tempCreatedActivityOptions);
  }, [getSkuStatusByLocationAndItemCodeReducer]);

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
  };

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseCode,
        // key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [skuOptions, setSkuOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getSkuByItemCodeReducer.sku.data
      ? getSkuByItemCodeReducer.sku.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.suid,
        value: activity.suid,
        qty: activity.qty,
        location: activity.locationName,
      });
    });
    setSkuOptions(tempCreatedActivityOptions);
  }, [getSkuByItemCodeReducer]);

  const [itemCodeForWarehouseOptions, setItemCodeForWarehouseOptions] =
    useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = itemCodeByWarehouseReducer.itemCodes
      ? itemCodeByWarehouseReducer.itemCodes
      : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempCreatedActivityOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
      });
    });
    setItemCodeForWarehouseOptions(tempCreatedActivityOptions);
  }, [itemCodeByWarehouseReducer]);

  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    let tempSuidOptions = [];
    let data = locationReducer.locations.data
      ? locationReducer.locations.data
      : [];
    data.map((suid) => {
      tempSuidOptions.push({
        label: suid.locationName,
        value: suid.locationId,
        // sLocation: suid.locationCode,
      });
    });
    setLocationOptions(tempSuidOptions);
  }, [locationReducer]);

  const initialValues = {
    rejectionFrom: "",
    documentNumber: "",
    rejectionType: "itemCode",
    itemsForLocation: [
      {
        itemId: "",
        skuStatus: "",
        location: "",
        sku: "",
      },
    ],
    itemsForItemCode: [
      {
        itemId: "",
        sku: "",
        skuStatus: "",
      },
    ],
  };

  const [releaseName, setReleaseName] = useState("Create Picklist");

  const [buttonName, setButtonName] = useState("Submit");

  const onSubmitForCreateRejection = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    if (values.rejectionType === "location") {
      let payload = {
        documentNumber: values.documentNumber.trim(),
        reason: values.reason.trim(),
        suids: values.itemsForLocation.map((item) => ({
          suid: item.sku,
          itemId: item.itemId,
          qty: item.qty, // Add qty here
        })),
      };
      let res = await createRejectionsForAcceptedList(payload, jwtToken);

      if (res.status) {
        setIsSuccessCreatedTransferOrder(true);
        getAcceptedRejections(pageNo, rowsPerPage, searchObject, jwtToken);
        resetForm();

        setTimeout(() => {
          handleCloseCreateRejectionModal();
          setButtonName("Submit");
        }, 2500);
      }

      if (!res.status) {
        setIsErrorCreatedTransferOrder(true);
        setErrorMsgForCreate(res?.data?.data?.msg);
        setButtonName("Submit");
      }
    }
    if (values.rejectionType === "itemCode") {
      let payload = {
        documentNumber: values.documentNumber.trim(),
        reason: values.reason,
        suids: values.itemsForItemCode.map((item) => ({
          suid: item.sku,
          itemId: item.itemId,
          qty: item.qty,
        })),
      };

      let res = await createRejectionsForAcceptedList(payload, jwtToken);

      if (res.status) {
        setIsSuccessCreatedTransferOrder(true);
        getAcceptedRejections(pageNo, rowsPerPage, searchObject, jwtToken);
        resetForm();
        setTimeout(() => {
          handleCloseCreateRejectionModal();
          setButtonName("Submit");
        }, 2500);
      }

      if (!res.status) {
        setIsErrorCreatedTransferOrder(true);
        setErrorMsgForCreate(res?.data?.data?.msg);
        setButtonName("Submit");
      }
    }
  };

  const [isSuccessCreatedTransferOrder, setIsSuccessCreatedTransferOrder] =
    useState(false);
  const [isErrorCreatedTransferOrder, setIsErrorCreatedTransferOrder] =
    useState(false);

  useEffect(() => {
    if (isErrorCreatedTransferOrder) {
      setTimeout(() => {
        setIsErrorCreatedTransferOrder(false);
      }, 3000);
    }
  }, [isErrorCreatedTransferOrder]);

  useEffect(() => {
    if (isSuccessCreatedTransferOrder) {
      setTimeout(() => {
        setIsSuccessCreatedTransferOrder(false);
      }, 3000);
    }
  }, [isSuccessCreatedTransferOrder]);

  const [createRejectionModal, setCreateRejectionModal] = useState(false);
  const handleCreateRejectionModal = () => {
    setCreateRejectionModal(true);
  };
  const handleCloseCreateRejectionModal = () => {
    setActivityIdSelected("");
    setCreateRejectionModal(false);
  };
  const [ReleaseModal, setReleaseModal] = useState(false);

  const handleOpenReleaseModal = () => {
    setReleaseModal(true);
    formikForRelease.setValues(initialValuesForRelease);
    formikForRelease.setTouched({});
    formikForRelease.setErrors({});
  };
  const handleCloseReleaseModal = () => {
    setReleaseModal(false);
    setSelectedAcn("");
  };
  const [isErrorForRelease, setIsErrorForRelease] = useState(false);
  const [isSuccessForRelease, setIsSuccessForRelease] = useState(false);

  const initialValuesForRelease = {
    movement: "",
  };

  const skuStatusOptionsForQuar = [
    { label: "Open", value: "0" },
    { label: "Quarantine", value: "1" },
  ];

  const [selectedAcn, setSelectedAcn] = useState("");

  const handleAcnChange = (selectedUser) => {
    setSelectedAcn(selectedUser);
  };

  const [acnOptions, setAcnOptions] = useState([]);
  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data || [];

    let filteredActivities = data.filter(
      (activity) => activity.mvtId === 22 || activity.mvtId === 23
    );

    filteredActivities.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtName,
        value: activity.mvtId,
      });
    });

    setAcnOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const submitForUser = async (values) => {
    setReleaseName("Please wait...");
    let payload = {
      mvtId: selectedAcn.value,
      documentNumber: documentNumber,
    };

    let res = await releaseRejections(payload, jwtToken);
    if (res.status) {
      setIsSuccessForRelease(true);
      getAcceptedRejections(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setIsSuccessForRelease(false);
      }, 2000);
      setTimeout(() => {
        handleCloseReleaseModal(false);
        setReleaseName("Release");
        setReleaseName("Create Picklist");
      }, 3000);
    }
    if (!res.status) {
      setErrorMsg(res?.data?.data?.msg);
      setIsErrorForRelease(true);
      setTimeout(() => {
        setIsErrorForRelease(false);
      }, 3000);
      setReleaseName("Release");
      setReleaseName("Create Picklist");
    }
  };

  const formikForRelease = useFormik({
    initialValues: initialValuesForRelease,
    onSubmit: submitForUser,
  });

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              {createAccess && (
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <CreateButton
                    tooltip="Create Rejection"
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleCreateRejectionModal();
                      getCreatedActivity(16, jwtToken);
                      getDocumentType(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
              )}
            </MDBox>

            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
                hideColumns={["", "asnCode", "vendorCodeName"]}
              />
            </MDBox>
            <Modal
              open={stationModal}
              onClose={handleCloseStationModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForStationModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Document No:{documentNo}
                  </MDTypography>

                  <MDBox display="flex" marginLeft="auto">
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            display: "flex",
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "auto",
                            marginBottom: "5px",
                            marginRight: "10px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseStationModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />
                <MDBox>
                  <DataTable
                    table={{ columns: stationColumns, rows: stationRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={createRejectionModal}
              onClose={handleCloseCreateRejectionModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Rejection
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateRejectionModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValues}
                          // validationSchema={validationSchema}
                          onSubmit={onSubmitForCreateRejection}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div>
                                  <MDBox
                                  // style={{
                                  //   // position: "sticky",
                                  //   top: "0",
                                  //   width: "100%",
                                  //   // zIndex: 999,
                                  // }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "270px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Rejection From
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={90}
                                            // placeholder="Choose one..."
                                            name="rejectionFrom"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "rejectionFrom",
                                                },
                                              });
                                            }}
                                            value={activityIdSelected}
                                            options={warehouseOptions}
                                            onChange={(selectedOption) => {
                                              handleActivityIdChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "rejectionFrom",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.rejectionFrom &&
                                            formik.errors.rejectionFrom && (
                                              <TextError
                                                msg={
                                                  formik.errors.rejectionFrom
                                                }
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={3}
                                          style={{ width: "235px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Document Number
                                          </MDTypography>
                                          <Field
                                            className="form-control"
                                            type="text"
                                            name="documentNumber"
                                            fullWidth
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.documentNumber}
                                            error={
                                              formik.touched.documentNumber &&
                                              formik.errors.documentNumber &&
                                              true
                                            }
                                            inputProps={{
                                              style: {
                                                height: "14px",
                                              },
                                              maxLength: 45,
                                            }}
                                          />
                                          {formik.errors.documentNumber && (
                                            <TextError
                                              msg={formik.errors.documentNumber}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "230px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Reason
                                          </MDTypography>
                                          <Field
                                            className="form-control"
                                            type="text"
                                            name="reason"
                                            fullWidth
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.reason}
                                            error={
                                              formik.touched.reason &&
                                              formik.errors.reason &&
                                              true
                                            }
                                            inputProps={{
                                              style: {
                                                height: "14px",
                                              },
                                              maxLength: 45,
                                            }}
                                          />
                                          {formik.errors.reason && (
                                            <TextError
                                              msg={formik.errors.reason}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  {/* <hr style={{ marginBottom: "10px" }} /> */}

                                  <MDBox>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div>
                                        <Field
                                          type="radio"
                                          name="rejectionType"
                                          value="itemCode"
                                          className="radio"
                                        />
                                        <span>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            style={{
                                              marginLeft: "1.5rem",
                                              marginRight: "10px",
                                              fontSize: "15px",
                                              marginTop: "-1.77rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Item Code
                                          </MDTypography>
                                        </span>
                                      </div>
                                      <div>
                                        <Field
                                          type="radio"
                                          name="rejectionType"
                                          value="location"
                                          className="radio"
                                        />
                                        <span>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            style={{
                                              marginLeft: "1.5rem",
                                              fontSize: "15px",
                                              marginTop: "-1.77rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Location
                                          </MDTypography>
                                        </span>
                                      </div>
                                    </div>
                                  </MDBox>

                                  {formik.values.rejectionType ===
                                    "itemCode" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForItemCode">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForItemCode } = values;

                                          return (
                                            <div>
                                              {itemsForItemCode.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        {/* SKU Status */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={6}
                                                          lg={6}
                                                        >
                                                          <MDBox>
                                                            <MDTypography variant="h6">
                                                              SUID Status
                                                            </MDTypography>
                                                            <Select
                                                              // className="select-css-forSku"
                                                              maxMenuHeight={90}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  // width:
                                                                  //   "170px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                activityIdSelected
                                                                  ? false
                                                                  : true
                                                              }
                                                              options={
                                                                skuStatusOptionsForQuar
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedSkuStatus(
                                                                  selectedOption
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.skuStatus`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={skuStatusOptionsForQuar.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  selectedSkuStatus
                                                              )}
                                                              // value={itemsForItemCode.skuStatus}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Item Code */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={6}
                                                          lg={6}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: -0.7,
                                                            }}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Item Code
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-itemcode"
                                                              maxMenuHeight={75}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForItemCode[
                                                                  index
                                                                ].skuStatus
                                                              }
                                                              options={
                                                                itemCodeForWarehouseOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedItemCode(
                                                                  selectedOption
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.itemId`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={
                                                                itemsForItemCode.itemId
                                                              }
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Location */}
                                                        <Grid
                                                          item
                                                          xs={9}
                                                          sm={6}
                                                          md={6}
                                                          lg={6}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              // sm: 3,
                                                            }}
                                                            mb={1}
                                                          >
                                                            <MDTypography variant="h6">
                                                              SUID
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-sku-item"
                                                              maxMenuHeight={70}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "390px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForItemCode[
                                                                  index
                                                                ].itemId
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.sku`,
                                                                  selectedOption?.value
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.sLocation`,
                                                                  selectedOption?.sLocation
                                                                );
                                                                const selectedItem =
                                                                  skuOptions.find(
                                                                    (item) =>
                                                                      item.value ===
                                                                      selectedOption?.value
                                                                  );
                                                                const location =
                                                                  selectedItem
                                                                    ? selectedItem.location
                                                                    : "";
                                                                const qty =
                                                                  selectedItem
                                                                    ? selectedItem.qty
                                                                    : 0; // Get qty

                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.location`,
                                                                  location
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.qty`,
                                                                  qty // Set qty
                                                                );
                                                              }}
                                                              options={
                                                                skuOptions
                                                              }
                                                              value={skuOptions.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  itemsForItemCode.sku
                                                              )}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* SKU */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={3}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: -0.5,
                                                            }}
                                                            mb={2}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Location
                                                            </MDTypography>
                                                            <Field
                                                              disabled
                                                              className="form-control"
                                                              style={{
                                                                borderRadius: 5,
                                                                height: "36px",
                                                                paddingLeft:
                                                                  "15px",
                                                                width: "345px",
                                                              }}
                                                              type="text"
                                                              name={`itemsForItemCode.${index}.location`}
                                                              value={
                                                                itemsForItemCode[
                                                                  index
                                                                ]?.location
                                                              }
                                                              fullWidth
                                                              autoComplete="off"
                                                              onChange={
                                                                formik.handleChange
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "36px",
                                                                },
                                                                maxLength: 45,
                                                                readOnly: true,
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .location &&
                                                              formik.errors
                                                                .location && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .location
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Action buttons */}
                                                        <Grid
                                                          item
                                                          xs={3}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                        >
                                                          <MDBox
                                                            display="flex"
                                                            alignItems="center"
                                                            mt={3}
                                                            ml={18}
                                                          >
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                            {index ===
                                                              itemsForItemCode.length -
                                                                1 && (
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      sku: "",
                                                                      skuStatus:
                                                                        "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            )}
                                                          </MDBox>
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>
                                  )}

                                  {formik.values.rejectionType ===
                                    "location" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForLocation">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForLocation } = values;

                                          return (
                                            <div>
                                              {itemsForLocation.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={6}
                                                          lg={6}
                                                        >
                                                          <MDBox>
                                                            <MDTypography variant="h6">
                                                              SUID Status
                                                            </MDTypography>
                                                            <Select
                                                              // className="select-css-forSku"
                                                              maxMenuHeight={90}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "370px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                activityIdSelected
                                                                  ? false
                                                                  : true
                                                              }
                                                              options={
                                                                skuStatusOptionsForQuar
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedSkuStatus(
                                                                  selectedOption
                                                                );

                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.skuStatus`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={skuStatusOptionsForQuar.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  selectedSkuStatus
                                                              )}
                                                            />
                                                            {formik.touched
                                                              .skuStatus &&
                                                              formik.errors
                                                                .skuStatus && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .skuStatus
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>
                                                        {/* Item Code Field */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={6}
                                                          lg={6}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: -2,
                                                              sm: -3,
                                                            }}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Item Code
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-itemcode"
                                                              maxMenuHeight={75}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForLocation[
                                                                  index
                                                                ].skuStatus
                                                              }
                                                              options={
                                                                itemCodeForWarehouseOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedItemCode(
                                                                  selectedOption
                                                                );
                                                                // getLocationsForQuarantine(selectedOption?.value, jwtToken);
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.itemId`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={
                                                                itemsForLocation.itemId
                                                              }
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Location Field */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={5.8}
                                                          lg={5.8}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              // sm: 4.5,
                                                            }}
                                                            mb={2}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Location
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-sku-item"
                                                              maxMenuHeight={75}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "370px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForLocation[
                                                                  index
                                                                ].itemId
                                                              }
                                                              // isDisabled={!formik.values.itemsForLocation[index].itemId}
                                                              options={
                                                                locationOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedLocationId(
                                                                  selectedOption
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.location`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* SKU Field */}
                                                        <Grid
                                                          item
                                                          xs={9}
                                                          sm={6}
                                                          md={4}
                                                          lg={2.6}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: -1,
                                                            }}
                                                          >
                                                            <MDTypography variant="h6">
                                                              SUID
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-sku"
                                                              maxMenuHeight={70}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "348px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForLocation[
                                                                  index
                                                                ].location
                                                              }
                                                              options={
                                                                skuStatusOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                const selectedSku =
                                                                  skuStatusOptions.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      selectedOption?.value
                                                                  );
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.sku`,
                                                                  selectedOption?.value
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.qty`,
                                                                  selectedSku?.qty ||
                                                                    0
                                                                ); // Update qty
                                                              }}
                                                              value={skuStatusOptions.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  itemsForLocation.sku
                                                              )}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Cancel/Add Buttons */}
                                                        <Grid
                                                          item
                                                          xs={3}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                        >
                                                          <MDBox
                                                            display="flex"
                                                            alignItems="center"
                                                            mt={3}
                                                            ml={21}
                                                          >
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                            {index ===
                                                              itemsForLocation.length -
                                                                1 && (
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      location:
                                                                        "",
                                                                      skuStatus:
                                                                        "",
                                                                      sku: "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            )}
                                                          </MDBox>
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>
                                  )}

                                  <div
                                    style={{
                                      background: "white",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <MDButton
                                      style={{
                                        position: "sticky",
                                        bottom: "0",
                                      }}
                                      color="info"
                                      fullWidth
                                      type="submit"
                                      // disabled={
                                      //   !formik.isValid ||
                                      //   buttonName === "Please wait..."
                                      // }
                                      disabled={
                                        !formik.isValid ||
                                        buttonName === "Please wait..." ||
                                        formik.values.documentNumber === ""
                                      }
                                    >
                                      {buttonName}
                                    </MDButton>
                                  </div>
                                  <MDBox>
                                    <Collapse in={isErrorCreatedTransferOrder}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorCreatedTransferOrder(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        {errorMsgForCreate}
                                      </Alert>
                                    </Collapse>
                                    <Collapse
                                      in={isSuccessCreatedTransferOrder}
                                    >
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessCreatedTransferOrder(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Rejection Created Successfully
                                      </Alert>
                                    </Collapse>
                                  </MDBox>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={ReleaseModal}
              onClose={handleCloseReleaseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Picklist
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseReleaseModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForRelease.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Document Number:-{" "}
                        <span style={{ fontWeight: 400 }}>
                          {documentNumber}
                        </span>
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={1}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Movement
                      </MDTypography>

                      <Select
                        isClearable
                        className="select-css"
                        np
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="movement"
                        onBlur={() => {
                          formikForRelease.handleBlur({
                            target: { name: "movement" },
                          });
                        }}
                        value={selectedAcn}
                        options={acnOptions}
                        onChange={(selectedOption) => {
                          handleAcnChange(selectedOption);
                          formikForRelease.setFieldValue(
                            "user",
                            selectedOption
                          );
                        }}
                      />
                      {formikForRelease.touched.movement &&
                        formikForRelease.errors.movement && (
                          <TextError msg={formikForRelease.errors.movement} />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForRelease}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForRelease(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForRelease}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForRelease(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Picklist released Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>

                    <MDBox mt={3}>
                      <MDButton
                        color="warning"
                        fullWidth
                        type="submit"
                        disabled={
                          !formikForRelease.isValid ||
                          releaseName === "Please wait..."
                        }
                      >
                        {releaseName}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    acceptedRejectionsReducer: state.acceptedRejectionsReducer,
    warehouses: state.warehouses,
    itemCodeByWarehouseReducer: state.itemCodeByWarehouseReducer,
    locationReducer: state.locationReducer,
    getSkuStatusByLocationAndItemCodeReducer:
      state.getSkuStatusByLocationAndItemCodeReducer,
    getSkuByItemCodeReducer: state.getSkuByItemCodeReducer,
    getSkuByDocumentNoforRejectionsReducer:
      state.getSkuByDocumentNoforRejectionsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    createRejectionsForAcceptedList: (payload, jwtToken) =>
      dispatch(createRejectionsForAcceptedList(payload, jwtToken)),
    getSkuStatusByLocationAndItemCode: (locationId, itemId, status, jwtToken) =>
      dispatch(
        getSkuStatusByLocationAndItemCode(locationId, itemId, status, jwtToken)
      ),
    getSkuByItemCode: (itemId, status, jwtToken) =>
      dispatch(getSkuByItemCode(itemId, status, jwtToken)),
    releaseRejections: (payload, jwtToken) =>
      dispatch(releaseRejections(payload, jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    getSkuByDocumentNoForRejections: (documentTrId, jwtToken) =>
      dispatch(getSkuByDocumentNoForRejections(documentTrId, jwtToken)),
    getAcceptedRejections: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getAcceptedRejections(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getItemCodeByWarehouse: (warehouseIdSelected, status, jwtToken) =>
      dispatch(getItemCodeByWarehouse(warehouseIdSelected, status, jwtToken)),
    getLocationsForQuarantine: (itemId, status, jwtToken) =>
      dispatch(getLocationsForQuarantine(itemId, status, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(AcceptedRejections);
