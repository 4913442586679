import {
  CREATE_PURCHASE_ORDER_FAILURE,
  CREATE_PURCHASE_ORDER_REQUEST,
  CREATE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAILURE,
  DELETE_PURCHASE_ORDER_REQUEST,
  DELETE_PURCHASE_ORDER_SUCCESS,
  EDIT_REQ_QTY_FAILURE,
  EDIT_REQ_QTY_REQUEST,
  EDIT_REQ_QTY_SUCCESS,
  GET_PURCHASE_ORDER_FAILURE,
  GET_PURCHASE_ORDER_REQUEST,
  GET_PURCHASE_ORDER_SUCCESS,
  PUBLISH_PURCHASE_ORDER_FAILURE,
  PUBLISH_PURCHASE_ORDER_REQUEST,
  PUBLISH_PURCHASE_ORDER_SUCCESS,
  GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE,
  GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST,
  GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS,
  GET_ACCEPTED_PURCHASE_ORDERS_REQUEST,
  GET_ACCEPTED_PURCHASE_ORDERS_SUCCESS,
  GET_ACCEPTED_PURCHASE_ORDERS_FAILURE,
  GET_LISTOFITEMS_REQUEST,
  GET_LISTOFITEMS_SUCCESS,
  GET_LISTOFITEMS_FAILURE,
  GET_VENDOR_CODE_REQUEST,
  GET_VENDOR_CODE_SUCCESS,
  GET_VENDOR_CODE_FAILURE,
  UPDATE_ACCEPTPO_REQUEST,
  UPDATE_ACCEPTPO_SUCCESS,
  UPDATE_ACCEPTPO_FAILURE,
  GET_RELEASED_PURCHASE_ORDERS_REQUEST,
  GET_RELEASED_PURCHASE_ORDERS_SUCCESS,
  GET_RELEASED_PURCHASE_ORDERS_FAILURE,
  GET_DELIVERY_PLANT_FOR_PO_REQUEST,
  GET_DELIVERY_PLANT_FOR_PO_SUCCESS,
  GET_DELIVERY_PLANT_FOR_PO_FAILURE,
  GET_UNLOADING_POINT_FOR_PO_REQUEST,
  GET_UNLOADING_POINT_FOR_PO_SUCCESS,
  GET_UNLOADING_POINT_FOR_PO_FAILURE,
} from "./purchaseOrderTypes";

const initialStateForGetPurchaseOrder = {
  loading: false,
  purchaseOrders: [],
  error: "",
};

const getPurchaseOrderReducer = (
  state = initialStateForGetPurchaseOrder,
  action
) => {
  switch (action.type) {
    case GET_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PURCHASE_ORDER_SUCCESS:
      return {
        loading: false,
        purchaseOrders: action.payload,
        error: "",
      };
    case GET_PURCHASE_ORDER_FAILURE:
      return {
        loading: false,
        purchaseOrders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAcceptedGetPurchaseOrder = {
  loading: false,
  acceptedOrders: [],
  error: "",
};

const getReleasedPurchaseOrderReducer = (
  state = initialStateForAcceptedGetPurchaseOrder,
  action
) => {
  switch (action.type) {
    case GET_RELEASED_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RELEASED_PURCHASE_ORDERS_SUCCESS:
      return {
        loading: false,
        acceptedOrders: action.payload,
        error: "",
      };
    case GET_RELEASED_PURCHASE_ORDERS_FAILURE:
      return {
        loading: false,
        acceptedOrders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  purchase: {},
  error: "",
};

const createPurchaseOrderReducer = (state = initialStateForCreate, action) => {
  switch (action.type) {
    case CREATE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PURCHASE_ORDER_SUCCESS:
      return {
        loading: false,
        purchase: action.payload,
        error: "",
      };
    case CREATE_PURCHASE_ORDER_FAILURE:
      return {
        loading: false,
        purchase: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEdit = {
  loading: false,
  purchaseEdit: {},
  error: "",
};

const editPurchaseOrderReducer = (state = initialStateForEdit, action) => {
  switch (action.type) {
    case EDIT_REQ_QTY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_REQ_QTY_SUCCESS:
      return {
        loading: true,
        purchaseEdit: action.payload,
        error: "",
      };
    case EDIT_REQ_QTY_FAILURE:
      return {
        loading: false,
        purchaseEdit: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  deleteOrder: {},
  error: "",
};

const deletePurchaseOrderReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PURCHASE_ORDER_SUCCESS:
      return {
        loading: false,
        deleteOrder: action.payload,
        error: "",
      };
    case DELETE_PURCHASE_ORDER_FAILURE:
      return {
        loading: false,
        deleteOrder: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPublish = {
  loading: false,
  publish: {},
  error: "",
};

const publishPurchaseOrderReducer = (
  state = initialStateForPublish,
  action
) => {
  switch (action.type) {
    case PUBLISH_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUBLISH_PURCHASE_ORDER_SUCCESS:
      return {
        loading: false,
        publish: action.payload,
        error: "",
      };
    case PUBLISH_PURCHASE_ORDER_FAILURE:
      return {
        loading: false,
        publish: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForListOfItems = {
  loading: false,
  listOfItems: {},
  error: "",
};

const listOfItemsReducer = (state = initialStateForListOfItems, action) => {
  switch (action.type) {
    case GET_LISTOFITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LISTOFITEMS_SUCCESS:
      return {
        loading: false,
        listOfItems: action.payload,
        error: "",
      };
    case GET_LISTOFITEMS_FAILURE:
      return {
        loading: false,
        listOfItems: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForNotAccepted = {
  loading: false,
  notAcceptedpurchasedOrders: {},
  error: "",
};

const notAcceptedPurchasedOrdersReducer = (
  state = initialStateForNotAccepted,
  action
) => {
  switch (action.type) {
    case GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS:
      return {
        loading: false,
        notAcceptedpurchasedOrders: action.payload,
        error: "",
      };
    case GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE:
      return {
        loading: false,
        notAcceptedpurchasedOrders: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVendorCode = {
  loading: false,
  vendors: [],
  error: "",
};

const getVendorCodeReducer = (state = initialStateForVendorCode, action) => {
  switch (action.type) {
    case GET_VENDOR_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VENDOR_CODE_SUCCESS:
      return {
        loading: false,
        vendors: action.payload,
        error: "",
      };
    case GET_VENDOR_CODE_FAILURE:
      return {
        loading: false,
        vendors: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAccept = {
  loading: false,
  accept: {},
  error: "",
};

const acceptPoReducer = (state = initialStateForAccept, action) => {
  switch (action.type) {
    case UPDATE_ACCEPTPO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACCEPTPO_SUCCESS:
      return {
        loading: true,
        accept: action.payload,
        error: "",
      };
    case UPDATE_ACCEPTPO_FAILURE:
      return {
        loading: false,
        accept: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAccepted = {
  loading: false,
  accepted: {},
  error: "",
};

const acceptedPoReducer = (state = initialStateForAccepted, action) => {
  switch (action.type) {
    case GET_ACCEPTED_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCEPTED_PURCHASE_ORDERS_SUCCESS:
      return {
        loading: false,
        accepted: action.payload,
        error: "",
      };
    case GET_ACCEPTED_PURCHASE_ORDERS_FAILURE:
      return {
        loading: false,
        accepted: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUnloadingPoint = {
  loading: false,
  unloadingPointData: {},
  error: "",
};

const unloadingPointForPOReducer = (
  state = initialStateForUnloadingPoint,
  action
) => {
  switch (action.type) {
    case GET_UNLOADING_POINT_FOR_PO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_UNLOADING_POINT_FOR_PO_SUCCESS:
      return {
        loading: false,
        unloadingPointData: action.payload,
        error: "",
      };
    case GET_UNLOADING_POINT_FOR_PO_FAILURE:
      return {
        loading: false,
        unloadingPointData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDeliveryPlant = {
  loading: false,
  deliveryPlantData: {},
  error: "",
};

const deliveryPlantForPOReducer = (
  state = initialStateForDeliveryPlant,
  action
) => {
  switch (action.type) {
    case GET_DELIVERY_PLANT_FOR_PO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_PLANT_FOR_PO_SUCCESS:
      return {
        loading: false,
        deliveryPlantData: action.payload,
        error: "",
      };
    case GET_DELIVERY_PLANT_FOR_PO_FAILURE:
      return {
        loading: false,
        deliveryPlantData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getPurchaseOrderReducer,
  createPurchaseOrderReducer,
  editPurchaseOrderReducer,
  deletePurchaseOrderReducer,
  publishPurchaseOrderReducer,
  listOfItemsReducer,
  notAcceptedPurchasedOrdersReducer,
  getVendorCodeReducer,
  acceptPoReducer,
  getReleasedPurchaseOrderReducer,
  acceptedPoReducer,
  unloadingPointForPOReducer,
  deliveryPlantForPOReducer,
};
