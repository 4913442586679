/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Clear";

// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Functions from store
import {
  getFinishedGoods,
  getSubModulesByRoleId,
  getVinByFgCode,
  createPicklist,
  getItemMaster,
  getDocumentType,
  getCreatedActivity,
  getWarehouses,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  createPicklistForQty,
  getAvailableQtyForTo,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  getSuidsForFg,
  getItemsForFg,
  createPicklistForSuid,
  getFgCodeForDocument,
  getRelationLocation,
} from "../../../store";

//  some date function
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";
import { Backdrop, CircularProgress } from "@mui/material";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 700 },
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 380, md: 800, lg: 1200, xl: 1200 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const FinishedGoods = ({
  getFinishedGoods,
  getFinishedGoodsReducer,
  getVinByFgCode,
  getVinByFgCodeReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  createPicklistForSuid,
  createPicklistForQty,

  getRelationLocation,
  getDocumentType,
  getCreatedActivity,
  getCreatedActivityReducer,
  getDocumentTypeReducer,
  getFgCodeDocumentReducer,
  getFgCodeForDocument,
  getWarehouses,
  warehouses,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevel,
  getLevel4ByLevelReducer,
  getAvailableQtyForTo,
  availableQtyState,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
  level1ByLevelSrcReducer,
  level2ByLevelSrcReducer,
  level3ByLevelSrcReducer,
  level4ByLevelSrcReducer,
  getRelationLocationReducer,
  getSuidsForFg,
  getSuidsForFgReducer,
  getItemsForFg,
  getFgItemsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let fgModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d5035fa7-0f37-47bc-aa40-cd33e49289b7"
  );

  let viewFG = fgModule
    ? fgModule.actionId.includes("97e5accd-a34a-49e9-b886-4a4c4f80c28e")
    : null;
  let createFG = fgModule
    ? fgModule.actionId.includes("4d4c4642-e7b3-400d-b8c3-0679354fc33e")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "FG Code", accessor: "fgCode" },
      {
        Header: "Date & Time",
        accessor: "dateTime",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      // { Header: "FG Description", accessor: "fgDescription" },
      { Header: "Qty", accessor: "qty" },
      { Header: "UOM", accessor: "uom" },
      { Header: "VIN", accessor: "vin" },
      { Header: "Serial No", accessor: "serialNo" },
      { Header: "Status", accessor: "status" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [vinTable, setVinTable] = useState({
    columns: [
      { Header: "VIN", accessor: "vin" },
      { Header: "Serial No", accessor: "serialNo" },
      { Header: "Status", accessor: "status" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = vinTable;

  useEffect(() => {
    getFinishedGoods(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const data = getRelationLocationReducer.locations?.data || [];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getFinishedGoodsReducer.finishedGoods.data
      ? getFinishedGoodsReducer.finishedGoods.data
      : [];
    setPaginationObj(getFinishedGoodsReducer?.finishedGoods?.pagination);

    setLoading(getFinishedGoodsReducer.loading);
    data?.map((finishedGoods) => {
      let des = finishedGoods?.description;
      let len = desLength;
      const time = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        fgCode: finishedGoods.fgCode,
        dateTime: finishedGoods.cd,
        fgDescription: des ? des.substring(0, len) : "",
        qty: `${finishedGoods.qty}`,
        uom: `${finishedGoods.uom}`,
        vin: finishedGoods.vin,
        serialNo: finishedGoods.sNo,
        status: (
          <button
            className={
              (finishedGoods.status === 0 && `status-created`) ||
              (finishedGoods.status === 1 && `status-error`) ||
              (finishedGoods.status === 3 && `status-success`) ||
              (finishedGoods.status === 2 && `status-warning`)
            }
            style={{ width: "5.5rem" }}
          >
            {(finishedGoods.status === 0 && `Created`) ||
              (finishedGoods.status === 1 && `Not Picked`) ||
              (finishedGoods.status === 2 && `Picked`) ||
              (finishedGoods.status === 3 && `Delivered`)}
          </button>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewFG && setState({ ...state, rows: tempFinishedGoods });
  }, [getFinishedGoodsReducer]);

  const [vinModal, setOpenVinModal] = useState(false);

  const handleOpenVinModal = () => {
    setTimeout(() => {
      setOpenVinModal(true);
    }, 200);
  };
  const handleCloseVinModal = () => {
    setOpenVinModal(false);
  };

  const [fgCode, setFgCode] = useState("");

  useEffect(() => {
    let tempVin = [];
    let data = getVinByFgCodeReducer.vin ? getVinByFgCodeReducer.vin : [];

    data.map((vin) => {
      const vinInfo = {
        vin: vin.vin,
        serialNo: vin.sNo,
        status: (
          <button
            className={
              (vin.status === 0 && `status-created`) ||
              (vin.status === 1 && `status-error`) ||
              (vin.status === 3 && `status-success`) ||
              (vin.status === 2 && `status-warning`)
            }
            style={{ width: "5.5rem" }}
          >
            {(vin.status === 0 && `Created`) ||
              (vin.status === 1 && `Not Picked`) ||
              (vin.status === 2 && `Picked`) ||
              (vin.status === 3 && `Delivered`)}
          </button>
        ),
      };
      tempVin.push(vinInfo);
    });

    setVinTable({ ...vinTable, rows: tempVin });
  }, [getVinByFgCodeReducer]);

  useEffect(() => {
    getItemsForFg(jwtToken);
  }, []);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getFgItemsReducer.fgItems.data
      ? getFgItemsReducer.fgItems.data
      : [];

    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [getFgItemsReducer]);
  const [selectedItemCode, setSelectedItemCode] = useState("");

  const [suidOptions, setSuidOptions] = useState([]);

  useEffect(() => {
    let tempSuidOptions = [];
    let data = getSuidsForFgReducer.suidsForFg.data
      ? getSuidsForFgReducer.suidsForFg.data
      : [];
    data.map((suid) => {
      tempSuidOptions.push({
        label: suid.suid,
        value: suid.suid,
        sLocation: suid.locationCode,
      });
    });
    setSuidOptions(tempSuidOptions);
  }, [getSuidsForFgReducer]);

  useEffect(() => {}, []);
  useEffect(() => {
    getFgCodeForDocument(jwtToken);
  }, []);
  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentCode,
        documentId: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  const [documentNoSelected, setDocumentNoSelected] = useState("");

  const handleDocumentNoChange = (selectedActivity) => {
    setDocumentNoSelected(selectedActivity);
  };

  const [documentNoOptions, setDocumentNoOptions] = useState([]);

  useEffect(() => {
    let tempDocumentNoOptions = [];
    let data = getFgCodeDocumentReducer.fgCodeDocument
      ? getFgCodeDocumentReducer.fgCodeDocument
      : [];
    data.map((activity) => {
      tempDocumentNoOptions.push({
        label: activity.fgCode,
        value: activity.fgCode,
      });
    });
    setDocumentNoOptions(tempDocumentNoOptions);
  }, [getFgCodeDocumentReducer]);

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
  };

  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
    setActivityIdSelected("");
  };

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const initialValues = {
    movementType: "",
    documentNumber: "",
    PicklistType: "suid",
    sourceLocation: "",
    destinationLocation: "",
    sWarehouse: "",
    sZone: "",
    sSection: "",
    sRack: "",
    sLocation: "",
    itemsForQty: [
      {
        itemId: "",
        qty: "",
        availableQty: "",
      },
    ],
    itemsForSuid: [
      {
        itemId: "",
        suids: "",
        sourceLocation: "",
      },
    ],
    dWarehouse: "",
    dZone: "",
    dSection: "",
    dRack: "",
    dLocation: "",
  };

  const validationSchema = Yup.object({
    movementType: Yup.object().required("Select the movement type!"),
    // documentNumber: Yup.string().required("Enter the document no!"),
    dWarehouse: Yup.object().required("Select the delivery warehouse!"),
    dZone: Yup.object().required("Select the delivery zone!"),
  });

  const PicklistSuccessNotification = () =>
    toast.success("Picklist created successfully!", {
      position: "top-center",
      autoClose: 2000,
    });

  const PicklistFailureNotification = () =>
    toast.error(errorMsgForCreate, {
      position: "top-center",
      autoClose: 2000,
    });

  const [buttonName, setButtonName] = useState("Submit");
  const onSubmitForCreatePicklist = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    if (values.PicklistType === "qty") {
      let payload = {
        documentNumber: documentNoSelected.value
          ? documentNoSelected.value
          : "",
        mvtId: activityIdSelected.value ? activityIdSelected.value : "",
        destinationLocation: values.destinationLocation,
        sourceLocation: values.sourceLocation,
        items: values.itemsForQty,
      };

      let res = await createPicklistForQty(payload, jwtToken);

      if (res.status) {
        // setIsSuccessCreatedPicklist(true);
        PicklistSuccessNotification();
        getFinishedGoods(pageNo, rowsPerPage, searchObject, jwtToken);
        resetForm();
        setSwarehouseSelected("");
        setSzoneSelected("");
        setSsectionSelected("");
        setSrackSelected("");
        setSlocationSelected("");
        setDwarehouseSelected("");
        setDzoneSelected("");
        setDsectionSelected("");
        setDrackSelected("");
        setDlocationSelected("");
        setDocumentTypeSelected("");
        setDocumentNoSelected("");
        setActivityIdSelected("");
        setTimeout(() => {
          handleCreateClosePicklistModal();
          setButtonName("Submit");
        }, 2500);
      }

      if (!res.status) {
        // setIsErrorCreatedPicklist(true);
        setErrorMsgForCreate(res?.data?.data?.msg);
        PicklistFailureNotification();
        setButtonName("Submit");
      }
    }
    if (values.PicklistType === "suid") {
      let payload = {
        documentNumber: documentNoSelected.value
          ? documentNoSelected.value
          : "",
        mvtId: activityIdSelected.value ? activityIdSelected.value : "",
        destinationLocation: values.destinationLocation,
        items: values.itemsForSuid,
      };

      let res = await createPicklistForSuid(payload, jwtToken);

      if (res.status) {
        // setIsSuccessCreatedPicklist(true);
        PicklistSuccessNotification();
        getFinishedGoods(pageNo, rowsPerPage, searchObject, jwtToken);
        resetForm();
        setSwarehouseSelected("");
        setSzoneSelected("");
        setSsectionSelected("");
        setSrackSelected("");
        setSlocationSelected("");
        setDwarehouseSelected("");
        setDzoneSelected("");
        setDsectionSelected("");
        setDrackSelected("");
        setDlocationSelected("");
        setDocumentTypeSelected("");
        setDocumentNoSelected("");
        setActivityIdSelected("");
        setTimeout(() => {
          handleCreateClosePicklistModal();
          setButtonName("Submit");
        }, 2500);
      }

      if (!res.status) {
        // setIsErrorCreatedPicklist(true);
        setErrorMsgForCreate(res?.data?.data?.msg);
        PicklistFailureNotification();
        setButtonName("Submit");
      }
    }
  };

  const [isSuccessCreatedPicklist, setIsSuccessCreatedPicklist] =
    useState(false);
  const [isErrorCreatedPicklist, setIsErrorCreatedPicklist] = useState(false);

  useEffect(() => {
    if (isErrorCreatedPicklist) {
      setTimeout(() => {
        setIsErrorCreatedPicklist(false);
      }, 3000);
    }
  }, [isErrorCreatedPicklist]);

  useEffect(() => {
    if (isSuccessCreatedPicklist) {
      setTimeout(() => {
        setIsSuccessCreatedPicklist(false);
      }, 3000);
    }
  }, [isSuccessCreatedPicklist]);

  const [createPicklistModal, setCreatePicklistModal] = useState(false);
  const handleCreatePicklistModal = () => {
    setItemCodeSelected("");

    setCreatePicklistModal(true);
  };
  const handleCreateClosePicklistModal = () => {
    setItemCodeSelected("");

    setCreatePicklistModal(false);
  };

  const [itemCodeSelected, setItemCodeSelected] = useState("");

  const [sWarehouseSelected, setSwarehouseSelected] = useState("");
  const [sZoneSelected, setSzoneSelected] = useState("");
  const [sSectionSelected, setSsectionSelected] = useState("");
  const [sRackSelected, setSrackSelected] = useState("");
  const [sLocationSelected, setSlocationSelected] = useState("");

  const handleSwarehouseChange = (selectedData) => {
    setSwarehouseSelected(selectedData);
    setSzoneSelected("");
    setSsectionSelected("");
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSzoneChange = (selectedRack) => {
    setSzoneSelected(selectedRack);
    setSsectionSelected("");
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSsectionChange = (selectedRack) => {
    setSsectionSelected(selectedRack);
    setSrackSelected("");
    setSlocationSelected("");
  };

  const handleSrackChange = (selectedRack) => {
    setSrackSelected(selectedRack);
    setSlocationSelected("");
  };

  const handleSlocationChange = (selectedLocation) => {
    setSlocationSelected(selectedLocation);
  };

  const [dWarehouseSelected, setDwarehouseSelected] = useState("");
  const [dZoneSelected, setDzoneSelected] = useState("");
  const [dSectionSelected, setDsectionSelected] = useState("");
  const [dRackSelected, setDrackSelected] = useState("");
  const [dLocationSelected, setDlocationSelected] = useState("");

  const handleDwarehouseChange = (selectedData) => {
    setDwarehouseSelected(selectedData);
    setDzoneSelected("");
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDzoneChange = (selectedRack) => {
    setDzoneSelected(selectedRack);
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDsectionChange = (selectedRack) => {
    setDsectionSelected(selectedRack);
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDrackChange = (selectedRack) => {
    setDrackSelected(selectedRack);
    setDlocationSelected("");
  };

  const handleDlocationChange = (selectedLocation) => {
    setDlocationSelected(selectedLocation);
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
        key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  // Source Options
  const [level1OptionsSrc, setLevel1OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level1ByLevelSrcReducer.level1ByLevel.data
      ? level1ByLevelSrcReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1OptionsSrc(tempLevelOptions);
  }, [level1ByLevelSrcReducer]);

  const [level2OptionsSrc, setLevel2OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level2ByLevelSrcReducer.level2ByLevel.data
      ? level2ByLevelSrcReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2OptionsSrc(tempLevelOptions);
  }, [level2ByLevelSrcReducer]);

  const [level3OptionsSrc, setLevel3OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level3ByLevelSrcReducer.level3ByLevel.data
      ? level3ByLevelSrcReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel3OptionsSrc(tempLevelOptions);
  }, [level3ByLevelSrcReducer]);

  const [level4OptionsSrc, setLevel4OptionsSrc] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = level4ByLevelSrcReducer.level4ByLevel.data
      ? level4ByLevelSrcReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel4OptionsSrc(tempLevelOptions);
  }, [level4ByLevelSrcReducer]);

  // Delivery Options
  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        key: level.locationName,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  return (
    <>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mt={-5}
            >
              {createFG && (
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <CreateButton
                    tooltip="Create Picklist"
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleCreatePicklistModal();
                      getDocumentType(jwtToken);
                      getCreatedActivity(21, jwtToken);
                      getRelationLocation(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
              )}
            </MDBox>
            <MDBox mt={-2}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>
            {/* <Modal
              open={vinModal}
              onClose={handleCloseVinModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    {fgCode}
                  </MDTypography>
                  <MDBox></MDBox>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseVinModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />
                <MDBox>
                  <DataTable
                    table={{ columns: itemColumns, rows: itemRows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
              </MDCard>
            </Modal> */}
            <Modal
              open={createPicklistModal}
              onClose={handleCreateClosePicklistModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Picklist
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCreateClosePicklistModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmitForCreatePicklist}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div>
                                  <MDBox
                                  // style={{
                                  //   // position: "sticky",
                                  //   top: "0",
                                  //   width: "100%",
                                  //   // zIndex: 999,
                                  // }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} lg={6}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Movement Type
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="movementType"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "movementType",
                                                },
                                              });
                                            }}
                                            value={activityIdSelected}
                                            options={activityOptions}
                                            onChange={(selectedOption) => {
                                              handleActivityIdChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "movementType",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.movementType &&
                                            formik.errors.movementType && (
                                              <TextError
                                                msg={formik.errors.movementType}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Document No
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="documentNumber"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "documentNumber",
                                                },
                                              });
                                            }}
                                            value={documentNoSelected}
                                            options={documentNoOptions}
                                            onChange={(selectedOption) => {
                                              handleDocumentNoChange(
                                                selectedOption
                                              );
                                              getSuidsForFg(
                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "documentNumber",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.errors.documentNumber && (
                                            <TextError
                                              msg={formik.errors.documentNumber}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  <hr style={{ marginBottom: "10px" }} />

                                  <MDBox>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div>
                                        <Field
                                          type="radio"
                                          name="PicklistType"
                                          value="suid"
                                          className="radio"
                                        />
                                        <span>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            style={{
                                              marginLeft: "1.5rem",
                                              marginRight: "10px",
                                              fontSize: "15px",
                                              marginTop: "-1.77rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            SUID
                                          </MDTypography>
                                        </span>
                                      </div>
                                      <div>
                                        <Field
                                          type="radio"
                                          name="PicklistType"
                                          value="qty"
                                          className="radio"
                                        />
                                        <span>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            style={{
                                              marginLeft: "1.5rem",
                                              fontSize: "15px",
                                              marginTop: "-1.77rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            QTY
                                          </MDTypography>
                                        </span>
                                      </div>
                                    </div>
                                  </MDBox>

                                  {formik.values.PicklistType === "qty" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Source Location
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />
                                      <Grid container>
                                        <Grid item xs={12} lg={4}>
                                          <MDBox
                                            mb={1}
                                            style={{ width: "370px" }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{
                                                fontWeight: "500",
                                              }}
                                            >
                                              WareHouse Code
                                            </MDTypography>
                                            <Select
                                              isClearable
                                              className="select-css"
                                              placeholder="Choose one..."
                                              name="sWarehouse"
                                              onBlur={() => {
                                                formik.handleBlur({
                                                  target: {
                                                    name: "sWarehouse",
                                                  },
                                                });
                                              }}
                                              value={sWarehouseSelected}
                                              options={warehouseOptions}
                                              onChange={(selectedOption) => {
                                                handleSwarehouseChange(
                                                  selectedOption
                                                );
                                                getLevel1ByLevelSrc(
                                                  selectedOption?.value,
                                                  0,
                                                  jwtToken
                                                );
                                                formik.setFieldValue(
                                                  "sWarehouse",
                                                  selectedOption
                                                );
                                                formik.setFieldValue(
                                                  "sourceLocation",
                                                  `${
                                                    selectedOption?.label
                                                      ? selectedOption.label
                                                      : ""
                                                  }`
                                                );
                                              }}
                                            />
                                            {formik.touched.sWarehouse &&
                                              formik.errors.sWarehouse && (
                                                <TextError
                                                  msg={formik.errors.sWarehouse}
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <MDBox
                                            ml={1}
                                            style={{ width: "370px" }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{
                                                fontWeight: "500",
                                              }}
                                            >
                                              Level 1:-
                                              {data.length > 0
                                                ? data[0].rltName
                                                : "No data available"}
                                            </MDTypography>
                                            <Select
                                              isClearable
                                              isDisabled={
                                                sWarehouseSelected
                                                  ? false
                                                  : true
                                              }
                                              className="select-css"
                                              maxMenuHeight={130}
                                              placeholder="Choose one..."
                                              name="sZone"
                                              onBlur={() => {
                                                formik.handleBlur({
                                                  target: {
                                                    name: "sZone",
                                                  },
                                                });
                                              }}
                                              value={sZoneSelected}
                                              options={level1OptionsSrc}
                                              onChange={(selectedOption) => {
                                                handleSzoneChange(
                                                  selectedOption
                                                );
                                                getLevel2ByLevelSrc(
                                                  sWarehouseSelected?.value,
                                                  selectedOption?.value,
                                                  jwtToken
                                                );
                                                formik.setFieldValue(
                                                  "sZone",
                                                  selectedOption
                                                );
                                                formik.setFieldValue(
                                                  "sourceLocation",
                                                  `${
                                                    formik.values.sWarehouse
                                                      ? formik.values.sWarehouse
                                                          .label
                                                      : ""
                                                  }${
                                                    selectedOption?.label
                                                      ? `-${selectedOption.label}`
                                                      : ""
                                                  }`
                                                );
                                              }}
                                            />
                                            {formik.touched.sZone &&
                                              formik.errors.sZone && (
                                                <TextError
                                                  msg={formik.errors.sZone}
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <MDBox
                                            mb={1}
                                            ml={1}
                                            style={{ width: "370px" }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{
                                                fontWeight: "500",
                                              }}
                                            >
                                              Level 2:-
                                              {data.length > 0
                                                ? data[1].rltName
                                                : "No data available"}
                                            </MDTypography>
                                            <Select
                                              isClearable
                                              isDisabled={
                                                sZoneSelected ? false : true
                                              }
                                              className="select-css"
                                              maxMenuHeight={130}
                                              placeholder="Choose one..."
                                              name="sSection"
                                              onBlur={() => {
                                                formik.handleBlur({
                                                  target: {
                                                    name: "sSection",
                                                  },
                                                });
                                              }}
                                              value={sSectionSelected}
                                              options={level2OptionsSrc}
                                              onChange={(selectedOption) => {
                                                handleSsectionChange(
                                                  selectedOption
                                                );
                                                getLevel3ByLevelSrc(
                                                  sWarehouseSelected?.value,
                                                  selectedOption?.value,
                                                  jwtToken
                                                );
                                                formik.setFieldValue(
                                                  "sSection",
                                                  selectedOption
                                                );
                                                formik.setFieldValue(
                                                  "sourceLocation",
                                                  `${
                                                    formik.values.sWarehouse
                                                      ? formik.values.sWarehouse
                                                          .label
                                                      : ""
                                                  }${
                                                    formik.values.sZone
                                                      ? `-${formik.values.sZone.label}`
                                                      : ""
                                                  }${
                                                    selectedOption?.label
                                                      ? `-${selectedOption.label}`
                                                      : ""
                                                  }`
                                                );
                                              }}
                                            />
                                            {formik.touched.sSection &&
                                              formik.errors.sSection && (
                                                <TextError
                                                  msg={formik.errors.sSection}
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <MDBox
                                            // ml={1}
                                            style={{ width: "370px" }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{
                                                fontWeight: "500",
                                              }}
                                            >
                                              Level 3:-
                                              {data.length > 0
                                                ? data[2].rltName
                                                : "No data available"}
                                            </MDTypography>
                                            <Select
                                              isClearable
                                              isDisabled={
                                                sSectionSelected ? false : true
                                              }
                                              className="select-css"
                                              maxMenuHeight={130}
                                              placeholder="Choose One..."
                                              name="sRack"
                                              onBlur={() => {
                                                formik.handleBlur({
                                                  target: {
                                                    name: "sRack",
                                                  },
                                                });
                                              }}
                                              value={sRackSelected}
                                              options={level3OptionsSrc}
                                              onChange={(selectedOption) => {
                                                handleSrackChange(
                                                  selectedOption
                                                );
                                                getLevel4ByLevelSrc(
                                                  sWarehouseSelected?.value,
                                                  selectedOption?.value,
                                                  jwtToken
                                                );
                                                formik.setFieldValue(
                                                  "sRack",
                                                  selectedOption
                                                );
                                                formik.setFieldValue(
                                                  "sourceLocation",
                                                  `${
                                                    formik.values.sWarehouse
                                                      ? formik.values.sWarehouse
                                                          .label
                                                      : ""
                                                  }${
                                                    formik.values.sZone
                                                      ? `-${formik.values.sZone.label}`
                                                      : ""
                                                  }${
                                                    formik.values.sSection
                                                      ? `-${formik.values.sSection.label}`
                                                      : ""
                                                  }${
                                                    selectedOption?.label
                                                      ? `-${selectedOption.label}`
                                                      : ""
                                                  }`
                                                );
                                              }}
                                            />
                                            {formik.touched.sRack &&
                                              formik.errors.sRack && (
                                                <TextError
                                                  msg={formik.errors.sRack}
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <MDBox
                                            mb={1}
                                            ml={1}
                                            style={{ width: "370px" }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data.length > 0
                                                ? data[3].rltName
                                                : "No data available"}
                                            </MDTypography>
                                            <Select
                                              isClearable
                                              isDisabled={
                                                sRackSelected ? false : true
                                              }
                                              className="select-css"
                                              placeholder="Choose one..."
                                              name="sLocation"
                                              onBlur={() => {
                                                formik.handleBlur({
                                                  target: {
                                                    name: "sLocation",
                                                  },
                                                });
                                              }}
                                              value={sLocationSelected}
                                              options={level4OptionsSrc}
                                              onChange={(selectedOption) => {
                                                handleSlocationChange(
                                                  selectedOption
                                                );
                                                formik.setFieldValue(
                                                  "sLocation",
                                                  selectedOption
                                                );
                                                formik.setFieldValue(
                                                  "sourceLocation",
                                                  `${
                                                    formik.values.sWarehouse
                                                      ? formik.values.sWarehouse
                                                          .label
                                                      : ""
                                                  }${
                                                    formik.values.sZone
                                                      ? `-${formik.values.sZone.label}`
                                                      : ""
                                                  }${
                                                    formik.values.sSection
                                                      ? `-${formik.values.sSection.label}`
                                                      : ""
                                                  }${
                                                    formik.values.sRack
                                                      ? `-${formik.values.sRack.label}`
                                                      : ""
                                                  }${
                                                    selectedOption?.label
                                                      ? `-${selectedOption.label}`
                                                      : ""
                                                  }`
                                                );
                                              }}
                                            />
                                            {formik.touched.sLocation &&
                                              formik.errors.sLocation && (
                                                <TextError
                                                  msg={formik.errors.sLocation}
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </MDBox>
                                  )}

                                  {formik.values.PicklistType === "suid" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForSuid">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForSuid } = values;

                                          return (
                                            <div>
                                              {itemsForSuid.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          lg={4}
                                                        >
                                                          <MDBox ml={1}>
                                                            <MDTypography variant="h6">
                                                              Item Code/Des -{" "}
                                                              {index + 1}
                                                            </MDTypography>
                                                            <Select
                                                              minMenuHeight={
                                                                300
                                                              }
                                                              className="select-css-for-itemcode"
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "12px",
                                                                  marginTop:
                                                                    "-2px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              options={
                                                                itemCodeOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForSuid.${index}.itemId`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={
                                                                itemsForSuid.itemId
                                                              }
                                                              placeholder="Choose one..."
                                                            />
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          lg={3}
                                                        >
                                                          <MDBox>
                                                            <MDTypography variant="h6">
                                                              SUID
                                                            </MDTypography>
                                                            <Select
                                                              disabled={
                                                                suidOptions.length ===
                                                                0
                                                              }
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "12px",
                                                                  marginTop:
                                                                    "-2px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              options={
                                                                suidOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForSuid.${index}.suids`,
                                                                  selectedOption?.value
                                                                );

                                                                formik.setFieldValue(
                                                                  `itemsForSuid.${index}.sourceLocation`,
                                                                  selectedOption?.sLocation
                                                                );
                                                              }}
                                                              value={
                                                                itemsForSuid.suids
                                                              }
                                                              placeholder="Choose one..."
                                                            />
                                                            {/* {formik?.touched[
                                                            `lineItemsForSuid.${index}.suid`
                                                          ] &&
                                                            formik?.errors[
                                                              `lineItemsForSuid.${index}.suid`
                                                            ] && (
                                                              <TextError
                                                                msg={
                                                                  formik.errors[
                                                                    `lineItemsForSuid.${index}.suid`
                                                                  ]
                                                                }
                                                              />
                                                            )} */}
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          lg={3}
                                                        >
                                                          <MDBox ml={1}>
                                                            <MDTypography variant="h6">
                                                              Source Location
                                                            </MDTypography>
                                                            <Field
                                                              disabled
                                                              className="form-control"
                                                              style={{
                                                                borderRadius: 5,
                                                                marginLeft: 0,
                                                                height: 36,
                                                                paddingLeft:
                                                                  "15px",
                                                                width: "280px",
                                                              }}
                                                              type="text"
                                                              name={`itemsForSuid.${index}.sourceLocation`}
                                                              value={
                                                                itemsForSuid[
                                                                  index
                                                                ]
                                                                  ?.sourceLocation
                                                              }
                                                              fullWidth
                                                              autoComplete="off"
                                                              onChange={
                                                                formik.handleChange
                                                              }
                                                              // error={
                                                              //   formik.touched
                                                              //     .sLocation &&
                                                              //   formik.errors
                                                              //     .sLocation &&
                                                              //   true
                                                              // }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                },
                                                                maxLength: 45,
                                                                readOnly: true,
                                                              }}
                                                            />

                                                            {formik.touched
                                                              .sLocation &&
                                                              formik.errors
                                                                .sLocation && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .sLocation
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            marginTop: "1.5rem",
                                                            marginLeft: "2rem",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                          </div>
                                                          {index ===
                                                            itemsForSuid.length -
                                                              1 && (
                                                            <div>
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      suids: "",
                                                                      sourceLocation:
                                                                        "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    width:
                                                                      "30px",
                                                                    marginLeft:
                                                                      "0rem",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>
                                  )}

                                  {formik.values.PicklistType === "qty" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForQty">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForQty } = values;

                                          return (
                                            <div
                                            // style={{
                                            //   overflowY: "auto",
                                            //   // maxHeight:
                                            //   //   "calc(60vh - 120px)",
                                            // }}
                                            >
                                              {itemsForQty.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          lg={4}
                                                        >
                                                          <MDBox ml={1}>
                                                            <MDTypography variant="h6">
                                                              Item Code/Des -{" "}
                                                              {index + 1}
                                                            </MDTypography>
                                                            <Select
                                                              minMenuHeight={
                                                                300
                                                              }
                                                              className="select-css-for-itemcode"
                                                              isDisabled={
                                                                formik.values
                                                                  .PicklistType ===
                                                                  "qty" &&
                                                                sWarehouseSelected &&
                                                                sZoneSelected
                                                                  ? false
                                                                  : true
                                                              }
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "12px",
                                                                  marginTop:
                                                                    "-2px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              options={
                                                                itemCodeOptions
                                                              }
                                                              onChange={async (
                                                                selectedOption
                                                              ) => {
                                                                let res =
                                                                  await getAvailableQtyForTo(
                                                                    selectedOption?.value,
                                                                    formik
                                                                      .values
                                                                      .sourceLocation,
                                                                    jwtToken
                                                                  );

                                                                formik.setFieldValue(
                                                                  `itemsForQty.${index}.availableQty`,
                                                                  res?.data
                                                                    ?.data?.qty
                                                                );

                                                                formik.setFieldValue(
                                                                  `itemsForQty.${index}.itemId`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={
                                                                itemsForQty.itemId
                                                              }
                                                              placeholder="Choose one..."
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* {values.lineItems[0]
                                                            .itemCodeDes &&
                                                            getAvailableQtyForTo(
                                                              values
                                                                .lineItems[0]
                                                                .itemCodeDes,
                                                              "",
                                                              jwtToken
                                                            )} */}

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          lg={3}
                                                        >
                                                          <MDBox mb={2} ml={1}>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                borderRadius: 5,
                                                              }}
                                                            >
                                                              <MDTypography
                                                                variant="h6"
                                                                style={{
                                                                  fontWeight: 500,
                                                                }}
                                                              >
                                                                Qty
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "7rem",
                                                                  }}
                                                                >
                                                                  Available Qty:{" "}
                                                                  {
                                                                    itemsForQty[
                                                                      index
                                                                    ]
                                                                      ?.availableQty
                                                                  }
                                                                </span>
                                                              </MDTypography>
                                                            </div>
                                                            <Field
                                                              className="form-control"
                                                              style={{
                                                                borderRadius: 5,
                                                                marginLeft: 0,
                                                                height: 36,
                                                                paddingLeft:
                                                                  "15px",
                                                              }}
                                                              fullWidth
                                                              type="number"
                                                              name={`itemsForQty[${index}].qty`}
                                                              value={
                                                                itemsForQty[
                                                                  index
                                                                ]?.qty
                                                              }
                                                              required
                                                              max={
                                                                itemsForQty[
                                                                  index
                                                                ]?.availableQty
                                                              }
                                                              autoComplete="off"
                                                              onChange={(e) => {
                                                                formik.handleChange(
                                                                  e
                                                                );
                                                              }}
                                                              // error={
                                                              //   formik.touched
                                                              //     .qty &&
                                                              //   formik.errors
                                                              //     .qty &&
                                                              //   true
                                                              // }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                  width:
                                                                    "150px",
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />

                                                            {formik.touched
                                                              .qty &&
                                                              formik.errors
                                                                .qty && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .qty
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            marginTop: "1.5rem",
                                                            marginLeft: "2rem",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                          </div>
                                                          {index ===
                                                            itemsForQty.length -
                                                              1 && (
                                                            <div>
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      qty: "",
                                                                      availableQty:
                                                                        "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    width:
                                                                      "30px",
                                                                    marginLeft:
                                                                      "0rem",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>
                                  )}

                                  <MDBox mt={2}>
                                    <MDTypography variant="h5">
                                      Delivery Location
                                    </MDTypography>
                                    <hr style={{ marginBottom: "10px" }} />
                                    <Grid container>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            WareHouse Code
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose one..."
                                            name="dWarehouse"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dWarehouse",
                                                },
                                              });
                                            }}
                                            value={dWarehouseSelected}
                                            options={warehouseOptions}
                                            onChange={(selectedOption) => {
                                              handleDwarehouseChange(
                                                selectedOption
                                              );
                                              getLevel1ByLevel(
                                                selectedOption?.value,
                                                0,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "dWarehouse",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "destinationLocation",
                                                `${
                                                  selectedOption?.label
                                                    ? selectedOption.label
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.dWarehouse &&
                                            formik.errors.dWarehouse && (
                                              <TextError
                                                msg={formik.errors.dWarehouse}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 1:-
                                            {data.length > 0
                                              ? data[0].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dWarehouseSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose one..."
                                            name="dZone"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dZone",
                                                },
                                              });
                                            }}
                                            value={dZoneSelected}
                                            options={level1Options}
                                            onChange={(selectedOption) => {
                                              handleDzoneChange(selectedOption);
                                              getLevel2ByLevel(
                                                dWarehouseSelected?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "dZone",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "destinationLocation",
                                                `${
                                                  formik.values.dWarehouse
                                                    ? formik.values.dWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.dZone &&
                                            formik.errors.dZone && (
                                              <TextError
                                                msg={formik.errors.dZone}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 2:-
                                            {data.length > 0
                                              ? data[1].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dZoneSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose one..."
                                            name="dSection"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dSection",
                                                },
                                              });
                                            }}
                                            value={dSectionSelected}
                                            options={level2Options}
                                            onChange={(selectedOption) => {
                                              handleDsectionChange(
                                                selectedOption
                                              );
                                              getLevel3ByLevel(
                                                dWarehouseSelected?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "dSection",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "destinationLocation",
                                                `${
                                                  formik.values.dWarehouse
                                                    ? formik.values.dWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  formik.values.dZone
                                                    ? `-${formik.values.dZone.label}`
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.dSection &&
                                            formik.errors.dSection && (
                                              <TextError
                                                msg={formik.errors.dSection}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          // ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 3:-
                                            {data.length > 0
                                              ? data[2].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dSectionSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose One..."
                                            name="dRack"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dRack",
                                                },
                                              });
                                            }}
                                            value={dRackSelected}
                                            options={level3Options}
                                            onChange={(selectedOption) => {
                                              handleDrackChange(selectedOption);
                                              getLevel4ByLevel(
                                                dWarehouseSelected?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "dRack",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "destinationLocation",
                                                `${
                                                  formik.values.dWarehouse
                                                    ? formik.values.dWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  formik.values.dZone
                                                    ? `-${formik.values.dZone.label}`
                                                    : ""
                                                }${
                                                  formik.values.dSection
                                                    ? `-${formik.values.dSection.label}`
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.dRack &&
                                            formik.errors.dRack && (
                                              <TextError
                                                msg={formik.errors.dRack}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data.length > 0
                                              ? data[3].rltName
                                              : "No data available"}
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dRackSelected ? false : true
                                            }
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="dLocation"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dLocation",
                                                },
                                              });
                                            }}
                                            value={dLocationSelected}
                                            options={level4Options}
                                            onChange={(selectedOption) => {
                                              handleDlocationChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "dLocation",
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "destinationLocation",
                                                `${
                                                  formik.values.dWarehouse
                                                    ? formik.values.dWarehouse
                                                        .label
                                                    : ""
                                                }${
                                                  formik.values.dZone
                                                    ? `-${formik.values.dZone.label}`
                                                    : ""
                                                }${
                                                  formik.values.dSection
                                                    ? `-${formik.values.dSection.label}`
                                                    : ""
                                                }${
                                                  formik.values.dRack
                                                    ? `-${formik.values.dRack.label}`
                                                    : ""
                                                }${
                                                  selectedOption?.label
                                                    ? `-${selectedOption.label}`
                                                    : ""
                                                }`
                                              );
                                            }}
                                          />
                                          {formik.touched.dLocation &&
                                            formik.errors.dLocation && (
                                              <TextError
                                                msg={formik.errors.dLocation}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  <div style={{ background: "white" }}>
                                    <MDButton
                                      style={{
                                        position: "sticky",
                                        bottom: "0",
                                      }}
                                      color="info"
                                      fullWidth
                                      type="submit"
                                      disabled={
                                        !formik.isValid ||
                                        buttonName === "Please wait..."
                                      }
                                    >
                                      {buttonName}
                                    </MDButton>
                                  </div>
                                  <MDBox>
                                    <Collapse in={isErrorCreatedPicklist}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorCreatedPicklist(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        {errorMsgForCreate}
                                      </Alert>
                                    </Collapse>
                                    <Collapse in={isSuccessCreatedPicklist}>
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessCreatedPicklist(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Picklist Created Successfully
                                      </Alert>
                                    </Collapse>
                                  </MDBox>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getFinishedGoodsReducer: state.getFinishedGoodsReducer,
    getFgCodeDocumentReducer: state.getFgCodeDocumentReducer,
    getVinByFgCodeReducer: state.getVinByFgCodeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getFgItemsReducer: state.getFgItemsReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    getSuidsForFgReducer: state.getSuidsForFgReducer,
    warehouses: state.warehouses,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    availableQtyState: state.getAvailableQtyForToReducer,
    level1ByLevelSrcReducer: state.getLevel1ByLevelSrcReducer,
    level2ByLevelSrcReducer: state.getLevel2ByLevelSrcReducer,
    level3ByLevelSrcReducer: state.getLevel3ByLevelSrcReducer,
    level4ByLevelSrcReducer: state.getLevel4ByLevelSrcReducer,

    getRelationLocationReducer: state.getRelationLocationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getFinishedGoods: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getFinishedGoods(pageNo, rowsPerPage, searchObject, jwtToken)),
    getFgCodeForDocument: (jwtToken) =>
      dispatch(getFgCodeForDocument(jwtToken)),
    getVinByFgCode: (fgCode, jwtToken) =>
      dispatch(getVinByFgCode(fgCode, jwtToken)),
    getSuidsForFg: (itemId, jwtToken) =>
      dispatch(getSuidsForFg(itemId, jwtToken)),
    createPicklistForSuid: (payload, jwtToken) =>
      dispatch(createPicklistForSuid(payload, jwtToken)),
    createPicklistForQty: (payload, jwtToken) =>
      dispatch(createPicklistForQty(payload, jwtToken)),
    getItemsForFg: (jwtToken) => dispatch(getItemsForFg(jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getAvailableQtyForTo: (itemId, location, jwtToken) =>
      dispatch(getAvailableQtyForTo(itemId, location, jwtToken)),
    getLevel1ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel2ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel3ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevelSrc(warehouseId, parentId, jwtToken)),
    getLevel4ByLevelSrc: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevelSrc(warehouseId, parentId, jwtToken)),

    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishedGoods);
