/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
// Functions from store
import { getSubModulesByRoleId, getRejectedTransactions } from "../../../store";

// React-Select component and styling

import "../../../index.css";

import Config from "../../../config/index";
// Cookies
import Cookies from "universal-cookie";
import { Backdrop, CircularProgress } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const RejectedTransaction = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getRejectedTransactions,
  getRejectedTransactionsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let rejectedTransactionModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ad4ef1a3-9a6f-489a-8bc1-4f20808d1ae1"
  );

  let viewAccess = rejectedTransactionModule
    ? rejectedTransactionModule.actionId.includes(
        "f720bf7d-488d-4f82-9f05-9caca4b8f3fe"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getRejectedTransactions(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Transaction code", accessor: "transactionCode" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Request Qty", accessor: "reqQty" },
      { Header: "Balance Qty", accessor: "balanceQty" },
      { Header: "To be Picked Qty ", accessor: "pckedQty" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempAsset = [];
    let data = getRejectedTransactionsReducer.rejectedTransactions.data
      ? getRejectedTransactionsReducer.rejectedTransactions.data
      : [];
    setPaginationObj(
      getRejectedTransactionsReducer.rejectedTransactions?.pagination
    );
    setLoading(getRejectedTransactionsReducer.loading);
    data.map((purchase) => {
      const formatDate = new Date(purchase.cd);
      let len = desLength;
      let des = purchase?.itemDescription;
      const purchaseObject = {
        privateKey: purchase.transactionId,
        transactionCode: purchase.transactionCode,
        itemCode: purchase.itemCode
        ? `${purchase.itemCode}`
        : "",
        itemDescription: 
         `${des ? des.substring(0, len) : ""}`
        ,
        reqQty: `${purchase.requestQty}`,
        balanceQty: `${purchase.balanceQty}`,
        pckedQty: `${purchase.toBePickedQty}`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        // isEditable: purchase.isEditable,
      };
      viewAccess && tempAsset.push(purchaseObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [getRejectedTransactionsReducer]);

  return (
    <div>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox mt={-3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getRejectedTransactionsReducer: state.getRejectedTransactionsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getRejectedTransactions: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getRejectedTransactions(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RejectedTransaction);
