/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getItemMaster,
  getDailyVendorPerformance,
  getItemTypesForStatus,
  getDateRange,
  getSubModulesByRoleId,
  getVendorsListForPlanner,
} from "../../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const Daily = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getItemMaster,
  getDailyVendorPerformance,
  itemMaster,
  getVendorsListForPlanner,
  vendorsListForPlannerReducer,
  getDailyVendorPerformanceReducer,
  getDateRange,
  getDateRangeReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  const [selectedSuccessRate, setSelectedSuccessRate] = useState("");
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getItemMaster(jwtToken);
  }, []);

  useEffect(() => {
    getVendorsListForPlanner(jwtToken);
  }, []);

  useEffect(() => {
    getDateRange(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemStatusReportModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "95815117-55fd-40be-ab8f-a9407d9ae1df"
  );

  let viewItemStatus = itemStatusReportModule
    ? itemStatusReportModule.actionId.includes(
        "e7b51078-d27b-4bf2-9707-efa327848bad"
      )
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Vendor Name", accessor: "vendorName" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      // { Header: "Created Date", accessor: "createdDate" },
      { Header: "Success Rate", accessor: "successRate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempDailyVendor = [];
    let data = getDailyVendorPerformanceReducer.dailyVendorPerformance.data
      ? getDailyVendorPerformanceReducer.dailyVendorPerformance.data
      : [];

    setLoading(getDailyVendorPerformanceReducer.loading);
    data?.map((dailyVendor) => {
      let des = dailyVendor?.itemDescription;
      let len = desLength;
      const formatDate = new Date(dailyVendor.cd);
      const dailyVendorObject = {
        itemCode: dailyVendor.itemCode,
        itemCodeDes: `${dailyVendor.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        vendorName: dailyVendor.vendorName,
        successRate: dailyVendor.successRate,
      };
      tempDailyVendor.push(dailyVendorObject);
    });
    // viewFG &&
    setState({ ...state, rows: tempDailyVendor });
  }, [getDailyVendorPerformanceReducer]);

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "itemDesc",
      key: "itemDesc",
    },
    { label: "categoryCode", key: "categoryCode" },
    { label: "controlType", key: "controlType" },
    { label: "qty", key: "qty" },
  ];

  const csvLink = {
    filename: "Item Status Report.csv",
    headers: headers,
    data: rows,
  };

  const [selectedVendorCode, setSelectedVendorCode] = useState("");
  const [vendorCodeOptions, setVendorCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = vendorsListForPlannerReducer.vendorsListForPlannerData.data
      ? vendorsListForPlannerReducer.vendorsListForPlannerData.data
      : [];

    data.map((item) => {
      tempItemCodeOptions.push({
        label: `${item.vendorName}`,
        value: item.vendorCode,
        // uom: item.uom,
      });
    });
    setVendorCodeOptions(tempItemCodeOptions);
  }, [vendorsListForPlannerReducer]);
  const handleVendorCodeChange = (selectedItemCode) => {
    setSelectedVendorCode(selectedItemCode);
  };

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };
  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemCode,
        uom: item.uom,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const handleDateRangeChange = (selected) => {
    setSelectedDateRange(selected);
  };

  const [dateRangeOptions, setDateRangeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getDateRangeReducer.dateRange.data
      ? getDateRangeReducer.dateRange.data
      : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        label: item.value,
        value: item.value,
      });
    });
    setDateRangeOptions(tempItemCodeOptions);
  }, [getDateRangeReducer]);

  const handleSuccessRateChange = (selectedOption) => {
    setSelectedSuccessRate(selectedOption);
  };
  const successRateOptions = [
    { label: "0-25", value: "0-25" },
    { label: "25-50,", value: "25-50," },
    { label: "50-75", value: "50-75" },
    { label: "75-100", value: "75-100" },
  ];

  useEffect(() => {
    getDailyVendorPerformance(
      selectedVendorCode ? selectedVendorCode.value : "",
      selectedItemCode ? selectedItemCode.value : "",

      selectedSuccessRate ? selectedSuccessRate.value : "",
      selectedDateRange ? selectedDateRange.value : "",
      jwtToken
    );
  }, [
    selectedVendorCode,
    selectedSuccessRate,
    selectedDateRange,
    selectedItemCode,
  ]);

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-3}
              mt={-4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", lg: "row" }}
                    mt={2}
                  >
                    {/* <MDBox
                      display="flex"
                      flexDirection="row"
                      width={{ xs: "auto", lg: "400px" }} // Adjust width as needed
                      style={{ marginRight: "5px" }}
                    >
                      <div>
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Start Date
                        </MDTypography>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date"
                          maxDate={new Date()}
                          onChange={(date) => setStartDate(date)}
                          isClearable={true}
                          withPortal
                          selectsStart
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      <div style={{ marginLeft: "4px" }}>
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          End Date
                        </MDTypography>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="End Date"
                          maxDate={new Date()}
                          onChange={(date) => setEndDate(date)}
                          isClearable={true}
                          withPortal
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                    </MDBox> */}

                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Item Code
                      </MDTypography>
                      <Select
                        isClearable
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={itemCodeOptions}
                        value={selectedItemCode}
                        onChange={handleItemCodeChange}
                      />
                    </MDBox>

                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Vendor
                      </MDTypography>
                      <Select
                        isClearable
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={vendorCodeOptions}
                        value={selectedVendorCode}
                        onChange={handleVendorCodeChange}
                      />
                    </MDBox>
                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Success Rate
                      </MDTypography>
                      <Select
                        isClearable
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={successRateOptions}
                        value={selectedSuccessRate}
                        onChange={handleSuccessRateChange}
                      />
                    </MDBox>
                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Date Range
                      </MDTypography>
                      <Select
                        isClearable
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={dateRangeOptions}
                        value={selectedDateRange}
                        onChange={handleDateRangeChange}
                      />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
    // </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
    getDateRangeReducer: state.getDateRangeReducer,
    getLocationsReducer: state.getLocationsReducer,
    getDailyVendorPerformanceReducer: state.getDailyVendorPerformanceReducer,
    vendorsListForPlannerReducer: state.vendorsListForPlannerReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getVendorsListForPlanner: (jwtToken) =>
      dispatch(getVendorsListForPlanner(jwtToken)),
    getItemTypesForStatus: (jwtToken) =>
      dispatch(getItemTypesForStatus(jwtToken)),
    getDateRange: (jwtToken) => dispatch(getDateRange(jwtToken)),
    getDailyVendorPerformance: (
      selectedVendorCode,
      selectedItemCode,
      selectedSuccessRate,
      selectedDateRange,
      jwtToken
    ) =>
      dispatch(
        getDailyVendorPerformance(
          selectedVendorCode,
          selectedItemCode,
          selectedSuccessRate,
          selectedDateRange,
          jwtToken
        )
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Daily);
