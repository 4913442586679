/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
// Formik and Yup
import { useFormik } from "formik";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

//  some date function

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getSubModulesByRoleId,
  getRejections,
  rejectionBySuid,
  acceptedBySuid,
} from "../../../store";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import { addMinutes, format } from "date-fns";
import { Backdrop } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const Rejections = ({
  acceptedBySuid,
  rejectionBySuid,
  rejectionReducer,
  getRejections,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let rejectionsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "13c26613-8372-4cb1-9dcf-da1388c0a4b5"
  );

  let viewRejections = rejectionsModule
    ? rejectionsModule.actionId.includes("8064e6f3-36e4-4b16-8500-3e2e79258352")
    : null;
  let acceptRejectionsAccess = rejectionsModule
    ? rejectionsModule.actionId.includes("53fffb21-5b90-4574-853d-9d49776889bd")
    : null;
  let rejectionsAccess = rejectionsModule
    ? rejectionsModule.actionId.includes("e02c986a-7bc6-407b-a39b-dacbb2b54a98")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const navigate = useNavigate();

  useEffect(() => {
    getRejections(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "SUID", accessor: "sku" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Uom", accessor: "uom" },
      { Header: "LOT No", accessor: "lotNo" },
      { Header: "Serial No", accessor: "SerialNo" },
      { Header: "Reason", accessor: "reason" },
      { Header: "Location", accessor: "locationCode" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempAsset = [];
    let data = rejectionReducer.rejections.data
      ? rejectionReducer.rejections.data
      : [];
    setPaginationObj(rejectionReducer?.rejections?.pagination);
    setLoading(rejectionReducer.loading);
    data.map((reject) => {
      const formatDate = new Date(reject.cd);

      let des = reject?.itemDescription;
      let len = desLength;
      const rejectObject = {
        privateKey: reject.suid,
        sku: reject.suid,
        itemCodeDes: `${reject.itemCode} / ${des ? des.substring(0, len) : ""}`,
        itemDescription: `${des ? des.substring(0, len) : ""}`,
        itemCode: reject.itemCode,
        itemId: reject.itemId,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: `${reject.qty}`,
        uom: `${reject.uom} `,
        // lotSerialNo: `${reject.lotNumber ? reject.lotNumber : "NA"} / ${
        //   reject.serialNumber ? reject.serialNumber : "NA"
        // }`,
        lotNo: reject.lotNumber ? reject.lotNumber : "NA",
        SerialNo: reject.serialNumber ? reject.serialNumber : "NA",
        suid: reject.suid,
        locationCode: reject.locationCode,
        reason: reject.rejectionReason,
      };
      tempAsset.push(rejectObject);
    });

    viewRejections && setState({ ...state, rows: tempAsset });
  }, [rejectionReducer]);

  const [buttonName, setButtonName] = useState("Submit");
  const [denyButtonName, setDenyButtonName] = useState("Deny");

  const [openCreateRejectionsModal, setOpenCreateRejectionsModal] =
    useState(false);

  const handleOpenCreateRejectionsModal = (data = {}) => {
    setOpenCreateRejectionsModal(true);
  };

  const handleCloseCreateRejectionsModal = () => {
    setOpenCreateRejectionsModal(false);
  };

  const createRejectionsHandler = (event, data) => {
    handleOpenCreateRejectionsModal(data);
  };
  const [uncheck, setUncheck] = useState(false);

  const [isErrorMsgForRejection, setIsErrorMsgForRejection] = useState(false);
  const [selectedCreateRejectionsData, setSelectedCreateRejectionsData] =
    useState([]);
  const [isErrorForRejections, setIsErrorRejections] = useState(false);
  const [isSuccessForRejections, setIsSuccessRejections] = useState(false);

  const createRejections = (data) => {
    setSelectedCreateRejectionsData(data);
  };
  const onClickCreateRejectionsList = async () => {
    setDenyButtonName("Please wait...");
    let tempArray = [];
    selectedCreateRejectionsData.map((data) => {
      const tempPayload = {
        suid: data.suid,
      };
      tempArray.push(tempPayload);
    });

    let payload = {
      suids: tempArray,
    };
    let res = await rejectionBySuid(payload, jwtToken);
    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessRejections(true);
      getRejections(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        setIsSuccessRejections(false);
      }, 2250);
      setTimeout(() => {
        handleCloseCreateRejectionsModal();
        setDenyButtonName("Deny");
      }, 3000);
    }
    if (!res.status) {
      setIsErrorRejections(true);
      setTimeout(() => {
        setIsErrorRejections(false);
      }, 3000);
      setIsErrorMsgForRejection(res?.data?.data?.msg);
      setDenyButtonName("Deny");
    }
  };

  const [isErrorForAllocate, setIsErrorForAllocate] = useState(false);
  const [isSuccessForAllocate, setIsSuccessForAllocate] = useState(false);
  const [errorMsgForAccept, setErrorMsgForAccept] = useState("");

  const submitForAccept = async (values) => {
    setButtonName("Please wait...");
    let tempArray = [];
    selectedCreateRejectionsData.map((data) => {
      const quantity = parseFloat(data.qty);
      const tempPayload = {
        suid: data.suid,
        qty: quantity,
        itemId: data.itemId,
      };
      tempArray.push(tempPayload);
    });

    let payload = {
      documentNumber: values.documentNumber.trim(),
      suids: tempArray,
    };

    let res = await acceptedBySuid(payload, jwtToken);
    if (res.status) {
      setIsSuccessForAllocate(true);
      getRejections(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setIsSuccessForAllocate(false);
      }, 3000);
      setTimeout(() => {
        handleCloseAcceptModal();
        setButtonName("Submit");
      }, 3000);
    }
    if (!res.status) {
      setErrorMsgForAccept(res?.data?.data?.msg);
      setIsErrorForAllocate(true);
      setTimeout(() => {
        setIsErrorForAllocate(false);
      }, 3000);
      setButtonName("Submit");
    }
  };

  const initialValuesForAccept = {
    documentNo: "",
  };
  const formikForAccept = useFormik({
    initialValues: initialValuesForAccept,
    onSubmit: submitForAccept,
  });

  const [acceptModal, setAcceptModal] = useState(false);

  const handleOpenAcceptModal = () => {
    setAcceptModal(true);
    formikForAccept.setValues(initialValuesForAccept);
    formikForAccept.setTouched({});
    formikForAccept.setErrors({});
  };
  const handleCloseAcceptModal = () => {
    setAcceptModal(false);
  };
  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            ></MDBox>
            <MDBox mt={-1}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                selection={true}
                uncheckProp={uncheck}
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
                selectionButtonClick={createRejections}
                selectionButtonComponent={
                  <>
                    {rejectionsAccess && (
                      <Tooltip title="Deny">
                        <MDButton
                          color="error"
                          type="button"
                          style={{
                            marginTop: "0.5rem",
                            marginLeft: "1rem",
                            marginBottom: "0.8rem",
                          }}
                          onClick={(evt, data) =>
                            createRejectionsHandler(evt, data)
                          }
                        >
                          Deny
                        </MDButton>
                      </Tooltip>
                    )}
                    {acceptRejectionsAccess && (
                      <Tooltip title="Confirm">
                        <MDButton
                          color="success"
                          type="button"
                          style={{
                            marginTop: "0rem",

                            marginLeft: "1rem",
                          }}
                          onClick={(evt, data) =>
                            handleOpenAcceptModal(evt, data)
                          }
                        >
                          Confirm
                        </MDButton>
                      </Tooltip>
                    )}
                  </>
                }
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                hideColumns={["", "asnCode", "vendorCodeName"]}
              />
            </MDBox>
            <Modal
              open={openCreateRejectionsModal}
              onClose={handleCloseCreateRejectionsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDTypography>Are you sure you want to Deny?</MDTypography>
                <MDBox mt={2}>
                  <MDButton
                    style={{ width: "10rem", marginRight: "0.5rem" }}
                    color="info"
                    variant="outlined"
                    onClick={handleCloseCreateRejectionsModal}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    style={{ width: "10rem", marginLeft: "0.5rem" }}
                    color="error"
                    onClick={onClickCreateRejectionsList}
                    disabled={denyButtonName === "Please wait..."}
                  >
                    {denyButtonName}
                  </MDButton>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessForRejections}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessRejections(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Denied successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorForRejections}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorRejections(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {isErrorMsgForRejection}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={acceptModal}
              onClose={handleCloseAcceptModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Confirm
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAcceptModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForAccept.handleSubmit}
                  >
                    <MDBox mb={1}>
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Document No
                      </MDTypography>
                      <MDInput
                        type="text"
                        name="documentNumber"
                        fullWidth
                        autoComplete="off"
                        onBlur={formikForAccept.handleBlur}
                        onChange={formikForAccept.handleChange}
                        value={formikForAccept.values.documentNumber}
                        error={
                          formikForAccept.touched.documentNumber &&
                          formikForAccept.errors.documentNumber &&
                          true
                        }
                      />
                      {formikForAccept.touched.documentNumber &&
                        formikForAccept.errors.documentNumber && (
                          <TextError
                            msg={formikForAccept.errors.documentNumber}
                          />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForAllocate}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForAllocate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForAccept}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForAllocate}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForAllocate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Confirmed Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>

                    <MDBox mt={3}>
                      <MDButton
                        color="success"
                        fullWidth
                        type="submit"
                        disabled={
                          !formikForAccept.isValid ||
                          buttonName === "Please wait..."
                        }
                      >
                        {buttonName}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    rejectionReducer: state.rejectionReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    acceptedBySuid: (payload, jwtToken) =>
      dispatch(acceptedBySuid(payload, jwtToken)),
    rejectionBySuid: (payload, jwtToken) =>
      dispatch(rejectionBySuid(payload, jwtToken)),
    getRejections: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getRejections(pageNo, rowsPerPage, searchObject, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Rejections);
