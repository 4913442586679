import {
  GET_CREATEMR_REQUEST,
  GET_CREATEMR_SUCCESS,
  GET_CREATEMR_FAILURE,
  GET_MATERIALREQUEST_REQUEST,
  GET_MATERIALREQUEST_SUCCESS,
  GET_MATERIALREQUEST_FAILURE,
  GET_MATERIALREQUESTUSERS_REQUEST,
  GET_MATERIALREQUESTUSERS_SUCCESS,
  GET_MATERIALREQUESTUSERS_FAILURE,
  GET_MATERIALREQUEST_CCUSAGE_REQUEST,
  GET_MATERIALREQUEST_CCUSAGE_SUCCESS,
  GET_MATERIALREQUEST_CCUSAGE_FAILURE,
  GET_MATERIALREQUEST_ACUSAGE_REQUEST,
  GET_MATERIALREQUEST_ACUSAGE_SUCCESS,
  GET_MATERIALREQUEST_ACUSAGE_FAILURE,
  GET_APPROVED_REQUESTS_REQUEST,
  GET_APPROVED_REQUESTS_SUCCESS,
  GET_APPROVED_REQUESTS_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_DOWNLOAD_APPROVED_REQUEST,
  GET_DOWNLOAD_APPROVED_SUCCESS,
  GET_DOWNLOAD_APPROVED_FAILURE,
  POST_SKU_REQUEST,
  POST_SKU_SUCCESS,
  POST_SKU_FAILURE,
  CREATE_DISAPPROVAL_REQUEST,
  CREATE_DISAPPROVAL_SUCCESS,
  CREATE_DISAPPROVAL_FAILURE,
  GET_APPROVED_MATERIAL_REQUEST,
  GET_APPROVED_MATERIAL_SUCCESS,
  GET_APPROVED_MATERIAL_FAILURE,
  GET_MATERIALREQUEST_BY_DOC_REQUEST,
  GET_MATERIALREQUEST_BY_DOC_SUCCESS,
  GET_MATERIALREQUEST_BY_DOC_FAILURE,
} from "./materialRequestTypes";

const initialStateForCreateMR = {
  loading: false,
  createMR: [],
  error: "",
};

const createMRReducer = (state = initialStateForCreateMR, action) => {
  switch (action.type) {
    case GET_CREATEMR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CREATEMR_SUCCESS:
      return {
        loading: false,
        createMR: action.payload,
        error: "",
      };
    case GET_CREATEMR_FAILURE:
      return {
        loading: false,
        createMR: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// const initialStateForCreatePickupListPutRequest = {
//   loading: false,
//   createPickupList: [],
//   error: "",
// };

// const createPickupListPutRequestReducer = (
//   state = initialStateForCreatePickupListPutRequest,
//   action
// ) => {
//   switch (action.type) {
//     case CREATEPICKUPLIST_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATEPICKUPLIST_SUCCESS:
//       return {
//         loading: false,
//         createPickupList: action.payload,
//         error: "",
//       };
//     case CREATEPICKUPLIST_FAILURE:
//       return {
//         loading: false,
//         createPickupList: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const initialStateForMaterialRequest = {
  loading: false,
  materialRequest: [],
  error: "",
};

const materialRequestReducer = (
  state = initialStateForMaterialRequest,
  action
) => {
  switch (action.type) {
    case GET_MATERIALREQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIALREQUEST_SUCCESS:
      return {
        loading: false,
        materialRequest: action.payload,
        error: "",
      };
    case GET_MATERIALREQUEST_FAILURE:
      return {
        loading: false,
        materialRequest: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForMaterialRequestUsers = {
  loading: false,
  materialRequestUsers: [],
  error: "",
};

const materialRequestUsersReducer = (
  state = initialStateForMaterialRequestUsers,
  action
) => {
  switch (action.type) {
    case GET_MATERIALREQUESTUSERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIALREQUESTUSERS_SUCCESS:
      return {
        loading: false,
        materialRequestUsers: action.payload,
        error: "",
      };
    case GET_MATERIALREQUESTUSERS_FAILURE:
      return {
        loading: false,
        materialRequestUsers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForMaterialRequestCCUsage = {
  loading: false,
  materialRequestCCUsage: [],
  error: "",
};

const materialRequestCCUsageReducer = (
  state = initialStateForMaterialRequestCCUsage,
  action
) => {
  switch (action.type) {
    case GET_MATERIALREQUEST_CCUSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIALREQUEST_CCUSAGE_SUCCESS:
      return {
        loading: false,
        materialRequestCCUsage: action.payload,
        error: "",
      };
    case GET_MATERIALREQUEST_CCUSAGE_FAILURE:
      return {
        loading: false,
        materialRequestCCUsage: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForMaterialRequestACUsage = {
  loading: false,
  materialRequestACUsage: [],
  error: "",
};

const materialRequestACUsageReducer = (
  state = initialStateForMaterialRequestACUsage,
  action
) => {
  switch (action.type) {
    case GET_MATERIALREQUEST_ACUSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIALREQUEST_ACUSAGE_SUCCESS:
      return {
        loading: false,
        materialRequestACUsage: action.payload,
        error: "",
      };
    case GET_MATERIALREQUEST_ACUSAGE_FAILURE:
      return {
        loading: false,
        materialRequestACUsage: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// const initialStateForPickupItem = {
//   loading: false,
//   pickupItem: [],
//   error: "",
// };

// const getPickupItemReducer = (state = initialStateForPickupItem, action) => {
//   switch (action.type) {
//     case GET_PICKUPITEM_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_PICKUPITEM_SUCCESS:
//       return {
//         loading: false,
//         pickupItem: action.payload,
//         error: "",
//       };
//     case GET_PICKUPITEM_FAILURE:
//       return {
//         loading: false,
//         pickupItem: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const initialStateForApprovedRequests = {
  loading: false,
  approvedRequests: [],
  error: "",
};

const approvedRequestsReducer = (
  state = initialStateForApprovedRequests,
  action
) => {
  switch (action.type) {
    case GET_APPROVED_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_APPROVED_REQUESTS_SUCCESS:
      return {
        loading: false,
        approvedRequests: action.payload,
        error: "",
      };
    case GET_APPROVED_REQUESTS_FAILURE:
      return {
        loading: false,
        approvedRequests: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForOrderDetails = {
  loading: false,
  orderDetails: [],
  error: "",
};

const orderDetailsReducer = (state = initialStateForOrderDetails, action) => {
  switch (action.type) {
    case GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        orderDetails: action.payload,
        error: "",
      };
    case GET_ORDER_DETAILS_FAILURE:
      return {
        loading: false,
        orderDetails: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDownload = {
  loading: false,
  approvedDownloadData: [],
  error: "",
};

const getDownloadApprovedDataReducer = (
  state = initialStateForDownload,
  action
) => {
  switch (action.type) {
    case GET_DOWNLOAD_APPROVED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DOWNLOAD_APPROVED_SUCCESS:
      return {
        loading: false,
        approvedDownloadData: action.payload,
        error: "",
      };
    case GET_DOWNLOAD_APPROVED_FAILURE:
      return {
        loading: false,
        approvedDownloadData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SKU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_SKU_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case POST_SKU_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateApprove = {
  loading: false,
  approvedRequests: [],
  error: "",
};

const getApproveMaterialRequestReducer = (state = initialStateApprove, action) => {
  switch (action.type) {
    case GET_APPROVED_MATERIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_APPROVED_MATERIAL_SUCCESS:
      return {
        loading: false,
        approvedRequests: action.payload,
        error: "",
      };
    case GET_APPROVED_MATERIAL_FAILURE:
      return {
        loading: false,
        approvedRequests: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateSuidApprove = {
  loading: false,
  approvedRequests: [],
  error: "",
};

const getSuidsForApproveMaterialRequestReducer = (state = initialStateSuidApprove, action) => {
  switch (action.type) {
    case GET_MATERIALREQUEST_BY_DOC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MATERIALREQUEST_BY_DOC_SUCCESS:
      return {
        loading: false,
        approvedRequests: action.payload,
        error: "",
      };
    case GET_MATERIALREQUEST_BY_DOC_FAILURE:
      return {
        loading: false,
        approvedRequests: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreateDisapproval = {
  loading: false,
  disapproval: [],
  error: "",
};

const createDisapprovalReducer = (
  state = initialStateForCreateDisapproval,
  action
) => {
  switch (action.type) {
    case CREATE_DISAPPROVAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DISAPPROVAL_SUCCESS:
      return {
        loading: false,
        disapproval: action.payload,
        error: "",
      };
    case CREATE_DISAPPROVAL_FAILURE:
      return {
        loading: false,
        disapproval: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  materialRequestUsersReducer,
  materialRequestReducer,
  materialRequestCCUsageReducer,
  materialRequestACUsageReducer,
  createMRReducer,
  approvedRequestsReducer,
  orderDetailsReducer,
  getDownloadApprovedDataReducer,
  getSkuReducer,
  createDisapprovalReducer,
  getApproveMaterialRequestReducer,
  getSuidsForApproveMaterialRequestReducer
};
