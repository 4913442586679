export const GET_ASSEMBLY_MASTER_REQUEST = "GET_ASSEMBLY_MASTER_REQUEST";
export const GET_ASSEMBLY_MASTER_SUCCESS = "GET_ASSEMBLY_MASTER_SUCCESS";
export const GET_ASSEMBLY_MASTER_FAILURE = "GET_ASSEMBLY_MASTER_FAILURE";

export const GET_ASSEMBLY_TYPES_REQUEST = "GET_ASSEMBLY_TYPES_REQUEST";
export const GET_ASSEMBLY_TYPES_SUCCESS = "GET_ASSEMBLY_TYPES_SUCCESS";
export const GET_ASSEMBLY_TYPES_FAILURE = "GET_ASSEMBLY_TYPES_FAILURE";

export const GET_UNMAPPED_STATIONS_REQUEST = "GET_UNMAPPED_STATIONS_REQUEST";
export const GET_UNMAPPED_STATIONS_SUCCESS = "GET_UNMAPPED_STATIONS_SUCCESS";
export const GET_UNMAPPED_STATIONS_FAILURE = "GET_UNMAPPED_STATIONS_FAILURE";

export const CREATE_ASSEMBLY_REQUEST = "CREATE_ASSEMBLY_REQUEST";
export const CREATE_ASSEMBLY_SUCCESS = "CREATE_ASSEMBLY_SUCCESS";
export const CREATE_ASSEMBLY_FAILURE = "CREATE_ASSEMBLY_FAILURE";

export const MAP_ASSEMBLY_REQUEST = "MAP_ASSEMBLY_REQUEST";
export const MAP_ASSEMBLY_SUCCESS = "MAP_ASSEMBLY_SUCCESS";
export const MAP_ASSEMBLY_FAILURE = "MAP_ASSEMBLY_FAILURE";

export const CREATE_ASSEMBLYSTATIONS_REQUEST = "CREATE_ASSEMBLYSTATIONS_REQUEST";
export const CREATE_ASSEMBLYSTATIONS_SUCCESS = "CREATE_ASSEMBLYSTATIONS_SUCCESS";
export const CREATE_ASSEMBLYSTATIONS_FAILURE = "CREATE_ASSEMBLYSTATIONS_FAILURE";

export const DELETE_ASSEMBLY_REQUEST = "DELETE_ASSEMBLY_REQUEST";
export const DELETE_ASSEMBLY_SUCCESS = "DELETE_ASSEMBLY_SUCCESS";
export const DELETE_ASSEMBLY_FAILURE = "DELETE_ASSEMBLY_FAILURE";

export const EDIT_ASSEMBLY_REQUEST = "EDIT_ASSEMBLY_REQUEST";
export const EDIT_ASSEMBLY_SUCCESS = "EDIT_ASSEMBLY_SUCCESS";
export const EDIT_ASSEMBLY_FAILURE = "EDIT_ASSEMBLY_FAILURE";
