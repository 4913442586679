/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import DatePicker from "react-datepicker";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getItemMaster,
  getItemStatus,
  getItemTypesForStatus,
  getLocations,
  getMinMaxFailure,
  getSubModulesByRoleId,
} from "../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";
import { getMinMaxFailureReducer } from "store/reports/min-max-failure/minMaxFailureReducers";
import zIndex from "@mui/material/styles/zIndex";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const MinMaxFailure = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getItemMaster,
  getItemTypesForStatus,
  itemMaster,

  getItemStatusReducer,
  getLocations,
  getLocationsReducer,
  getMinMaxFailure,
  getMinMaxFailureReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  const [selectedItemType, setSelectedItemType] = useState("");
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getItemMaster(jwtToken);
  }, []);

  useEffect(() => {
    getItemTypesForStatus(jwtToken);
  }, []);

  useEffect(() => {
    getLocations(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemStatusReportModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "95815117-55fd-40be-ab8f-a9407d9ae1df"
  );

  let viewItemStatus = itemStatusReportModule
    ? itemStatusReportModule.actionId.includes(
        "e7b51078-d27b-4bf2-9707-efa327848bad"
      )
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Transaction Code", accessor: "trCode" },
      { Header: "QTY", accessor: "qty" },
      { Header: "Picklist Qty", accessor: "picklistQty" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      { Header: "Created Date", accessor: "createdDate" },
      //   { Header: "Uom", accessor: "uom" },
      //   { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempMinMax = [];
    let data = getMinMaxFailureReducer.minMax.data
      ? getMinMaxFailureReducer.minMax.data
      : [];

    setLoading(getMinMaxFailureReducer.loading);
    data?.map((minMax) => {
      let des = minMax?.itemDescription;
      let len = desLength;
      const formatDate = new Date(minMax.cd);
      const minMaxObject = {
        trCode: minMax.transactionCode,
        itemCodeDes: `${minMax.itemCode} / ${des ? des.substring(0, len) : ""}`,

        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),

        picklistQty: minMax.picklistQty,

        qty: `${minMax.qty}`,
        uom: ` ${minMax.uom}`,
      };
      tempMinMax.push(minMaxObject);
    });
    // viewFG &&
    setState({ ...state, rows: tempMinMax });
  }, [getMinMaxFailureReducer]);

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "itemDesc",
      key: "itemDesc",
    },
    { label: "categoryCode", key: "categoryCode" },
    { label: "controlType", key: "controlType" },
    { label: "qty", key: "qty" },
  ];

  const csvLink = {
    filename: "Item Status Report.csv",
    headers: headers,
    data: rows,
  };

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
    setSelectedLocation("");
  };
  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemCode,
        uom: item.uom,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const handleLocationChange = (selectedItemCode) => {
    setSelectedLocation(selectedItemCode);
  };

  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getLocationsReducer.locations
      ? getLocationsReducer.locations
      : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        label: item.locationName,
        value: item.locationId,
      });
    });
    setLocationOptions(tempItemCodeOptions);
  }, [getLocationsReducer]);

  const handleItemTypeChange = (selectedOption) => {
    setSelectedItemType(selectedOption);
    setSelectedItemCode("");
    setSelectedLocation("");
    getItemTypesForStatus(jwtToken);
  };
  const itemTypeOptions = [
    { label: "Rejection", value: "REJ" },
    { label: "Quarantine", value: "QA" },
    { label: "RTV", value: "RTV" },
    { label: "Scrap", value: "SCRAP" },
  ];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let isoStartDate;
  let isoEndDate;
  useEffect(() => {
    if (startDate || endDate) {
      isoStartDate = new Date(
        (startDate ? startDate : new Date()).getTime() -
          (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
      ).toISOString();

      const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const formattedStartDate = formatDate(new Date(isoStartDate));

      isoEndDate = new Date(
        (endDate ? endDate : new Date()).getTime() -
          (endDate ? endDate : new Date()).getTimezoneOffset() * 60000
      ).toISOString();

      const formattedEndDate = formatDate(new Date(isoEndDate));
    }
    // Now you can use both formattedStartDate and formattedEndDate
    getMinMaxFailure(
      isoStartDate ? isoStartDate : "",
      isoEndDate ? isoEndDate : "",
      selectedItemCode?.value ? selectedItemCode?.value : "",
      jwtToken
    );
  }, [startDate, endDate, selectedItemCode]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-4}
              >
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} lg={8}>
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", lg: "row" }}
                      mt={2}
                    >
                      <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Date Range
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                        //   options={itemTypeOptions}
                          value={selectedItemType}
                          onChange={handleItemTypeChange}
                        />
                      </MDBox>

                      <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Item Code
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                        //   options={itemCodeOptions}
                          value={selectedItemCode}
                          onChange={handleItemCodeChange}
                        />
                      </MDBox>

                      <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Picklist Qty
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                        //   options={locationOptions}
                          value={selectedLocation}
                          onChange={handleLocationChange}
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        marginLeft={1}
                        marginTop={{ xs: 2, lg: 3 }}
                      >
                        <CSVLink {...csvLink}>
                          <DownloadDataButton
                            style={{ marginLeft: "5px" }}
                            type="button"
                            variant="outlined"
                            color="success"
                            iconOnly
                            tooltip="Download All Data"
                          >
                            <Icon>download</Icon>
                          </DownloadDataButton>
                        </CSVLink>
                      </MDBox>
                    </MDBox>
                  </Grid> */}
                  <Grid item xs={12} lg={8}>
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", lg: "row" }}
                      mt={2}
                    >
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        width={{ xs: "auto", lg: "400px" }} // Adjust width as needed
                        style={{ marginRight: "5px" }}
                      >
                        <div>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Start Date
                          </MDTypography>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Start Date"
                            maxDate={new Date()}
                            onChange={(date) => setStartDate(date)}
                            isClearable={true}
                            withPortal
                            selectsStart
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </div>
                        <div style={{ marginLeft: "4px" }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            End Date
                          </MDTypography>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="End Date"
                            maxDate={new Date()}
                            onChange={(date) => setEndDate(date)}
                            isClearable={true}
                            withPortal
                            selectsEnd
                            selected={endDate}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                          />
                        </div>
                      </MDBox>

                      <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Item Code
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                          options={itemCodeOptions}
                          value={selectedItemCode}
                          onChange={handleItemCodeChange}
                        />
                      </MDBox>

                      {/* <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Picklist Qty
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                          value={selectedLocation}
                          onChange={handleLocationChange}
                        />
                      </MDBox> */}
                      {/* <MDBox
        width={{ xs: "125px", lg: "200px" }}
        style={{ marginRight: "5px" }}
      >
        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
          Success Rate
        </MDTypography>
        <Select
          isClearable
          placeholder="Choose One..."
          className="select-css-for-filter"
          maxMenuHeight={130}
          value={selectedLocation}
          onChange={handleLocationChange}
        />
      </MDBox> */}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
    getItemTypeReducer: state.getItemTypeReducer,
    getLocationsReducer: state.getLocationsReducer,
    getItemStatusReducer: state.getItemStatusReducer,
    getMinMaxFailureReducer: state.getMinMaxFailureReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getItemTypesForStatus: (jwtToken) =>
      dispatch(getItemTypesForStatus(jwtToken)),
    getLocations: (jwtToken) => dispatch(getLocations(jwtToken)),
    getItemStatus: (
      selectedItemType,
      selectedItemCode,
      selectedLocation,
      jwtToken
    ) =>
      dispatch(
        getItemStatus(
          selectedItemType,
          selectedItemCode,
          selectedLocation,
          jwtToken
        )
      ),
    getMinMaxFailure: (isoStartDate, isoEndDate, selectedItemCode, jwtToken) =>
      dispatch(
        getMinMaxFailure(isoStartDate, isoEndDate, selectedItemCode, jwtToken)
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(MinMaxFailure);
