import {
  GET_QUARANTINE_REQUEST,
  GET_QUARANTINE_SUCCESS,
  GET_QUARANTINE_FAILURE,
  GET_ACCEPTED_QUARANTINES_REQUEST,
  GET_ACCEPTED_QUARANTINES_SUCCESS,
  GET_ACCEPTED_QUARANTINES_FAILURE,
  PUT_ACCEPT_QUARANTINE_REQUEST,
  PUT_ACCEPT_QUARANTINE_SUCCESS,
  PUT_ACCEPT_QUARANTINE_FAILURE,
  PUT_REJECT_QUARANTINE_REQUEST,
  PUT_REJECT_QUARANTINE_SUCCESS,
  PUT_REJECT_QUARANTINE_FAILURE,
  GET_SKU_BYSKUSTATUS_REQUEST,
  GET_SKU_BYSKUSTATUS_SUCCESS,
  GET_SKU_BYSKUSTATUS_FAILURE,
  GET_ITEMCODE_BYWAREHOUSE_REQUEST,
  GET_ITEMCODE_BYWAREHOUSE_SUCCESS,
  GET_ITEMCODE_BYWAREHOUSE_FAILURE,
  GET_LOCATIONS_FOR_QUARANTINE_REQUEST,
  GET_LOCATIONS_FOR_QUARANTINE_SUCCESS,
  GET_LOCATIONS_FOR_QUARANTINE_FAILURE,
  CREATE_QUARANTINE_REQUEST,
  CREATE_QUARANTINE_SUCCESS,
  CREATE_QUARANTINE_FAILURE,
  GET_SKUSTATUS_BYLOCATION_REQUEST,
  GET_SKUSTATUS_BYLOCATION_SUCCESS,
  GET_SKUSTATUS_BYLOCATION_FAILURE,
  GET_SKU_BY_ITEMCODE_REQUEST,
  GET_SKU_BY_ITEMCODE_SUCCESS,
  GET_SKU_BY_ITEMCODE_FAILURE,
  GET_SKU_BYDOCUMENTNO_REQUEST,
  GET_SKU_BYDOCUMENTNO_SUCCESS,
  GET_SKU_BYDOCUMENTNO_FAILURE,
  PUT_RELEASE_QUARANTINE_REQUEST,
  PUT_RELEASE_QUARANTINE_SUCCESS,
  PUT_RELEASE_QUARANTINE_FAILURE,
  GET_ACN_TYPES_REQUEST,
  GET_ACN_TYPES_SUCCESS,
  GET_ACN_TYPES_FAILURE,
} from "./quarantineTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getQuarantineRequest = () => {
  return {
    type: GET_QUARANTINE_REQUEST,
  };
};

const getQuarantineSuccess = (payload) => {
  return {
    type: GET_QUARANTINE_SUCCESS,
    payload: payload,
  };
};

const getQuarantineFaliure = (error) => {
  return {
    type: GET_QUARANTINE_FAILURE,
    payload: error,
  };
};

const getQuarantine = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getQuarantineRequest());
    let url = `${baseUrl}/quarantine/get?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getQuarantineSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getQuarantineFaliure(err.message));
      });
  };
};
const getAcnTypesRequest = () => {
  return {
    type: GET_ACN_TYPES_REQUEST,
  };
};

const getAcnTypesSuccess = (payload) => {
  return {
    type: GET_ACN_TYPES_SUCCESS,
    payload: payload,
  };
};

const getAcnTypesFaliure = (error) => {
  return {
    type: GET_ACN_TYPES_FAILURE,
    payload: error,
  };
};

const getAcnTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAcnTypesRequest());
    let url = `${baseUrl}/quarantine/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAcnTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAcnTypesFaliure(err.message));
      });
  };
};

const getLocationsForQuarantineRequest = () => {
  return {
    type: GET_LOCATIONS_FOR_QUARANTINE_REQUEST,
  };
};

const getLocationsForQuarantineSuccess = (payload) => {
  return {
    type: GET_LOCATIONS_FOR_QUARANTINE_SUCCESS,
    payload: payload,
  };
};

const getLocationsForQuarantineFaliure = (error) => {
  return {
    type: GET_LOCATIONS_FOR_QUARANTINE_FAILURE,
    payload: error,
  };
};

const getLocationsForQuarantine = (itemId, status, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsForQuarantineRequest());
    let url = `${baseUrl}/location/get_loc_for_item?itemId=${itemId}&status=${status}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsForQuarantineSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLocationsForQuarantineFaliure(err.message));
      });
  };
};

const getSkuBySkuStatusRequest = () => {
  return {
    type: GET_SKU_BYSKUSTATUS_REQUEST,
  };
};

const getSkuBySkuStatusSuccess = (payload) => {
  return {
    type: GET_SKU_BYSKUSTATUS_SUCCESS,
    payload: payload,
  };
};

const getSkuBySkuStatusFaliure = (error) => {
  return {
    type: GET_SKU_BYSKUSTATUS_FAILURE,
    payload: error,
  };
};

const getSkuBySkuStatus = (locationId, itemId, status, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuBySkuStatusRequest());
    let url = `${baseUrl}/get_suid_by_location_&_item?locationId=1534&itemId=4&status=0`;
    // let url = `${baseUrl}/get_suid_by_location_&_item?locationId=${locationId}&itemId=${itemId}&status=${status}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSkuBySkuStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSkuBySkuStatusFaliure(err.message));
      });
  };
};
const getSkuStatusByLocationAndItemCodeRequest = () => {
  return {
    type: GET_SKUSTATUS_BYLOCATION_REQUEST,
  };
};

const getSkuStatusByLocationAndItemCodeSuccess = (payload) => {
  return {
    type: GET_SKUSTATUS_BYLOCATION_SUCCESS,
    payload: payload,
  };
};

const getSkuStatusByLocationAndItemCodeFaliure = (error) => {
  return {
    type: GET_SKUSTATUS_BYLOCATION_FAILURE,
    payload: error,
  };
};

const getSkuStatusByLocationAndItemCode = (
  locationId,
  itemId,
  status,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuStatusByLocationAndItemCodeRequest());
    let url = `${baseUrl}/get_suid_by_location_&_item?locationId=${locationId}&itemId=${itemId}&status=${status}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSkuStatusByLocationAndItemCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSkuStatusByLocationAndItemCodeFaliure(err.message));
      });
  };
};
const getSkuByItemCodeRequest = () => {
  return {
    type: GET_SKU_BY_ITEMCODE_REQUEST,
  };
};

const getSkuByItemCodeSuccess = (payload) => {
  return {
    type: GET_SKU_BY_ITEMCODE_SUCCESS,
    payload: payload,
  };
};

const getSkuByItemCodeFaliure = (error) => {
  return {
    type: GET_SKU_BY_ITEMCODE_FAILURE,
    payload: error,
  };
};

const getSkuByItemCode = (itemId, status, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuByItemCodeRequest());
    // let url = `${baseUrl}/get_suid_by_location_&_item?locationId=${locationId}&itemId=${itemId}&status=${status}`;
    let url = `${baseUrl}/get_suid_by_location_&_item?itemId=${itemId}&status=${status}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSkuByItemCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSkuByItemCodeFaliure(err.message));
      });
  };
};

const getAcceptedQuarantinesRequest = () => {
  return {
    type: GET_ACCEPTED_QUARANTINES_REQUEST,
  };
};

const getAcceptedQuarantinesSuccess = (payload) => {
  return {
    type: GET_ACCEPTED_QUARANTINES_SUCCESS,
    payload: payload,
  };
};

const getAcceptedQuarantinesFaliure = (error) => {
  return {
    type: GET_ACCEPTED_QUARANTINES_FAILURE,
    payload: error,
  };
};

const getAcceptedQuarantines = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAcceptedQuarantinesRequest());
    let url = `${baseUrl}/quarantine/get_quarantined_suids_grouped?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAcceptedQuarantinesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAcceptedQuarantinesFaliure(err.message));
      });
  };
};

const acceptQuarantineRequest = () => {
  return {
    type: PUT_ACCEPT_QUARANTINE_REQUEST,
  };
};

const acceptQuarantineSuccess = (payload) => {
  return {
    type: PUT_ACCEPT_QUARANTINE_SUCCESS,
    payload: payload,
  };
};

const acceptQuarantineFailure = (error) => {
  return {
    type: PUT_ACCEPT_QUARANTINE_FAILURE,
    payload: error,
  };
};

const acceptQuarantine = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(acceptQuarantineRequest());
    let url = `${baseUrl}/quarantine/accept`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(acceptQuarantineSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(acceptQuarantineFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const releaseQuarantineRequest = () => {
  return {
    type: PUT_RELEASE_QUARANTINE_REQUEST,
  };
};

const releaseQuarantineSuccess = (payload) => {
  return {
    type: PUT_RELEASE_QUARANTINE_SUCCESS,
    payload: payload,
  };
};

const releaseQuarantineFailure = (error) => {
  return {
    type: PUT_RELEASE_QUARANTINE_FAILURE,
    payload: error,
  };
};

const releaseQuarantine = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(releaseQuarantineRequest());
    let url = `${baseUrl}/quarantine/picklist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(releaseQuarantineSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(releaseQuarantineFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const rejectQuarantineRequest = () => {
  return {
    type: PUT_REJECT_QUARANTINE_REQUEST,
  };
};

const rejectQuarantineSuccess = (payload) => {
  return {
    type: PUT_REJECT_QUARANTINE_SUCCESS,
    payload: payload,
  };
};

const rejectQuarantineFailure = (error) => {
  return {
    type: PUT_REJECT_QUARANTINE_FAILURE,
    payload: error,
  };
};

const rejectQuarantine = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(rejectQuarantineRequest());
    let url = `${baseUrl}/quarantine/reject`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(rejectQuarantineSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(rejectQuarantineFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getItemCodeByWarehouseRequest = () => {
  return {
    type: GET_ITEMCODE_BYWAREHOUSE_REQUEST,
  };
};

const getItemCodeByWarehouseSuccess = (payload) => {
  return {
    type: GET_ITEMCODE_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getItemCodeByWarehouseFailure = (error) => {
  return {
    type: GET_ITEMCODE_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getItemCodeByWarehouse = (warehouseIdSelected, status, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemCodeByWarehouseRequest());
    // let url = `${baseUrl}/item/get_by_wh_code?warehouseCode=${warehouseIdSelected}`;
    let url = `${baseUrl}/item/get_by_wh_code?warehouseCode=${warehouseIdSelected}&status=${status}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemCodeByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemCodeByWarehouseFailure(err.message));
      });
  };
};
const getSkuByDocumentNoRequest = () => {
  return {
    type: GET_SKU_BYDOCUMENTNO_REQUEST,
  };
};

const getSkuByDocumentNoSuccess = (payload) => {
  return {
    type: GET_SKU_BYDOCUMENTNO_SUCCESS,
    payload: payload,
  };
};

const getSkuByDocumentNoFailure = (error) => {
  return {
    type: GET_SKU_BYDOCUMENTNO_FAILURE,
    payload: error,
  };
};

const getSkuByDocumentNo = (documentTrId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuByDocumentNoRequest());
    let url = `${baseUrl}/quarantine/get_quarantined_suids_by_doc_no?doc_id=${documentTrId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSkuByDocumentNoSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSkuByDocumentNoFailure(err.message));
      });
  };
};
const createQuarantineForAcceptedListRequest = () => {
  return {
    type: CREATE_QUARANTINE_REQUEST,
  };
};

const createQuarantineForAcceptedListSuccess = (payload) => {
  return {
    type: CREATE_QUARANTINE_SUCCESS,
    payload: payload,
  };
};

const createQuarantineForAcceptedListFailure = (error) => {
  return {
    type: CREATE_QUARANTINE_FAILURE,
    payload: error,
  };
};

const createQuarantineForAcceptedList = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createQuarantineForAcceptedListRequest());
    let url = `${baseUrl}/quarantine/direct_quarantine`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createQuarantineForAcceptedListSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createQuarantineForAcceptedListFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getQuarantine,
  getAcceptedQuarantines,
  acceptQuarantine,
  rejectQuarantine,
  getItemCodeByWarehouse,
  getLocationsForQuarantine,
  getSkuBySkuStatus,
  getSkuStatusByLocationAndItemCode,
  createQuarantineForAcceptedList,
  getSkuByItemCode,
  getSkuByDocumentNo,
  releaseQuarantine,
  getAcnTypes,
};
