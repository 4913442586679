/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Select component
import Select from "react-select";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getAllocatedActivity,
  getCreatedActivity,
  createActivity,
  getDocumentType,
  getSubModulesByRoleId,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";
import { Backdrop, CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const CreatedActivity = ({
  getAllocatedActivity,
  getAllocatedActivityReducer,
  getCreatedActivity,
  getCreatedActivityReducer,
  createActivity,
  getDocumentType,
  getDocumentTypeReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let activityAllocationModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "9be44ebe-2e3e-40dd-8d1d-2facc8906fa3"
  );

  let viewAccess = activityAllocationModule
    ? activityAllocationModule.actionId.includes(
        "10334b17-8378-4a36-9c36-18ed244c97d1"
      )
    : null;
  let allocateMovement = activityAllocationModule
    ? activityAllocationModule.actionId.includes(
        "945c185d-7726-449c-9fac-045546720fb6"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "Document Code", accessor: "documentCode" },
      { Header: "Activity Code", accessor: "mvtCode", align: "left" },

      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  useEffect(() => {
    getAllocatedActivity(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempgetAllocatedActivity = [];
    let data = getAllocatedActivityReducer.allocatedActivity.data
      ? getAllocatedActivityReducer.allocatedActivity.data
      : [];
    setPaginationObj(
      getAllocatedActivityReducer?.allocatedActivity?.pagination
    );
    setLoading(getAllocatedActivityReducer.loading);
    data.map((allocatedActivity) => {
      const formatDate = new Date(allocatedActivity.cd);
      const allocatedActivityObject = {
        documentCode: allocatedActivity.documentCode,
        mvtCode: allocatedActivity.mvtCode,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
      };
      tempgetAllocatedActivity.push(allocatedActivityObject);
    });
    viewAccess && setState({ ...state, rows: tempgetAllocatedActivity });
  }, [getAllocatedActivityReducer]);

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");

  const handleDocumentTypeChange = (selectedDocumentType) => {
    setDocumentTypeSelected(selectedDocumentType);
  };

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  const [createdActivitySelected, setCreatedActivitySelected] = useState("");

  const handleCreatedActivityChange = (selectedCreatedActivity) => {
    setCreatedActivitySelected(selectedCreatedActivity);
  };

  const [createdActivityOptions, setCreatedActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtName,
        value: activity.mvtId,
      });
    });
    setCreatedActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const [createActivityModal, setCreateActivityModal] = useState(false);

  const handleCreateActivityModal = () => {
    setCreateActivityModal(true);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
    setDocumentTypeSelected("");
    setCreatedActivitySelected("");
    getCreatedActivity("", jwtToken);
  };
  const handleCreateCloseActivityModal = () => {
    setCreateActivityModal(false);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
    setDocumentTypeSelected("");
    setCreatedActivitySelected("");
    getCreatedActivity("", jwtToken);
  };

  const initialValues = {
    documentId: "",
    mvtId: "",
  };

  const validationSchema = Yup.object({
    documentId: Yup.object().required("Select document type"),
    mvtId: Yup.object().required("Select activity name"),
  });

  const [errorMsg, setErrorMsg] = useState("");

  const [buttonName, setButtonName] = useState("Submit");
  const onSubmit = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const payload = {
      documentId: documentTypeSelected?.value,
      mvtId: createdActivitySelected?.value,
    };

    let res = await createActivity(payload);

    if (res.status) {
      setIsSuccessCreatedActivity(true);
      getAllocatedActivity(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setButtonName("Submit");
        handleCreateCloseActivityModal();
      }, 2250);
    }
    if (!res.status) {
      setErrorMsg(res?.data?.data?.msg);
      setIsErrorCreatedActivity(true);
      setButtonName("Submit");
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [isSuccessCreatedActivity, setIsSuccessCreatedActivity] =
    useState(false);
  const [isErrorCreatedActivity, setIsErrorCreatedActivity] = useState(false);

  useEffect(() => {
    if (isErrorCreatedActivity) {
      setTimeout(() => {
        setIsErrorCreatedActivity(false);
      }, 3000);
    }
  }, [isErrorCreatedActivity]);

  useEffect(() => {
    if (isSuccessCreatedActivity) {
      setTimeout(() => {
        setIsSuccessCreatedActivity(false);
      }, 3000);
    }
  }, [isSuccessCreatedActivity]);

  return (
    <MDBox pt={0} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            mt={-2}
          >
            {allocateMovement && (
              <MDBox
                color="text"
                px={{ xs: 0.5, sm: 0.5, md: 2, lg: 2, xl: 2 }}
                style={{ marginLeft: "-15px" }}
              >
                <CreateButton
                  tooltip="Allocate"
                  // disabled={!allocateMovement}
                  color="info"
                  variant="contained"
                  type="button"
                  iconOnly
                  onClick={(event) => {
                    handleCreateActivityModal();
                    getDocumentType(jwtToken);
                    getCreatedActivity("", jwtToken);
                  }}
                >
                  <Icon>add</Icon>
                </CreateButton>
              </MDBox>
            )}
          </MDBox>
          <MDBox mt={-3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={true}
              tableSearch={true}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 10, 15, 20, 25, 30],
              }}
              showTotalEntries={true}
              noEndBorder
              options={{ draggable: false }}
              serverSidePagination={true}
              paginationObj={paginationObj}
              pageNoValue={pageNo}
              rowsPerPageValue={rowsPerPage}
              pageNoProp={importPageNo}
              rowsPerPageProp={importRowsPerPage}
              searchProp={importSearch}
            />
          </MDBox>
          <Modal
            open={createActivityModal}
            onClose={handleCreateCloseActivityModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                  Allocate
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCreateCloseActivityModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formik.handleSubmit}
                >
                  <MDBox mb={1}>
                    <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                      {" "}
                      Document Type{" "}
                    </MDTypography>

                    <Select
                      isClearable
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Choose one..."
                      name="documentId"
                      onBlur={() => {
                        formik.handleBlur({
                          target: { name: "documentId" },
                        });
                      }}
                      value={documentTypeSelected}
                      options={documentTypeOptions}
                      onChange={(selectedOption) => {
                        handleDocumentTypeChange(selectedOption);
                        formik.setFieldValue("documentId", selectedOption);
                      }}
                    />
                    {formik.touched.documentId && formik.errors.documentId && (
                      <TextError msg={formik.errors.documentId} />
                    )}
                  </MDBox>

                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                      Activity Name
                    </MDTypography>

                    <Select
                      isClearable
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Choose one..."
                      name="mvtId"
                      // styles={customStyles}
                      onBlur={() => {
                        formik.handleBlur({
                          target: { name: "mvtId" },
                        });
                      }}
                      value={createdActivitySelected}
                      options={createdActivityOptions}
                      onChange={(selectedOption) => {
                        handleCreatedActivityChange(selectedOption);
                        formik.setFieldValue("mvtId", selectedOption);
                      }}
                    />

                    {formik.touched.mvtId && formik.errors.mvtId && (
                      <TextError msg={formik.errors.mvtId} />
                    )}
                  </MDBox>
                  <MDBox>
                    <Collapse in={isErrorCreatedActivity}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreatedActivity(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessCreatedActivity}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessCreatedActivity(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Activity allocated successfully
                      </Alert>
                    </Collapse>
                  </MDBox>

                  <MDBox mt={3}>
                    <MDButton
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={
                        !formik.isValid ||
                        !documentTypeSelected ||
                        !createdActivitySelected ||
                        buttonName === "Please wait..."
                      }
                    >
                      {buttonName}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
        </Grid>
      </Grid>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllocatedActivityReducer: state.getAllocatedActivityReducer,
    getCreatedActivityReducer: state.getCreatedActivityReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAllocatedActivity: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getAllocatedActivity(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getCreatedActivity: (documentType, jwtToken) =>
      dispatch(getCreatedActivity(documentType, jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    createActivity: (payload, jwtToken) =>
      dispatch(createActivity(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatedActivity);
