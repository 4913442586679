import { GET_CONSUMPTION_PENDING_FAILURE, GET_CONSUMPTION_PENDING_REQUEST, GET_CONSUMPTION_PENDING_SUCCESS } from "./consumptionPendingTypes";

  const initialState = {
    loading: false,
    consumptionPending: [],
    error: "",
  };
  
  const getConsumptionPendingReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CONSUMPTION_PENDING_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_CONSUMPTION_PENDING_SUCCESS:
        return {
          loading: false,
          consumptionPending: action.payload,
          error: "",
        };
      case GET_CONSUMPTION_PENDING_FAILURE:
        return {
          loading: false,
          consumptionPending: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export { getConsumptionPendingReducer };
  