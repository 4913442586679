/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  createCategory,
  updateCategory,
  deleteCategory,
  getCategoriesWithPagination,
} from "../../store";

// Cookies
import Cookies from "universal-cookie";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import { Backdrop, CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const CategoryCreation = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getCategoriesWithPagination,
  allCategories,
  createCategory,
  updateCategory,
  deleteCategory,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails?.data?.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);
  let categoryCreation = modulesData.find(
    (moduleId) => moduleId.moduleId == "c86bf7ec-3e40-43e0-8175-562142a85e92"
  );
  let createAccess = categoryCreation
    ? categoryCreation.actionId.includes("498118ae-99e2-4acd-82c4-ed4a73ec3177")
    : null;
  let viewAccess = categoryCreation
    ? categoryCreation.actionId.includes("42596585-0012-4ea3-8f84-b1af45a7b5b5")
    : null;
  let editAccess = categoryCreation
    ? categoryCreation.actionId.includes("05220a37-9a82-43b9-a179-7f9e591256ca")
    : null;
  let deleteAccess = categoryCreation
    ? categoryCreation.actionId.includes("c671494f-62df-4fcd-a1c1-b4dc118705ca")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  useEffect(() => {
    getCategoriesWithPagination(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [state, setState] = useState({
    columns: [
      { Header: "Category Code", accessor: "categoryCode" },
      { Header: "Category Description", accessor: "categoryName" },

      { Header: "Created Date", accessor: "createdDate" },
      {
        Header: "action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  const { columns, rows } = state;

  useEffect(() => {
    let tempCategories = [];
    let data = allCategories.categories.data
      ? allCategories.categories.data
      : [];

    setPaginationObj(allCategories?.categories?.pagination);
    setLoading(allCategories.loading);
    data.map((category) => {
      const formatDate = new Date(category.cd);
      const categoryObject = {
        categoryCode: category.categoryCode,
        categoryName: category.categoryName,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        action: category.categoryId !== 1 && (
          <div>
            {editAccess && (
              <EditButton
                tooltip="Edit Category"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateCategoryModal(category)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}

            {deleteAccess && (
              <DeleteButton
                tooltip="Delete Category"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteCategoryModal(category)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </div>
        ),
      };
      viewAccess && tempCategories.push(categoryObject);
    });

    setState({ ...state, rows: tempCategories });
  }, [allCategories]);

  const [isErrorCreateCategory, setIsErrorCreateCategory] = useState(false);
  const [isSuccessCreateCategory, setIsSuccessCreateCategory] = useState(false);

  const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);

  useEffect(() => {
    if (isErrorCreateCategory) {
      setTimeout(() => {
        setIsErrorCreateCategory(false);
      }, 3000);
    }
  }, [isErrorCreateCategory]);

  useEffect(() => {
    if (isSuccessCreateCategory) {
      setTimeout(() => {
        setIsSuccessCreateCategory(false);
      }, 3000);
    }
  }, [isSuccessCreateCategory]);

  const handleOpenCreateCategoryModal = () => {
    setOpenCreateCategoryModal(true);
  };

  const handleCloseCreateCategoryModal = () => {
    setOpenCreateCategoryModal(false);
  };

  const initialValueForCreateCategory = {
    categoryCode: "",
    categoryName: "",
  };

  const validationSchema = Yup.object({
    categoryCode: Yup.string().required("Enter the Category Code"),
    categoryName: Yup.string().required("Enter the Category Description"),
  });

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [buttonName, setButtonName] = useState("Create Category");

  const onSubmitForCreateCategory = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    let res = await createCategory(values, jwtToken);

    if (res.status) {
      setIsSuccessCreateCategory(true);
      getCategoriesWithPagination(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseCreateCategoryModal();
        setButtonName("Create Category");
      }, 2250);

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForCreate(res?.data?.data?.msg);
      setIsErrorCreateCategory(true);
      setButtonName("Create Category");
    }
  };

  const formikForCreateCategory = useFormik({
    initialValues: initialValueForCreateCategory,
    validationSchema: validationSchema,
    onSubmit: onSubmitForCreateCategory,
  });

  const [singleCategoryDataForDelete, setSingleCategoryDataForDelete] =
    useState({});
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);

  const handleOpenDeleteCategoryModal = (data = {}) => {
    setSingleCategoryDataForDelete(data);
    setOpenDeleteCategoryModal(true);
  };
  const handleCloseDeleteCategoryModal = () => {
    setOpenDeleteCategoryModal(false);
  };

  const [isErrorDeleteCategory, setIsErrorDeleteCategory] = useState(false);
  const [isSuccessDeleteCategory, setIsSuccessDeleteCategory] = useState(false);

  useEffect(() => {
    if (isErrorDeleteCategory) {
      setTimeout(() => {
        setIsErrorDeleteCategory(false);
      }, 3000);
    }
  }, [isErrorDeleteCategory]);

  useEffect(() => {
    if (isSuccessDeleteCategory) {
      setTimeout(() => {
        setIsSuccessDeleteCategory(false);
      }, 3000);
    }
  }, [isSuccessDeleteCategory]);

  const [errorMsgForDelete, setErrorMsgForDelete] = useState("");
  const [deleteButtonName, setDeleteButtonName] = useState("Delete");

  const deleteCategoryHandler = async () => {
    setDeleteButtonName("Please wait...");
    let categoryId = singleCategoryDataForDelete.categoryId;

    let response = await deleteCategory(categoryId, jwtToken);

    if (response.status) {
      setIsSuccessDeleteCategory(true);
      getCategoriesWithPagination(pageNo, rowsPerPage, searchObject, jwtToken);

      setTimeout(() => {
        handleCloseDeleteCategoryModal();
        setDeleteButtonName("Delete");
      }, 2250);
    }
    if (!response.status) {
      setErrorMsgForDelete(response?.data?.data?.msg);
      setIsErrorDeleteCategory(true);
      setDeleteButtonName("Delete");
    }
  };

  const [openUpdateCategoryModal, setOpenUpdateCategoryModal] = useState(false);
  const [singleCategoryDataForUpdate, setCategoryDataForUpdate] = useState({});

  const handleOpenUpdateCategoryModal = (data = {}) => {
    setCategoryDataForUpdate(data);
    setOpenUpdateCategoryModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };

  const handleCloseUpdateCategoryModal = () => {
    setOpenUpdateCategoryModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    categoryCode: singleCategoryDataForUpdate.categoryCode,
    categoryName: singleCategoryDataForUpdate.categoryName,
  };

  const validationSchemaForUpdate = Yup.object({
    categoryCode: Yup.string().required("Enter the category code!"),
    categoryName: Yup.string().required("Enter the category Description!"),
  });

  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");

  const [editButtonName, setEditButtonName] = useState("Submit");

  const onSubmitForUpdate = async (values) => {
    setEditButtonName("Please wait...");
    let payload = {
      categoryCode: values.categoryCode.trim(),
      categoryName: values.categoryName.trim(),
      id: singleCategoryDataForUpdate.categoryId,
    };
    let res = await updateCategory(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getCategoriesWithPagination(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setEditButtonName("Submit");
        handleCloseUpdateCategoryModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res?.data?.data?.msg);
      setEditButtonName("Submit");
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={3}
                >
                  {createAccess ? (
                    <MDBox color="text" px={2} ml={-2} mb={1}>
                      <CreateButton
                        tooltip="Create Category"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateCategoryModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    </MDBox>
                  ) : (
                    <MDBox color="text" px={2} ml={-2} mb={1}>
                      <CreateButton
                        tooltip="Create Category"
                        disabled
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateCategoryModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    </MDBox>
                  )}
                </MDBox>

                <MDBox mt={0}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                    searchProp={importSearch}
                  />
                </MDBox>
                <Modal
                  open={openCreateCategoryModal}
                  onClose={handleCloseCreateCategoryModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Category
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateCategoryModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreateCategory.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Category Code
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="categoryCode"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateCategory.handleBlur}
                              onChange={formikForCreateCategory.handleChange}
                              value={
                                formikForCreateCategory.values.categoryCode
                              }
                              error={
                                formikForCreateCategory.touched.categoryCode &&
                                formikForCreateCategory.errors.categoryCode &&
                                true
                              }
                              inputProps={{
                                maxLength: 45,
                              }}
                            />
                            {formikForCreateCategory.errors.categoryCode && (
                              <TextError
                                msg={
                                  formikForCreateCategory.errors.categoryCode
                                }
                              />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Category Description
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="categoryName"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateCategory.handleBlur}
                              onChange={formikForCreateCategory.handleChange}
                              value={
                                formikForCreateCategory.values.categoryName
                              }
                              error={
                                formikForCreateCategory.touched.categoryName &&
                                formikForCreateCategory.errors.categoryName &&
                                true
                              }
                              inputProps={{
                                maxLength: 45,
                              }}
                            />
                            {formikForCreateCategory.errors.categoryName && (
                              <TextError
                                msg={
                                  formikForCreateCategory.errors.categoryName
                                }
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessCreateCategory}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateCategory(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Category created successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorCreateCategory}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateCategory(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMsgForCreate}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForCreateCategory.isValid ||
                                buttonName === "Please wait..."
                              }
                            >
                              {buttonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openUpdateCategoryModal}
                  onClose={handleCloseUpdateCategoryModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Edit Category
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseUpdateCategoryModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForUpdate.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Category Code
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="categoryCode"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.categoryCode}
                            error={
                              formikForUpdate.touched.categoryCode &&
                              formikForUpdate.errors.categoryCode &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.categoryCode &&
                            formikForUpdate.errors.categoryCode && (
                              <TextError
                                msg={formikForUpdate.errors.categoryCode}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Category Description
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="categoryName"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.categoryName}
                            error={
                              formikForUpdate.touched.categoryName &&
                              formikForUpdate.errors.categoryName &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.categoryName &&
                            formikForUpdate.errors.categoryName && (
                              <TextError
                                msg={formikForUpdate.errors.categoryName}
                              />
                            )}
                        </MDBox>

                        <MDBox>
                          <Collapse in={isErrorForUpdate}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForUpdate}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForUpdate}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Category updated successfully!
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={
                              !formikForUpdate.isValid ||
                              editButtonName === "Please wait..."
                            }
                          >
                            {editButtonName}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openDeleteCategoryModal}
                  onClose={handleCloseDeleteCategoryModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to delete?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseDeleteCategoryModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deleteCategoryHandler}
                          disabled={deleteButtonName === "Please wait..."}
                        >
                          {deleteButtonName}
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDeleteCategory}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDeleteCategory(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Category deleted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDeleteCategory}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteCategory(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    allCategories: state.getCategoriesWithPaginationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getCategoriesWithPagination: (
      pageNo,
      rowsPerPage,
      searchObject,
      jwtToken
    ) =>
      dispatch(
        getCategoriesWithPagination(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    createCategory: (payload, jwtToken) =>
      dispatch(createCategory(payload, jwtToken)),
    updateCategory: (payload, jwtToken) =>
      dispatch(updateCategory(payload, jwtToken)),
    deleteCategory: (id, jwtToken) => dispatch(deleteCategory(id, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCreation);
