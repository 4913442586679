import {
  GET_RELATIONLOCATION_FAILURE,
  GET_RELATIONLOCATION_REQUEST,
  GET_RELATIONLOCATION_SUCCESS,
  CREATE_RELATIONLOCATION_REQUEST,
  CREATE_RELATIONLOCATION_SUCCESS,
  CREATE_RELATIONLOCATION_FAILURE,
  UPDATE_RELATION_LOCATION_REQUEST,
  UPDATE_RELATION_LOCATION_SUCCESS,
  UPDATE_RELATION_LOCATION_FAILURE,
} from "./relationLocationTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getRelationLocationRequest = () => {
  return {
    type: GET_RELATIONLOCATION_REQUEST,
  };
};

const getRelationLocationSuccess = (payload) => {
  return {
    type: GET_RELATIONLOCATION_SUCCESS,
    payload: payload,
  };
};

const getRelationLocationFailure = (error) => {
  return {
    type: GET_RELATIONLOCATION_FAILURE,
    payload: error,
  };
};

const getRelationLocation = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRelationLocationRequest());
    let url = `${baseUrl}/rlt`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRelationLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRelationLocationFailure(err.message));
      });
  };
};

const createRelationLocationRequest = () => {
  return {
    type: CREATE_RELATIONLOCATION_REQUEST,
  };
};

const createRelationLocationSuccess = (payload) => {
  return {
    type: CREATE_RELATIONLOCATION_SUCCESS,
    payload: payload,
  };
};

const createRelationLocationFailure = (error) => {
  return {
    type: CREATE_RELATIONLOCATION_FAILURE,
    payload: error,
  };
};

const createRelationLocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createRelationLocationRequest());
    let url = `${baseUrl}/rlt`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createRelationLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createRelationLocationFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateRelationLocationRequest = () => {
  return {
    type: UPDATE_RELATION_LOCATION_REQUEST,
  };
};

const updateRelationLocationSuccess = (payload) => {
  return {
    type: UPDATE_RELATION_LOCATION_SUCCESS,
    payload: payload,
  };
};

const updateRelationLocationFaliure = (error) => {
  return {
    type: UPDATE_RELATION_LOCATION_FAILURE,
    payload: error,
  };
};

const updateRelationLocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateRelationLocationRequest());
    let url = `${baseUrl}/rlt`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateRelationLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateRelationLocationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getRelationLocation, createRelationLocation, updateRelationLocation };
