import {
  GET_FAILING_MINIMUM_TOP_10_FAILURE,
  GET_FAILING_MINIMUM_TOP_10_REQUEST,
  GET_FAILING_MINIMUM_TOP_10_SUCCESS,
  GET_REJECTED_TOP_10_REQUEST,
  GET_REJECTED_TOP_10_SUCCESS,
  GET_REJECTED_TOP_10_FAILURE,
  GET_RTV_TOP_10_REQUEST,
  GET_RTV_TOP_10_SUCCESS,
  GET_RTV_TOP_10_FAILURE,
  GET_TO_TOP_10_REQUEST,
  GET_TO_TOP_10_SUCCESS,
  GET_TO_TOP_10_FAILURE,
} from "./top10Types";

const initialStateForFailingMinimumTop10 = {
  loading: false,
  failingMinimumTop10: [],
  error: "",
};

const getFailingMinimumTop10Reducer = (
  state = initialStateForFailingMinimumTop10,
  action
) => {
  switch (action.type) {
    case GET_FAILING_MINIMUM_TOP_10_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FAILING_MINIMUM_TOP_10_SUCCESS:
      return {
        loading: false,
        failingMinimumTop10: action.payload,
        error: "",
      };
    case GET_FAILING_MINIMUM_TOP_10_FAILURE:
      return {
        loading: false,
        failingMinimumTop10: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRejectedTop10 = {
  loading: false,
  rejectedTop10: [],
  error: "",
};

const getRejectedTop10Reducer = (
  state = initialStateForRejectedTop10,
  action
) => {
  switch (action.type) {
    case GET_REJECTED_TOP_10_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REJECTED_TOP_10_SUCCESS:
      return {
        loading: false,
        rejectedTop10: action.payload,
        error: "",
      };
    case GET_REJECTED_TOP_10_FAILURE:
      return {
        loading: false,
        rejectedTop10: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRTVTop10 = {
  loading: false,
  rtvTop10: [],
  error: "",
};

const getRTVTop10Reducer = (state = initialStateForRTVTop10, action) => {
  switch (action.type) {
    case GET_RTV_TOP_10_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RTV_TOP_10_SUCCESS:
      return {
        loading: false,
        rtvTop10: action.payload,
        error: "",
      };
    case GET_RTV_TOP_10_FAILURE:
      return {
        loading: false,
        rtvTop10: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForTOTop10 = {
  loading: false,
  toTop10: [],
  error: "",
};

const getTOTop10Reducer = (state = initialStateForTOTop10, action) => {
  switch (action.type) {
    case GET_TO_TOP_10_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TO_TOP_10_SUCCESS:
      return {
        loading: false,
        toTop10: action.payload,
        error: "",
      };
    case GET_TO_TOP_10_FAILURE:
      return {
        loading: false,
        toTop10: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export {
  getFailingMinimumTop10Reducer,
  getRejectedTop10Reducer,
  getRTVTop10Reducer,
  getTOTop10Reducer,
};
