/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getItemMaster,
  getItemStatus,
  getItemTypesForStatus,
  getLocations,
  getSubModulesByRoleId,
  getMonthlyVendorPerformance,
} from "../../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const Monthly = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getItemMaster,
  getItemTypesForStatus,
  itemMaster,
  getItemStatus,
  getMonthlyVendorPerformance,
  getMonthlyVendorPerformanceReducer,
  getLocations,
  getLocationsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getItemMaster(jwtToken);
  }, []);

  useEffect(() => {
    getItemTypesForStatus(jwtToken);
  }, []);

  useEffect(() => {
    getMonthlyVendorPerformance(
      selectedMonth ? selectedMonth.value : "",
      selectedItemCode ? selectedItemCode.value : "",

      jwtToken
    );
  }, [selectedMonth, selectedItemCode]);

  useEffect(() => {
    getLocations(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemStatusReportModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "95815117-55fd-40be-ab8f-a9407d9ae1df"
  );

  let viewItemStatus = itemStatusReportModule
    ? itemStatusReportModule.actionId.includes(
        "e7b51078-d27b-4bf2-9707-efa327848bad"
      )
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Vendor Name", accessor: "vendorName" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      { Header: "Month", accessor: "month" },
      { Header: "Success Rate", accessor: "successRate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempMonthlyVendor = [];
    let data = getMonthlyVendorPerformanceReducer.monthlyVendorPerformance.data
      ? getMonthlyVendorPerformanceReducer.monthlyVendorPerformance.data
      : [];

    setLoading(getMonthlyVendorPerformanceReducer.loading);
    data?.map((monthlyVendor) => {
      let des = monthlyVendor?.itemDescription;
      let len = desLength;
      const formatDate = new Date(monthlyVendor.cd);
      const monthlyVendorObject = {
        itemCode: monthlyVendor.itemCode,
        itemCodeDes: `${monthlyVendor.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        vendorName: monthlyVendor.vendorName,
        successRate: monthlyVendor.successRate,
        month: monthlyVendor.month,
      };
      tempMonthlyVendor.push(monthlyVendorObject);
    });
    // viewFG &&
    setState({ ...state, rows: tempMonthlyVendor });
  }, [getMonthlyVendorPerformanceReducer]);

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "itemDesc",
      key: "itemDesc",
    },
    { label: "categoryCode", key: "categoryCode" },
    { label: "controlType", key: "controlType" },
    { label: "qty", key: "qty" },
  ];

  const csvLink = {
    filename: "Item Status Report.csv",
    headers: headers,
    data: rows,
  };

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
    setSelectedLocation("");
  };
  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemCode,
        uom: item.uom,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const handleLocationChange = (selectedItemCode) => {
    setSelectedLocation(selectedItemCode);
  };

  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getLocationsReducer.locations
      ? getLocationsReducer.locations
      : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        label: item.locationName,
        value: item.locationId,
      });
    });
    setLocationOptions(tempItemCodeOptions);
  }, [getLocationsReducer]);

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };
  const monthOptions = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-3}
              mt={-4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", lg: "row" }}
                    mt={2}
                  >
                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Month DropDown
                      </MDTypography>
                      <Select
                        isClearable
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={monthOptions}
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      />
                    </MDBox>

                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Item Code
                      </MDTypography>
                      <Select
                        isClearable
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={itemCodeOptions}
                        value={selectedItemCode}
                        onChange={handleItemCodeChange}
                      />
                    </MDBox>

                    {/* <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                           Vendor
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                        //   options={locationOptions}
                          value={selectedLocation}
                          onChange={handleLocationChange}
                        />
                      </MDBox>
                      <MDBox
                        width={{ xs: "125px", lg: "200px" }}
                        style={{ marginRight: "5px" }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                           Success Rate
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                        //   options={locationOptions}
                          value={selectedLocation}
                          onChange={handleLocationChange}
                        />
                      </MDBox> */}
                    {/* <MDBox
                        display="flex"
                        alignItems="center"
                        marginLeft={1}
                        marginTop={{ xs: 2, lg: 3 }}
                      >
                        <CSVLink {...csvLink}>
                          <DownloadDataButton
                            style={{ marginLeft: "5px" }}
                            type="button"
                            variant="outlined"
                            color="success"
                            iconOnly
                            tooltip="Download All Data"
                          >
                            <Icon>download</Icon>
                          </DownloadDataButton>
                        </CSVLink>
                      </MDBox> */}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
    // </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
    getItemTypeReducer: state.getItemTypeReducer,
    getLocationsReducer: state.getLocationsReducer,
    getMonthlyVendorPerformanceReducer:
      state.getMonthlyVendorPerformanceReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getItemTypesForStatus: (jwtToken) =>
      dispatch(getItemTypesForStatus(jwtToken)),
    getLocations: (jwtToken) => dispatch(getLocations(jwtToken)),
    getMonthlyVendorPerformance: (
      selectedMonth,
      selectedItemCode,

      jwtToken
    ) =>
      dispatch(
        getMonthlyVendorPerformance(
          selectedMonth,
          selectedItemCode,

          jwtToken
        )
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Monthly);
