export const GET_DOCUMENT_TYPE_REQUEST = "GET_DOCUMENT_TYPE_REQUEST";
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const GET_DOCUMENT_TYPE_FAILURE = "GET_DOCUMENT_TYPE_FAILURE";

export const GET_DOCUMENT_TYPE_WITH_PAGINATION_REQUEST =
  "GET_DOCUMENT_TYPE_WITH_PAGINATION_REQUEST";
export const GET_DOCUMENT_TYPE_WITH_PAGINATION_SUCCESS =
  "GET_DOCUMENT_TYPE_WITH_PAGINATION_SUCCESS";
export const GET_DOCUMENT_TYPE_WITH_PAGINATION_FAILURE =
  "GET_DOCUMENT_TYPE_WITH_PAGINATION_FAILURE";

export const CREATE_DOCUMENT_TYPE_REQUEST = "CREATE_DOCUMENT_TYPE_REQUEST";
export const CREATE_DOCUMENT_TYPE_SUCCESS = "CREATE_DOCUMENT_TYPE_SUCCESS";
export const CREATE_DOCUMENT_TYPE_FAILURE = "CREATE_DOCUMENT_TYPE_FAILURE";
