/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

//date-fns
import { format, addMinutes } from "date-fns";
// Formik and Yup
import { useFormik } from "formik";
import TextError from "utils/TextError";

// Dashboard example components

import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// @mui material components
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// React-Select component and styling
import Select from "react-select";
// Functions from store
import {
  getGroupedPickList,
  getItemsByPickList,
  getPickListTracking,
  getSubModulesByRoleId,
  getUser,
  releaseUser,
} from "../../../store";

// Config
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import ReleaseButton from "components/standardized-components/ReleaseButton";

const cookies = new Cookies();

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};
const styleForGrnModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "auto",
  width: 1400,
  maxWidth: 1400,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const GroupedPicklistTracking = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getPickListTracking,
  getGroupedPickList,
  picklistTrackingReducer,
  getItemsByPickList,
  getGroupedPickListReducer,
  getItemsByPickListReducer,
  getUserReducer,
  getUser,
  releaseUser,
}) => {
  const desLength = Config.descriptionLength;

  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let groupedpicklistModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "4bd208bf-c862-4ac4-af3f-4f5b512044d8"
  );

  let viewGroupedPicklist = groupedpicklistModule
    ? groupedpicklistModule.actionId.includes(
        "75ea6920-b1f1-47e4-8613-12efb65a9cea"
      )
    : null;
  let releaseAccess = groupedpicklistModule
    ? groupedpicklistModule.actionId.includes(
        "c54b503f-166b-4cec-87e1-cfc8062765da"
      )
    : null;
  let viewSuidsAccess = groupedpicklistModule
    ? groupedpicklistModule.actionId.includes(
        "0d026cea-7c06-49b7-b0c3-65b4c1101517"
      )
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const [loading, setLoading] = useState(true);

  const [groupedPicklistId, setGroupedPicklistId] = useState("");

  useEffect(() => {
    getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);
  useEffect(() => {
    getGroupedPickList(pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const [picklistId, setPickListId] = useState("");

  const [selectedUser, setSelectedUser] = useState("");

  const handleUserChange = (selectedUser) => {
    setSelectedUser(selectedUser);
  };
  const [isErrorForAllocate, setIsErrorForAllocate] = useState(false);
  const [isSuccessForAllocate, setIsSuccessForAllocate] = useState(false);

  const initialValuesForUser = {
    user: "",
  };
  const [errorMsg, setErrorMsg] = useState("");

  const [releaseButtonName, setReleaseButtonName] = useState("Submit");

  const submitForUser = async (values) => {
    setReleaseButtonName("Please wait...");
    let payload = {
      userId: selectedUser.value,
      picklistId: picklistId,
    };

    let res = await releaseUser(payload, jwtToken);
    if (res.status) {
      setIsSuccessForAllocate(true);
      getGroupedPickList(pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setIsSuccessForAllocate(false);
        setReleaseButtonName("Submit");
        handleCloseAllocateUserModal();
      }, 3000);
    }
    if (!res.status) {
      setErrorMsg(res?.data?.data?.msg);
      setIsErrorForAllocate(true);
      setReleaseButtonName("Submit");
    }
  };

  const formikForUser = useFormik({
    initialValues: initialValuesForUser,
    onSubmit: submitForUser,
  });

  const [allocateUserModal, setAllocateUserModal] = useState(false);

  const handleOpenAllocateUserModal = () => {
    setAllocateUserModal(true);
    formikForUser.setValues(initialValuesForUser);
    formikForUser.setTouched({});
    formikForUser.setErrors({});
    setSelectedUser("");
  };
  const handleCloseAllocateUserModal = () => {
    setAllocateUserModal(false);
    setSelectedUser("");
  };

  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getUserReducer.user.data ? getUserReducer.user.data : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.name,
        value: document.userId,
      });
    });
    setUserOptions(tempDocumentTypeOptions);
  }, [getUserReducer]);

  const [state, setState] = useState({
    columns: [
      { Header: "Pick List Code", accessor: "picklistCode" },
      { Header: "Document Code", accessor: "documentCode" },
      { Header: "Document No", accessor: "documentNo" },
      { Header: "Source", accessor: "source" },
      { Header: "Destination", accessor: "destination" },
      {
        Header: "Status",
        accessor: "status",
        cannotSearch: "true",
        cannotSort: "true",
      },
      {
        Header: "Remarks 1",
        accessor: "remarks1",
      },
      {
        Header: "Remarks 2",
        accessor: "remarks2",
      },
      {
        Header: "Remarks 3",
        accessor: "remarks3",
      },
      {
        Header: "Remarks 4",
        accessor: "remarks4",
      },
      { Header: "Created Date", accessor: "createdDate" },

      {
        Header: "Action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempPicklist = [];

    let data = getGroupedPickListReducer.list.data
      ? getGroupedPickListReducer.list.data
      : [];
    setPaginationObj(getGroupedPickListReducer?.list?.pagination);

    setLoading(getGroupedPickListReducer.loading);

    data.map((picklist) => {
      const formatDate = new Date(picklist.cd);
      let itemDescription = picklist.itemDescription;
      let len = desLength;

      const picklistObject = {
        privateKey: picklist.pickupListCode,
        picklistCode: picklist.picklistCode,
        documentCode: picklist.documentCode,
        documentNo: picklist.documentNumber,
        source: picklist.source,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        destination: picklist.destination,
        status: (
          <button
            className={
              (picklist.status === 0 && `status-created`) ||
              (picklist.status === 1 && `status-warning`) ||
              (picklist.status === 2 && `status-info`) ||
              (picklist.status === 3 && `status-info`) ||
              (picklist.status === 4 && `status-info`) ||
              (picklist.status === 5 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(picklist.status === 0 && `Created`) ||
              (picklist.status === 1 && `Released`) ||
              (picklist.status === 2 && `Open`) ||
              (picklist.status === 3 && `Open`) ||
              (picklist.status === 4 && `Open`) ||
              (picklist.status === 5 && `Delivered`)}
          </button>
        ),
        action: (
          <span style={{ display: "flex" }}>
            <Tooltip title="View Grouped Items">
              {viewSuidsAccess && (
                <MDButton
                  style={{ marginLeft: "0.5rem" }}
                  variant="gradient"
                  color="info"
                  // disabled={!viewGRN}
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenGroupedLineItemsModal();
                    setGroupedPicklistId(picklist.picklistCode);
                    getItemsByPickList(picklist.picklistId, jwtToken);
                  }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              )}
            </Tooltip>
            {/* {picklist.status === 1 || picklist.status === 0 ? ( */}
            {releaseAccess && (
              <>
                <ReleaseButton
                  disabled={
                    picklist.status === 1 || picklist.status === 0
                      ? false
                      : true
                  }
                  tooltip="Release"
                  style={{ marginLeft: "5px" }}
                  type="button"
                  variant="gradient"
                  color="success"
                  iconOnly
                  onClick={() => {
                    setPickListId(picklist.picklistId);
                    handleOpenAllocateUserModal(picklist);
                    getUser(jwtToken);
                  }}
                >
                  {" "}
                  <Icon>forward</Icon>
                </ReleaseButton>

                <MDTypography
                  variant="h6"
                  fontWeight="regular"
                  style={{ marginTop: "0.45rem", marginLeft: "0.75rem" }}
                >
                  {picklist.userName}
                </MDTypography>
              </>
            )}
          </span>
        ),
      };
      tempPicklist.push(picklistObject);
    });
    viewGroupedPicklist && setState({ ...state, rows: tempPicklist });
  }, [getGroupedPickListReducer]);

  const [openGroupedLineItemsModal, setOpenGroupedLineItemsModal] =
    useState(false);

  const handleOpenGroupedLineItemsModal = () => {
    setOpenGroupedLineItemsModal(true);
  };

  const handleCloseGroupedLineItemsModal = () => {
    setOpenGroupedLineItemsModal(false);
    setOpen(false);
  };
  const [groupedItemTable, setGroupedItemTable] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Mfg Date", accessor: "mfgDate", align: "left" },
      { Header: "QTY", accessor: "qty", align: "left" },

      {
        Header: "Status",
        accessor: "status",
        align: "center",
        cannotSearch: "true",
        cannotSort: "true",
      },

      // { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: [],
  });

  const { columns: grnColumns, rows: grnRows } = groupedItemTable;
  const [openGroupedPicklistViewModal, setOpenGroupedPicklistViewModal] =
    useState(false);

  const [singlePicklist, setSinglePicklistItem] = useState({});
  const handleOpenViewGroupedPicklistModal = (singleGrn) => {
    setSinglePicklistItem(singleGrn);
    setOpenGroupedPicklistViewModal(true);
  };

  const handleCloseViewGroupedPicklistModal = () => {
    setOpenGroupedPicklistViewModal(false);
  };

  const [suidTable, setSuidTable] = useState({
    columns: [
      { Header: "SUID", accessor: "suid", align: "left" },
      { Header: "LOT No", accessor: "lotNumber" },
      { Header: "Serial No", accessor: "serialNumber" },
      { Header: "Current Location", accessor: "locationCode", align: "left" },
      { Header: "QTY", accessor: "suqty", align: "left" },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        cannotSearch: "true",
        cannotSort: "true",
      },
    ],
    rows: [],
  });

  const [suidData, setSuidData] = useState(false);
  const [loading2, setLoading2] = useState(true);

  const [itemCode, setItemCode] = useState("");
  const [open, setOpen] = useState(false);
  const [rowActiveColor, setRowActiveColor] = useState("none");
  const [title, setTitle] = useState("");

  useEffect(() => {
    let tempGRN = [];
    let data = getItemsByPickListReducer.items.data
      ? getItemsByPickListReducer?.items.data
      : [];
    setLoading2(getItemsByPickListReducer.loading);

    data.map((singleGrn) => {
      let des = singleGrn?.itemDescription;
      let len = desLength;

      const formatDate = new Date(singleGrn.cd);
      const singleUser = {
        itemCodeDes: `${singleGrn.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        itemCode: singleGrn.itemCode,
        itemDescription: singleGrn.itemDescription,
        qty: `${singleGrn.qty} ${singleGrn.uom}`,
        mfgDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        status: (
          <button
            className={`status-button ${
              (singleGrn.status === 0 && `status-created`) ||
              (singleGrn.status === 1 && `status-warning`) ||
              (singleGrn.status === 2 && `status-info`) ||
              (singleGrn.status === 3 && `status-info`) ||
              (singleGrn.status === 4 && `status-info`) ||
              (singleGrn.status === 5 && `status-success`)
            }`}
            style={{ width: "5.5rem" }}
          >
            {(singleGrn.status === 0 && `Created`) ||
              (singleGrn.status === 1 && `Released`) ||
              (singleGrn.status === 2 && `Open`) ||
              (singleGrn.status === 3 && `Open`) ||
              (singleGrn.status === 4 && `Open`) ||
              (singleGrn.status === 5 && `Delivered`)}
          </button>
        ),
        expand: (
          <IconButton
            aria-label="expand row"
            size="small"
            style={{
              marginRight: "-180px",
              color: singleGrn.suids.length === 0 ? "#b0b0b0" : "#000",
              cursor: singleGrn.suids.length === 0 ? "not-allowed" : "pointer",
              opacity: singleGrn.suids.length === 0 ? 0.6 : 1,
              backgroundColor: singleGrn.suids.length === 0 ? "#e0e0e0" : "",
              border: `1px solid ${
                singleGrn.suids.length === 0 ? "#d0d0d0" : ""
              }`,
              borderRadius: "4px",
              height: "30px",
              transition: "all 0.3s ease",
              textAlign: "center",
              boxShadow:
                singleGrn.suids.length === 0
                  ? "none"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)",
              pointerEvents: singleGrn.suids.length === 0 ? "none" : "auto",
            }}
            disabled={singleGrn.suids.length === 0}
            className="expand-collapse-button"
          >
            {itemCode === singleGrn.itemCode && open === true ? (
              <MDTypography>
                <KeyboardArrowUpIcon
                  onClick={() => {
                    setSuidData([]);
                    setItemCode(singleGrn.itemCode);
                    setOpen(false);
                    setRowActiveColor("none");
                  }}
                />
              </MDTypography>
            ) : (
              <MDTypography>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setSuidData(singleGrn?.suids);
                    setItemCode(singleGrn.itemCode);
                    setOpen(true);
                    setRowActiveColor("blue");
                  }}
                />
              </MDTypography>
            )}
          </IconButton>
        ),
        nestedTableComponent: (
          <Collapse
            in={itemCode === singleGrn.itemCode && open}
            timeout="auto"
            unmountOnExit
          >
            <MDBox className="nested-table-container-suid">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml={12}
              >
                <DataTable
                  table={{ columns: suidColumns, rows: suidRows }}
                  totalRecords={rows.length}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={false}
                  noEndBorder
                  subTableColor="#e0e0de76"
                  subTableHeaderColor="#FFFFFF"
                  tableHoverColor="#FFFFFF"
                  // tableheadColor="#b4b4b276"
                  // tableEntriesColor="#b4b4b276"
                  tableHeaderHoverColor="green"
                  subTableBorderRadius={0}
                  subTableHoverColor="none"
                  searchBarForSubTable={true}
                  subTableEntries={true}
                  tableEntries={false}
                  suid={true}
                  className="nested-table"
                />
              </MDBox>
            </MDBox>
          </Collapse>
        ),
      };

      tempGRN.push(singleUser);
    });
    setGroupedItemTable({ ...groupedItemTable, rows: tempGRN });
  }, [getItemsByPickListReducer, open, suidTable]);

  useEffect(() => {
    let tempGRN = [];
    let data = suidData ? suidData : [];

    data.map((singleSuid) => {
      const singleUser = {
        privateKey: singleSuid.suidId,
        locationId: singleSuid.locationId,
        lotNumber: `${singleSuid.lotNumber ? singleSuid.lotNumber : "NA"}`,
        serialNumber: `${
          singleSuid.serialNumber ? singleSuid.serialNumber : "NA"
        }`,
        suid: singleSuid.suid,
        suqty: singleSuid.qty,
        locationCode: singleSuid.locationCode,
        status: (
          <button
            className={
              (singleSuid.status === 2 && `status-created`) ||
              (singleSuid.status === 3 && `status-info`) ||
              (singleSuid.status === 5 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(singleSuid.status === 2 && `Created`) ||
              (singleSuid.status === 3 && `Picked`) ||
              (singleSuid.status === 5 && `Delivered`)}
          </button>
        ),
      };
      tempGRN.push(singleUser);
    });
    setSuidTable({ ...suidTable, rows: tempGRN });
  }, [suidData]);

  const { columns: suidColumns, rows: suidRows } = suidTable;
  return (
    <>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
                hideColumns={["remarks1", "remarks2", "remarks3", "remarks4"]}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <div>
        <Modal
          open={openGroupedLineItemsModal}
          onClose={handleCloseGroupedLineItemsModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={styleForGrnModal}>
            <MDBox ml={3} mt={-1} display="flex" alignItems="center">
              <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                {groupedPicklistId} - Items
              </MDTypography>
              <MDBox>
                <span>
                  <Tooltip title="Close">
                    <IconButton
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                        marginBottom: "5px",
                      }}
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseGroupedLineItemsModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </MDBox>
            </MDBox>
            <hr style={{ marginBottom: "1rem" }} />

            <MDBox className="nested-table-container">
              {!loading2 ? (
                <DataTable
                  table={{ columns: grnColumns, rows: grnRows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  nestedTable={true}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
          </MDCard>
        </Modal>
      </div>
      <Modal
        open={allocateUserModal}
        onClose={handleCloseAllocateUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDCard sx={styleForRelease}>
          <MDBox ml={3} mt={-1} display="flex" alignItems="center">
            <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
              Release
            </MDTypography>
            <MDBox>
              <Tooltip title="Close">
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseAllocateUserModal}
                  style={{
                    background: "whitesmoke",
                    color: "black",
                    borderRadius: 5,
                    marginLeft: "-55px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          </MDBox>
          <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

          <MDBox pt={1} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              onSubmit={formikForUser.handleSubmit}
            >
              <MDBox mb={1}>
                <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                  {" "}
                  User (Optional)
                </MDTypography>

                <Select
                  isClearable
                  className="select-css"
                  maxMenuHeight={130}
                  placeholder="Choose one..."
                  name="user"
                  onBlur={() => {
                    formikForUser.handleBlur({
                      target: { name: "user" },
                    });
                  }}
                  value={selectedUser}
                  options={userOptions}
                  onChange={(selectedOption) => {
                    handleUserChange(selectedOption);
                    formikForUser.setFieldValue("user", selectedOption);
                  }}
                />
                {formikForUser.touched.user && formikForUser.errors.user && (
                  <TextError msg={formikForUser.errors.user} />
                )}
              </MDBox>

              <MDBox>
                <Collapse in={isErrorForAllocate}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorForAllocate(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {errorMsg}
                  </Alert>
                </Collapse>
                <Collapse in={isSuccessForAllocate}>
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorForAllocate(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Picklist released Successfully
                  </Alert>
                </Collapse>
              </MDBox>

              <MDBox mt={3}>
                <MDButton
                  color="success"
                  fullWidth
                  type="submit"
                  // disabled={!formikForUser.isValid}
                  disabled={releaseButtonName === "Please wait..."}
                >
                  {releaseButtonName}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDCard>
      </Modal>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    picklistTrackingReducer: state.getPickListTrackingReducer,
    getGroupedPickListReducer: state.getGroupedPickListTrackingReducer,
    getItemsByPickListReducer: state.getItemsByPickListReducer,
    getUserReducer: state.getUserReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPickListTracking: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(
        getPickListTracking(pageNo, rowsPerPage, searchObject, jwtToken)
      ),
    getGroupedPickList: (pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getGroupedPickList(pageNo, rowsPerPage, searchObject, jwtToken)),
    getItemsByPickList: (picklistId, jwtToken) =>
      dispatch(getItemsByPickList(picklistId, jwtToken)),
    getUser: (jwtToken) => dispatch(getUser(jwtToken)),
    releaseUser: (payload, jwtToken) =>
      dispatch(releaseUser(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(GroupedPicklistTracking);
