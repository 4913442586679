import {
  GET_MIN_MAX_FAILURE_FAILURE,
  GET_MIN_MAX_FAILURE_REQUEST,
  GET_MIN_MAX_FAILURE_SUCCESS,
} from "./minMaxFailureTypes";

const initialStateForMinMax = {
  loading: false,
  minMax: [],
  error: "",
};

const getMinMaxFailureReducer = (state = initialStateForMinMax, action) => {
  switch (action.type) {
    case GET_MIN_MAX_FAILURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MIN_MAX_FAILURE_SUCCESS:
      return {
        loading: false,
        minMax: action.payload,
        error: "",
      };
    case GET_MIN_MAX_FAILURE_FAILURE:
      return {
        loading: false,
        minMax: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export { getMinMaxFailureReducer };
