/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.=
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// React-Select component and styling
import Select from "react-select";
// Dashboard example components

import DataTable from "examples/Tables/DataTable";

// @mui material components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
// @mui material icons
import CancelIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getAllGRN,
  individualPrint,
  packagePrint,
  createGrn,
  uploadCsv,
  uploadCsvForGrn,
  getSubModulesByRoleId,
  getItemMaster,
  getCreatedActivity,
  createPickList,
  getSkuForReprint,
  reprint,
} from "../../../store";

import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import PrintButton from "components/standardized-components/PrintButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import StandardButton from "components/standardized-components/StandardButton";
import { addMinutes, format } from "date-fns";
import { Backdrop } from "@mui/material";

const cookies = new Cookies();

const styleForReprint = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 350, md: 800, lg: 1000, xl: 1000 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1200, xl: 1200 },
  overflow: "auto",
  height: "80%",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1420 },
  height: "fit-content",
  maxWidth: "90%",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const styleForLabelModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 450,
  width: { xs: 340, sm: 340, md: 440, lg: 800, xl: 800 },
  // maxWidth: 1000,
  // minWidth: 600,
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",

  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForMovement = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const Grn = ({
  getAllGRN,
  allGRN,
  getLineItemsByGrn,

  individualPrint,
  packagePrint,

  createGrn,
  uploadCsvForGrn,
  getItemMaster,

  getSubModulesByRoleId,
  itemMaster,
  getSubModulesByRoleIdReducer,
  getCreatedActivity,
  getCreatedActivityReducer,
  createPickList,
  getSkuForReprint,
  skuForReprint,
  reprint,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let printerConfig = cookies.get("printerConfigForWarehouse");

  // let printerId = loginDetails.data.printerId;

  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let grnModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "f8c10f40-97fe-43fa-bdbc-6e00e40259a0"
  );

  let createNewGRN = grnModule
    ? grnModule.actionId.includes("81a58557-5e7d-4c65-b0e7-4bafc6fff0a4")
    : null;
  let createPicklistAccess = grnModule
    ? grnModule.actionId.includes("f56f82c9-2e21-4e9f-a253-21b2ed2c52ef")
    : null;

  let uploadNewGRN = grnModule
    ? grnModule.actionId.includes("9a8e2c47-161d-4615-a5da-603f8c1c09c3")
    : null;
  let downloadNewGRN = grnModule
    ? grnModule.actionId.includes("67865591-b5b1-4883-9000-a81323767e8f")
    : null;

  let printNewGRN = grnModule
    ? grnModule.actionId.includes("5136c046-f785-47c7-959e-9cf57a776dbc")
    : null;
  let reprintGRN = grnModule
    ? grnModule.actionId.includes("74770155-2683-4a5f-ad47-113e645ca3a8")
    : null;

  let viewGRN = grnModule
    ? grnModule.actionId.includes("749e3a08-10ea-4ea0-8e9c-265336992296")
    : null;

  let viewSKUS = grnModule
    ? grnModule.actionId.includes("0beef2c5-845e-42c6-8cf8-a4d0c2a2626b")
    : null;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchObject, setSearchObject] = useState(JSON.stringify({}));
  const [paginationObj, setPaginationObj] = useState({});
  const [errorMsgFromApi, setErrorMsgFromApi] = useState("");
  const [uncheck, setUncheck] = useState(false);

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex === -1 ? pageNo : pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearchObject(globalFilter ? JSON.stringify(globalFilter) : "");
  };

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  let flag = 1;
  useEffect(() => {
    getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
  }, [pageNo, rowsPerPage, searchObject]);

  const grnPrintedNotification = () =>
    toast.success("GRN Printed Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const initialValue = {
    printingType: "",
    noOfPackages: "",
    qtyInPackage: "",
    packageArray: [],
  };
  const [isErrorPrintLabel, setIsErrorPrintLabel] = useState(false);

  useEffect(() => {
    if (isErrorPrintLabel) {
      setTimeout(() => {
        setIsErrorPrintLabel(false);
      }, 3000);
    }
  }, [isErrorPrintLabel]);

  const [isSuccessPrintLabel, setIsSuccessPrintLabel] = useState(false);

  useEffect(() => {
    if (isSuccessPrintLabel) {
      setTimeout(() => {
        setIsSuccessPrintLabel(false);
      }, 3000);
    }
  }, [isSuccessPrintLabel]);

  const [printButtonName, setPrintButtonName] = useState("Yes");
  const onSubmitForPrint = async (values, { resetForm }) => {
    setPrintButtonName("Please wait...");
    if (
      singleLineItem.serialNumber !== ("NA" || null) ||
      values.printingType === "Individual Printing"
    ) {
      const payload = singleLineItem;
      singleLineItem.printerId = printerId;
      let res = await individualPrint(payload, jwtToken);

      if (res.status) {
        setIsSuccessPrintLabel(true);
        setTimeout(() => {
          handleClosePrintLabelModal();
          setPrintButtonName("Yes");
        }, 3000);
        getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
        getLineItemsByGrn(grn, jwtToken);
      }
      if (!res.status) {
        setErrorMsg(res?.data?.data?.msg);
        setIsErrorPrintLabel(true);
        setPrintButtonName("Yes");
      }
    }

    if (
      (singleLineItem.lotNumber === ("NA" || null) &&
        singleLineItem.serialNumber === ("NA" || null)) ||
      (singleLineItem.lotNumber !== ("NA" || null) &&
        singleLineItem.serialNumber === ("NA" || null)) ||
      values.printingType === "Package Printing"
    ) {
      let arr = values.packageArray;

      singleLineItem.printerId = printerId;
      const payload = { ...singleLineItem, totalPackage: arr.filter((n) => n) };

      let res = await packagePrint(payload, jwtToken);

      if (res.status) {
        setIsSuccessPrintLabel(true);
        setTimeout(() => {
          handleClosePrintLabelModal();
          setPrintButtonName("Yes");
        }, 3000);
        getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
        getLineItemsByGrn(grn, jwtToken);
      }

      if (!res.status) {
        setErrorMsg(res?.data?.data?.msg);
        setIsErrorPrintLabel(true);
        setPrintButtonName("Yes");
      }
    }
  };

  const [state, setState] = useState({
    columns: [
      {
        Header: "GRN No",
        accessor: "grnNumber",
        align: "left",
      },
      {
        Header: "GRN Line Item No",
        accessor: "grnLineNumber",
        align: "left",
      },
      // { Header: "Item Code/Des", accessor: "itemCodeDes", align: "left" },

      { Header: "Item Code", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "Quantity", accessor: "qty", align: "left" },
      { Header: "UOM", accessor: "uom", align: "left" },
      // { Header: "uom", accessor: "uom", align: "left" },
      { Header: "LOT", accessor: "lotNo", align: "left" },
      { Header: "Serial No", accessor: "SerialNo", align: "left" },

      { Header: "ASN Code", accessor: "asnCode", align: "left" },

      { Header: "Created Date", accessor: "createdDate" },

      {
        Header: "Vendor Code / Name",
        accessor: "vendorCodeName",
        align: "left",
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [itemTable, setItemTable] = useState({
    columns: [
      { Header: "Item Code/Des", accessor: "itemCode", align: "left" },
      {
        Header: "Item Description",
        accessor: "itemDescription",
        align: "left",
      },
      { Header: "", accessor: "mfgDate", align: "left" },
      { Header: "Quantity", accessor: "quantity", align: "left" },
      { Header: "NOB", accessor: "nob", align: "left" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo", align: "left" },
      { Header: "Item Category", accessor: "itemCategory", align: "left" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = itemTable;

  const [tableHeading, setTableHeading] = useState("");
  const [vendorCodeName, setVendorCodeName] = useState("");
  const [openGrnItemModal, setOpenGrnItemModal] = useState(false);

  const handleOpenGrnItemModal = () => {
    setOpenGrnItemModal(!openGrnItemModal);
  };

  const [lineItems, setLineItems] = useState({});

  const handleOpenLineItemsModal = (lineItems = {}) => {
    setLineItems(lineItems);
  };

  const [grn, setGrn] = useState("");

  const [grnNumber, setGrnNumber] = useState("");
  const [grnLineNumber, setGrnLineNumber] = useState("");

  useEffect(() => {}, [grn]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempGRN = [];
    let data = allGRN.GRN.data ? allGRN.GRN.data : [];
    setPaginationObj(allGRN?.GRN?.pagination);
    setLoading(allGRN.loading);
    data.map((singleGrn) => {
      const formatDate = new Date(singleGrn.cd);
      const singleUser = {
        privateKey: singleGrn.grnNumber,
        grnNumber: singleGrn.grnNumber,
        grnLineNumber: singleGrn.grnLineNumber,
        poNo: singleGrn.PONumber,
        asnCode: singleGrn.asnCode,
        vendorCode: singleGrn.vendorCode,
        vendorName: singleGrn.vendorName,
        itemCode: singleGrn.itemCode,
        itemDescription: singleGrn.itemDescription,
        lotNo: singleGrn.lotNumber ? singleGrn.lotNumber : "NA",
        SerialNo: singleGrn.serialNumber ? singleGrn.serialNumber : "NA",
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        invNo: singleGrn.InvoiceNo,
        itemId: singleGrn.itemId,
        locationId: singleGrn.locationId,
        // vendorCodeName: `${singleGrn.vendorCode} / ${singleGrn.vendorName}`,
        // itemCodeDes: `${singleGrn.itemCode} / ${singleGrn.itemDescription}`,
        // lotSerialNo: `${singleGrn.lotNumber ? singleGrn.lotNumber : "NA"} / ${
        //   singleGrn.serialNumber ? singleGrn.serialNumber : "NA"
        // }`,
        qty: `${singleGrn.qty}`,
        uom: `${singleGrn.uom}`,

        action: (
          <>
            <PrintButton
              tooltip={singleGrn.isPrinted === 0 ? `Print` : `Re-Print`}
              disabled={singleGrn.isPrinted === 0 ? !printNewGRN : !viewSKUS}
              color={singleGrn.isPrinted === 0 ? `success` : `error`}
              variant="gradient"
              // disabled={
              //   singleGrn.isPrinted === 1 && role !== "admin" ? true : false
              // }
              iconOnly
              type="button"
              onClick={() => {
                if (singleGrn.isPrinted === 0) {
                  handleOpenSingleLineItemModal(singleGrn);
                  handleOpenPrintLabelModal();
                }
                if (singleGrn.isPrinted === 1) {
                  // navigate("/reprint");
                  handleOpenReprintModal();
                  setGrnNumber(singleGrn.grnNumber);
                  setGrnNumberForReprint(singleGrn.grnNumber);
                  setGrnLineNumber(singleGrn.grnLineNumber);
                  getSkuForReprint(
                    singleGrn.grnNumber,
                    singleGrn.grnLineNumber,
                    jwtToken
                  );
                }
                // handleOpenSingleLineItemModal(singleGrn);
                // handleOpenPrintLabelModal();
                // if (printerConfig === "1" && singleGrn.isPrinted === 0) {
                //   handleOpenSingleLineItemModal(singleGrn);
                //   handleOpenPrintLabelModal();
                // }
                // if (printerConfig === "1" && singleGrn.isPrinted === 1) {
                //   // navigate("/reprint");
                //   handleOpenReprintModal();
                // }
                // if (printerConfig === "0") {
                //   navigate("/printer_configurator");
                // }
              }}
            >
              <Icon>print</Icon>
            </PrintButton>
            {createPicklistAccess && (
              <StandardButton
                tooltip="Create Picklist"
                disabled={singleGrn.isDisabled === 1}
                style={{ marginLeft: "5px" }}
                color="warning"
                variant="gradient"
                iconOnly
                icon="rotate_right"
                type="button"
                onClick={() => {
                  handleCreatePicklistModal(singleGrn);
                  getCreatedActivity(1, jwtToken);
                }}
              >
                <Icon>rotate_right</Icon>
              </StandardButton>
            )}
          </>
        ),
      };
      tempGRN.push(singleUser);
    });

    viewGRN && setState({ ...state, rows: tempGRN });
  }, [allGRN]);

  const [singleLineItem, setSingleLineItem] = useState({});

  const handleOpenSingleLineItemModal = (item = {}) => {
    setSingleLineItem(item);
  };

  const handleCloseLineItemsModal = () => {
    setOpenGrnItemModal(false);
  };

  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);
  //    const handleClosePrintLabelModal = () => {
  //     setOpenPrintLabelModal(false);
  //   };

  const handleOpenPrintLabelModal = () => {
    setOpenPrintLabelModal(true);
  };
  const handleClosePrintLabelModal = () => {
    setBalance("");
    setConfirmationMsg(false);
    setBalanceItemMsg("");
    setOpenPrintLabelModal(false);
    setErrorMsg("");
    setIsErrorPrintLabel(false);
  };

  const [confirmationMsg, setConfirmationMsg] = useState(false);

  const onClickForConfirmation = () => {
    setConfirmationMsg(true);
  };

  const [sumOfArray, setSumOfArray] = useState("");

  const [balance, setBalance] = useState("");

  useEffect(() => {
    let rem = singleLineItem.qty - sumOfArray;
    if (isNaN(rem)) {
      setBalance("");
    }
    if (!isNaN(rem)) {
      setBalance(rem);
    }
  }, [sumOfArray]);

  let pushPackage;
  const [openBalanceItemModal, setOpenBalanceItemModal] = useState(false);

  const handleCloseBalanceItemModal = () => {
    setOpenBalanceItemModal(false);
  };

  const [balanceItemMsg, setBalanceItemMsg] = useState("");

  useEffect(() => {
    if (balance > 0) {
      setBalanceItemMsg(`${balance} ${singleLineItem.uom} remaining`);
    }
    if (balance < 0) {
      setBalanceItemMsg(`Remove ${Math.abs(balance)} ${singleLineItem.uom}`);
    }
    if (balance === 0) {
      setBalanceItemMsg("Qty matched");
    }
  }, [balance]);

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = (data) =>
    toast.error(data, {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("grn", uploadCsvData);

    let res = await uploadCsvForGrn(data, jwtToken);

    if (res.status) {
      getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification(res?.data?.data?.msg);
    }
  };

  const [controlTypeSelected, setControlTypeSelected] = useState({
    label: "",
    value: 0,
  });

  const handleChangeControlType = (selectedOption) => {
    setControlTypeSelected(selectedOption);
  };

  const [createdActivitySelected, setCreatedActivitySelected] = useState("");

  const handleCreatedActivityChange = (selectedCreatedActivity) => {
    setCreatedActivitySelected(selectedCreatedActivity);
  };

  const [createdActivityOptions, setCreatedActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getCreatedActivityReducer.activity.data
      ? getCreatedActivityReducer.activity.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setCreatedActivityOptions(tempCreatedActivityOptions);
  }, [getCreatedActivityReducer]);

  const [createPicklistModal, setCreatePicklistModal] = useState(false);
  const handleCreatePicklistModal = (data = {}) => {
    setCreatePicklistModal(true);
    setSingleLineItem(data);
    setCreatedActivitySelected("");
  };

  const handleCloseCreatePicklistModal = (data = {}) => {
    setCreatePicklistModal(false);
  };

  const initialValuesForAdd = {
    mvtId: "",
    documentTypeId: "",
  };
  const validationSchemaForAdd = Yup.object({
    // mvtId: Yup.string().required("Select activity name"),
  });

  const [picklistButtonName, setPicklistButtonName] = useState("Submit");

  const [isErrorCreatedMsgActivity, setIsErrorMsgActivity] = useState("");
  const onSubmitForAdd = async (values, { resetForm }) => {
    setPicklistButtonName("Please wait...");
    let payload = {
      mvtId: createdActivitySelected?.value,
      documentTypeId: 1,
      documentNumber: singleLineItem.grnNumber,
      items: [
        {
          itemId: singleLineItem.itemId,
          itemCode: singleLineItem.itemCode,
          qty: singleLineItem.qty,
        },
      ],
    };
    let res = await createPickList(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreatedActivity(true);

      getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        setPicklistButtonName("Submit");
        handleCloseCreatePicklistModal();
      }, 2259);
    }
    if (!res.status) {
      setIsErrorCreatedActivity(true);
      setIsErrorMsgActivity(res?.data?.data?.msg);
      setPicklistButtonName("Submit");
    }

    resetForm();
  };

  const formikForAdd = useFormik({
    initialValues: initialValuesForAdd,
    onSubmit: onSubmitForAdd,
    validationSchema: validationSchemaForAdd,
    enableReinitialize: true,
  });

  const [isSuccessCreatedActivity, setIsSuccessCreatedActivity] =
    useState(false);
  const [isErrorCreatedActivity, setIsErrorCreatedActivity] = useState(false);

  useEffect(() => {
    if (isErrorCreatedActivity) {
      setTimeout(() => {
        setIsErrorCreatedActivity(false);
      }, 3000);
    }
  }, [isErrorCreatedActivity]);

  useEffect(() => {
    if (isSuccessCreatedActivity) {
      setTimeout(() => {
        setIsSuccessCreatedActivity(false);
      }, 3000);
    }
  }, [isSuccessCreatedActivity]);

  const headers = [
    {
      label: "grnNumber",
      key: "grnNumber",
    },
    {
      label: "grnLineNumber",
      key: "grnLineNumber",
    },
    { label: "asnCode", key: "asnCode" },
    { label: "itemCode", key: "itemCode" },
    {
      label: "itemDescription",
      key: "itemDescription",
    },

    {
      label: "qty",
      key: "qty",
    },
    {
      label: "uom",
      key: "uom",
    },
    {
      label: "vendorCode",
      key: "vendorCode",
    },
    {
      label: "vendorName",
      key: "vendorName",
    },
    {
      label: "lotNumber",
      key: "lotNumber",
    },
    {
      label: "serialNumber",
      key: "serialNumber",
    },
    {
      label: "batchNumber",
      key: "batchNumber",
    },
  ];

  const csvLink = {
    filename: "Grn List.csv",
    headers: headers,
    data: rows,
  };

  const csvLink2 = {
    filename: "Grn Template.csv",
    headers: headers,
    data: [],
  };

  const initialValues = {
    grnNumber: "",
    lineItems: [
      {
        grnLineNumber: "",
        itemCodeDes: "",
        qty: "",
        uom: "",
        batchNumber: "",
        lotNumber: "",
        serialNumber: "",
        asnCode: "",
      },
    ],

    vendorCode: "",
    vendorName: "",
  };

  const validationSchema = Yup.object({
    grnNumber: Yup.string().required("Enter grn number."),
    grnLineNumber: Yup.string().required("Enter grn line number "),
    itemCodeDes: Yup.string().required("Select a item code/des"),
    lineItems: Yup.array().of(
      Yup.object({
        qty: Yup.number().required("Enter a valid qty."),
      })
    ),

    // batchNumber: Yup.string().required("Enter batch number "),
    // lotNumber: Yup.string().required("required"),
    // serialNumber: Yup.string().required("Enter serial number "),
    asnCode: Yup.string().required("Enter asn code "),
    vendorCode: Yup.string().required("Enter vendor code "),
    vendorName: Yup.string().required("Enter vendor name "),
  });

  const [buttonName, setButtonName] = useState("Submit");

  const onSubmitForLineItems = async (values, { resetForm }) => {
    setButtonName("Please wait...");
    const { lineItems, grnNumber, vendorCode, asnCode, vendorName } = values;

    // Trim the values
const trimmedGrnNumber = grnNumber?.trim();
const trimmedVendorCode = vendorCode?.trim();
const trimmedAsnCode = asnCode?.trim();
const trimmedVendorName = vendorName?.trim();

    const payloads = [];

    for (let index = 0; index < lineItems.length; index++) {
      const selectedItem = itemCodeOptions.find(
        (option) => option.value === lineItems[index]?.itemCodeDes
      );

      const payload = {
        grnNumber: trimmedGrnNumber,
        vendorCode: trimmedVendorCode,
        asnCode: trimmedAsnCode,
        vendorName: trimmedVendorName,
        grnLineNumber: lineItems[index]?.grnLineNumber,
        itemCode: selectedItem?.value,
        itemDescription: selectedItem?.des,
        qty: lineItems[index]?.qty,
        uom: lineItems[index]?.uom,
        batchNumber: lineItems[index]?.batchNumber.trim(),
        lotNumber:
          (selectedItem?.controlType === "No Control" && "NA") ||
          (selectedItem?.controlType === "Serial Control" && "NA") ||
          (selectedItem?.controlType === "Lot Control" &&
            lineItems[index]?.lotNumber),
        serialNumber:
          (selectedItem?.controlType === "No Control" && "NA") ||
          (selectedItem?.controlType === "Serial Control" &&
            lineItems[index]?.serialNumber) ||
          (selectedItem?.controlType === "Lot Control" && "NA"),
      };
      payloads.push(payload);
    }
    const res = await createGrn(payloads, jwtToken);
    if (res.status) {
      setIsSuccessCreatedGrn(true);
      getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken);
      setTimeout(() => {
        handleCreateGrnModal();
        setButtonName("Submit");
      }, 2500);
    }

    if (!res.status) {
      setIsErrorCreatedGrn(true);
      setErrorMsgForCreate(res?.data?.data?.msg);
      setButtonName("Submit");
    }
  };

  const [isSuccessCreatedGrn, setIsSuccessCreatedGrn] = useState(false);
  const [isErrorCreatedGrn, setIsErrorCreatedGrn] = useState(false);

  useEffect(() => {
    if (isErrorCreatedGrn) {
      setTimeout(() => {
        setIsErrorCreatedGrn(false);
      }, 3000);
    }
  }, [isErrorCreatedGrn]);

  useEffect(() => {
    if (isSuccessCreatedGrn) {
      setTimeout(() => {
        setIsSuccessCreatedGrn(false);
      }, 3000);
    }
  }, [isSuccessCreatedGrn]);

  // const formik = useFormik({
  //   initialValues,
  //   onSubmit,
  //   validationSchema,
  // });

  const [createGrnModal, setCreateGrnModal] = useState(false);
  const handleCreateGrnModal = () => {
    // formik.setValues(initialValues);
    // formik.setTouched({});
    // formik.setErrors({});
    setItemCodeSelected("");
    setControlTypeSelected({
      label: "",
      value: 0,
    });
    setCreateGrnModal(!createGrnModal);
  };

  let controlTypeOptions = [
    { label: "No Control", value: 0 },
    { label: "Serial Control", value: 1 },
    { label: "Lot Control", value: 2 },
  ];

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((itemType) => {
      tempItemCodeOptions.push({
        key: `${itemType.itemCode}/${itemType.itemDescription}`,
        value: itemType.itemCode,
        des: itemType.itemDescription,
        uom: itemType.uom,
        controlType:
          (itemType.controlType == 0 && "No Control") ||
          (itemType.controlType == 1 && "Serial Control") ||
          (itemType.controlType == 2 && "Lot Control"),
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [itemCodeSelected, setItemCodeSelected] = useState("");
  const [controlTypeByItemSelected, setControlTypByItemeSelected] =
    useState("No Control");

  const handleChangeItemType = (selectedOption) => {
    setItemCodeSelected(selectedOption);
    setControlTypByItemeSelected(selectedOption.controlType);
  };

  const [itemDescriptionOptions, setItemDescriptionOptions] = useState([]);

  useEffect(() => {
    let tempItemDesOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((itemType) => {
      tempItemDesOptions.push({
        label: itemType.itemDescription ? itemType.itemDescription : "null",
        value: itemType.itemId,
      });
    });
    setItemDescriptionOptions(tempItemDesOptions);
  }, [itemMaster]);

  const [itemDesSelected, setItemDesSelected] = useState("");

  const handleChangeDesType = (selectedOption) => {
    setItemDesSelected(selectedOption);
  };
  const [itemCodeForControl, setItemCodeForControl] = useState({});

  const [isErrorRePrint, setIsErrorRePrint] = useState(false);

  useEffect(() => {
    if (isErrorRePrint) {
      setTimeout(() => {
        setIsErrorRePrint(false);
      }, 3000);
    }
  }, [isErrorRePrint]);

  const [reprintData, setReprintData] = useState({});

  const [openReprintModal, setOpenReprintModal] = useState(false);
  const [grnNumberForReprint, setGrnNumberForReprint] = useState("");

  const handleOpenReprintModal = () => {
    setOpenReprintModal(true);
  };
  const handleCloseReprintModal = () => {
    setOpenReprintModal(false);
  };

  const ReprintNotification = () =>
    toast.success("SKU Reprinted Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const [openReprintConfirmationModal, setOpenReprintConfirmationModal] =
    useState(false);

  const handleOpenReprintConfirmationModal = (data = {}) => {
    setReprintData(data);
    setOpenReprintConfirmationModal(true);
  };
  const handleCloseReprintConfirmationModal = () => {
    setOpenReprintConfirmationModal(false);
  };
  const onClickRePrint = async () => {
    let payload = {
      suid: reprintData.suid,
      printerId: printerId,
    };
    let res = await reprint(payload, jwtToken);
    if (res.status) {
      ReprintNotification();
      getSkuForReprint(grnNumber, grnLineNumber, jwtToken);
      setOpenReprintConfirmationModal(false);
    }
    if (!res.status) {
      setIsErrorRePrint(true);
    }
  };

  const [reprintTable, setReprintTable] = useState({
    columns: [
      { Header: "SKU", accessor: "sku" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });

  const { columns: reprintColumns, rows: reprintRows } = reprintTable;

  useEffect(() => {
    getSkuForReprint(grnNumber, grnLineNumber, jwtToken);
  }, [grnNumber, grnLineNumber]);

  const [loading2, setLoading2] = useState(true);
  useEffect(() => {
    let tempSkus = [];
    let data = skuForReprint.sku.data ? skuForReprint.sku.data : [];
    setLoading2(skuForReprint.loading);

    data.map((sku) => {
      const object = {
        privateKey: sku.grnNumber,

        sku: sku.sku,

        qty: `${sku.qty} ${sku.uom}`,

        action: (
          <Tooltip title="Reprint">
            {reprintGRN && (
              <MDButton
                // disabled={!reprintGRN}
                iconOnly
                variant="gradient"
                color="error"
                onClick={() => {
                  // handleOpenReprintModal(sku);
                  if (printerConfig === true) {
                    handleOpenReprintConfirmationModal(sku);
                  }
                  if (printerConfig === false) {
                    navigate("/printer-configurator");
                  }
                }}
              >
                <Icon> print </Icon>
              </MDButton>
            )}
          </Tooltip>
        ),
      };
      tempSkus.push(object);
    });

    setReprintTable({ ...reprintTable, rows: tempSkus });
  }, [skuForReprint]);

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              {/* <MDBox>
                  <MDTypography variant="h4" gutterBottom>
                    GRN
                  </MDTypography>
                </MDBox> */}
              <MDBox color="text" px={2} ml={-2} mb={1}>
                {createNewGRN && (
                  <CreateButton
                    tooltip="Create GRN"
                    // disabled={!createNewGRN}
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleCreateGrnModal();
                      getItemMaster(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}

                {downloadNewGRN ? (
                  <CSVLink {...csvLink2}>
                    <DownloadButton
                      tooltip="Download Template"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="error"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadButton>
                  </CSVLink>
                ) : (
                  ""
                )}
                {uploadNewGRN && (
                  <UploadButton
                    tooltip="Upload CSV"
                    disabled={!uploadNewGRN}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </UploadButton>
                )}
                {downloadNewGRN ? (
                  <CSVLink {...csvLink}>
                    <Tooltip title="Download Grn List">
                      <MDButton
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="success"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </MDButton>
                    </Tooltip>
                  </CSVLink>
                ) : (
                  ""
                )}
              </MDBox>
            </MDBox>
            {/* {uploadCsvFilter && (
              <div>
                <div>
                  <input
                    className="choose_file"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  />
                  {downloadNewGRN ? (
                    <CSVLink {...csvLink}>
                      <DownloadDataButton
                        tooltip="Download Grn List"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="success"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadDataButton>
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )} */}
            {uploadCsvFilter && (
              <div>
                <div>
                  <input
                    className="choose_file"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  />

                  <MDButton
                    disabled={
                      !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                    }
                    variant="contained"
                    color="info"
                    style={{
                      marginLeft: { xs: "1.5rem", lg: "1" },
                      marginTop: "0rem",
                    }}
                    type="button"
                    onClick={uploadCsvHandler}
                  >
                    UPLOAD
                  </MDButton>
                </div>
                <div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    <strong>*</strong> Please upload
                    <strong>".csv"</strong> file only
                  </span>
                </div>
              </div>
            )}

            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
                options={{ draggable: false }}
                serverSidePagination={true}
                paginationObj={paginationObj}
                pageNoValue={pageNo}
                rowsPerPageValue={rowsPerPage}
                pageNoProp={importPageNo}
                rowsPerPageProp={importRowsPerPage}
                searchProp={importSearch}
                hideColumns={["", "asnCode", "vendorCodeName", "createdDate"]}
              />
            </MDBox>

            <Modal
              open={openReprintModal}
              onClose={handleCloseReprintModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForReprint}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    {grnNumberForReprint}
                  </MDTypography>
                  <MDBox></MDBox>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                            marginBottom: "5px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseReprintModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />
                <MDBox>
                  {!loading2 ? (
                    <DataTable
                      table={{ columns: reprintColumns, rows: reprintRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openReprintConfirmationModal}
              onClose={handleCloseReprintConfirmationModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={deleteStyle}>
                <MDTypography>Are you sure you want to reprint ?</MDTypography>
                <MDBox mt={2} display="flex">
                  <MDButton
                    style={{ width: "100%", marginRight: "0.5rem" }}
                    color="info"
                    variant="outlined"
                    onClick={handleCloseReprintConfirmationModal}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    style={{ width: "100%", marginLeft: "0.5rem" }}
                    color="success"
                    onClick={onClickRePrint}
                  >
                    Yes
                  </MDButton>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isErrorRePrint}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorRePrint(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Could not reprint
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
            <div>
              <Modal
                open={openGrnItemModal}
                onClose={handleOpenGrnItemModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Items
                    </MDTypography>
                    <MDBox></MDBox>
                    <MDBox>
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                              marginBottom: "5px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseLineItemsModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />
                  <MDBox>
                    <MDTypography variant="h5" gutterBottom marginLeft="25px">
                      GRN: {tableHeading}
                    </MDTypography>
                    <MDTypography variant="h5" gutterBottom marginLeft="25px">
                      Vendor: {vendorCodeName}
                    </MDTypography>
                    <DataTable
                      table={{ columns: itemColumns, rows: itemRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </MDBox>
                </MDCard>
              </Modal>
            </div>
            <Modal
              open={createPicklistModal}
              onClose={handleCloseCreatePicklistModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForMovement}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Movement Change
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreatePicklistModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForAdd.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Grn Number:-{" "}
                        <span style={{ fontWeight: 400 }}>
                          {singleLineItem?.grnNumber}
                        </span>
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Movement
                      </MDTypography>

                      <Select
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="mvtId"
                        // styles={customStyles}
                        onBlur={() => {
                          formikForAdd.handleBlur({
                            target: { name: "mvtId" },
                          });
                        }}
                        value={createdActivitySelected}
                        options={createdActivityOptions}
                        onChange={(selectedOption) => {
                          handleCreatedActivityChange(selectedOption);
                          // formikForAdd.handleChange("mvtId")(
                          //   selectedOption.label
                          // );
                        }}
                      />

                      {formikForAdd.touched.mvtId &&
                        formikForAdd.errors.mvtId && (
                          <TextError msg={formikForAdd.errors.mvtId} />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorCreatedActivity}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorCreatedActivity(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorCreatedMsgActivity}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessCreatedActivity}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessCreatedActivity(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Submitted successfully
                        </Alert>
                      </Collapse>
                    </MDBox>

                    <MDBox mt={3}>
                      <MDButton
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={
                          createdActivitySelected == "" ||
                          picklistButtonName === "Please wait..."
                        }
                      >
                        {picklistButtonName}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openPrintLabelModal}
              onClose={handleClosePrintLabelModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForLabelModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Print Label
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-40px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleClosePrintLabelModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <Formik
                  initialValues={initialValue}
                  onSubmit={onSubmitForPrint}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div style={{ display: "flex" }}>
                          <MDTypography variant="h6">
                            Grn Number:{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singleLineItem.grnNumber}
                            </span>
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            style={{ marginLeft: "2rem" }}
                          >
                            Item Code:{" "}
                            <span style={{ fontWeight: 400 }}>
                              {singleLineItem.itemCode}
                            </span>
                          </MDTypography>
                        </div>
                        {singleLineItem.lotNumber !== ("NA" || null) && (
                          <div className="form-group">
                            <MDTypography
                              variant="h5"
                              fontWeight="medium"
                              flexGrow={1}
                              mt={2}
                            >
                              Type of Printing
                            </MDTypography>
                            <div>
                              <Field
                                type="radio"
                                name="printingType"
                                value="Individual Printing"
                                className="radio"
                              />
                              <span>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  style={{
                                    marginLeft: "1.5rem",
                                    fontSize: "15px",
                                    marginTop: "-1.77rem",
                                    fontWeight: 500,
                                  }}
                                >
                                  Individual Printing
                                </MDTypography>
                              </span>
                            </div>
                            <div>
                              <Field
                                type="radio"
                                name="printingType"
                                value="Package Printing"
                                className="radio"
                              />
                              <span>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="medium"
                                  style={{
                                    marginLeft: "1.5rem",
                                    fontSize: "15px",
                                    marginTop: "-1.77rem",
                                    fontWeight: 500,
                                  }}
                                >
                                  Package Printing
                                </MDTypography>
                              </span>
                            </div>
                          </div>
                        )}
                        {((singleLineItem.lotNumber === ("NA" || null) &&
                          singleLineItem.serialNumber === ("NA" || null)) ||
                          (singleLineItem.lotNumber !== ("NA" || null) &&
                            singleLineItem.serialNumber === ("NA" || null) &&
                            formik.values.printingType ===
                              "Package Printing")) && (
                          <div>
                            <div style={{ marginTop: "20px" }}>
                              {/* <h5>Package Printing</h5> */}
                              <div style={{ display: "flex" }}>
                                <MDTypography variant="h5">
                                  Total: {singleLineItem.qty}{" "}
                                  {singleLineItem.uom}
                                </MDTypography>
                                <MDTypography
                                  variant="h5"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  {formik.values.packageArray.length > 0 &&
                                    `Balance: ${balanceItemMsg}`}
                                </MDTypography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                }}
                              >
                                {formik.values.packageArray.length === 0 && (
                                  <div className="form-group">
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                      flexGrow={1}
                                    >
                                      Number of Packages
                                    </MDTypography>

                                    <Field
                                      type="text"
                                      name="noOfPackages"
                                      className="form-control"
                                      autocomplete="off"
                                      style={{
                                        width: "100px",
                                        borderRadius: 10,
                                        marginLeft: 0,
                                        height: 35,
                                        paddingLeft: "15px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              {formik.values.packageArray.length === 0 && (
                                <div>
                                  <MDButton
                                    type="button"
                                    variant="gradient"
                                    color="success"
                                    style={{
                                      marginBottom: "1rem",
                                      marginTop: "12px",
                                    }}
                                    onClick={() => {
                                      // for (
                                      //   let i = 0;
                                      //   i < formik.values.noOfPackages;
                                      //   i++
                                      // ) {
                                      //   pushPackage();
                                      // }

                                      let a = singleLineItem.qty;
                                      let b = +formik.values.noOfPackages;
                                      // let a = 68;
                                      // let b = 7;

                                      let quo = +Math.floor(a / b);
                                      let rem = +a % b;

                                      formik.setFieldValue(
                                        "packageArray",
                                        rem === 0
                                          ? Array(b).fill(quo)
                                          : [
                                              ...Array(b - 1).fill(quo),
                                              ...Array(1).fill(quo + rem),
                                            ]
                                      );
                                    }}
                                  >
                                    Create Package
                                  </MDButton>
                                </div>
                              )}
                              <div>
                                <FieldArray name="packageArray">
                                  {(fieldArrayProps) => {
                                    const { push, remove, form } =
                                      fieldArrayProps;
                                    const { values } = form;
                                    const { packageArray } = values;
                                    pushPackage = push;

                                    let newPakageArray =
                                      packageArray.filter(Number);

                                    let sum = +newPakageArray.reduce(
                                      (a, b) => a + b,
                                      0
                                    );
                                    setSumOfArray(sum);
                                    return (
                                      <div>
                                        <Grid container>
                                          {packageArray.map((type, index) => (
                                            <Grid item xs={12} lg={6}>
                                              <div
                                                key={index}
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <label
                                                  htmlFor="packageArray"
                                                  style={{
                                                    marginTop: "0.4rem",
                                                  }}
                                                >
                                                  <MDTypography variant="h6">
                                                    Package {index + 1}
                                                  </MDTypography>
                                                </label>
                                                <Field
                                                  name={`packageArray[${index}]`}
                                                  type="number"
                                                  style={{
                                                    width: "100px",
                                                    borderRadius: 10,
                                                    marginLeft: 0,
                                                    height: 35,
                                                    paddingLeft: "15px",
                                                    marginLeft: "2rem",
                                                    margin: "5px",
                                                  }}
                                                  autocomplete="off"
                                                  value={
                                                    formik.values.packageArray[
                                                      index
                                                    ]
                                                  }
                                                  // onChange={(e) => {
                                                  //   formik.handleChange(e);
                                                  // }}
                                                  // onBlur={() => {
                                                  //   setSumOfArray(
                                                  //     formik.values.packageArray.reduce(
                                                  //       (a, b) => a + b,
                                                  //       0
                                                  //     )
                                                  //   );
                                                  // }}
                                                />

                                                <span>
                                                  {index > 0 && (
                                                    <MDButton
                                                      variant="graidiant"
                                                      color="error"
                                                      type="button"
                                                      onClick={(e) => {
                                                        remove(index);

                                                        // setSumOfArray(
                                                        //   formik.values.packageArray.reduce(
                                                        //     (a, b) => a + b,
                                                        //     0
                                                        //   )
                                                        // );
                                                      }}
                                                      style={{
                                                        // marginLeft: "0.1rem",
                                                        marginTop: "0.4rem",
                                                      }}
                                                    >
                                                      {/* <i
                                                     className="fa fa-times"
                                                     style={{
                                                       color: "red",
                                                     }}
                                                   ></i> */}

                                                      <IconButton
                                                        style={{
                                                          background:
                                                            "whitesmoke",
                                                          color: "black",
                                                          borderRadius: 5,
                                                          // marginLeft: "-42px",
                                                        }}
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                      >
                                                        <CloseIcon fontSize="inherit" />
                                                      </IconButton>
                                                    </MDButton>
                                                  )}
                                                </span>
                                              </div>
                                            </Grid>
                                          ))}
                                          {balance !== 0 &&
                                            formik.values.packageArray.length >
                                              0 && (
                                              <MDButton
                                                type="button"
                                                variant="outlined"
                                                color="info"
                                                style={{
                                                  marginBottom: "1rem",
                                                  marginTop: "10px",
                                                }}
                                                onClick={() => {
                                                  push("");
                                                }}
                                              >
                                                Add Package
                                              </MDButton>
                                            )}
                                        </Grid>
                                      </div>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </div>
                          </div>
                        )}

                        {confirmationMsg && (
                          <MDTypography variant="h5" mt={2}>
                            {(singleLineItem.serialNumber !== ("NA" || null) ||
                              formik.values.printingType ===
                                "Individual Printing") &&
                              confirmationMsg &&
                              `Do you want to print ${singleLineItem.qty} individual labels?`}

                            {((singleLineItem.lotNumber === ("NA" || null) &&
                              singleLineItem.serialNumber === ("NA" || null)) ||
                              (singleLineItem.lotNumber !== ("NA" || null) &&
                                singleLineItem.serialNumber ===
                                  ("NA" || null) &&
                                formik.values.printingType ===
                                  "Package Printing")) &&
                              confirmationMsg &&
                              `Do you want to print ${formik.values.packageArray.length} package labels?`}
                          </MDTypography>
                        )}
                        {confirmationMsg && balance !== 0 && balance !== "" && (
                          <h6 style={{ color: "red" }}>{balanceItemMsg}</h6>
                        )}

                        <div class="clearfix">
                          {!confirmationMsg && singleLineItem !== "" && (
                            <MDButton
                              style={{ marginTop: "10px" }}
                              type="button"
                              onClick={onClickForConfirmation}
                              variant="gradient"
                              color="info"
                              fullWidth
                              // color="info"
                              disabled={
                                (singleLineItem.lotNumber === ("NA" || null) &&
                                  singleLineItem.serialNumber ===
                                    ("NA" || null)) ||
                                (singleLineItem.lotNumber !== ("NA" || null) &&
                                  singleLineItem.serialNumber ===
                                    ("NA" || null) &&
                                  formik.values.printingType ===
                                    "Package Printing")
                                  ? (balance === 0) === true
                                    ? false
                                    : true
                                  : false
                              }
                            >
                              Print Label
                            </MDButton>
                          )}
                          {confirmationMsg && (
                            <span>
                              <MDButton
                                type="button"
                                variant="outlined"
                                color="error"
                                style={{ marginTop: "10px" }}
                                onClick={() => {
                                  setOpenPrintLabelModal(false);
                                  setConfirmationMsg(false);
                                }}
                              >
                                Cancel
                              </MDButton>
                              <MDButton
                                type="submit"
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "40px",
                                }}
                                variant="gradient"
                                color="success"
                                disabled={printButtonName === "Please wait..."}
                              >
                                {printButtonName}
                              </MDButton>
                            </span>
                          )}
                        </div>
                        <MDBox>
                          <Collapse in={isErrorPrintLabel}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorPrintLabel(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessPrintLabel}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessPrintLabel(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              GRN Printed Successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                      </Form>
                    );
                  }}
                </Formik>
              </MDCard>
            </Modal>
            <Modal
              open={createGrnModal}
              onClose={handleCreateGrnModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create GRN
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCreateGrnModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                  // component="form"
                  // role="form"
                  // onSubmit={formik.handleSubmit}
                  >
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmitForLineItems}
                        >
                          {(formik) => (
                            <Form>
                              <div>
                                <MDBox
                                  style={{
                                    position: {
                                      xs: "relative",
                                      lg: "sticky",
                                    },
                                    top: "0",
                                    width: "100%",
                                    zIndex: "999",
                                  }}
                                >
                                  {/* <MDTypography variant="h5">
                                      Header
                                    </MDTypography> */}
                                  {/* <hr style={{ marginBottom: "20px" }} /> */}
                                  <Grid container>
                                    <Grid item xs={12} lg={3}>
                                      <MDBox mb={1} ml={1}>
                                        <MDTypography
                                          variant="h6"
                                          style={{ fontWeight: "500" }}
                                        >
                                          GRN Number
                                        </MDTypography>

                                        <Field
                                          className="form-control"
                                          type="text"
                                          name="grnNumber"
                                          fullWidth
                                          autoComplete="off"
                                          onChange={formik.handleChange}
                                          value={formik.values.grnNumber}
                                          error={
                                            formik.touched.grnNumber &&
                                            formik.errors.grnNumber &&
                                            true
                                          }
                                          inputProps={{
                                            style: {
                                              height: "14px",
                                            },
                                            maxLength: 45,
                                          }}
                                        />

                                        {formik.touched.grnNumber &&
                                          formik.errors.grnNumber && (
                                            <TextError
                                              msg={formik.errors.grnNumber}
                                            />
                                          )}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                      <MDBox mb={1} ml={1}>
                                        <MDTypography
                                          variant="h6"
                                          style={{ fontWeight: "500" }}
                                        >
                                          Vendor Code
                                        </MDTypography>

                                        <Field
                                          className="form-control"
                                          type="text"
                                          name="vendorCode"
                                          fullWidth
                                          autoComplete="off"
                                          onChange={formik.handleChange}
                                          value={formik.values.vendorCode}
                                          error={
                                            formik.touched.vendorCode &&
                                            formik.errors.vendorCode &&
                                            true
                                          }
                                          inputProps={{
                                            style: {
                                              height: "14px",
                                            },
                                            maxLength: 45,
                                          }}
                                        />

                                        {formik.touched.vendorCode &&
                                          formik.errors.vendorCode && (
                                            <TextError
                                              msg={formik.errors.vendorCode}
                                            />
                                          )}
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} lg={3}>
                                      <MDBox ml={1} mb={1}>
                                        <MDTypography
                                          variant="h6"
                                          style={{ fontWeight: "500" }}
                                        >
                                          Vendor Name
                                        </MDTypography>

                                        <Field
                                          className="form-control"
                                          type="text"
                                          name="vendorName"
                                          fullWidth
                                          autoComplete="off"
                                          onChange={formik.handleChange}
                                          value={formik.values.vendorName}
                                          error={
                                            formik.touched.vendorName &&
                                            formik.errors.vendorName &&
                                            true
                                          }
                                          inputProps={{
                                            style: {
                                              height: "14px",
                                            },
                                            maxLength: 45,
                                          }}
                                        />

                                        {formik.touched.vendorName &&
                                          formik.errors.vendorName && (
                                            <TextError
                                              msg={formik.errors.vendorName}
                                            />
                                          )}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                      <MDBox ml={1} mb={1}>
                                        <MDTypography
                                          variant="h6"
                                          style={{ fontWeight: "500" }}
                                        >
                                          ASN Code
                                        </MDTypography>

                                        <Field
                                          className="form-control"
                                          type="text"
                                          name="asnCode"
                                          fullWidth
                                          autoComplete="off"
                                          onChange={formik.handleChange}
                                          value={formik.values.asnCode}
                                          error={
                                            formik.touched.asnCode &&
                                            formik.errors.asnCode &&
                                            true
                                          }
                                          inputProps={{
                                            style: {
                                              height: "14px",
                                            },
                                            maxLength: 45,
                                          }}
                                        />

                                        {formik.touched.asnCode &&
                                          formik.errors.asnCode && (
                                            <TextError
                                              msg={formik.errors.asnCode}
                                            />
                                          )}
                                      </MDBox>
                                    </Grid>
                                  </Grid>
                                </MDBox>

                                <div>
                                  <MDTypography variant="h5">
                                    Line Items
                                  </MDTypography>
                                  <hr style={{ marginBottom: "10px" }} />
                                  <FieldArray name="lineItems">
                                    {(fieldArrayProps) => {
                                      const { push, remove, form } =
                                        fieldArrayProps;
                                      const { values } = form;
                                      const { lineItems } = values;

                                      return (
                                        <div>
                                          {lineItems.map((lineItem, index) => {
                                            return (
                                              <>
                                                <MDTypography
                                                  variant="h6"
                                                  ml={1}
                                                >
                                                  Line Item - {index + 1}
                                                </MDTypography>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                  key={index}
                                                >
                                                  <Grid container>
                                                    <Grid item xs={12} lg={3}>
                                                      <MDBox ml={1} mb={1}>
                                                        <MDTypography
                                                          variant="h6"
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          GRN Line Number
                                                        </MDTypography>

                                                        <Field
                                                          type="number"
                                                          className="form-control"
                                                          name={`lineItems.${index}.grnLineNumber`}
                                                          fullWidth
                                                          autoComplete="off"
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          value={
                                                            formik.values
                                                              .grnLineNumber
                                                          }
                                                          error={
                                                            formik.touched
                                                              .grnLineNumber &&
                                                            formik.errors
                                                              .grnLineNumber &&
                                                            true
                                                          }
                                                          inputProps={{
                                                            style: {
                                                              height: "14px",
                                                            },
                                                            maxLength: 45,
                                                          }}
                                                        />

                                                        {formik.touched
                                                          .grnLineNumber &&
                                                          formik.errors
                                                            .grnLineNumber && (
                                                            <TextError
                                                              msg={
                                                                formik.errors
                                                                  .grnLineNumber
                                                              }
                                                            />
                                                          )}
                                                      </MDBox>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      lg={3}
                                                      mr={7}
                                                    >
                                                      <MDBox ml={1} mb={1}>
                                                        <MDTypography
                                                          variant="h6"
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Item Code/Des
                                                        </MDTypography>
                                                        <Select
                                                          isClearable
                                                          className="select-css-for-itemcode"
                                                          maxMenuHeight={130}
                                                          styles={{
                                                            control: (
                                                              provided
                                                            ) => ({
                                                              ...provided,

                                                              height: "12px",
                                                            }),
                                                          }}
                                                          options={itemCodeOptions.map(
                                                            (option) => ({
                                                              value:
                                                                option.value,
                                                              label: option.key,
                                                            })
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            if (
                                                              selectedOption
                                                            ) {
                                                              const selectedItem =
                                                                itemCodeOptions.find(
                                                                  (option) =>
                                                                    option.value ===
                                                                    selectedOption.value
                                                                );
                                                              if (
                                                                selectedItem
                                                              ) {
                                                                formik.setFieldValue(
                                                                  `lineItems.${index}.itemCodeDes`,
                                                                  selectedOption.value
                                                                );
                                                                formik.setFieldValue(
                                                                  `lineItems.${index}.controlType`,
                                                                  selectedItem.controlType
                                                                );
                                                                formik.setFieldValue(
                                                                  `lineItems.${index}.uom`,
                                                                  selectedItem.uom
                                                                );
                                                              }
                                                            } else {
                                                              // Handle the case when the selection is cleared
                                                              formik.setFieldValue(
                                                                `lineItems.${index}.itemCodeDes`,
                                                                ""
                                                              );
                                                              formik.setFieldValue(
                                                                `lineItems.${index}.controlType`,
                                                                ""
                                                              );
                                                              formik.setFieldValue(
                                                                `lineItems.${index}.uom`,
                                                                ""
                                                              );
                                                            }
                                                          }}
                                                          value={
                                                            formik.values
                                                              .itemCodeDes
                                                          }
                                                          placeholder="Choose one..."
                                                        />
                                                        {formik?.touched[
                                                          `lineItems.${index}.itemCodeDes`
                                                        ] &&
                                                          formik?.errors[
                                                            `lineItems.${index}.itemCodeDes`
                                                          ] && (
                                                            <TextError
                                                              msg={
                                                                formik.errors[
                                                                  `lineItems.${index}.itemCodeDes`
                                                                ]
                                                              }
                                                            />
                                                          )}
                                                      </MDBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={3}>
                                                      <MDBox ml={1} mb={1}>
                                                        <MDTypography
                                                          variant="h6"
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Control Type
                                                        </MDTypography>
                                                        <Field
                                                          disabled
                                                          type="text"
                                                          value={
                                                            formik.values
                                                              .lineItems[index]
                                                              ?.controlType
                                                          }
                                                          className="form-control"
                                                          autocomplete="off"
                                                          name={`lineItems.${index}.controlType`}
                                                          fullWidth
                                                          autoComplete="off"
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          inputProps={{
                                                            style: {
                                                              height: "14px",
                                                            },
                                                            maxLength: 45,
                                                            readOnly: true,
                                                          }}
                                                        />
                                                      </MDBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={2}>
                                                      <MDBox ml={1} mb={1}>
                                                        <MDTypography
                                                          variant="h6"
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          QTY
                                                        </MDTypography>

                                                        <Field
                                                          className="form-control"
                                                          fullWidth
                                                          type="text"
                                                          name={`lineItems.${index}.qty`}
                                                          autoComplete="off"
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          value={
                                                            formik.values
                                                              .lineItems[index]
                                                              .qty
                                                          }
                                                          inputProps={{
                                                            style: {
                                                              height: "14px",
                                                              width: "150px",
                                                              min: 0,
                                                            },
                                                            maxLength: 45,
                                                          }}
                                                        />

                                                        {formik.touched
                                                          .lineItems &&
                                                          formik.touched
                                                            .lineItems[index] &&
                                                          formik.touched
                                                            .lineItems[index]
                                                            .qty &&
                                                          formik.errors
                                                            .lineItems &&
                                                          formik.errors
                                                            .lineItems[index] &&
                                                          formik.errors
                                                            .lineItems[index]
                                                            .qty && (
                                                            <TextError
                                                              msg={
                                                                formik.errors
                                                                  .lineItems[
                                                                  index
                                                                ].qty
                                                              }
                                                            />
                                                          )}
                                                      </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12} lg={3}>
                                                      <MDBox ml={1} mb={1}>
                                                        <MDTypography
                                                          variant="h6"
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          UOM
                                                        </MDTypography>
                                                        <Field
                                                          disabled
                                                          className="form-control"
                                                          type="text"
                                                          name={`lineItems.${index}.uom`}
                                                          value={
                                                            formik.values
                                                              .lineItems[index]
                                                              ?.uom
                                                          }
                                                          fullWidth
                                                          autoComplete="off"
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          error={
                                                            formik.touched
                                                              .uom &&
                                                            formik.errors.uom &&
                                                            true
                                                          }
                                                          inputProps={{
                                                            style: {
                                                              height: "14px",
                                                            },
                                                            maxLength: 45,
                                                            readOnly: true,
                                                          }}
                                                        />

                                                        {formik.touched.uom &&
                                                          formik.errors.uom && (
                                                            <TextError
                                                              msg={
                                                                formik.errors
                                                                  .uom
                                                              }
                                                            />
                                                          )}
                                                      </MDBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={3}>
                                                      <MDBox ml={1} mb={1}>
                                                        <MDTypography
                                                          variant="h6"
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Batch Number
                                                          (Optional)
                                                        </MDTypography>

                                                        <Field
                                                          className="form-control"
                                                          type="text"
                                                          name={`lineItems.${index}.batchNumber`}
                                                          fullWidth
                                                          autoComplete="off"
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          value={
                                                            formik.values
                                                              .batchNumber
                                                          }
                                                          error={
                                                            formik.touched
                                                              .batchNumber &&
                                                            formik.errors
                                                              .batchNumber &&
                                                            true
                                                          }
                                                          inputProps={{
                                                            style: {
                                                              height: "14px",
                                                            },
                                                            maxLength: 45,
                                                          }}
                                                        />

                                                        {formik.touched
                                                          .batchNumber &&
                                                          formik.errors
                                                            .batchNumber && (
                                                            <TextError
                                                              msg={
                                                                formik.errors
                                                                  .batchNumber
                                                              }
                                                            />
                                                          )}
                                                      </MDBox>
                                                    </Grid>

                                                    {formik.values.lineItems[
                                                      index
                                                    ]?.controlType ===
                                                      "Lot Control" && (
                                                      <Grid item xs={12} lg={3}>
                                                        <MDBox ml={1} mb={1}>
                                                          <MDTypography
                                                            variant="h6"
                                                            style={{
                                                              fontWeight: "500",
                                                            }}
                                                          >
                                                            LOT Number
                                                          </MDTypography>

                                                          <Field
                                                            className="form-control"
                                                            type="text"
                                                            name={`lineItems.${index}.lotNumber`}
                                                            fullWidth
                                                            autoComplete="off"
                                                            onChange={
                                                              formik.handleChange
                                                            }
                                                            value={
                                                              formik.values
                                                                .lotNumber
                                                            }
                                                            error={
                                                              formik.touched
                                                                .lotNumber &&
                                                              formik.errors
                                                                .lotNumber &&
                                                              true
                                                            }
                                                            inputProps={{
                                                              style: {
                                                                height: "14px",
                                                              },
                                                              maxLength: 45,
                                                            }}
                                                          />

                                                          {formik.values
                                                            .lineItems[index]
                                                            ?.controlType ===
                                                            2 &&
                                                            formik.values
                                                              .lotNumber ==
                                                              "" && (
                                                              <TextError msg="Please enter lot number" />
                                                            )}
                                                        </MDBox>
                                                      </Grid>
                                                    )}
                                                    {formik.values.lineItems[
                                                      index
                                                    ]?.controlType ===
                                                      "Serial Control" && (
                                                      <Grid item xs={12} lg={3}>
                                                        <MDBox ml={1} mb={1}>
                                                          <MDTypography
                                                            variant="h6"
                                                            style={{
                                                              fontWeight: "500",
                                                            }}
                                                          >
                                                            Serial Number
                                                          </MDTypography>

                                                          <Field
                                                            className="form-control"
                                                            type="text"
                                                            name={`lineItems.${index}.serialNumber`}
                                                            fullWidth
                                                            autoComplete="off"
                                                            onChange={
                                                              formik.handleChange
                                                            }
                                                            value={
                                                              formik.values
                                                                .serialNumber
                                                            }
                                                            error={
                                                              formik.touched
                                                                .serialNumber &&
                                                              formik.errors
                                                                .serialNumber &&
                                                              true
                                                            }
                                                            inputProps={{
                                                              style: {
                                                                height: "14px",
                                                              },
                                                              maxLength: 45,
                                                            }}
                                                          />

                                                          {controlTypeSelected.value ===
                                                            1 &&
                                                            formik.values
                                                              .serialNumber ==
                                                              "" && (
                                                              <TextError msg="Please enter serial number" />
                                                            )}
                                                        </MDBox>
                                                      </Grid>
                                                    )}

                                                    <span
                                                      style={{
                                                        display: "flex",
                                                        marginTop: "1.5rem",
                                                        marginLeft: "2rem",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        {index > 0 && (
                                                          <CancelIcon
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            type="button"
                                                            className="secondary"
                                                            onClick={() =>
                                                              remove(index)
                                                            }
                                                            color="error"
                                                          />
                                                        )}
                                                      </div>
                                                      {index ===
                                                        lineItems.length -
                                                          1 && (
                                                        <div>
                                                          <Tooltip title="Add LineItems">
                                                            <AddCircleIcon
                                                              fontSize="large"
                                                              onClick={() =>
                                                                push({
                                                                  grnLineNumber:
                                                                    "",
                                                                  qty: "",
                                                                  itemCodeDes:
                                                                    "",
                                                                  controlType:
                                                                    "",
                                                                  qty: "",
                                                                  uom: "",
                                                                  batchNumber:
                                                                    "",
                                                                  lotNumber: "",
                                                                  serialNumber:
                                                                    "",
                                                                  asnCode: "",
                                                                })
                                                              }
                                                              color="info"
                                                              style={{
                                                                width: "30px",
                                                                marginLeft:
                                                                  "0rem",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            />
                                                          </Tooltip>
                                                        </div>
                                                      )}
                                                    </span>
                                                  </Grid>
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      );
                                    }}
                                  </FieldArray>
                                </div>
                              </div>
                              <div style={{ background: "white" }}>
                                <MDButton
                                  style={{
                                    position: "sticky",
                                    bottom: "0",
                                  }}
                                  color="info"
                                  fullWidth
                                  type="submit"
                                  disabled={
                                    !formik.values.asnCode ||
                                    formik.values.grnNumber === "" ||
                                    formik.values.vendorName === "" ||
                                    formik.values.vendorCode === "" ||
                                    formik.values.lineItems.some(
                                      (item) =>
                                        item.grnLineNumber === "" ||
                                        item.itemCodeDes === "" ||
                                        item.qty === "" ||
                                        (item.controlType ===
                                          "Serial Control" &&
                                          item.serialNumber === "") ||
                                        (item.controlType === "Lot Control" &&
                                          item.lotNumber === "")
                                    ) ||
                                    buttonName === "Please wait..."
                                  }
                                >
                                  {buttonName}
                                </MDButton>
                              </div>
                              <MDBox>
                                <Collapse in={isErrorCreatedGrn}>
                                  <Alert
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorCreatedGrn(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {errorMsgForCreate}
                                  </Alert>
                                </Collapse>
                                <Collapse in={isSuccessCreatedGrn}>
                                  <Alert
                                    severity="success"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsSuccessCreatedGrn(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    GRN Created Successfully
                                  </Alert>
                                </Collapse>
                              </MDBox>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    allGRN: state.allGRN,
    lineItemsByGrn: state.lineItemsByGrn,

    itemMaster: state.itemMaster,

    getCreatedActivityReducer: state.getCreatedActivityReducer,
    skuForReprint: state.skuForReprint,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAllGRN: (flag, pageNo, rowsPerPage, searchObject, jwtToken) =>
      dispatch(getAllGRN(flag, pageNo, rowsPerPage, searchObject, jwtToken)),

    individualPrint: (payload, jwtToken) =>
      dispatch(individualPrint(payload, jwtToken)),
    packagePrint: (payload, jwtToken) =>
      dispatch(packagePrint(payload, jwtToken)),
    uploadCsv: (payload, jwtToken) => dispatch(uploadCsv(payload, jwtToken)),
    uploadCsvForGrn: (payload, jwtToken) =>
      dispatch(uploadCsvForGrn(payload, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    createGrn: (payload, jwtToken) => dispatch(createGrn(payload, jwtToken)),
    getCreatedActivity: (documentType, jwtToken) =>
      dispatch(getCreatedActivity(documentType, jwtToken)),
    createPickList: (payload, jwtToken) =>
      dispatch(createPickList(payload, jwtToken)),
    getSkuForReprint: (grnNumber, grnLineNumber, jwtToken) =>
      dispatch(getSkuForReprint(grnNumber, grnLineNumber, jwtToken)),
    reprint: (payload, jwtToken) => dispatch(reprint(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Grn);
