/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Dashboard components
import MDBox from "./components/MDBox";

// Dashboard example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Dashboard themes
import theme from "./assets/theme";

// Dashboard Dark Mode themes
import themeDark from "./assets/theme-dark";

// Dashboard contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

import IdleTimerContainer from "utils/IdleTimerContainer";

// JWT and Cookies
import { isExpired } from "react-jwt";
import Cookies from "universal-cookie";

import { getSubModulesByRoleId } from "../src/store";

// Dashboard layouts (includes sub route components)
import Profile from "./layouts/profile";
import Login from "./layouts/authentication/login";
import Logout from "./layouts/authentication/logout";
import ResetPassword from "./layouts/authentication/reset-password";
import SetPassword from "./layouts/authentication/set-password";
import ForgotPassword from "./layouts/authentication/forgot-password";
import Home from "layouts/home";
import ItemMaster from "layouts/item-master";
import Warehouses from "layouts/warehouses";
import RelationLocation from "layouts/relation-Location";
import GrnDetails from "layouts/grn-details";
import ViewLocation from "layouts/view-location";
import Activity from "layouts/activity";
import Picklist from "layouts/picklist";
import UserManagement from "layouts/user-management";
import PrinterConfigurator from "layouts/printer-configurator";
import AssetMaster from "layouts/asset-master";
import BinPalletMasters from "layouts/bin-pallet-masters";
import Otp from "layouts/otp";
import DashboardForm from "layouts/dashboard-form";
import DirectDemand from "layouts/direct-demand";
import ProductionPlan from "layouts/production-plan";
import Status from "layouts/status";
import RegisteredVendors from "layouts/registered-vendors";
import RejectedVendors from "layouts/rejected-vendors";
import VendorDetails from "layouts/vendor-details";
import CategoryCreation from "layouts/category-creation";
import CategoryItemMapping from "layouts/category-item-mapping";
import LocationMapping from "layouts/location-mapping";
import TransferOrder from "layouts/transfer-order";
import AcceptPo from "layouts/accept-po";

import AsnDetails from "layouts/asn-details";
import Quarantine from "layouts/quarantine";
import Dispatch from "layouts/dispatch";
import StandardVendorForm from "layouts/standard-vendor-form";
import Rejections from "layouts/rejections";
import WarehouseOccupancy from "layouts/reports/warehouse-occupancy";
import NoAccessPage from "layouts/authentication/no-access-page";
import WorkOrder from "layouts/work-order";
import WmsStock from "layouts/reports/wms-stock";
import FinishedGoodsParent from "layouts/finished-goods-parent";
import FgMaster from "layouts/fg-master";
import Bom from "layouts/bom";
import PicklistAgeing from "layouts/reports/picklist-ageing";
import AgeingReport from "layouts/reports/ageing-report";
import DocumentTypeTurnAroundTime from "layouts/reports/document-type-turn-around-time";
import ItemStatus from "layouts/reports/item-status";
import BannerLogo from "layouts/banner-logo";
import AssemblyView from "layouts/assembly-view";
import AssemblyMaster from "layouts/assembly-master";
import StationAndItemMapping from "layouts/station-and-item-mapping";
import SfWarehouse from "layouts/sf-warehouse";
import TransactionParent from "layouts/transaction-parent";
import CycleCount from "layouts/cycle-count";
import UnplannedIssue from "layouts/unplanned-issue";
import unplannedIssue from "layouts/unplanned-issue";
import VendorMaterialMappingMaster from "layouts/vendor-material-mapping-master";

import ConsumptionPending from "layouts/consumption-pending";

import PurchaseOrders from "layouts/purchase-orders";

import CreateMaterialRequestParent from "layouts/create-material-request/index";
import AsnGrnDependency from "layouts/reports/asn-grn-dependency";
import MinMaxFailure from "layouts/reports/min-max-failure";
import VendorPerformance from "layouts/reports/vendor-performance";
import Top10 from "layouts/reports/top-10";
import DoctypeUserMapping from "layouts/doctype-user-mapping";

const cookies = new Cookies();

const App = ({ getSubModulesByRoleId, getSubModulesByRoleIdReducer }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Login details from cookies
  let loginDetails = cookies.get("loginDetailsForWMS");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      // bgColor="white"
      // shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      // color="dark"
      // sx={{ cursor: "pointer" }}
      // onClick={handleConfiguratorOpen}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
    </MDBox>
  );

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails ? loginDetails.data.roleId : "";

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  let loading = getSubModulesByRoleIdReducer.loading;

  let warehousesModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "c2ccc512-d196-47f0-aefe-fda53b010b9c"
      )
    : "";

  let grnDetailsModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "7ce93f23-45db-46dd-81fa-ce66954c0fba"
      )
    : "";

  let reprintModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "cb6cabe4-7621-4a3d-8be6-6d43e87befc4"
      )
    : "";

  let materialIssuanceModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "511218db-be4c-44d2-896d-4c832b2d3f6b"
      )
    : "";

  let storeIssuanceModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "4d1bbe7e-e752-48e3-986f-b2ae4745d405"
      )
    : "";

  let pivTrackingModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "60e34942-b6a2-4615-8f0e-f9674fe8d98f"
      )
    : "";

  let pivFillingAndReleasedPivModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "c53dae9d-3fee-4132-92f8-0d17deef918b"
      )
    : "";

  let productionAllocationModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "d8326dc9-1fda-4a5f-aac0-aa17cf8b28a8"
      )
    : "";

  let userManagementModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "a482ffd0-bc1d-41f9-84ee-cc0d32c85cc7"
      )
    : "";

  let activityMappingModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "6557362e-6abb-4b21-a41b-8f9575d1080a"
      )
    : "";

  let purchaseOrderModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "8ebca7b8-e613-46cb-a3d3-c52b739c1327"
      )
    : "";
  let picklistTrackingModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "7abbd2e9-f4c2-4dc7-978d-a057356c0a9e"
      )
    : "";
  let locationMappingModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "37e693e3-a277-40ee-9f07-e4f6029b61be"
      )
    : "";
  let binMasterModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "e92885b3-7efd-48c7-8d73-597a041980d5"
      )
    : "";
  let activityMovementModule = modulesData
    ? modulesData.find(
        (moduleId) =>
          moduleId.parentModuleId == "6c969e95-f230-4ab1-b116-48ced1b1bfba"
      )
    : "";
  let newVendorRequest = modulesData.find(
    (moduleId) => moduleId.moduleId == "d71efda6-caa3-4fb0-b38d-2a4dc5683b70"
  );
  let statusModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "df1b3bed-2f1b-4361-b0b6-3dfa98ad5eb6"
  );

  let registerVModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d0113c1f-d817-4f47-b0da-b77b49992502"
  );

  let rejectedVendorsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "4b1cf524-6f2a-4ebc-a7e6-6f2be06c136a"
  );
  let demandModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "f4e71fdd-d9ea-4bb8-85d6-3fc95cf3c069"
  );
  let acceptPOModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "a4939e7a-10e3-415b-b07d-326cc89d564d"
  );
  let productionPlanModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "8a3103fd-be61-47d3-86a7-00cdb982d36b"
  );
  let assetMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "fd7da1c7-7f06-4e50-837a-f7b6a08bbd3b"
  );
  let itemMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b496ec6f-d8d8-4047-990a-d357587027b9"
  );
  let categoryCreationModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "c86bf7ec-3e40-43e0-8175-562142a85e92"
  );
  let relationLocationModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "a704a09e-b7ad-40a8-97b9-b6146e2acfac"
  );
  let categoryItemModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "cbf5f0c3-01dc-4b05-a697-194a8cdf7c9d"
  );
  let transferOrderModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "3027759c-96b6-4624-abc3-b742e144d40e"
  );
  let asnDetailsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "8ec6366f-d5bd-4938-8900-e7fa44bbcaba"
  );
  let docTypeUserMappingModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "a420789b-29e6-40ba-be62-ec264a05ba60"
  );
  let vendorMaterialMappingModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "0c1bc97f-fd69-4226-a004-9ba49f90c25e"
  );
  let quarantineModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "7c758176-8bf7-46ae-bcf5-0789cc05f29e"
  );
  let dispatchModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "e1b63085-6df6-44d3-8b45-555bb3939d24"
  );
  let transactionModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "0f03568f-3176-4774-8288-1376e2c03e64"
  );
  let cycleCountModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "25db8bb0-ef56-4da4-ab28-434a1b0ca5e3"
  );
  let rejectionModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "a1d0642c-3a34-48e6-8812-339cf73772e0"
  );
  let unplannedIssueModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "72d38894-5a02-4be4-88a0-a02d7c0cb3e1"
  );
  let CreateMaterialRequestParentModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "2d83416d-d760-4139-b3bf-ccb7e0fc8d10"
  );

  // let CreateMaterialRequestParentModule=true

  let sfWarehouseModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "5cb42fad-fdc5-4471-a70e-a9457ecf3d9f"
  );
  let assemblyMasterModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "b98ca81d-04c3-4d8b-bc1f-05c88204e7e4"
  );
  let stationItemMappingModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "b3e0f209-82c1-4527-b764-c57fc990b0e8"
  );
  let assemblyViewModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "934d4f7e-217d-459c-84fd-3f0092863c6a"
  );
  let bomModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "66300d3e-42b2-4bd1-a2cf-93370c23311c"
  );
  let fgTransactionModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "21774308-3b8d-4d29-9af0-39e52148f5e9"
  );
  let fgMasterModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "c203a65b-713e-4133-918c-2fe53860c114"
  );
  let workOrderModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "23b7b11f-0daf-490e-a1be-d16fa1e769ae"
  );
  let warehouseOccupancyModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "48149eb9-a387-4788-8178-4c3323d5f1cd"
  );
  let wmsStockModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "398c502b-4694-48aa-80d5-413d96d50bee"
  );
  let itemStatusModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "1087705d-34b2-4328-9824-9b0a5b9a3aca"
  );
  let picklistAgeingModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "a6e115fe-5ee4-4541-9db2-29237d01b458"
  );
  let ageingReportModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "e29cfb17-bd7d-4cd4-a28d-9b612f949e41"
  );
  let documentTurnAroundModule = modulesData.find(
    (moduleId) =>
      moduleId.parentModuleId == "c34fa3f7-52de-4d7f-a89a-85957629fc4a"
  );
  let asnGrnDependencyModule = modulesData.find(
    (moduleId) => moduleId.parentModuleId == ""
  );

  let minMaxFailureModule = modulesData.find(
    (moduleId) => moduleId.parentModuleId == ""
  );

  let vendorPerformanceModule = modulesData.find(
    (moduleId) => moduleId.parentModuleId == ""
  );

  let top10Module = modulesData.find(
    (moduleId) => moduleId.parentModuleId == ""
  );

  const routes = [
    {
      type: "collapse",
      name: "Home",
      key: "home",
      icon: <Icon fontSize="small">home</Icon>,
      route: "/home",
      component: <Home />,
    },

    warehousesModule
      ? {
          type: "collapse",
          name: "Warehouses",
          key: "warehouses",
          icon: <Icon fontSize="small">store_outlined</Icon>,
          route: "/warehouses",
          component: <Warehouses />,
        }
      : "",
    warehousesModule
      ? {
          type: "collapse",
          name: "Consumption Pending",
          key: "consumptionPending",
          icon: <Icon fontSize="small">pending_actions</Icon>,
          route: "/consumptionPending",
          component: <ConsumptionPending />,
        }
      : "",

    grnDetailsModule
      ? {
          type: "collapse",
          name: "GRN Details",
          key: "grn-details",
          icon: <Icon fontSize="small">summarize</Icon>,
          route: "/grn-details",
          component: <GrnDetails />,
        }
      : "",

    quarantineModule
      ? {
          type: "collapse",
          name: "Quarantine",
          key: "quarantine",
          icon: <Icon fontSize="small">factory</Icon>,
          route: "/quarantine",
          component: <Quarantine />,
        }
      : "",
    rejectionModule
      ? {
          type: "collapse",
          name: "Rejections",
          key: "rejections",
          icon: <Icon fontSize="small">do_not_disturb</Icon>,
          route: "/rejections",
          component: <Rejections />,
        }
      : "",
    dispatchModule
      ? {
          type: "collapse",
          name: "Dispatch",
          key: "dispatch",
          icon: <Icon fontSize="small">local_shipping</Icon>,
          route: "/dispatch",
          component: <Dispatch />,
        }
      : "",
    transactionModule
      ? {
          type: "collapse",
          name: "Transaction",
          key: "transaction",
          icon: <Icon fontSize="small">request_quote</Icon>,
          route: "/transaction",
          component: <TransactionParent />,
        }
      : "",
    cycleCountModule
      ? {
          type: "collapse",
          name: "Cycle Count",
          key: "cycle-count",
          icon: <Icon fontSize="small">assessment</Icon>,
          route: "/cycle-count",
          component: <CycleCount />,
        }
      : "",
    unplannedIssueModule
      ? {
          type: "collapse",
          name: "Unplanned Issue",
          key: "unplanned-issue",
          icon: <Icon fontSize="small">grading</Icon>,
          route: "/unplanned-issue",
          component: <UnplannedIssue />,
        }
      : "",
    // {
    //   type: "collapse",
    //   name: "Material Request",
    //   key: "material-request",
    //   icon: <Icon fontSize="small">summarize</Icon>,
    //   route: "/material-request",
    //   component: <MaterialRequest />,
    // },
    CreateMaterialRequestParentModule
      ? {
          type: "collapse",
          name: "Material Request",
          key: "material-request",
          icon: <Icon fontSize="small">create_new_folder</Icon>,
          route: "/material-request",
          component: <CreateMaterialRequestParent />,
        }
      : "",
    ...(sfWarehouseModule ||
    assemblyMasterModule ||
    stationItemMappingModule ||
    assemblyViewModule ||
    bomModule
      ? [
          {
            type: "expand",
            name: "Shop Floor",
            key: "Shop Floor",
            icon: <Icon fontSize="small">inventory</Icon>,
            subRoutes: [
              ...(sfWarehouseModule
                ? [
                    {
                      type: "collapse",
                      name: "SF Warehouse",
                      key: "sf-warehouse",
                      icon: <Icon fontSize="small">factory</Icon>,
                      route: "/sf-warehouse",
                    },
                  ]
                : []),
              ...(assemblyMasterModule
                ? [
                    {
                      type: "collapse",
                      name: "Assembly Master",
                      key: "assembly-master",
                      icon: <Icon fontSize="small">maps_home_work</Icon>,
                      route: "/assembly-master",
                    },
                  ]
                : []),
              ...(stationItemMappingModule
                ? [
                    {
                      type: "collapse",
                      name: "Station & Item Mapping",
                      key: "station-and-item-mapping",
                      icon: <Icon fontSize="small">grading</Icon>,
                      route: "/station-and-item-mapping",
                    },
                  ]
                : []),
              ...(assemblyViewModule
                ? [
                    {
                      type: "collapse",
                      name: "Assembly View",
                      key: "assembly-view",
                      icon: <Icon fontSize="small">view_module</Icon>,
                      route: "/assembly-view",
                    },
                  ]
                : []),
              ...(bomModule
                ? [
                    {
                      type: "collapse",
                      name: "BOM",
                      key: "bom",
                      icon: <Icon fontSize="small">format_list_numbered</Icon>,
                      route: "/bom",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(fgTransactionModule || fgMasterModule || workOrderModule
      ? [
          {
            type: "expand",
            name: "Finished Goods",
            key: "Finished Goods",
            icon: <Icon fontSize="small">move_to_inbox</Icon>,
            subRoutes: [
              ...(fgTransactionModule
                ? [
                    {
                      type: "collapse",
                      name: "FG Transaction",
                      key: "fg-transaction",
                      icon: <Icon fontSize="small">all_inbox</Icon>,
                      route: "/fg-transaction",
                    },
                  ]
                : []),
              ...(fgMasterModule
                ? [
                    {
                      type: "collapse",
                      name: "FG Master",
                      key: "fg-master",
                      icon: <Icon fontSize="small">location_searching</Icon>,
                      route: "/fg-master",
                    },
                  ]
                : []),
              ...(workOrderModule
                ? [
                    {
                      type: "collapse",
                      name: "Work Order",
                      key: "work-order",
                      icon: <Icon fontSize="small">grading</Icon>,
                      route: "/work-order",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(itemMasterModule ||
    categoryCreationModule ||
    activityMovementModule ||
    assetMasterModule ||
    binMasterModule
      ? [
          {
            type: "expand",
            name: "Master Setup",
            key: "master-setup",
            icon: <Icon fontSize="small">settings</Icon>,
            subRoutes: [
              ...(itemMasterModule
                ? [
                    {
                      type: "collapse",
                      name: "Item Master",
                      key: "item-master",
                      icon: <Icon fontSize="small">description</Icon>,
                      route: "/item-master",
                    },
                  ]
                : []),
              ...(categoryCreationModule
                ? [
                    {
                      type: "collapse",
                      name: "Category Creation",
                      key: "category-creation",
                      icon: <Icon fontSize="small">category</Icon>,
                      route: "/category-creation",
                    },
                  ]
                : []),

              ...(activityMovementModule
                ? [
                    {
                      type: "collapse",
                      name: "Activity",
                      key: "activity",
                      icon: (
                        <Icon fontSize="small">production_quantity_limits</Icon>
                      ),
                      route: "/activity",
                    },
                  ]
                : []),
              ...(assetMasterModule
                ? [
                    {
                      type: "collapse",
                      name: "Asset Master",
                      key: "asset-master",
                      icon: <Icon fontSize="small">web_asset</Icon>,
                      route: "/asset-master",
                    },
                  ]
                : []),
              ...(binMasterModule
                ? [
                    {
                      type: "collapse",
                      name: "Bin/Pallet",
                      key: "bin-pallet",
                      icon: <Icon fontSize="small">dvr</Icon>,
                      route: "/bin-pallet",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(relationLocationModule || categoryItemModule || locationMappingModule
      ? [
          {
            type: "expand",
            name: "Warehouse Setup",
            key: "warehouse-setup",
            icon: <Icon fontSize="small">warehouse_icon</Icon>,
            subRoutes: [
              ...(relationLocationModule
                ? [
                    {
                      type: "collapse",
                      name: "Relational Location",
                      key: "relation-location",
                      icon: <Icon fontSize="small">receipt</Icon>,
                      route: "/relation-location",
                    },
                  ]
                : []),
              ...(docTypeUserMappingModule
                ? [
                    {
                      type: "collapse",
                      name: "DocType User Mapping",
                      key: "doctype-user-mapping",
                      icon: <Icon fontSize="small">u_turn_right</Icon>,
                      route: "/doctype-user-mapping",
                    },
                  ]
                : []),
              ...(categoryItemModule
                ? [
                    {
                      type: "collapse",
                      name: "Category Item Mapping",
                      key: "category-item-mapping",
                      icon: <Icon fontSize="small">class</Icon>,
                      route: "/category-item-mapping",
                    },
                  ]
                : []),
              ...(locationMappingModule
                ? [
                    {
                      type: "collapse",
                      name: "Location Mapping",
                      key: "location-mapping",
                      icon: <Icon fontSize="small">dvr</Icon>,
                      route: "/location-mapping",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(picklistTrackingModule || transferOrderModule
      ? [
          {
            type: "expand",
            name: "Movement",
            key: "movement",
            icon: <Icon fontSize="small">move_to_inbox</Icon>,
            subRoutes: [
              ...(picklistTrackingModule
                ? [
                    {
                      type: "collapse",
                      name: "Picklist Tracking",
                      key: "picklist-tracking",
                      icon: <Icon fontSize="small">location_searching</Icon>,
                      route: "/picklist-tracking",
                    },
                  ]
                : []),
              ...(transferOrderModule
                ? [
                    {
                      type: "collapse",
                      name: "Transfer Order",
                      key: "transfer-order",
                      icon: <Icon fontSize="small">format_list_numbered</Icon>,
                      route: "/transfer-order",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(newVendorRequest ||
    statusModule ||
    registerVModule ||
    rejectedVendorsModule ||
    demandModule ||
    purchaseOrderModule ||
    acceptPOModule ||
    productionPlanModule ||
    asnDetailsModule ||
    vendorMaterialMappingModule
      ? [
          {
            type: "expand",
            name: "Supplier Portal",
            key: "Supplier-portal",
            icon: <Icon fontSize="small">format_list_bulleted_icon</Icon>,
            subRoutes: [
              ...(newVendorRequest
                ? [
                    {
                      type: "collapse",
                      name: "New Vendor Request",
                      key: "new-vendor-request",
                      icon: <Icon fontSize="small">contacts_icon</Icon>,
                      route: "/new-vendor-request",
                    },
                  ]
                : []),
              ...(statusModule
                ? [
                    {
                      type: "collapse",
                      name: "Status",
                      key: "status",
                      icon: <Icon fontSize="small">donut_large_icon</Icon>,
                      route: "/status",
                    },
                  ]
                : []),
              ...(registerVModule
                ? [
                    {
                      type: "collapse",
                      name: "Registered Vendors",
                      key: "registered-vendors",
                      icon: (
                        <Icon fontSize="small">assignment_turned_in_icon</Icon>
                      ),
                      route: "/registered-vendors",
                    },
                  ]
                : []),
              ...(rejectedVendorsModule
                ? [
                    {
                      type: "collapse",
                      name: "Rejected Vendors",
                      key: "rejected-vendors",
                      icon: <Icon fontSize="small">cancel_icon</Icon>,
                      route: "/rejected-vendors",
                    },
                  ]
                : []),
              ...(demandModule
                ? [
                    {
                      type: "collapse",
                      name: "Demand",
                      key: "demand",
                      icon: <Icon fontSize="small">payment</Icon>,
                      route: "/demand",
                    },
                  ]
                : []),
              ...(purchaseOrderModule
                ? [
                    {
                      type: "collapse",
                      name: "Purchase Order",
                      key: "purchase-order",
                      icon: <Icon fontSize="small">request_quote</Icon>,
                      route: "/purchase-order",
                    },
                  ]
                : []),
              ...(acceptPOModule
                ? [
                    {
                      type: "collapse",
                      name: "Accept PO",
                      key: "accept-po",
                      icon: <Icon fontSize="small">account_tree</Icon>,
                      route: "/accept-po",
                    },
                  ]
                : []),
              ...(productionPlanModule
                ? [
                    {
                      type: "collapse",
                      name: "Production Plan",
                      key: "production-plan",
                      icon: <Icon fontSize="small">how_to_reg</Icon>,
                      route: "/production-plan",
                    },
                  ]
                : []),
              ...(asnDetailsModule
                ? [
                    {
                      type: "collapse",
                      name: "ASN details",
                      key: "asn-details",
                      icon: <Icon fontSize="small">account_box</Icon>,
                      route: "/asn-details",
                    },
                  ]
                : []),
              ...(vendorMaterialMappingModule
                ? [
                    {
                      type: "collapse",
                      name: "Vendor Material Mapping",
                      key: "vendor-material-mapping",
                      icon: <Icon fontSize="small">ballot</Icon>,
                      route: "/vendor-material-mapping",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(warehouseOccupancyModule ||
    wmsStockModule ||
    itemStatusModule ||
    picklistAgeingModule ||
    ageingReportModule ||
    documentTurnAroundModule
      ? [
          {
            type: "expand",
            name: "Reports",
            key: "reports",
            icon: <Icon fontSize="small">playlist_add</Icon>,
            subRoutes: [
              ...(warehouseOccupancyModule
                ? [
                    {
                      type: "collapse",
                      name: "Warehouse Occupancy",
                      key: "warehouse-occupancy",
                      icon: (
                        <Icon fontSize="small">insert_drive_file_outlined</Icon>
                      ),
                      route: "/warehouse-occupancy",
                    },
                  ]
                : []),
              ...(wmsStockModule
                ? [
                    {
                      type: "collapse",
                      name: "WMS Stock",
                      key: "wms-stock",
                      icon: <Icon fontSize="small">list_alt</Icon>,
                      route: "/wms-stock",
                    },
                  ]
                : []),
              ...(itemStatusModule
                ? [
                    {
                      type: "collapse",
                      name: "Item Status",
                      key: "item-status",
                      icon: <Icon fontSize="small">label</Icon>,
                      route: "/item-status",
                    },
                  ]
                : []),
              ...(picklistAgeingModule
                ? [
                    {
                      type: "collapse",
                      name: "Picklist Ageing",
                      key: "picklist-ageing",
                      icon: (
                        <Icon fontSize="small">format_indent_decrease</Icon>
                      ),
                      route: "/picklist-ageing",
                    },
                  ]
                : []),
              ...(ageingReportModule
                ? [
                    {
                      type: "collapse",
                      name: "Ageing Report",
                      key: "ageing-report",
                      icon: <Icon fontSize="small">gps_fixed</Icon>,
                      route: "/ageing-report",
                    },
                  ]
                : []),
              ...(documentTurnAroundModule
                ? [
                    {
                      type: "collapse",
                      name: "Doc Type TA Time",
                      key: "document-type-turn-around-time",
                      icon: <Icon fontSize="small">drive_file_move</Icon>,
                      route: "/document-type-turn-around-time",
                    },
                  ]
                : []),

              ...(!asnGrnDependencyModule
                ? [
                    {
                      type: "collapse",
                      name: "Asn Grn Dependency",
                      key: "asn-grn-dependency",
                      icon: <Icon fontSize="small">auto_graph</Icon>,
                      route: "/asn-grn-dependency",
                    },
                  ]
                : []),

              ...(!minMaxFailureModule
                ? [
                    {
                      type: "collapse",
                      name: "Min Max Failure",
                      key: "min-max-failure",
                      icon: <Icon fontSize="small">trending_down</Icon>,
                      route: "/min-max-failure",
                    },
                  ]
                : []),

              ...(!vendorPerformanceModule
                ? [
                    {
                      type: "collapse",
                      name: "Vendor Performance",
                      key: "vendor-performance",
                      icon: <Icon fontSize="small">bar_chart</Icon>,
                      route: "/vendor-performance",
                    },
                  ]
                : []),
              ...(!top10Module
                ? [
                    {
                      type: "collapse",
                      name: "Top 10",
                      key: "top-10",
                      icon: <Icon fontSize="small">align_vertical_top</Icon>,
                      route: "/top-10",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    userManagementModule
      ? {
          type: "collapse",
          name: "User Management",
          key: "user-management",
          icon: <Icon fontSize="small">group</Icon>,
          route: "/user-management",
          component: <UserManagement />,
        }
      : "",

    {
      type: "collapse",
      name: "Logout",
      key: "logout",
      icon: <Icon fontSize="small">lock</Icon>,
      route: "/authentication/logout",
      component: <Logout />,
    },
  ];

  let forgotPasswordData = cookies.get("forgot-password-for-warehouse");
  let verificationData = cookies.get("vendor-portal-jwtToken");
  let token = loginDetails?.jwt;
  const isMyTokenExpired = isExpired(token);

  if (loginDetails && !isMyTokenExpired) {
    if (loginDetails.status) {
      cookies.set("jwtForMobillorEditor", token, { path: "/" });
      return (
        <>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route
                exact
                path="/profile"
                element={<Profile />}
                key="profile"
              />
              <Route
                exact
                path="/banner-logo"
                element={<BannerLogo />}
                key="banner-logo"
              />
              <Route
                exact
                path="/:warehouseId"
                element={warehousesModule && <ViewLocation />}
              />
              <Route
                exact
                path="/authentication/reset-password"
                element={<ResetPassword />}
                key="resetPassword"
              />

              <Route
                exact
                path="/printer-configurator"
                element={<PrinterConfigurator />}
                key="printer-configurator"
              />

              <Route
                exact
                path="/verification"
                element={<Navigate to="/home" />}
                key="verification"
              />

              <Route
                exact
                path="/standard-vendor-form"
                element={<Navigate to="/home" />}
                key="standard-vendor-form"
              />
              <Route
                exact
                path="/fg-master"
                element={<FgMaster />}
                key="fg-master"
              />
              <Route
                exact
                path="/fg-transaction"
                element={<FinishedGoodsParent />}
                key="fg-transaction"
              />
              <Route
                exact
                path="/assembly-master"
                element={<AssemblyMaster />}
                key="assembly-master"
              />
              <Route
                exact
                path="/assembly-view"
                element={<AssemblyView />}
                key="assembly-view"
              />
              <Route
                exact
                path="/sf-warehouse"
                element={<SfWarehouse />}
                key="sf-warehouse"
              />
              <Route
                exact
                path="/station-and-item-mapping"
                element={<StationAndItemMapping />}
                key="station and item mapping"
              />
              <Route exact path="/bom" element={<Bom />} key="bom" />
              <Route
                exact
                path="/work-order"
                element={<WorkOrder />}
                key="work-order"
              />

              <Route
                exact
                path="/status/:vendorUserId"
                element={<DashboardForm />}
                key="DashboardForm"
              />
              <Route
                exact
                path="/item-master"
                element={<ItemMaster />}
                key="item-master"
              />
              <Route
                exact
                path="/asn-details"
                element={<AsnDetails />}
                key="asn-details"
              />
              <Route
                exact
                path="/relation-location"
                element={<RelationLocation />}
                key="relation-location"
              />
              <Route
                exact
                path="/activity"
                element={<Activity />}
                key="activity"
              />
              <Route
                exact
                path="/category-item-mapping"
                element={<CategoryItemMapping />}
                key="category-item-mapping"
              />
              <Route
                exact
                path="/doctype-user-mapping"
                element={<DoctypeUserMapping />}
                key="doctype-user-mapping"
              />

              <Route
                exact
                path="/bin-pallet"
                element={<BinPalletMasters />}
                key="bin-pallet"
              />

              <Route
                exact
                path="/location-mapping"
                element={<LocationMapping />}
                key="location-mapping"
              />
              <Route
                exact
                path="/asset-master"
                element={<AssetMaster />}
                key="asset-master"
              />
              <Route
                exact
                path="/category-creation"
                element={<CategoryCreation />}
                key="category-creation"
              />

              <Route
                exact
                path="/picklist-tracking"
                element={<Picklist />}
                key="picklist-tracking"
              />
              <Route
                exact
                path="/transfer-order"
                element={<TransferOrder />}
                key="transfer-order"
              />
              <Route
                exact
                path="/vendor-material-mapping"
                element={<VendorMaterialMappingMaster />}
                key="vendor-material-mapping"
              />
              {/* <Route
              exact
              path="/vandor-form"
              element={<VendorForm />}
              key="VendorForm"
            /> */}
              <Route
                exact
                path="/rejected-vendors/:vendorUserId"
                element={<DashboardForm />}
                key="DashboardForm"
              />
              <Route
                exact
                path="/registered-vendors/:vendorUserId"
                element={<DashboardForm />}
                key="DashboardForm"
              />
              <Route
                exact
                path="/demand"
                element={<DirectDemand />}
                key="demand"
              />
              <Route
                exact
                path="/purchase-order"
                element={<PurchaseOrders />}
                key="purchase-order"
              />
              <Route
                exact
                path="/accept-po"
                element={<AcceptPo />}
                key="accept"
              />
              <Route
                exact
                path="/production-plan"
                element={<ProductionPlan />}
                key="production-plan"
              />

              <Route
                exact
                path="/new-vendor-request"
                element={<VendorDetails />}
                key="new-vendor-request"
              />
              <Route exact path="/status" element={<Status />} key="status" />
              <Route
                exact
                path="/registered-vendors"
                element={<RegisteredVendors />}
                key="registered-vendors"
              />
              <Route
                exact
                path="/rejected-vendors"
                element={<RejectedVendors />}
                key="rejected-vendors"
              />
              <Route
                exact
                path="/warehouse-occupancy"
                element={<WarehouseOccupancy />}
                key="warehouse-occupancy"
              />
              <Route
                exact
                path="/wms-stock"
                element={<WmsStock />}
                key="wms-stock"
              />
              <Route
                exact
                path="/picklist-ageing"
                element={<PicklistAgeing />}
                key="picklist-ageing"
              />
              <Route
                exact
                path="/document-type-turn-around-time"
                element={<DocumentTypeTurnAroundTime />}
                key="document-type-turn-around-time"
              />
              <Route
                exact
                path="/ageing-report"
                element={<AgeingReport />}
                key="ageing-report"
              />
              <Route
                exact
                path="/asn-grn-dependency"
                element={<AsnGrnDependency />}
                key="asn-grn-dependency"
              />
              <Route
                exact
                path="/min-max-failure"
                element={<MinMaxFailure />}
                key="min-max-failure"
              />
              <Route
                exact
                path="/vendor-performance"
                element={<VendorPerformance />}
                key="vendor-performance"
              />
              <Route exact path="/top-10" element={<Top10 />} key="top-10" />
              <Route
                exact
                path="/item-status"
                element={<ItemStatus />}
                key="item-status"
              />
            </Routes>
            <IdleTimerContainer />
          </ThemeProvider>
        </>
      );
    } else {
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/authentication/logout"
              element={<Logout />}
              key="logout"
            />
            {/* <Route
            path="/grn-details"
            element={<Navigate to="/authentication/logout" replace />}
            /> */}
          </Routes>
        </ThemeProvider>
      );
    }
  } else if (forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />

          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else if (verificationData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/verification"
            element={<Otp />}
            key="verification"
          />
          <Route
            exact
            path="/standard-vendor-form"
            element={<StandardVendorForm />}
            key="standard-vendor-form"
          />
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route
            exact
            path="/authentication/forgot-password/:email"
            element={<ForgotPassword />}
            key="forgotPassword"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/verification"
            element={<Otp />}
            key="verification"
          />
          <Route
            exact
            path="/standard-vendor-form"
            element={<NoAccessPage />}
            key="standard-vendor-form"
          />

          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route
            exact
            path="/authentication/forgot-password/:email"
            element={<ForgotPassword />}
            key="forgotPassword"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    // getModulesByRoleIdReducer: state.getModulesByRoleIdReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    // getModulesByRoleId: (roleId, jwtToken) =>
    //   dispatch(getModulesByRoleId(roleId, jwtToken)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(App);
