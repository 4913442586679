/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

import { getSubModulesByRoleId } from "../../../store";

import Cookies from "universal-cookie";

// import PicklistTracking from "./picklist-tracking";
// import GroupedPicklistTracking from "./grouped-picklist";
import Daily from "./daily";
import Monthly from "./monthly";

const cookies = new Cookies();

const VendorPerformance = ({ getSubModulesByRoleIdReducer, getSubModulesByRoleId }) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  // let picklistTrackingModule = modulesData.find(
  //   (moduleId) => moduleId.moduleId == "281193f6-e2a6-42d1-9c4f-69c69038ac90"
  // );
  let picklistTrackingModule=true

  // let groupedpicklistModule = modulesData.find(
  //   (moduleId) => moduleId.moduleId == "4bd208bf-c862-4ac4-af3f-4f5b512044d8"
  // );

  let groupedpicklistModule=true

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* {picklistTrackingModule && groupedpicklistModule ? ( */}
              
              <TabView>
                <TabPanel header="Daily">
                  <Daily />
                </TabPanel>

                <TabPanel header="Monthly">
                  <Monthly/>
                </TabPanel>
              </TabView>

            {/* ) : !picklistTrackingModule && groupedpicklistModule ? (
             
             <TabView>
                <TabPanel header="Grouped By Picklist">
                  <GroupedPicklistTracking />
                </TabPanel>
              </TabView>

            ) : picklistTrackingModule && !groupedpicklistModule ? (
              
              <TabView>
                <TabPanel header="Picklist Tracking">
                  <PicklistTracking />
                </TabPanel>
              </TabView>
              
            ) : (
              <>
                <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                  No PickList Access
                </h4>
              </>
            )} */}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(VendorPerformance);
