import {
  CREATE_ASSEMBLY_FAILURE,
  CREATE_ASSEMBLY_REQUEST,
  CREATE_ASSEMBLY_SUCCESS,
  CREATE_ASSEMBLYSTATIONS_FAILURE,
  CREATE_ASSEMBLYSTATIONS_REQUEST,
  CREATE_ASSEMBLYSTATIONS_SUCCESS,
  DELETE_ASSEMBLY_FAILURE,
  DELETE_ASSEMBLY_REQUEST,
  DELETE_ASSEMBLY_SUCCESS,
  EDIT_ASSEMBLY_FAILURE,
  EDIT_ASSEMBLY_REQUEST,
  EDIT_ASSEMBLY_SUCCESS,
  GET_ASSEMBLY_MASTER_FAILURE,
  GET_ASSEMBLY_MASTER_REQUEST,
  GET_ASSEMBLY_MASTER_SUCCESS,
  GET_ASSEMBLY_TYPES_FAILURE,
  GET_ASSEMBLY_TYPES_REQUEST,
  GET_ASSEMBLY_TYPES_SUCCESS,
  GET_UNMAPPED_STATIONS_FAILURE,
  GET_UNMAPPED_STATIONS_REQUEST,
  GET_UNMAPPED_STATIONS_SUCCESS,
  MAP_ASSEMBLY_FAILURE,
  MAP_ASSEMBLY_REQUEST,
  MAP_ASSEMBLY_SUCCESS,
} from "./assemblyMasterTypes";

import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getAssemblyMasterRequest = () => {
  return {
    type: GET_ASSEMBLY_MASTER_REQUEST,
  };
};

const getAssemblyMasterSuccess = (payload) => {
  return {
    type: GET_ASSEMBLY_MASTER_SUCCESS,
    payload: payload,
  };
};

const getAssemblyMasterFailure = (error) => {
  return {
    type: GET_ASSEMBLY_MASTER_FAILURE,
    payload: error,
  };
};

const getAssemblyMaster = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssemblyMasterRequest());
    let url = `${baseUrl}/assembly/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssemblyMasterSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssemblyMasterFailure(err.message));
      });
  };
};

const getAssemblyMasterWithPaginationRequest = () => {
  return {
    type: GET_ASSEMBLY_MASTER_REQUEST,
  };
};

const getAssemblyMasterWithPaginationSuccess = (payload) => {
  return {
    type: GET_ASSEMBLY_MASTER_SUCCESS,
    payload: payload,
  };
};

const getAssemblyMasterWithPaginationFailure = (error) => {
  return {
    type: GET_ASSEMBLY_MASTER_FAILURE,
    payload: error,
  };
};

const getAssemblyMasterWithPagination = (
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssemblyMasterWithPaginationRequest());
    let url = `${baseUrl}/assembly/get_all_with_pagination?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssemblyMasterWithPaginationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssemblyMasterWithPaginationFailure(err.message));
      });
  };
};
const getUnmappedStationsRequest = () => {
  return {
    type: GET_UNMAPPED_STATIONS_REQUEST,
  };
};

const getUnmappedStationsSuccess = (payload) => {
  return {
    type: GET_UNMAPPED_STATIONS_SUCCESS,
    payload: payload,
  };
};

const getUnmappedStationsFailure = (error) => {
  return {
    type: GET_UNMAPPED_STATIONS_FAILURE,
    payload: error,
  };
};

const getUnmappedStations = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUnmappedStationsRequest());
    let url = `${baseUrl}/station/get_unmapped`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnmappedStationsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnmappedStationsFailure(err.message));
      });
  };
};

const deleteAssemblyRequest = () => {
  return {
    type: DELETE_ASSEMBLY_REQUEST,
  };
};

const deleteAssemblySuccess = (payload) => {
  return {
    type: DELETE_ASSEMBLY_SUCCESS,
    payload: payload,
  };
};

const deleteAssemblyFaliure = (error) => {
  return {
    type: DELETE_ASSEMBLY_FAILURE,
    payload: error,
  };
};

const deleteAssembly = (assemblyId, userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteAssemblyRequest());
    let url = `${baseUrl}/assembly/delete?assemblyId=${assemblyId}&userId=${userId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteAssemblySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteAssemblyFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editAssemblyRequest = () => {
  return {
    type: EDIT_ASSEMBLY_REQUEST,
  };
};

const editAssemblySuccess = (payload) => {
  return {
    type: EDIT_ASSEMBLY_SUCCESS,
    payload: payload,
  };
};

const editAssemblyFaliure = (error) => {
  return {
    type: EDIT_ASSEMBLY_FAILURE,
    payload: error,
  };
};

const editAssembly = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editAssemblyRequest());
    let url = `${baseUrl}/assembly/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editAssemblySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editAssemblyFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getAssemblyTypesRequest = () => {
  return {
    type: GET_ASSEMBLY_TYPES_REQUEST,
  };
};

const getAssemblyTypesSuccess = (payload) => {
  return {
    type: GET_ASSEMBLY_TYPES_SUCCESS,
    payload: payload,
  };
};

const getAssemblyTypesFailure = (error) => {
  return {
    type: GET_ASSEMBLY_TYPES_FAILURE,
    payload: error,
  };
};

const getAssemblyTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssemblyTypesRequest());
    let url = `${baseUrl}/assembly_type/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssemblyTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssemblyTypesFailure(err.message));
      });
  };
};

const createAssemblyMasterRequest = () => {
  return {
    type: CREATE_ASSEMBLY_REQUEST,
  };
};

const createAssemblyMasterSuccess = (payload) => {
  return {
    type: CREATE_ASSEMBLY_SUCCESS,
    payload: payload,
  };
};

const createAssemblyMasterFailure = (error) => {
  return {
    type: CREATE_ASSEMBLY_FAILURE,
    payload: error,
  };
};

const createAssemblyMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createAssemblyMasterRequest());
    let url = `${baseUrl}/assembly/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createAssemblyMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createAssemblyMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const mapAssemblyRequest = () => {
  return {
    type: MAP_ASSEMBLY_REQUEST,
  };
};

const mapAssemblySuccess = (payload) => {
  return {
    type: MAP_ASSEMBLY_SUCCESS,
    payload: payload,
  };
};

const mapAssemblyFailure = (error) => {
  return {
    type: MAP_ASSEMBLY_FAILURE,
    payload: error,
  };
};

const mapAssembly = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(mapAssemblyRequest());
    let url = `${baseUrl}/assembly/map_station`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(mapAssemblySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(mapAssemblyFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createAssemblyStationMasterRequest = () => {
  return {
    type: CREATE_ASSEMBLYSTATIONS_REQUEST,
  };
};

const createAssemblyStationMasterSuccess = (payload) => {
  return {
    type: CREATE_ASSEMBLYSTATIONS_SUCCESS,
    payload: payload,
  };
};

const createAssemblyStationMasterFailure = (error) => {
  return {
    type: CREATE_ASSEMBLYSTATIONS_FAILURE,
    payload: error,
  };
};

const createAssemblyStationMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createAssemblyStationMasterRequest());
    let url = `${baseUrl}/assembly/add_stations`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createAssemblyStationMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createAssemblyStationMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getAssemblyMaster,
  getAssemblyMasterWithPagination,
  deleteAssembly,
  editAssembly,
  getAssemblyTypes,
  createAssemblyMaster,
  createAssemblyStationMaster,
  getUnmappedStations,
  mapAssembly,
};
