export const GET_PURCHASE_ORDER_REQUEST = "GET_PURCHASE_ORDER_REQUEST";
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS";
export const GET_PURCHASE_ORDER_FAILURE = "GET_PURCHASE_ORDER_FAILURE";

export const CREATE_PURCHASE_ORDER_REQUEST = "CREATE_PURCHASE_ORDER_REQUEST";
export const CREATE_PURCHASE_ORDER_SUCCESS = "CREATE_PURCHASE_ORDER_SUCCESS";
export const CREATE_PURCHASE_ORDER_FAILURE = "CREATE_PURCHASE_ORDER_FAILURE";

export const EDIT_REQ_QTY_REQUEST = "EDIT_REQ_QTY_REQUEST";
export const EDIT_REQ_QTY_SUCCESS = "EDIT_REQ_QTY_SUCCESS";
export const EDIT_REQ_QTY_FAILURE = "EDIT_REQ_QTY_FAILURE";

export const DELETE_PURCHASE_ORDER_REQUEST = "DELETE_PURCHASE_ORDER_REQUEST";
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS";
export const DELETE_PURCHASE_ORDER_FAILURE = "DELETE_PURCHASE_ORDER_FAILURE";

export const UPLOAD_EXCEL_FOR_PURCHASE_ORDER_REQUEST =
  "UPLOAD_EXCEL_FOR_PURCHASE_ORDER_REQUEST";
export const UPLOAD_EXCEL_FOR_PURCHASE_ORDER_SUCCESS =
  "UPLOAD_EXCEL_FOR_PURCHASE_ORDER_SUCCESS";
export const UPLOAD_EXCEL_FOR_PURCHASE_ORDER_FAILURE =
  "UPLOAD_EXCEL_FOR_PURCHASE_ORDER_FAILURE";

export const PUBLISH_PURCHASE_ORDER_REQUEST = "PUBLISH_PURCHASE_ORDER_REQUEST";
export const PUBLISH_PURCHASE_ORDER_SUCCESS = "PUBLISH_PURCHASE_ORDER_SUCCESS";
export const PUBLISH_PURCHASE_ORDER_FAILURE = "PUBLISH_PURCHASE_ORDER_FAILURE";

export const GET_LISTOFITEMS_REQUEST = "GET_LISTOFITEMS_REQUEST";
export const GET_LISTOFITEMS_SUCCESS = "GET_LISTOFITEMS_SUCCESS";
export const GET_LISTOFITEMS_FAILURE = "GET_LISTOFITEMS_FAILURE";

export const GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST =
  "GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST";
export const GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS =
  "GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS";
export const GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE =
  "GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE";

export const UPDATE_ACCEPTPO_REQUEST = "UPDATE_ACCEPTPO_REQUEST";
export const UPDATE_ACCEPTPO_SUCCESS = "UPDATE_ACCEPTPO_SUCCESS";
export const UPDATE_ACCEPTPO_FAILURE = "UPDATE_ACCEPTPO_FAILURE";

export const GET_VENDOR_CODE_REQUEST = "GET_VENDOR_CODE_REQUEST";
export const GET_VENDOR_CODE_SUCCESS = "GET_VENDOR_CODE_SUCCESS";
export const GET_VENDOR_CODE_FAILURE = "GET_VENDOR_CODE_FAILURE";

export const GET_RELEASED_PURCHASE_ORDERS_REQUEST =
  "GET_RELEASED_PURCHASE_ORDERS_REQUEST";
export const GET_RELEASED_PURCHASE_ORDERS_SUCCESS =
  "GET_RELEASED_PURCHASE_ORDERS_SUCCESS";
export const GET_RELEASED_PURCHASE_ORDERS_FAILURE =
  "GET_RELEASED_PURCHASE_ORDERS_FAILURE";

export const GET_ACCEPTED_PURCHASE_ORDERS_REQUEST =
  "GET_ACCEPTED_PURCHASE_ORDERS_REQUEST";
export const GET_ACCEPTED_PURCHASE_ORDERS_SUCCESS =
  "GET_ACCEPTED_PURCHASE_ORDERS_SUCCESS";
export const GET_ACCEPTED_PURCHASE_ORDERS_FAILURE =
  "GET_ACCEPTED_PURCHASE_ORDERS_FAILURE";

export const PUBLISH_PO_REQUEST = "PUBLISH_PO_REQUEST";
export const PUBLISH_PO_SUCCESS = "PUBLISH_PO_SUCCESS";
export const PUBLISH_PO_FAILURE = "PUBLISH_PO_FAILURE";

export const GET_DELIVERY_PLANT_FOR_PO_REQUEST =
  "GET_DELIVERY_PLANT_FOR_PO_REQUEST";
export const GET_DELIVERY_PLANT_FOR_PO_SUCCESS =
  "GET_DELIVERY_PLANT_FOR_PO_SUCCESS";
export const GET_DELIVERY_PLANT_FOR_PO_FAILURE =
  "GET_DELIVERY_PLANT_FOR_PO_FAILURE";

export const GET_UNLOADING_POINT_FOR_PO_REQUEST =
  "GET_UNLOADING_POINT_FOR_PO_REQUEST";
export const GET_UNLOADING_POINT_FOR_PO_SUCCESS =
  "GET_UNLOADING_POINT_FOR_PO_SUCCESS";
export const GET_UNLOADING_POINT_FOR_PO_FAILURE =
  "GET_UNLOADING_POINT_FOR_PO_FAILURE";
