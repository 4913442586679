/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";

import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";

// Functions from store
import { getSubModulesByRoleId, getComponents } from "../../../store";

// Cookies
import Cookies from "universal-cookie";

import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const Components = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getComponents,
  componentsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getComponents(jwtToken);
  }, []);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let componentsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "99dfc411-81a6-4609-942c-d1a0aee2cc79"
  );

  let viewAccess = componentsModule
    ? componentsModule.actionId.includes("1d97c139-638e-4d1b-a395-b32e731f4287")
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Component", accessor: "componentName" },
      { Header: "Modules", accessor: "moduleNames" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let tempComponents = [];

    let data = componentsReducer.components.data
      ? componentsReducer.components.data
      : [];
    setLoading(componentsReducer.loading);

    data.map((component) => {
      const compObj = {
        componentName: component.componentName,
        moduleNames: component.moduleNames,
      };
      tempComponents.push(compObj);
    });
    setState({ ...state, rows: tempComponents });
  }, [componentsReducer]);

  return (
    <MDBox mt={-8}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox>
            {!loading ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  marginBottom: "100px",
                }}
              >
                <CircularProgress color="info" />
              </div>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    componentsReducer: state.getComponentsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getComponents: (jwtToken) => dispatch(getComponents(jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Components);
