import {
  GET_CREATEMR_REQUEST,
  GET_CREATEMR_SUCCESS,
  GET_CREATEMR_FAILURE,
  GET_MATERIALREQUEST_REQUEST,
  GET_MATERIALREQUEST_SUCCESS,
  GET_MATERIALREQUEST_FAILURE,
  GET_MATERIALREQUESTUSERS_REQUEST,
  GET_MATERIALREQUESTUSERS_SUCCESS,
  GET_MATERIALREQUESTUSERS_FAILURE,
  GET_MATERIALREQUEST_CCUSAGE_REQUEST,
  GET_MATERIALREQUEST_CCUSAGE_SUCCESS,
  GET_MATERIALREQUEST_CCUSAGE_FAILURE,
  GET_MATERIALREQUEST_ACUSAGE_REQUEST,
  GET_MATERIALREQUEST_ACUSAGE_SUCCESS,
  GET_MATERIALREQUEST_ACUSAGE_FAILURE,
  CREATE_MATERIALREQUEST_REQUEST,
  CREATE_MATERIALREQUEST_SUCCESS,
  CREATE_MATERIALREQUEST_FAILURE,
  CREATE_MR_REQUEST,
  CREATE_MR_SUCCESS,
  CREATE_MR_FAILURE,
  EDIT_QTY_FOR_CREATE_MR_REQUEST,
  EDIT_QTY_FOR_CREATE_MR_SUCCESS,
  EDIT_QTY_FOR_CREATE_MR_FAILURE,
  GET_APPROVED_REQUESTS_REQUEST,
  GET_APPROVED_REQUESTS_SUCCESS,
  GET_APPROVED_REQUESTS_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_DOWNLOAD_APPROVED_REQUEST,
  GET_DOWNLOAD_APPROVED_SUCCESS,
  GET_DOWNLOAD_APPROVED_FAILURE,
  POST_SKU_REQUEST,
  POST_SKU_SUCCESS,
  POST_SKU_FAILURE,
  CREATE_DISAPPROVAL_REQUEST,
  CREATE_DISAPPROVAL_SUCCESS,
  CREATE_DISAPPROVAL_FAILURE,
  POST_PENDING_REQUEST,
  POST_PENDING_SUCCESS,
  POST_PENDING_FAILURE,
  GET_APPROVED_MATERIAL_REQUEST,
  GET_APPROVED_MATERIAL_SUCCESS,
  GET_APPROVED_MATERIAL_FAILURE,
  CREATE_PICKLIST_FORAPPROVED_REQUEST,
  CREATE_PICKLIST_FORAPPROVED_SUCCESS,
  CREATE_PICKLIST_FORAPPROVED_FAILURE,
  GET_MATERIALREQUEST_BY_DOC_REQUEST,
  GET_MATERIALREQUEST_BY_DOC_SUCCESS,
  GET_MATERIALREQUEST_BY_DOC_FAILURE,
} from "./materialRequestTypes";
import axios from "axios";
import Config from "../../config/index";
// import { CREATE_MR_FAILURE, CREATE_MR_REQUEST, CREATE_MR_SUCCESS } from "./materialRequestTypes";
let baseUrl = Config.baseUrl;

const getCreateMRRequest = () => {
  return {
    type: GET_CREATEMR_REQUEST,
  };
};

const getCreateMRSuccess = (payload) => {
  return {
    type: GET_CREATEMR_SUCCESS,
    payload: payload,
  };
};

const getCreateMRFailure = (error) => {
  return {
    type: GET_CREATEMR_FAILURE,
    payload: error,
  };
};

const getCreateMR = (warehouseId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCreateMRRequest());
    let url = `${baseUrl}/mr/get_pending_mr`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCreateMRSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getCreateMRFailure(err.message));
      });
  };
};

const getSuidsByDocumentNoForApproveRequest = () => {
  return {
    type: GET_MATERIALREQUEST_BY_DOC_REQUEST,
  };
};

const getSuidsByDocumentNoForApproveSuccess = (payload) => {
  return {
    type: GET_MATERIALREQUEST_BY_DOC_SUCCESS,
    payload: payload,
  };
};

const getSuidsByDocumentNoForApproveFailure = (error) => {
  return {
    type: GET_MATERIALREQUEST_BY_DOC_FAILURE,
    payload: error,
  };
};

const getSuidsByDocumentNoForApprove = (documentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSuidsByDocumentNoForApproveRequest());
    let url = `${baseUrl}/mr_requestget_suids_by_doc?documentNumber=${documentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSuidsByDocumentNoForApproveSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSuidsByDocumentNoForApproveFailure(err.message));
      });
  };
};
const getApprovedMaterialRequestRequest = () => {
  return {
    type: GET_APPROVED_MATERIAL_REQUEST,
  };
};

const getApprovedMaterialRequestSuccess = (payload) => {
  return {
    type: GET_APPROVED_MATERIAL_SUCCESS,
    payload: payload,
  };
};

const getApprovedMaterialRequestFailure = (error) => {
  return {
    type: GET_APPROVED_MATERIAL_FAILURE,
    payload: error,
  };
};

const getApprovedMaterialRequest = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getApprovedMaterialRequestRequest());
    let url = `${baseUrl}/mr_request/get_grouped`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getApprovedMaterialRequestSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getApprovedMaterialRequestFailure(err.message));
      });
  };
};

const createMRRequest = () => {
  return {
    type: CREATE_MR_REQUEST,
  };
};

const createMRSuccess = (payload) => {
  return {
    type: CREATE_MR_SUCCESS,
    payload: payload,
  };
};

const createMRFailure = (error) => {
  return {
    type: CREATE_MR_FAILURE,
    payload: error,
  };
};

const createMR = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createMRRequest());
    let url = `${baseUrl}/mr/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createMRSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createMRFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

const createPicklistForPendingRequest = () => {
  return {
    type: POST_PENDING_REQUEST,
  };
};

const createPicklistForPendingSuccess = (payload) => {
  return {
    type: POST_PENDING_SUCCESS,
    payload: payload,
  };
};

const createPicklistForPendingFailure = (error) => {
  return {
    type: POST_PENDING_FAILURE,
    payload: error,
  };
};

const createPicklistForPending = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPicklistForPendingRequest());
    let url = `${baseUrl}/mr/approve_mr`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPicklistForPendingSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPicklistForPendingFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

const getMaterialRequestRequest = () => {
  return {
    type: GET_MATERIALREQUEST_REQUEST,
  };
};

const getMaterialRequestSuccess = (payload) => {
  return {
    type: GET_MATERIALREQUEST_SUCCESS,
    payload: payload,
  };
};

const getMaterialRequestFailure = (error) => {
  return {
    type: GET_MATERIALREQUEST_FAILURE,
    payload: error,
  };
};

const getMaterialRequest = ( jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMaterialRequestRequest());
    let url = `${baseUrl}/mr/get_all_mr`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMaterialRequestSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getMaterialRequestFailure(err.message));
      });
  };
};

const getMaterialRequestUsersRequest = () => {
  return {
    type: GET_MATERIALREQUESTUSERS_REQUEST,
  };
};

const getMaterialRequestUsersSuccess = (payload) => {
  return {
    type: GET_MATERIALREQUESTUSERS_SUCCESS,
    payload: payload,
  };
};

const getMaterialRequestUsersFailure = (error) => {
  return {
    type: GET_MATERIALREQUESTUSERS_FAILURE,
    payload: error,
  };
};

const getMaterialRequestUsers = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMaterialRequestUsersRequest());
    let url = `${baseUrl}/mr/user`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMaterialRequestUsersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getMaterialRequestUsersFailure(err.message));
      });
  };
};

const getMaterialRequestCCUsageRequest = () => {
  return {
    type: GET_MATERIALREQUEST_CCUSAGE_REQUEST,
  };
};

const getMaterialRequestCCUsageSuccess = (payload) => {
  return {
    type: GET_MATERIALREQUEST_CCUSAGE_SUCCESS,
    payload: payload,
  };
};

const getMaterialRequestCCUsageFailure = (error) => {
  return {
    type: GET_MATERIALREQUEST_CCUSAGE_FAILURE,
    payload: error,
  };
};

const getMaterialRequestCCUsage = (acUsage, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMaterialRequestCCUsageRequest());
    let url = `${baseUrl}/mr/cc_usage?ac_usage=${acUsage}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMaterialRequestCCUsageSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getMaterialRequestCCUsageFailure(err.message));
      });
  };
};

const getMaterialRequestACUsageRequest = () => {
  return {
    type: GET_MATERIALREQUEST_ACUSAGE_REQUEST,
  };
};

const getMaterialRequestACUsageSuccess = (payload) => {
  return {
    type: GET_MATERIALREQUEST_ACUSAGE_SUCCESS,
    payload: payload,
  };
};

const getMaterialRequestACUsageFailure = (error) => {
  return {
    type: GET_MATERIALREQUEST_ACUSAGE_FAILURE,
    payload: error,
  };
};

const getMaterialRequestACUsage = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMaterialRequestACUsageRequest());
    let url = `${baseUrl}/mr/ac_usage`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMaterialRequestACUsageSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getMaterialRequestACUsageFailure(err.message));
      });
  };
};

const createMaterialRequestRequest = () => {
  return {
    type: CREATE_MATERIALREQUEST_REQUEST,
  };
};

const createMaterialRequestSuccess = (payload) => {
  return {
    type: CREATE_MATERIALREQUEST_SUCCESS,
    payload: payload,
  };
};

const createMaterialRequestFaliure = (error) => {
  return {
    type: CREATE_MATERIALREQUEST_FAILURE,
    payload: error,
  };
};

const createMaterialRequest = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createMaterialRequestRequest());
    let url = `${baseUrl}/mr/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createMaterialRequestSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(createMaterialRequestFaliure(err.message));
        return err.message;
      });
  };
};

// const getPickupItemRequest = () => {
//   return {
//     type: GET_PICKUPITEM_REQUEST,
//   };
// };

// const getPickupItemSuccess = (payload) => {
//   return {
//     type: GET_PICKUPITEM_SUCCESS,
//     payload: payload,
//   };
// };

// const getPickupItemFailure = (error) => {
//   return {
//     type: GET_PICKUPITEM_FAILURE,
//     payload: error,
//   };
// };

// const getPickupItem = (pickupCode) => {
//   return (dispatch) => {
//     dispatch(getPickupItemRequest());
//     let url = `${baseUrl}/production/pickup_item_dashboard?pickup_code=${pickupCode}`;
//     axios
//       .get(url)
//       .then((response) => {
//         dispatch(getPickupItemSuccess(response.data.data));
//       })
//       .catch((err) => {
//         dispatch(getPickupItemFailure(err.message));
//       });
//   };
// };

const editQtyForCreateMRRequest = () => {
  return {
    type: EDIT_QTY_FOR_CREATE_MR_REQUEST,
  };
};

const editQtyForCreateMRSuccess = (payload) => {
  return {
    type: EDIT_QTY_FOR_CREATE_MR_SUCCESS,
    payload: payload,
  };
};

const editQtyForCreateMRFailure = (error) => {
  return {
    type: EDIT_QTY_FOR_CREATE_MR_FAILURE,
    payload: error,
  };
};

const editQtyForCreateMR = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editQtyForCreateMRRequest());
    let url = `${baseUrl}/mr/edit_final_qty`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editQtyForCreateMRSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editQtyForCreateMRFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getApprovedRequestsRequest = () => {
  return {
    type: GET_APPROVED_REQUESTS_REQUEST,
  };
};

const getApprovedRequestsSuccess = (payload) => {
  return {
    type: GET_APPROVED_REQUESTS_SUCCESS,
    payload: payload,
  };
};

const getApprovedRequestsFailure = (error) => {
  return {
    type: GET_APPROVED_REQUESTS_FAILURE,
    payload: error,
  };
};

const getApprovedRequests = (warehouseId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getApprovedRequestsRequest());
    let url = `${baseUrl}/mr/get_approved_mr`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getApprovedRequestsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getApprovedRequestsFailure(err.message));
      });
  };
};

const getOrderDetailsRequest = () => {
  return {
    type: GET_ORDER_DETAILS_REQUEST,
  };
};

const getOrderDetailsSuccess = (payload) => {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    payload: payload,
  };
};

const getOrderDetailsFailure = (error) => {
  return {
    type: GET_ORDER_DETAILS_FAILURE,
    payload: error,
  };
};

const getOrderDetails = (mrCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getOrderDetailsRequest());
    let url = `${baseUrl}/mr/get_approved_mr/sku?mr_code=${mrCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getOrderDetailsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getOrderDetailsFailure(err.message));
      });
  };
};

const getDownloadApprovedDataRequest = () => {
  return {
    type: GET_DOWNLOAD_APPROVED_REQUEST,
  };
};

const getDownloadApprovedDataSuccess = (payload) => {
  return {
    type: GET_DOWNLOAD_APPROVED_SUCCESS,
    payload: payload,
  };
};

const getDownloadApprovedDataFailure = (error) => {
  return {
    type: GET_DOWNLOAD_APPROVED_FAILURE,
    payload: error,
  };
};

const getDownloadApprovedData = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDownloadApprovedDataRequest());
    let url = `${baseUrl}/mr/get_approved_mr/download`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDownloadApprovedDataSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getDownloadApprovedDataFailure(err.message));
      });
  };
};

const getSkuRequest = () => {
  return {
    type: POST_SKU_REQUEST,
  };
};

const getSkuSuccess = (payload) => {
  return {
    type: POST_SKU_SUCCESS,
    payload: payload,
  };
};

const getSkuFailure = (error) => {
  return {
    type: POST_SKU_FAILURE,
    payload: error,
  };
};

const getSku = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuRequest());
    let url = `${baseUrl}/mr/get_skus`;
    axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(getSkuSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getSkuFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

const createDisapprovalRequest = () => {
  return {
    type: CREATE_DISAPPROVAL_REQUEST,
  };
};

const createDisapprovalSuccess = (payload) => {
  return {
    type: CREATE_DISAPPROVAL_SUCCESS,
    payload: payload,
  };
};

const createDisapprovalFailure = (error) => {
  return {
    type: CREATE_DISAPPROVAL_FAILURE,
    payload: error,
  };
};

const createDisapproval = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createDisapprovalRequest());
    let url = `${baseUrl}/mr/release_mr`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createDisapprovalSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(createDisapprovalFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};


const releasePicklistForApprovedRequestRequest = () => {
  return {
    type: CREATE_PICKLIST_FORAPPROVED_REQUEST,
  };
};

const releasePicklistForApprovedRequestSuccess = (payload) => {
  return {
    type: CREATE_PICKLIST_FORAPPROVED_SUCCESS,
    payload: payload,
  };
};

const releasePicklistForApprovedRequestFailure = (error) => {
  return {
    type: CREATE_PICKLIST_FORAPPROVED_FAILURE,
    payload: error,
  };
};

const releasePicklistForApprovedRequest = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(releasePicklistForApprovedRequestRequest());
    let url = `${baseUrl}/mr_request/picklist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(releasePicklistForApprovedRequestSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(releasePicklistForApprovedRequestFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

export {
  getMaterialRequestUsers,
  getMaterialRequest,
  getMaterialRequestCCUsage,
  getMaterialRequestACUsage,
  createMaterialRequest,
  getCreateMR,
  createMR,
  editQtyForCreateMR,
  getApprovedRequests,
  getOrderDetails,
  getDownloadApprovedData,
  getSku,
  createPicklistForPending,
  createDisapproval,
  getApprovedMaterialRequest,
  releasePicklistForApprovedRequest,
  getSuidsByDocumentNoForApprove
};
