import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Functions from store
import {
  getSubModulesByRoleId,
  getAllCategories,
  getZonesByCategoryAndWarehouse,
  getLocationsByWarehouse,
  addLocationToWarehouse,
  deleteLocation,
  getItemByLocation,
  getWarehouseById,
  uploadExcelForCreatingZoneRackLocation,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getRelationLocation,
  getZonesByWarehouseForViewLocation,
} from "../../store";

// Router Components
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";
import Config from "../../config/index";

import { CSVLink } from "react-csv";

// Notification
import { ToastContainer, toast } from "react-toastify";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Utils component
import TextError from "utils/TextError";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import { getZonesByWarehouse } from "layouts";
const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForZoneModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 380, sm: 380, md: 800, lg: 1200, xl: 1200 },
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ViewLocation = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,

  getLocationsByWarehouse,

  locationsByWarehouse,
  addLocationToWarehouse,
  deleteLocation,
  getItemByLocation,
  item,
  getWarehouseById,
  warehouseById,
  uploadExcelForCreatingZoneRackLocation,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevelReducer,
  getRelationLocation,
  getRelationLocationReducer,
  getZonesByWarehouseForViewLocation,
  getZonesByWarehouseForViewLocationReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let sfWarehouseModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "11d34625-6b02-49ab-8411-c1520ed2a085"
  );

  let viewLocations = sfWarehouseModule
    ? sfWarehouseModule.actionId.includes(
        "a031a9b2-cb12-4dea-88d7-4e5769f3d263"
      )
    : null;

  let createLocations = sfWarehouseModule
    ? sfWarehouseModule.actionId.includes(
        "2e1e1f31-1291-4901-91a2-3767d37dedfa"
      )
    : null;

  let deleteLocations = sfWarehouseModule
    ? sfWarehouseModule.actionId.includes(
        "7b8c84b3-cab4-40ea-89aa-eb4fab0870d6"
      )
    : null;
  let uploadLocations = sfWarehouseModule
    ? sfWarehouseModule.actionId.includes(
        "b7b99d40-5321-4646-8883-0df8b2036b1d"
      )
    : null;
  let downloadLocations = sfWarehouseModule
    ? sfWarehouseModule.actionId.includes(
        "dd8c04dc-6f02-49bb-9811-61f128214469"
      )
    : null;

  const navigate = useNavigate();

  const { warehouseId } = useParams();

  const [zonesListByWarehouseSelected, setZonesListByWarehouseSelected] =
    useState("");
  useEffect(() => {
    getLevel2ByLevel(
      warehouseId,
      zonesListByWarehouseSelected?.value,
      jwtToken
    );
  }, [warehouseId, zonesListByWarehouseSelected]);

  const handleChangeZonesListByWarehouse = (selectedOption) => {
    setZonesListByWarehouseSelected(selectedOption);
    setSectionsListByZoneSelected("");
    getLevel2ByLevel(warehouseId, selectedOption?.value, jwtToken);
  };

  const [zonesListByWarehouseOptions, setZonesListByWarehouseOptions] =
    useState([]);

  const [sectionsListByZoneSelected, setSectionsListByZoneSelected] =
    useState("");
  const handleChangeSectionsListByZone = (selectedOption) => {
    setSectionsListByZoneSelected(selectedOption);
  };

  const [sectionsListByZoneOptions, setSectionsListByZoneOptions] = useState(
    []
  );

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        locationId: level.locationId,
      });
    });

    setSectionsListByZoneOptions(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  useEffect(() => {
    let tempZonesListByWarehouseOptions = [];
    let data = getZonesByWarehouseForViewLocationReducer.zones.data
      ? getZonesByWarehouseForViewLocationReducer.zones.data
      : [];

    data.map((zonesListByWarehouse) => {
      tempZonesListByWarehouseOptions.push({
        label: zonesListByWarehouse.locationName,
        value: zonesListByWarehouse.locationId,
      });
    });
    setZonesListByWarehouseOptions(tempZonesListByWarehouseOptions);
  }, [getZonesByWarehouseForViewLocationReducer]);

  useEffect(() => {
    getWarehouseById(warehouseId, jwtToken);
  }, [warehouseId]);

  useEffect(() => {
    getRelationLocation(jwtToken);
  }, []);

  const [levelOptions, setLevelOptions] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getRelationLocationReducer.locations.data
      ? getRelationLocationReducer.locations.data
      : [];
    data.map((level) => {
      tempLevelOptions.push({
        label: level.rltName,
        value: level.rltLevel,
      });
    });
    setLevelOptions(tempLevelOptions);
  }, [getRelationLocationReducer]);

  const [zoneIdSelected, setZoneIdSelected] = useState("");

  const handleZoneIdChange = (selectedZone) => {
    setZoneIdSelected(selectedZone);
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const [sectionIdSelected, setSectionIdSelected] = useState("");
  const [rackIdSelected, setRackIdSelected] = useState("");
  const [locationIdSelected, setLocationIdSelected] = useState("");

  const handleRackIdChange = (selectedRack) => {
    setRackIdSelected(selectedRack);
    setLocationIdSelected("");
  };

  const handleSectionIdChange = (selectedRack) => {
    setSectionIdSelected(selectedRack);
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const [locationSelected, setLocationSelected] = useState("");

  const handleLocationIdChange = (selectedLocation) => {
    setLocationIdSelected(selectedLocation);
  };

  const [warehouseLocations, setWarehouseLocations] = useState([]);

  useEffect(() => {
    getZonesByWarehouseForViewLocation(warehouseId, jwtToken);
  }, [warehouseId]);
  useEffect(() => {
    getLocationsByWarehouse(
      zonesListByWarehouseSelected?.value,
      sectionsListByZoneSelected ? sectionsListByZoneSelected.value : "",
      jwtToken
    );
  }, [zonesListByWarehouseSelected, sectionsListByZoneSelected]);

  useEffect(() => {
    setWarehouseLocations(locationsByWarehouse.locations.data);
    setLoading(locationsByWarehouse.loading);
  }, [locationsByWarehouse]);

  useEffect(() => {
    getLevel1ByLevel(warehouseId, 0, jwtToken);
  }, [warehouseId]);

  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        locationId: level.locationId,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        locationId: level.locationId,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        locationId: level.locationId,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  const [warehouseNameHeading, setWarehouseNameHeading] = useState("");

  useEffect(() => {
    setWarehouseNameHeading(warehouseById.warehouse[0]?.warehouseName);
  }, [warehouseById]);

  const [warehouseCodeHeading, setWarehouseCodeHeading] = useState("");
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    setLoading2(warehouseById.loading);
    setWarehouseCodeHeading(warehouseById.warehouse[0]?.warehouseCode);
  }, [warehouseById]);

  const [isSuccessAddLocation, setIsSuccessAddLocation] = useState(false);
  const [isErrorAddLocation, setIsErrorAddLocation] = useState(false);
  const [isErrorMsgAddLocation, setIsErrorMsgAddLocation] = useState("");

  useEffect(() => {
    if (isErrorAddLocation) {
      setTimeout(() => {
        setIsErrorAddLocation(false);
      }, 3000);
    }
  }, [isErrorAddLocation]);

  useEffect(() => {
    if (isSuccessAddLocation) {
      setTimeout(() => {
        setIsSuccessAddLocation(false);
      }, 3000);
    }
  }, [isSuccessAddLocation]);

  const [addLocationModal, setAddLocationModal] = useState(false);
  const [locationData, setLocationData] = useState({});

  const handleAddLocationModal = (data = {}) => {
    setLocationData(data);
    setAddLocationModal(!addLocationModal);
    getLevel2ByLevel(warehouseId, "", jwtToken);
  };
  const handleCloseAddLocationModal = (data = {}) => {
    setAddLocationModal(false);
    getLevel2ByLevel(
      warehouseId,
      zonesListByWarehouseSelected?.value,
      jwtToken
    );
  };

  const initialValue = {
    warehouseCode: "",
    parentId: "",
    rltId: "",
    isLocation: "",
    locationName: "",
    locationCode: "",
  };

  const validationSchema = Yup.object({
    location: Yup.string().required("Enter the Location"),
  });

  const onSubmitForAddLocation = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseId,

      parentId: locationData.locationId,
      rltId: 4,
      isLocation: 1,
      locationName: values.location,
      locationCode: `${locationData.locationCode}-${values.location}`,
    };

    let res = await addLocationToWarehouse(payload, jwtToken);
    if (res.status) {
      setIsSuccessAddLocation(true);
      getLocationsByWarehouse(
        zonesListByWarehouseSelected?.value,
        sectionsListByZoneSelected ? sectionsListByZoneSelected.value : "",
        jwtToken
      );
    }
    if (!res.status) {
      setIsErrorAddLocation(true);
      setIsErrorMsgAddLocation(res?.data?.data?.msg);
    }
    resetForm();
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: onSubmitForAddLocation,
    validationSchema: validationSchema,
  });

  const [itemDetailsModal, setOpenItemDetailsModal] = useState(false);

  const handleOpenItemDetailsModal = () => {
    setOpenItemDetailsModal(!itemDetailsModal);
  };

  const [itemTable, setItemTable] = useState({
    columns: [
      { Header: "Item Code/Des", accessor: "itemCodeDes", align: "left" },
      { Header: "SKU", accessor: "sku" },
      { Header: "SUID", accessor: "suid" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo" },
      { Header: "vin No", accessor: "vinNo" },
      { Header: "FG Code", accessor: "fgCode" },
      { Header: "Quantity", accessor: "qty", align: "left" },
    ],
    rows: [],
  });

  const { columns, rows } = itemTable;

  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    let tempItem = [];
    let data = item.item ? item.item : [];
    data.map((item) => {
      let itemDescription = item?.itemDescription;
      let len = desLength;
      const itemData = {
        suid: item.suid,
        sku: item.sku,
        vinNo: item.vinNo ? item.vinNo : "NA",
        fgCode: item.fgCode ? item.fgCode : "NA",

        itemCodeDes: `${item.itemCode} / ${
          itemDescription ? itemDescription.substring(0, len) : ""
        }`,
        qty: `${item.qty} ${item.uom}`,
        lotSerialNo: `${item.lotNumber ? item.lotNumber : "NA"} / ${
          item.serialNumber ? item.serialNumber : "NA"
        }`,
      };
      tempItem.push(itemData);
    });
    viewLocations && setItemTable({ ...itemTable, rows: tempItem });
  }, [item]);

  const notifyForUploadFile = (data) => {
    toast.success(data, {
      position: "top-center",
    });
  };
  const [ErrorMsg, setErrorMsg] = useState("");

  const notifyForUploadFileFailure = (data) => {
    toast.error(data, {
      position: "top-center",
      style: {
        width: "40rem",
        height: "20rem",
        lineHeight: "50px",
      },
    });
  };

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(true);
  };
  const uploadCsvFilterCloseHandler = () => {
    setUploadCsvFilter(false);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const [loading, setLoading] = useState(false);

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("files", uploadCsvData);

    let res = await uploadExcelForCreatingZoneRackLocation(data, jwtToken);
    if (res.statusCode == 200) {
      getLocationsByWarehouse(
        zonesListByWarehouseSelected?.value,
        sectionsListByZoneSelected ? sectionsListByZoneSelected.value : "",
        jwtToken
      );
      let data = `Data Submitted Successfully `;
      notifyForUploadFile(data);
      setUploadCsvFilter(false);
      setUploadCsvData({});
      setUploadCsvData("");
    }
    if (res.statusCode == 406) {
      setLoading(true);
      // setWarehouseData(res.data, jwtToken);
      setLoading(false);
    }
    if (!res.status) {
      notifyForUploadFileFailure(res?.data?.data?.msg);
    }
  };

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const [levelSelected, setLevelSelected] = useState("");

  const handleLevelChange = (selectedDocumentType) => {
    setLevelSelected(selectedDocumentType);
    setZoneIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const [parentIdSelected, setParentIdSelected] = useState("");

  useEffect(() => {
    if (levelSelected?.value === 4) {
      setParentIdSelected(rackIdSelected?.locationId);
    }
    if (levelSelected?.value === 3) {
      setParentIdSelected(sectionIdSelected?.locationId);
    }
    if (levelSelected?.value === 2) {
      setParentIdSelected(zoneIdSelected?.locationId);
    }
    if (levelSelected?.value === 1) {
      setParentIdSelected(0);
    }
  }, [levelSelected, rackIdSelected, sectionIdSelected, zoneIdSelected]);

  const [isSuccessAddZone, setIsSuccessAddZone] = useState(false);
  const [isErrorAddZone, setIsErrorAddZone] = useState(false);

  useEffect(() => {
    if (isErrorAddZone) {
      setTimeout(() => {
        setIsErrorAddZone(false);
      }, 3000);
    }
  }, [isErrorAddZone]);

  useEffect(() => {
    if (isSuccessAddZone) {
      setTimeout(() => {
        setIsSuccessAddZone(false);
      }, 3000);
    }
  }, [isSuccessAddZone]);

  const [addZoneModal, setAddZoneModal] = useState(false);
  const handleAddZoneModal = () => {
    setAddZoneModal(!addZoneModal);
    setLevelSelected("");
    setZoneIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    formikForAddZone.setValues(initialValueForAddZone);
    formikForAddZone.setErrors({});
  };

  const initialValueForAddZone = {
    location: "",
  };

  const validationSchemaForAddZone = Yup.object({
    location: Yup.string().required("Enter the level name"),
  });

  const onSubmitForAddZone = async (values, { resetForm }) => {
    let payload;
    if (levelSelected.value === 1) {
      payload = {
        warehouseId: warehouseId,

        parentId: parentIdSelected,
        rltId: levelSelected.value,
        isLocation: levelSelected.value === 4 ? 1 : 0,
        locationName: values.location.trim(),
        locationCode: `${warehouseId}-${values.location.trim()}`,
      };
    }

    if (levelSelected.value === 2) {
      payload = {
        warehouseId: warehouseId,

        parentId: parentIdSelected,
        rltId: levelSelected.value,
        isLocation: levelSelected.value === 4 ? 1 : 0,
        locationName: values.location.trim(),
        locationCode: `${warehouseId}-${zoneIdSelected.label}-${values.location.trim()}`,
      };
    }

    if (levelSelected.value === 3) {
      payload = {
        warehouseId: warehouseId,

        parentId: parentIdSelected,
        rltId: levelSelected.value,
        isLocation: levelSelected.value === 4 ? 1 : 0,
        locationName: values.location.trim(),
        locationCode: `${warehouseId}-${zoneIdSelected.label}-${sectionIdSelected.label}-${values.location.trim()}`,
      };
    }

    if (levelSelected.value === 4) {
      payload = {
        warehouseId: warehouseId,

        parentId: parentIdSelected,
        rltId: levelSelected.value,
        isLocation: levelSelected.value === 4 ? 1 : 0,
        locationName: values.location.trim(),
        locationCode: `${warehouseId}-${zoneIdSelected.label}-${sectionIdSelected.label}-${rackIdSelected.label}-${values.location.trim()}`,
      };
    }
    let res = await addLocationToWarehouse(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddZone(true);
      getLocationsByWarehouse(
        zonesListByWarehouseSelected?.value,
        sectionsListByZoneSelected ? sectionsListByZoneSelected.value : "",
        jwtToken
      );
      getLevel1ByLevel(warehouseId, 0, jwtToken);
    }
    if (!res.status) {
      setIsErrorAddZone(true);
      setCreateErrorMsg(res?.data?.data?.msg);
    }
    resetForm();
  };

  const formikForAddZone = useFormik({
    initialValues: initialValueForAddZone,
    onSubmit: onSubmitForAddZone,
    validationSchema: validationSchemaForAddZone,
  });

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const [isSuccessRemoveLevel, setIsSuccessRemoveLevel] = useState(false);
  const [isErrorRemoveLevel, setIsErrorRemoveLevel] = useState(false);

  useEffect(() => {
    if (isErrorRemoveLevel) {
      setTimeout(() => {
        setIsErrorRemoveLevel(false);
      }, 3000);
    }
  }, [isErrorRemoveLevel]);

  useEffect(() => {
    if (isSuccessRemoveLevel) {
      setTimeout(() => {
        setIsSuccessRemoveLevel(false);
      }, 3000);
    }
  }, [isSuccessRemoveLevel]);

  const [removeLevelModal, setRemoveLevelModal] = useState(false);
  const handleRemoveLevelModal = () => {
    setRemoveLevelModal(!removeLevelModal);
    setLevelSelected("");
    setZoneIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
    formikForRemoveLevel.setValues(initialValueForRemoveLevel);
    formikForRemoveLevel.setErrors({});
    getLevel2ByLevel(warehouseId, "", jwtToken);
  };
  const handleCloseRemoveLevelModal = () => {
    setRemoveLevelModal(false);
    setLevelSelected("");
    setZoneIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
    formikForRemoveLevel.setValues(initialValueForRemoveLevel);
    formikForRemoveLevel.setErrors({});
    getLevel2ByLevel(warehouseId, "", jwtToken);
  };

  const initialValueForRemoveLevel = {
    location: "",
  };

  const validationSchemaForRemoveLevel = Yup.object({
    // location: Yup.string().required("Enter the level name"),
  });

  const onSubmitForRemoveLevel = async (values, { resetForm }) => {
    let payload;
    if (levelSelected.value === 1) {
      payload = {
        warehouseId: warehouseId,

        locationId: zoneIdSelected.value,
      };
    }

    if (levelSelected.value === 2) {
      payload = {
        warehouseId: warehouseId,

        locationId: sectionIdSelected.value,
      };
    }

    if (levelSelected.value === 3) {
      payload = {
        warehouseId: warehouseId,

        locationId: rackIdSelected.value,
      };
    }

    if (levelSelected.value === 4) {
      payload = {
        warehouseId: warehouseId,

        locationId: locationIdSelected.value,
      };
    }

    let res = await deleteLocation(payload.locationId, warehouseId, jwtToken);

    if (res.status) {
      setIsSuccessRemoveLevel(true);
      getLocationsByWarehouse(
        zonesListByWarehouseSelected?.value,
        sectionsListByZoneSelected ? sectionsListByZoneSelected.value : "",
        jwtToken
      );
      getLevel1ByLevel(warehouseId, 0, jwtToken);
    }
    if (!res.status) {
      setIsErrorRemoveLevel(true);
      setDeleteErrorMsg(res?.data?.data?.msg);
    }
    resetForm();
  };

  const formikForRemoveLevel = useFormik({
    initialValues: initialValueForRemoveLevel,
    onSubmit: onSubmitForRemoveLevel,
    validationSchema: validationSchemaForRemoveLevel,
  });

  useEffect(() => {
    if (zonesListByWarehouseOptions) {
      setZonesListByWarehouseSelected({
        label: zonesListByWarehouseOptions[0]?.label,
        value: zonesListByWarehouseOptions[0]?.value,
      });
    }
  }, [zonesListByWarehouseOptions]);

  const headers = [
    {
      label: "Warehouse",
      key: "Warehouse",
    },
    {
      label: "Zone",
      key: "Zone",
    },
    {
      label: "Section",
      key: "Section",
    },
    {
      label: "Rack",
      key: "Rack",
    },
    {
      label: "Location",
      key: "Location",
    },
  ];

  const csvLink = {
    filename: "Locations Template.csv",
    headers: headers,
    data: [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar previousPage={true} />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="left"
                p={3}
                mb={-1}
              >
                {/* <MDBox display="flex">
                  <MDTypography variant="h4" gutterBottom>
                    {warehouseNameHeading}
                  </MDTypography>
                </MDBox> */}

                <MDBox>
                  <MDBox
                    display="flex"
                    // flexDirection={{
                    //   xs: "column",
                    //   sm: "column",
                    //   md: "row",
                    //   lg: "row",
                    //   xl: "row",
                    // }}
                  >
                    {createLocations && (
                      <Tooltip title="Add Level">
                        <MDButton
                          color="info"
                          iconOnly
                          variant="contained"
                          type="button"
                          onClick={() => {
                            setAddZoneModal(true);
                          }}
                          style={{
                            margin: "10px",
                            marginLeft: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
                          }}
                        >
                          <Icon>add</Icon>
                        </MDButton>
                      </Tooltip>
                    )}

                    {deleteLocations && (
                      <Tooltip title="Remove Level">
                        <MDButton
                          disabled={!deleteLocation}
                          color="error"
                          iconOnly
                          variant="contained"
                          type="button"
                          onClick={() => {
                            setRemoveLevelModal(true);
                          }}
                          style={{
                            margin: "10px",
                            marginLeft: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
                          }}
                        >
                          <Icon>remove</Icon>
                        </MDButton>
                      </Tooltip>
                    )}

                    {downloadLocations && (
                      <MDBox px={1} mb={-5} mt={1.3}>
                        <CSVLink {...csvLink}>
                          <DownloadButton
                            tooltip="Download Template"
                            type="button"
                            variant="outlined"
                            color="error"
                            iconOnly
                          >
                            <Icon>download</Icon>
                          </DownloadButton>
                        </CSVLink>
                      </MDBox>
                    )}

                    {!uploadCsvFilter && (
                      <MDBox color="text" px={1} mb={-5} mt={1.3}>
                        <UploadButton
                          tooltip="Upload CSV"
                          disabled={!uploadLocations}
                          type="button"
                          variant="outlined"
                          color="info"
                          iconOnly
                          onClick={uploadCsvFilterHandler}
                        >
                          <Icon>upload</Icon>
                        </UploadButton>
                      </MDBox>
                    )}
                    {/* <MDBox style={{ marginBottom: "-30px" }}>
                        {uploadCsvFilter ? (
                          <div>
                            <div>
                              <input
                                className="choose_file"
                                style={{
                                  marginTop: "0.6rem",
                                  marginLeft: "0.5rem",
                                  // marginBottom: "-9rem",
                                }}
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                  setUploadCsvData(e.target.files[0]);
                                }}
                              />

                              <MDButton
                                disabled={
                                  !uploadCsvData.name ||
                                  uploadCsvData.type !== "text/csv"
                                }
                                variant="contained"
                                color="info"
                                style={{
                                  marginLeft: { xs: "10px", lg: "0px" },
                                  marginTop: "0rem",
                                }}
                                type="button"
                                onClick={uploadCsvHandler}
                              >
                                UPLOAD
                              </MDButton>
                              <Tooltip title="Close">
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  onClick={uploadCsvFilterCloseHandler}
                                  style={{
                                    fontSize: "15px",
                                    background: "red",
                                    color: "black",
                                    borderRadius: 5,
                                    color: "white",
                                    marginLeft: "5px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div></div>
                          </div>
                        ) : (
                          <div style={{ visibility: "hidden" }}>
                            <div>
                              <input
                                className="choose_file"
                                style={{
                                  marginLeft: "0.5rem",
                                }}
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                  setUploadCsvData(e.target.files[0]);
                                }}
                              />

                              <MDButton
                                disabled={
                                  !uploadCsvData.name ||
                                  uploadCsvData.type !== "text/csv"
                                }
                                variant="contained"
                                color="info"
                                style={{
                                  marginLeft: { xs: "1.5rem", lg: "1" },
                                  marginTop: "0rem",
                                }}
                                type="button"
                                onClick={uploadCsvHandler}
                              >
                                UPLOAD
                              </MDButton>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </MDBox> */}
                    {uploadCsvFilter && (
                      <div style={{ marginLeft: "1", marginTop: "-0.9rem" }}>
                        <div>
                          <input
                            className="choose_file"
                            style={{
                              marginTop: "1.5rem",
                              marginLeft: "1.5rem",
                              // marginBottom: "-9rem",
                            }}
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              setUploadCsvData(e.target.files[0]);
                            }}
                          />
                          <MDButton
                            disabled={
                              !uploadCsvData.name ||
                              uploadCsvData.type !== "text/csv"
                            }
                            variant="contained"
                            color="info"
                            style={{
                              marginLeft: { xs: "1.5rem", lg: "1" },
                              marginTop: "0rem",
                            }}
                            type="button"
                            onClick={uploadCsvHandler}
                          >
                            UPLOAD
                          </MDButton>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={uploadCsvFilterCloseHandler}
                              style={{
                                fontSize: "15px",
                                background: "red",
                                color: "black",
                                borderRadius: 5,
                                color: "white",
                                marginLeft: "5px",
                                marginTop: "5px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div style={{ marginBottom: "-20px" }}>
                          <span
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "30px",
                              fontStyle: "italic",
                            }}
                          >
                            <strong>*</strong> Please upload
                            <strong>".csv"</strong> file only
                          </span>
                        </div>
                      </div>
                    )}
                    <MDBox
                      marginLeft={{ xs: "-560px", lg: "300px" }}
                      display="flex"
                    >
                      <MDButton
                        variant="outlined"
                        type="button"
                        style={{
                          cursor: "auto",
                          margin: "10px",
                          float: "right",
                          marginRight: "1.5rem",
                          marginLeft: "1rem",
                          backgroundColor: "#28B463",
                          color: "white",
                        }}
                      >
                        Empty
                      </MDButton>
                      <MDButton
                        variant="contained"
                        type="button"
                        style={{
                          float: "right",
                          margin: "10px",
                          cursor: "auto",
                          backgroundColor: "#B03A2E",
                          color: "white",
                        }}
                      >
                        Occupied
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDTypography
                variant="h5"
                style={{
                  marginLeft: "1.5rem",
                }}
              >
                <span
                  style={{
                    color: "#133337",
                    fontWeight: 800,
                  }}
                >
                  WareHouse Name / Code:-
                </span>
                <span style={{ color: "#133337", fontWeight: 400 }}>
                  {!loading2 ? (
                    <>
                      {warehouseNameHeading} / {warehouseCodeHeading}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "20rem",
                        marginTop: "-2rem",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </span>
              </MDTypography>
              <MDBox display="flex">
                <MDBox
                  width={{ xs: "250px", lg: "300px" }}
                  style={{ marginLeft: "28px", marginTop: "20px" }}
                >
                  <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                    {getRelationLocationReducer.locations.data
                      ? getRelationLocationReducer.locations.data[0].rltName
                      : ""}
                  </MDTypography>
                  <Select
                    placeholder="Select"
                    className="select-css"
                    value={zonesListByWarehouseSelected}
                    options={zonesListByWarehouseOptions}
                    onChange={handleChangeZonesListByWarehouse}
                    autoComplete="off"
                    maxMenuHeight={130}
                  />
                </MDBox>
                <MDBox
                  width={{ xs: "250px", lg: "300px" }}
                  style={{ marginLeft: "10px", marginTop: "20px" }}
                >
                  <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                    {getRelationLocationReducer.locations.data
                      ? getRelationLocationReducer.locations.data[1].rltName
                      : ""}
                  </MDTypography>
                  <Select
                    isClearable
                    placeholder="Select"
                    className="select-css"
                    value={sectionsListByZoneSelected}
                    options={sectionsListByZoneOptions}
                    onChange={handleChangeSectionsListByZone}
                    autoComplete="off"
                    maxMenuHeight={130}
                  />
                </MDBox>
              </MDBox>
              <Grid mb={2}>
                {warehouseLocations?.map((zone) =>
                  !loading ? (
                    <Grid mb={1}>
                      <span
                        style={{
                          marginTop: "0px",
                          marginLeft: "20px",
                          marginRight: "20px",
                          fontWeight: 800,
                          color: "#133337",
                        }}
                      >
                        {!zone.data && zone?.locationName}
                      </span>

                      {zone?.data?.map((section) => (
                        <Grid>
                          {!section.data && (
                            <MDTypography
                              variant="h5"
                              style={{
                                marginTop: "1.5rem",
                                marginLeft: "20px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#133337",
                                  fontWeight: 800,
                                }}
                              >
                                {zone?.locationName}
                              </span>{" "}
                              <span style={{ fontWeight: 900 }}>/</span>{" "}
                              <span
                                style={{
                                  color: "#133337",
                                  fontWeight: 500,
                                }}
                              >
                                {section?.locationName}
                                {/* {section?.locationName && `<hr />`} */}
                              </span>{" "}
                            </MDTypography>
                          )}
                          {section?.data?.map((rack) => (
                            <Grid
                              style={{
                                border: "1px solid grey",
                                borderRadius: 10,
                                margin: "5px",
                                paddingRight: "15px",
                              }}
                            >
                              <MDTypography
                                variant="h5"
                                style={{
                                  marginTop: "1.5rem",
                                  marginLeft: "20px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#133337",
                                    fontWeight: 800,
                                    border: "2px solid #F1C40F ",
                                    borderRadius: "5px",
                                    padding: "5px",
                                  }}
                                >
                                  {zone?.locationName}
                                </span>{" "}
                                <span style={{ fontWeight: 900 }}>/</span>{" "}
                                <span
                                  style={{
                                    color: "#133337",
                                    fontWeight: 500,
                                    border: "2px solid #6495ED",
                                    borderRadius: "5px",
                                    padding: "5px",
                                  }}
                                >
                                  {section?.locationName}
                                </span>{" "}
                                <span style={{ fontWeight: 900 }}>/</span>{" "}
                                <span
                                  style={{
                                    color: "#440f2b",
                                    fontWeight: 400,
                                    border: "2px solid #800080",
                                    borderRadius: "5px",
                                    padding: "5px",
                                  }}
                                >
                                  {rack?.locationName}
                                </span>
                              </MDTypography>
                              <Grid
                                display="flex"
                                container
                                spacing={2}
                                mt={2}
                                ml={1}
                                mr={2}
                              >
                                <Grid
                                  ml={0.5}
                                  mt={0.2}
                                  mr={2}
                                  display="flex"
                                  container
                                  spacing={2}
                                >
                                  {rack?.data?.map((location) => (
                                    <div
                                      className={`masterStoreItem ${
                                        location.isEmpty === 1
                                          ? `badge-success`
                                          : `badge-danger`
                                      }`}
                                    >
                                      {location.isEmpty === 0 && (
                                        <Button
                                          variant="contained"
                                          style={{
                                            margin: "10px",
                                            marginRight: "4.5px",
                                            backgroundColor: "#B03A2E",
                                            color: "white",
                                          }}
                                          onClick={() => {
                                            // setLocationId(location.locationId);
                                            setLocationName(
                                              location.locationName
                                            );
                                            getItemByLocation(
                                              location.locationId,
                                              jwtToken
                                            );
                                            handleOpenItemDetailsModal();
                                          }}
                                        >
                                          {location.locationName}
                                        </Button>
                                      )}
                                      {location.isEmpty === 1 && (
                                        <MDButton
                                          type="button"
                                          variant="contained"
                                          style={{
                                            margin: "10px",
                                            marginRight: "4.5px",
                                            cursor: "auto",
                                            backgroundColor: "#28B463",
                                            color: "white",
                                          }}
                                        >
                                          {location.locationName}
                                        </MDButton>
                                      )}
                                    </div>
                                  ))}

                                  <div
                                    onClick={() => {
                                      handleAddLocationModal(rack);
                                    }}
                                  >
                                    <Tooltip title="Add Location">
                                      <AddCircleIcon
                                        fontSize="large"
                                        color="info"
                                        style={{
                                          width: "30px",
                                          marginTop: "10px",
                                          // marginBottom: "-58px",
                                          marginRight: "5px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )
                )}
                <div>
                  <Modal
                    open={addLocationModal}
                    onClose={handleAddLocationModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={style}>
                      <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          Add Location
                        </MDTypography>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseAddLocationModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                      <hr
                        style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                      />
                      <MDBox pt={1} pb={3} px={3}>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={formik.handleSubmit}
                        >
                          <MDBox>
                            <MDBox mb={2}>
                              <MDTypography variant="h6">Location</MDTypography>

                              <MDInput
                                type="text"
                                name="location"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                                error={
                                  formik.touched.location &&
                                  formik.errors.location &&
                                  true
                                }
                              />
                              {formik.touched.location &&
                                formik.errors.location && (
                                  <TextError msg={formik.errors.location} />
                                )}
                            </MDBox>

                            <MDBox>
                              <Collapse in={isErrorAddLocation}>
                                <Alert
                                  severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsErrorAddLocation(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {isErrorMsgAddLocation}
                                </Alert>
                              </Collapse>
                              <Collapse in={isSuccessAddLocation}>
                                <Alert
                                  severity="success"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsSuccessAddLocation(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  Location added successfully
                                </Alert>
                              </Collapse>
                            </MDBox>
                            <div className="mt-3">
                              <MDButton
                                type="submit"
                                variant="gradient"
                                color="info"
                                fullWidth
                              >
                                Add Location
                              </MDButton>
                            </div>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDCard>
                  </Modal>
                </div>
                <Modal
                  open={addZoneModal}
                  onClose={handleAddZoneModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Add Level
                      </MDTypography>
                      <MDBox>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleAddZoneModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForAddZone.handleSubmit}
                      >
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Level{" "}
                          </MDTypography>

                          <Select
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="level"
                            onBlur={() => {
                              formikForAddZone.handleBlur({
                                target: { name: "level" },
                              });
                            }}
                            value={levelSelected}
                            options={levelOptions}
                            onChange={(selectedOption) => {
                              handleLevelChange(selectedOption);
                              formikForAddZone.handleChange("level")(
                                selectedOption.label
                              );
                            }}
                          />
                          {formikForAddZone.touched.level &&
                            formikForAddZone.errors.level && (
                              <TextError msg={formikForAddZone.errors.level} />
                            )}
                        </MDBox>

                        {levelSelected.value !== "" && (
                          <>
                            {levelSelected.value >= 2 && (
                              <MDBox mb={1}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: "500" }}
                                >
                                  {" "}
                                  Level 1:-{" "}
                                  {
                                    getRelationLocationReducer.locations.data[0]
                                      .rltName
                                  }{" "}
                                </MDTypography>

                                <Select
                                  className="select-css"
                                  maxMenuHeight={130}
                                  placeholder="Choose one..."
                                  name="level1"
                                  onBlur={() => {
                                    formikForAddZone.handleBlur({
                                      target: { name: "level1" },
                                    });
                                  }}
                                  value={zoneIdSelected}
                                  options={level1Options}
                                  onChange={(selectedOption) => {
                                    handleZoneIdChange(selectedOption);
                                    formikForAddZone.handleChange("level1")(
                                      selectedOption.label
                                    );
                                    getLevel2ByLevel(
                                      warehouseId,
                                      selectedOption?.value,
                                      jwtToken
                                    );
                                  }}
                                />
                                {formikForAddZone.touched.level1 &&
                                  formikForAddZone.errors.level1 && (
                                    <TextError
                                      msg={formikForAddZone.errors.level1}
                                    />
                                  )}
                              </MDBox>
                            )}
                            {levelSelected.value >= 3 && (
                              <>
                                <MDBox mb={1}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {" "}
                                    Level 2:-{" "}
                                    {
                                      getRelationLocationReducer.locations
                                        .data[1].rltName
                                    }
                                  </MDTypography>

                                  <Select
                                    className="select-css"
                                    isDisabled={!zoneIdSelected}
                                    maxMenuHeight={130}
                                    placeholder="Choose one..."
                                    name="level2"
                                    onBlur={() => {
                                      formikForAddZone.handleBlur({
                                        target: { name: "level2" },
                                      });
                                    }}
                                    value={sectionIdSelected}
                                    options={level2Options}
                                    onChange={(selectedOption) => {
                                      handleSectionIdChange(selectedOption);
                                      formikForAddZone.handleChange("level2")(
                                        selectedOption.label
                                      );
                                      getLevel3ByLevel(
                                        warehouseId,
                                        selectedOption?.value,
                                        jwtToken
                                      );
                                    }}
                                  />
                                  {formikForAddZone.touched.level2 &&
                                    formikForAddZone.errors.level2 && (
                                      <TextError
                                        msg={formikForAddZone.errors.level2}
                                      />
                                    )}
                                </MDBox>
                              </>
                            )}
                            {levelSelected.value === 4 && (
                              <MDBox mb={1}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: "500" }}
                                >
                                  Level 3:-{" "}
                                  {
                                    getRelationLocationReducer.locations.data[2]
                                      .rltName
                                  }
                                </MDTypography>

                                <Select
                                  className="select-css"
                                  isDisabled={!sectionIdSelected}
                                  maxMenuHeight={130}
                                  placeholder="Choose One..."
                                  name="level3"
                                  value={rackIdSelected}
                                  options={level3Options}
                                  onChange={(selectedOption) => {
                                    handleRackIdChange(selectedOption);
                                    getLevel4ByLevel(
                                      warehouseId,
                                      selectedOption?.value,
                                      jwtToken
                                    );
                                    // formikForFilter.handleChange(
                                    //   "zoneCategory"
                                    // )(selectedOption.value);
                                  }}
                                />
                                {formikForAddZone.errors.level3 && (
                                  <TextError
                                    msg={formikForAddZone.errors.level3}
                                  />
                                )}
                              </MDBox>
                            )}

                            {levelSelected.value && (
                              <MDBox mb={2}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  {levelSelected.label} Name
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="location"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={formikForAddZone.handleBlur}
                                  onChange={formikForAddZone.handleChange}
                                  value={formikForAddZone.values.location}
                                  error={
                                    formikForAddZone.touched.location &&
                                    formikForAddZone.errors.location &&
                                    true
                                  }
                                />
                                {formikForAddZone.touched.location &&
                                  formikForAddZone.errors.location && (
                                    <TextError
                                      msg={formikForAddZone.errors.location}
                                    />
                                  )}
                              </MDBox>
                            )}
                          </>
                        )}
                        <MDBox>
                          <Collapse in={isErrorAddZone}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorAddZone(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {createErrorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessAddZone}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessAddZone(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Level added successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="info"
                            fullWidth
                            type="submit"
                            disabled={
                              !formikForAddZone.isValid ||
                              !levelSelected ||
                              (levelSelected.value === 3 &&
                                (!zoneIdSelected || !sectionIdSelected)) ||
                              (levelSelected.value === 4 &&
                                (!zoneIdSelected ||
                                  !sectionIdSelected ||
                                  !rackIdSelected)) ||
                              (levelSelected.value === 2 && !zoneIdSelected)
                            }
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={removeLevelModal}
                  onClose={handleRemoveLevelModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Remove Level
                      </MDTypography>
                      <MDBox>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseRemoveLevelModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForRemoveLevel.handleSubmit}
                      >
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Level{" "}
                          </MDTypography>

                          <Select
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="level"
                            onBlur={() => {
                              formikForRemoveLevel.handleBlur({
                                target: { name: "level" },
                              });
                            }}
                            value={levelSelected}
                            options={levelOptions}
                            onChange={(selectedOption) => {
                              handleLevelChange(selectedOption);
                              formikForRemoveLevel.handleChange("level")(
                                selectedOption.label
                              );
                            }}
                          />
                          {formikForRemoveLevel.touched.level &&
                            formik.errors.level && (
                              <TextError
                                msg={formikForRemoveLevel.errors.level}
                              />
                            )}
                        </MDBox>

                        {levelSelected.value !== "" && (
                          <>
                            {levelSelected.value >= 1 && (
                              <MDBox mb={1}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: "500" }}
                                >
                                  {" "}
                                  Level 1:-{" "}
                                  {
                                    getRelationLocationReducer.locations.data[0]
                                      .rltName
                                  }{" "}
                                </MDTypography>

                                <Select
                                  isClearable
                                  className="select-css"
                                  maxMenuHeight={130}
                                  placeholder="Choose one..."
                                  name="level1"
                                  value={zoneIdSelected}
                                  options={level1Options}
                                  onChange={(selectedOption) => {
                                    handleZoneIdChange(selectedOption);

                                    if (
                                      selectedOption &&
                                      selectedOption.label
                                    ) {
                                      formikForAddZone.handleChange("level1")(
                                        selectedOption.label
                                      );
                                    } else {
                                      formikForAddZone.handleChange("level1")(
                                        ""
                                      );
                                    }

                                    if (
                                      selectedOption &&
                                      selectedOption.value
                                    ) {
                                      getLevel2ByLevel(
                                        warehouseId,
                                        selectedOption.value,
                                        jwtToken
                                      );
                                    } else {
                                      getLevel2ByLevel(
                                        warehouseId,
                                        null,
                                        jwtToken
                                      );
                                    }
                                  }}
                                />
                                {formikForRemoveLevel.touched.level1 &&
                                  formikForRemoveLevel.errors.level1 && (
                                    <TextError
                                      msg={formikForRemoveLevel.errors.level1}
                                    />
                                  )}
                              </MDBox>
                            )}
                            {levelSelected.value >= 2 && (
                              <>
                                <MDBox mb={1}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {" "}
                                    Level 2:-{" "}
                                    {
                                      getRelationLocationReducer.locations
                                        .data[1].rltName
                                    }
                                  </MDTypography>

                                  <Select
                                    isClearable
                                    isDisabled={!zoneIdSelected}
                                    className="select-css"
                                    maxMenuHeight={130}
                                    placeholder="Choose one..."
                                    name="level2"
                                    value={sectionIdSelected}
                                    options={level2Options}
                                    onChange={(selectedOption) => {
                                      handleSectionIdChange(selectedOption);

                                      if (
                                        selectedOption &&
                                        selectedOption.label
                                      ) {
                                        formikForAddZone.handleChange("level2")(
                                          selectedOption.label
                                        );
                                      } else {
                                        formikForAddZone.handleChange("level2")(
                                          ""
                                        );
                                      }

                                      if (
                                        selectedOption &&
                                        selectedOption.value
                                      ) {
                                        getLevel3ByLevel(
                                          warehouseId,
                                          selectedOption.value,
                                          jwtToken
                                        );
                                      } else {
                                        getLevel3ByLevel(
                                          warehouseId,
                                          null,
                                          jwtToken
                                        );
                                      }
                                    }}
                                  />
                                  {formikForRemoveLevel.touched.level2 &&
                                    formikForRemoveLevel.errors.level2 && (
                                      <TextError
                                        msg={formikForRemoveLevel.errors.level2}
                                      />
                                    )}
                                </MDBox>
                              </>
                            )}
                            {levelSelected.value >= 3 && (
                              <MDBox mb={1}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: "500" }}
                                >
                                  Level 3:-{" "}
                                  {
                                    getRelationLocationReducer.locations.data[2]
                                      .rltName
                                  }
                                </MDTypography>

                                <Select
                                  isClearable
                                  isDisabled={!sectionIdSelected}
                                  className="select-css"
                                  maxMenuHeight={130}
                                  placeholder="Choose One..."
                                  name="level3"
                                  value={rackIdSelected}
                                  options={level3Options}
                                  onChange={(selectedOption) => {
                                    handleRackIdChange(selectedOption);
                                    getLevel4ByLevel(
                                      warehouseId,
                                      selectedOption?.value,
                                      jwtToken
                                    );
                                    // formikForFilter.handleChange(
                                    //   "zoneCategory"
                                    // )(selectedOption.value);
                                  }}
                                />
                                {formikForRemoveLevel.errors.level3 && (
                                  <TextError
                                    msg={formikForRemoveLevel.errors.level3}
                                  />
                                )}
                              </MDBox>
                            )}

                            {levelSelected.value === 4 && (
                              <MDBox mb={2}>
                                <MDTypography
                                  variant="h6"
                                  style={{ fontWeight: 500 }}
                                >
                                  Level 4:-{" "}
                                  {
                                    getRelationLocationReducer.locations.data[3]
                                      .rltName
                                  }
                                </MDTypography>

                                <Select
                                  isClearable
                                  isDisabled={!rackIdSelected}
                                  className="select-css"
                                  maxMenuHeight={130}
                                  placeholder="Choose one..."
                                  name="location"
                                  value={locationIdSelected}
                                  options={level4Options}
                                  onChange={(selectedOption) => {
                                    handleLocationIdChange(selectedOption);
                                  }}
                                />

                                {formikForRemoveLevel.touched.location &&
                                  formikForRemoveLevel.errors.location && (
                                    <TextError
                                      msg={formikForRemoveLevel.errors.location}
                                    />
                                  )}
                              </MDBox>
                            )}
                          </>
                        )}
                        <MDBox>
                          <Collapse in={isErrorRemoveLevel}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorRemoveLevel(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {deleteErrorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessRemoveLevel}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessRemoveLevel(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Level deleted successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="error"
                            fullWidth
                            type="submit"
                            disabled={
                              !formikForRemoveLevel.isValid ||
                              !levelSelected ||
                              (levelSelected.value === 3 &&
                                (!zoneIdSelected ||
                                  !sectionIdSelected ||
                                  !rackIdSelected)) ||
                              (levelSelected.value === 4 &&
                                (!zoneIdSelected ||
                                  !sectionIdSelected ||
                                  !rackIdSelected ||
                                  !locationIdSelected)) ||
                              (levelSelected.value === 2 &&
                                !zoneIdSelected &&
                                !sectionIdSelected) ||
                              (levelSelected.value === 1 && !zoneIdSelected)
                            }
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <div>
                  <Modal
                    open={itemDetailsModal}
                    onClose={handleOpenItemDetailsModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForZoneModal}>
                      <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          {locationName}
                        </MDTypography>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleOpenItemDetailsModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>

                      <MDBox pt={1} pb={3} px={3}>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={true}
                          tableSearch={true}
                          entriesPerPage={{
                            defaultValue: 5,
                            entries: [5, 10, 15, 20, 25, 30],
                          }}
                          showTotalEntries={true}
                          noEndBorder
                          options={{ draggable: false }}
                        />
                      </MDBox>
                    </MDCard>
                  </Modal>
                </div>
              </Grid>
              <ToastContainer />
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    locationsByWarehouse: state.locationsByWarehouse,
    locationsByWarehouseAndZone: state.locationsByWarehouseAndZone,
    item: state.item,

    warehouseById: state.warehouseById,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    getRelationLocationReducer: state.getRelationLocationReducer,
    getZonesByWarehouseForViewLocationReducer:
      state.getZonesByWarehouseForViewLocationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getLocationsByWarehouse: (zoneId, sectionId, jwtToken) =>
      dispatch(getLocationsByWarehouse(zoneId, sectionId, jwtToken)),

    addLocationToWarehouse: (payload, jwtToken) =>
      dispatch(addLocationToWarehouse(payload, jwtToken)),
    deleteLocation: (locationId, warehouseId, jwtToken) =>
      dispatch(deleteLocation(locationId, warehouseId, jwtToken)),
    getItemByLocation: (locationId, jwtToken) =>
      dispatch(getItemByLocation(locationId, jwtToken)),
    getAllCategories: () => dispatch(getAllCategories()),

    getWarehouseById: (id, jwtToken) =>
      dispatch(getWarehouseById(id, jwtToken)),
    uploadExcelForCreatingZoneRackLocation: (payload, jwtToken) =>
      dispatch(uploadExcelForCreatingZoneRackLocation(payload, jwtToken)),
    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
    getZonesByWarehouseForViewLocation: (warehouseId, jwtToken) =>
      dispatch(getZonesByWarehouseForViewLocation(warehouseId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLocation);
