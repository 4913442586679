import {
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAILURE,
} from "./statusTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getStatusRequest = () => {
  return {
    type: GET_STATUS_REQUEST,
  };
};

const getStatusSuccess = (payload) => {
  return {
    type: GET_STATUS_SUCCESS,
    payload: payload,
  };
};

const getStatusFaliure = (error) => {
  return {
    type: GET_STATUS_FAILURE,
    payload: error,
  };
};

const getStatus = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getStatusRequest());
    let url = `${baseUrl}/vendor/get_not_approved?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getStatusFaliure(err.message));
      });
  };
};

export { getStatus };
