/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import { getSubModulesByRoleId, getTOTop10 } from "../../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const TOReports = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,

  getTOTop10,
  getTOTop10Reducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  const [selectedWithinWH, setSelectedWithinWH] = useState({
    label: "Within WH",
    value: true,
  });

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getTOTop10(selectedWithinWH?.value, jwtToken);
  }, [selectedWithinWH]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemStatusReportModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "95815117-55fd-40be-ab8f-a9407d9ae1df"
  );

  let viewItemStatus = itemStatusReportModule
    ? itemStatusReportModule.actionId.includes(
        "e7b51078-d27b-4bf2-9707-efa327848bad"
      )
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "category Code", accessor: "categoryCode" },
      { Header: "Item Code/Des", accessor: "itemCodeDes" },
      { Header: "Control Type", accessor: "controlType" },
      { Header: "movement Type", accessor: "movementType" },
      { Header: "picklist Count", accessor: "picklistCount" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempWithinWH = [];
    let data = getTOTop10Reducer.toTop10.data
      ? getTOTop10Reducer.toTop10.data
      : [];

    setLoading(getTOTop10Reducer.loading);
    data?.map((WithinWH) => {
      let des = WithinWH?.itemDescription;
      let len = desLength;
      const formatDate = new Date(WithinWH.cd);
      const withinWHObject = {
        itemCode: WithinWH.itemCode,
        itemCodeDes: `${WithinWH.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        controlType:
          (WithinWH.controlType === 0 && `No Control`) ||
          (WithinWH.controlType === 1 && `Serial Control`) ||
          (WithinWH.controlType === 2 && `Lot Control`),
        categoryCode: WithinWH.categoryCode,
        movementType: WithinWH.movementType,
        picklistCount: WithinWH.picklistCount,
      };
      tempWithinWH.push(withinWHObject);
    });
    // viewFG &&
    setState({ ...state, rows: tempWithinWH });
  }, [getTOTop10Reducer]);

  const headers = [
    { Header: "category Code", accessor: "categoryCode" },
    { Header: "Item Code/Des", accessor: "itemCodeDes" },
    { Header: "Control Type", accessor: "controlType" },
    { Header: "movement Type", accessor: "movementType" },
    { Header: "picklist Count", accessor: "picklistCount" },
  ];

  const csvLink = {
    filename: "Item Status Report.csv",
    headers: headers,
    data: rows,
  };

  const handleWithinWHChange = (selectedOption) => {
    setSelectedWithinWH(selectedOption);
  };
  const withinWHptions = [
    { label: "Within WH", value: true },
    { label: "Across WH", value: false },
  ];

  // useEffect(() => {
  //   setSelectedWithinWH({
  //     label: withinWHptions[0]?.label,
  //     value: withinWHptions[0]?.value,
  //   });
  // }, [withinWHptions]);

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-3}
              mt={-4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", lg: "row" }}
                    mt={2}
                  >
                    <MDBox
                      width={{ xs: "125px", lg: "200px" }}
                      style={{ marginRight: "5px" }}
                    >
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Within Warehouse and Across Warehouse
                      </MDTypography>
                      <Select
                        placeholder="Choose One..."
                        className="select-css-for-filter"
                        maxMenuHeight={130}
                        options={withinWHptions}
                        value={selectedWithinWH}
                        onChange={handleWithinWHChange}
                      />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
    // </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getTOTop10Reducer: state.getTOTop10Reducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getTOTop10: (selectedWithinWH, jwtToken) =>
      dispatch(getTOTop10(selectedWithinWH, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(TOReports);
