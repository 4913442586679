export const GET_DOCTYPE_USERMAPPING_REQUEST =
  "GET_DOCTYPE_USERMAPPING_REQUEST";
export const GET_DOCTYPE_USERMAPPING_SUCCESS =
  "GET_DOCTYPE_USERMAPPING_SUCCESS";
export const GET_DOCTYPE_USERMAPPING_FAILURE =
  "GET_DOCTYPE_USERMAPPING_FAILURE";

export const GET_UNMAPPED_DOCUMENT_TYPE_REQUEST =
  "GET_UNMAPPED_DOCUMENT_TYPE_REQUEST";
export const GET_UNMAPPED_DOCUMENT_TYPE_SUCCESS =
  "GET_UNMAPPED_DOCUMENT_TYPE_SUCCESS";
export const GET_UNMAPPED_DOCUMENT_TYPE_FAILURE =
  "GET_UNMAPPED_DOCUMENT_TYPE_FAILURE";

export const CREATE_DOCTYPE_USERMAPPING_REQUEST =
  "CREATE_DOCTYPE_USERMAPPING_REQUEST";
export const CREATE_DOCTYPE_USERMAPPING_SUCCESS =
  "CREATE_DOCTYPE_USERMAPPING_SUCCESS";
export const CREATE_DOCTYPE_USERMAPPING_FAILURE =
  "CREATE_DOCTYPE_USERMAPPING_FAILURE";

export const UPDATE_DOCTYPE_USERMAPPING_REQUEST =
  "UPDATE_DOCTYPE_USERMAPPING_REQUEST";
export const UPDATE_DOCTYPE_USERMAPPING_SUCCESS =
  "UPDATE_DOCTYPE_USERMAPPING_SUCCESS";
export const UPDATE_DOCTYPE_USERMAPPING_FAILURE =
  "UPDATE_DOCTYPE_USERMAPPING_FAILURE";

export const GET_UNMAPPED_USER_DETAILS_REQUEST =
  "GET_UNMAPPED_USER_DETAILS_REQUEST";
export const GET_UNMAPPED_USER_DETAILS_SUCCESS =
  "GET_UNMAPPED_USER_DETAILS_SUCCESS";
export const GET_UNMAPPED_USER_DETAILS_FAILURE =
  "GET_UNMAPPED_USER_DETAILS_FAILURE";

export const DELETE_DOCTYPE_USERMAPPING_REQUEST =
  "DELETE_DOCTYPE_USERMAPPING_REQUEST";
export const DELETE_DOCTYPE_USERMAPPING_SUCCESS =
  "DELETE_DOCTYPE_USERMAPPING_SUCCESS";
export const DELETE_DOCTYPE_USERMAPPING_FAILURE =
  "DELETE_DOCTYPE_USERMAPPING_FAILURE";
