import {
  GET_STATIONS_BY_ASSEMBLY_REQUEST,
  GET_STATIONS_BY_ASSEMBLY_SUCCESS,
  GET_STATIONS_BY_ASSEMBLY_FAILURE,
  CREATE_STATION_AND_ITEM_MAPPING_REQUEST,
  CREATE_STATION_AND_ITEM_MAPPING_SUCCESS,
  CREATE_STATION_AND_ITEM_MAPPING_FAILURE,
  GET_ITEMS_BY_STATION_REQUEST,
  GET_ITEMS_BY_STATION_SUCCESS,
  GET_ITEMS_BY_STATION_FAILURE,
  DELETE_STATIONS_REQUEST,
  DELETE_STATIONS_SUCCESS,
  DELETE_STATIONS_FAILURE,
  DELETE_STATIONSFORVIEW_REQUEST,
  DELETE_STATIONSFORVIEW_SUCCESS,
  DELETE_STATIONSFORVIEW_FAILURE,
  UPLOAD_EXCEL_FOR_STATION_REQUEST,
  UPLOAD_EXCEL_FOR_STATION_FAILURE,
  UPLOAD_EXCEL_FOR_STATION_SUCCESS,
  UPDATE_STATIONS_REQUEST,
  UPDATE_STATIONS_SUCCESS,
  UPDATE_STATIONS_FAILURE,
} from "./stationAndItemTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getStationsByAssemblyTypeRequest = () => {
  return {
    type: GET_STATIONS_BY_ASSEMBLY_REQUEST,
  };
};

const getStationsByAssemblyTypeSuccess = (payload) => {
  return {
    type: GET_STATIONS_BY_ASSEMBLY_SUCCESS,
    payload: payload,
  };
};

const getStationsByAssemblyTypeFailure = (error) => {
  return {
    type: GET_STATIONS_BY_ASSEMBLY_FAILURE,
    payload: error,
  };
};

const getStationsByAssemblyType = (
  assemblyName,
  pageNo,
  rowsPerPage,
  search,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getStationsByAssemblyTypeRequest());
    let url = `${baseUrl}/station/get_by_assembly_id?assemblyId=${assemblyName}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getStationsByAssemblyTypeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getStationsByAssemblyTypeFailure(err.message));
      });
  };
};


const updateStationViewRequest = () => {
  return {
    type: UPDATE_STATIONS_REQUEST,
  };
};

const updateStationViewSuccess = (payload) => {
  return {
    type: UPDATE_STATIONS_SUCCESS,
    payload: payload,
  };
};

const updateStationViewFaliure = (error) => {
  return {
    type: UPDATE_STATIONS_FAILURE,
    payload: error,
  };
};

const updateStationView = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStationViewRequest());
    let url = `${baseUrl}/station_item/edit_threshold_request`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateStationViewSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateStationViewFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};


const createStationAndItemMappingRequest = () => {
  return {
    type: CREATE_STATION_AND_ITEM_MAPPING_REQUEST,
  };
};

const createStationAndItemMappingSuccess = (payload) => {
  return {
    type: CREATE_STATION_AND_ITEM_MAPPING_SUCCESS,
    payload: payload,
  };
};

const createStationAndItemMappingFailure = (error) => {
  return {
    type: CREATE_STATION_AND_ITEM_MAPPING_FAILURE,
    payload: error,
  };
};

const createStationAndItemMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createStationAndItemMappingRequest());
    let url = `${baseUrl}/station_item/create_mapping`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createStationAndItemMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createStationAndItemMappingFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getItemsByStationRequest = () => {
  return {
    type: GET_ITEMS_BY_STATION_REQUEST,
  };
};

const getItemsByStationSuccess = (payload) => {
  return {
    type: GET_ITEMS_BY_STATION_SUCCESS,
    payload: payload,
  };
};

const getItemsByStationFailure = (error) => {
  return {
    type: GET_ITEMS_BY_STATION_FAILURE,
    payload: error,
  };
};

const getItemsByStation = (station, pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsByStationRequest());
    let url = `${baseUrl}/station_item/get_mapping_by_station_id?stationId=${station}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}&search=${search ? search : ""}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsByStationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsByStationFailure(err.message));
      });
  };
};
const deleteStationsRequest = () => {
  return {
    type: DELETE_STATIONS_REQUEST,
  };
};

const deleteStationsSuccess = (payload) => {
  return {
    type: DELETE_STATIONS_SUCCESS,
    payload: payload,
  };
};

const deleteStationsFaliure = (error) => {
  return {
    type: DELETE_STATIONS_FAILURE,
    payload: error,
  };
};

const deleteStations = (stationId, assemblyId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteStationsRequest());
    let url = `${baseUrl}/station_item/remove_station_assembly_mapping?stationId=${stationId}&assemblyId=${assemblyId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteStationsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteStationsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteStationsForViewRequest = () => {
  return {
    type: DELETE_STATIONSFORVIEW_REQUEST,
  };
};

const deleteStationsForViewSuccess = (payload) => {
  return {
    type: DELETE_STATIONSFORVIEW_SUCCESS,
    payload: payload,
  };
};

const deleteStationsForViewFaliure = (error) => {
  return {
    type: DELETE_STATIONSFORVIEW_FAILURE,
    payload: error,
  };
};

const deleteStationsForView = (stationId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteStationsForViewRequest());
    let url = `${baseUrl}/station_item/remove_item?itemMinMaxAndStationMapId=${stationId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteStationsForViewSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteStationsForViewFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadForStationRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_STATION_REQUEST,
  };
};

const uploadForStationSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_STATION_SUCCESS,
    payload: payload,
  };
};

const uploadForStationFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_STATION_FAILURE,
    payload: error,
  };
};

const uploadForStation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadForStationRequest());
    let url = `${baseUrl}/station_item_map/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadForStationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadForStationFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getStationsByAssemblyType,
  createStationAndItemMapping,
  getItemsByStation,
  deleteStationsForView,
  deleteStations,
  uploadForStation,
  updateStationView,
};
