/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyhandright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getDocTypeUserMapping,
  createDocTypeUserMapping,
  updateDocTypeUserMapping,
  getUnmappedUsersDetails,
  getUnmappedDocumentType,
  deleteDocTypeUserMapping,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import { CircularProgress } from "@mui/material";
import { addMinutes, format } from "date-fns";
import DeleteButton from "components/standardized-components/DeleteButton";
import EditButton from "components/standardized-components/EditButton";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForItemsViewModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  width: { xs: 350, lg: 800 },
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const DocTypeUserMapping = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getDocTypeUserMapping,
  docTypeUserMappingState,
  createDocTypeUserMapping,
  updateDocTypeUserMapping,
  getUnmappedDocumentType,
  getUnmappedDocumentTypeReducer,
  getUnmappedUsersDetails,
  getUnmappedUsersDetailsReducer,
  deleteDocTypeUserMapping,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails?.data?.roleId;
  let userId = loginDetails?.data?.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let docTypeUserMapping = modulesData.find(
    (moduleId) => moduleId.moduleId == "d8733306-9b75-4486-b12b-426388ff7177"
  );

  let createAccess = docTypeUserMapping
    ? docTypeUserMapping.actionId.includes(
        "6dc2abfd-f628-4060-a02d-3dd2c0e0d84f"
      )
    : null;
  let editAccess = docTypeUserMapping
    ? docTypeUserMapping.actionId.includes(
        "670e933f-98a0-4154-b501-c935280ef9fa"
      )
    : null;
  let deleteAccess = docTypeUserMapping
    ? docTypeUserMapping.actionId.includes(
        "724d3c33-442c-4a1e-a90f-2efd87ade7a3"
      )
    : null;
  let viewAccess = docTypeUserMapping
    ? docTypeUserMapping.actionId.includes(
        "a50c80eb-6e44-429e-9be9-c71352e21996"
      )
    : null;

  useEffect(() => {
    getDocTypeUserMapping(jwtToken);
  }, []);

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getUnmappedDocumentTypeReducer.documentType.data
      ? getUnmappedDocumentTypeReducer.documentType.data
      : [];

    data.map((documentType) => {
      tempDocumentTypeOptions.push({
        label: `${documentType.documentCode} / ${documentType.documentName}`,
        value: documentType.documentId,
      });
    });

    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getUnmappedDocumentTypeReducer]);

  const [userDetailsOptions, setDocTypeUserMappingOptions] = useState([]);

  useEffect(() => {
    let tempUsersDetailsOptions = [];
    let data = getUnmappedUsersDetailsReducer.userDetails.data
      ? getUnmappedUsersDetailsReducer.userDetails.data
      : [];

    data.map((UsersDetails) => {
      tempUsersDetailsOptions.push({
        label: `${UsersDetails.userName}`,
        value: UsersDetails.userId,
      });
    });

    setDocTypeUserMappingOptions(tempUsersDetailsOptions);
  }, [getUnmappedUsersDetailsReducer]);

  const [state, setState] = useState({
    columns: [
      { Header: "Document Code", accessor: "documentCode" },
      { Header: "Document Name", accessor: "documentName" },
      { Header: "User Name", accessor: "userName" },

      {
        Header: "action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [documentTypeCode, setDocumentTypeCode] = useState("");
  const [documentTypeName, setDocumentTypeName] = useState("");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempDocTypeUser = [];
    let data = docTypeUserMappingState?.docTypeUserMapping?.data
      ? docTypeUserMappingState?.docTypeUserMapping?.data
      : [];
    setLoading(docTypeUserMappingState.loading);
    let filteredData = data.filter(
      (documentType) => documentType.documentTypeId === 1
    );

    data.map((documentType) => {
      const formatDate = new Date(documentType?.cd);
      const documentTypeObj = {
        documentCode: documentType.documentCode,
        documentName: documentType.documentName,
        userName: documentType.userName,
        // createdDate: format(
        //   addMinutes(formatDate, formatDate?.getTimezoneOffset()),
        //   "do MMMM yyyy"
        // ),
        action: (
          <>
            {editAccess && (
              <EditButton
                disabled={!editAccess}
                tooltip="Edit"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateModal(documentType)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
            {deleteAccess && (
              <DeleteButton
                disabled={!deleteAccess}
                tooltip="Delete"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                onClick={() =>
                  handleOpenDeleteDocTypeUserMappingModal(documentType)
                }
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </>
        ),
      };
      tempDocTypeUser.push(documentTypeObj);
    });

    viewAccess && setState({ ...state, rows: tempDocTypeUser });
  }, [docTypeUserMappingState]);

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");
  const handleDocumentTypeChange = (selectedValue) => {
    setDocumentTypeSelected(selectedValue);
  };

  const [userSelected, setUserSelected] = useState("");
  const handleUserChange = (selectedValue) => {
    setUserSelected(selectedValue);
  };

  const [isErrorCreateMapping, setIsErrorCreateMapping] = useState(false);
  const [isSuccessCreateMapping, setIsSuccessCreateMapping] = useState(false);

  useEffect(() => {
    if (isErrorCreateMapping) {
      setTimeout(() => {
        setIsErrorCreateMapping(false);
      }, 3000);
    }
  }, [isErrorCreateMapping]);

  useEffect(() => {
    if (isSuccessCreateMapping) {
      setTimeout(() => {
        setIsSuccessCreateMapping(false);
      }, 3000);
    }
  }, [isSuccessCreateMapping]);

  const [openCreateMappingModal, setOpenCreateMappingModal] = useState(false);

  const handleOpenCreateMappingModal = () => {
    setOpenCreateMappingModal(true);
    getUnmappedDocumentType(jwtToken);
    getUnmappedUsersDetails(jwtToken);
  };

  const handleCloseCreateMappingModal = () => {
    setOpenCreateMappingModal(false);
    setDocumentTypeSelected("");
    setUserSelected("");
  };

  const initialValueForCreateMapping = {
    documentType: "",
    userDetails: "",
  };

  const validationSchema = Yup.object({
    documentType: Yup.object().required("Select the documentType!"),
    userDetails: Yup.object().required("Select the user!"),
  });

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [createButtonName, setCreateButtonName] = useState("Submit");

  const onSubmitForCreateMapping = async (values, { resetForm }) => {
    setCreateButtonName("Please wait...");
    let payload = {
      documentId: documentTypeSelected?.value,
      userId: userSelected?.value,
    };

    let res = await createDocTypeUserMapping(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreateMapping(true);
      getDocTypeUserMapping(jwtToken);
      setDocumentTypeSelected("");
      setUserSelected("");

      setTimeout(() => {
        handleCloseCreateMappingModal();
        setCreateButtonName("Submit");
      }, 2250);

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForCreate(res?.data?.data?.msg);
      setIsErrorCreateMapping(true);
      setCreateButtonName("Submit");
    }
  };

  const formikForCreateMapping = useFormik({
    initialValues: initialValueForCreateMapping,
    validationSchema: validationSchema,
    onSubmit: onSubmitForCreateMapping,
  });

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [singleDataForUpdate, setSingleDataForUpdate] = useState({});
  const handleOpenUpdateModal = (data = {}) => {
    setDocumentTypeSelected({
      label: `${data.documentCode} / ${data.documentName}`,
      value: data.documentId,
    });
    setUserSelected({
      label: `${data.userName}`,
      value: data.userId,
    });
    getUnmappedDocumentType(jwtToken);
    getUnmappedUsersDetails(jwtToken);
    setSingleDataForUpdate(data);
    setOpenUpdateModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
    formikForUpdate.setErrors({});
  };
  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);
  const [updateErrorMsg, setUpdateErrorMsg] = useState("");
  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    documentType: singleDataForUpdate ? singleDataForUpdate.documentCode : "",
    userDetails: singleDataForUpdate ? singleDataForUpdate.userName : "",
  };

  const validationSchemaForUpdate = Yup.object({
    documentType: Yup.string().required("Select a document type"),
    userDetails: Yup.string().required("Select a user name"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      documentUserMappingId: singleDataForUpdate.documentUserMappingId,
      userId: userSelected?.value,

      documentId: documentTypeSelected?.value,
    };

    let res = await updateDocTypeUserMapping(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getDocTypeUserMapping(jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setUpdateErrorMsg(res?.data?.data?.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [
    openDeleteDocTypeUserMappingModal,
    setOpenDeleteDocTypeUserMappingModal,
  ] = useState(false);
  const [
    singleDocTypeUserMappingDataForDelete,
    setDocTypeUserMappingDataForDelete,
  ] = useState({});

  const [isErrorDeleteUser, setIsErrorDeleteUser] = useState(false);
  const [isSuccessDeleteUser, setIsSuccessDeleteUser] = useState(false);

  useEffect(() => {
    if (isErrorDeleteUser) {
      setTimeout(() => {
        setIsErrorDeleteUser(false);
      }, 3000);
    }
  }, [isErrorDeleteUser]);

  useEffect(() => {
    if (isSuccessDeleteUser) {
      setTimeout(() => {
        setIsSuccessDeleteUser(false);
      }, 3000);
    }
  }, [isSuccessDeleteUser]);

  const handleOpenDeleteDocTypeUserMappingModal = (userDetails = {}) => {
    setDocTypeUserMappingDataForDelete(userDetails);
    setOpenDeleteDocTypeUserMappingModal(true);
  };
  const handleCloseDeleteDocTypeUserMappingModal = () => {
    setOpenDeleteDocTypeUserMappingModal(false);
  };

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const deleteDocTypeUserMappingData = async () => {
    let documentUserMappingId =
      singleDocTypeUserMappingDataForDelete.documentUserMappingId;
    let response = await deleteDocTypeUserMapping(
      documentUserMappingId,
      jwtToken
    );

    if (response.status) {
      getDocTypeUserMapping(jwtToken);
      setIsSuccessDeleteUser(true);

      setTimeout(() => {
        handleCloseDeleteDocTypeUserMappingModal();
      }, 2250);
    }
    if (!response.status) {
      setDeleteErrorMsg(response?.data?.data?.msg);
      setIsErrorDeleteUser(true);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {/* {createAssetAccess && ( */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={3}
                >
                  {createAccess && (
                    <MDBox color="text" px={2} ml={-2} mb={1}>
                      <CreateButton
                        // disabled={!createAccess}
                        tooltip="Create Document Type and User Mapping"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateMappingModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    </MDBox>
                  )}
                </MDBox>

                <MDBox mt={0}>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
                <Modal
                  open={openCreateMappingModal}
                  onClose={handleCloseCreateMappingModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Mapping
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateMappingModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreateMapping.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Document Type
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Select Document Type..."
                              name="documentType"
                              value={documentTypeSelected}
                              options={documentTypeOptions}
                              onChange={(selectedOption) => {
                                handleDocumentTypeChange(selectedOption);
                                formikForCreateMapping.setFieldValue(
                                  "documentType",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForCreateMapping.errors.documentType && (
                              <TextError
                                msg={formikForCreateMapping.errors.documentType}
                              />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              User
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Select User..."
                              name="userDetails"
                              value={userSelected}
                              options={userDetailsOptions}
                              onChange={(selectedOption) => {
                                handleUserChange(selectedOption);
                                formikForCreateMapping.setFieldValue(
                                  "userDetails",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForCreateMapping.errors.userDetails && (
                              <TextError
                                msg={formikForCreateMapping.errors.userDetails}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessCreateMapping}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateMapping(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Mapped successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorCreateMapping}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateMapping(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMsgForCreate}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForCreateMapping.isValid ||
                                createButtonName === "Please wait..."
                              }
                            >
                              {createButtonName}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openUpdateModal}
                  onClose={handleCloseUpdateModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Update
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseUpdateModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForUpdate.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Document Type
                          </MDTypography>
                          <Select
                            className="select-css"
                            placeholder="Choose one..."
                            name="documentType"
                            options={documentTypeOptions}
                            value={documentTypeSelected}
                            onChange={(selectedOption) => {
                              handleDocumentTypeChange(selectedOption);
                              // formikForUpdate.handleChange("documentType")(
                              //   selectedOption.value
                              // );
                            }}
                          />
                          {formikForUpdate.touched.documentType &&
                            formikForUpdate.errors.documentType && (
                              <TextError
                                msg={formikForUpdate.errors.documentType}
                              />
                            )}
                        </MDBox>

                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            User
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Select User..."
                            name="userDetails"
                            value={userSelected}
                            options={userDetailsOptions}
                            onChange={(selectedOption) => {
                              handleUserChange(selectedOption);
                              // formikForUpdate.setFieldValue(
                              //   "userDetails",
                              //   selectedOption
                              // );
                            }}
                          />
                          {formikForUpdate.errors.userDetails && (
                            <TextError
                              msg={formikForUpdate.errors.userDetails}
                            />
                          )}
                        </MDBox>

                        <MDBox>
                          <Collapse in={isErrorForUpdate}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {updateErrorMsg}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForUpdate}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Data updated successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={
                              !formikForUpdate.isValid ||
                              createButtonName === "Please wait..."
                            }
                          >
                            Update
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openDeleteDocTypeUserMappingModal}
                  onClose={handleCloseDeleteDocTypeUserMappingModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to delete this user?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseDeleteDocTypeUserMappingModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deleteDocTypeUserMappingData}
                        >
                          Delete
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDeleteUser}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDeleteUser(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDeleteUser}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteUser(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {deleteErrorMsg}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    docTypeUserMappingState: state.getDocTypeUserMappingReducer,
    getUnmappedDocumentTypeReducer: state.getUnmappedDocumentTypeReducer,
    getUnmappedUsersDetailsReducer: state.getUnmappedUsersDetailsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getDocTypeUserMapping: (jwtToken) =>
      dispatch(getDocTypeUserMapping(jwtToken)),
    createDocTypeUserMapping: (payload, jwtToken) =>
      dispatch(createDocTypeUserMapping(payload, jwtToken)),
    updateDocTypeUserMapping: (payload, jwtToken) =>
      dispatch(updateDocTypeUserMapping(payload, jwtToken)),
    deleteDocTypeUserMapping: (payload, jwtToken) =>
      dispatch(deleteDocTypeUserMapping(payload, jwtToken)),
    getUnmappedDocumentType: (jwtToken) =>
      dispatch(getUnmappedDocumentType(jwtToken)),
    getUnmappedUsersDetails: (jwtToken) =>
      dispatch(getUnmappedUsersDetails(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocTypeUserMapping);
